
// Components
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import ProductosProveedoresFiltros from "../../components/AccordionFilter/ProductosProveedores";
import ProductosProveedorTable from "../../components/Tables/ProductosProveedorTable";


const CONSTANT = {
   title: 'Productos en proveedor',
   path: 'productos',
   create_btn: 'Crear producto en proveedor'
}

const Listado = () => {
   return (
      <div className="row">
         <h3 className="mb-1 d-flex justify-content-between">
            <p><b>{CONSTANT.title}</b> - Listado</p>
            <DropdownWrapper text="Acciones" classes="ms-auto">
               <DropdownMenuLink text={CONSTANT.create_btn} href={`/proveedores/${CONSTANT.path}/create`} icon="Plus" />
            </DropdownWrapper>
         </h3>

         <ProductosProveedoresFiltros />

         <ProductosProveedorTable />
      </div>
   );
}

export default Listado;
import { Route, Routes } from 'react-router-dom'

import Create from '../../views/ProveedoresCategoria/Create'

const ProveedoresCategoriaRouter = () => {
   return (
      <Routes>
         <Route path='/create' element={<Create/>} />
      </Routes>
   )
}

export default ProveedoresCategoriaRouter
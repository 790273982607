
// Components
import BannersFiltros from "../../components/AccordionFilter/Banners";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import BannersTable from "../../components/Tables/BannersTable";

const CONSTANT = {
	title: 'Banners',
	path: 'template/banners',
	create_btn: 'Crear banner'
}

const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>
			
			<BannersFiltros />

			<BannersTable />
		</div>
	);
}

export default Listado;
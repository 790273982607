import axios from 'axios'
import { useEffect, useState } from 'react'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import Switch from '../../components/Switch'
import CustomLoader from '../../components/CustomLoader'


// Router Dom
import { useNavigate, useParams } from 'react-router-dom'


// Endpoints
import { getOne } from '../../endpoints/getOne'
import AccordionTable from '../../layout/AccordionTable'
import Table from '../../components/Table'
import { NoResults } from '../../components/Tables/Misc/NoResults'
import Modal from '../../components/Modal'
import SelectFilter from '../../components/SelectFilter'
import DropdownWrapper from '../../components/Dropdown/Wrapper'
import DropdownMenuLink from '../../components/Dropdown/Link'
import { create } from '../../endpoints/create'
import { getAll } from '../../endpoints/getAll'
import { idExtractor } from '../../helpers/idExtractor'
import FormInput from '../../components/FormInput'
import { historicoData } from '../../helpers/historicoData'
import { ModalHistorico } from '../../components/Historico/ModalHistorico'
import TablaDetalle from '../../components/Historico/TablaDetalle'


const CONSTANT = {
  queryUrlGetOne: getOne.usuarios,
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  title: 'Usuarios',
  redirectUpdate: (id) => `/usuarios/update/${id}`,
  redirectListado: '/usuarios',
  modelo: 'App\\Models\\NeuUsuario',

  createRoles: create.usuarios_roles,
  createDatosFacturacion: create.datos_facturacion,
  fetchRoles: getAll.roles,
  fetchComunas: getAll.comunas + '/buscar',

  selectFilterInitialState: { roles: [], comunas: [] },

  modalInitialState: { display: false, target: '' },

  rolFormInitialState: { rol_id: '' },

  datosFacturaFormInitialState: {
    comuna_id: '',
    usuario_id: '',
    empresa_rut: '',
    empresa_razon_social: '',
    empresa_giro: '',
    empresa_direccion: ''
  },

  permisos: {}
}


const handleSelectFiltersData = async () => {
  const comunas = await axios(CONSTANT.fetchComunas,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.comunas] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const roles = await axios(CONSTANT.fetchRoles,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { comunas, roles }
}


const Detalle = () => {
  const [data, setData] = useState(null)
  const [modal, setModal] = useState(CONSTANT.modalInitialState)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [datosFacturaForm, setDatosFacturaForm] = useState(CONSTANT.datosFacturaFormInitialState)
  const [rolForm, setRolForm] = useState(CONSTANT.rolFormInitialState)
  const [permisos, setPermisos] = useState(CONSTANT.permisos)
  const { id } = useParams()
  const dispatch = useDispatch()
  const redirect = useNavigate()
  const [modalHistorico, setModalHistorico] = useState({ id: null, display: false, data: null })
  const [historico, setHistorico] = useState(null)


  // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
  useEffect(() => {
    axios(CONSTANT.queryUrlGetOne(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        let permisosObj = {}
        setData(data)
        data?.roles?.[0]?.permissions?.forEach(el => {
          const { group, name } = el

          const isListed = Object.keys(permisosObj).find(el => el === group)

          permisosObj = {
            ...permisosObj,
            [group]: isListed ? [...permisosObj?.[group], name?.split('.')?.[1] ?? 'Error'] : [name?.split('.')?.[1] ?? 'Error']
          }
        })

        historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))

        setPermisos(permisosObj)
      })
      .catch(err => {
        console.error(err)
        dispatch(fireToaster({ title: `Error al buscar el registro: Código ${err.statusCode}`, icon: 'warning', timer: 50000 }))
        redirect(CONSTANT.redirectListado)
      })

    return () => {
      setData(null)
    }
  }, [id, dispatch, redirect])


  // FUNCION MANEJADORA DE FORMULARIO
  const handleInputChange = (e) => {
    const { name, value } = e.target
    const key = name?.split('-')[1] ?? null

    if (name.includes('roles')) {
      setRolForm({
        ...rolForm,
        [key]: value
      })
    }

    if (name.includes('datos_facturacion')) {
      setDatosFacturaForm({
        ...datosFacturaForm,
        [key]: value
      })
    }
  }


  // EFECTO QUE SETEA LA DATA DE LOS SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  const handleModalData = (data, target) => {
    if (target.includes('datos_facturacion')) {
      const { id: comunaId, nombre: comunaNombre } = selectFilter.comunas.find(({ id }) => Number(data?.comuna_id) === Number(id)) ?? ''

      setDatosFacturaForm({
        id: data?.id ?? '',
        comuna_id: `${comunaId ?? ''}${comunaId ? '-' : ''}${comunaNombre ?? ''}`,
        usuario_id: data?.usuario_id ?? '',
        empresa_rut: data?.empresa_rut ?? '',
        empresa_razon_social: data?.empresa_razon_social ?? '',
        empresa_giro: data?.empresa_giro ?? '',
        empresa_direccion: data?.empresa_direccion ?? ''
      })
    }

    setModal({ display: true, target })
  }


  // FUNCIÓN MANEJADORA PARA ACTUALIZACIÓN DEL ESTATUS
  const handleFetch = async (objData, target) => {
    let config
    let url = CONSTANT.queryUrlGetOne(objData.id)

    if (target === 'estatus') {
      let newEstatus = objData[target] ? 0 : 1

      config = {
        data: {
          method: 'POST',
          [target]: newEstatus,
          _method: 'PUT'
        }
      }
    }

    if (target === 'delete') {
      if (!window.confirm('¿Estás seguro que desea borrar el registro?')) return
      config = {
        method: 'DELETE',
      }
    }

    if (target === 'rol-delete') {
      if (!window.confirm('¿Estás seguro que desea borrar el registro?')) return
      url = CONSTANT.createRoles
      config = {
        method: 'DELETE',
        data: {
          rol_id: Number(objData?.id),
          usuario_id: Number(data.id)
        }
      }
    }

    if (target === 'rol-create') {
      url = CONSTANT.createRoles
      config = {
        method: 'POST',
        data: {
          usuario_id: Number(id),
          rol_id: idExtractor(rolForm.rol_id)
        }
      }
    }

    if (target.includes('datos_facturacion')) {
      url = target.includes('create')
        ? CONSTANT.createDatosFacturacion
        : CONSTANT.createDatosFacturacion + `/${datosFacturaForm?.id}`
      config = {
        method: target.includes('create') ? 'POST' : 'PUT',
        data: {
          ...datosFacturaForm,
          comuna_id: idExtractor(datosFacturaForm?.comuna_id),
          usuario_id: data?.id
        }
      }
    }

    if (target === 'datos_facturacion-delete') {
      if (!window.confirm('¿Estás seguro que desea borrar el registro?')) return
      url = CONSTANT.createDatosFacturacion + `/${objData?.id}`
      config = {
        method: 'DELETE'
      }
    }

    config = {
      ...config,
      headers: {
        ...config.headers,
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    }

    await axios(url, config)
      .then(res => {
        let toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: registro actualizado con éxito`,
          icon: 'success'
        }

        if (target.includes('delete')) {
          toasterContent = {
            title: 'Operación realizada',
            text: `Registro borrado con éxito`,
            icon: 'success'
          }

          return dispatch(fireToaster(toasterContent))
        }

        dispatch(fireToaster(toasterContent))

        setModal(CONSTANT.modalInitialState)

        setData(null)

        if (target === 'delete') return redirect(CONSTANT.redirectListado)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
      })
      .finally(async () => {
        await axios(CONSTANT.queryUrlGetOne(id),
          {
            headers: {
              'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
          })
          .then(({ data }) => {
            let permisosObj = {}
            setData(data)
            data?.roles?.[0]?.permissions?.forEach(el => {
              const { group, name } = el

              const isListed = Object.keys(permisosObj).find(el => el === group)

              permisosObj = {
                ...permisosObj,
                [group]: isListed ? [...permisosObj?.[group], name?.split('.')?.[1] ?? 'Error'] : [name?.split('.')?.[1] ?? 'Error']
              }
            })

            historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))

            setPermisos(permisosObj)
          })
          .catch(err => {
            console.error(err)
            dispatch(fireToaster({ title: `Error al buscar el registro: Código ${err.statusCode}`, icon: 'warning', timer: 50000 }))
            redirect(CONSTANT.redirectListado)
          })
      })
  }


  // FUNCION MANEJADORA DE LA DATA DEL MODAL
  const handleModal = (data) => {
    const { id, anterior, nuevo } = data

    setModalHistorico({
      display: true,
      id,
      data: {
        anterior,
        nuevo
      }
    })
  }


  return (
    <div className='row'>

      <ModalHistorico modal={modalHistorico} fnHandleModal={setModalHistorico} />

      <Modal display={modal.display} handleValue={() => setModal(CONSTANT.modalInitialState)}>

        {/* ROLES */}
        {modal.target === 'roles' &&
          <>
            <h3 className='text-center'>Agregar rol</h3>

            <div className='row justify-content-center mt-3'>
              <SelectFilter
                name='roles-rol_id'
                value={rolForm?.rol_id?.split('-')[1] ?? 'Seleccione'}
                labelText='Roles'
                optionObj={selectFilter.roles?.map(({ id, name, nombre }) => ({ id, name: name ?? nombre })) ?? []}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-7'
              />

              <div className='d-flex col-4'>
                <Button text='Guardar' className='m-auto' onClick={() => handleFetch({}, 'rol-create')} />
              </div>
            </div>
          </>
        }

        {/* DATOS FACTURACION */}
        {modal?.target?.includes('datos_facturacion') &&
          <>
            <h3 className='text-center'>{modal.target.includes('update') ? 'Actualizar' : 'Crear'} dato de facturacion</h3>

            <div className='row justify-content-center mt-3'>
              <SelectFilter
                name='datos_facturacion-comuna_id'
                value={datosFacturaForm?.comuna_id?.split('-')[1] ?? 'Seleccione'}
                labelText='Comuna'
                optionObj={selectFilter.comunas?.map(({ id, name, nombre }) => ({ id, name: name ?? nombre })) ?? []}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-7'
              />

              <FormInput
                name='datos_facturacion-empresa_rut'
                value={datosFacturaForm?.empresa_rut ?? 'Seleccione'}
                labelText='Empresa RUT'
                placeholder='RUT'
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                name='datos_facturacion-empresa_razon_social'
                value={datosFacturaForm?.empresa_razon_social ?? 'Seleccione'}
                labelText='Empresa razón social'
                placeholder='Razón social'
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                name='datos_facturacion-empresa_giro'
                value={datosFacturaForm?.empresa_giro ?? 'Seleccione'}
                labelText='Empresa giro'
                placeholder='Giro'
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                name='datos_facturacion-empresa_direccion'
                value={datosFacturaForm?.empresa_direccion ?? 'Seleccione'}
                labelText='Empresa dirección'
                placeholder='Dirección'
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <div className='d-flex col-4'>
                <Button text='Guardar' className='m-auto' onClick={() => handleFetch({}, modal.target)} />
              </div>
            </div>
          </>
        }
      </Modal>

      <div className='col-12'>
        <div className='col mb-50'>
          <div className='d-flex gap-1'>
            <div className='col'>
              <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
            </div>
            <Button text='Editar' icon='Settings' color='success' isLink url={CONSTANT.redirectUpdate(id)} />
            <Button text='Borrar' icon='Trash' color='danger' onClick={() => handleFetch(data, 'delete')} />
          </div>
        </div>

        {data && selectFilter.roles.length && selectFilter.comunas.length

          ? <div className='row'>

            <div className='col col-md-6'>
              <Accordion
                title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
                isOpen
                classes='mb-2'
              >
                <AccordionDetailData dataKey={'ID'} value={data?.id} />
                <AccordionDetailData dataKey={'Nombre'} value={data?.nombre ?? 'N/A'} />
                <AccordionDetailData dataKey={'Email'} value={data?.email ?? 'N/A'} />
                <AccordionDetailData dataKey={'Teléfono'} value={data?.fono ?? 'N/A'} />
                <AccordionDetailData dataKey={'% Desc. máximo'} value={data?.porcentaje_descuento_max ?? 'N/A'} />
                <AccordionDetailData dataKey={'RUT'} value={data?.rut ?? 'N/A'} />
                <AccordionDetailData dataKey={'Usuario ERP'} value={data?.usuario_erp ?? 'N/A'} />
                <AccordionDetailData dataKey={'Cod. Maximise'} value={data?.cod_maxi ?? 'N/A'} />
                <AccordionDetailData dataKey={'Pais'} value={data?.pais?.nombre ?? 'N/A'} />
                <AccordionDetailData dataKey={'Roles'} value={
                  <div className='d-flex gap-1 justify-content-center'>
                    {data?.roles?.length
                      ? data?.roles.map(({ name }) => <span key={name} className='tag px-50'>{name}</span>)
                      : 'N/A'
                    }
                  </div>
                } />
                <AccordionDetailData dataKey={'Estatus'} value={
                  <Switch
                    value={data?.estatus}
                    labelText={""}
                    margin='my-0'
                    handleValue={() => handleFetch(data, 'estatus')}
                  />}
                />
                <AccordionDetailData dataKey={'Creación'} value={data?.created_at?.split('T')[0] ?? 'N/A'} />
                <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at?.split('T')[0] ?? 'N/A'} last />
              </Accordion>

              {/* TABLA DATOS FACTURACION */}
              <AccordionTable
                classes='mb-2'
                title='Datos facturación'
              >
                <Table pb={false} style={{ overflow: 'initial' }}>
                  <thead className="table-light">
                    <tr role="row">
                      <th>ID</th>
                      <th>Datos Empresa</th>
                      <th>Comuna</th>
                      <th className='col-2'>
                        <div className='d-flex gap-2'>
                          <span>Acciones</span>
                          <Button icon='Plus' color='success' className='p-25' onClick={() => handleModalData({}, 'datos_facturacion-create')} />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.datos_facturacion?.length

                      ? data?.datos_facturacion?.map(({ id, empresa_rut, empresa_razon_social, empresa_giro, empresa_direccion, comuna }, i, arr) => (
                        <tr key={`datos-facturacion-${id}`}>
                          <td>{id}</td>

                          <td>
                            <div className='d-flex flex-column'>
                              <span>RUT: <b>{empresa_rut ?? 'N/A'}</b></span>
                              <span>Razón social: <b>{empresa_razon_social ?? 'N/A'}</b></span>
                              <span>Giro: <b>{empresa_giro ?? 'N/A'}</b></span>
                              <span>Dirección: <b>{empresa_direccion ?? 'N/A'}</b></span>
                            </div>
                          </td>

                          <td>{comuna?.nombre ?? 'N/A'}</td>

                          <td>
                            <div className='d-flex justify-content-end'>
                              <DropdownWrapper icon={'MoreHorizontal'}>
                                <DropdownMenuLink text='Editar' icon='Settings' onClick={() => handleModalData(arr[i], 'datos_facturacion-update')} />
                                <DropdownMenuLink text='Eliminar' icon='X' onClick={() => handleFetch(arr[i], 'datos_facturacion-delete')} />
                              </DropdownWrapper>
                            </div>
                          </td>
                        </tr>
                      ))

                      : <tr><td colSpan={4}><NoResults /></td></tr>}
                  </tbody>
                </Table>
              </AccordionTable>
            </div>

            <div className='col col-md-6'>
              {/* TABLA ROLES */}
              <AccordionTable
                classes='mb-2'
                title='Roles'
              >
                <Table pb={false} style={{ overflow: 'initial' }}>
                  <thead className="table-light">
                    <tr role="row">
                      <th>ID</th>
                      <th>Rol</th>
                      <th className='col-2'>
                        <div className='d-flex gap-2'>
                          <span>Acciones</span>
                          <Button icon='Plus' color='success' className='p-25' onClick={() => setModal({ display: true, target: 'roles' })} />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.roles?.length
                      ? data?.roles.map(({ name, id }) => (
                        <tr key={`roles-${name}`}>
                          <td>{id}</td>
                          <td><a href={`/roles/detalle/${id}`}>{name}</a></td>
                          <td>
                            <div className='d-flex justify-content-end'>
                              <DropdownWrapper icon={'MoreHorizontal'}>
                                <DropdownMenuLink text='Detalle' icon='List' href={`/roles/detalle/${id}`} />
                                <DropdownMenuLink text='Eliminar' icon='X' onClick={() => handleFetch({ id }, 'rol-delete')} />
                              </DropdownWrapper>
                            </div>
                          </td>
                        </tr>
                      ))
                      : <tr><td colSpan={3}><NoResults /></td></tr>
                    }
                  </tbody>
                </Table>
              </AccordionTable>

              {/* TABLA PERMISOS */}
              <AccordionTable
                classes='mb-2'
                title='Permisos'
              >
                <Table pb={false} style={{ overflow: 'auto' }}>
                  <thead className="table-light">
                    <tr role="row">
                      <th>Grupo</th>
                      <th>Permisos</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(permisos)?.length
                      ? Object.entries(permisos).map(([k, v]) => (
                        <tr key={`roles-${k}`}>
                          <td><b>{k.toUpperCase()}</b></td>
                          <td>
                            <div className='d-flex gap-25'>
                              {v.map(permiso => (<span className='tag px-50 text-nowrap'>{permiso}</span>))}
                            </div>
                          </td>
                        </tr>
                      ))
                      : <tr><td colSpan={3}><NoResults /></td></tr>
                    }
                  </tbody>
                </Table>
              </AccordionTable>
            </div>

            <TablaDetalle historicoData={historico} fnHandleModal={handleModal} />

          </div>

          : <CustomLoader />
        }
      </div>
    </div>
  )
}

export default Detalle

// Components
import BusquedaClientesFiltros from "../../components/AccordionFilter/BusquedaClientes";
import BusquedaClientesTable from "../../components/Tables/BusquedaClientesTable";


const CONSTANT = {
   title: 'Clientes',
   path: 'clientes',
}


const Listado = () => {
   return (
      <div className="row">
         <h3 className="mb-1 d-flex justify-content-between">
            <p><b>{CONSTANT.title}</b> - Listado</p>
         </h3>

         <BusquedaClientesFiltros />

         <BusquedaClientesTable />
      </div>
   );
}

export default Listado;
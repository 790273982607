import moment from 'moment'
import AccordionTable from '../../layout/AccordionTable'
import Table from '../Table'
import { NoResults } from '../Tables/Misc/NoResults'

const TablaDetalle = ({ historicoData, fnHandleModal }) => {
  return (
    <AccordionTable title='Historico de cambios' classes='col-12'>
      <Table pb={false}>
        <thead>
          <tr>
            <th className='text-center'>ID</th>
            <th className='text-center'>Acción</th>
            <th className='text-center'>Usuario</th>
            <th className='text-center'>Creación</th>
            <th className='text-center'>Edición</th>
          </tr>
        </thead>

        <tbody>
          {historicoData?.length
            ? historicoData.map(({ id, accion, created_at, updated_at, usuario, evento }, i, arr) => (
              <tr className='data-selector' key={`${i}-${new Date().getTime()}`} onClick={() => fnHandleModal((arr[i]))}>
                <td className='text-center'><b>{id}</b></td>
                <td className='text-center'>
                  <div className='d-flex flex-column'>
                    <b>{accion.toUpperCase()}</b>
                    <span>Evento: <b>{evento.nombre}</b></span>
                  </div>
                </td>
                <td className='text-center'>
                  <div className='d-flex flex-column'>
                    <span>Nombre: <b>{usuario?.nombre}</b></span>
                    <span>Email: <b>{usuario?.email}</b></span>
                    <span>Usuario: <b>{usuario?.rut}</b></span>
                  </div>
                </td>
                {/* <td className='text-center'><b>{created_at?.split('T')?.[0]}</b> | <b>{created_at?.split('T')?.[1]?.split('.')?.[0]}</b></td> */}
                <td className='text-center'><b>{moment(created_at).format('YYYY-MM-DD hh:mm:ss')}</b></td>
                <td className='text-center'><b>{moment(updated_at).format('YYYY-MM-DD hh:mm:ss')}</b></td>
              </tr>
            ))
            : <tr><td colSpan={5}><NoResults /></td></tr>
          }
        </tbody>
      </Table >
    </AccordionTable >
  )
}

export default TablaDetalle
import axios from 'axios'
import { useEffect, useState } from 'react'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import Switch from '../../components/Switch'
import CustomLoader from '../../components/CustomLoader'


// Router Dom
import { useNavigate, useParams } from 'react-router-dom'


// Endpoints
import { getOne } from '../../endpoints/getOne'
import { BASE_URL } from '../../endpoints'
import { historicoData } from '../../helpers/historicoData'
import { ModalHistorico } from '../../components/Historico/ModalHistorico'
import TablaDetalle from '../../components/Historico/TablaDetalle'


const CONSTANT = {
  redirectListado: '/productos/valores-atributos',
  redirectUpdate: (id) => `/productos/valores-atributos/update/${id}`,
  queryUrlGetOne: getOne.valores_atributos,
  title: 'Valores/Atributos',
  logo: (data) => `${BASE_URL}storage/${data?.logo}` ?? '/placeholder.png',
  modelo: 'App\\Models\\NeuValorAtributo',
}


const Detalle = () => {
  const [data, setData] = useState(null)
  const { id } = useParams()
  const dispatch = useDispatch()
  const redirect = useNavigate()
  const [historico, setHistorico] = useState(null)
  const [modal, setModal] = useState({ id: null, display: false, data: null })


  // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
  useEffect(() => {
    axios(CONSTANT.queryUrlGetOne(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        const imagenesProductosArr = data?.imagenes_productos?.split(',') ?? []
        setData({
          ...data,
          imagenes_productos: imagenesProductosArr
        })

        historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
      })
      .catch(err => {
        console.error(err)
        const toasterContent = {
          title: 'Registro no encontrado',
          icon: 'warning'
        }

        dispatch(fireToaster(toasterContent))
        redirect(CONSTANT.redirectListado)
      })

    return () => {
      setData(null)
    }
  }, [id, dispatch, redirect])


  // FUNCIÓN MANEJADORA PARA EDICION RAPIDA
  const handleFetch = async (objData, target) => {
    let config

    if (target === 'estatus') {
      let newEstatus = objData.estatus ? 0 : 1
      config = {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: {
          [target]: newEstatus,
          _method: 'PUT'
        }
      }
    }

    if (target === 'delete') {
      config = {
        method: 'DELETE',
      }
    }

    config = {
      ...config,
      headers: {
        ...config.headers,
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
      }
    }

    await axios(CONSTANT.queryUrlGetOne(objData.id), config)
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: estatus actualizado con éxito`,
          icon: 'success'
        }

        if (target === 'delete') {
          redirect(CONSTANT.redirectListado)
        }

        dispatch(fireToaster(toasterContent))
        setData(null)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
      })
      .finally(async () => {
        await axios(CONSTANT.queryUrlGetOne(id),
          {
            headers: {
              'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
          })
          .then(({ data }) => {
            const imagenesProductosArr = data?.imagenes_productos?.split(',') ?? []
            setData({
              ...data,
              imagenes_productos: imagenesProductosArr
            })

            historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
          })
          .catch(err => {
            console.error(err)
            const toasterContent = {
              title: 'Registro no encontrado',
              icon: 'warning'
            }

            dispatch(fireToaster(toasterContent))
            redirect(CONSTANT.redirectListado)
          })
      })
  }


  // FUNCION MANEJADORA DE LA DATA DEL MODAL
  const handleModal = (data) => {
    const { id, anterior, nuevo } = data

    setModal({
      display: true,
      id,
      data: {
        anterior,
        nuevo
      }
    })
  }


  return (
    <div className='row'>

      <ModalHistorico modal={modal} fnHandleModal={setModal} />


      <div className='col-12'>
        <div className='col mb-50'>
          <div className='d-flex gap-1'>
            <div className='col'>
              <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
            </div>
            <Button text='Editar' icon='Settings' color='success' isLink url={CONSTANT.redirectUpdate(id)} />
            <Button text='Borrar' icon='Trash' color='danger' onClick={() => handleFetch(data, 'delete')} />
          </div>
        </div>


        {data
          ? <>
            <Accordion
              title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
              classes='col-md-6 mx-auto mb-2'
              isOpen
            >
              <AccordionDetailData dataKey={'ID'} value={data?.id} />
              <AccordionDetailData dataKey={'Nombre'} value={data?.nombre ?? 'N/A'} />
              <AccordionDetailData dataKey={'Clave/Atributo'} value={data?.neu_clave_atributo?.nombre ?? 'N/A'} />
              <AccordionDetailData dataKey={'Símbolo'} value={data?.simbolo ?? 'N/A'} />
              <AccordionDetailData dataKey={'# Imágenes-Producto'} value={data?.imagenes_productos?.length ?? 0} />
              <AccordionDetailData dataKey={'Slug'} value={data?.slug ?? 'N/A'} />
              <AccordionDetailData dataKey={'Valor'} value={data?.valor ?? 'N/A'} />
              <AccordionDetailData dataKey={'Valor por defecto'} value={data?.valor_default ? 'Sí' : 'No'} />
              <AccordionDetailData dataKey={'Unidad'} value={data?.unidad?.nombre ?? 'N/A'} />
              <AccordionDetailData dataKey={'Código'} value={data?.codigo ?? 'N/A'} />
              <AccordionDetailData dataKey={'Estatus'} value={
                <Switch
                  value={data?.estatus}
                  labelText={""}
                  margin='my-0'
                  handleValue={() => handleFetch(data, 'estatus')}
                />} />
              <AccordionDetailData dataKey={'Logo'} value={
                <img className="height-listado" src={CONSTANT?.logo(data)} alt={data?.nombre} />
              } />
              {data.imagenes_productos.length
                ? data.imagenes_productos.map((url) => (
                  <AccordionDetailData key={url} dataKey={'Imagen producto'} value={
                    <img className="height-listado" src={`${BASE_URL}storage/${url}`} alt={url} />
                  } />
                ))

                : <></>
              }
              <AccordionDetailData dataKey={'Creación'} value={data?.created_at?.split('T')[0] ?? 'N/A'} />
              <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at?.split('T')[0] ?? 'N/A'} last />
            </Accordion>

            <TablaDetalle historicoData={historico} fnHandleModal={handleModal} />

          </>

          : <CustomLoader />
        }

      </div>
    </div>
  )
}

export default Detalle


// Components
import { useEffect, useState } from "react";
import ProductosFiltros from "../../../components/AccordionFilter/Productos";
import DropdownMenuLink from "../../../components/Dropdown/Link";
import DropdownWrapper from "../../../components/Dropdown/Wrapper";
import Modal from "../../../components/Modal";
import ProductosTable from "../../../components/Tables/ProductosTable";
import { getAll } from "../../../endpoints/getAll";
import axios from "axios";
import { fireToaster } from "../../../redux/actions/toaster";
import { useDispatch } from "react-redux";
import FileUploaderB from "../../../components/FileUploader_B";
import Button from "../../../components/Button";
import SelectFilter from '../../../components/SelectFilter';
import { BASE_URL, PATH_URL } from '../../../endpoints';
import DownloadFile from '../../../components/Download/DownloadFile';


const CONSTANT = {
   title: 'Productos',
   path: 'productos',
   create_btn: 'Crear producto',
   download_template_btn: 'Plantilla de carga masiva de productos',
   upload_products_btn: 'Carga masiva de productos',
   stock_masivo_btn: 'Actualizar stock masivo',
   precio_masivo_btn: 'Actualizar precio masivo',
   publicacion_masivo_btn: 'Actualizar publicación masiva',
   actualizar_masivo_btn: 'Actualizar productos masivo',

   urlStockMasivo: getAll.productos + `/importar/stock`,
   urlPreciosMasivo: getAll.productos + `/importar/precios`,
   urlPublicadoMasivo: getAll.productos + `/importar/publicado`,
   urlActualizarMasivo: getAll.productos + `/importar/actualizacion`,


   fetchCategorias: `${getAll.categorias}/buscar`,
   nullObject: { id: '*NULL*', nombre: '- Error -' },
   unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },

   urlDescargaPlantilla: (categoria_id) => `${BASE_URL}${PATH_URL}/productos/exportar-plantilla-carga-masiva/${categoria_id}`,

   initialFormState: {
      categoria_id: '',
   },

   selectFilterInitialState: {
      categorias: [],
   },

   fetchTarget: {
      plantilla_masivo: 'download productos masivo',
      stock_masivo: 'act stock masivo',
      precio_masivo: 'act precio masivo',
      publicacion_masiva: 'act publi masiva',
      actualizacion_masiva: 'act prod masiva',
   },

   modalState: { display: false, target: null }
}


const handleSelectFiltersData = async () => {
   const categorias = await axios(CONSTANT.fetchCategorias,
      {
         method: 'POST',
         headers: {
            'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
         },
      })
      .then(({ data }) => [CONSTANT.unselectedObject, ...data.categorias] ?? [CONSTANT.unselectedObject])
      .catch(err => {
         console.error(err)
         return [CONSTANT.nullObject]
      })

   return { categorias }
}


const Listado = () => {
   const dispatch = useDispatch()
   const [modal, setModal] = useState(CONSTANT.modalState)
   const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
   const [form, setForm] = useState(CONSTANT.initialFormState)
   const [excelFile, setExcelFile] = useState([])


   // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
   useEffect(() => {
      handleSelectFiltersData().then(data => setSelectFilter(data))
   }, [])


   // MANEJADOR DE LOS INPUTS
   const handleInputChange = (e) => {
      const { value, name } = e.target

      setForm({
         ...form,
         [name]: value
      })
   }


   const handleFetch = async (objData, target) => {
      let config
      let url

      if (target === CONSTANT.fetchTarget.stock_masivo) {
         let modalData = new FormData()
         excelFile.length && modalData.append('excel', excelFile[0]?.file)

         url = CONSTANT.urlStockMasivo
         config = {
            method: 'POST',
            headers: {
               'Content-Type': 'multipart/form-data'
            },
            data: modalData
         }
      }

      if (target === CONSTANT.fetchTarget.precio_masivo) {
         let modalData = new FormData()
         excelFile.length && modalData.append('excel', excelFile[0]?.file)

         url = CONSTANT.urlPreciosMasivo
         config = {
            method: 'POST',
            headers: {
               'Content-Type': 'multipart/form-data'
            },
            data: modalData
         }
      }

      if (target === CONSTANT.fetchTarget.publicacion_masiva) {
         let modalData = new FormData()
         excelFile.length && modalData.append('excel', excelFile[0]?.file)

         url = CONSTANT.urlPublicadoMasivo
         config = {
            method: 'POST',
            headers: {
               'Content-Type': 'multipart/form-data'
            },
            data: modalData
         }
      }

      if (target === CONSTANT.fetchTarget.actualizacion_masiva) {
         let modalData = new FormData()
         excelFile.length && modalData.append('excel', excelFile[0]?.file)

         url = CONSTANT.urlActualizarMasivo
         config = {
            method: 'POST',
            headers: {
               'Content-Type': 'multipart/form-data'
            },
            data: modalData
         }
      }

      config = {
         ...config,
         headers: {
            ...config?.headers,
            'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
         }
      }

      await axios(url, config)
         .then(res => {
            const toasterContent = {
               title: 'Operación realizada',
               text: `${CONSTANT.title}: registro actualizado con éxito`,
               icon: 'success'
            }

            dispatch(fireToaster(toasterContent))
         })
         .catch(err => {
            console.error(err);
            const { errores } = err.response.data
            let detalles = []
            Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

            const toasterContent = {
               title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
               html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
               icon: 'error'
            }

            dispatch(fireToaster(toasterContent))
         })

         .finally(() => {
            setModal(CONSTANT.modalState)
         })
   }



   const handleDownloadErrors = () => {
      if (!form.categoria_id || form.categoria_id.includes('*NULL*')) {
         dispatch(fireToaster({ title: 'La categoría es obligatoria', icon: 'info' }))
         return true;
      }

      return false;
   }


   const closeModal = () => {
      setModal({ ...modal, display: false })

      setForm(CONSTANT.initialFormState)
   }


   return (
      <div className="row">
         <Modal display={modal.display} handleValue={closeModal}>
            {modal.target === CONSTANT.fetchTarget.stock_masivo &&
               <div className='m-2'>
                  <h3 className='p-50 rounded-2 m-0'>Actualizar stock de manera masiva</h3>
                  <hr className='mt-0 mb-1' />

                  <div className='col-12 mb-50'>
                     <FileUploaderB value={excelFile} handleValue={setExcelFile} />
                  </div>

                  <div className='d-flex'>
                     <Button text='Guardar' className='mx-auto' color='success' onClick={() => handleFetch({}, CONSTANT.fetchTarget.stock_masivo)} />
                  </div>
               </div>
            }

            {modal.target === CONSTANT.fetchTarget.precio_masivo &&
               <div className='m-2'>
                  <h3 className='p-50 rounded-2 m-0'>Actualizar precios de manera masiva</h3>
                  <hr className='mt-0 mb-1' />

                  <div className='col-12 mb-50'>
                     <FileUploaderB value={excelFile} handleValue={setExcelFile} />
                  </div>

                  <div className='d-flex'>
                     <Button text='Guardar' className='mx-auto' color='success' onClick={() => handleFetch({}, CONSTANT.fetchTarget.precio_masivo)} />
                  </div>
               </div>
            }

            {modal.target === CONSTANT.fetchTarget.publicacion_masiva &&
               <div className='m-2'>
                  <h3 className='p-50 rounded-2 m-0'>Actualizar publicación de manera masiva</h3>
                  <hr className='mt-0 mb-1' />

                  <div className='col-12 mb-50'>
                     <FileUploaderB value={excelFile} handleValue={setExcelFile} />
                  </div>

                  <div className='d-flex'>
                     <Button text='Guardar' className='mx-auto' color='success' onClick={() => handleFetch({}, CONSTANT.fetchTarget.publicacion_masiva)} />
                  </div>
               </div>
            }

            {modal.target === CONSTANT.fetchTarget.actualizacion_masiva &&
               <div className='m-2'>
                  <h3 className='p-50 rounded-2 m-0'>Actualizar productos de manera masiva</h3>
                  <hr className='mt-0 mb-1' />

                  <div className='col-12 mb-50'>
                     <FileUploaderB value={excelFile} handleValue={setExcelFile} />
                  </div>

                  <div className='d-flex'>
                     <Button text='Guardar' className='mx-auto' color='success' onClick={() => handleFetch({}, CONSTANT.fetchTarget.actualizacion_masiva)} />
                  </div>
               </div>
            }

            {modal.target === CONSTANT.fetchTarget.plantilla_masivo &&
               <form className='m-2'>
                  <h3 className='p-50 rounded-2 m-0'>Descargar plantilla de carga masiva de productos</h3>
                  <hr className='mt-0 mb-1' />

                  <div className='col-12 mb-50'>
                     <SelectFilter
                        labelText="* Categoría"
                        name="categoria_id"
                        value={form?.categoria_id?.split('-')[1] ?? 'Seleccione'}
                        handleValue={handleInputChange}
                        optionObj={selectFilter.categorias?.map(({ nombre, id }) => ({ name: nombre, id }))}
                        size="col-12"
                        sizeDesk='col-12'
                     />
                  </div>

                  <div className='d-flex'>
                     <DownloadFile url={CONSTANT.urlDescargaPlantilla(form.categoria_id)} handleErrors={handleDownloadErrors} />
                  </div>
               </form>
            }
         </Modal>

         <h3 className="mb-1 d-flex justify-content-between">
            <p><b>{CONSTANT.title}</b> - Listado</p>

            <div className="d-flex gap-1">
               <DropdownWrapper text="Asignaciones masivas" classes="ms-auto" color="success">
                  <DropdownMenuLink text='Puntos de entrega' href={`/productos/puntos-entrega/asignacion-masiva`} />
                  <DropdownMenuLink text='Métodos de pago' href={`/productos/metodos-pago/asignacion-masiva`} />
                  <DropdownMenuLink text='Etiquetas' href={`/productos/etiquetas/asignacion-masiva`} />
                  <DropdownMenuLink text='Ofertas' href={`/productos/ofertas/asignacion-masiva`} />
                  <DropdownMenuLink text='Adicionales' href={`/productos/adicionales/asignacion-masiva`} />
               </DropdownWrapper>
               <DropdownWrapper text="Ediciones masivas" classes="ms-auto" color="warning">
                  <DropdownMenuLink text='Instalación y despacho' href={`/productos/instalacion-despacho/edicion-masiva`} />
                  <DropdownMenuLink text='Métodos de pago' href={`/productos/metodos-pago/edicion-masiva`} />
                  <DropdownMenuLink text='Etiquetas' href={`/productos/etiquetas/edicion-masiva`} />
                  <DropdownMenuLink text='Ofertas' href={`/productos/ofertas/edicion-masiva`} />
                  <DropdownMenuLink text='Adicionales' href={`/productos/adicionales/edicion-masiva`} />
               </DropdownWrapper>

               <DropdownWrapper text="Acciones" classes="ms-auto">
                  <DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
                  <DropdownMenuLink text={CONSTANT.download_template_btn} onClick={() => setModal({ display: true, target: CONSTANT.fetchTarget.plantilla_masivo })} icon="Download" />
                  <DropdownMenuLink text={CONSTANT.upload_products_btn} href='/productos/carga-masiva' icon="Upload" />
                  <DropdownMenuLink text={CONSTANT.stock_masivo_btn} onClick={() => setModal({ display: true, target: CONSTANT.fetchTarget.stock_masivo })} icon="List" />
                  <DropdownMenuLink text={CONSTANT.publicacion_masivo_btn} onClick={() => setModal({ display: true, target: CONSTANT.fetchTarget.publicacion_masiva })} icon="Check" />
                  <DropdownMenuLink text={CONSTANT.precio_masivo_btn} onClick={() => setModal({ display: true, target: CONSTANT.fetchTarget.precio_masivo })} icon="DollarSign" />
                  <DropdownMenuLink text={CONSTANT.actualizar_masivo_btn} onClick={() => setModal({ display: true, target: CONSTANT.fetchTarget.actualizacion_masiva })} icon="Upload" />
               </DropdownWrapper>
            </div>
         </h3>

         <ProductosFiltros isListadoErp />

         <ProductosTable />
      </div >
   )
}

export default Listado;
import { useEffect, useState } from "react";
import axios from "axios";

//	Components
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import FormInput from "../../components/FormInput";
import CustomLoader from "../../components/CustomLoader";


// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


// Hooks
import { getOne } from "../../endpoints/getOne";


// React-router-dom
import { useNavigate, useParams } from "react-router-dom";


const CONSTANT = {
  redirectUrl: '/generales/formas-pago',
  componentTitle: 'Editar forma de pago',
  title: 'Formas de pago',
  queryUrl: getOne.formas_pago,
}


const Update = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetchData, setFetchData] = useState(null)
  const { id } = useParams()


  // FUNCIÓN MANEJADORA QUE ACTUALIZA EL ESTADO DE 'FetchData'
  const handleInputChange = (e) => {
    const { target } = e

    setFetchData({
      ...fetchData,
      [target.name]: target.value,
    })
  }


  // EFECTO QUE CONSULTA LA DATA DEL REGISTRO Y ACTUALIZA EL ESTADO 'FetchData'
  useEffect(() => {
    axios(CONSTANT.queryUrl(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => setFetchData(data))
      .catch(err => console.error(err))

    return () => {
      setFetchData(null)
    }
  }, [id])


  const handleFetch = async (e) => {
    e.preventDefault()

    let data = {
      nombre: fetchData?.nombre,
      estatus: Number(fetchData?.estatus) ? true : false
    }

    axios(CONSTANT.queryUrl(id), {
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        '_method': 'PUT'
      },
      data
    })
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${res.data.nombre} actualizado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
          Operación fallida
          (${err.response.status} - ${err.response.statusText})
        `,
          html: `
          <b>Detalle: </b>
          ${detalles.map(error => `<br /><i>-${error}</i>`)}
        `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>
        {fetchData
          ? <CustomForm size="" title={CONSTANT.title}>
            <div className="row">
              <Switch name="estatus"
                value={fetchData?.estatus}
                labelText="Estatus"
                handleValue={handleInputChange}
                size="col-12"
              />

              <FormInput
                name="nombre"
                value={fetchData?.nombre}
                labelText="* Nombre"
                handleValue={handleInputChange}
                size="col-6"
              />

              <FormInput
                name="cod_erp"
                value={fetchData?.cod_erp}
                labelText="* Cod. ERP"
                handleValue={handleInputChange}
                size="col-6"
              />
            </div>
            <Button type="submit" text="Actualizar" color="primary" icon="Circle" onClick={handleFetch} />
          </CustomForm>
          : <CustomLoader />
        }
      </div>
    </div>
  );
};

export default Update;

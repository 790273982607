import React, { useEffect, useState } from 'react';
import axios from 'axios';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from "../../redux/actions/toaster";
import { clearFilterCiudades, resultadosCiudades } from '../../redux/actions/ciudades';


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Table from "../Table";


// Layouts
import AccordionTable from "../../layout/AccordionTable";


// Endpoints
import { BASE_URL, PATH_URL } from '../../endpoints';
import { getAll } from '../../endpoints/getAll';
import Modal from '../Modal';
import { todayFormat } from '../../helpers/todayInputFormat';
import Button from '../Button';
import { idExtractor } from '../../helpers/idExtractor';
import { PrecioFormatter } from '../../helpers/precioFormatter';
import FileUploaderB from '../FileUploader_B';
import { useCopyClipboard } from '../../hooks/useCopyClipboard';
import { ModalPlanificacion } from '../Modal/NotasVentas/ModalPlanificacion';
import { ModalOrdenCompra } from '../Modal/NotasVentas/ModalOrdenCompra';


const Precio = (v) => new PrecioFormatter(v).producto()


const CONSTANT = {
	pathUrl: '/erp/notas-ventas',
	permissionsPath: 'notas-venta',
	title: 'Notas de venta',
	reduxClearFilters: clearFilterCiudades,
	reduxSetResults: resultadosCiudades,

	fecthMaximiseData: BASE_URL + PATH_URL + '/erp/notas-ventas/lineas/listar',
	fecthProductosOc: BASE_URL + PATH_URL + '/erp/productos?ligero=1',
	fetchAgendamientos: getAll.agendamientos + '/listar?desde=2020-01-01&hasta=2031-01-01',
	fetchBodegas: BASE_URL + PATH_URL + '/erp/bodegas/obtener-vigentes',
	fetchProveedores: BASE_URL + PATH_URL + '/erp/proveedores/obtener',
	fetchMetodosEntrega: getAll.metodos_entrega,

	createPlanificacion: BASE_URL + PATH_URL + `/erp/notas-ventas/planificar`,
	createOC: BASE_URL + PATH_URL + `/erp/ordenes-compras/crear-desde-nv`,

	fetchNvPdf: (nv) => `${BASE_URL}${PATH_URL}/erp/notas-ventas/${nv}/pdf`,

	planificacionForm: { nota_venta: '', fecha_planificacion: todayFormat() },

	OCForm: {
		nota_venta: '',
		rut_proveedor: '',
		bodega: '',
		comentario: '',
		productos: [],
		extra_productos: []
	},

	ordenForm: { nota_venta: '' },

	selectFilter: {
		bodegas: [],
		proveedores: [],
		metodoEntrega: [],
		ocProductos: []
	},

	acordeones: { productos: false }

}


const fetchSecondaryDataNotaVenta = async (NV) => {
	const dataERP = NV?.nv?.map(({ ORDER_NUM }) => ORDER_NUM) ?? []
	const dataAgendamientos = NV?.nv?.map(({ EXT_NUM }) => Number(EXT_NUM)).filter(el => !isNaN(el)) ?? []
	let erpLineas = []

	if (dataERP.length) {
		erpLineas = await axios(CONSTANT.fecthMaximiseData, {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
			method: 'POST',
			data: { notas_ventas: dataERP }
		})
			.then(({ data }) => NV?.nv?.map(({ ORDER_NUM }, i, arr) => {
				const erp_data = data.data[ORDER_NUM]
				return { ...arr[i], erp_data }
			}))
			.catch(err => {
				console.error(err)
				return NV
			})
	}

	const agendamientos = await axios(CONSTANT.fetchAgendamientos, {
		headers: {
			'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
		},
		method: 'POST',
		data: {
			filtros: {
				pedido_id: dataAgendamientos
			}
		}
	})
		.then(({ data }) => {
			return erpLineas.map(({ EXT_NUM }, i, arr) => {
				const selectedAgendamiento = data.agendamientos.find(({ id }) => Number(id) === Number(EXT_NUM))
				return { ...arr[i], agenda: selectedAgendamiento ?? null }
			})
		})
		.catch(err => {
			console.error(err)
			return erpLineas
		})

	return agendamientos
}


const fetchSelectFiltersData = async () => {
	const ocProductos = await axios(CONSTANT.fecthProductosOc,
		{
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
		.then(({ data }) => data.data)
		.catch(err => {
			console.error(err)
			return []
		})

	const bodegas = await axios(CONSTANT.fetchBodegas,
		{
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
		.then(({ data }) => data?.data ?? [])
		.catch(err => {
			console.error(err)
			return []
		})

	const proveedores = await axios(CONSTANT.fetchProveedores,
		{
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
		.then(({ data }) => data?.data ?? [])
		.catch(err => {
			console.error(err)
			return []
		})

	const metodoEntrega = await axios(CONSTANT.fetchMetodosEntrega, {
		headers: {
			'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
		},
	})
		.then(({ data }) => data)
		.catch(err => {
			console.error(err)
			return [{ codigo_vendedor: 'Error', vendedor: 'Error' }]
		})
		
	return {
		bodegas,
		proveedores,
		metodoEntrega,
		ocProductos
	}
}


const NotasVentasTable = () => {
	const { resultados: response } = useSelector(state => state.notasVentas)
	const [resultados, setResultados] = useState([])
	const dispatch = useDispatch()
	const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
	const [modal, setModal] = useState({ display: false, target: '' })
	const [planificacionForm, setPlanificacionForm] = useState(CONSTANT.planificacionForm)
	const [OCForm, setOCForm] = useState(CONSTANT.OCForm)
	const { user } = useSelector(state => state.auth)
	const [acordeones, setAcordeones] = useState(CONSTANT.acordeones)
	const [ordenImg, setOrdenImg] = useState([])
	const [ordenForm, setOrdenForm] = useState(CONSTANT.ordenForm)
	const [copy] = useCopyClipboard()


	// EFECTO QUE FORMATEA LA RESPUESTA DE PRODUCTOS CON LA RESPUESTA DEL MAXIMISE
	useEffect(() => {
		if (!response?.nv) return
		dispatch(fireToaster({ title: 'Realizando consulta al ERP', icon: 'info', text: 'Buscando...' }))

		fetchSecondaryDataNotaVenta(response).then(data => setResultados(data))

		dispatch(fireToaster({ title: 'Consulta al ERP finalizada', icon: 'success' }))

		return setResultados(null)
	}, [response, dispatch])


	// EFFECTO QUE SETEA LA DATA DE LOS SELECTFILTER
	useEffect(() => {
		fetchSelectFiltersData().then(data => {
			setSelectFilter(data)
		})
	}, [])


	const handleInputChange = (e) => {
		const { name, value } = e.target

		if (name.includes('planificacion')) {
			const key = name.split('%-%')[1]
			setPlanificacionForm({
				...planificacionForm,
				[key]: value
			})
		}

		if (name.includes('OC')) {
			const target = name.split('%-%')?.[1]

			if (target === 'producto') {
				const lineaItem = name.split('%-%')[2]
				const key = name.split('%-%')[3]

				setOCForm({
					...OCForm,
					productos: OCForm.productos.map((prod) => prod.linea_item === lineaItem ? { ...prod, [key]: value } : prod)
				})

				return
			}

			if (target === 'extra_productos') {
				const key = name.split('%-%')[3]
				const sku = name.split('%-%')[2]

				if (key === 'add') {
					const newProd = {
						linea_item: 0,
						sku,
						costo_neto: 0,
						qty: 0
					}

					const isListed = OCForm.extra_productos.find(({ sku }) => sku === newProd.sku)

					return (isListed)
						? dispatch(fireToaster({ title: 'Producto ya incluído', icon: 'info' }))
						: setOCForm({ ...OCForm, extra_productos: [...OCForm.extra_productos, newProd] })
				}

				if (key === 'delete') {
					return setOCForm({
						...OCForm, extra_productos: OCForm.extra_productos.filter((prod) => prod.sku !== sku)
					})
				}

				else return setOCForm({
					...OCForm,
					extra_productos: OCForm.extra_productos.map((prod) => prod.sku === sku ? { ...prod, [key]: value } : prod)
				})
			}

			else return setOCForm({
				...OCForm,
				[target]: value
			})
		}
	}


	const handleModalData = (data, target) => {
		if (target === 'planificacion') {
			setPlanificacionForm({
				...planificacionForm,
				nota_venta: Number(data?.id)
			})
			return setModal({ target: 'planificacion', display: true })
		}

		if (target === 'OC') {
			const lineasNvFiltered = data?.erp_data?.filter(({ BACK_QTY }) => Number(BACK_QTY))
			const defaultBodega = selectFilter?.bodegas?.find(({ WARE_CODE }) => WARE_CODE === lineasNvFiltered?.[0]?.WARE_CODE)

			setOCForm({
				...OCForm,
				nota_venta: data?.ORDER_NUM,
				bodega: `${defaultBodega?.WARE_CODE ?? '*NULL*'}-${defaultBodega?.WARE_CODE ?? 'N/A'} | ${defaultBodega?.DESC_TEXT ?? '*Sin selección*'}`,
				rut_proveedor: data?.CLIENTE_RUT ?? '',
				productos: data?.erp_data?.filter(({ BACK_QTY }) => Number(BACK_QTY) > 0)
					.map(producto => ({
						check: true,
						descripcion: producto?.DESC_TEXT ?? 'Error',
						linea_item: producto?.LINE_NUM ?? '',
						sku: producto?.PART_CODE ?? '',
						costo_neto: 0,
						qty: Number(producto?.ORDER_QTY ?? 0),
						qty_reservada: Number(producto?.SCHED_QTY),
						qty_pendiente: Number(producto?.BACK_QTY)
					}))
			})
			return setModal({ target: 'OC', display: true })
		}

		if (target === 'orden') {
			setOrdenForm({
				...ordenForm,
				nota_venta: data
			})
			setModal({ target: 'orden', display: true })
		}
	}


	const handleFetch = async (objData, target) => {
		let config = {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		}
		let url

		if (target === 'planificacion-create') {
			url = CONSTANT.createPlanificacion
			config = {
				...config,
				method: 'POST',
				data: planificacionForm
			}
		}

		if (target === 'oc-create') {
			url = CONSTANT.createOC
			config = {
				...config,
				method: 'POST',
				data: {
					nota_venta: Number(OCForm.nota_venta),
					bodega: OCForm.bodega.split('-')[0],
					comentario: OCForm.comentario,
					rut_proveedor: idExtractor(OCForm.rut_proveedor, true),
					productos: OCForm.productos.filter(({ check }) => check)
						.map(el => ({
							linea_item: Number(el.linea_item),
							sku: el.sku,
							costo_neto: Number(el.costo_neto),
							qty: Number(el.qty)
						}))
						.concat(OCForm.extra_productos.map(el => ({
							linea_item: Number(el.linea_item),
							sku: el.sku,
							costo_neto: Number(el.costo_neto),
							qty: Number(el.qty)
						})))
				}
			}
		}

		if (target === 'orden') {
			if (!ordenImg?.length) dispatch(fireToaster({ title: 'Debe enviar al menos una imagen', icon: 'info' }))
			if (!ordenForm.nota_venta) dispatch(fireToaster({ title: 'El agendamiento debe tener una nota de venta asociada', icon: 'info' }))

			url = `${BASE_URL}${PATH_URL}/erp/notas-ventas/cierre-orden/evidencia/${ordenForm.nota_venta}`
			let data = new FormData()
			ordenImg.forEach(({ file }) => {
				data.append('imagenes[]', file)
			})

			config = {
				...config,
				headers: {
					...config.headers,
					'Content-Type': 'multipart/form-data'
				},
				method: 'POST',
				data
			}
		}

		await axios(url, config)
			.then(res => {
				const toasterContent = {
					title: 'Operación realizada',
					text: `${CONSTANT.title}: registro actualizado con éxito`,
					icon: 'success'
				}
				dispatch(fireToaster(toasterContent))
				setModal({ target: '', display: false })
			})
			.catch(err => {
				console.error(err);
				const { errores } = err.response.data
				let detalles = []

				Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))
				let toasterContent = {
					title: `
						Operación fallida
						(${err.response.status} - ${err.response.statusText})
					`,
					html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
					icon: 'error'
				}

				dispatch(fireToaster(toasterContent))
			})
	}


	// FUNCION MANEJADORA DE LOS ACORDEONES
	const handleInnerAccordion = (value, target) => {
		setAcordeones({ ...acordeones, [target]: !value })
	}


	const handleFiltrosPersistentes = (location) => {
		return `?filtros_persistentes=${location?.search?.split('filtros_persistentes=')?.[1] ?? '0'}`
	}
	

	return (
		<>
			<div className='d-flex gap-1 justify-content-end mb-1'>
				<span className='my-auto text-black fw-bold bg-white border rounded-3 p-50'>Empresa: {response?.empresa ?? 'Desconocido'}</span>

				<div className='w-fit'>
					<Button onClick={() => handleInnerAccordion(acordeones.productos, 'productos')} text='Productos' icon={acordeones.productos ? 'Minimize2' : 'Maximize2'} />
				</div>
			</div>

			<AccordionTable title={`${CONSTANT.title} (${resultados?.length ?? 0} resultados)`}>
				{!resultados && <CustomLoader blocking={'partial'} />}
				<Modal display={modal.display} handleValue={() => setModal(false)} size={modal.target === 'OC' ? 'w-75 h-75' : ''}>

					{/* MARK: CIERRE ORDEN */}
					{modal.target === 'orden' &&
						<>
							<div className='m-2'>
								<h3 className='text-primary p-50 rounded-2 m-0'>Cerrar Orden {ordenForm?.nota_venta ?? 'N/A'}</h3>
								<hr className='mt-0 mb-2' />

								<div className='col-12 mb-50'>
									<FileUploaderB value={ordenImg} handleValue={setOrdenImg} />
								</div>
							</div>

							<div className='col-3 mx-auto'>
								<Button text='Adjuntar' color='success' onClick={() => handleFetch(null, 'orden')} />
							</div>
						</>
					}

					{/* MARK: PLANIFICACION */}
					{(modal.target === 'planificacion') &&
						<ModalPlanificacion
							key={'component-planificacion-modal'}
							form={planificacionForm}
							id={planificacionForm.nota_venta}
							handleInputChange={handleInputChange}
							handleFetch={handleFetch}
							selectFilter={selectFilter}
						/>
					}

					{/* MARK: OC */}
					{modal.target === 'OC' &&
						<ModalOrdenCompra
							key={'component-OC-modal'}
							form={OCForm}
							id={OCForm.nota_venta}
							handleInputChange={handleInputChange}
							handleFetch={handleFetch}
							selectFilter={selectFilter}
						/>
					}
				</Modal>

				<Table>
					<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
						<tr role="row" className='text-center'>
							<th>NV</th>
							<th>Cliente</th>
							<th>Dirección</th>
							<th>Planificación</th>
							<th>Facturación</th>
							<th>Total</th>
							<th>Agenda</th>
							<th className="w-max">Acciones</th>
						</tr>
					</thead>
					<tbody>
						{resultados?.length
							? resultados?.map(({
								ORDER_NUM, EXT_NUM, ORDER_DATE, NOMBRE_VENDEDOR, STATUS_IND,
								CLIENTE_NOMBRE, CLIENTE_RUT, CLIENTE_EMAIL, CLIENTE_TELEFONO,
								SHIP_ADDR_TEXT, UD_TIPO_DESTI, COMUNA_ENTREGA, SHIP_NAME_TEXT,
								SHIP_DATE, UD_TIPO_FACTURACION, UD_MONTO_ABONADO, TOTAL_AMT,
								UD_ESTATUS_VENTA, UD_ESTATUS_LOGISTICA, UD_ESTATUS_BODEGA,
								DOCSHIPVIA_CODE, DXC, SUM_MONTO_PAGADO, erp_data, agenda,
								COM_TEXT
							}, i, arr) => {
								return (
									<React.Fragment key={`nv-rw-${i}`} >
										<tr
											className={`odd multi-data text-center`}
											key={`nv-${i}-${ORDER_NUM}-${EXT_NUM}-${CLIENTE_RUT}`}
											style={{ backgroundColor: i % 2 > 0 ? '#e8eaeb' : '#fff' }}
										>
											{/* MARK: NV */}
											<td rowSpan={2} style={{ borderBottom: 'transparent' }} className='pt-3'>
												<div className='d-flex flex-column text-center'>
													<a target='_blank' rel='noreferrer' href={`${CONSTANT.pathUrl}/detalle/${ORDER_NUM}`}><b className='fs-4 text-primary'>{ORDER_NUM ?? '?'}</b></a>
													<span>
														NP: {EXT_NUM
															? <a href={`/pedidos/detalle/${EXT_NUM}`} target='_blank' rel='noreferrer'><b>{EXT_NUM}</b></a>
															: <b>N/A</b>
														}
													</span>
													<span>{ORDER_DATE?.split('T')[0] ?? '?'}</span>
													<span><b>{NOMBRE_VENDEDOR ?? '?'}</b></span>
													<span>Estatus: {STATUS_IND ?? '?'}</span>
												</div>

												<Button onClick={() => handleModalData(ORDER_NUM, 'orden')} text='Cerrar Orden' className='p-25 px-50 mx-auto  mt-1' />

											</td>

											{/* MARK: CLIENTE */}
											<td className='px-50'>
												<div className='d-flex flex-column text-center'>
													<b>{CLIENTE_NOMBRE ?? '?'}</b>
													<span><b>{CLIENTE_RUT ?? 'N/A'}</b></span>

													<DropdownWrapper icon={"Plus"} classes="w-fit mx-auto my-auto mt-50" buttonClasses={'p-25'}>
														<DropdownMenuLink text={CLIENTE_EMAIL ?? 'N/A'} icon="Mail" onClick={() => copy(CLIENTE_EMAIL)} />
														<DropdownMenuLink text={CLIENTE_TELEFONO ?? 'N/A'} icon="Phone" style={{ cursor: 'initial' }} />
													</DropdownWrapper>
												</div>
											</td>

											{/* MARK: DIRECCION */}
											<td className='px-50'>
												<div className='d-flex flex-column text-center'>
													<span><b>{SHIP_ADDR_TEXT ?? '?'}</b></span>
													<span><b>{UD_TIPO_DESTI ?? '?'}</b></span>
													<span>{COMUNA_ENTREGA ?? '?'}</span>
												</div>

												<DropdownWrapper icon={"Plus"} classes="w-fit mx-auto my-auto mt-50" buttonClasses={'p-25'}>
													<DropdownMenuLink text={selectFilter.metodoEntrega.find(({ id }) => Number(id) === Number(DOCSHIPVIA_CODE))?.nombre ?? '?'} icon="Truck" style={{ cursor: 'initial' }} />
													<DropdownMenuLink text={SHIP_NAME_TEXT ?? '?'} icon="User" style={{ cursor: 'initial' }} />
												</DropdownWrapper>
											</td>

											<td><b className='text-nowrap'>{SHIP_DATE?.split('T')?.[0] ?? 'N/A'}</b></td>

											{/* MARK: FACTURACION */}
											<td className='px-50'>
												<div className='d-flex flex-column'>
													<span>Tipo: {UD_TIPO_FACTURACION ?? 'N/A'}</span>
													<b>DXC: {DXC ?? '?'}</b>
												</div>
											</td>

											{/* MARK: TOTAL */}
											<td className='px-50'>
												<div className='d-flex flex-column'>
													<b className={`tag px-50 fs-3 mx-auto w-max ${TOTAL_AMT - (SUM_MONTO_PAGADO ?? 0) > 0 ? 'red' : 'success'}`}>{Precio(TOTAL_AMT)}</b>
													<span>Pagado: <b>{Precio(SUM_MONTO_PAGADO)}</b></span>
												</div>
											</td>

											{/* MARK: AGENDA */}
											<td>
												<div className='rounded-2 py-25 px-50 w-100'>
													{agenda
														? <div className='tag dark-blue px-50 mx-auto'>
															<b>
																<p className='my-0 text-center'>{agenda?.agendamiento?.fecha?.split('T')[0] ?? '?'}</p>
																<p className='my-0 text-center'>{agenda?.agendamiento?.hora_agenda?.hora_inicio_intervalo ?? '?'} - {agenda?.agendamiento?.hora_agenda?.hora_final_intervalo ?? '?'}</p>
															</b>
														</div>

														: <p className='tag px-50 text-center mx-auto'><b>Sin agenda</b></p>
													}
												</div>
											</td>

											<td>
												<DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
													<>
														{user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
															<DropdownMenuLink text='Detalle' target='_blank' rel='noreferrer' href={`${CONSTANT.pathUrl}/detalle/${ORDER_NUM}` + handleFiltrosPersistentes(window.location)} icon="Table" />
														}

														{user.permisos[`planificar.crear`] &&
															<DropdownMenuLink
																text='Planificar'
																onClick={() => handleModalData({ id: ORDER_NUM }, 'planificacion')}
																icon="Calendar"
															/>
														}

														{user.permisos[`ordenes-compra.crear`] &&
															<DropdownMenuLink
																text='Realizar OC'
																onClick={() => handleModalData(arr[i], 'OC')}
																icon="CreditCard"
															/>
														}

														{user.permisos[`pedidos.editar`] &&
															<DropdownMenuLink
																text='Agregar pago (Pedido)'
																icon="Check"
																href={`/pedidos/detalle/${EXT_NUM}?ap=1`}
															/>
														}

														{user.permisos[`${CONSTANT.permissionsPath}.exportar`] &&
															<DropdownMenuLink
																text='Exportar'
																href={CONSTANT.fetchNvPdf(ORDER_NUM)}
																isInnerLink={false}
																icon="Download"
															/>
														}
													</>
												</DropdownWrapper>
											</td>
										</tr>

										<tr className={`odd multi-data`}>
											<td style={{ borderBottom: 'transparent', backgroundColor: i % 2 > 0 ? '#e8eaeb' : '#fff' }} colSpan={11} className='p-0 pb-75'>
												<AccordionTable isOpen={acordeones.productos} title={`Productos ERP (${erp_data?.reduce((prev, { UOM_QTY }) => prev += Number(UOM_QTY), 0) ?? 0})`} style={{ backgroundColor: i % 2 > 0 ? '#456ca3' : "#942626" }}>
													<Table pb={false} >
														<thead className='table-light position-sticky top-0 w-100 z-100 shadow'>
															<tr>
																<th>Línea Item</th>
																<th>Descripción</th>
																<th>SKU</th>
																<th>Bodega</th>
																<th>Qty</th>
																<th>Qty reservada</th>
																<th>Qty pdte.</th>
																<th>P. unit. neto</th>
																<th>P. subtotal neto</th>
															</tr>
														</thead>
														<tbody>
															{erp_data?.length
																? erp_data.map(({ DESC_TEXT, UD_MONTO_INSTALACION, UD_RECARGO, LINE_NUM, PART_CODE, WARE_CODE, UOM_QTY, SCHED_QTY, BACK_QTY, UNIT_PRICE_AMT, EXT_PRICE_AMT }, innerI) => (
																	<tr key={`lineas-pedido-erp-${DESC_TEXT ?? 'producto'}-${innerI ?? PART_CODE}`}>
																		<td><b>{LINE_NUM ?? 'N/A'}</b></td>
																		<td>
																			{/* <a href='/producto/detalle'> */}
																			{DESC_TEXT.toUpperCase() ?? 'N/A'}
																			{/* </a> */}
																		</td>
																		<td>{PART_CODE ?? 'N/A'}</td>
																		<td className='text-center'>{WARE_CODE ?? 'N/A'}</td>
																		<td className='text-center'>{UOM_QTY ?? 'N/A'}</td>
																		<td className='text-center'>{SCHED_QTY ?? 'N/A'}</td>
																		<td className='text-center'>{BACK_QTY ?? 'N/A'}</td>
																		<td className='text-center'>{Precio(UNIT_PRICE_AMT)}</td>
																		<td className='text-center'>{Precio(EXT_PRICE_AMT)}</td>
																	</tr>
																))
																: <tr><td colSpan={5}><p className='tag dark-blue px-50 m-auto'><b>Error de conexión</b></p></td></tr>
															}
														</tbody>
													</Table>
												</AccordionTable>
											</td>
										</tr>

										<tr className={`odd multi-data`} style={{ borderBottom: '2px solid #333', backgroundColor: i % 2 > 0 ? '#e8eaeb' : '#fff' }}>
											<td colSpan={8} className='pb-3'>
												<div className='d-flex justify-content-between gap-25'>
													<div className='d-flex gap-1 rounded bg-white px-50' style={{ border: '1px solid #e8eaeb' }}>
														<span><b>Comentario:</b></span>
														<span>{COM_TEXT ?? 'N/A'}</span>
													</div>
													<div className='d-flex justify-content-around gap-1'>
														<span className='tag px-50'>Estatus venta: <b>{UD_ESTATUS_VENTA ?? 'N/A'}</b></span>
														<span className='tag px-50'>Estatus logistica: <b>{UD_ESTATUS_LOGISTICA ?? 'N/A'}</b></span>
														<span className='tag px-50'>Estatus bodega: <b>{UD_ESTATUS_BODEGA ?? 'N/A'}</b></span>
													</div>
												</div>
											</td>
										</tr>

									</React.Fragment>
								)
							})
							: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={8}><i>Sin datos para mostrar...</i></td></tr>
						}
					</tbody>
				</Table>
			</AccordionTable >
		</>
	)
}

export default NotasVentasTable
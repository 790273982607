import axios from "axios";
import React, { useState } from "react";


// Redux
import { useDispatch, useSelector } from "react-redux";
import { clearFilterCotizaciones, resultadosCotizaciones } from "../../redux/actions/cotizaciones";
import { fireToaster } from "../../redux/actions/toaster";


// Components
import Table from "../Table";
import Button from "../Button";
import DropdownWrapper from "../Dropdown/Wrapper";
import DropdownMenuLink from "../Dropdown/Link";


// Layouts
import AccordionTable from "../../layout/AccordionTable";


// Endpoints
import { getOne } from "../../endpoints/getOne";
import { create } from "../../endpoints/create";


// Helpers
import { queryParamsFormatter } from "../../helpers/queryParamsFormatter";
import { BASE_URL } from "../../endpoints";
import DropdownDownloadLink from "../Dropdown/DownloadLink";
import { useWindowDimensions } from "../../hooks/useDimensions";
import { useEffect } from "react";
import CheckBoxInput from "../CheckboxInput";
import { PrecioFormatter } from "../../helpers/precioFormatter";
import { renderRecargoInstalacion } from "../../helpers/renderRecargoInstalacion";
import Modal from "../Modal";
import FormInput from "../FormInput";


const CONSTANT = {
  pathUrl: '/cotizaciones',
  permissionsPath: 'cotizaciones',
  queryUrlGetOne: getOne.cotizaciones,
  queryEnviarCot: create.cotizaciones + `/enviar`,
  title: 'Cotizaciones',
  reduxClearFilters: clearFilterCotizaciones,
  reduxSetResults: resultadosCotizaciones,

  modalInitialState: { data: null, display: false },

  modalTarget: {
    productos: 'productos',
    enviar_cot: 'enviar-cot'
  },

  acordeones: { productos: false }
}

const Precio = (v) => new PrecioFormatter(v).producto()


const CotizacionesTable = ({ handleValue = false, selected = false }) => {
  const { resultados: response, filtros } = useSelector(state => state.cotizaciones)
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const { cliente, cotizacion_id, desde, hasta } = filtros
  const { user } = useSelector(state => state.auth)
  const [acordeones, setAcordeones] = useState(CONSTANT.acordeones)
  const { width } = useWindowDimensions()
  const [selectedCell, setSelectedCell] = useState(false)
  const [modal, setModal] = useState(CONSTANT.modalInitialState)

  const [isConsulting, setIsConsulting] = useState(false)


  useEffect(() => {
    if (!response?.data?.length) return

    setSelectedCell(response?.data?.find(({ id }) => id === selected))
  }, [selected, response?.data])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target
    const [target, key] = name.split('-')

    if (target === 'enviar_cot') {
      return setModal({
        ...modal,
        data: {
          ...modal.data,
          [key]: value
        }
      })
    }
  }


  // FUNCION MANEJADORA DE PAGINACIÓN
  const handleUrl = (url) => {
    dispatch(CONSTANT.reduxSetResults(null))
    axios(url + `&limit=${showing_results}` + queryParamsFormatter({ cliente, cotizacion_id, desde, hasta }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST'
      }
    )
      .then(res => dispatch(CONSTANT.reduxSetResults(res.data)))
      .catch(err => console.log(err))
  }


  // FUNCIÓN MANEJADORA PARA BORRAR UN REGISTRO
  const handleFetch = async (id, url, target) => {
    setIsConsulting(true)

    let config
    let query = CONSTANT.queryUrlGetOne(id)

    if (target === CONSTANT.modalTarget.enviar_cot) {
      query = CONSTANT.queryEnviarCot
      config = {
        method: 'POST',
        data: {
          ...modal.data,
          totalizacion: modal.data.totalizacion ? 1 : 0
        }
      }
    }

    if (target === 'delete') {
      if (!window.confirm('¿Está seguro que desea eliminar el registro?')) return
      config = {
        method: 'DELETE'
      }
    }

    config = {
      ...config,
      headers: {
        ...config.headers,
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    }

    await axios(query, config)
      .then(res => {
        if (target === 'delete') {
          const toasterContent = {
            title: 'Operación realizada',
            text: `${CONSTANT.title}: registro eliminado con éxito`,
            icon: 'info'
          }

          dispatch(fireToaster(toasterContent))
        }

        dispatch(CONSTANT.reduxSetResults(null))
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
         Operación fallida
         (${err.response.status} - ${err.response.statusText})
       `,
          html: `
         <b>Detalle: </b>
         ${detalles.map(error => `<br /><i>-${error}</i>`)}
       `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(async () => {
        await axios(url, {
          headers: {
            'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
          },
          method: 'POST'
        })
          .then(res => {
            setModal(CONSTANT.modalInitialState)
            dispatch(CONSTANT.reduxSetResults(res.data))
          })
          .catch(err => console.error(err))

        setIsConsulting(false)
      })
  }


  // FUNCION MANEJADORA DE LOS ACORDEONES
  const handleInnerAccordion = (value, target) => {
    setAcordeones({ ...acordeones, [target]: !value })
  }


  const handleModal = (data, target) => {
    if (target === CONSTANT.modalTarget.productos) {
      return setModal({ display: target, data })
    }

    if (target === CONSTANT.modalTarget.enviar_cot) {
      return setModal({ display: target, data: { correo: data?.usuario?.email ?? data?.email_cliente, cotizacion_id: data?.id, totalizacion: 0 } })
    }
  }


  return (
    <>
      <div className='d-flex gap-1 justify-content-end mb-50'>
        <div className='w-fit'>
          <Button onClick={() => handleInnerAccordion(acordeones.productos, 'productos')} text='Productos' icon={acordeones.productos ? 'Minimize2' : 'Maximize2'} />
        </div>
      </div>

      <Modal size='w-100' handleValue={() => setModal({ data: null, display: false })} display={modal.display}>
        {modal.display === CONSTANT.modalTarget.productos &&
          <>
            <h3>Productos de cotización #{modal?.data?.id}</h3>
            <hr className="mt-25 mb-2" />
            <AccordionTable title={`Productos (${modal?.data?.total_productos})`} classes='my-3'>
              <Table pb={false}>
                <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                  <tr>
                    <th>Descripción</th>
                    <th>SKU</th>
                    <th>Qty</th>
                    <th>P. unit.</th>
                    <th>P. totales </th>
                  </tr>
                </thead>
                <tbody>
                  {modal?.data?.lineas_cotizacion.map(cotizacion => (
                    <tr key={`cotizacion-${cotizacion?.id}-${cotizacion?.producto?.id}`}>
                      <td>
                        <a href={`/productos/detalle/${cotizacion?.producto?.id}`} target="_blank" rel="noreferrer">
                          {cotizacion?.producto?.nombre.toUpperCase() ?? "N/A"}
                        </a>
                      </td>

                      <td className="fw-bold">{cotizacion?.producto?.sku ?? "N/A"}</td>

                      <td className="fw-bold">{cotizacion?.cantidad_cotizada ?? "N/A"}</td>

                      <td>
                        <div className="d-flex flex-column">

                          <span className="fw-bolder fs-3 text-black">
                            {Precio(cotizacion?.precios_mostrar?.precio_unitario)}
                          </span>

                          <span style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            Neto: {Precio(cotizacion?.precios_mostrar?.precio_unitario_neto)}
                          </span>

                          <span style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            Inst.: {renderRecargoInstalacion(cotizacion?.precio_instalacion)}
                          </span>

                          <span style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            Recargo: {renderRecargoInstalacion(cotizacion?.recargo)}
                          </span>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex flex-column">

                          <span className="fw-bolder fs-3 text-black">
                            {Precio(cotizacion?.subtotal)}
                          </span>

                          <span style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            Neto: {Precio(cotizacion?.subtotal_neto)}
                          </span>

                          <span style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            Inst.: {cotizacion?.precio_instalacion === null
                              ? renderRecargoInstalacion(cotizacion?.precio_instalacion)
                              : renderRecargoInstalacion(cotizacion?.precio_instalacion * cotizacion?.cantidad_cotizada)
                            }
                          </span>

                          <span style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            Recargo: {cotizacion?.recargo === null
                              ? renderRecargoInstalacion(cotizacion?.recargo)
                              : renderRecargoInstalacion(cotizacion?.recargo * cotizacion?.cantidad_cotizada)
                            }
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </AccordionTable>
          </>
        }

        {modal.display === CONSTANT.modalTarget.enviar_cot &&
          <>
            <h4>Enviar cotización #{modal?.data?.cotizacion_id ?? 'Error'}</h4>

            <hr className="mt-25" />

            <CheckBoxInput
              name={`enviar_cot-totalizacion`}
              handleValue={handleInputChange}
              labelText='Agregar totales'
              value={modal?.data?.totalizacion ?? 0}
            />

            <FormInput
              type='Email'
              name='enviar_cot-correo'
              labelText="Correo"
              placeholder="Email"
              size="col-12"
              sizeDesk="col-md-6"
              classes='mt-1'
              handleValue={handleInputChange}
              value={modal?.data?.correo ?? ''}
            />

            <div>
              <Button disabled={isConsulting} text="Enviar" className='m-auto' onClick={(e) => handleFetch({}, response.path + '?page=' + response.current_page + `&limit=${showing_results}` + queryParamsFormatter({ cliente, cotizacion_id, desde, hasta }), CONSTANT.modalTarget.enviar_cot)} />
            </div>
          </>

        }
      </Modal>

      {(width > 500)
        // ESCRITORIO
        ? <AccordionTable title={`Listado de cotizaciones (${response?.data?.length} resultados)`} classes={'mb-2'}>
          <Table response={response} handlePagination={handleUrl}>
            <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
              <tr className="text-center" role="row">
                <th>ID</th>
                <th>Pedido</th>
                <th>Cliente</th>
                <th>Punto entrega</th>
                <th>Generales</th>
                <th>Total</th>
                <th>Vigencia</th>
                <th>Acc.</th>
              </tr>
            </thead>
            <tbody>
              {response?.data?.map((data, i) => {
                return (
                  <tr
                    style={{ backgroundColor: i % 2 > 0 ? '#e8eaeb' : '#fff', borderBottom: '1px solid #555' }}
                    className={`odd multi-data text-center listado-focus`}
                    key={`tr-${data?.id}-${data.email_cliente}`}
                  >
                    <td className='py-4'>
                      <div className="d-flex flex-column">
                        <b className="fs-2">{data?.id ?? "N/A"}</b>
                        {handleValue &&
                          <CheckBoxInput
                            name={`check-${data?.id}`}
                            handleValue={handleValue}
                            labelText=''
                            value={selectedCell?.id === data?.id ? 1 : 0}
                          />
                        }
                        <span className="mt-1">{data?.created_at.split('T')[0] ?? 'N/A'}</span>
                        <b>{data?.ejecutivo?.nombre ?? "N/A"}</b>
                      </div>
                    </td>

                    <td className="text-black">
                      <div>
                        {data?.pedido?.id
                          ? <div>
                            <p className="fs-4 py-25">
                              <b>{data?.pedido?.id ?? "N/A"}</b>
                            </p>
                            <p className="py-25">{data?.pedido?.created_at?.split('T')[0] ?? "N/A"}</p>
                            <p className="tag success px-25">{data?.pedido?.estatus_pedido?.nombre ?? "N/A"}</p>
                          </div>
                          : <span className="tag px-50 red text-nowrap">Sin pedido</span>
                        }
                      </div>
                    </td>

                    <td className="px-50">
                      <div className='d-flex flex-column'>
                        <b>{data?.usuario?.nombre ?? data?.nombre_cliente ?? "Sin nombre"}</b>
                        <span>{data?.usuario?.email ?? data?.email_cliente ?? "Sin email"}</span>
                        <span>{data?.usuario?.rut ?? data?.rut_empresa ?? "Sin RUT"}</span>
                        <span>{data?.usuario?.fono ?? data?.telefono_cliente ?? "Sin tlf."}</span>
                        <p className={`tag px-50 ${data?.frontend?.id === 2 ? 'bridgestone' : 'chileneumaticos'} mx-auto mt-50`}>{data?.frontend?.nombre ?? 'chileneumaticos'}</p>
                      </div>
                    </td>

                    <td className="px-50">
                      <div className='d-flex flex-column'>
                        <div className='d-flex flex-column'>
                          <span className='text-black'><b>{data?.punto_entrega?.direccion ?? "N/A"}</b></span>
                          <span className='text-black'>{data?.punto_entrega?.metodo_entrega?.nombre ?? "N/A"}</span>
                          <span className='text-black'>{data?.punto_entrega?.comuna?.nombre ?? "N/A"}</span>
                        </div>

                        <DropdownWrapper icon={"Plus"} classes="w-fit mx-auto my-auto" buttonClasses={'p-25 mt-25'}>
                          <DropdownMenuLink text={data?.punto_entrega?.comuna?.region?.nombre ?? 'N/A'} icon="Tag" style={{ cursor: 'initial' }} />
                          <DropdownMenuLink text={data?.punto_entrega?.tipo_destino?.nombre ?? 'N/A'} icon="Tool" style={{ cursor: 'initial' }} />
                        </DropdownWrapper>
                      </div>
                    </td>

                    <td>
                      <div className="d-flex flex-column">
                        <p>QTY: <b>{data?.total_productos ?? "N/A"}</b></p>
                        <DropdownWrapper icon={"Plus"} classes="w-fit mx-auto my-auto" buttonClasses={'p-25 mt-25'}>
                          <DropdownMenuLink text={<span>Método de pago: <b>{data?.metodo_pago?.nombre ?? "N/A"}</b></span>} icon="CreditCard" style={{ cursor: 'initial' }} />
                          <DropdownMenuLink text={<span>Facturación: <b>{data?.tipo_facturacion?.nombre ?? "N/A"}</b></span>} icon="DollarSign" style={{ cursor: 'initial' }} />
                          <DropdownMenuLink text={<span>Atención: <b>{data?.tipo_atencion?.nombre ?? "N/A"}</b></span>} icon="User" style={{ cursor: 'initial' }} />
                        </DropdownWrapper>

                        <Button onClick={() => handleModal(data, CONSTANT.modalTarget.productos)} text='Productos' icon='Plus' className='mt-1 p-50' />
                      </div>
                    </td>

                    <td>
                      <b className="fs-3">{Precio(data?.monto_total)}</b>
                      <p>Impuestos: <span className="text-nowrap">{Precio(data?.monto_impuesto)}</span></p>
                      <p>Subtotal: <span className="text-nowrap">{Precio(data?.monto_subtotal)}</span></p>
                    </td>

                    <td className="px-25">
                      {data?.fecha_vigente_hasta
                        ? <p className="tag dark-blue mx-auto px-50">
                          <b> {data?.fecha_vigente_hasta.split(' ')[0]} </b>
                        </p>
                        : <p className="tag dark-blue px-25 mx-auto">
                          <b>N/A</b>
                        </p>
                      }
                    </td>

                    <td>
                      <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                        {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                          <DropdownMenuLink text='Detalle/Edición' href={`${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
                        }

                        {user.permisos[`${CONSTANT.permissionsPath}.exportar`] &&
                          <>
                            <DropdownDownloadLink
                              text='PDF'
                              icon="FileText"
                              href={`${BASE_URL}admin/v1/cotizaciones/${data?.id}/pdf`}
                            />

                            <DropdownDownloadLink
                              text='PDF (con totales)'
                              icon="FileText"
                              href={`${BASE_URL}admin/v1/cotizaciones/${data?.id}/pdf?totalizacion=1`}
                            />
                          </>
                        }

                        {user.permisos[`${CONSTANT.permissionsPath}.crear`] &&
                          <DropdownMenuLink
                            text='Enviar'
                            icon="Mail"
                            onClick={() => handleModal(data, CONSTANT.modalTarget.enviar_cot)}
                          />
                        }
                      </DropdownWrapper>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </AccordionTable>

        // MOVIL
        : <AccordionTable title={`Listado de cotizaciones (${response?.data?.length ?? 0} resultados)`}>
          <Table response={response} pb={false}>
            <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
              <tr className="text-center" role="row">
                <th>Datos</th>
                <th>Fecha</th>
              </tr>
            </thead>
            <tbody>
              {response?.data?.map((data, i) => {
                return (
                  <React.Fragment key={`tr-${data?.id}-${data.email_cliente}`}>
                    <tr
                      style={{ borderBottom: 'transparent', backgroundColor: i % 2 > 0 ? '#dadfe3' : '#fff' }}
                      className={`odd multi-data text-center`}
                    >
                      <td>
                        <div className="d-flex flex-column" style={{ width: '150px' }}>
                          {handleValue &&
                            <CheckBoxInput
                              name={`check-${data?.id}`}
                              handleValue={handleValue}
                              labelText=''
                              value={selectedCell?.id === data?.id ? 1 : 0}
                            />
                          }
                          <b className="w-fit">ID: {data?.id ?? "N/A"}</b>

                          <div className="w-fit mt-25">
                            {data?.pedido?.id
                              ? <div className="border-primary p-50 rounded-3">
                                <p className="fs-4 py-25">
                                  Pedido: <b>{data?.pedido?.id ?? "N/A"}</b>
                                </p>
                                <p className="py-25">{data?.pedido?.created_at?.split('T')[0] ?? "N/A"}</p>
                                <p className="tag success px-25">{data?.pedido?.estatus_pedido?.nombre ?? "N/A"}</p>
                              </div>
                              : <span className="tag px-25 red text-nowrap">Sin pedido asignado</span>
                            }
                          </div>

                          <div className="text-start mt-50">
                            <b>{data?.usuario?.nombre ?? data?.nombre_cliente ?? "N/A"}</b>
                            <p style={{ wordBreak: 'break-all' }}>{data?.usuario?.email ?? data?.email_cliente ?? "N/A"}</p>
                            <p>{data?.usuario?.rut ?? data?.rut_empresa ?? "N/A"}</p>
                          </div>
                        </div>
                      </td>

                      <td>
                        <p>Creación</p>
                        <b>{data?.created_at.split('T')[0] ?? 'N/A'}</b>
                        <div className="border border-primary rounded-2 my-50 py-50 px-25">
                          <p>Vigencia</p>
                          {data?.fecha_vigente_hasta
                            ? <p className="tag dark-blue mx-auto p-25">
                              <b> {data?.fecha_vigente_hasta.split(' ')[0]} </b>
                            </p>
                            : <p className="tag dark-blue px-50 mx-auto">
                              <b>N/A</b>
                            </p>
                          }
                        </div>
                      </td>
                    </tr>

                    <tr className={`odd multi-data`}
                      style={{ backgroundColor: i % 2 > 0 ? '#f5f5f5' : '#fff' }}>
                      <td colSpan={2}>
                        <div className="d-flex flex-column">
                          <div className="d-flex gap-1">
                            <b>{data?.punto_entrega?.direccion ?? "N/A"}</b>
                            <p>Tipo destino: <b>{data?.punto_entrega?.tipo_destino?.nombre ?? "N/A"}</b></p>
                          </div>

                          <div className="d-flex gap-1">
                            <p>{data?.punto_entrega?.descripcion ?? "N/A"}</p>
                            <p>Método entrega: <b>{data?.punto_entrega?.metodo_entrega?.nombre ?? "N/A"}</b></p>
                          </div>
                        </div>

                        <div style={{ borderTop: '1px solid #444' }} className="d-flex gap-1">
                          <b>Total: ${data?.monto_total ?? "N/A"}</b>
                          <p>Impuestos: ${data?.monto_impuesto ?? "N/A"}</p>
                          <p>Subtotal: ${data?.monto_subtotal ?? "N/A"}</p>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </AccordionTable >
      }


    </>
  );
};

export default CotizacionesTable;

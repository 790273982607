import {
   FILTER_ADD_ESTATUS,
   FILTER_REMOVE_ESTATUS,
   FILTER_CLEAR,
   FILTER_CREACION_DESDE,
   FILTER_BUSQUEDA,
   FILTER_CREACION_HASTA,
   RESULTADOS_CINTA_INFORMATIVA,
   FILTER_REMOVE_TEXTO,
   FILTER_ADD_TEXTO,
   FILTER_REMOVE_FRONTEND,
   FILTER_ADD_FRONTEND
} from "../redux-types";


export const estatusFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_ESTATUS, payload: data }
      : { type: FILTER_ADD_ESTATUS, payload: data };

export const textoFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_TEXTO, payload: data }
      : { type: FILTER_ADD_TEXTO, payload: data };

export const frontendFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_FRONTEND, payload: data }
      : { type: FILTER_ADD_FRONTEND, payload: data };

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const creacionDesdeFilter = (data) => ({ type: FILTER_CREACION_DESDE, payload: data })

export const creacionHastaFilter = (data) => ({ type: FILTER_CREACION_HASTA, payload: data })

export const resultadosCintaInformativa = (data) => ({ type: RESULTADOS_CINTA_INFORMATIVA, payload: data })

export const clearFilterCintaInformativa = () => ({ type: FILTER_CLEAR });

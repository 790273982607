import { useEffect, useState } from 'react'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import CustomLoader from '../../components/CustomLoader'


// Router Dom
import { useNavigate, useParams } from 'react-router-dom'


// Endpoints
import { historicoData } from '../../helpers/historicoData'
import { ModalHistorico } from '../../components/Historico/ModalHistorico'
import TablaDetalle from '../../components/Historico/TablaDetalle'
import { getDetail } from '../../services/empresas/getDetail.service'
import Tabs from '../../components/Tabs'


const CONSTANT = {
   title: 'Empresas',
   redirectListado: '/empresas',
   modelo: 'App\\Models\\NeuEmpresa',
   tabs: [
      { name: 'Informacion', url: '?tab=informacion' },
      { name: 'Cuenta corriente', url: '?tab=cuenta-corriente' }
   ]
}


export const Detalle = () => {
   const [data, setData] = useState(null)
   const { id } = useParams()
   const dispatch = useDispatch()
   const redirect = useNavigate()
   const [historico, setHistorico] = useState(null)
   const [modal, setModal] = useState({ id: null, display: false, data: null })


   // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
   useEffect(() => {
      getDetail(id).then(res => {
         if (res.status >= 400) throw res

         setData(res.data)
      })
         .catch((err) => {
            console.error(err)
            const toasterContent = {
               title: 'Registro no encontrado',
               icon: 'warning'
            }

            dispatch(fireToaster(toasterContent))
            redirect(CONSTANT.redirectListado)
         })

      historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
      return () => {
         setData(null)
      }
   }, [id, dispatch, redirect])



   // FUNCION MANEJADORA DE LA DATA DEL MODAL
   const handleModal = (data) => {
      const { id, anterior, nuevo } = data

      setModal({
         display: true,
         id,
         data: {
            anterior,
            nuevo
         }
      })
   }


   return (
      <div className='row'>

         <ModalHistorico modal={modal} fnHandleModal={handleModal} />

         <div className='col-12'>
            <div className='col mb-50'>
               <div className='d-flex gap-1'>
                  <div className='col'>
                     <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
                  </div>
               </div>

               <div className='mt-1'>
                  <Tabs tabs={CONSTANT.tabs} />
               </div>
            </div>

            <div className='row'>
               {data
                  ? <>
                     <Accordion
                        title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
                        classes='col-md-6 mx-auto mb-2'
                        isOpen
                     >
                        <AccordionDetailData dataKey={'ID'} value={data?.id} />
                        <AccordionDetailData dataKey={'Tipo de empresa'} value={data?.tipo_empresa?.toUpperCase() ?? 'Nueva empresa'} />
                        <AccordionDetailData dataKey={'Razón social'} value={data?.razon_social ?? 'N/A'} />
                        <AccordionDetailData dataKey={'RUT'} value={data?.rut ?? 'N/A'} />
                        <AccordionDetailData dataKey={'% Descuento'} value={data?.porcentaje_descuento ?? 'N/A'} />
                        <AccordionDetailData dataKey={'% Profit'} value={data?.profit ?? 'N/A'} />
                        <AccordionDetailData dataKey={'Creación'} value={data?.created_at?.split('T')[0] ?? 'N/A'} />
                        <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at?.split('T')[0] ?? 'N/A'} last />
                     </Accordion>

                     {data?.tiene_datos_factura
                        ? <Accordion
                           title={<h3 className='m-0 w-100 text-center text-white'>Datos de facturacion</h3>}
                           classes='col-md-6 mx-auto mb-2'
                           isOpen
                        >
                           <AccordionDetailData dataKey={'ID'} value={data?.datos_factura?.id} />
                           <AccordionDetailData dataKey={'Giro'} value={data?.datos_factura?.empresa_giro ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Dirección'} value={data?.datos_factura?.empresa_direccion ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Creación'} value={data?.datos_factura?.created_at?.split('T')[0] ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Modicación'} value={data?.datos_factura?.updated_at?.split('T')[0] ?? 'N/A'} last />
                        </Accordion>

                        : <span>Esta empresa aún no tiene datos de facturación</span>
                     }

                     <TablaDetalle historicoData={historico} fnHandleModal={handleModal} />
                  </>

                  : <CustomLoader />
               }
            </div>
         </div>
      </div>
   )
}

import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_DISTRITOS,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosDistritos = (data) => ({ type: RESULTADOS_DISTRITOS, payload: data })

export const clearFilterDistritos = () => ({ type: FILTER_CLEAR });

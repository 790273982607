
// Components
import PasosCompraFiltros from "../../components/AccordionFilter/PasosCompra";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import PasosCompraTable from "../../components/Tables/PasosCompraTable";

const CONSTANT = {
	title: 'Pasos de compra',
	path: 'pasos-compra',
	create_btn: 'Crear paso de compra'
}

const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/template/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<PasosCompraFiltros />

			<PasosCompraTable />
		</div>
	);
}

export default Listado;
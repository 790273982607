import {
   FILTER_CLEAR,
   FILTER_BUSQUEDA,
   RESULTADOS_METODOS_ENTREGA
} from "../redux-types";


export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosMetodosEntrega = (data) => ({ type: RESULTADOS_METODOS_ENTREGA, payload: data })

export const clearFilterMetodosEntrega = () => ({ type: FILTER_CLEAR })


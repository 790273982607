export const isValidCreateForm = (form) => {
  const stringValuesArr = ['nombre', 'sku', 'descripcion', "meta-descripcion", "meta-titulo", "datos-estructurados"]
  const intValuesArr = ['precio_final', 'ultimo_costo']
  const boolValuesArr = ['nuevo', 'es_variante', 'publicado', 'mostrar_en_cliente']

  let result = { isValid: true }

  // required values
  if (
    !form?.categoria_id
    || !form?.nombre
    || !form?.sku
    || !form?.descripcion
    || !form?.precio_final
    || !form?.["meta-descripcion"]
    || !form?.["meta-titulo"]
    || !form?.["datos-estructurados"]
  ) {
    result = { ...result, isValid: false }
  }


  Object.entries(form).forEach(([k, v]) => {

    // strings
    if (stringValuesArr.includes(k) && typeof v === 'string' && !v?.length) {
      result = {
        ...result,
        [k]: `[${typeof v}], length: ${v?.length ?? 0}`,
        isValid: false
      }
      return
    }

    // int
    if (intValuesArr.includes(k) && typeof v === 'number' && v < 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
        isValid: false
      }
      return
    }

    // bool
    if (boolValuesArr.includes(k) && typeof v === 'number' && v < 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
        isValid: false
      }
      return
    }
  })

  return result
}

export const isValidUpdateForm = (form) => {
  const stringValuesArr = ['nombre', 'sku', 'descripcion', "meta-descripcion", "meta-titulo", "datos-estructurados"]
  const intValuesArr = ['precio_final', 'ultimo_costo']
  const boolValuesArr = ['nuevo', 'es_variante', 'publicado', 'mostrar_en_cliente']

  let result = { isValid: true }

  // required values
  if (
    !form?.categoria_id
    || !form?.nombre
    || !form?.sku
    || !form?.descripcion
    || !form?.precio_final
    // || !form?.imagenes?.length
    || !form?.["meta-descripcion"]
    || !form?.["meta-titulo"]
    || !form?.["datos-estructurados"]
  ) {
    result = { ...result, isValid: false }
  }


  Object.entries(form).forEach(([k, v]) => {

    // strings
    if (stringValuesArr.includes(k) && typeof v === 'string' && !v?.length) {
      result = {
        ...result,
        [k]: `[${typeof v}], length: ${v?.length ?? 0}`,
        isValid: false
      }
      return
    }

    // int
    if (intValuesArr.includes(k) && typeof v === 'number' && v < 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
        isValid: false
      }
      return
    }

    // bool
    if (boolValuesArr.includes(k) && typeof v === 'number' && v < 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
        isValid: false
      }
      return
    }
  })

  return result
}
const capitalize = (str) => {
   if (typeof str !== 'string' && typeof str !== 'number') {
      console.error(new Error(`Can't capitalize '${typeof str}'. Expected parameter typeof 'string'`));
      return `[${typeof str}]`;
   };
   const firsLetter = String(str).charAt(0).toUpperCase();
   const restLetters = String(str).slice(1);
   return firsLetter + restLetters;
}

export default capitalize
import { useEffect, useState } from 'react';


// Components
import Icon from './Icon';


// Helpers
import { sanitaze } from '../helpers/numberShorter';


const CardInfo = ({ text, icon, value, classes = "col-6 col-md-3 mb-2 mb-xl-0" }) => {

   const [sanitazedValue, setSanitazedValue] = useState(null);

   useEffect(() => {
      setSanitazedValue(sanitaze(value));
   }, [value]);

   return (
      <div className={classes}>
         <div className="d-flex flex-column align-items-center flex-xxl-row">
            <div className="avatar bg-light-primary me-xxl-2 mb-50 mb-xxl-0">
               <div className="avatar-content">
                  {
                     icon && (
                        <Icon icon={icon} />
                     )
                  }
               </div>
            </div>

            <div className="my-auto">
               <h4 className="fw-bolder mb-0 text-center">{sanitazedValue}</h4>

               <p className="card-text font-small-3 mb-0 text-center">{text}</p>
            </div>
         </div>
      </div>
   );
};


export default CardInfo;
import React from 'react'
import { useLocation } from 'react-router-dom'
import NotasVentasFiltros from '../../../components/AccordionFilter/NotasVentas'
import NotasVentasTable from '../../../components/Tables/NotasVentasTable'
import Button from '../../../components/Button'
import { useSelector } from 'react-redux'


const CONSTANT = {
  'notas-ventas': {
    title: 'Notas de venta',
    path: 'erp/notas-ventas',
    create_btn: 'Crear nota de venta',
    render: <>
      <NotasVentasFiltros />
      <NotasVentasTable />
    </>
  }
}


const Listado = () => {
  const { pathname } = useLocation()
  const path = pathname.split('/')[2]
  const { user } = useSelector(state => state.auth)

  return (
    <div>
      <h3 className="mb-1 d-flex justify-content-between">
        <p><b>{CONSTANT[path?.length ? path : 'listado'].title}</b> - Listado</p>
        {user?.permisos['planificar.listar'] &&
          <div className='w-fit'>
            <Button text='Planificación' className='m-auto p-50' color='success' url={`/erp/planificacion`} isLink />
          </div>
        }
      </h3>


      {CONSTANT[path?.length ? path : 'listado'].render}
    </div>
  )
}

export default Listado
import {
   FILTER_ADD_ESTATUS,
   FILTER_ADD_METODO_PAGO,
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   FILTER_CREACION,
   FILTER_REMOVE_ESTATUS,
   FILTER_REMOVE_METODO_PAGO,
   RESULTADOS_METODOS_PAGO
} from "../redux-types";

const initialState = {
   filtros: {
      busqueda: '',
      metodo_pago: [],
      estatus: [],
      creacion: ''
   },
   resultados: null
}


export const metodosPagoReducer = (state = initialState, action) => {
   const isListedMetodoPago = state.filtros.metodo_pago.find(el => el === action.payload)
   const isListedEstatus = state.filtros.estatus.find(el => el === action.payload)

   switch (action.type) {
      case FILTER_ADD_METODO_PAGO:
         return {
            ...state, filtros: {
               ...state.filtros,
               metodo_pago: isListedMetodoPago
                  ? state.filtros.metodo_pago
                  : [...state.filtros.metodo_pago, action.payload]
            }
         };

      case FILTER_REMOVE_METODO_PAGO:
         return {
            ...state, filtros: {
               ...state.filtros,
               metodo_pago: state.filtros.metodo_pago.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_ESTATUS:
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus: isListedEstatus
                  ? state.filtros.estatus
                  : [...state.filtros.estatus, action.payload]
            }
         };

      case FILTER_REMOVE_ESTATUS:
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus: state.filtros.estatus.filter(el => el !== action.payload)
            }
         };

      case FILTER_CREACION:
         return {
            ...state, filtros: {
               ...state.filtros,
               creacion: action.payload
            }
         };

      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda: action.payload
            }
         };

      case RESULTADOS_METODOS_PAGO:
         return {
            ...state,
            resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
import { useEffect, useState } from "react";
import axios from "axios";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import CheckBoxInput from "../../components/CheckboxInput";
import TextareaInput from "../../components/TextareaInput";
import IconPicker from "../../components/IconPicker";
import SelectFilter from "../../components/SelectFilter";


// Hooks / Helpers
import { useForm } from "../../hooks/useForm";
import { iconSanatizerFaMdi } from "../../helpers/iconSanatizerFaMdi";


// React-router
import { useNavigate } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";
import { getAll } from "../../endpoints/getAll";


const CONSTANT = {
  redirectUrl: '/productos/claves-atributos',
  componentTitle: 'Crear clave/atributo',
  title: 'Claves/Atributos',
  queryUrl: create.claves_atributos,
  queryFetchCategorias: getAll.categorias + '/buscar',
  fetchClaves: getAll.claves
}


const initialformState = {
  categoria_id: '',
  descripcion: '',
  informacion: '',
  nombre: '',
  posicion_en_nombre: undefined,

  //valores de Switch
  atributo_filtrable: true,
  atributo_comparable: true,
  incluir_en_nombre: true,
  incluir_en_descripcion: true,
  incluir_en_especificaciones: true,
  incluir_en_busqueda: true,
  incluir_en_comparacion: true,
  incluir_en_url: true,
  valor_unico_producto: true,
  valor_obligatorio: true,

  // valores de IconPicker
  icono_libreria: null,
  icono_fuente: null,
  icono_icono: null,

  estatus: true
}

const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [icono, setIcono] = useState(null)
  const [categorias, setCategorias] = useState(null)
  const [claves, setClaves] = useState(null)

  const [{
    categoria_id,
    descripcion,
    informacion,
    posicion_en_nombre,
    nombre,

    //valores de Switch
    atributo_filtrable,
    atributo_comparable,
    incluir_en_nombre,
    incluir_en_descripcion,
    incluir_en_especificaciones,
    incluir_en_busqueda,
    incluir_en_comparacion,
    valor_unico_producto,
    incluir_en_url,
    valor_obligatorio,

    estatus, }, handleInputChange, reset] = useForm(initialformState);

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RELLENA EL SELECTFILTER DE CATEGORIAS
  useEffect(() => {
    axios(CONSTANT.queryFetchCategorias,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        }, method: 'POST'
      })
      .then(({ data }) => setCategorias(data.categorias))
      .catch(err => console.error(err))

    axios(CONSTANT.fetchClaves,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => setClaves(data))
      .catch(err => console.error(err))
  }, [])


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    if (!icono) return dispatch(fireToaster({ title: 'El ícono es obligatorio', icon: 'warning' }))

    const sanitazedIcon = iconSanatizerFaMdi(icono)

    let data = {
      categoria_id: Number(categoria_id.split('-')[0]),
      descripcion,
      informacion,
      nombre_mostrar: nombre.split('-')[0],
      posicion_en_nombre: Number(posicion_en_nombre),

      //valores de Switch
      atributo_filtrable,
      atributo_comparable,
      incluir_en_nombre,
      incluir_en_descripcion,
      incluir_en_especificaciones,
      incluir_en_busqueda,
      incluir_en_comparacion,
      incluir_en_url,
      valor_unico_producto,
      valor_obligatorio,

      // valores de IconPicker
      icono_libreria: sanitazedIcon?.libreria,
      icono_fuente: sanitazedIcon?.fuente ?? null,
      icono_icono: sanitazedIcon?.icono,

      estatus: Number(estatus) ? true : false
    }

    setIsConsulting(true)

    axios(CONSTANT.queryUrl, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'application/json'
      },
      data
    })
      .then(({ data }) => {
        reset()

        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data?.nombre} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() =>
        setIsConsulting(false)
      )
  }

  return (
    <div className="row">
      < div className="col col-md-6 mx-auto" >
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>
        <CustomForm size="" title={CONSTANT.componentTitle}>
          <div className='row'>
            <Switch
              name='estatus'
              value={estatus}
              labelText='Estatus'
              handleValue={handleInputChange}
              size='col-12'
            />

            <div className="col-12 col-md-6">
              <SelectFilter
                name='categoria_id'
                value={categoria_id?.split('-')[1] ?? 'Seleccione'}
                labelText='* Categoria'
                handleValue={handleInputChange}
                optionObj={categorias?.map(({ id, nombre }) => ({ id, name: nombre }))}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <SelectFilter
                name='nombre'
                value={nombre?.split('-')[1] ?? 'Seleccione'}
                labelText='* Nombre'
                handleValue={handleInputChange}
                optionObj={claves?.map(({ nombre }) => ({ id: nombre, name: nombre }))}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <FormInput
                value={nombre?.split('-')[0].toLowerCase() ?? ''}
                labelText='Nombre a mostrar'
                handleValue={handleInputChange}
                size='col-12'
                isDisabled
              />

              <FormInput
                type='number'
                name='posicion_en_nombre'
                value={posicion_en_nombre}
                labelText='Posición en nombre'
                handleValue={handleInputChange}
                size='col-12'
              />

              <FormInput
                name='informacion'
                value={informacion}
                labelText='Información'
                handleValue={handleInputChange}
                size='col-12'
              />
            </div>

            <div className='col-12 col-md-6'>
              <IconPicker
                labelText="* Ícono"
                value={icono}
                handleValue={setIcono}
              />

              <CheckBoxInput
                name='atributo_filtrable'
                value={atributo_filtrable}
                labelText='Atributo filtrable'
                handleValue={handleInputChange}
                classes='col-12 mb-50'
              />

              <CheckBoxInput
                name='atributo_comparable'
                value={atributo_comparable}
                labelText='Atributo comparable'
                handleValue={handleInputChange}
                classes='col-12 mb-50'
              />

              <CheckBoxInput
                name='incluir_en_nombre'
                value={incluir_en_nombre}
                labelText='Incluir en nombre'
                handleValue={handleInputChange}
                classes='col-12 mb-50'
              />

              <CheckBoxInput
                name='incluir_en_descripcion'
                value={incluir_en_descripcion}
                labelText='Incluir en descripción'
                handleValue={handleInputChange}
                classes='col-12 mb-50'
              />

              <CheckBoxInput
                name='incluir_en_especificaciones'
                value={incluir_en_especificaciones}
                labelText='Incluir en especificaciones'
                handleValue={handleInputChange}
                classes='col-12 mb-50'
              />

              <CheckBoxInput
                name='incluir_en_busqueda'
                value={incluir_en_busqueda}
                labelText='Incluir en búsqueda'
                handleValue={handleInputChange}
                classes='col-12 mb-50'
              />

              <CheckBoxInput
                name='incluir_en_url'
                value={incluir_en_url}
                labelText='Incluir en URL'
                handleValue={handleInputChange}
                classes='col-12 mb-50'
              />

              <CheckBoxInput
                name='incluir_en_comparacion'
                value={incluir_en_comparacion}
                labelText='Incluir en comparación'
                handleValue={handleInputChange}
                classes='col-12 mb-50'
              />

              <CheckBoxInput
                name='valor_unico_producto'
                value={valor_unico_producto}
                labelText='Valor único producto'
                handleValue={handleInputChange}
                classes='col-12 mb-50'
              />

              <CheckBoxInput
                name='valor_obligatorio'
                value={valor_obligatorio}
                labelText='Valor obligatorio'
                handleValue={handleInputChange}
                classes='col-12 mb-50'
              />
            </div>

            <TextareaInput
              name='descripcion'
              value={descripcion}
              labelText='Descripción'
              handleValue={handleInputChange}
              rows={2}
              size='col-12'
            />
          </div>

          <Button disabled={isConsulting} type="submit" text="Crear" color="primary" icon="Plus" onClick={(e) => handleFetch(e)} />
        </CustomForm>
      </div >
    </div >
  );
};

export default Create;

import { Navigate, Route, Routes } from "react-router-dom"
import Create from "../../views/Metadatos/Create";
import Listado from "../../views/Metadatos/Listado";
import Update from "../../views/Metadatos/Update";
import { useSelector } from "react-redux";

const MetadatosRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['metadatos.crear'] &&
            <Route path='/create' element={<Create />} />
         }

         {user.permisos['metadatos.editar'] &&
            <Route path='/update/:id' element={<Update />} />
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}

export default MetadatosRouter;
import {
   FILTER_ADD_PROVEEDOR_CATEGORIA,
   FILTER_CLEAR,
   FILTER_REMOVE_PROVEEDOR_CATEGORIA,
   RESULTADOS_PRODUCTOS_PROVEEDORES,
} from "../redux-types";

export const proveedorCategoriaFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_PROVEEDOR_CATEGORIA, payload: data }
      : { type: FILTER_ADD_PROVEEDOR_CATEGORIA, payload: data }

export const resultadosProductosProveedores = (data) => ({ type: RESULTADOS_PRODUCTOS_PROVEEDORES, payload: data })

export const clearFilterProductosProveedores = () => ({ type: FILTER_CLEAR });

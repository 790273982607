/**Función que formatea la data del componente IconPicker para ser enviada correctamente y de manera limpia al endpoint.
 *formato: {
 ** libreria string
 ** fuente string || null
 ** icono string
 * }
*/
export const iconSanatizerFaMdi = (iconData) => {
   let sanitazedIcon = null

   iconData && Object.entries(iconData).forEach(([key, value]) => {
      iconData.prefix.includes('fa')
         ? sanitazedIcon = {
            ...sanitazedIcon,
            libreria: key === 'prefix' ? 'fa' : sanitazedIcon?.libreria,
            icono: key === 'path' ? value : sanitazedIcon?.icono,
            fuente: key === 'prefix' ? value : sanitazedIcon?.fuente,
         }
         : sanitazedIcon = {
            ...sanitazedIcon,
            libreria: key === 'prefix' ? 'mdi' : sanitazedIcon?.libreria,
            icono: key === 'iconName' ? value : sanitazedIcon?.icono
         }
   })

   return sanitazedIcon
}
import { useEffect, useState } from "react";
import axios from "axios";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import TextareaInput from "../../components/TextareaInput";
import FileUploaderB from "../../components/FileUploader_B";
import SelectFilter from "../../components/SelectFilter";


// Helpers
import { idExtractor } from "../../helpers/idExtractor";


// React-router
import { useNavigate } from "react-router-dom";


// Endpoints
import { getAll } from "../../endpoints/getAll";
import CustomLoader from "../../components/CustomLoader";
import { create } from "../../endpoints/create";


const CONSTANT = {
  redirectUrl: '/template/pasos-compra',
  componentTitle: 'Crear paso de compra',
  title: 'Pasos de compra',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  fetchFrontendId: getAll.frontend_id,
  queryUrl: create.pasos_compra,

  initialFormState: {
    frontend_id: '',
    titulo: '',
    paso_numero: '',
    descripcion: '',
    estatus: true
  },

  selectFilterInitialState: {
    frontendId: [],
  }
}


const handleSelectFiltersData = async () => {
  const frontendId = await axios(CONSTANT.fetchFrontendId,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { frontendId }
}


const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [imagen, setImagen] = useState([])
  const [form, setForm] = useState(CONSTANT.initialFormState);
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [isConsulting, setIsConsulting] = useState(false)


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)

    if (!form?.titulo.length) return dispatch(fireToaster({ title: 'El título es obligatorio', icon: 'info' }))
    if (!form?.paso_numero.length) return dispatch(fireToaster({ title: 'El número de paso es obligatorio', icon: 'info' }))
    if (!form?.descripcion.length) return dispatch(fireToaster({ title: 'La decripción es obligatoria', icon: 'info' }))
    if (!imagen.length) return dispatch(fireToaster({ title: 'La imagen es obligatoria', icon: 'info' }))

    let data = new FormData()
    data.append('titulo', form?.titulo)
    data.append('paso_numero', form?.paso_numero)
    data.append('icono', imagen[0].file)
    data.append('descripcion', form?.descripcion)
    data.append('estatus', Number(form?.estatus))
    !form.frontend_id.includes('*NULL*') && data.append('frontend_id', idExtractor(form?.frontend_id))

    // for (const [k, v] of data) {
    //   console.log(k, v);
    // }
    // return TODO: borrar

    axios(CONSTANT.queryUrl, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'multipart/form-data'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.titulo} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() =>
        setIsConsulting(false)
      )
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {selectFilter?.frontendId?.length
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                labelText='Estatus'
                name='estatus'
                value={form?.estatus}
                handleValue={handleInputChange}
                size='col-12'
              />

              <FormInput
                labelText='* Título'
                name='titulo'
                value={form?.titulo}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <SelectFilter
                name="frontend_id"
                value={form?.frontend_id?.split('-')[1] ?? ''}
                labelText="* Frontend"
                optionObj={selectFilter.frontendId.map(({ id, nombre }) => ({ id, name: nombre }))}
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='* Número de paso'
                name='paso_numero'
                value={form?.paso_numero}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <TextareaInput
                labelText='* Descripción'
                name='descripcion'
                value={form?.descripcion}
                handleValue={handleInputChange}
                rows={2}
                size='col-12'
              />

              <FileUploaderB labelText='* Ícono' handleValue={setImagen} value={imagen} />

            </div>

            <Button disabled={isConsulting} type="submit" text="Crear" color="primary" icon="Plus" onClick={(e) => handleFetch(e)} />
          </CustomForm>

          : <CustomLoader />
        }
      </div>
    </div>
  );
};

export default Create;

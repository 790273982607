import React from 'react';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { resultadosAdicionales } from '../../redux/actions/adicionales';


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Switch from "../Switch";
import Table from "../Table";
import IconFAMDI from './Icons/IconFAMDI';


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";


// Helpers
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter';
import { useCustomFetch } from '../../hooks/useCustomFetch';
import { contractFormat } from '../../views/Adicionales/misc/contractFormat';


const CONSTANT = {
  pathUrl: '/adicionales',
  permissionsPath: 'adicionales',
  queryUrlGetOne: getOne.adicionales,
  title: 'Adicionales',
  reduxSetResults: resultadosAdicionales,
}


const AdicionalesTable = () => {
  const { resultados: response, filtros } = useSelector(state => state.adicionales)
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const [customFetch] = useCustomFetch()
  const { nombre, categoria_id } = filtros
  const { user } = useSelector(state => state.auth)


  // FUNCION MANEJADORA DE PAGINACIÓN
  const handleUrl = (url) => {
    dispatch(CONSTANT.reduxSetResults(null))
    customFetch(url + `&limit=${showing_results}` + queryParamsFormatter({ nombre }), contractFormat('listar', categoria_id))
  }


  // FUNCIÓN MANEJADORA PARA ACTUALIZAR EL ESTATUS
  const handleFetch = async (objData, url, target) => {
    await customFetch(CONSTANT.queryUrlGetOne(objData.id), contractFormat(target, objData))

    dispatch(CONSTANT.reduxSetResults(null))

    const { data } = await customFetch(url + queryParamsFormatter({ nombre }), contractFormat('listar', categoria_id))

    dispatch(CONSTANT.reduxSetResults(data?.adicionales))
  }


  return (
    <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
      {!response && <CustomLoader blocking={'partial'} />}

      <Table response={response} handlePagination={handleUrl}>
        <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
          <tr role="row">
            <th>ID</th>
            <th>Nombre</th>
            <th>Categoría</th>
            <th>Ícono</th>
            <th style={{ maxWidth: '50px' }}>Creación</th>
            <th style={{ maxWidth: '50px' }}>Estatus</th>
            <th style={{ maxWidth: '50px' }}>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {response?.data?.length
            ? response.data?.map((data) => {
              return (
                <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                  <td >{data.id}</td>
                  <td>{data?.nombre ?? 'N/A'}</td>
                  <td>{data?.categoria?.nombre ?? 'N/A'}</td>
                  <td>
                    {data?.neu_icono?.icono
                      ? <IconFAMDI iconData={data} module={data?.neu_icono?.libreria} />
                      : "N/A"
                    }
                  </td>
                  <td>{data.created_at.split('T')[0]}</td>
                  <td>
                    <Switch
                      value={data.estatus ? true : false}
                      labelText={''} withIcons
                      handleValue={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`, 'estatus')}
                    />
                  </td>
                  <td >
                    <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                      {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                        <DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
                      }

                      {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
                        <DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
                      }

                      {user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
                        <DropdownMenuLink text='Borrar' icon="X" onClick={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}` + queryParamsFormatter({ nombre }), 'delete')} />
                      }
                    </DropdownWrapper>
                  </td>
                </tr>
              )
            })
            : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
          }
        </tbody>
      </Table>
    </AccordionTable >
  )
}

export default AdicionalesTable
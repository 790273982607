import axios from 'axios';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from "../../redux/actions/toaster";


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Table from "../Table";


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { clearFilterEmpresas, resultadosEmpresas } from '../../redux/actions/empresas';
import { getAllEmpresas } from '../../services/empresas/getAllEmpresas.service';
import { filtrosFormatter } from '../AccordionFilter/Empresas';
import { useState } from 'react';
import ModalAprobarEmpresa from '../Modal/Empresas/ModalAprobarEmpresa';


const CONSTANT = {
	permissionsPath: 'empresas',
	pathUrl: '/empresas',
	queryUrlGetOne: getOne.empresas,
	title: 'Empresas',
	reduxClearFilters: clearFilterEmpresas,
	reduxSetResults: resultadosEmpresas,
}


export const EmpresasTable = () => {
	const { resultados: response, filtros } = useSelector(state => state.ciudades)
	const { showing_results } = useSelector(state => state.listedResults)
	const dispatch = useDispatch()
	const { user } = useSelector(state => state.auth)
	const [modalApproval, setModalApproval] = useState({ isOpen: false, target: null })


	// FUNCION MANEJADORA DE PAGINACIÓN
	const handleUrl = (url) => {
		console.log(url);
		dispatch(CONSTANT.reduxSetResults(null))

		getAllEmpresas(showing_results, filtrosFormatter(filtros), url).then(res => {
			if (res.status >= 400) {
				// dispatch(fireToaster(toasterErrorFormatter({ errors: res.data, response: res })))
				dispatch(CONSTANT.reduxSetResults([]))
				return
			}

			dispatch(CONSTANT.reduxSetResults(res))
		})
	}


	const handleApproval = async () => {
		setModalApproval({ ...modalApproval, isOpen: false })

		handleUrl(`${response?.path}?page=${response.current_page}`)
	}


	return (
		<>
			<ModalAprobarEmpresa data={modalApproval.target} isOpen={modalApproval.isOpen} handleClose={handleApproval} />

			<AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
				{!response && <CustomLoader blocking={'partial'} />}

				<Table response={response} handlePagination={handleUrl}>
					<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
						<tr role="row">
							<th>ID</th>
							<th>Tipo empresa</th>
							<th>Razón social</th>
							<th>RUT</th>
							<th>% Desc.</th>
							<th>% Profit</th>
							<th>Creación</th>
							<th className="w-max">Acciones</th>
						</tr>
					</thead>

					<tbody>
						{response?.data.length
							? response.data?.map((data) => {
								return (
									<tr className="odd" key={`user-${data.id}-${data.titulo}`}>
										<td className='col-1'>{data?.id}</td>
										<td>{data?.empresa_tipo?.nombre.toUpperCase() ?? "N/A"}</td>
										<td>{data?.razon_social ?? "N/A"}</td>
										<td>{data?.rut ?? "N/A"}</td>
										<td className='col-1'>{data?.porcentaje_descuento ?? "N/A"}</td>
										<td className='col-1'>{data?.profit ?? "N/A"}</td>
										<td className='col-2'>{data?.created_at?.split("T")[0] || 'N/A'}</td>

										<td className='col-1'>
											<DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
												{user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
													<DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}?tab=informacion`} icon="Table" />
												}

												{user.permisos[`${CONSTANT.permissionsPath}.aprobar`] && !data?.tipo_id &&
													<DropdownMenuLink text='Aprobar' icon="ThumbsUp" onClick={() => setModalApproval({ target: data, isOpen: true })} />
												}

												{/* {user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
												<DropdownMenuLink text='Borrar' icon="X" onClick={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`, 'DELETE')} />
											} */}
											</DropdownWrapper>
										</td>
									</tr>
								)
							})
							: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
						}
					</tbody>
				</Table>
			</AccordionTable >
		</>
	)
}
import { Navigate, Route, Routes } from "react-router-dom"

import Listado from "../views/BusquedaClientes/Listado"
import Detalle from "../views/BusquedaClientes/Detalle"
import { useSelector } from "react-redux"


const BusquedaClientesRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['busqueda-clientes.detalle'] &&
            <Route path='/busqueda-clientes/*' element={<BusquedaClientesRouter />} />
         }
         <Route path='/detalle/:id' element={<Detalle />} />

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}

export default BusquedaClientesRouter;
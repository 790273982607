import { Route, Routes } from "react-router-dom";
import Create from "../../views/TiposElementos/Create";
import Update from "../../views/TiposElementos/Update";
import Listado from "../../views/TiposElementos/Listado";
import Detalle from "../../views/TiposElementos/Detalle";
import { useSelector } from "react-redux";

const TiposElementosRouter = () => {
  const { user } = useSelector(state => state.auth)

  return (
    <Routes>
      <Route path="/" element={<Listado />} />

      {user.permisos['tipos-elementos.crear'] &&
        <Route path="/create" element={<Create />} />
      }

      {user.permisos['tipos-elementos.detalle'] &&
        <Route path="/detalle/:id" element={<Detalle />} />
      }

      {user.permisos['tipos-elementos.editar'] &&
        <Route path="/update/:id" element={<Update />} />
      }
    </Routes>
  );
};

export default TiposElementosRouter;

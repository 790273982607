import { Route, Routes } from "react-router-dom"
import Listado from "../views/Stock/Listado"



const StockRouter = () => {
   return (
      <Routes>
         <Route path='/' element={<Listado />} />
      </Routes>
   )
}

export default StockRouter
import axios from "axios"
import { getOne } from "../../endpoints/getOne"
import { queryParamsFormatter } from "../../helpers/queryParamsFormatter"
import { currentMonthDate } from "../../helpers/currentMonth"

const URL = getOne.agendamientos

export const getOneAgendaPuntoEntrega = async (puntoentrega_id, date = undefined) => {
  try {
    if (!puntoentrega_id) return

    return await axios(URL(puntoentrega_id) + '?q=s' + queryParamsFormatter({ desde: currentMonthDate(date).first, hasta: currentMonthDate(date).last }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        console.error(err)
        throw err
      })
  }

  catch (err) {
    return { ok: false, error: err }
  }
}
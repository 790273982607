import axios from "axios";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";


// Hooks / Helpers
import { useForm } from "../../hooks/useForm";


// React-router
import { useNavigate } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";


const CONSTANT = {
  redirectUrl: '/generales/unidades',
  componentTitle: 'Crear unidad',
  title: 'Unidad',
  queryUrl: create.unidades,
}


const initialformState = {
  nombre: '',
  simbolo: '',
  estatus: true
};

const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [{
    nombre,
    simbolo,
    estatus
  }, handleInputChange] = useForm(initialformState);


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()


    let data = {
      nombre,
      simbolo,
      estatus: Number(estatus) ? true : false
    }

    axios(CONSTANT.queryUrl, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'application/json'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data?.nombre} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      < div className="col col-md-6 mx-auto" >
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>
        <CustomForm size="" title={CONSTANT.componentTitle}>
          <div className="row">
            <Switch
              labelText='Estatus'
              name='estatus'
              value={estatus}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-12'
            />

            <FormInput
              labelText='* Nombre'
              name='nombre'
              value={nombre}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              labelText='* Símbolo'
              name='simbolo'
              value={simbolo}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />
          </div>

          <Button type="submit" text="Crear" color="primary" icon="Plus" onClick={(e) => handleFetch(e)} />
        </CustomForm>
      </div >
    </div >
  );
};

export default Create;

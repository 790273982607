import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_DIAS,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosDias = (data) => ({ type: RESULTADOS_DIAS, payload: data })

export const clearFilterDias = () => ({ type: FILTER_CLEAR });

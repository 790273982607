import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../Icon'

const DropdownMenuLink = ({ isInnerLink = true, href = '', text, icon, ...props }) => {
   if (href && isInnerLink) return (
      <Link className="dropdown-item w-100" to={href} key={text + "-dropdown-btn"} {...props}>
         <Icon icon={icon} />
         <i className="me-50"></i>
         {text}
      </Link>
   )

   if (href && !isInnerLink) return (
      <a className="dropdown-item w-100" href={href} key={text + "-dropdown-btn"} {...props}>
         <Icon icon={icon} />
         <i className="me-50"></i>
         {text}
      </a>
   )

   return (
      <button className="dropdown-item w-100" to={href} key={text + "-dropdown-btn"} {...props}>
         <Icon icon={icon} />
         <i className="me-50"></i>
         {text}
      </button>
   )
}

export default DropdownMenuLink
import { Route, Routes } from "react-router-dom"
import Detalle from "../../views/NotasVentas/Detalle";
import Index from "../../views/NotasVentas/Index";
import Update from "../../views/NotasVentas/Update";


const NotasVentasRouter = () => {
   return (
      <Routes>
         <Route path='/' element={<Index />} />
         <Route path='/detalle/:id' element={<Detalle />} />
         <Route path='/update/:id' element={<Update />} />
      </Routes>
   )
}

export default NotasVentasRouter;
import {
   FILTER_ADD_CATEGORIA,
   FILTER_REMOVE_CATEGORIA,
   FILTER_BUSQUEDA,
   FILTER_ADD_PUNTO_ENTREGA,
   FILTER_REMOVE_PUNTO_ENTREGA,
   RESULTADOS_PRODUCTOS_PUNTOS_ENTREGA,
   FILTER_CLEAR_PRODUCTOS_PUNTOS_ENTREGA,
   FILTER_PUBLICADO,
   FILTER_ADD_REGION,
   FILTER_REMOVE_REGION,
   FILTER_SKUS_PRODUCTO
} from "../redux-types";


const initialState = {
   filtros: {
      categorias_id: [],
      puntos_entregas_ids: [],
      busqueda: '',
      publicado: '1-Publicado',
      skus: '',
      regiones: [],
   },
   resultados: []
}


export const productosPuntosEntregaReducer = (state = initialState, action) => {
   let isListed

   switch (action.type) {
      case FILTER_ADD_CATEGORIA:
         isListed = state.filtros.categorias_id.find(el => el === action.payload)

         return {
            ...state, filtros: {
               ...state.filtros,
               categorias_id: isListed
                  ? state.filtros.categorias_id
                  : [...state.filtros.categorias_id, action.payload]
            }
         };

      case FILTER_REMOVE_CATEGORIA:
         return {
            ...state, filtros: {
               ...state.filtros,
               categorias_id: state.filtros.categorias_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_PUNTO_ENTREGA:
         isListed = state.filtros.puntos_entregas_ids.find(el => el === action.payload)

         return {
            ...state, filtros: {
               ...state.filtros,
               puntos_entregas_ids: isListed
                  ? state.filtros.puntos_entregas_ids
                  : [...state.filtros.puntos_entregas_ids, action.payload]
            }
         };

      case FILTER_REMOVE_PUNTO_ENTREGA:
         return {
            ...state, filtros: {
               ...state.filtros,
               puntos_entregas_ids: state.filtros.puntos_entregas_ids.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_REGION:
         isListed = state.filtros.regiones.find(el => el === action.payload)

         return {
            ...state, filtros: {
               ...state.filtros,
               regiones: isListed
                  ? state.filtros.regiones
                  : [...state.filtros.regiones, action.payload]
            }
         };

      case FILTER_REMOVE_REGION:
         return {
            ...state, filtros: {
               ...state.filtros,
               regiones: state.filtros.regiones.filter(el => el !== action.payload)
            }
         };

      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda: action.payload
            }
         };

      case FILTER_SKUS_PRODUCTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               skus: action.payload
            }
         };

      case FILTER_PUBLICADO:
         return {
            ...state, filtros: {
               ...state.filtros,
               publicado: action.payload
            }
         };

      case RESULTADOS_PRODUCTOS_PUNTOS_ENTREGA:
         return {
            ...state,
            resultados: action.payload
         };

      case FILTER_CLEAR_PRODUCTOS_PUNTOS_ENTREGA:
         return initialState;

      default:
         return state;
   }
};
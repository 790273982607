import axios from "axios";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";


// React-router
import { useNavigate } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";
import { useEffect, useState } from "react";
import { idExtractor } from "../../helpers/idExtractor";
import SelectFilter from "../../components/SelectFilter";
import { getAll } from "../../endpoints/getAll";
import CustomLoader from "../../components/CustomLoader";


const CONSTANT = {
  redirectUrl: '/generales/distritos',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitle: 'Crear distrito',
  title: 'Distrito',
  queryUrl: create.distritos,
  fetchPaises: getAll.paises + '/buscar',
  fetchDistritosErp: getAll.erp_distritos,
  initialFormState: {
    pais_id: '',
    nombre: undefined,
    cod_maxi: undefined,
    estatus: 1
  },
  selectFilterInitialState: {
    paises: [],
    erpDistritosCodMaxi: []
  }
}


const handleSelectFiltersData = async () => {
  const paises = await axios(CONSTANT.fetchPaises,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.paises] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const erpDistritosCodMaxi = await axios(CONSTANT.fetchDistritosErp,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.Distritos] ?? [CONSTANT.unselectedObject])

    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })
  return { paises, erpDistritosCodMaxi }
}


const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.initialFormState)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)


  // EFECTO QUE SETEA LOS VALORES DE LOS SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    const { nombre, pais_id, cod_maxi, estatus } = form

    let data = {
      nombre,
      pais_id: idExtractor(pais_id),
      cod_maxi: cod_maxi.split('-')[0],
      estatus: Number(estatus) ? true : false
    }

    axios(CONSTANT.queryUrl,
      {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
          'Content-Type': 'application/json'
        },
        data
      })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }


  return (
    <div className="row">
      < div className="col col-md-6 mx-auto" >
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {selectFilter.paises.length && selectFilter.erpDistritosCodMaxi.length
          ? <CustomForm size="" title={CONSTANT.componentTitle}>

            <div className="row">
              <Switch
                labelText='Estatus'
                name='estatus'
                value={form.estatus}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <SelectFilter
                labelText='* Cod. maximise'
                name='cod_maxi'
                value={form?.cod_maxi?.split('-')[1] ?? ''}
                handleValue={handleInputChange}
                optionObj={selectFilter.erpDistritosCodMaxi.map(({ cod_maxi, nombre }) => ({ id: cod_maxi ?? '*NULL*', name: `${nombre ?? 'Error'} | ${cod_maxi ?? ''}` }))}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <SelectFilter
                labelText='* País'
                name='pais_id'
                value={form.pais_id?.split('-')[1]}
                handleValue={handleInputChange}
                optionObj={selectFilter.paises.map(({ id, nombre }) => ({ id: id ?? '*NULL*', name: nombre ?? 'Error' }))}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='* Nombre'
                name='nombre'
                value={form.nombre}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />
            </div>

            <Button
              type="submit"
              text="Crear"
              color="primary"
              icon="Plus"
              onClick={(e) => handleFetch(e)}
            />
          </CustomForm>
          : <CustomLoader />
        }
      </div >
    </div >
  );
};

export default Create;

import { Navigate, Route, Routes } from "react-router-dom"
import Create from "../views/Ofertas/Create";
import Detalle from "../views/Ofertas/Detalle";
import Listado from "../views/Ofertas/Listado";
import Update from "../views/Ofertas/Update";
import { useSelector } from "react-redux";


const OfertasRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['ofertas.crear'] &&
            <Route path='/create' element={<Create />} />
         }

         {user.permisos['ofertas.editar'] &&
            <Route path='/update/:id' element={<Update />} />
         }

         {user.permisos['ofertas.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   );
}

export default OfertasRouter;
import { useEffect, useState } from "react";
import axios from "axios";


// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";


// React-router-dom
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { getOne } from "../../endpoints/getOne";
import CustomLoader from "../../components/CustomLoader";
import TextareaInput from "../../components/TextareaInput";
import FileUploaderB from "../../components/FileUploader_B";
import { BASE_URL } from "../../endpoints";
import { idExtractor } from "../../helpers/idExtractor";
import SelectFilter from "../../components/SelectFilter";
import { getAll } from "../../endpoints/getAll";


const CONSTANT = {
  redirectUrl: '/template/pasos-compra',
  componentTitle: 'Editar paso de compra',
  title: 'Pasos de compra',
  imagen: (data) => `${BASE_URL}storage/${data?.icono}` ?? '/placeholder.png',
  queryUrl: getOne.pasos_compra,
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  fetchFrontendId: getAll.frontend_id,

  initialFormState: {
    frontend_id: '',
    titulo: '',
    paso_numero: '',
    descripcion: '',
    estatus: true
  },

  selectFilterInitialState: {
    frontendId: [],
  }
}


const handleSelectFiltersData = async () => {
  const frontendId = await axios(CONSTANT.fetchFrontendId,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { frontendId }
}


const Update = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [imagen, setImagen] = useState([])
  const { id } = useParams()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [form, setForm] = useState(CONSTANT.initialFormState);

  const [isConsulting, setIsConsulting] = useState(false)

  // FUNCIÓN MANEJADORA QUE ACTUALIZA EL ESTADO DE 'FetchData'
  const handleInputChange = (e) => {
    const { target } = e

    setForm({
      ...form,
      [target.name]: target.value,
    })
  }


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // EFECTO QUE CONSULTA LA DATA DEL REGISTRO Y ACTUALIZA EL ESTADO 'form'
  useEffect(() => {
    axios(CONSTANT.queryUrl(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => setForm({
        ...data,
        frontend_id: `${data?.frontend_id ?? '*NULL*'}-${data?.frontend?.nombre ?? 'Sin selección'}`,
      }))
      .catch(err => console.log(err))

    return () => {
      setForm(null)
    }
  }, [id])


  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)

    let data = new FormData()
    data.append('titulo', form?.titulo)
    data.append('paso_numero', form?.paso_numero)
    imagen.length && data.append('icono', imagen[0].file)
    data.append('descripcion', form?.descripcion)
    data.append('estatus', Number(form?.estatus))
    data.append('_method', 'PUT')
    !form.frontend_id.includes('*NULL*') && data.append('frontend_id', idExtractor(form?.frontend_id))

    // for (const [k, v] of data) {
    //   console.log(k, v);
    // }
    // return TODO: borrar

    axios(CONSTANT.queryUrl(id), {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'multipart/form-data'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.titulo} actualizado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() =>
        setIsConsulting(false)
      )
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {form?.id && selectFilter.frontendId.length
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                labelText='Estatus'
                name='estatus'
                value={form?.estatus}
                handleValue={handleInputChange}
                size='col-12'
              />

              <FormInput
                labelText='* Título'
                name='titulo'
                value={form?.titulo}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <SelectFilter
                name="frontend_id"
                value={form?.frontend_id?.split('-')[1] ?? ''}
                labelText="* Frontend"
                optionObj={selectFilter.frontendId.map(({ id, nombre }) => ({ id, name: nombre }))}
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='* Número de paso'
                name='paso_numero'
                value={form?.paso_numero}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <TextareaInput
                labelText='* Descripción'
                name='descripcion'
                value={form?.descripcion}
                handleValue={handleInputChange}
                rows={2}
                size='col-12'
              />

              <FileUploaderB labelText='* Ícono ' value={imagen} handleValue={setImagen} />

              <div className="input-inset col-12">
                <label className="form-label">Imagen actual</label>
                <div className="form-outline border rounded-2 lista-detalle mb-2 d-flex justify-content-center p-2 w-fit">
                  {form?.icono &&
                    <img src={CONSTANT.imagen(form)} alt={`img-${form.nombre}`} />
                  }
                </div>
              </div>

            </div>

            <Button disabled={isConsulting} type="submit" text="Editar" color="primary" icon="Check" onClick={(e) => handleFetch(e)} />

          </CustomForm>
          : <CustomLoader />
        }

      </div>
    </div>

  );
};

export default Update;

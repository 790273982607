import axios from "axios";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";


// Hooks / Helpers
import { useForm } from "../../hooks/useForm";


// React-router
import { useNavigate } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";
import TextareaInput from "../../components/TextareaInput";
import { todayFormat } from "../../helpers/todayInputFormat";
import { useState } from "react";


const CONSTANT = {
  redirectUrl: '/codigos-descuento',
  componentTitle: 'Crear código',
  title: 'Códigos de descuento',
  queryUrl: create.codigos_descuento,
}


const initialformState = {
  nombre: '',
  descripcion: '',
  codigo: new Date().getTime(),
  porcentaje_descuento: undefined,
  monto_descuento: undefined,
  fecha_creacion: todayFormat(),
  fecha_caducidad: '',
  estatus: true
};

const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [{
    nombre,
    descripcion,
    codigo,
    porcentaje_descuento,
    monto_descuento,
    fecha_creacion,
    fecha_caducidad,
    estatus
  }, handleInputChange, reset] = useForm(initialformState);

  const [isConsulting, setIsConsulting] = useState(false)


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    let data = {
      nombre,
      descripcion,
      codigo: String(codigo),
      porcentaje_descuento: porcentaje_descuento ?? undefined,
      monto_descuento: monto_descuento ?? undefined,
      fecha_creacion: `${fecha_creacion} 00:00:00`,
      fecha_caducidad: `${fecha_caducidad} 00:00:00`,
      estatus: Number(estatus) ? true : false
    }

    setIsConsulting(true)

    axios(CONSTANT.queryUrl, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'application/json'
      },
      data
    })
      .then(res => {
        reset()

        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${res.data.nombre} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() =>
        setIsConsulting(false)
      )
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto" >
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        <CustomForm size="" title={CONSTANT.componentTitle}>
          <div className="row">
            <Switch
              labelText='Estatus'
              name='estatus'
              value={estatus}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-12'
            />

            <FormInput
              labelText='* Nombre'
              placeholder='Nombre...'
              name='nombre'
              value={nombre}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              labelText='* Código'
              placeholder='Código...'
              name='codigo'
              value={codigo}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              labelText='* Porcentaje de descuento'
              placeholder='%'
              type="number"
              isDisabled={monto_descuento}
              name='porcentaje_descuento'
              value={porcentaje_descuento}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              labelText='* Monto de descuento'
              placeholder='Monto...'
              type="number"
              isDisabled={porcentaje_descuento}
              name='monto_descuento'
              value={monto_descuento}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              labelText='* Fecha de creación'
              type="date"
              max={fecha_caducidad}
              name='fecha_creacion'
              value={fecha_creacion}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              labelText='* Fecha de caducidad'
              type="date"
              min={fecha_creacion}
              name='fecha_caducidad'
              value={fecha_caducidad}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <TextareaInput
              labelText='Descripción'
              name='descripcion'
              value={descripcion}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-12'
              rows={2}
            />
          </div>

          <Button disabled={isConsulting} type="submit" text="Crear" color="primary" icon="Plus" onClick={(e) => handleFetch(e)} />
        </CustomForm>
      </div >
    </div >
  );
};

export default Create;

import { useEffect, useState } from "react";
import axios from "axios";

// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import SelectFilter from "../../components/SelectFilter";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Enpoints
import { getAll } from "../../endpoints/getAll";
import { create } from "../../endpoints/create";
import CustomLoader from "../../components/CustomLoader";
import { idExtractor } from "../../helpers/idExtractor";


const CONSTANT = {
  redirectUrl: '/proveedores/productos',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitleCreate: 'Crear producto en proveedor',
  componentTitleUpdate: 'Editar producto en proveedor',
  title: 'Producto en proveedor',

  queryUrl: create.productos_proveedor,
  fetchProveedorCategoria: getAll.proveedores_categorias,

  initialFormState: {
    proveedor_categoria_id: '',
    sku_proveedor: '',
    descripcion: '',
    precio_producto: '',
    stock_producto: '',
    estatus: true
  },

  selectFilterInitialState: {
    proveedorCategoria: [],
  }
}


const handleSelectFiltersData = async () => {
  const proveedorCategoria = await axios(CONSTANT.fetchProveedorCategoria,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { proveedorCategoria }
}


const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.initialFormState);
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const { id } = useParams()


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // EFFECTO QUE SETEA form
  useEffect(() => {
    if (!id || !selectFilter.proveedorCategoria.length) return

    axios(CONSTANT.queryUrl + `/${id}`)
      .then(({ data }) => {
        let res = data
        const { id: provId, categoria: provCat, proveedor: prov } = selectFilter.proveedorCategoria.find(({ id }) => id === res.proveedor_categoria_id)
        setForm({
          ...data,
          proveedor_categoria_id: `${provId}-${prov?.nombre ?? 'N/A'} | ${provCat?.nombre ?? 'Sin selección'}`
        })

      })
      .catch(err => console.log(err))

    return () => {
      setForm(null)
    }
  }, [id, selectFilter.proveedorCategoria])


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    if (!form.proveedor_categoria_id || form.proveedor_categoria_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'La categoría es obligatoria', icon: 'warning' }))

    let url = id ? CONSTANT.queryUrl + `/${id}` : CONSTANT.queryUrl
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: id ? 'PUT' : 'POST',
      data: {
        proveedor_categoria_id: idExtractor(form.proveedor_categoria_id),
        sku_proveedor: form.sku_proveedor,
        descripcion: form.descripcion,
        precio_producto: Number(form.precio_producto),
        stock_producto: Number(form.stock_producto),
        estatus: form.estatus
      }
    }

    axios(url, config)
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: registro ${id ? 'actualizado' : 'creado'} con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);

        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
      })
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {selectFilter.proveedorCategoria.length
          ? <CustomForm size="" title={id ? CONSTANT.componentTitleUpdate : CONSTANT.componentTitleCreate}>
            <div className="row">
              <Switch
                name="estatus"
                value={form?.estatus}
                labelText="Estatus"
                handleValue={handleInputChange}
                size="col-12"
              />

              <SelectFilter
                labelText="* Categoría proveedor"
                name="proveedor_categoria_id"
                value={form?.proveedor_categoria_id?.split('-')[1] ?? 'Seleccione'}
                handleValue={handleInputChange}
                optionObj={selectFilter.proveedorCategoria?.map(({ categoria, proveedor, id }) => ({ name: `${proveedor?.nombre ?? 'N/A'} | ${categoria?.nombre ?? 'Sin seleccion'}`, id }))}
                size="col-12"
                sizeDesk="col-md-12"
              />

              <FormInput
                labelText='* SKU del proveedor'
                name='sku_proveedor'
                value={form?.sku_proveedor ?? ''}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='Descripción'
                name='descripcion'
                value={form?.descripcion ?? ''}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                type='number'
                labelText='Precio del producto'
                name='precio_producto'
                value={form?.precio_producto ?? ''}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                type='number'
                labelText='Stock del producto'
                name='stock_producto'
                value={form?.stock_producto ?? ''}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />
            </div>

            <Button type="submit" text={id ? 'Editar' : 'Crear'} color="primary" icon="Check" onClick={(e) => handleFetch(e)} />
          </CustomForm>

          : <CustomLoader />
        }
      </div>
    </div>
  );
};

export default Form;

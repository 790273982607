import { useEffect, useState } from "react";
import axios from "axios";


// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";


// React-router-dom
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { getOne } from "../../endpoints/getOne";
import CustomLoader from "../../components/CustomLoader";
import TextareaInput from "../../components/TextareaInput";
import SelectFilter from "../../components/SelectFilter";
import { getAll } from "../../endpoints/getAll";
import { idExtractor } from "../../helpers/idExtractor";


const CONSTANT = {
  redirectUrl: '/template/faq',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitle: 'Editar FAQ',
  title: 'FAQ',
  queryUrl: getOne.faq,
  fetchFrontendId: getAll.frontend_id,

  initialFormState: {
    frontend_id: '',
    titulo: '',
    informacion: '',
    estatus: true
  },

  selectFilterInitialState: {
    frontendId: [],
  }
}


const handleSelectFiltersData = async () => {
  const frontendId = await axios(CONSTANT.fetchFrontendId,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { frontendId }
}


const Update = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [form, setForm] = useState(CONSTANT.initialFormState);
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [isConsulting, setIsConsulting] = useState(false)


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // EFECTO QUE CONSULTA LA DATA DEL REGISTRO Y ACTUALIZA EL ESTADO 'form'
  useEffect(() => {
    axios(getOne.faq(id), {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
      }
    })
      .then(({ data }) => setForm(form => ({
        ...data,
        frontend_id: `${data?.frontend_id ?? '*NULL*'}-${data?.frontend?.nombre ?? 'Sin selección'}`,
      })))
      .catch(err => console.log(err))

    return () => {
      setForm(null)
    }
  }, [id])


  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)
    const { titulo, estatus, informacion } = form

    let data = {
      titulo,
      informacion,
      estatus: Number(estatus)
    }

    if (!form?.frontend_id.includes('*NULL*')) {
      data = {
        ...data,
        frontend_id: idExtractor(form?.frontend_id),
      }
    }

    axios(CONSTANT?.queryUrl(id),
      {
        method: 'PUT',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        },
        data
      })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `FAQ: ${data.titulo} actualizada con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate('/template/faq')
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []

        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))
        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() =>
        setIsConsulting(false)
      )
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text='Preguntas frecuentes' icon='ArrowLeft' style='flat' isLink url='/template/faq' />
        </div>

        {form?.id && selectFilter.frontendId.length
          ? <CustomForm size="" title="Editar pregunta frecuente">
            <div className="row">
              <Switch
                labelText='Estatus'
                name='estatus'
                value={form?.estatus}
                handleValue={handleInputChange}
                size='col-12'
              />

              <FormInput
                labelText='Título'
                name='titulo'
                value={form?.titulo ?? ''}
                handleValue={handleInputChange}
                size='col-12'
              />

              <SelectFilter
                name="frontend_id"
                value={form?.frontend_id?.split('-')[1] ?? ''}
                labelText="* Frontend"
                optionObj={selectFilter.frontendId.map(({ id, nombre }) => ({ id, name: nombre }))}
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <TextareaInput
                labelText='Información'
                name='informacion'
                value={form?.informacion ?? ''}
                handleValue={handleInputChange}
                rows={2}
                size='col-12'
              />
            </div>

            <Button disabled={isConsulting} type="submit" text="Editar" color="primary" icon="Check" onClick={(e) => handleFetch(e)} />
          </CustomForm>

          : <CustomLoader />
        }
      </div>
    </div>
  );
};

export default Update;

import axios from "axios";
import { useEffect, useState } from "react";


// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


// React-router
import { useNavigate } from "react-router-dom";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import SelectFilter from "../../components/SelectFilter";
import CustomLoader from "../../components/CustomLoader";
import FileUploaderB from "../../components/FileUploader_B";


// Enpoints
import { create } from "../../endpoints/create";
import { getAll } from "../../endpoints/getAll";



const CONSTANT = {
  redirectUrl: '/generales/regiones',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitle: 'Crear región',
  title: 'Regiones',
  queryUrl: create.region,
  fetchPaises: getAll.paises + '/buscar',
  fetchRegionesErp: getAll.erp_regiones,
  initialFormState: {
    pais_id: "",
    nombre: "",
    cod_maxi: "",
    imagen_bandera: "",
    estatus: 1,
  },
  selectFilterInitialState: {
    paises: [],
    // erpRegiones: []
  }
}


const handleSelectFiltersData = async () => {
  const paises = await axios(CONSTANT.fetchPaises,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.paises] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  // const erpRegiones = await axios(CONSTANT.fetchRegionesErp)
  //   .then(({ data }) => [CONSTANT.unselectedObject, ...data?.regiones] ?? [CONSTANT.unselectedObject])
  //   .catch(err => {
  //     console.error(err)
  //     return [CONSTANT.nullObject]
  //   })

  return { paises }
}


const Create = () => {
  const [imagen_bandera, setImagen_bandera] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.initialFormState);
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])

  const handleFetch = async (e) => {
    e.preventDefault()

    if (!form.nombre.length) return dispatch(fireToaster({ title: 'El nombre es obligatorio', icon: 'info' }))
    if (!form.cod_maxi.length) return dispatch(fireToaster({ title: 'El código maximise es obligatorio', icon: 'info' }))
    if (!form.pais_id.length) return dispatch(fireToaster({ title: 'El país es obligatorio', icon: 'info' }))
    if (!imagen_bandera.length) return dispatch(fireToaster({ title: 'La bandera es obligatoria', icon: 'info' }))

    let data = new FormData()
    data.append('nombre', form.nombre)
    data.append('cod_maxi', form.cod_maxi)
    data.append('imagen_bandera', imagen_bandera[0].file)
    data.append('pais_id', form.pais_id.split('-')[0])
    data.append('estatus', Number(form.estatus))

    axios(create.region, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'multipart/form-data'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `Región: ${data.nombre} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate('/regiones')
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {selectFilter.paises.length

          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                name="estatus"
                value={form.estatus}
                labelText="Estatus"
                handleValue={handleInputChange}
                size="col-12"
              />

              <FormInput
                name="nombre"
                value={form.nombre}
                labelText="* Nombre"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk="col-md-4"
              />

              <SelectFilter
                name="pais_id"
                value={form.pais_id.split('-')[1]}
                labelText="* País"
                handleValue={handleInputChange}
                optionObj={selectFilter.paises.map(({ nombre, id }) => ({ name: nombre, id }))}
                size="col-12"
                sizeDesk="col-md-4"
              />

              <FormInput
                name="cod_maxi"
                value={form.cod_maxi}
                labelText="* Cod. Maximise"
                handleValue={handleInputChange}
                // optionObj={selectFilter.erpRegiones?.map(({ cod_maxi, nombre }) => ({ name: `${nombre ?? 'Error'} | ${cod_maxi ?? ''}`, id: cod_maxi ?? '*NULL*' }))}
                size="col-12"
                sizeDesk="col-md-4"
              />

              <FileUploaderB labelText='* Bandera' handleValue={setImagen_bandera} value={imagen_bandera} />
            </div>

            <Button type="submit" text="Crear" color="primary" icon="Plus" onClick={(e) => handleFetch(e)} />
          </CustomForm>

          : <CustomLoader />
        }

      </div>
    </div>
  );
};

export default Create;

import { useEffect, useState } from "react";
import axios from "axios";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import SelectFilter from "../../components/SelectFilter";
import IconPicker from "../../components/IconPicker";
import TextareaInput from "../../components/TextareaInput";


// Hooks
import { useCustomFetch } from "../../hooks/useCustomFetch";


// React-router
import { useNavigate } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";
import { getAll } from "../../endpoints/getAll";


// Helpers
import { iconSanatizerFaMdi } from "../../helpers/iconSanatizerFaMdi";
import { idExtractor } from "../../helpers/idExtractor";


// Misc
import { contractFormat } from "./misc/contractFormat";


const CONSTANT = {
  redirectUrl: '/adicionales',
  componentTitle: 'Crear adicional',
  title: 'Adicionales',
  queryUrl: create.adicionales,
  queryUrlGetAllCategorias: getAll.categorias + `/buscar?limit=99999`
}


const initialformState = {
  categoria_id: '',
  nombre: '',
  descripcion: '',
  estatus: true
};


const Create = () => {
  const navigate = useNavigate()
  const [icono, setIcono] = useState(null)
  const [inputSelect, setInputSelect] = useState({ categorias: [] })
  const [form, setForm] = useState(initialformState);
  const [customFetch] = useCustomFetch()

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE SETEA LOS SELECTFILTER
  useEffect(() => {
    axios(CONSTANT.queryUrlGetAllCategorias, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
      },
      method: 'POST'
    })
      .then(({ data }) => setInputSelect({ categorias: data?.categorias?.data ?? [] }))
      .catch(err => {
        console.error(err)
      })
  }, [])


  // FUNCION MANEJADORA DE LOS INPUTS DEL FORMULARIO
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCION MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)

    const { categoria_id, nombre, descripcion, estatus } = form
    const sanitazedIcon = iconSanatizerFaMdi(icono)
    let data = {
      categoria_id: idExtractor(categoria_id),
      nombre,
      descripcion,

      icono_libreria: sanitazedIcon?.libreria,
      icono_fuente: sanitazedIcon?.fuente ?? null,
      icono_icono: sanitazedIcon?.icono,

      estatus: Number(estatus) ? true : false
    }

    const res = await customFetch(CONSTANT.queryUrl, contractFormat('create', data))
    setIsConsulting(false)
    if (res.status >= 200 && res.status < 300) navigate(CONSTANT.redirectUrl)
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        <CustomForm size="" title={CONSTANT.componentTitle}>
          <div className="row">
            <Switch
              labelText='Estatus'
              name='estatus'
              value={form.estatus}
              handleValue={handleInputChange}
              size='col-12'
            />

            <IconPicker
              value={icono}
              handleValue={setIcono}
              size='col-12'
            />

            <FormInput
              labelText='* Nombre'
              name='nombre'
              value={form.nombre}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <SelectFilter
              labelText='* Categoría'
              name='categoria_id'
              value={form.categoria_id.split('-')[1]}
              handleValue={handleInputChange}
              optionObj={inputSelect.categorias.map(({ id, nombre }) => ({ id, name: nombre }))}
              size='col-12'
            />

            <TextareaInput
              labelText='Descripción'
              name='descripcion'
              value={form.descripcion}
              handleValue={handleInputChange}
              rows={2}
              size='col-12'
            />
          </div>

          <Button disabled={isConsulting} type="submit" text="Crear" color="primary" icon="Plus" onClick={(e) => handleFetch(e)} />
        </CustomForm>
      </div>
    </div>
  );
};

export default Create;

import {
   FILTER_ADD_CLAVE_ATRIBUTO,
   FILTER_ADD_UNIDAD,
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   FILTER_REMOVE_CLAVE_ATRIBUTO,
   FILTER_REMOVE_UNIDAD,
   RESULTADOS_VALORES_ATRIBUTOS,
} from "../redux-types";

export const claveAtributoFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_CLAVE_ATRIBUTO, payload: data }
      : { type: FILTER_ADD_CLAVE_ATRIBUTO, payload: data };

export const unidadFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_UNIDAD, payload: data }
      : { type: FILTER_ADD_UNIDAD, payload: data };

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosValoresAtributos = (data) => ({ type: RESULTADOS_VALORES_ATRIBUTOS, payload: data })

export const clearFilterValoresAtributos = () => ({ type: FILTER_CLEAR });

import { Navigate, Route, Routes } from "react-router-dom"
import useAuth from "../hooks/useAuth";
import Login from "../views/Auth/Login";



const AuthRouter = () => {
  useAuth(false)

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path='*' element={<Navigate to='/home' replace />} />
    </Routes>
  )
}

export default AuthRouter;
import axios from 'axios'
import { useEffect, useState } from 'react'


// Redux


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import CustomLoader from '../../components/CustomLoader'


// Router Dom
import { useParams } from 'react-router-dom'


// Layouts


// Endpoints
import { getOne } from '../../endpoints/getOne'
import { historicoData } from '../../helpers/historicoData'
import { ModalHistorico } from '../../components/Historico/ModalHistorico'
import TablaDetalle from '../../components/Historico/TablaDetalle'


const CONSTANT = {
  redirectListado: '/busqueda-clientes',
  modelo: 'App\\Models\\NeuBusquedaCliente',
  queryUrlGetOne: getOne.busquedas_clientes,
  title: 'Búsqueda clientes',
}


const Detalle = () => {
  const [data, setData] = useState(null)
  const { id } = useParams()
  const [historico, setHistorico] = useState(null)
  const [modal, setModal] = useState({ id: null, display: false, data: null })


  // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
  useEffect(() => {
    axios(CONSTANT.queryUrlGetOne(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => setData(data))
      .catch(err => console.error(err))

    historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))

    return () => {
      setData(null)
    }
  }, [id])

  
  // FUNCION MANEJADORA DE LA DATA DEL MODAL
  const handleModal = (data) => {
    const { id, anterior, nuevo } = data

    setModal({
      display: true,
      id,
      data: {
        anterior,
        nuevo
      }
    })
  }


  return (
    <div className='row'>

      <ModalHistorico modal={modal} fnHandleModal={setModal} />

      <div className='col-12 '>
        <div className='col mb-50'>
          <div className='d-flex gap-1'>
            <div className='col'>
              <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
            </div>
          </div>
        </div>

        {data?.id
          ? <>

            <Accordion
              title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
              isOpen
              classes='col-md-6 mx-auto mb-2'
            >
              <AccordionDetailData dataKey={'ID'} value={data?.id} />
              <AccordionDetailData dataKey={'Búsqueda'} value={data?.busqueda_string ?? 'N/A'} />
              <AccordionDetailData dataKey={'Descripción'} value={data?.descripcion ?? 'N/A'} />
              <AccordionDetailData dataKey={'Alto'} value={data?.alto ?? 'N/A'} />
              <AccordionDetailData dataKey={'Ancho'} value={data?.ancho ?? 'N/A'} />
              <AccordionDetailData dataKey={'Aro'} value={data?.aro ?? 'N/A'} />
              <AccordionDetailData dataKey={'Filtro precios'} value={
                <div className='d-flex flex-column'>
                  <span>Desde: <b>{`${data?.filtro_precio_desde}` ?? "N/A"}</b></span>
                  <span>Hasta: <b>{`${data?.filtro_precio_hasta}` ?? "N/A"}</b></span>
                </div>
              } last />
            </Accordion>

            <TablaDetalle historicoData={historico} fnHandleModal={handleModal} />

          </>

          : <CustomLoader />
        }
      </div>
    </div >

  )
}

export default Detalle
import { Navigate, Route, Routes } from "react-router-dom"
import Listado from "../views/Claves/Listado"
import Form from "../views/Claves/Form"
import { useSelector } from "react-redux"


const ClavesRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['atributos.crear'] &&
            <Route path='/update/:id' element={<Form />} />
         }

         {user.permisos['atributos.editar'] &&
            <Route path='/create' element={<Form />} />
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}

export default ClavesRouter
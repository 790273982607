
// Components
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import ComunasFiltros from "../../components/AccordionFilter/Comunas";
import ComunasTable from "../../components/Tables/ComunasTable";


const CONSTANT = {
   title: 'Comunas',
   path: 'comunas',
   create_btn: 'Crear comuna'
}

const Listado = () => {
   return (
      <div className="row">
         <h3 className="mb-1 d-flex justify-content-between">
            <p><b>{CONSTANT.title}</b> - Listado</p>
            <DropdownWrapper text="Acciones" classes="ms-auto">
               <DropdownMenuLink text={CONSTANT.create_btn} href={`/generales/${CONSTANT.path}/create`} icon="Plus" />
            </DropdownWrapper>
         </h3>

         <ComunasFiltros />

         <ComunasTable />
      </div>
   );
}

export default Listado;
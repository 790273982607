import {
   FILTER_FECHA_FINAL,
   FILTER_FECHA_INICIO,
   FILTER_ADD_OFERTA,
   FILTER_REMOVE_OFERTA,
   FILTER_NOMBRE,
   FILTER_DESCRIPCION,
   FILTER_CREACION_DESDE,
   FILTER_CREACION_HASTA,
   RESULTADOS_ETIQUETAS,
   FILTER_CLEAR_ETIQUETAS,
} from "../redux-types";


export const nombreFilter = (data, isRemove = false) => ({ type: FILTER_NOMBRE, payload: data })

export const ofertaFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_OFERTA, payload: data }
      : { type: FILTER_ADD_OFERTA, payload: data };

export const creacionDesdeFilter = (data) => ({ type: FILTER_CREACION_DESDE, payload: data })

export const creacionHastaFilter = (data) => ({ type: FILTER_CREACION_HASTA, payload: data })

export const descripcionFilter = (data) => ({ type: FILTER_DESCRIPCION, payload: data })

export const fechaHoraInicioFilter = (data) => ({ type: FILTER_FECHA_INICIO, payload: data });

export const fechaHoraFinalFilter = (data) => ({ type: FILTER_FECHA_FINAL, payload: data });

export const resultadosEtiquetas = (data) => ({ type: RESULTADOS_ETIQUETAS, payload: data })

export const clearFilterEtiquetas = () => ({ type: FILTER_CLEAR_ETIQUETAS })


import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { FILTER_AUTOGESTION_INPUT } from '../../redux/redux-types'
import { autogestionInputFilter, clearFilterAutogestion, resultadosAutogestion } from '../../redux/actions/autogestion'


// Components
import FormInput from '../FormInput'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { toasterErrorFormatter } from '../../helpers/toasterErrorFormatter'
import SelectFilter from '../SelectFilter'
import { idExtractor } from '../../helpers/idExtractor'
import { filtrosFormatter, getAllAutogestion } from '../../services/autogestion/getAllAutogestion.service'
import { getAllComunas } from '../../services/comunas/getAllComunas.service'
import DownloadFile from '../Download/DownloadFile'
import { getAll } from '../../endpoints/getAll'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'


const CONSTANT = {
  redirectUrl: '/autogestion',
  reduxStateSelector: 'autogestion',
  title: 'Búsqueda de empresas',

  queryExportar: `${getAll.autogestion}/exportar`,

  reduxClearFilters: clearFilterAutogestion,
  reduxSetResults: resultadosAutogestion,
  nullValue: { id: 'NULL', nombre: 'Sin selección' },

  selectFilterInitialState: {
    comunas: [],
  },
}

export const handleSelectsData = async () => {
  const comunas = await getAllComunas(999999).then(({ comunas, ...rest }) => {
    if (rest.status >= 400) return []
    return [...comunas.data]
  })

  return { comunas }
}

export const AutogestionFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const { comuna_id, pedido_id, fecha_desde, fecha_hasta } = filtros
  const dispatch = useDispatch()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    setIsConsulting(true)

    getAllAutogestion(showing_results).then(res => dispatch(CONSTANT.reduxSetResults(res)))

    handleSelectsData().then(res => setSelectFilter(res))

    setIsConsulting(false)

    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [showing_results, dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return getAllAutogestion(showing_results).then(res => dispatch(CONSTANT.reduxSetResults(res)))
    }

    setIsConsulting(true)

    await getAllAutogestion(showing_results, filtros)
      .then(res => {
        if (res.status >= 400) {
          dispatch(fireToaster(toasterErrorFormatter({ errors: res.data, response: res })))
          dispatch(CONSTANT.reduxSetResults([]))
          return
        }

        dispatch(CONSTANT.reduxSetResults(res))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value, name: key } = e.target

    switch (filter) {
      case FILTER_AUTOGESTION_INPUT:
        dispatch(autogestionInputFilter({ key, value }))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
        exportBtn={
          <DownloadFile
            method='GET'
            url={CONSTANT.queryExportar + '?s=1' + queryParamsFormatter(filtrosFormatter(filtros))}
          />
        }
      >
        <>
          <FormInput
            labelText='# Pedido'
            placeholder='Ingrese un número de pedido'
            name='pedido_id'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_AUTOGESTION_INPUT)}
            value={pedido_id}
          />

          <FormInput
            labelText='Fecha desde'
            name='fecha_desde'
            size='col-12'
            sizeDesk='col-md-2'
            type='date'
            handleValue={(e) => handleAddFilter(e, FILTER_AUTOGESTION_INPUT)}
            value={fecha_desde}
            max={fecha_hasta}
          />

          <FormInput
            min={fecha_desde}
            labelText='Fecha hasta'
            name='fecha_hasta'
            size='col-12'
            sizeDesk='col-md-2'
            type='date'
            handleValue={(e) => handleAddFilter(e, FILTER_AUTOGESTION_INPUT)}
            value={fecha_hasta}
          />

          <SelectFilter
            labelText="Comuna"
            name='comuna_id'
            altSeparator
            size="col-12"
            sizeDesk={"col-md-4"}
            optionObj={selectFilter?.comunas?.concat([CONSTANT.nullValue]).map(({ id, nombre }) => ({ id, name: nombre }))}
            handleValue={(e) => handleAddFilter(e, FILTER_AUTOGESTION_INPUT)}
            value={comuna_id.split('%-%')[1] ?? 'Seleccione'}
          />
        </>
      </ListadoHeader>
    </div>
  )
}
import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_TIPOS_ATENCION,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosTiposAtencion = (data) => ({ type: RESULTADOS_TIPOS_ATENCION, payload: data })

export const clearFilterTiposAtencion = () => ({ type: FILTER_CLEAR });

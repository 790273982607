import { useEffect, useState } from "react";
import axios from "axios";
import * as fas from '@fortawesome/free-solid-svg-icons';
import * as far from '@fortawesome/free-regular-svg-icons';
import * as fab from '@fortawesome/free-brands-svg-icons';
import * as MDI from '@mdi/js';

// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import CustomLoader from "../../components/CustomLoader";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import IconPicker from "../../components/IconPicker";
import Switch from "../../components/Switch";


// React-router-dom
import { useNavigate, useParams } from "react-router-dom";


// Hooks - Helpers
import { iconSanatizerFaMdi } from "../../helpers/iconSanatizerFaMdi";


// Endpoints
import { getOne } from "../../endpoints/getOne";
import { getAll } from "../../endpoints/getAll";
import SelectFilter from "../../components/SelectFilter";
import { idExtractor } from "../../helpers/idExtractor";


const CONSTANT = {
  redirectUrl: '/template/menus',
  componentTitle: 'Editar menú',
  title: 'Menús',
  queryUrl: getOne.menus,
  fetchFrontendId: getAll.frontend_id,
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  queryUrlGetAllComponentes: getAll.componentes,

  selectFilterInitialState: {
    frontendId: [],
  }
}

const iconsFA = { fas, far, fab }


const handleSelectFiltersData = async () => {
  const frontendId = await axios(CONSTANT.fetchFrontendId,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { frontendId }
}


const Update = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [icono, setIcono] = useState(null)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [form, setForm] = useState(null)
  const { id } = useParams()


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCIÓN MANEJADORA QUE ACTUALIZA EL ESTADO DE 'FetchData'
  const handleInputChange = (e) => {
    const { target } = e

    setForm({
      ...form,
      [target.name]: target.value,
    })
  }


  // EFECTO QUE CONSULTA LA DATA DEL REGISTRO Y ACTUALIZA EL ESTADO 'FetchData'
  useEffect(() => {
    let fetchIcon = null

    axios(CONSTANT.queryUrl(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then((res) => {
        let { data } = res
        fetchIcon = data?.neu_icono?.libreria === 'fa'
          ? {
            prefix: data?.neu_icono?.fuente,
            path: data?.neu_icono?.icono,
            iconName: iconsFA[data?.neu_icono?.fuente][data?.neu_icono?.icono].iconName,
            icon: iconsFA[data?.neu_icono?.fuente][data?.neu_icono?.icono]
          }
          : data?.neu_icono?.libreria === 'mdi'
            ? {
              icon: {
                prefix: 'mdi',
                iconName: MDI[data?.neu_icono?.icono],
                path: MDI[data?.neu_icono?.icono].path
              },
              nativeIcon: MDI[data?.neu_icono?.icono]
            }
            : null

        setForm({
          ...data,
          frontend_id: `${data?.frontend_id ?? '*NULL*'}-${data?.frontend?.nombre ?? 'Sin selección'}`,
        })
        setIcono(fetchIcon)
      })
      .catch(err => console.log(err))

    return () => {
      setForm(null)
    }
  }, [id])

  // EFECTO QUE CONSULTA LA DATA DEL REGISTRO Y RELLENA EL NOMBRE DEL COMPONENTE_ID
  // useEffect(() => {
  //   form?.componente_id && axios(CONSTANT.queryComponenteNombre(form?.componente_id))
  //     .then(({ data }) => setComponentes(data))
  //     .catch(err => console.log(err))
  // }, [form])


  const handleFetch = async (e) => {
    e.preventDefault()

    const sanitazedIcon = iconSanatizerFaMdi(icono)

    let data = {
      titulo: form?.titulo,
      nombre_componente: form?.nombre_componente?.split('-')[0],
      menu_padre_id: form?.menu_padre_id,
      url_redireccion: form?.url_redireccion,
      es_url_externo: form?.es_url_externo,
      movil: form?.movil,
      icono_libreria: sanitazedIcon?.libreria,
      icono_fuente: sanitazedIcon?.fuente ?? null,
      icono_icono: sanitazedIcon?.icono,
      estatus: Number(form?.estatus) ? true : false
    }

    if (!form?.frontend_id.includes('*NULL*')) {
      data = {
        ...data,
      frontend_id: idExtractor(form?.frontend_id),
    }}

    // for (const [k, v] of data) {
    //   console.log(k, v);
    // }

    axios(CONSTANT.queryUrl(id), {
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'application/json',
        '_method': 'PUT'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.titulo} actualizado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (

    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {form
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                labelText='Estatus'
                name='estatus'
                value={form?.estatus}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
                classes='justify-content-end'
              />

              <Switch
                labelText='Móvil'
                name='movil'
                value={form?.movil}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
                classes='justify-content-end'
              />

              <Switch
                labelText='Escritorio'
                name='escritorio'
                value={form.escritorio}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
                classes='justify-content-end'
              />

              <Switch
                labelText='Es URL externo'
                name='es_url_externo'
                value={form?.es_url_externo}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
                classes='justify-content-end'
              />

              <FormInput
                labelText='Título'
                name='titulo'
                value={form?.titulo}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='Menú Padre'
                type="number"
                name='menu_padre_id'
                value={form?.menu_padre_id}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <SelectFilter
                name="frontend_id"
                value={form?.frontend_id?.split('-')[1] ?? ''}
                labelText="* Frontend"
                optionObj={selectFilter.frontendId.map(({ id, nombre }) => ({ id, name: nombre }))}
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <IconPicker
                value={icono}
                handleValue={setIcono}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                isDisabled
                labelText='Componente'
                name='componente'
                value={form?.componente_id}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='URL de redirección'
                name='url_redireccion'
                value={form?.url_redireccion}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />
            </div>

            <Button
              type="submit"
              text="Actualizar"
              color="primary"
              icon="Circle"
              onClick={handleFetch}
            />
          </CustomForm>
          : <CustomLoader />
        }
      </div>
    </div>

  );
};

export default Update;

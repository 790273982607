export const idExtractor = (str, alt = false) => {
   let separator = alt ? '%-%' : '-'
   if (typeof str !== 'string') {
      return null
   }
   const [id] = str.split(separator)
   if (!id || typeof Number(id) !== 'number') {
      return null
   }
   return alt ? id : Number(id)
}
import axios from 'axios';

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from "../../redux/actions/toaster";
import { resultadosRegiones } from '../../redux/actions/regiones';


// Components
import CustomLoader from "../../components/CustomLoader";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import Switch from "../../components/Switch";
import Table from "../../components/Table";


// Endpoints
import { BASE_URL } from "../../endpoints";
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";


const CONSTANT = {
  pathUrl: '/generales/regiones',
  permissionsPath: 'regiones',
  title: 'Regiones',
}


const dataFormatter = (objFiltros) => {
  let data = {}

  Object.entries(objFiltros).forEach(([key, value]) => {
    if (key === 'busqueda') return

    if (key === 'patron_identificacion' && value.length) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          [key]: [value]
        }
      }
      return
    }

    if (key === 'creacion_desde' && value.length > 0) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          created_at_desde: value
        }
      }
      return
    }

    if (key === 'creacion_hasta' && value.length > 0) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          created_at_hasta: value
        }
      }
      return
    }

    if (value.length > 0 && key === 'estatus') {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          [key]: value.map(value => value === 'Activo' ? 1 : 0)
        }
      }
      return
    }

    if (value.length > 0) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          [key]: value
        }
      }
    }
  })

  return data
}


const RegionesTable = () => {
  const { resultados: response, filtros } = useSelector(state => state.regiones)
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const { busqueda } = filtros
  const { user } = useSelector(state => state.auth)


  const handleUrl = (url) => {
    let data = { busqueda, ...dataFormatter(filtros) }

    dispatch(resultadosRegiones(null))

    axios(url + `&limit=${showing_results}`,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data
      })
      .then(({ data }) => dispatch(resultadosRegiones(data.regiones)))
      .catch(err => console.error(err))
  }


  const handleEstatus = async (objData, url) => {
    let newEstatus = objData.estatus ? 0 : 1

    let data = new FormData()
    data.append('estatus', newEstatus)
    data.append('_method', 'PUT')

    await axios(getOne.region(objData.id), {

      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'multipart/form-data'
      },
      data
    })
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `Región: ${res.data.nombre} - estatus actualizado con éxito`,
          icon: 'success'
        }

        dispatch(fireToaster(toasterContent))
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })

    // dispatch(resultadosRegiones(null))
    axios(url,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: { busqueda, ...dataFormatter(filtros) }
      })
      .then(res => {
        dispatch(resultadosRegiones(res.data.regiones))
      })
      .catch(err => console.error(err))
  }


  return (
    <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
      {!response && <CustomLoader blocking={'partial'} />}

      <Table response={response} handlePagination={handleUrl}>
        <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
          <tr role="row">
            <th>ID</th>
            <th>Nombre</th>
            <th>País</th>
            <th>Cod Maximise</th>
            <th>Bandera</th>
            <th>Creación</th>
            <th>Estatus</th>
            <th className="w-max">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {response?.data?.length
            ? response.data?.map((data) => {
              const imagen_bandera = `${BASE_URL}storage/${data.imagen_bandera}` ?? '/placeholder.png'

              return (
                <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                  <td>{data?.id}</td>
                  <td>{data?.nombre ?? "N/A"}</td>
                  <td>{data?.neu_pais?.nombre ?? "N/A"}</td>
                  <td>{data?.cod_maxi ?? "N/A"}</td>
                  <td>{data?.imagen_bandera ? <img className="height-listado" src={imagen_bandera} alt={data.nombre} /> : "N/A"}</td>
                  <td>{data?.created_at.split("T")[0] || 'N/A'}</td>
                  <td>
                    <Switch value={data.estatus} labelText={""} handleValue={() => handleEstatus(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`)} />
                  </td>
                  <td>
                    <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                      {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                        <DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
                      }

                      {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
                        <DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
                      }
                    </DropdownWrapper>
                  </td>
                </tr>
              )
            })
            : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={8}><i>Sin datos para mostrar...</i></td></tr>
          }
        </tbody>
      </Table>
    </AccordionTable >
  )
}

export default RegionesTable
import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_FORMAS_PAGO,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosFormasPago = (data) => ({ type: RESULTADOS_FORMAS_PAGO, payload: data })

export const clearFilterFormasPago = () => ({ type: FILTER_CLEAR });

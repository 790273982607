import axios from 'axios'
import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { FILTER_BUSQUEDA } from '../../redux/redux-types'
import { busquedaFilter, clearFilterMetodosEntrega, resultadosMetodosEntrega } from '../../redux/actions/metodosEntrega'


// Components
import FormInput from '../FormInput'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'


const CONSTANT = {
	queryUrlGetAll: getAll.metodos_entrega,
	redirectUrl: '/metodos-entrega',
	title: 'Búsqueda de métodos de entrega',
	reduxClearFilters: (data) => clearFilterMetodosEntrega(data),
	reduxSetResults: (data) => resultadosMetodosEntrega(data)
}

const MetodosEntregaFiltros = () => {
	const { filtros } = useSelector(state => state.metodosEntrega)
	const { showing_results } = useSelector(state => state.listedResults)
	const { busqueda } = filtros
	const dispatch = useDispatch()

	const [isConsulting, setIsConsulting] = useState(false)


	// EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
	useEffect(() => {
		axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}`, {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
			.then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
			.catch(err => console.error(err))
	}, [dispatch, showing_results])


	// EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
	useEffect(() => {
		return () => dispatch(CONSTANT.reduxClearFilters())
	}, [dispatch])


	// FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
	const handleFetchFilter = (reset = false) => {
		setIsConsulting(true)

		if (reset) {
			dispatch(CONSTANT.reduxClearFilters())
			return axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}`, {
				headers: {
					'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
				},
			})
				.then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
				.catch(err => console.error(err))
				.finally(() => {
					setIsConsulting(false)
				})
		}

		axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}` + queryParamsFormatter({ busqueda }), {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
			.then(({ data }) => {
				dispatch(CONSTANT.reduxSetResults(data))
				dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
			})
			.catch(err => {
				console.error(err);
				const { errores } = err.response.data
				let detalles = []
				Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

				const toasterContent = {
					title: `
            Operación fallida
         (${err.response.status} - ${err.response.statusText})
         `,
					html: `
      < b > Detalle: </b >
      ${detalles.map(error => `<br /><i>-${error}</i>`)}
         `,
					icon: 'error'
				}
				dispatch(fireToaster(toasterContent))
			})
			.finally(() => {
				setIsConsulting(false)
			})
	}

	// MANEJADORES DE FILTRO
	const handleAddFilter = (e, filter) => {
		const { value } = e.target

		switch (filter) {
			case FILTER_BUSQUEDA:
				dispatch(busquedaFilter(value))
				break;

			default:
				break;
		}
	}


	return (
		<div className="card mb-50 shadow-none bg-transparent">
			<ListadoHeader
				classes='border rounded-2'
				title={`${CONSTANT.title}(${filtrosAplicadosTotal(filtros)})`}
				handleDisable={isConsulting}
				handleClickSearch={() => handleFetchFilter()}
				handleClickClearFilter={() => handleFetchFilter(true)}
			>
				<>
					<FormInput
						labelText='Búsqueda'
						placeholder='Ingrese su búsqueda'
						size='col-12'
						sizeDesk='col-md-4'
						handleValue={(e) => handleAddFilter(e, FILTER_BUSQUEDA)}
						value={busqueda}
					/>
				</>
			</ListadoHeader>
		</div>
	)
}

export default MetodosEntregaFiltros
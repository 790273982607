import {
  FILTER_COTIZACION_ID,
  FILTER_ADD_METODO_PAGO,
  FILTER_REMOVE_METODO_PAGO,
  FILTER_ADD_PUNTO_ENTREGA,
  FILTER_REMOVE_PUNTO_ENTREGA,
  FILTER_ADD_COMUNA,
  FILTER_REMOVE_COMUNA,
  FILTER_NOMBRE_CLIENTE,
  FILTER_CLEAR,
  RESULTADOS_COTIZACIONES,
  FILTER_CREACION_DESDE,
  FILTER_CREACION_HASTA,
  FILTER_REMOVE_EJECUTIVO,
  FILTER_ADD_EJECUTIVO,
  FILTER_REMOVE_FRONTEND,
  FILTER_ADD_FRONTEND,
  FILTROS_PERSISTENTES_COTIZACIONES,
  RESULTADOS_RESUMEN_COTIZACIONES,
} from "../redux-types";

export const metodoPagoFilter = (data, isRemove = false) =>
  isRemove ? { type: FILTER_REMOVE_METODO_PAGO, payload: data } : { type: FILTER_ADD_METODO_PAGO, payload: data };

export const ejecutivoFilter = (data, isRemove = false) =>
  isRemove ? { type: FILTER_REMOVE_EJECUTIVO, payload: data } : { type: FILTER_ADD_EJECUTIVO, payload: data };

export const comunaFilter = (data, isRemove = false) =>
  isRemove ? { type: FILTER_REMOVE_COMUNA, payload: data } : { type: FILTER_ADD_COMUNA, payload: data };

export const frontendFilter = (data, isRemove = false) =>
  isRemove ? { type: FILTER_REMOVE_FRONTEND, payload: data } : { type: FILTER_ADD_FRONTEND, payload: data };

export const puntoEntregaFilter = (data, isRemove = false) =>
  isRemove ? { type: FILTER_REMOVE_PUNTO_ENTREGA, payload: data } : { type: FILTER_ADD_PUNTO_ENTREGA, payload: data };


export const clienteFilter = (data) => ({ type: FILTER_NOMBRE_CLIENTE, payload: data });
export const cotizacionIdFilter = (data) => ({ type: FILTER_COTIZACION_ID, payload: data });
export const desdeFilter = (data) => ({ type: FILTER_CREACION_DESDE, payload: data });
export const hastaFilter = (data) => ({ type: FILTER_CREACION_HASTA, payload: data });

export const filtrosPersistentesCotizaciones = (data) => ({ type: FILTROS_PERSISTENTES_COTIZACIONES, payload: data })

export const clearFilterCotizaciones = () => ({ type: FILTER_CLEAR });

export const resultadosCotizaciones = (data) => ({ type: RESULTADOS_COTIZACIONES, payload: data });

export const resultadosResumenCotizaciones = (data) => ({ type: RESULTADOS_RESUMEN_COTIZACIONES, payload: data });

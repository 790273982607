import { idExtractor } from "../../../helpers/idExtractor"
import { isValidCreateForm, isValidUpdateForm } from "./validations"

export const createFormatter = (form) => {
  const validations = isValidCreateForm(form)

  if (!validations.isValid) {
    console.error({ msg: "Error en validaciones", validations })
    return {}
  }

  const formData = new FormData()
  // string
  formData.append('nombre', form?.nombre)
  formData.append('sku', form?.sku)
  formData.append('descripcion', form?.descripcion)
  formData.append("meta-descripcion", form["meta-descripcion"])
  formData.append("meta-titulo", form["meta-titulo"])
  formData.append("datos-estructurados", form["datos-estructurados"])

  // bool
  formData.append('es_variante', form?.es_variante ? 1 : 0)
  formData.append('nuevo', form?.nuevo ? 1 : 0)
  formData.append('publicado', form?.publicado ? 1 : 0)
  formData.append('mostrar_en_cliente', form?.mostrar_en_cliente ? 1 : 0)

  // int
  !form.categoria_id.includes('*NULL*') && formData.append('categoria_id', idExtractor(form.categoria_id))
  formData.append('precio_final', form?.precio_final)
  // formData.append('stock', form?.stock)
  form?.ultimo_costo && formData.append('ultimo_costo', form?.ultimo_costo) // optional

  // arr
  form?.imagenes?.lenght && form?.imagenes.forEach(({ file }) => formData.append("imagenes[]", file))

  return formData
}

export const updateFormatter = (form) => {
  const validations = isValidUpdateForm(form)

  if (!validations.isValid) {
    console.error({ msg: "Error en validaciones", validations })
    return {}
  }

  const formData = new FormData()
  // string
  formData.append('_method', 'PUT')
  formData.append('nombre', form?.nombre)
  // formData.append('sku', form?.sku) nota: no se permite modificar el SKU
  formData.append('descripcion', form?.descripcion)
  // formData.append("meta-descripcion", form["meta-descripcion"]) nota: campo no mayor a 255 char
  formData.append("meta-titulo", form["meta-titulo"])
  formData.append("datos-estructurados", form["datos-estructurados"])

  // bool
  formData.append('es_variante', form?.es_variante ? 1 : 0)
  formData.append('nuevo', form?.nuevo ? 1 : 0)
  formData.append('publicado', form?.publicado ? 1 : 0)
  formData.append('mostrar_en_cliente', form?.mostrar_en_cliente ? 1 : 0)

  // int
  // !form.categoria_id.includes('*NULL*') && formData.append('categoria_id', idExtractor(form.categoria_id)) nota: no se permite modificar
  formData.append('precio_final', Number(form?.precio_final))
  form?.ultimo_costo && formData.append('ultimo_costo', Number(form?.ultimo_costo)) // optional

  // arr
  // formData.append("imagenes", form?.imagenes?.[0]?.file)

  return formData
}
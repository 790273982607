import {
   FILTER_NOMBRE_CLIENTE,
   FILTER_CLEAR,
   FILTER_PAIS,
   FILTER_FECHA_INICIO,
   FILTER_FECHA_FINAL,
   FILTER_FECHA_AGENDA,
   FILTER_ADD_USUARIO,
   FILTER_REMOVE_USUARIO,
   FILTER_REMOVE_PUNTO_ENTREGA,
   FILTER_ADD_PUNTO_ENTREGA,
   FILTER_REMOVE_METODO_ENTREGA,
   FILTER_ADD_METODO_ENTREGA,
   FILTER_ADD_METODO_PAGO,
   FILTER_REMOVE_METODO_PAGO,
   FILTER_REMOVE_DIVISA,
   FILTER_ADD_DIVISA,
   FILTER_REMOVE_TIPO_FACTURACION,
   FILTER_ADD_TIPO_FACTURACION,
   FILTER_REMOVE_ESTATUS_PEDIDO,
   FILTER_ADD_ESTATUS_PEDIDO,
   RESULTADOS_PEDIDOS,
   FILTER_PEDIDO_ID,
   FILTER_REMOVE_ESTATUS_PAGO,
   FILTER_ADD_ESTATUS_PAGO,
   FILTER_REMOVE_FRONTEND,
   FILTER_ADD_FRONTEND,
   FILTROS_PERSISTENTES_PEDIDOS
} from "../redux-types";

export const clearFilterPedidos = () => ({ type: FILTER_CLEAR });
export const resultadosPedidos = (data) => ({ type: RESULTADOS_PEDIDOS, payload: data });
export const filtrosPersistentesPedidos = (data) => ({ type: FILTROS_PERSISTENTES_PEDIDOS, payload: data })


// params
export const busquedaUsuarioFilter = (data) => ({ type: FILTER_NOMBRE_CLIENTE, payload: data })
export const pedidoIdFilter = (data) => ({ type: FILTER_PEDIDO_ID, payload: data })
export const paisFilter = (data) => ({ type: FILTER_PAIS, payload: data })
export const agendaDesdeFilter = (data) => ({ type: FILTER_FECHA_INICIO, payload: data })
export const agendaHastaFilter = (data) => ({ type: FILTER_FECHA_FINAL, payload: data })
export const agendaFechaFilter = (data) => ({ type: FILTER_FECHA_AGENDA, payload: data })


// body
export const estadoPagoFilter = (data, isRemove) => isRemove
   ? ({ type: FILTER_REMOVE_ESTATUS_PAGO, payload: data })
   : ({ type: FILTER_ADD_ESTATUS_PAGO, payload: data })

export const usuarioFilter = (data, isRemove) => isRemove
   ? ({ type: FILTER_REMOVE_USUARIO, payload: data })
   : ({ type: FILTER_ADD_USUARIO, payload: data })

export const frontendFilter = (data, isRemove) => isRemove
   ? ({ type: FILTER_REMOVE_FRONTEND, payload: data })
   : ({ type: FILTER_ADD_FRONTEND, payload: data })

export const puntoEntregaFilter = (data, isRemove) => isRemove
   ? ({ type: FILTER_REMOVE_PUNTO_ENTREGA, payload: data })
   : ({ type: FILTER_ADD_PUNTO_ENTREGA, payload: data })

export const metodoEntregaFilter = (data, isRemove) => isRemove
   ? ({ type: FILTER_REMOVE_METODO_ENTREGA, payload: data })
   : ({ type: FILTER_ADD_METODO_ENTREGA, payload: data })

export const metodoPagoFilter = (data, isRemove) => isRemove
   ? ({ type: FILTER_REMOVE_METODO_PAGO, payload: data })
   : ({ type: FILTER_ADD_METODO_PAGO, payload: data })

export const divisaFilter = (data, isRemove) => isRemove
   ? ({ type: FILTER_REMOVE_DIVISA, payload: data })
   : ({ type: FILTER_ADD_DIVISA, payload: data })

export const tipoFacturacionFilter = (data, isRemove) => isRemove
   ? ({ type: FILTER_REMOVE_TIPO_FACTURACION, payload: data })
   : ({ type: FILTER_ADD_TIPO_FACTURACION, payload: data })

export const estatusPedidoFilter = (data, isRemove) => isRemove
   ? ({ type: FILTER_REMOVE_ESTATUS_PEDIDO, payload: data })
   : ({ type: FILTER_ADD_ESTATUS_PEDIDO, payload: data })
import axios from 'axios'
import React, { useEffect, useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import {
  busquedaFilter,
  categoriaFilter,
  clavesFilter,
  clearFilterProductos,
  etiquetaFilter,
  filtrosPersistentesProductos,
  precioDesdeFilter,
  precioHastaFilter,
  publicadoFilter,
  resultadosProductos,
  skusFilter
} from '../../redux/actions/productos'
import { FILTER_ADD_CATEGORIA, FILTER_ADD_CLAVE, FILTER_ADD_ETIQUETA, FILTER_BUSQUEDA_PRODUCTO, FILTER_PRECIO_DESDE, FILTER_PRECIO_HASTA, FILTER_PUBLICADO, FILTER_SKUS_PRODUCTO } from '../../redux/redux-types'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Components
import FiltroAplicado from './FiltroAplicado'
import Button from '../Button'
import FormInput from '../FormInput'
import SelectFilter from '../SelectFilter'


// Helpers
import { lastPosition } from '../../helpers/lastPosition'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'


// Endpoints
import { getAll } from '../../endpoints/getAll'
import ModalProductosFiltros from '../Modal/Productos'
import { clearFiltersModal, displayModal, filtrosPersistentesModal } from '../../redux/actions/modalProductosFiltros'
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import capitalize from '../../helpers/capitalize'
import { useLocation } from 'react-router-dom'
import DownloadFile from '../Download/DownloadFile'
import { setListedResults } from '../../redux/actions/listedResults'
import { persistentFilters } from '../../helpers/pesistentFilters'


const CONSTANT = {
  queryUrlGetAll: getAll.productos,
  queryExportar: getAll.productos + `/exportar-tabla`,
  queryUrlGetAllEtiquetasProductos: getAll.etiquetas_productos,
  redirectUrl: '/proveedores',
  title: 'Búsqueda de poveedores',
  reduxClearFilters: clearFilterProductos,
  reduxClearModalFilters: clearFiltersModal,
  reduxSetResults: (data) => resultadosProductos(data),

  queryUrlGetAllCategorias: getAll.categorias + '/buscar',
  queryUrlGetAllEtiquetas: getAll.etiquetas + '/listar?limit=9999',
  queryGetAllClavesAtributosFiltros: getAll.claves_atributos + '/filtros',

  publicacionSelect: [
    { id: 1, nombre: 'Publicado' },
    { id: 0, nombre: 'No publicado' },
    { id: 'null', nombre: 'Ambos' },
  ]
}


const fecthDataFormatter = (filtrosObj, modalObj) => {
  let data = {}

  Object.entries(filtrosObj).forEach(([k, v]) => {
    if (!v?.length) return

    if (k === 'publicado' && v === 'null') return

    if (k === 'busqueda') return

    if (['categorias_id', 'etiqueta_id'].includes(k)) {
      data = {
        ...data,
        [k]: v.map(el => Number(el.split('-')[0]))
      }
    }

    if (k === 'skus') {
      data = {
        ...data,
        [k]: v
      }
    }

    if (['precio_hasta', 'precio_desde'].includes(k)) {
      data = {
        ...data,
        filtro_precio: {
          ...data.filtro_precio,
          [k]: Number(v)
        }
      }
    }
  })

  if (Object.keys(modalObj).length) {
    let filtros = {}
    Object.entries(modalObj).forEach(([k, v]) => {
      if (!v?.length) return
      filtros = {
        ...filtros,
        [k]: v.map(el => Number(el.split('-')[0]))
      }
    })
    data = { ...data, filtros }
  }

  return data
}


const locationWithoutServices = ['productos']


const ProductosFiltros = ({
  isAsignacionMasiva = false,
  isCotizaciones = false,
  puntoEntrega = null,
  isOpen = false,
  mobile = false,
  firstQuery = true,
  filtrosRapidos = true,
  isListadoErp
}) => {
  const { filtros } = useSelector(state => state.productos)
  const { filtros_productos, resultados } = useSelector(state => state.modalProductosFiltros)
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const { categorias_id, busqueda, etiqueta_id, publicado, skus } = filtros
  const [categorias, setCategorias] = useState(null)
  const [publicacion] = useState(CONSTANT.publicacionSelect)
  const [AM] = useState(isAsignacionMasiva)
  const [currentUrl] = useState(useLocation())
  const isProductLocation = window.location.pathname === `/productos`

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RESETEA LOS FILTROS
  useEffect(() => {
    (currentUrl?.pathname === '/productos')
      ? dispatch(setListedResults('100'))
      : dispatch(setListedResults('20'))

    dispatch(CONSTANT.reduxClearFilters())
    dispatch(CONSTANT.reduxClearModalFilters())
  }, [currentUrl, dispatch])


  // RENDERIZA RESULTADOS POR PRIMERA VEZ
  useEffect(() => {
    if (!firstQuery) {
      dispatch(CONSTANT.reduxSetResults([]))
      return
    }

    const queryURL = (showing_results) => {
      if (isCotizaciones || isListadoErp) return CONSTANT.queryUrlGetAll + `/listar?limit=${showing_results}&bodegas_con_saldo=1&stock_erp=1&incluir_servicios=${isProductLocation ? '0' : '1'}`
      else return CONSTANT.queryUrlGetAll + `/listar?stock_erp=0&limit=${AM ? '15' : showing_results}&incluir_servicios=${isProductLocation ? '0' : '1'}`
    }

    const filtrosParams = persistentFilters(window.location)?.obj
    let url = queryURL(showing_results) + queryParamsFormatter({ publicado: publicado?.split('-')?.[0] ?? '1' })
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
      data: fecthDataFormatter(filtros, filtros_productos)
    }

    if (filtrosParams?.filtros_comp && filtrosParams?.filtros_modal && isListadoErp) {
      dispatch(filtrosPersistentesProductos(filtrosParams.filtros_comp))
      dispatch(filtrosPersistentesModal(filtrosParams.filtros_modal))
      url = queryURL(showing_results) + queryParamsFormatter(
        {
          busqueda: filtrosParams.filtros_comp.busqueda,
          publicado: filtrosParams.filtros_comp.publicado?.split('-')?.[0] ?? '1',
        }
      )
      config = { ...config, data: fecthDataFormatter(filtrosParams.filtros_comp, filtrosParams.filtros_modal) }
    }

    axios(url, config)
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data.productos)))
      .catch(err => console.error(err))
    return () => dispatch(CONSTANT.reduxSetResults(null))
  }, [showing_results, dispatch, AM, isCotizaciones, isListadoErp])


  //EFECTO QUE RELLENA EL SELECTFILTER DE: categorias
  useEffect(() => {
    axios(CONSTANT.queryUrlGetAllCategorias, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
      .then(({ data }) => setCategorias(data.categorias))
      .catch(err => console.error(err))
  }, [])


  // FUNCION MANEJADORA QUE CONSULTA EL ENDPOINT CON LOS FILTROS APLICADOS
  const handleFetchFilter = async (reset = false) => {
    let paramPublicado = publicado.includes('null') ? '' : publicado?.split('-')?.[0]
    let data = {}
    if (isCotizaciones && !puntoEntrega) return dispatch(fireToaster({ title: 'Por favor, seleccione un punto de entrega para continuar', icon: 'warning' }))

    setIsConsulting(true)

    const queryURL = (isAM) => {
      if (isCotizaciones) return CONSTANT.queryUrlGetAll + `/listar?limit=99999&punto_entrega_id=${puntoEntrega}&bodegas_con_saldo=1&stock_erp=1&incluir_servicios=${isProductLocation ? '0' : '1'}`
      if (isListadoErp) return CONSTANT.queryUrlGetAll + `/listar?limit=${isAM ? '99999' : showing_results}&stock_erp=1&bodegas_con_saldo=1&incluir_servicios=${isProductLocation ? '0' : '1'}`
      else return CONSTANT.queryUrlGetAll + `/listar?limit=${isAM ? '99999' : showing_results}&stock_erp=0&asignacion_masiva=1&incluir_servicios=${isProductLocation ? '0' : '1'}`
    }

    if (reset) {
      const queryURL = () => {
        if (isCotizaciones || isListadoErp) return CONSTANT.queryUrlGetAll + `/listar?limit=${showing_results}&bodegas_con_saldo=1&stock_erp=1${publicado?.split('-')?.[0] ?? '1'}&incluir_servicios=${isProductLocation ? '0' : '1'}`
        else return CONSTANT.queryUrlGetAll + `/listar?stock_erp=0&limit=${AM ? '15' : showing_results}&asignacion_masiva=1&incluir_servicios=${isProductLocation ? '0' : '1'}`
      }
      dispatch(CONSTANT.reduxClearFilters())
      dispatch(CONSTANT.reduxClearModalFilters())
      return axios(queryURL(),
        {
          headers: {
            'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
          },
          method: 'POST'
        })
        .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data.productos)))
        .catch(err => console.error(err))
        .finally(() => {
          setIsConsulting(false)
        })
    }

    // FORMATEADOR DE data
    else {
      data = fecthDataFormatter(filtros, filtros_productos)
    }

    dispatch(CONSTANT.reduxSetResults(null))

    await axios(queryURL(AM) + queryParamsFormatter({ busqueda, publicado: paramPublicado }), {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'application/json',
      },
      data
    })
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data?.productos ?? []))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
        window.history.pushState({}, '', persistentFilters(window.location, { filtros_comp: filtros, filtros_modal: filtros_productos }).urlWithFilters)
      })
      .catch(err => {
        console.error(err)

        const { errores } = err.response.data
        let detalles = []

        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  // MANEJADORES DE FILTRO - PRODUCTOS
  const handleFilter = (e, filter) => {
    const { value } = e.target
    switch (filter) {
      case FILTER_ADD_CATEGORIA:
        dispatch(categoriaFilter(value))
        break;

      case FILTER_PRECIO_DESDE:
        dispatch(precioDesdeFilter(value))
        break;

      case FILTER_PRECIO_HASTA:
        dispatch(precioHastaFilter(value))
        break;

      case FILTER_ADD_ETIQUETA:
        dispatch(etiquetaFilter(value))
        break;

      case FILTER_ADD_CLAVE:
        dispatch(clavesFilter(value))
        break;

      case FILTER_BUSQUEDA_PRODUCTO:
        dispatch(busquedaFilter(value))
        break;

      case FILTER_SKUS_PRODUCTO:
        dispatch(skusFilter(value))
        break;

      case FILTER_PUBLICADO:
        dispatch(publicadoFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className={`card mb-50 shadow-none bg-transparent ${isAsignacionMasiva && mobile ? 'col-md-6 col-12' : 'col-12'}`}>
      <ModalProductosFiltros />

      <ListadoHeader
        classes='border rounded-2'
        title={`Búsqueda de productos (${filtrosAplicadosTotal(filtros)} - Modal: ${filtrosAplicadosTotal(filtros_productos)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
        exportBtn={
          <DownloadFile
            url={CONSTANT.queryExportar + '?asignacion_masiva=1&exportar_atributos=1' + queryParamsFormatter({ busqueda, publicado: publicado.includes('null') ? '' : publicado?.split('-')?.[0] })}
            data={{ data: fecthDataFormatter(filtros, filtros_productos) }}
          />
        }
        isOpen
      >
        <FormInput
          labelText='Búsqueda'
          placeholder='Búsqueda'
          size='col-12'
          sizeDesk={isAsignacionMasiva && mobile ? 'col-md-6' : 'col-md-4'}
          handleValue={(e) => handleFilter(e, FILTER_BUSQUEDA_PRODUCTO)}
          value={busqueda}
        />

        <FormInput
          labelText='SKUs'
          placeholder='SKUs'
          size='col-12'
          sizeDesk={isAsignacionMasiva && mobile ? 'col-md-6' : 'col-md-4'}
          handleValue={(e) => handleFilter(e, FILTER_SKUS_PRODUCTO)}
          value={skus}
        />

        <SelectFilter
          labelText='Categoría'
          size='col-12'
          sizeDesk={isAsignacionMasiva && mobile ? 'col-md-6' : 'col-md-3'}
          handleValue={(e) => handleFilter(e, FILTER_ADD_CATEGORIA)}
          optionObj={categorias?.map(({ id, nombre }) => ({ id, name: nombre }))}
          value={lastPosition(categorias_id)}
        />

        <SelectFilter
          labelText='Estatus de publicación'
          size='col-12'
          sizeDesk={isAsignacionMasiva && mobile ? 'col-md-6' : 'col-md-3'}
          handleValue={(e) => handleFilter(e, FILTER_PUBLICADO)}
          optionObj={publicacion?.map(({ id, nombre }) => ({ id, name: nombre }))}
          value={publicado?.split('-')?.[1] ?? 'Seleccione'}
        />

        <hr className='my-1' />

        {filtrosRapidos &&
          <p className='mb-25 text-black'>Filtros rápidos</p>
        }

        {filtrosRapidos && resultados &&
          resultados.filter(({ atributo_filtrable }) => atributo_filtrable).sort((a, b) => (b.nombre > a.nombre) ? -1 : 1).map((clave, i) => (
            <div key={clave?.nombre + `-filtro_rapido-${i}`} className="col-12 col-md-2 my-auto" style={{ minWidth: '200px' }}>
              <SelectFilter
                labelText={<b>{clave?.nombre?.split('_')?.join(' ').toUpperCase()}</b>}
                size='col-12'
                sizeDesk='col-md-12'
                handleValue={(e) => handleFilter({ target: { value: `${e.target.value}-${clave.nombre}` } }, FILTER_ADD_CLAVE)}
                optionObj={clave.valores?.map(({ id, nombre }) => ({ id, name: nombre.split('-').length > 1 ? `${nombre.split('-').join('_')}` : nombre }))}
                value={lastPosition(filtros_productos?.[clave?.nombre])}
              />
            </div>
          ))}


        <div className="col-12 col-md-1 my-auto">
          <Button icon={'Plus'} text='Filtros' className={'w-fit my-auto'} onClick={() => dispatch(displayModal(true))} />
        </div>

        {/* FILTROS APLICADOS - PRODUCTOS */}
        <hr className='my-1' />

        <p className='mb-25 text-black'>Filtros aplicados</p>
        <FiltroAplicado array={categorias_id} func={categoriaFilter} title={'Categorías'} />
        {AM === 'etiquetas' && <FiltroAplicado array={etiqueta_id} func={etiquetaFilter} title={'Etiquetas'} />}

        {Object?.keys(filtros_productos)?.length
          ? Object?.entries(filtros_productos).map(([nombreFiltro, valoresArr]) => (valoresArr.length)
            ? <FiltroAplicado key={capitalize(nombreFiltro.split('_').join(' '))} array={valoresArr} title={capitalize(nombreFiltro.split('_').join(' '))} func={clavesFilter} />
            : <></>
          )

          : <></>
        }
        <hr className='my-1' />
      </ListadoHeader>
    </div>
  )
}

export default ProductosFiltros
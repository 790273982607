import React, { useState } from 'react'


// Components


// Layouts
import AccordionTable from '../../layout/AccordionTable'


// Hooks
import { useWindowDimensions } from '../../hooks/useDimensions'
import Pagination from '../PaginationR'
import Button from '../Button'
import Modal from '../Modal'


// Mocks
const mock = [
   {
      id: 1,
      producto: {
         nombre: 'Neumatico pirelli 225/65 R16 88H Pzero RUNFLAT - 1020134',
         estatus: 1,
         categoria: 'Neumático automoviles'
      },
      qty_fisica: 12,
      qty_reservada: 1,
      qty_fac_sin_despachar: 1,
      qty_disponible: 10,
      qty_por_llegar: 20,
      costo_promedio: 160000,
      precio_pagina: {
         normal: 357000,
         oferta: 357000
      },
      oferta: '4x3',
      stock_pagina: 10
   },
   {
      id: 2,
      producto: {
         nombre: 'Neumatico pirelli 225/65 R16 88H Pzero RUNFLAT - 1020134',
         estatus: 0,
         categoria: 'Neumático automoviles'
      },
      qty_fisica: 12,
      qty_reservada: 12,
      qty_fac_sin_despachar: 0,
      qty_por_llegar: 20,
      costo_promedio: 160000,
      precio_pagina: {
         normal: 357000,
         oferta: 357000
      },
      oferta: '4x3',
      stock_pagina: 11
   }
]

const modalMock = [
   {
      bodega: 1,
      qty_fisica: 10,
      qty_reservada: 1,
      qty_fac_sin_despachar: 1,
      qty_por_llegar: 0
   },
   {
      bodega: 2,
      qty_fisica: 10,
      qty_reservada: 2,
      qty_fac_sin_despachar: 3,
      qty_por_llegar: 0
   },
   {
      bodega: 3,
      qty_fisica: 10,
      qty_reservada: 4,
      qty_fac_sin_despachar: 6,
      qty_por_llegar: 0
   },
   {
      bodega: 1,
      qty_fisica: 10,
      qty_reservada: 1,
      qty_fac_sin_despachar: 1,
      qty_por_llegar: 0
   },
   {
      bodega: 2,
      qty_fisica: 10,
      qty_reservada: 2,
      qty_fac_sin_despachar: 3,
      qty_por_llegar: 0
   },
   {
      bodega: 3,
      qty_fisica: 10,
      qty_reservada: 4,
      qty_fac_sin_despachar: 6,
      qty_por_llegar: 0
   },
   {
      bodega: 1,
      qty_fisica: 10,
      qty_reservada: 1,
      qty_fac_sin_despachar: 1,
      qty_por_llegar: 0
   },
   {
      bodega: 2,
      qty_fisica: 10,
      qty_reservada: 2,
      qty_fac_sin_despachar: 3,
      qty_por_llegar: 0
   },
   {
      bodega: 3,
      qty_fisica: 10,
      qty_reservada: 4,
      qty_fac_sin_despachar: 6,
      qty_por_llegar: 0
   }
]


const ProductosStockTable = () => {
   const { width } = useWindowDimensions()
   const [modalDisplay, setModalDisplay] = useState(false)
   const [modalData, setModalData] = useState(modalMock)

   const handleModalDisplay = () => setModalDisplay(!modalDisplay)

   return (
      <>
         <Modal display={modalDisplay} handleValue={handleModalDisplay} sizeDesk='col'>
            <div className='m-2'>
               <a href='/producto/detalle'><h3 className='text-primary p-50 rounded-2 m-0'>{mock[0].producto.nombre}</h3></a>
               <hr className='mt-0 mb-2' />
               <div className={`card-datatable table-responsive pt-0 ${width > 1200 ? 'overflow-visible' : ''}`}>
                  <table className="user-list-table table dataTable no-footer dtr-column z-10 border ">
                     <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                        <tr role="row">
                           <th>Bodega</th>
                           <th>Qty física</th>
                           <th>Qty reservada</th>
                           <th>Qty fac. sin despachar</th>
                           <th>Qty disponible</th>
                           <th>Qty por llegar</th>
                        </tr>
                     </thead>
                     <tbody>
                        {
                           modalData.map((data, i) => {
                              const qty_disponible = data.qty_fisica - (data.qty_reservada + data.qty_fac_sin_despachar)
                              return (
                                 <tr className="odd multi-data fw-bold" key={`data-${i}-${data.bodega}`}>
                                    <td>{data.bodega}</td>
                                    <td>{data.qty_fisica}</td>
                                    <td className='text-red'>{data.qty_reservada}</td>
                                    <td className='text-red'>{data.qty_fac_sin_despachar}</td>
                                    <td className={`${qty_disponible ? 'text-green' : 'text-lgray'}`}>{qty_disponible}</td>
                                    <td>{data.qty_por_llegar}</td>
                                 </tr>
                              )
                           })
                        }
                     </tbody>
                  </table>
               </div>
            </div>
         </Modal>

         <AccordionTable title='Consultar Stock'>
            <div className={`card-datatable table-responsive pt-0 ${width > 1200 ? 'overflow-visible' : ''}`}>
               <table className="user-list-table table dataTable no-footer dtr-column z-10">
                  <thead className="table-light">
                     <tr role="row">
                        <th>ID</th>
                        <th className='col-3'>Producto</th>
                        <th>Qty física</th>
                        <th>Qty reservada</th>
                        <th>Qty fac. sin despachar</th>
                        <th>Qty disponible</th>
                        <th>Qty por llegar</th>
                        <th>Costo promedio</th>
                        <th className='col-2'>Precio página</th>
                        <th>Oferta</th>
                        <th>Stock página</th>
                        <th></th>
                     </tr>
                  </thead>
                  <tbody>
                     {
                        mock.map(data => {
                           const qty_disponible = data.qty_fisica - (data.qty_reservada + data.qty_fac_sin_despachar)
                           return (
                              <tr className="odd multi-data" key={`data-${data.id}-${data.producto.nombre}`}>
                                 <td>{data.id}</td>
                                 <td >
                                    <a href='/producto/detalle'><p>{data.producto.nombre ?? 'N/A'}</p></a>
                                    <p className={`tag ${data.producto.estatus ? 'success' : 'failed'} px-25 m-0`}>{data.producto.estatus ? 'Publicado' : 'Inactivo'}</p>
                                    <p className='sub-text-3'>{data.producto.categoria ?? 'N/A'}</p>
                                 </td>
                                 <td>{data.qty_fisica ?? 'N/A'}</td>
                                 <td className='text-red fw-bold'>{data.qty_reservada ?? 'N/A'}</td>
                                 <td className='text-red fw-bold'>{data.qty_fac_sin_despachar ?? 'N/A'}</td>
                                 <td className={`fw-bold ${qty_disponible > 0 ? 'text-green' : 'text-lgray'}`}>{qty_disponible}</td>
                                 <td>{data.qty_por_llegar ?? 'N/A'}</td>
                                 <td>{data.costo_promedio ?? 'N/A'}</td>
                                 <td>
                                    <p className='text-lgrey'>Normal</p>
                                    <p>$ {data.precio_pagina.normal ?? 'N/A'}</p>
                                    <p className='text-black fw-bold'>Oferta</p>
                                    <p className='text-black fw-bold'>$ {data.precio_pagina.oferta ?? 'N/A'}</p>

                                 </td>
                                 <td className='fw-bold'>{data.oferta ?? 'N/A'}</td>
                                 <td>{data.stock_pagina ?? 'N/A'}</td>
                                 <td><Button icon='MoreVertical' className='p-25' onClick={handleModalDisplay} /></td>
                              </tr>
                           )
                        })
                     }
                  </tbody>
               </table>
            </div>

            <div className='d-flex'>
               {/* <Pagination classes='mx-auto' /> */}
            </div>
         </AccordionTable>
      </>

   )
}

export default ProductosStockTable
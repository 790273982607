import React, { useEffect, useState } from 'react';


// Components
import Button from '../Button';


// Helpers


const SmallChart = ({ isRefresh, children, title, url, headerChildren }) => {

  return (
    <div className='col'>
      <div className="card overflow-hidden pb-3">
        <div className='card-header'>
          {/* Boton de redirección / Título de la Card */}
          <h4 className="card card-title">
            {title}
          </h4>

          {/* Acciones del Card Header */}
          <div className="d-flex">

            {headerChildren}

            {isRefresh &&
              <Button
                icon='RefreshCw'
                isRounded
                className='my-auto'
              />
            }
          </div>

        </div>
        <div className="card-body statistics-body">
          <div className="row justify-content-center">
            {children}
          </div>
        </div>

        {url &&
          <Button
            isLink
            url={url}
            text='Ir'
            icon='ArrowRight'
            className='link-btn-card'
          />
        }
      </div>
    </div>
  );
}


export default SmallChart;
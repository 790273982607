import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_TERMINOS_CONDICIONES,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosTerminosCondiciones = (data) => ({ type: RESULTADOS_TERMINOS_CONDICIONES, payload: data })

export const clearFilterTerminosCondiciones = () => ({ type: FILTER_CLEAR });


// Components
import FaqFiltros from "../../components/AccordionFilter/FAQ";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import FaqTable from "../../components/Tables/FaqTable";


const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>Preguntas frecuentes</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text='Crear pregunta frecuente' href={`create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<FaqFiltros />

			<FaqTable />
		</div>
	);
}

export default Listado;
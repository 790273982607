import React, { useState } from 'react'
import FileUploaderB from '../../../FileUploader_B'
import Button from '../../../Button'
import { addImgProducto } from '../../../../services/productos/imagenes/add.service'
import { useDispatch } from 'react-redux'
import { fireToaster } from '../../../../redux/actions/toaster'
import { responseToasterContent } from '../../../../helpers/toasterContent'
import { successResponse } from '../../../../helpers/successResponse'
import Modal from '../../../Modal'

export const AgregarImagenes = ({ data, modal, setModal, handleReturn = () => { } }) => {
  const [images, setImages] = useState([])
  const dispatch = useDispatch()

  const handleFetch = async (id) => {
    addImgProducto({ id, images })
      .then(data => {
        dispatch(fireToaster(responseToasterContent(data)))
        if (successResponse(data)) {
          handleReturn()
          setImages([])
          handleClose()
        }
      })
  }

  const handleClose = () => {
    setModal({ ...modal, display: false })
    setImages([])
  }

  return (
    <Modal display={modal.display} handleValue={handleClose} sizeDesk='col-md-12 h-fit'>
      <div className='m-2'>
        <h3 className='p-50 rounded-2 m-0'>Adjuntar imagen a producto #{data.id}</h3>
        <hr className='mt-0 mb-1' />

        <div className='col-12 mb-50'>
          <FileUploaderB value={images} handleValue={setImages} />
        </div>

        <div className='col-3'>
          <Button text='Adjuntar' color='success' onClick={() => handleFetch(data.id)} />
        </div>
      </div>
    </Modal>
  )
}

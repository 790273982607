

// Components
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import DropdownMenuLink from "../../components/Dropdown/Link";
import RegionesFiltros from "../../components/AccordionFilter/Regiones";
import RegionesTable from "../../components/Tables/RegionesTable";


const CONSTANT = {
	title: 'Regiones',
	path: 'regiones',
	create_btn: 'Crear región'
}


const Listado = () => {
	return (
		<>
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/generales/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<RegionesFiltros />

			<RegionesTable />
		</>
	);
}

export default Listado;
import React, { useState } from 'react';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { resultadosGarantiasRespuestas } from '../../redux/actions/garantiasRespuestas';


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Switch from "../Switch";
import Table from "../Table";
import IconFAMDI from './Icons/IconFAMDI';


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";


// Helpers
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter';
import { fireToaster } from '../../redux/actions/toaster';
import axios from 'axios';


const CONSTANT = {
  pathUrl: '/garantias/respuestas',
  permissionsPath: 'garantias',
  reduxSelector: 'garantiasRespuestas',
  queryUrlGetOne: getOne.garantia_respuestas,
  title: 'Respuestas de garantía',
  reduxSetResults: resultadosGarantiasRespuestas,
}


const GarantiasRespuestasTable = () => {
  const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const { busqueda } = filtros
  const { user } = useSelector(state => state.auth)

  const [isConsulting, setIsConsulting] = useState(false)


  // FUNCION MANEJADORA DE PAGINACIÓN
  const handleUrl = (url) => {
    dispatch(CONSTANT.reduxSetResults(null))
    axios(url + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        }
      }
    )
      .then(res => dispatch(CONSTANT.reduxSetResults(res.data)))
      .catch(err => console.log(err))
  }


  // FUNCIÓN MANEJADORA PARA BORRAR UN REGISTRO
  const handleFetch = async (id, url, target) => {
    setIsConsulting(true)

    let config
    let query = CONSTANT.queryUrlGetOne(id)

    if (target === 'delete') {
      if (!window.confirm('¿Está seguro que desea eliminar el registro?')) return
      config = {
        method: 'DELETE'
      }
    }

    config = {
      ...config,
      headers: {
        ...config.headers,
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    }

    await axios(query, config)
      .then(res => {
        if (target === 'delete') {
          const toasterContent = {
            title: 'Operación realizada',
            text: `${CONSTANT.title}: registro eliminado con éxito`,
            icon: 'info'
          }

          dispatch(fireToaster(toasterContent))
        }

        dispatch(CONSTANT.reduxSetResults(null))
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
         Operación fallida
         (${err.response.status} - ${err.response.statusText})
       `,
          html: `
         <b>Detalle: </b>
         ${detalles.map(error => `<br /><i>-${error}</i>`)}
       `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(async () => {
        await axios(url, {
          headers: {
            'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
          },
        })
          .then(({ data }) => {
            dispatch(CONSTANT.reduxSetResults(data))
          })
          .catch(err => console.error(err))

        setIsConsulting(false)
      })
  }


  return (
    <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
      {!response && <CustomLoader blocking={'partial'} />}

      <Table response={response} handlePagination={handleUrl}>
        <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
          <tr role="row">
            <th>ID</th>
            <th>Título</th>
            <th>Texto</th>
            <th style={{ maxWidth: '50px' }}>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {response?.data?.length
            ? response.data?.map((data) => {
              return (
                <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                  <td >{data.id}</td>

                  <td >{data.titulo}</td>

                  <td className='col-5'><textarea className='tx custom-scrollbar rounded-2 border' rows={3} readOnly value={data?.texto}>{data?.texto ?? 'N/A'}</textarea></td>

                  <td>
                    <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                      {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                        <DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}?filtros_persistentes=${JSON.stringify(filtros)}`} icon="Table" />
                      }

                      {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
                        <DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
                      }

                      {user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
                        <DropdownMenuLink text='Borrar' icon="X" onClick={() => handleFetch(data.id, response.path + '?page=' + response.current_page + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }), 'delete')} />
                      }
                    </DropdownWrapper>
                  </td>
                </tr>
              )
            })
            : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
          }
        </tbody>
      </Table>
    </AccordionTable >
  )
}

export default GarantiasRespuestasTable
import React from 'react'
import Icon from './Icon'

const Sorry = () => {
   return (
      <div className='row'>
         <div className='col'>
            <div className='building bg-white rounded-3 shadow'>
               <div className='rounded-3 overflow-hidden mx-auto row'>
                  <div className='col' style={{ minWidth: '250px' }}>
                     <img style={{ backgroundColor: '#ffe5a3' }} src='/error-404.png' className='img-fluid w-100 m-auto' alt='error-404' />
                  </div>

                  <div className='pb-3 col shadow flex-grow-1' style={{ backgroundColor: '#d4d692' }}>
                     <div className='my-auto'>
                        <h3 className='fs-1 px-3 py-1 text-white text-end me-1'>Oops, algo no está bien<Icon className='ms-25' icon='HelpCircle' size={20} /></h3>
                        <div className='my-auto mx-5 border p-2 rounded-3 shadow bg-white'>
                           <p className='my-0'><i>Lo sentimos, esta sección no está disponible o no existe.</i></p>
                           <p><i>Pruebe a volver al <a href='/home'><u>Inicio</u></a>.</i></p>
                        </div>
                        <p className='mt-2 ms-5 fs-5 text-white'><i>- Lamentamos las molestias.</i></p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Sorry
import { useEffect, useState } from 'react'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch, useSelector } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import CustomLoader from '../../components/CustomLoader'


// Router Dom
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'


// Endpoints
import { historicoData } from '../../helpers/historicoData'
import { ModalHistorico } from '../../components/Historico/ModalHistorico'
import TablaDetalle from '../../components/Historico/TablaDetalle'
import { getCuentaCorriente, getDetail } from '../../services/empresas/getDetail.service'
import Tabs from '../../components/Tabs'
import { CuentaCorrienteTable } from '../../components/Tables/Empresas/CuentaCorrienteTable'

import axios from 'axios'
import { PrecioFormatter } from '../../helpers/precioFormatter'
// import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'
const Precio = (v) => new PrecioFormatter(v).producto()

const CONSTANT = {
  title: 'Empresas',
  redirectListado: '/empresas',
  modelo: 'App\\Models\\NeuEmpresa',

  tabs: [
    { name: 'Información', url: '?tab=info' },
    { name: 'Cuenta corriente', url: '?tab=cuenta_corriente' }
  ]
}


const TabComponentSelector = ({ tab = 'info', data, historico, handleModal, handlePagination }) => {
  switch (tab) {
    case 'info':
      return (
        <>
          <Accordion
            title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
            classes='col-md-6 mx-auto mb-2'
            isOpen
          >
            <AccordionDetailData dataKey={'ID'} value={data?.id} />
            <AccordionDetailData dataKey={'Tipo de empresa'} value={data?.tipo_empresa?.toUpperCase() ?? 'Nueva empresa'} />
            <AccordionDetailData dataKey={'Razón social'} value={data?.razon_social ?? 'N/A'} />
            <AccordionDetailData dataKey={'RUT'} value={data?.rut ?? 'N/A'} />
            <AccordionDetailData dataKey={'% Descuento'} value={data?.porcentaje_descuento ?? 'N/A'} />
            <AccordionDetailData dataKey={'% Profit'} value={data?.profit ?? 'N/A'} />
            <AccordionDetailData dataKey={'Creación'} value={data?.created_at?.split('T')[0] ?? 'N/A'} />
            <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at?.split('T')[0] ?? 'N/A'} last />
          </Accordion>

          <Accordion
            title={<h3 className='m-0 w-100 text-center text-white'>Datos de facturacion</h3>}
            classes='col-md-6 mx-auto mb-2'
            isOpen
          >
            {data?.tiene_datos_factura
              ? <>
                <AccordionDetailData dataKey={'ID'} value={data?.datos_factura?.id} />
                <AccordionDetailData dataKey={'Giro'} value={data?.datos_factura?.empresa_giro ?? 'N/A'} />
                <AccordionDetailData dataKey={'Dirección'} value={data?.datos_factura?.empresa_direccion ?? 'N/A'} />
                <AccordionDetailData dataKey={'Creación'} value={data?.datos_factura?.created_at?.split('T')[0] ?? 'N/A'} />
                <AccordionDetailData dataKey={'Modicación'} value={data?.datos_factura?.updated_at?.split('T')[0] ?? 'N/A'} last />
              </>

              : <p className='mt-50 fs-4 text-center fw-bold'>Esta empresa aún no tiene datos de facturación</p>
            }
          </Accordion>

          <TablaDetalle historicoData={historico} fnHandleModal={handleModal} />
        </>
      )

    case 'cuenta_corriente':
      return (
        <div>
          <div className='d-flex p-1 border rounded-1 mb-2 text-center fw-bold fs-4 bg-white'>
            <div className='d-flex col flex-column'>
              <span>Deuda total</span>
              <span className='text-danger'>{Precio(data?.cuenta_corriente?.deuda_total)}</span>
            </div>

            <div className='d-flex col flex-column'>
              <span>Pago en proceso</span>
              <span className='text-warning'>{Precio(data?.cuenta_corriente?.monto_pago_en_proceso)}</span>
            </div>

            <div className='d-flex col flex-column'>
              <span>Crédito total</span>
              <span className='text-primary'>{Precio(data?.cuenta_corriente?.credito_total)}</span>
            </div>

            <div className='d-flex col flex-column'>
              <span>Crédito disponible</span>
              <span className='text-success'>{Precio(data?.cuenta_corriente?.credito_disponible)}</span>
            </div>
          </div>

          <CuentaCorrienteTable lineas_credito={data?.cuenta_corriente?.lineas_credito} handlePagination={handlePagination} />
        </div>
      )

    default:
      break;
  }
}


export const Detalle = () => {
  const [data, setData] = useState(null)
  const { id } = useParams()
  const dispatch = useDispatch()
  const redirect = useNavigate()
  const [historico, setHistorico] = useState(null)
  const [modal, setModal] = useState({ id: null, display: false, data: null })
  const [queryParam] = useSearchParams()
  const { showing_results: limit } = useSelector(state => state.listedResults)

  // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
  useEffect(() => {
    getDetail(id).then(res => {
      if (res.status >= 400) throw res

      setData(res.data)
    })
      .catch((err) => {
        console.error(err)
        const toasterContent = {
          title: 'Registro no encontrado',
          icon: 'warning'
        }

        dispatch(fireToaster(toasterContent))
        redirect(CONSTANT.redirectListado)
      })

    getCuentaCorriente(id, undefined, limit).then(res => {
      if (res.status >= 400) throw res

      setData(prev => ({ ...prev, cuenta_corriente: res.data }))
    })
      .catch((err) => {
        console.error(err)
        const toasterContent = {
          title: 'Registro no encontrado',
          icon: 'warning'
        }

        dispatch(fireToaster(toasterContent))
        redirect(CONSTANT.redirectListado)
      })

    historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
    return () => {
      setData(null)
    }
  }, [id, dispatch, redirect, limit])

  const handlePagination = async (url) => {
    await axios(url + `&empresa_id=${id}&limit=${limit}`,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        setData(prev => ({ ...prev, cuenta_corriente: data }))
      })
      .catch(err => console.error(err))
  }

  // FUNCION MANEJADORA DE LA DATA DEL MODAL
  const handleModal = (data) => {
    const { id, anterior, nuevo } = data

    setModal({
      display: true,
      id,
      data: {
        anterior,
        nuevo
      }
    })
  }


  return (
    <div className='row'>

      <ModalHistorico modal={modal} fnHandleModal={handleModal} />

      <div className='col-12'>
        <div className='col mb-50'>
          <div className='d-flex gap-1'>
            <div className='col'>
              <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />

              <div className='mt-1'>
                <Tabs tabs={CONSTANT.tabs} />
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          {data
            ? <TabComponentSelector tab={queryParam.get('tab')} data={data} handleModal={handleModal} historico={historico} handlePagination={handlePagination} />

            : <CustomLoader />
          }
        </div>
      </div>
    </div>
  )
}

import {
  FILTER_ADD_COMUNA,
  FILTER_ADD_METODO_ENTREGA,
  FILTER_ADD_TIPO_DESTINO,
  FILTER_BUSQUEDA,
  FILTER_CLEAR,
  FILTER_ESTATUS,
  FILTER_REMOVE_COMUNA,
  FILTER_REMOVE_METODO_ENTREGA,
  FILTER_REMOVE_TIPO_DESTINO,
  RESULTADOS_PUNTOS_ENTREGA
} from "../redux-types";


export const tipoDestinoFilter = (data, isRemove = false) =>
  isRemove
    ? { type: FILTER_REMOVE_TIPO_DESTINO, payload: data }
    : { type: FILTER_ADD_TIPO_DESTINO, payload: data };

export const comunaFilter = (data, isRemove = false) =>
  isRemove
    ? { type: FILTER_REMOVE_COMUNA, payload: data }
    : { type: FILTER_ADD_COMUNA, payload: data };

export const metodoEntregaFilter = (data, isRemove) =>
  isRemove
    ? { type: FILTER_REMOVE_METODO_ENTREGA, payload: data }
    : { type: FILTER_ADD_METODO_ENTREGA, payload: data };

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const estatusFilter = (data) => ({ type: FILTER_ESTATUS, payload: data })

export const resultadosPuntosEntrega = (data) => ({ type: RESULTADOS_PUNTOS_ENTREGA, payload: data  });

export const clearFilterPuntosEntrega = () => ({ type: FILTER_CLEAR });

import {
   FILTER_ADD_CATEGORIA,
   FILTER_CLEAR,
   FILTER_NOMBRE,
   FILTER_REMOVE_CATEGORIA,
   FILTROS_PERSISTENTES_ADICIONALES,
   RESULTADOS_ADICIONALES,
} from "../redux-types";

const initialState = {
   filtros: {
      nombre: '',
      categoria_id: []
   },
   resultados: null
}


export const adicionalesReducer = (state = initialState, action) => {

   switch (action.type) {
      case FILTER_ADD_CATEGORIA:
         const isListedCategoria = state.filtros.categoria_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               categoria_id: isListedCategoria
                  ? state.filtros.categoria_id
                  : [...state.filtros.categoria_id, action.payload]
            }
         };

      case FILTER_REMOVE_CATEGORIA:
         return {
            ...state, filtros: {
               ...state.filtros,
               categoria_id: state.filtros.categoria_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_NOMBRE:
         return {
            ...state, filtros: {
               ...state.filtros,
               nombre: action.payload
            }
         };

      case FILTROS_PERSISTENTES_ADICIONALES:
         return {
            ...state, filtros: action.payload
         };

      case RESULTADOS_ADICIONALES:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
import { useEffect, useState } from 'react'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import CustomLoader from '../../components/CustomLoader'


// Router Dom
import { useNavigate, useParams } from 'react-router-dom'


// Endpoints
import { historicoData } from '../../helpers/historicoData'
import { ModalHistorico } from '../../components/Historico/ModalHistorico'
import TablaDetalle from '../../components/Historico/TablaDetalle'
import { getDetailedServicio } from '../../services/servicios/getDetail.service'
import { deleteServicio } from '../../services/servicios/delete.service'
import AccordionTable from '../../layout/AccordionTable'
import Table from '../../components/Table'
import { NoResults } from '../../components/Tables/Misc/NoResults'
import { BASE_URL } from '../../endpoints'
import { deleteImgProducto } from '../../services/productos/imagenes/delete.service'
import { successResponse } from '../../helpers/successResponse'
import { responseToasterContent } from '../../helpers/toasterContent'
import { AgregarImagenes } from '../../components/Modal/Productos/Imagenes/AgregarImagenes'


const CONSTANT = {
   title: 'Servicio',
   redirectListado: '/servicios',
   modelo: 'App\\Models\\NeuServicio',
   redirectUpdate: (id) => `/servicios/update/${id}`,
}


export const Detalle = () => {
   const [data, setData] = useState(null)
   const { id } = useParams()
   const dispatch = useDispatch()
   const redirect = useNavigate()
   const [historico, setHistorico] = useState(null)
   const [modal, setModal] = useState({ id: null, display: false, data: null })
   const [modalAddImg, setModalAddImg] = useState({ display: false })

   const fetchData = () => {
      getDetailedServicio(id).then(res => {
         if (res.status >= 400) throw res

         setData(res.data)
      })
         .catch((err) => {
            console.error(err)
            const toasterContent = {
               title: 'Registro no encontrado',
               icon: 'warning'
            }

            dispatch(fireToaster(toasterContent))
            redirect(CONSTANT.redirectListado)
         })

      historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
   }


   // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
   useEffect(() => {
      fetchData()

      return () => {
         setData(null)
      }
   }, [id, dispatch, redirect])


   // FUNCION MANEJADORA DE LA DATA DEL MODAL
   const handleModal = (data) => {
      const { id, anterior, nuevo } = data

      setModal({
         display: true,
         id,
         data: {
            anterior,
            nuevo
         }
      })
   }

   const handleDelete = (id) => {
      deleteServicio(id).finally(() => fetchData())
   }

   const handleDeleteImg = (id) => {
      deleteImgProducto(id)
         .then(data => {
            dispatch(fireToaster(responseToasterContent(data)))
            if (successResponse(data)) fetchData()
         })
   }


   return (
      <div className='row'>
         <ModalHistorico modal={modal} fnHandleModal={handleModal} />

         <div className='col-12'>
            <div className='col mb-50'>
               <div className='d-flex gap-1'>
                  <div className='col'>
                     <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
                  </div>
                  <Button text='Editar' icon='Settings' color='success' isLink url={CONSTANT.redirectUpdate(id)} />
                  <Button text='Borrar' icon='Trash' color='danger' onClick={() => handleDelete(id)} />
                  <Button icon='Plus' text='Adjuntar imágenes' onClick={() => setModalAddImg({ display: true })} />
               </div>
            </div>

            <div className='row'>
               {data
                  ? <>
                     <AgregarImagenes data={data?.servicio} modal={modalAddImg} setModal={setModalAddImg} handleReturn={fetchData} />

                     {/* MARK: COLUMNA IZQUIERDA */}
                     <div className='col-md-6'>
                        <Accordion
                           title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
                           classes='mb-2'
                           isOpen
                        >
                           <AccordionDetailData dataKey={'ID'} value={data?.servicio?.id} />
                           <AccordionDetailData dataKey={'Nombre'} value={data?.servicio?.nombre ?? 'Error'} />
                           <AccordionDetailData dataKey={'SKU'} value={data?.servicio?.sku ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Descripción'} value={data?.servicio?.descripcion ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Precio final'} value={data?.servicio?.precio_final ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Precio base'} value={data?.servicio?.precio_base ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Precio anterior'} value={data?.servicio?.precio_anterior ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Último costo'} value={data?.servicio?.ultimo_costo ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Nuevo'} value={data?.servicio?.nuevo ? 'Sí' : 'No'} />
                           <AccordionDetailData dataKey={'Publicado'} value={data?.servicio?.publicado ? 'Sí' : 'No'} />
                           <AccordionDetailData dataKey={'Es variante'} value={data?.servicio?.es_variante ? 'Sí' : 'No'} />
                           <AccordionDetailData dataKey={'Mostrar en cliente'} value={data?.servicio?.mostrar_en_cliente ? 'Sí' : 'No'} />
                           <AccordionDetailData dataKey={'Meta-título'} value={data?.servicio?.["meta-titulo"] ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Datos-estructurados'} value={data?.servicio?.["datos-estruturados"] ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Meta-descripción'} value={data?.servicio?.["meta-descripcion"] ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Parámetros de búsqueda'} value={data?.servicio?.parametros_busqueda ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Creación'} value={data?.servicio?.created_at?.split('T')[0] ?? 'N/A'} />
                           <AccordionDetailData dataKey={'Modicación'} value={data?.servicio?.updated_at?.split('T')[0] ?? 'N/A'} last />
                        </Accordion>
                     </div>


                     {/* MARK: COLUMNA DERECHA */}
                     <div className='col-md-6'>
                        {/* MARK: TABLA PUNTOS DE ENTREGA */}
                        <AccordionTable
                           classes='mb-2'
                           isOpen={true}
                           title='Puntos de entrega'
                        >
                           <Table pb={false} style={{ maxHeight: '700px' }}>
                              <thead className="table-light">
                                 <tr role="row">
                                    <th>Nombre</th>
                                    <th>Instalación</th>
                                    <th>Recargo</th>
                                    {/* <th className='text-center'>Estatus</th> */}
                                 </tr>
                              </thead>
                              <tbody>
                                 {data?.puntos_entregas?.length

                                    ? data?.puntos_entregas?.map(({ id, rut_proveedor, nombre, direccion, pivot }, i, arr) => (
                                       <tr key={`puntos_entregas-${id}-${nombre ?? '?'}`}>
                                          <td>
                                             <div className='d-flex flex-column'>
                                                <a target='_blank' rel='noreferrer' href={`/puntos-entrega/detalle/${id}`}><span>{nombre ?? '?'} | {direccion ?? 'Sin dirección'}</span></a>
                                                <span>RUT: <b>{rut_proveedor ?? '?'}</b></span>
                                             </div>
                                          </td>

                                          <td>{pivot?.precio_instalacion ?? 'N/A'}</td>

                                          <td>{pivot?.recargo ?? 'N/A'}</td>

                                          {/* 
                                       <td>
                                          <div className='d-flex gap-1'>

                                             <Switch
                                                value={estatus ? true : false}
                                                labelText=''
                                                classes='mt-0 mb-0'
                                                // handleValue={() => handleFetch(arr[i], 'estatus-punto_entrega')}
                                             />

                                             {user.permisos[`${CONSTANT.permissionsPath.puntos_entrega}.eliminar`] &&
                                                <Button icon={'X'} color={'danger'} className={'my-auto p-25'} onClick={() => handleDeleteRelation(id)} />
                                             }
                                          </div>
                                       </td> 
                                       */}
                                       </tr>
                                    ))

                                    : <tr><td colSpan={5}><NoResults /></td></tr>
                                 }
                              </tbody>
                           </Table>
                        </AccordionTable >

                        {/* MARK: METODOS PAGO */}
                        <AccordionTable
                           classes='mb-1'
                           title='Métodos de pago'
                        >
                           <Table pb={false} style={{ maxHeight: '900px' }}>
                              <thead className="table-light">
                                 <tr role="row">
                                    <th>Nombre</th>
                                    <th>Imagen</th>
                                    {/* <th>Estatus</th> */}
                                 </tr>
                              </thead>
                              <tbody>
                                 {data?.metodos_pagos?.length

                                    ? data?.metodos_pagos?.map(({ id, nombre, imagen, estatus, pivot }, i, arr) => (
                                       <tr key={`metodos_pagos-${id}-${nombre ?? '?'}`}>
                                          <td><a target='_blank' rel='noreferrer' href={`/metodos-pago/detalle/${id}`}>{nombre?.toUpperCase() ?? '?'}</a></td>
                                          <td><img className='height-listado' src={BASE_URL + 'storage/' + imagen} alt={`img-${nombre}`} /></td>
                                          {/* <td>
                                          <Switch
                                             value={pivot?.estatus ? true : false}
                                             labelText={null}
                                             classes='mx-auto mt-0 mb-0'
                                          />
                                       </td> */}
                                       </tr>
                                    ))

                                    : <tr><td colSpan={6}><NoResults /></td></tr>
                                 }
                              </tbody>
                           </Table>
                        </AccordionTable >

                        {/* MARK: IMAGENES */}
                        <AccordionTable
                           classes='mb-1'
                           title='Imágenes'
                        >
                           <Table pb={false} style={{ maxHeight: '900px' }}>
                              {data?.imagenes?.length

                                 ? <div className='img-grid-table'>
                                    {data?.imagenes?.map(({ id, imagen }) => (

                                       <figure key={`producto-imagen-${id}`}>
                                          <img
                                             className='height-listado'
                                             src={BASE_URL + 'storage/' + imagen}
                                             alt={`producto-imagen-${id}`}
                                          />

                                          <Button icon='X' color='danger' onClick={() => handleDeleteImg(id)} className='btn-delete p-25' />
                                       </figure>
                                    ))}
                                 </div>

                                 : <div><NoResults /></div>
                              }
                           </Table>
                        </AccordionTable >
                     </div>

                     <TablaDetalle historicoData={historico} fnHandleModal={handleModal} />
                  </>

                  : <CustomLoader />
               }
            </div>
         </div>
      </div>
   )
}

import {
   FILTER_CLEAR,
   FILTER_FECHA_INICIO,
   FILTER_FECHA_FINAL,
   FILTER_PUNTO_ENTREGA,
   RESULTADOS_AGENDAMIENTOS_PUNTOS_ENTREGA
} from "../redux-types";


export const clearFilterAgendamientosPuntoEntrega = () => ({ type: FILTER_CLEAR });
export const resultadosAgendamientosPuntoEntrega = (data) => ({ type: RESULTADOS_AGENDAMIENTOS_PUNTOS_ENTREGA, payload: data });

// params
export const puntoEntregaFilter = (data,) => ({ type: FILTER_PUNTO_ENTREGA, payload: data })
export const fechaInicialFilter = (data) => ({ type: FILTER_FECHA_INICIO, payload: data })
export const fechaFinalFilter = (data) => ({ type: FILTER_FECHA_FINAL, payload: data })


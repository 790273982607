
// Components
import HistorialCambiosFiltros from "../../components/AccordionFilter/HistorialCambios";
import HistorialCambiosTable from "../../components/Tables/HistorialCambiosTable";


const CONSTANT = {
   title: 'Historial de cambios',
   path: 'historial-cambios',
   // create_btn: 'Crear producto en proveedor'
}

const Listado = () => {
   return (
      <div className="row">
         <h3 className="mb-1 d-flex justify-content-between">
            <p><b>{CONSTANT.title}</b> - Listado</p>
            {/* <DropdownWrapper text="Acciones" classes="ms-auto">
               <DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
            </DropdownWrapper> */}
         </h3>

         <HistorialCambiosFiltros />

         <HistorialCambiosTable />
      </div>
   );
}

export default Listado;
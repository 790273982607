import { useEffect, useState } from "react";
import axios from "axios";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import SelectFilter from "../../components/SelectFilter";
import CustomLoader from "../../components/CustomLoader";
import IconPicker from "../../components/IconPicker";


// Hooks / Helpers
import { iconSanatizerFaMdi } from "../../helpers/iconSanatizerFaMdi";


// React-router
import { useNavigate } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";
import { getAll } from "../../endpoints/getAll";
import { idExtractor } from "../../helpers/idExtractor";



const CONSTANT = {
  redirectUrl: '/template/menus',
  componentTitle: 'Crear menú',
  title: 'Menús',
  queryUrl: create.menus,
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  fetchFrontendId: getAll.frontend_id,
  queryUrlGetAllComponentes: getAll.componentes,

  initialFormState: {
    frontend_id: '',
    titulo: '',
    nombre_componente: '',
    menu_padre_id: '',
    url_redireccion: '',
    es_url_externo: false,
    movil: true,
    escritorio: true,
    icono_libreria: null,
    icono_fuente: null,
    icono_icono: null,
    estatus: true
  },

  selectFilterInitialState: {
    frontendId: [],
    componentes: []
  }
}


const handleSelectFiltersData = async () => {
  const frontendId = await axios(CONSTANT.fetchFrontendId,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const componentes = await axios(CONSTANT.queryUrlGetAllComponentes, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
    }
  })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { frontendId, componentes }
}


const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [icono, setIcono] = useState(null)
  const [form, setForm] = useState(CONSTANT.initialFormState);
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    const sanitazedIcon = iconSanatizerFaMdi(icono)

    let data = {
      titulo: form.titulo,
      nombre_componente: form.nombre_componente?.split('-')[0],
      menu_padre_id: form.menu_padre_id,
      url_redireccion: form.url_redireccion,
      es_url_externo: form.es_url_externo,
      movil: form.movil,
      escritorio: form.escritorio,
      icono_libreria: sanitazedIcon?.libreria,
      icono_fuente: sanitazedIcon?.fuente ?? null,
      icono_icono: sanitazedIcon?.icono,
      estatus: Number(form.estatus) ? true : false
    }

    if (!form?.frontend_id.includes('*NULL*')) {
      data = {
        ...data,
        frontend_id: idExtractor(form?.frontend_id),
      }
    }

    axios(CONSTANT.queryUrl, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'application/json'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.titulo} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }


  return (
    <div className="row">
      < div className="col col-md-6 mx-auto" >
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {selectFilter.componentes.length && selectFilter.frontendId.length
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                labelText='Estatus'
                name='estatus'
                value={form.estatus}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
                classes='justify-content-end'
              />

              <Switch
                labelText='Escritorio'
                name='escritorio'
                value={form.escritorio}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
                classes='justify-content-end'
              />

              <Switch
                labelText='Móvil'
                name='movil'
                value={form.movil}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
                classes='justify-content-end'
              />

              <Switch
                labelText='Es URL externo'
                name='es_url_externo'
                value={form.es_url_externo}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
                classes='justify-content-end'
              />

              <FormInput
                labelText='* Título'
                name='titulo'
                value={form.titulo}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <FormInput
                labelText='Menú Padre'
                type="number"
                name='menu_padre_id'
                value={form.menu_padre_id}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <IconPicker
                value={icono}
                handleValue={setIcono}
                size='col-12'
                sizeDesk='col-md-6'
                labelText="* Ícono"
              />

              <SelectFilter
                labelText='* Componente'
                name='nombre_componente'
                value={form.nombre_componente?.split('-')[1]}
                handleValue={handleInputChange}
                optionObj={selectFilter?.componentes?.map(({ nombre }) => ({ id: nombre, name: nombre }))}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <SelectFilter
                name="frontend_id"
                value={form?.frontend_id?.split('-')[1] ?? ''}
                labelText="* Frontend"
                optionObj={selectFilter.frontendId.map(({ id, nombre }) => ({ id, name: nombre }))}
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='* URL de redirección'
                name='url_redireccion'
                value={form.url_redireccion}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />


            </div>

            <Button type="submit" text="Crear" color="primary" icon="Plus" onClick={(e) => handleFetch(e)} />
          </CustomForm>
          : <CustomLoader />
        }
      </div >
    </div >
  );
};

export default Create;




//	Components
import FormInput from '../../components/FormInput';
import Button from '../../components/Button';
import CustomForm from '../../components/CustomForm';



// Hooks
import { useForm } from '../../hooks/useForm';



const PermissionsCreate = () => {
   const [{ name, guard_name }, handleInputChange] = useForm({
      name: '',
      guard_name: ''
   });


   return (
      <div className='row'>
         <div className='col col-md-6 mx-auto'>
            <CustomForm
               size=''
               title='Crear Permiso'
            >
               <div className='row'>
                  <FormInput
                     name='name'
                     value={name}
                     labelText='Nombre'
                     handleValue={handleInputChange}
                     size='col-12'
                  />

                  <FormInput
                     name='guard_name'
                     value={guard_name}
                     labelText='Rol'
                     handleValue={handleInputChange}
                     size='col-12'
                  />
               </div>

               <Button
                  type='submit'
                  text='Crear'
                  color='primary'
                  icon='Table'
               />
            </CustomForm>
         </div>
      </div>
   )
}

export default PermissionsCreate;
import { Navigate, Route, Routes } from "react-router-dom"
import Create from "../views/Etiquetas/Create";
import Detalle from "../views/Etiquetas/Detalle";
import Listado from "../views/Etiquetas/Listado";
import Update from "../views/Etiquetas/Update";
import { useSelector } from "react-redux";



const EtiquetasRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['etiquetas.crear'] &&
            <Route path='/create' element={<Create />} />
         }

         {user.permisos['etiquetas.editar'] &&
            <Route path='/update/:id' element={<Update />} />
         }

         {user.permisos['etiquetas.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}

export default EtiquetasRouter;
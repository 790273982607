import {
  FILTER_COTIZACION_ID,
  FILTER_ADD_METODO_PAGO,
  FILTER_REMOVE_METODO_PAGO,
  FILTER_NOMBRE_CLIENTE,
  FILTER_CLEAR,
  FILTER_BUSQUEDA,
  RESULTADOS_COTIZACIONES,
  FILTER_CREACION_DESDE,
  FILTER_CREACION_HASTA,
  FILTER_ADD_EJECUTIVO,
  FILTER_REMOVE_EJECUTIVO,
  FILTER_ADD_PUNTO_ENTREGA,
  FILTER_REMOVE_PUNTO_ENTREGA,
  FILTER_ADD_COMUNA,
  FILTER_REMOVE_COMUNA,
  FILTER_ADD_FRONTEND,
  FILTER_REMOVE_FRONTEND,
  FILTROS_PERSISTENTES_COTIZACIONES,
  RESULTADOS_RESUMEN_COTIZACIONES,
} from "../redux-types";

const initialState = {
  filtros: {
    cliente: '',
    cotizacion_id: '',
    desde: '',
    hasta: '',

    metodo_pago_id: [],
    ejecutivo_id: [],
    puntoentrega_id: [],
    frontend_id: [],

    comuna_id: [],
  },
  resultados: null,

  resumen_mes: []
};

export const cotizacionesReducer = (state = initialState, action) => {
  let isListed

  switch (action.type) {
    case RESULTADOS_RESUMEN_COTIZACIONES:
      return {
        ...state,
        resumen_mes: Object?.values(action.payload ?? {})?.sort((a, b) => b.cantidad - a.cantidad)?.map(p => p),
      };

    case FILTER_NOMBRE_CLIENTE:
      return {
        ...state,
        filtros: {
          ...state.filtros,
          cliente: action.payload,
        },
      };

    case FILTER_COTIZACION_ID:
      return {
        ...state,
        filtros: {
          ...state.filtros,
          cotizacion_id: action.payload,
        },
      };

    case FILTER_CREACION_DESDE:
      return {
        ...state,
        filtros: {
          ...state.filtros,
          desde: action.payload,
        },
      };

    case FILTER_CREACION_HASTA:
      return {
        ...state,
        filtros: {
          ...state.filtros,
          hasta: action.payload,
        },
      };

    case FILTER_ADD_METODO_PAGO:
      isListed = state.filtros.metodo_pago_id.find((el) => el === action.payload);

      return {
        ...state,
        filtros: {
          ...state.filtros,
          metodo_pago_id: isListed
            ? state.filtros.metodo_pago_id
            : [...state.filtros.metodo_pago_id, action.payload],
        },
      };

    case FILTER_REMOVE_METODO_PAGO:
      return {
        ...state,
        filtros: {
          ...state.filtros,
          metodo_pago_id: state.filtros.metodo_pago_id.filter((el) => el !== action.payload),
        },
      };

    case FILTER_ADD_FRONTEND:
      isListed = state.filtros.frontend_id.find((el) => el === action.payload);

      return {
        ...state,
        filtros: {
          ...state.filtros,
          frontend_id: isListed
            ? state.filtros.frontend_id
            : [...state.filtros.frontend_id, action.payload],
        },
      };

    case FILTER_REMOVE_FRONTEND:
      return {
        ...state,
        filtros: {
          ...state.filtros,
          frontend_id: state.filtros.frontend_id.filter((el) => el !== action.payload),
        },
      };

    case FILTER_ADD_EJECUTIVO:
      isListed = state.filtros.ejecutivo_id.find((el) => el === action.payload);

      return {
        ...state,
        filtros: {
          ...state.filtros,
          ejecutivo_id: isListed
            ? state.filtros.ejecutivo_id
            : [...state.filtros.ejecutivo_id, action.payload],
        },
      };

    case FILTER_REMOVE_EJECUTIVO:
      return {
        ...state,
        filtros: {
          ...state.filtros,
          ejecutivo_id: state.filtros.ejecutivo_id.filter((el) => el !== action.payload),
        },
      };

    case FILTER_ADD_PUNTO_ENTREGA:
      isListed = state.filtros.puntoentrega_id.find((el) => el === action.payload);

      return {
        ...state,
        filtros: {
          ...state.filtros,
          puntoentrega_id: isListed
            ? state.filtros.puntoentrega_id
            : [...state.filtros.puntoentrega_id, action.payload],
        },
      };

    case FILTER_REMOVE_PUNTO_ENTREGA:
      return {
        ...state,
        filtros: {
          ...state.filtros,
          puntoentrega_id: state.filtros.puntoentrega_id.filter((el) => el !== action.payload),
        },
      };

    case FILTER_ADD_COMUNA:
      isListed = state.filtros.comuna_id.find((el) => el === action.payload);

      return {
        ...state,
        filtros: {
          ...state.filtros,
          comuna_id: isListed
            ? state.filtros.comuna_id
            : [...state.filtros.comuna_id, action.payload],
        },
      };

    case FILTER_REMOVE_COMUNA:
      return {
        ...state,
        filtros: {
          ...state.filtros,
          comuna_id: state.filtros.comuna_id.filter((el) => el !== action.payload),
        },
      };

    case FILTER_BUSQUEDA:
      return {
        ...state,
        filtros: {
          ...state.filtros,
          busqueda: action.payload
        }
      }

    case RESULTADOS_COTIZACIONES:
      return {
        ...state,
        resultados: action.payload
      }

    case FILTROS_PERSISTENTES_COTIZACIONES:
      return {
        ...state,
        filtros: action.payload
      }

    case FILTER_CLEAR:
      return initialState;

    default:
      return state;
  }
};

import {
   TOASTER_CLEAR,
   TOASTER_CONTENT
} from '../redux-types'

const initialState = {
   data: {
      icon: 'info',
      title: '',
      text: '',
      html: undefined,
      toast: true,
      position: 'bottom-right',
      timer: 3000,
      timerProgressBar: true
   },
   fire: false
}

export const toasterReducer = (state = initialState, action) => {
   switch (action.type) {
      case TOASTER_CONTENT:
         return state = {
            ...state,
            data: {
               ...state.data,
               icon: action.payload.icon,
               text: action.payload.text,
               title: action.payload.title,
               html: action.payload.html,
               timer: action.payload.timer ?? 4000
            },
            fire: true
         };

      case TOASTER_CLEAR:
         return state = initialState;

      default:
         return state
   }
}
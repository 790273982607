
// Components
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import EtiquetasFiltros from "../../components/AccordionFilter/Etiquetas";
import EtiquetasTable from "../../components/Tables/EtiquetasTable";


const CONSTANT = {
   title: 'Etiquetas',
   path: 'etiquetas',
   create_btn: 'Crear etiqueta'
}


const Listado = () => {
   return (
      <div className="row">
         <h3 className="mb-1 d-flex justify-content-between">
            <p><b>{CONSTANT.title}</b> - Listado</p>
            <DropdownWrapper text="Acciones" classes="ms-auto">
               <DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
            </DropdownWrapper>
         </h3>

         <EtiquetasFiltros />

         <EtiquetasTable />
      </div>
   );
}

export default Listado;
import React from 'react'


// Components
import NavBar from './components/hud/NavBar';
import SideBar from './components/hud/SideBar';
import useAuth from './hooks/useAuth';


// Routers
import MainRouter from './routers/MainRouter';


const Main = () => {
  useAuth()

  return (
    <>
      <NavBar />

      <SideBar />

      <MainRouter />
    </>
  )
}

export default Main
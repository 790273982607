
// Components
import CintaInformativaFiltros from "../../components/AccordionFilter/CintaInformativa";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import CintaInformativaTable from "../../components/Tables/CintaInformativaTable";


const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>Cinta informativa</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text='Crear cinta informativa' href={`/template/cinta-informativa/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<CintaInformativaFiltros />

			<CintaInformativaTable />
		</div>

	);
}

export default Listado;
import { Navigate, Route, Routes } from "react-router-dom"
// import Create from "../views/HistorialCambios/Create";
// import Update from "../views/HistorialCambios/Update";
import Listado from "../views/HistorialCambios/Listado";
import Detalle from "../views/HistorialCambios/Detalle";
import { useSelector } from "react-redux";


const HistorialRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {/* {user.permisos['historial.crear'] &&
            <Route path='/create' element={<Create />} />
         } */}

         {/* {user.permisos['historial.detalle'] &&
         } */}
         <Route path='/detalle/:id' element={<Detalle />} />

         {/* {user.permisos['historial.editar'] &&
            <Route path='/update/:id' element={<Update />} />
         } */}

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}

export default HistorialRouter;
import {
   FILTER_BUSQUEDA,
   FILTER_ADD_ESTATUS,
   FILTER_REMOVE_ESTATUS,
   FILTER_CLEAR,
   FILTER_CREACION_DESDE,
   FILTER_CREACION_HASTA,
   FILTER_REMOVE_NOMBRE,
   FILTER_ADD_NOMBRE,
   FILTER_REMOVE_TABLA,
   FILTER_ADD_TABLA,
   RESULTADOS_TIPOS_ELEMENTOS
} from "../redux-types";

const initialState = {
   filtros: {
      busqueda: '',
      nombre: [],
      tabla: [],
      estatus: [],
      created_at_desde: '',
      created_at_hasta: '',
   },
   resultados: null
}


export const tiposElementosReducer = (state = initialState, action) => {
   let isListed

   switch (action.type) {
      case FILTER_ADD_ESTATUS:
         isListed = state.filtros.estatus.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus: isListed
                  ? state.filtros.estatus
                  : [...state.filtros.estatus, action.payload]
            }
         };

      case FILTER_REMOVE_ESTATUS:
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus: state.filtros.estatus.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_TABLA:
         isListed = state.filtros.tabla.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               tabla: isListed
                  ? state.filtros.tabla
                  : [...state.filtros.tabla, action.payload]
            }
         };

      case FILTER_REMOVE_TABLA:
         return {
            ...state, filtros: {
               ...state.filtros,
               tabla: state.filtros.tabla.filter(el => el !== action.payload)
            }
         };

      case FILTER_CREACION_DESDE:
         return {
            ...state, filtros: {
               ...state.filtros,
               created_at_desde: action.payload
            }
         };

      case FILTER_CREACION_HASTA:
         return {
            ...state, filtros: {
               ...state.filtros,
               created_at_hasta: action.payload
            }
         };

      case FILTER_ADD_NOMBRE:
         isListed = state.filtros.nombre.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               nombre: isListed
                  ? state.filtros.nombre
                  : [...state.filtros.nombre, action.payload]
            }
         };

      case FILTER_REMOVE_NOMBRE:
         return {
            ...state, filtros: {
               ...state.filtros,
               nombre: state.filtros.nombre.filter(el => el !== action.payload)
            }
         };

      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda: action.payload
            }
         };

      case RESULTADOS_TIPOS_ELEMENTOS:
         return {
            ...state, resultados: action.payload
         };


      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
import { Navigate, Route, Routes } from 'react-router-dom';
import Construction from '../components/Construction';
import ProductosIndex from '../views/Productos/Index';
import Create from '../views/Productos/Create';
import Detalle from '../views/Productos/Detalle';

import MetodosPago from '../views/Productos/Tabs/MetodosPago';
import Ofertas from '../views/Productos/Tabs/Ofertas';
import Etiquetas from '../views/Productos/Tabs/Etiquetas';
import PuntosEntrega from '../views/Productos/Tabs/PuntosEntrega';
import Adicionales from '../views/Productos/Tabs/Adicionales';

import MetodosPagoAsignacionMasiva from '../views/Productos/AsignacionMasiva/MetodosPago';
import PuntosEntregaAsignacionMasiva from '../views/Productos/AsignacionMasiva/PuntosEntrega';
import EtiquetasAsignacionMasiva from '../views/Productos/AsignacionMasiva/Etiquetas';
import OfertasAsignacionMasiva from '../views/Productos/AsignacionMasiva/Ofertas';
import AdicionalesAsignacionMasiva from '../views/Productos/AsignacionMasiva/Adicionales';

import InstalacionDespachoAsignacionMasiva from '../views/Productos/EdicionMasiva/InstalacionDespacho';
import MetodosPagoEdicionMasiva from '../views/Productos/EdicionMasiva/MetodosPago';
import AdicionalesEdicionMasiva from '../views/Productos/EdicionMasiva/Adicionales';
import OfertasEdicionMasiva from '../views/Productos/EdicionMasiva/OfertasNew';
import EtiquetasEdicionMasiva from '../views/Productos/EdicionMasiva/Etiquetas';

import CategoriasRouter from './Productos/CategoriasRouter';
import TiposElementosRouter from './Productos/TiposElementosRouter';
import ClavesAtributosRouter from './Productos/ClavesAtributosRouter';
import ValoresAtributosRouter from './Productos/ValoresAtributosRouter';
import { useSelector } from 'react-redux';
import CargaMasiva from '../views/Productos/Tabs/CargaMasiva';
import { PuntosEntregaActualizacionMasiva } from '../views/Productos/EdicionMasiva/PuntosEntrega';
import { Imagenes } from '../views/Productos/Tabs/Imagenes';


const ProductosRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<ProductosIndex />} />

         {user.permisos['productos.crear'] &&
            <Route path='/create' element={<Create />} />
         }

         {user.permisos['productos.importar'] &&
            <Route path='/carga-masiva' element={<CargaMasiva />} />
         }

         {user.permisos['productos.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         <Route path='/stock' element={<ProductosIndex view={<Construction />} />} />

         {user.permisos['categorias.listar'] &&
            <Route path='/categorias/*' element={<CategoriasRouter />} />
         }

         {user.permisos['tipos-elementos.listar'] &&
            <Route path='/tipos-elementos/*' element={<TiposElementosRouter />} />
         }

         {user.permisos['atributos.listar'] &&
            <>
               <Route path='/claves-atributos/*' element={<ClavesAtributosRouter />} />
               <Route path='/valores-atributos/*' element={<ValoresAtributosRouter />} />
            </>
         }

         <Route path='/metodos-pago' element={<ProductosIndex view={<MetodosPago />} />} />
         <Route path='/puntos-entrega' element={<ProductosIndex view={<PuntosEntrega />} />} />
         <Route path='/etiquetas' element={<ProductosIndex view={<Etiquetas />} />} />
         <Route path='/ofertas' element={<ProductosIndex view={<Ofertas />} />} />
         <Route path='/adicionales' element={<ProductosIndex view={<Adicionales />} />} />
         <Route path='/imagenes' element={<ProductosIndex view={<Imagenes />} />} />

         {user.permisos['productos.asignacion-masiva'] &&
            <>
               <Route path='/ofertas/asignacion-masiva' element={<ProductosIndex view={<OfertasAsignacionMasiva />} />} />
               <Route path='/etiquetas/asignacion-masiva' element={<ProductosIndex view={<EtiquetasAsignacionMasiva />} />} />
               <Route path='/adicionales/asignacion-masiva' element={<ProductosIndex view={<AdicionalesAsignacionMasiva />} />} />
               <Route path='/puntos-entrega/asignacion-masiva' element={<ProductosIndex view={<PuntosEntregaAsignacionMasiva />} />} />
               <Route path='/metodos-pago/asignacion-masiva' element={<ProductosIndex view={<MetodosPagoAsignacionMasiva />} />} />
            </>
         }

         {user.permisos['productos.edicion-masiva'] &&
            <>
               <Route path='/instalacion-despacho/edicion-masiva' element={<ProductosIndex view={<InstalacionDespachoAsignacionMasiva />} />} />
               <Route path='/metodos-pago/edicion-masiva' element={<ProductosIndex view={<MetodosPagoEdicionMasiva />} />} />
               <Route path='/adicionales/edicion-masiva' element={<ProductosIndex view={<AdicionalesEdicionMasiva />} />} />
               <Route path='/ofertas/edicion-masiva' element={<ProductosIndex view={<OfertasEdicionMasiva />} />} />
               <Route path='/etiquetas/edicion-masiva' element={<ProductosIndex view={<EtiquetasEdicionMasiva />} />} />
               <Route path='/puntos-entrega/actualizacion-masiva' element={<ProductosIndex view={<PuntosEntregaActualizacionMasiva />} />} />
            </>
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   );
}

export default ProductosRouter;
import axios from "axios"
import { getAll } from "../../endpoints/getAll"
import { idExtractor } from "../../helpers/idExtractor"
import { queryParamsFormatter } from "../../helpers/queryParamsFormatter"

const dataFormatter = (obj) => {
  let data = {}

  Object.entries(obj).forEach(([k, v]) => {
    if (['usuario_id', 'puntoentrega_id', 'metodo_entrega_id', 'metodo_pago_id', 'divisa_id', 'tipo_facturacion_id', 'estatus_pedido_id'].includes(k) && v.length) {
      return data = {
        ...data,
        filtros: {
          ...data.filtros,
          [k]: v.map(str => idExtractor(str))
        }
      }
    }

    if (['pedido_id'].includes(k) && v.length) {
      return data = {
        ...data,
        filtros: {
          ...data.filtros,
          [k]: [Number(v)]
        }
      }
    }
  })

  return data
}

const URL = getAll.agendamientos + '/listar'

export const getAllAgendamientos = async (url = URL, data = {}) => {
  try {
    if (!data) throw Error(`Missing data`)

    const {
      busqueda_usuario,
      pais_id,
      desde,
      hasta,
      fecha,
      limit,
      // pedido_id,
      // usuario_id,
      // puntoentrega_id,
      // metodo_entrega_id,
      // metodo_pago_id,
      // divisa_id,
      // tipo_facturacion_id,
      // estatus_pedido_id
    } = data

    const finalUrl = url + `?limit=${limit}` + queryParamsFormatter({ busqueda_usuario, pais_id, desde, hasta, fecha })

    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
      data: dataFormatter(data)
    }

    return await axios(finalUrl, config)
      .then(res => res)
      .catch(err => { throw err })
  } catch (err) {
    return { ok: false, msg: err }
  }
} 
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import DropdownMenu from '../../components/DropdownMenu'
import Modal from '../../components/Modal'
import Table from '../../components/Table'
import { NoResults } from '../../components/Tables/Misc/NoResults'
import CustomLoader from '../../components/CustomLoader'


// Layouts
import AccordionTable from '../../layout/AccordionTable'


// Endpoints
import { getOne } from '../../endpoints/getOne'


// Helpers
import FileUploaderB from '../../components/FileUploader_B'
import { BASE_URL, PATH_URL } from '../../endpoints'
import DropdownWrapper from '../../components/Dropdown/Wrapper'
import DropdownMenuLink from '../../components/Dropdown/Link'


const CONSTANT = {
	queryUrlGetOne: getOne.notas_ventas,
	title: 'Notas de venta',
	redirectListado: 'erp/planificacion',
	urlCreateTracking: (id) => BASE_URL + PATH_URL + `/erp/notas-ventas/tracking/${id}`,

	tiposPagos: {
		E: 'Efectivo',
		C: 'Cheque',
		T: 'Tarjeta de crédito',
		D: 'Tarjeta de dédito',
		N: 'Convenio',
		P: 'Cupón descuento',
		R: 'Cuenta corriente personal',
		W: 'Recaudador externo',
		Y: 'Depósito directo',
		B: 'Transferencia bancaria',
		V: 'Vale vista',
		1: 'Financiera',
		2: 'WebPay',
		default: 'Desconocido'
	},
}


// const fetchSelectFiltersData = async () => {
// 	const vendedor = await axios(CONSTANT.fecthErpVendedores,
// 		{
// 			headers: {
// 				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
// 			},
// 		})
// 		.then(({ data }) => data?.data ?? [])
// 		.catch(err => {
// 			console.error(err)
// 			return [{ id: '*NULL*', name: 'Sin resultados' }]
// 		})

// 	const bodega_reserva = await axios(CONSTANT.fecthErpBodegas,
// 		{
// 			headers: {
// 				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
// 			},
// 		})
// 		.then(({ data }) => [{ WARE_CODE: '*NULL*', DESC_TEXT: 'Sin selección' }, ...data?.data] ?? [])
// 		.catch(err => {
// 			console.error(err)
// 			return [{ WARE_CODE: '*NULL*', DESC_TEXT: 'Sin resultados' }]
// 		})

// 	return { vendedor, bodega_reserva }
// }


const Detalle = () => {
	const [modalDisplay, setModalDisplay] = useState(false)
	const [displayAllAccordion, setDisplayAllAccodion] = useState(true)
	const [data, setData] = useState(null)
	const [images, setImages] = useState([])
	const [trackingForm, setTrackingForm] = useState([])
	const [iframeData, setIframeData] = useState(undefined)
	const { id } = useParams()
	const dispatch = useDispatch()


	// EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO Y DE LOS SELECTS PARA NOTA VENTA
	useEffect(() => {
		axios(CONSTANT.queryUrlGetOne(id),
			{
				headers: {
					'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
				},
			})
			.then(({ data }) => {
				setData(data)
				setImages(data?.detalle_nv?.UD_IMG_TRACKING_1?.split(',') ?? [])
			})
			.catch(err => {
				console.error(err)
				const toasterContent = {
					title: `${err.code} - ${err.status}`,
					text: `${err.response.data.message}`,
					icon: 'error',
					timer: 300000
				}

				dispatch(fireToaster(toasterContent))
			})
		return () => setData(null)
	}, [id, dispatch])


	// FUNCIÓN MANEJADORA PARA ACTUALIZACIÓN DEL ESTATUS
	const handleFecth = async (objData, target) => {
		let config
		let url

		if (target === 'estatus') {
			let newEstatus = objData[target] ? 0 : 1
			url = CONSTANT.queryUrlGetOne(objData.id)
			config = {
				method: 'PUT',
				data: { [target]: newEstatus, _method: 'PUT' }
			}
		}

		if (target === 'tracking') {
			url = CONSTANT.urlCreateTracking(id)
			let dataForm = new FormData()
			trackingForm?.length && trackingForm.forEach(({ file }) => dataForm.append('imagenes[]', file))
			config = {
				method: 'POST',
				headers: { 'Content-Type': 'multipart/form-data' },
				data: dataForm
			}
		}

		if (target === 'download') {
			url = BASE_URL + 'common/v1/descargas?link=' + objData
			config = {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
				'Access-Control-Allow-Credentials': true,
				'Access-Control-Allow-Origin': '*'
			}
		}

		config = {
			...config,
			headers: {
				...config?.headers,
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			}
		}

		await axios(url, config)
			.then(res => {
				if (target === 'download') {
					setIframeData(BASE_URL + 'common/v1/descargas?link=' + objData)
					return
				}

				const toasterContent = {
					title: 'Operación realizada',
					text: `${CONSTANT.title}: ${target} actualizado con éxito`,
					icon: 'success'
				}

				dispatch(fireToaster(toasterContent))

				setData(null)
				setModalDisplay(false)
			})
			.catch(err => {
				console.error(err);
				const { errores } = err.response.data
				let detalles = []
				Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))
				const toasterContent = {
					title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
					html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
					icon: 'error'
				}

				dispatch(fireToaster(toasterContent))
			})

		await axios(CONSTANT.queryUrlGetOne(id),
			{
				headers: {
					'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
				},
			})
			.then(({ data }) => {
				setData(data)
				setImages(data.detalle_nv.UD_IMG_TRACKING_1.split(','))
			})
			.catch(err => console.error(err))
	}


	return (
		<>
			{/* MODAL AGENDAMIENTO  ??*/}
			<Modal display={modalDisplay} handleValue={() => setModalDisplay(false)} sizeDesk='col-md-12 h-fit'>
				<div className='m-2'>
					<h3 className='p-50 rounded-2 m-0'>Adjuntar tracking a NV #{id}</h3>
					<hr className='mt-0 mb-1' />

					<div className='col-12 mb-50'>
						<FileUploaderB value={trackingForm} handleValue={setTrackingForm} />
					</div>

					<div className='col-3'>
						<Button text='Adjuntar' color='success' onClick={() => handleFecth(trackingForm, 'tracking')} />
					</div>
				</div>
			</Modal>

			<iframe title='tracking' src={iframeData} style={{ display: 'none' }}></iframe>

			<div className="card-body p-25 bg-white mb-50 rounded-2 border">

				<div className='d-flex gap-1'>
					<div className='col'>
						<Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
					</div>
				</div>

				<div className="d-flex justify-content-between flex-md-row mt-0 p-25 align-items-center">
					<b className="mb-0 px-1 fs-3">Nota de venta
						<span className="mx-50 mb-0">#{id}</span>
					</b>
					<div className="d-flex gap-1">
						<Button text='Adjuntar tracking' icon='Plus' color='success' className='p-50' onClick={() => setModalDisplay(true)} />
						<Button icon={`${displayAllAccordion ? 'Minimize2' : 'Maximize2'}`} className='p-50' onClick={() => setDisplayAllAccodion(!displayAllAccordion)} />
						<DropdownMenu
							icon='MoreHorizontal'
						/>
					</div>
				</div>

			</div>

			{data
				? <div className='row'>

					{/* COLUMNA IZQUIERDA */}
					<div className='col-12 col-md-6'>

						{/* DATOS GENERALES */}
						<div className='col mb-1'>
							<Accordion
								title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
								isOpen={displayAllAccordion}
							>
								<div className='col px-2 align-items-center'>
									<div className='row'>
										<AccordionDetailData dataKey={'Fecha creación'} value={<b>{data?.detalle_nv?.ORDER_DATE?.split('T')?.[0] ?? 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Facturación'} value={<b>{data?.detalle_nv?.UD_TIPO_FACTURACION ?? 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Pedido'} value={<b>{data?.detalle_nv?.EXT_NUM ?? 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Estatus'} value={<b>{data?.detalle_nv?.STATUS_IND ?? 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Orden a proveedor'} value={<b>{data?.ordenes_compra.length ? 'Si' : 'No'}</b>} />
										<AccordionDetailData dataKey={'Total'} value={<b>{data?.detalle_nv?.TOTAL_AMT?.length ? '$ ' + data?.detalle_nv?.TOTAL_AMT : 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Neto'} value={<b>{data?.detalle_nv?.GOODS_AMT?.length ? '$ ' + data?.detalle_nv?.GOODS_AMT : 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Descuento'} value={<b>{data?.detalle_nv?.DISC_AMT?.length ? '$ ' + data?.detalle_nv?.DISC_AMT : 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Monto pagado'} value={<b>{data?.detalle_nv?.UD_MONTO_ABONADO?.length ? '$ ' + data?.detalle_nv?.UD_MONTO_ABONADO : 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Vendedor'} value={<b>{data?.detalle_nv?.NOMBRE_VENDEDOR?.length ? data?.detalle_nv?.NOMBRE_VENDEDOR : 'N/A'}</b>} last />
									</div>
								</div>
							</Accordion>
						</div>

						{/* OBSERVACIONES */}
						<div className='col mb-1'>
							<Accordion
								title={<h3 className='m-0 w-100 text-center text-white'>Descripción</h3>}
								isOpen={displayAllAccordion}
							>
								<p className='m-0 px-1'>{data?.detalle_nv?.COM_TEXT?.length ? data?.detalle_nv?.COM_TEXT : 'Sin observaciones'}</p>
							</Accordion>
						</div>
					</div>

					{/* COLUMNA DERECHA */}
					<div className='col-12 col-md-6'>
						{/* CLIENTE */}
						<div className='col mb-1'>
							<Accordion
								isOpen={displayAllAccordion}
								title={<h3 className='m-0 w-100 text-center text-white'>Cliente</h3>}
							>
								<div className='col px-2 align-items-center'>
									<div className='row'>
										<AccordionDetailData dataKey={'Nombre y apellido'} value={<b>{data?.detalle_nv?.CLIENTE_NOMBRE?.length ? data?.detalle_nv?.CLIENTE_NOMBRE : 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Teléfono'} value={<b>{data?.detalle_nv?.CLIENTE_TELEFONO?.length ? data?.detalle_nv?.CLIENTE_TELEFONO : 'N/A'}</b>} />
										<AccordionDetailData dataKey={'Email'} value={<b>{data?.detalle_nv?.CLIENTE_EMAIL?.length ? data?.detalle_nv?.CLIENTE_EMAIL : 'N/A'}</b>} />
										<AccordionDetailData dataKey={'RUT'} value={<b>{data?.detalle_nv?.CLIENTE_RUT?.length ? data?.detalle_nv?.CLIENTE_RUT : 'N/A'}</b>} last />
									</div>
								</div>
							</Accordion>
						</div>

						{/* PUNTO ENTREGA */}
						<div className='col mb-1'>
							<Accordion
								isOpen={displayAllAccordion}
								title={<h3 className='m-0 w-100 text-center text-white'>Punto de entrega</h3>}
							>
								<div className='col px-2 align-items-center'>
									<div className='row'>
										<AccordionDetailData dataKey={'Despachar a'} value={`${data?.detalle_nv?.SHIP_CODE ?? 'N/A'}`} />
										<AccordionDetailData dataKey={'Comuna/Destino'} value={data?.detalle_nv?.COMUNA_ENTREGA ?? 'N/A'} />
										<AccordionDetailData dataKey={'Nombre'} value={data?.detalle_nv?.SHIP_NAME_TEXT ?? 'N/A'} />
										<AccordionDetailData dataKey={'Dirección'} value={data?.detalle_nv?.SHIP_ADDR_TEXT ?? 'N/A'} last />
										<AccordionDetailData dataKey={'Planificación'} value={data?.detalle_nv?.SHIP_DATE?.split('T')?.[0] ?? 'N/A'} />
									</div>
								</div>
							</Accordion>
						</div>

						{/* DATOS FACTURA */}
						{data?.detalle_nv?.UD_TIPO_FACTURACION === 'Factura' &&
							<div className='col mb-1'>
								<Accordion
									isOpen={displayAllAccordion}
									title={<h3 className='m-0 w-100 text-center text-white'>Datos factura</h3>}
								>
									<div className='col px-2 align-items-center'>
										<div className='row'>
											<AccordionDetailData dataKey={'Facturar a'} value={`${data?.detalle_nv?.BILL_CODE ?? 'N/A'}`} />
											<AccordionDetailData dataKey={'Comuna'} value={data?.detalle_nv?.COMUNA_FACTURA ?? 'N/A'} />
											<AccordionDetailData dataKey={'Nombre'} value={data?.detalle_nv?.BILL_NAME_TEXT ?? 'N/A'} />
											<AccordionDetailData dataKey={'Dirección'} value={data?.detalle_nv?.BILL_ADDR_TEXT ?? 'N/A'} last />
										</div>
									</div>
								</Accordion>
							</div>
						}
					</div>

					{/* PRODUCTOS */}
					<AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Productos'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead>
								<tr>
									<th>Descripción</th>
									<th>SKU</th>
									<th>Bodega</th>
									<th>Qty</th>
									<th>Qty reservada</th>
									<th>Qty pdte.</th>
									<th>P. unit. neto</th>
									<th>P. subtotal neto</th>
								</tr>
							</thead>
							<tbody>
								{data?.lineas_nv?.length
									? data?.lineas_nv.map(({ DESC_TEXT, PART_CODE, WARE_CODE, UOM_QTY, SCHED_QTY, BACK_QTY, UNIT_PRICE_AMT, EXT_PRICE_AMT }, i) => (
										<tr key={`lineas-pedido-erp-${DESC_TEXT ?? 'producto'}-${i ?? PART_CODE}`}>

											<td><a href='/producto/detalle'>{DESC_TEXT.toUpperCase() ?? 'N/A'}</a></td>

											<td><b>{PART_CODE ?? 'N/A'}</b></td>

											<td><b>{WARE_CODE ?? 'N/A'}</b></td>

											<td><b>{UOM_QTY ?? 'N/A'}</b></td>

											<td><b>{SCHED_QTY ?? 'N/A'}</b></td>

											<td><b>{BACK_QTY ?? 'N/A'}</b></td>

											<td><b>{UNIT_PRICE_AMT?.length ? '$ ' + UNIT_PRICE_AMT : 'N/A'}</b></td>

											<td><b>{EXT_PRICE_AMT?.length ? '$ ' + EXT_PRICE_AMT : 'N/A'}</b></td>

										</tr>
									))
									: <tr><td colSpan={8}><p className='tag dark-blue px-50 m-auto'><b>Error de conexión</b></p></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable>

					{/* IMAGENES */}
					<AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Imágenes'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead>
								<tr>
									<th>Imagen</th>
									<th>Ruta</th>
									<th className='col-1'>Acciones</th>
								</tr>
							</thead>
							<tbody>
								{images?.length
									? images.map((imagen, i) => (
										<tr key={`detalle-tracking-${imagen ?? 'producto'}-${i}`}>
											<td>
												<div className='d-flex w-100 justify-content-center'>
													<img
														key={`clave-atributo-imagen-${i}-${imagen ?? 'unk'}`}
														className='height-listado p-50 border rounded-2'
														src={BASE_URL + 'storage/' + imagen}
														alt="img"
													/>
												</div>
											</td>

											<td><b>{imagen}</b></td>

											<td>
												<DropdownWrapper icon='MoreHorizontal'>
													<DropdownMenuLink text='Descargar' icon='Download' onClick={() => handleFecth('storage/' + imagen, 'download')} download={'imagen1'} />
												</DropdownWrapper>
											</td>
										</tr>
									))

									: <tr><td colSpan={3}><p className='tag dark-blue px-50 m-auto'><b>Sin resultados</b></p></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable>

					{/* FACTURA */}
					<AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Facturas'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead className="table-light">
								<tr role="row">
									<th>Factura</th>
									<th>Cliente</th>
									<th>NV</th>
									<th>Guía</th>
									<th>Montos</th>
									<th>Pagado</th>
								</tr>
							</thead>
							<tbody>
								{data?.facturas_nv?.length

									? data?.facturas_nv?.map(({
										INV_NUM, INV_DATE, ENTRY_CODE, CUST_CODE, ORD_NUM, GUIA_NUM, GOODS_AMT, TAX_AMT, TOTAL_AMT, PAID_AMT, COM_TEXT, NAME_TEXT
									}, i) => (
										<React.Fragment key={`factura-${ORD_NUM}-${i ?? GUIA_NUM}`}>
											<tr style={{ backgroundColor: i % 2 > 0 ? '#ededeb' : '#fff' }}>
												<td rowSpan={2}>
													<div className='d-flex flex-column'>
														<b>#{INV_NUM ?? 'N/A'}</b>
														<span>Digitador: <b>{ENTRY_CODE ?? 'N/A'}</b></span>
														<span>{INV_DATE?.split('T')[0] ?? '?'}</span>
													</div>
												</td>

												<td>
													<div className='d-flex flex-column'>
														<b>{NAME_TEXT ?? 'N/A'}</b>
														<span>RUT: <b>{CUST_CODE ?? 'N/A'}</b></span>
													</div>
												</td>


												<td><b>{ORD_NUM ?? 'N/A'}</b></td>

												<td><b>{GUIA_NUM ?? 'N/A'}</b></td>

												<td style={{ backgroundColor: i % 2 > 0 ? '#ededeb' : '#fff' }}>
													<div className='d-flex flex-column'>
														<b className='fs-3'>{TOTAL_AMT?.length ? '$ ' + TOTAL_AMT : 'N/A'}</b>
														<span>Subtotal: <b>{GOODS_AMT?.length ? '$ ' + GOODS_AMT : 'N/A'}</b></span>
														<span>Impuesto: <b>{TAX_AMT?.length ? '$ ' + TAX_AMT : 'N/A'}</b></span>
													</div>
												</td>

												<td><b>{PAID_AMT?.length ? '$ ' + PAID_AMT : 'N/A'}</b></td>
											</tr>

											<tr>
												<td colSpan={5}>
													<div className='d-flex flex-column'>
														<b>Comentario:</b>
														<span>{COM_TEXT?.length ? COM_TEXT : 'Sin comentarios'}</span>
													</div>
												</td>
											</tr>

										</React.Fragment>

									))

									: <tr><td colSpan={6}><NoResults /></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable>

					{/* ORDENES COMPRA */}
					<AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Órdenes de compra'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead className="table-light">
								<tr role="row">
									<th>Orden compra</th>
									<th>SKU</th>
									<th>Bodega</th>
									<th>Qty solicitada</th>
									<th>Precio unit. neto</th>
									<th>Precio total</th>
								</tr>
							</thead>
							<tbody>
								{data?.ordenes_compra?.length

									? data?.ordenes_compra?.map(({
										NUM_OC, FECHA_OC, PART_CODE, DESC_TEXT, BODEGA_OC, ORDER_QTY, UNIT_PRICE_AMT, LINE_TOTAL_AMT
									}, i) => (
										<React.Fragment key={`ordenes-compra-${NUM_OC}-${i ?? FECHA_OC}`}>
											<tr>
												<td rowSpan={2} style={{ borderBottom: '1px solid #444' }}>
													<div className='d-flex flex-column'>
														<b>#{NUM_OC ?? 'N/A'}</b>
														<span>{FECHA_OC?.split('T')[0] ?? '?'}</span>
													</div>
												</td>

												<td><b>{PART_CODE ?? 'N/A'}</b></td>

												<td><b>{BODEGA_OC ?? 'N/A'}</b></td>

												<td><b>{ORDER_QTY ?? 'N/A'}</b></td>

												<td><b>{UNIT_PRICE_AMT ?? 'N/A'}</b></td>

												<td><b>{LINE_TOTAL_AMT ?? 'N/A'}</b></td>

											</tr>

											<tr style={{ borderBottom: '#444' }}>
												<td colSpan={6}>
													<div className='d-flex flex-column'>
														<b>Descripción:</b>
														<span>{DESC_TEXT?.length ? DESC_TEXT : 'Sin descripción'}</span>
													</div>
												</td>
											</tr>

										</React.Fragment>

									))

									: <tr><td colSpan={7}><NoResults /></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable>

					{/* PAGOS */}
					<AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Pagos'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead className="table-light">
								<tr role="row">
									<th>Pago</th>
									<th>Digitador</th>
									<th>Tipo de pago</th>
									<th>Monto pago</th>
									<th>Monto aplicado</th>
								</tr>
							</thead>
							<tbody>
								{data?.pagos_nv?.length

									? data?.pagos_nv?.map(({
										CASH_NUM, ENTRY_DATE, ENTRY_CODE, CASH_TYPE_IND, CASH_AMT, APPLIED_AMT
									}, i) => (
										<tr key={`pagos-${CASH_NUM}-${i ?? ENTRY_DATE}`}>
											<td>
												<div className='d-flex flex-column'>
													<b>#{CASH_NUM ?? 'N/A'}</b>
													<span>{ENTRY_DATE?.split('T')[0] ?? '?'}</span>
												</div>
											</td>

											<td><b>{ENTRY_CODE ?? 'N/A'}</b></td>

											<td><b>{CONSTANT.tiposPagos[CASH_TYPE_IND ?? 'default'] ?? 'N/A'}</b></td>

											<td><b>{CASH_AMT?.length ? '$ ' + CASH_AMT : 'N/A'}</b></td>

											<td><b>{APPLIED_AMT?.length ? '$ ' + APPLIED_AMT : 'N/A'}</b></td>

										</tr>
									))

									: <tr><td colSpan={5}><NoResults /></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable>

					{/* GUIAS DESPACHO */}
					<AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Guías despacho'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead className="table-light">
								<tr role="row">
									<th>Guía</th>
									<th>Tipo guía</th>
									<th>Digitador</th>
									<th>Monto neto</th>
									<th>Monto total</th>
									<th>Dirección despacho</th>
								</tr>
							</thead>
							<tbody>
								{data?.guias_despacho_nv?.length

									? data?.guias_despacho_nv?.map(({
										GUIA_NUM, ENTRY_DATE, COD_MOV, ENTRY_CODE, GOODS_AMT, TOTAL_AMT, SHIP_TEXT
									}, i) => (
										<tr key={`pagos-${GUIA_NUM}-${i ?? ENTRY_DATE}`}>
											<td>
												<div className='d-flex flex-column'>
													<b>#{GUIA_NUM ?? 'N/A'}</b>
													<span>{ENTRY_DATE?.split('T')[0] ?? '?'}</span>
												</div>
											</td>

											<td><b>{COD_MOV ?? 'N/A'}</b></td>

											<td><b>{ENTRY_CODE ?? 'N/A'}</b></td>

											<td><b>{GOODS_AMT?.length ? '$ ' + GOODS_AMT : 'N/A'}</b></td>

											<td><b>{TOTAL_AMT?.length ? '$ ' + TOTAL_AMT : 'N/A'}</b></td>

											<td><b>{SHIP_TEXT ?? 'N/A'}</b></td>
										</tr>
									))

									: <tr><td colSpan={6}><NoResults /></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable>

					{/* NOTAS CREDITO */}
					<AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Notas de crédito'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead className="table-light">
								<tr role="row">
									<th>Notas crédito</th>
									<th>Digitador</th>
									<th>Cliente</th>
									<th>Monto neto</th>
									<th>Monto total</th>
								</tr>
							</thead>
							<tbody>
								{data?.notas_creditos_nv?.length

									? data?.notas_creditos_nv?.map(({
										CRED_NUM, ENTRY_DATE, ENTRY_CODE, BILL_CODE, BILL_NAME_TEXT, GOODS_AMT, TOTAL_AMT
									}, i) => (
										<tr key={`pagos-${CRED_NUM}-${i ?? ENTRY_DATE}`}>
											<td>
												<div className='d-flex flex-column'>
													<b>#{CRED_NUM ?? 'N/A'}</b>
													<span>{ENTRY_DATE?.split('T')[0] ?? '?'}</span>
												</div>
											</td>

											<td><b>{ENTRY_CODE ?? 'N/A'}</b></td>

											<td>
												<div className='d-flex flex-column'>
													<b>{BILL_NAME_TEXT ?? 'N/A'}</b>
													<span>RUT: <b>{BILL_CODE ?? 'N/A'}</b></span>
												</div>
											</td>

											<td><b>{GOODS_AMT?.length ? '$ ' + GOODS_AMT : 'N/A'}</b></td>

											<td><b>{TOTAL_AMT?.length ? '$ ' + TOTAL_AMT : 'N/A'}</b></td>
										</tr>
									))

									: <tr><td colSpan={5}><NoResults /></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable>

				</div >

				: <CustomLoader />
			}
		</>
	)
}

export default Detalle
import {
   FILTER_CLEAR,
   FILTER_REMOVE_VENDEDOR,
   FILTER_ADD_VENDEDOR,
   FILTER_PERIODO,
   FILTER_ESTADO_PAGO,
   RESULTADOS_REPORTE_VENTAS,
   FILTER_REMOVE_ESTATUS_PEDIDO,
   FILTER_ADD_ESTATUS_PEDIDO,
   FILTER_FECHA_INICIO,
   FILTER_FECHA_FINAL,
   FILTER_DEPARTAMENTO,
   FILTER_EMPRESA
} from "../redux-types";

export const departamentoFilter = (data) => ({ type: FILTER_DEPARTAMENTO, payload: data })

export const periodoFilter = (data) => ({ type: FILTER_PERIODO, payload: data })

export const fechaInicioFilter = (data) => ({ type: FILTER_FECHA_INICIO, payload: data })

export const fechaFinalFilter = (data) => ({ type: FILTER_FECHA_FINAL, payload: data })

export const estadoPagoFilter = (data) => ({ type: FILTER_ESTADO_PAGO, payload: data })

export const empresaFilter = (data) => ({ type: FILTER_EMPRESA, payload: data })

export const vendedorFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_VENDEDOR, payload: data }
      : { type: FILTER_ADD_VENDEDOR, payload: data }

export const estatusPedidoFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_ESTATUS_PEDIDO, payload: data }
      : { type: FILTER_ADD_ESTATUS_PEDIDO, payload: data }

export const resultadosReporteVentas = (data) => ({ type: RESULTADOS_REPORTE_VENTAS, payload: data })

export const clearFilterReporteVentas = () => ({ type: FILTER_CLEAR });

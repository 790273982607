import { useSelector } from 'react-redux'


// Redux
import { useEffect, useState } from 'react'


// Components
import ProductosEtiquetasFiltros from '../../../components/AccordionFilter/ProductosEtiquetas'


// Layouts
import AccordionTable from '../../../layout/AccordionTable'
import Button from '../../../components/Button'


const CONSTANT = {
	path: 'etiquetas',
	title: 'Etiquetas'
}


const Etiquetas = () => {
	const { resultados: response } = useSelector(state => state.productosEtiquetas)
	const [r, setR] = useState(null)
	const [etiquetas, setEtiquetas] = useState([])
	const [resultadosFormatted, setResutadosFormatted] = useState(null)


	useEffect(() => {
		setResutadosFormatted(null)
		setEtiquetas([])
		if (!response) return
		setR(response?.etiquetas_productos ?? [])
	}, [response])


	useEffect(() => {
		if (!r?.length) return
		const arrEtq = []

		r.forEach(PE => {
			Object.entries(PE).forEach(([k, v]) => {
				if (k === 'neu_etiqueta_producto') {
					v.forEach(ep => {
						if (!arrEtq.length) arrEtq.push(ep)
						arrEtq.find(({ etiqueta_id }) => ep.etiqueta_id === etiqueta_id) ?? arrEtq.push(ep)
					})
				}
			})
		})
		setEtiquetas(arrEtq);
	}, [r])


	useEffect(() => {
		if (!r?.length) return
		const newResultados = r.map(productoEtiqueta => {
			let newObj = {
				...productoEtiqueta,
				neu_etiqueta_producto: etiquetas.map(() => ({}))
			}
			etiquetas.forEach((currentEtiqueta, i) => {
				const isIncludedEtiqueta = productoEtiqueta.neu_etiqueta_producto.find(el => el.etiqueta_id === currentEtiqueta.etiqueta_id)

				if (isIncludedEtiqueta) {
					newObj.neu_etiqueta_producto.splice(i, 1, isIncludedEtiqueta)
					newObj = {
						...newObj,
						neu_etiqueta_producto: newObj.neu_etiqueta_producto
					}
				}
			})

			return newObj
		})
		setResutadosFormatted(newResultados)
	}, [etiquetas])


	return (
		<div className="row">
			<div className='d-flex justify-content-between mb-1'>
				<h3>{CONSTANT.title} - Listado</h3>

				<div className='d-flex gap-1'>
					<div className='w-fit'>
						<Button text='Asignación masiva' className='m-auto p-50' color='success' url={`/productos/${CONSTANT.path}/asignacion-masiva`} isLink />
					</div>
					<div className='w-fit'>
						<Button text='Edición masiva' className='m-auto p-50' color='warning' url={`/productos/${CONSTANT.path}/edicion-masiva`} isLink />
					</div>
				</div>
			</div>

			<div className="col-12 mx-auto">
				{/* COMPONENTE FILTROS - PRODUCTOS */}
				<ProductosEtiquetasFiltros type='visualizar' />

				{/* TABLA DE RESULTADOS PRODUCTOS - ETIQUETAS */}
				<AccordionTable
					title={`Tabla de resultados (${r?.length ?? 0})`}
				>
					<div className='d-flex'>

						{/* PRODUCTOS */}
						<div
							className={`card-datatable table-responsive pt-0 custom-scrollbar overflow-auto shadow flex-shrink-0 dataTable w-100`}
						>
							<table className="user-list-table table position-relative no-footer dtr-column z-10">
								<thead className="table-light position-sticky top-0 z-100" >
									<tr role="row">
										{resultadosFormatted &&
											<th className='border-end position-sticky left-0 z-100 w-fit' style={{ height: '85px', width: '180px' }}>
												<p className='text-center mt-2'>Producto</p>
											</th>
										}

										{etiquetas?.map(({ neu_etiqueta }, i) => (
											<th style={{ height: '85px', minWidth: '210px' }} className='border-end' key={`${i}-${neu_etiqueta?.nombre}`}>
												<div className='mx-auto w-fit my-auto'>
													<a href={`/etiquetas/detalle/${neu_etiqueta?.id}`} target='_blank' rel='noreferrer'>
														<p className='my-0'>{neu_etiqueta?.nombre ?? 'N/A'} <span className='tag px-50 sub-text-4'>{neu_etiqueta?.estatus ? 'Activo' : 'Inactivo'}</span></p>
													</a>
													<p className='sub-text-4 my-0'>{neu_etiqueta?.neu_oferta?.nombre ?? 'N/A'}</p>
													<p className='sub-text-4 my-0'>{neu_etiqueta?.fecha_hora_inicio.split('T')[0] ?? 'N/A'} / {neu_etiqueta?.fecha_hora_final.split('T')[0] ?? 'N/A'}</p>
												</div>
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{resultadosFormatted
										? resultadosFormatted?.map((data, i) => {
											return (
												<tr className="odd" key={`product-${data?.etiqueta_producto_id ?? i}-${data?.producto}`}>
													{/* RESUMEN PRODUCTO - DETALLE */}
													<td className='p-0 position-sticky left-0 border-end z-10'>
														<div className='px-2 py-1 bg-product'>
															<div className='my-0 d-flex flex-column'>
																<span>
																	<a href={`/productos/detalle/${data?.id}`} target='_blank' rel='noreferrer'>
																		<b>{data?.producto.toUpperCase()}</b>
																	</a>
																</span>
															</div>
															<div className='my-0 sub-text-3'>{data?.categoria}</div>
															<div className='my-0 sub-text-3'>{data?.sku}</div>
															<span className={`my-auto tag px-50 sub-text-3 w-fit ${data?.publicado ? 'success' : 'red'}`}>{data?.publicado ? 'Publicado' : 'Sin publicar'}</span>
														</div>
													</td>
													{data?.neu_etiqueta_producto.map((etiqueta, i) => (
														<td className='bg-white border-end' key={`${i}-etiqueta-${data?.producto}`}>
															{Object.entries(etiqueta).length
																? <div className='m-auto w-fit z-10 '>
																	<div>
																		<span className={`tag ${etiqueta?.estatus ? 'success' : 'red'}  px-50`}>{etiqueta?.estatus ? 'Activo' : 'Inactivo'}</span>
																	</div>
																	<div><b>{etiqueta?.fecha_hora_inicio.split('T')[0] ?? 'N/A'}</b></div>
																	<div><b>{etiqueta?.fecha_hora_final.split('T')[0] ?? 'N/A'}</b></div>
																	{/* <div>Editar</div> */}
																</div>
																: <p className='text-center'>N/A</p>
															}
														</td>
													))}
												</tr>
											)
										})
										: <tr><td><p className='text-center fw-bolder fs-2 mt-1'>Realice una búsqueda</p></td></tr>
									}
								</tbody>
							</table>
						</div>
					</div>

				</AccordionTable>
			</div>
		</div >
	)
}

export default Etiquetas
import {
   FILTER_ADD_ATRIBUTOS,
   FILTER_ADD_PUNTO_ENTREGA,
   FILTER_CLEAR,
   FILTER_REMOVE_ATRIBUTOS,
   FILTER_REMOVE_PUNTO_ENTREGA,
   FILTROS_PERSISTENTES_PARAMETROS_RECARGOS,
   RESULTADOS_PARAMETROS_RECARGOS,
} from "../redux-types";

const initialState = {
   filtros: {
      puntos_entregas_ids: [],
      atributos_ids: [] // endpoint: valores-atributos
   },
   resultados: null
}


export const parametrosRecargosReducer = (state = initialState, action) => {
   let isListed

   switch (action.type) {
      case FILTER_ADD_PUNTO_ENTREGA:
         isListed = state.filtros.puntos_entregas_ids.find(el => el === action.payload)

         return {
            ...state, filtros: {
               ...state.filtros,
               puntos_entregas_ids: isListed
                  ? state.filtros.puntos_entregas_ids
                  : [...state.filtros.puntos_entregas_ids, action.payload]
            }
         };

      case FILTER_REMOVE_PUNTO_ENTREGA:
         return {
            ...state, filtros: {
               ...state.filtros,
               puntos_entregas_ids: state.filtros.puntos_entregas_ids.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_ATRIBUTOS:
         isListed = state.filtros.atributos_ids.find(el => el === action.payload)

         return {
            ...state, filtros: {
               ...state.filtros,
               atributos_ids: isListed
                  ? state.filtros.atributos_ids
                  : [...state.filtros.atributos_ids, action.payload]
            }
         };

      case FILTER_REMOVE_ATRIBUTOS:
         return {
            ...state, filtros: {
               ...state.filtros,
               atributos_ids: state.filtros.atributos_ids.filter(el => el !== action.payload)
            }
         };

      case RESULTADOS_PARAMETROS_RECARGOS:
         return {
            ...state, resultados: action.payload
         };

      case FILTROS_PERSISTENTES_PARAMETROS_RECARGOS:
         return {
            ...state, filtros: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
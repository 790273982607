import { useEffect, useState } from "react"
import Modal from "../../Modal"
import FormInput from "../../FormInput"
import SelectFilter from "../../SelectFilter"
import Button from "../../Button"
import { handleSelectsData } from "../../AccordionFilter/Empresas"
import { approveEmpresa } from "../../../services/empresas/approveEmpresa.service"
import { fireToaster } from "../../../redux/actions/toaster"
import { useDispatch } from "react-redux"

const CONSTANT = {
  form: {
    tipo_id: null,
    credito: null,
    porcentaje_descuento: null
  },
  selectFilterInitialState: {
    empresasTipos: [],
  },
}

const ModalAprobarEmpresa = ({ data, isOpen, handleClose }) => {
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [form, setForm] = useState(CONSTANT.form)
  const dispatch = useDispatch()

  useEffect(() => {
    handleSelectsData().then(res => setSelectFilter(res))
  }, [])

  useEffect(() => {
    setForm(CONSTANT.form)

    return () => {
      setForm(CONSTANT.form)
    }
  }, [isOpen])


  const handleInputChange = (e) => {
    const { name, value } = e.target

    if (['porcentaje_descuento', 'credito'].includes(name) && isNaN(Number(value))) return

    setForm(prev => ({
      ...prev,
      [name]: value
    }))
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!form.tipo_id) return dispatch(fireToaster({ title: 'El tipo de empresa es requerido', icon: 'info' }))

    await approveEmpresa({ ...form, id: data?.id }).then(res => {
      handleClose()
    })
  }

  if (!data) return (<></>)

  return (
    <Modal display={isOpen} handleValue={handleClose}>
      <h3>Aprobar empresa</h3>

      <h5 className="mb-2">#{data?.id} - {data?.razon_social}</h5>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <SelectFilter
              labelText="Tipo empresa"
              altSeparator
              size="col-12"
              sizeDesk={"col-md-12"}
              optionObj={selectFilter?.empresasTipos?.map(({ id, nombre }) => ({ id, name: nombre }))}
              handleValue={handleInputChange}
              name={'tipo_id'}
              value={form?.tipo_id?.split('%-%')[1] ?? 'Seleccione'}
            />
          </div>

          <FormInput
            labelText='% Descuento'
            placeholder='Ingrese un porcentaje'
            size='col-12'
            name={`porcentaje_descuento`}
            sizeDesk='col-md-6'
            handleValue={handleInputChange}
            value={form?.porcentaje_descuento ?? ''}
          />

          <FormInput
            labelText='Crédito'
            name={`credito`}
            placeholder='Ingrese una cantidad'
            size='col-12'
            handleValue={handleInputChange}
            sizeDesk='col-md-6'
            value={form.credito ?? ''}
            isDisabled={form?.tipo_id?.split('%-%')[1] !== 'b2b'}
          />

          <div className="col-12 d-flex justify-content-around mt-4">
            <Button text={`Aprobar`} onClick={handleSubmit} color={'success'} />
            <Button text={`Cancelar`} onClick={handleClose} color={'danger'} />
          </div>
        </div>

      </form>
    </Modal>
  )
}

export default ModalAprobarEmpresa
import axios from 'axios'
import React, { useEffect, useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import {
  busquedaFilter,
  categoriaFilter,
  etiquetaFilter,
  precioDesdeFilter,
  precioHastaFilter,
  skusFilter,
  clearFilterProductosEtiquetas,
  resultadosProductosEtiquetas
} from '../../redux/actions/productosEtiquetas'
import { clavesFilter, displayModal } from '../../redux/actions/modalProductosFiltros'
import { FILTER_ADD_CATEGORIA, FILTER_ADD_CLAVE, FILTER_ADD_ETIQUETA, FILTER_BUSQUEDA, FILTER_PRECIO_DESDE, FILTER_PRECIO_HASTA, FILTER_SKUS_PRODUCTO } from '../../redux/redux-types'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Components
import FiltroAplicado from './FiltroAplicado'
import Button from '../Button'
import FormInput from '../FormInput'
import SelectFilter from '../SelectFilter'


// Helpers
import { lastPosition } from '../../helpers/lastPosition'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'


// Endpoints
import { getAll } from '../../endpoints/getAll'
import { fireToaster } from '../../redux/actions/toaster'
import ModalProductosFiltros from '../Modal/Productos'
import capitalize from '../../helpers/capitalize'


const CONSTANT = {
  queryUrlGetAll: getAll.etiquetas_productos,
  redirectUrl: '/productos-etiquetas',
  title: 'Búsqueda de poveedores',
  reduxClearFilters: clearFilterProductosEtiquetas,
  reduxSetResults: (data) => resultadosProductosEtiquetas(data),
  queryUrlGetAllCategorias: getAll.categorias + '/buscar',
  queryUrlGetAllEtiquetas: getAll.etiquetas + '/listar?limit=9999'
}


const fecthDataFormatter = (filtrosObj, modalObj) => {
  let data = {}

  Object.entries(filtrosObj).forEach(([k, v]) => {
    if (!v?.length) return
    if (k === 'busqueda') return

    if (['categorias_id', 'etiquetas_ids'].includes(k)) {
      data = {
        ...data,
        [k]: v.map(el => Number(el.split('-')[0]))
      }
    }

    if (k === 'skus') {
      data = {
        ...data,
        [k]: v
      }
    }

    if (['precio_desde', 'precio_hasta'].includes(k)) {
      data = {
        ...data,
        filtro_precio: {
          ...data.filtro_precio,
          [k]: Number(v)
        }
      }
    }
  })

  if (Object.keys(modalObj).length) {
    let filtros_productos = {}

    Object.entries(modalObj).forEach(([k, v]) => {
      if (!v?.length) return
      filtros_productos = {
        ...filtros_productos,
        [k]: v.map(el => Number(el.split('-')[0]))
      }
    })

    data = { ...data, filtros_productos }
  }

  return data
}


const ProductosEtiquetasFiltros = ({ type = 'listar' }) => {
  const { filtros } = useSelector(state => state.productosEtiquetas)
  const { filtros_productos } = useSelector(state => state.modalProductosFiltros)
  const dispatch = useDispatch()
  const { categorias_id, precio_desde, precio_hasta, parametros_busqueda, etiquetas_ids, skus } = filtros
  const [categorias, setCategorias] = useState(null)
  const [etiquetas, setEtiquetas] = useState(null)

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RELLENA EL SELECTFILTER DE: categorias, Y RENDERIZA RESULTADOS POR PRIMERA VEZ
  useEffect(() => {
    axios(CONSTANT.queryUrlGetAllEtiquetas, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
      .then(({ data }) => setEtiquetas(data.etiquetas.data))
      .catch(err => console.log(err))

    axios(CONSTANT.queryUrlGetAllCategorias, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
      .then(({ data }) => setCategorias(data.categorias))
      .catch(err => console.log(err))
  }, [dispatch])


  // EFECTO QUE LIMPIA LOS RESULTADOS AL DESMONTAR
  useEffect(() => { return () => dispatch(CONSTANT.reduxSetResults(null)) }, [dispatch])


  // FUNCION MANEJADORA QUE CONSULTA EL ENDPOINT CON LOS FILTROS APLICADOS
  const handleFetchFilter = async (reset = false) => {
    let data = false
    let config
    let url = CONSTANT.queryUrlGetAll + `/${type}?limit=99999&asignacion_masiva=1` + queryParamsFormatter({ parametros_busqueda })

    if (!Object.values(filtros).find(el => el?.length)) return dispatch(fireToaster({ title: 'Por favor seleccione al menos un filtro', icon: 'warning' }))

    setIsConsulting(true)

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      setIsConsulting(false)
      return dispatch(CONSTANT.reduxSetResults([]))
    }

    // FORMATEADOR DE data
    else {
      data = fecthDataFormatter(filtros, filtros_productos)
      config = {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data
      }
    }

    dispatch(CONSTANT.reduxSetResults(null))

    await axios(url, config)
      .then(({ data }) => {
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
        return dispatch(CONSTANT.reduxSetResults(data ?? null))
      })
      .catch(err => {
        console.error(err)

        const { errores } = err.response.data
        let detalles = []

        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  // MANEJADORES DE FILTRO - PRODUCTOS
  const handleFilter = (e, filter) => {
    const { value } = e.target
    switch (filter) {
      case FILTER_ADD_CATEGORIA:
        dispatch(categoriaFilter(value))
        break;

      case FILTER_PRECIO_DESDE:
        dispatch(precioDesdeFilter(value))
        break;

      case FILTER_PRECIO_HASTA:
        dispatch(precioHastaFilter(value))
        break;

      case FILTER_ADD_ETIQUETA:
        dispatch(etiquetaFilter(value))
        break;

      case FILTER_BUSQUEDA:
        dispatch(busquedaFilter(value))
        break;

      case FILTER_SKUS_PRODUCTO:
        dispatch(skusFilter(value))
        break;

      case FILTER_ADD_CLAVE:
        dispatch(clavesFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">

      <ModalProductosFiltros />

      <ListadoHeader
        classes='border rounded-2'
        title='Búsqueda de productos'
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <FormInput
          labelText='Búsqueda'
          placeholder='Búsqueda'
          size='col-12'
          sizeDesk='col-md-4'
          handleValue={(e) => handleFilter(e, FILTER_BUSQUEDA)}
          value={parametros_busqueda}
        />

        <FormInput
          labelText='SKUs'
          placeholder='SKUs'
          size='col-12'
          sizeDesk='col-md-4'
          handleValue={(e) => handleFilter(e, FILTER_SKUS_PRODUCTO)}
          value={skus}
        />

        <SelectFilter
          labelText='Categoría'
          size='col-12'
          sizeDesk='col-md-3'
          handleValue={(e) => handleFilter(e, FILTER_ADD_CATEGORIA)}
          optionObj={categorias?.map(({ id, nombre }) => ({ id, name: nombre }))}
          value={lastPosition(categorias_id)}
        />

        <SelectFilter
          labelText='Etiquetas'
          size='col-12'
          sizeDesk='col-md-3'
          handleValue={(e) => handleFilter(e, FILTER_ADD_ETIQUETA)}
          optionObj={etiquetas?.map(({ id, nombre }) => ({ id, name: nombre }))}
          value={lastPosition(etiquetas_ids)}
        />

        <FormInput
          type='number'
          labelText='Precio desde'
          placeholder='Número'
          size='col-12'
          sizeDesk='col-md-2'
          handleValue={(e) => handleFilter(e, FILTER_PRECIO_DESDE)}
          value={precio_desde}
        />

        <FormInput
          type='number'
          labelText='Precio hasta'
          placeholder='Número'
          size='col-12'
          sizeDesk='col-md-2'
          handleValue={(e) => handleFilter(e, FILTER_PRECIO_HASTA)}
          value={precio_hasta}
        />

        <div className="col-12 col-md-1 my-auto">
          <Button icon={'Plus'} text='Filtros' className={'w-fit my-auto'} onClick={() => dispatch(displayModal(true))} />
        </div>

        {/* FILTROS APLICADOS - PRODUCTOS */}
        <p className='mb-25 text-black'>Filtros aplicados</p>
        <FiltroAplicado array={categorias_id} func={categoriaFilter} title={'Categorías'} />
        <FiltroAplicado array={etiquetas_ids} func={etiquetaFilter} title={'Etiquetas'} />

        {Object?.keys(filtros_productos)?.length
          ? Object?.entries(filtros_productos).map(([nombreFiltro, valoresArr]) => (valoresArr.length)
            ? <FiltroAplicado key={capitalize(nombreFiltro.split('_').join(' '))} array={valoresArr} title={capitalize(nombreFiltro.split('_').join(' '))} func={clavesFilter} />
            : <></>
          )

          : <></>
        }

        <hr className='my-1' />
      </ListadoHeader>
    </div>
  )
}

export default ProductosEtiquetasFiltros
import axios from 'axios'
import { useEffect, useState } from 'react'


// React-router
import { useNavigate } from 'react-router-dom'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../../redux/actions/toaster'


// Components
import Button from '../../../components/Button'
import CheckBoxInput from '../../../components/CheckboxInput'
import Switch from '../../../components/Switch'
import ProductosFiltros from '../../../components/AccordionFilter/Productos'
import FormInput from '../../../components/FormInput'
import CustomLoader from '../../../components/CustomLoader'
import Table from '../../../components/Table'
import { NoResults } from '../../../components/Tables/Misc/NoResults'
import MetodosPagoFiltros from '../../../components/AccordionFilter/MetodosPago'


// Layouts
import AccordionTable from '../../../layout/AccordionTable'


// Endpoints
import { BASE_URL, PATH_URL } from '../../../endpoints'


const CONSTANT = {
  title: 'Método de pago',
  asignacionTitle: 'Métodos de pago encontrados',
  redirectPath: 'metodos-pago',
  reduxSelector: 'metodosPago',
  urlCreate: BASE_URL + PATH_URL + '/metodos-pagos-productos',
  datatableInputsInitialState: {
    productos_encontrados: {
      nombre: '',
      sku: ''
    },
    productos_seleccionados: {
      filtro: '',
    }
  },
  resultados_productos: {
    encontrados: [],
    encontrados_filtrados: [],
    seleccionados: [],
    seleccionados_filtrados: []
  },
  resultados_metodos_pagos: {
    encontrados: [],
    seleccionado: null
  }
}


// FORMATEO DE RESPUESTAS
const productosFormatter = (arr) => {
  if (typeof arr !== 'object') return

  return arr?.map(el => ({
    ...el,
    estatus: 1,
    filter_params: `
    ${el?.nombre}
    ${el?.sku}
    `
  }))
}

const metodosPagoFormatter = (arr) => {
  if (typeof arr !== 'object') return []
  return arr?.map(el => ({ ...el, seleccionado: false }))
}


const MetodosPagoAsignacionMasiva = () => {
  const { resultados: reduxResultadosProductos } = useSelector(state => state.productos)
  const { resultados: reduxResultadosMetodosPago } = useSelector(state => state[CONSTANT.reduxSelector])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const [productos, setProductos] = useState(CONSTANT.resultados_productos)
  const [inputsDatatable, setInputsDatatables] = useState(CONSTANT.datatableInputsInitialState)
  const [metodosPago, setMetodosPago] = useState(CONSTANT.resultados_metodos_pagos)


  // EFECTOS QUE ACTUALIZAN Y RELLENAN LAS TABLAS DE RESULTADOS
  useEffect(() => {
    setProductos((productos) => ({
      ...productos,
      encontrados: productosFormatter(reduxResultadosProductos?.data ?? reduxResultadosProductos),
      encontrados_filtrados: productosFormatter(reduxResultadosProductos?.data ?? reduxResultadosProductos)
    }))
  }, [reduxResultadosProductos])

  useEffect(() => {
    setMetodosPago((metodosPago) => ({
      ...metodosPago,
      encontrados: metodosPagoFormatter(reduxResultadosMetodosPago?.data)
    }))
  }, [reduxResultadosMetodosPago])

  useEffect(() => {
    const encontrados_filtrados = productos.encontrados?.filter(({ nombre, sku }) => nombre.toLowerCase().includes(inputsDatatable.productos_encontrados?.nombre?.toLowerCase()) && sku.toLowerCase().includes(inputsDatatable.productos_encontrados?.sku?.toLowerCase()))
    setProductos((productos) => ({ ...productos, encontrados_filtrados }))
  }, [productos.encontrados, inputsDatatable.productos_encontrados])

  useEffect(() => {
    const seleccionados_filtrados = productos.seleccionados?.filter(({ filter_params }) => filter_params.toLowerCase().includes(inputsDatatable.productos_seleccionados?.filtro?.toLowerCase()))
    setProductos((productos) => ({ ...productos, seleccionados_filtrados }))
  }, [productos.seleccionados, inputsDatatable.productos_seleccionados])


  // FUNCION MANEJADORA QUE SELECCIONA EL ADICIONAL
  const handleAdicional = (e) => {
    const { name } = e.target?.dataset
    if (!name) return
    const [id] = name.split('_');
    const seleccionado = metodosPago.encontrados.find(el => String(el.id) === id)

    setMetodosPago({ ...metodosPago, seleccionado })
  }


  // FUNCION MANEJADORA PARA LA SELECCION DE UN PRODUCTO
  const handleProductoSeleccionado = (e) => {
    const { name } = e.target?.dataset ?? e.target
    const [id] = name.split('%-%')
    const isSelected = productos.seleccionados.find(obj => String(obj.id) === id)
    const selected = productos.encontrados.find(obj => String(obj.id) === id)

    setProductos({
      ...productos,
      seleccionados: isSelected
        ? productos.seleccionados.filter(obj => (obj.id !== isSelected.id))
        : [...productos.seleccionados, selected],
    })
  }


  const handleSeleccionarTodo = (remove = false) => {
    setProductos({
      ...productos,
      seleccionados: !remove ? productos.encontrados_filtrados : []
    })
  }


  // FUNCION MANEJADORA DE LOS INPUTS DE LA DATATABLE DE PRODUCTOS SELECCIONADOS
  const handleDatatableInputs = (e, isRemove = false) => {
    const { value, name } = e.target
    const [target, key] = name.split('-')

    setInputsDatatables({
      ...inputsDatatable,
      [target]: {
        ...inputsDatatable[target],
        [key]: !isRemove ? value : ''
      }
    })
  }


  const handleSend = async () => {
    const metodoPago = metodosPago.seleccionado?.id

    if (!metodoPago) {
      const toasterContent = {
        title: 'Error',
        text: `Falta seleccionar el método de pago`,
        icon: 'info'
      }
      return dispatch(fireToaster(toasterContent))
    }
    if (!productos.seleccionados.length) {
      const toasterContent = {
        title: 'No hay productos seleccionados',
        text: `Por favor, seleccione los productos para proseguir`,
        icon: 'info'
      }
      return dispatch(fireToaster(toasterContent))
    }


    let data = { metodo_pago_id: metodoPago }
    let asignaciones = []
    productos.seleccionados.forEach((el) => {
      let producto = {}

      Object.entries(el).forEach(([k, v]) => {
        if (['estatus'].includes(k)) producto = { ...producto, [k]: Number(v) }

        if (k === 'id') producto = { ...producto, producto_id: v }
      })

      asignaciones = [...asignaciones, producto]
    })

    data = { ...data, asignaciones }

    // return console.log(data);

    setLoader(true)

    await axios(CONSTANT.urlCreate,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data
      })
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `Registros creados con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        setLoader(false)
        return navigate(`/productos/${CONSTANT.redirectPath}`)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        return dispatch(fireToaster(toasterContent))
      })
    setLoader(false)
  }


  return (
    <div className="row">
      {loader && <CustomLoader blocking={'full'} />}
      <div className="col-12 mx-auto">

        {/* COMPONENTES DE FILTROS */}
        <div className='row'>
          <ProductosFiltros isAsignacionMasiva mobile/>

          <MetodosPagoFiltros isAsignacionMasiva />
        </div>

        {/* TABLAS */}
        <div className='row'>

          {/* PRODUCTOS ENCONTRADOS */}
          <AccordionTable
            title={`Productos encontrados (${productos.encontrados?.length ?? 0} encontrados / ${productos.encontrados_filtrados?.length ?? 0} filtrados)`}
            classes='col-12 col-md-6 mb-1'
          >
            <Table style={{ maxHeight: '1000px' }}>
              <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                <tr role="row">

                  <th>
                    <div className='d-flex'>
                      <div>
                        <CheckBoxInput
                          labelText='Todos'
                          value={productos.encontrados_filtrados?.length === productos.seleccionados?.length}
                          handleValue={() => handleSeleccionarTodo(productos.encontrados_filtrados?.length === productos.seleccionados?.length)}
                          classes='mt-1 me-1'
                        />
                      </div>

                      <FormInput
                        name='productos_encontrados-nombre'
                        value={inputsDatatable.productos_encontrados.nombre}
                        handleValue={handleDatatableInputs}
                        placeholder='Producto'
                        labelText=''
                        sizeDesk='col'
                        classes='text-capitalize sub-text-3 font-weight-normal'
                        margin='my-auto'
                      />
                    </div>
                  </th>

                  <th>
                    <div className='d-flex'>
                      <FormInput
                        name='productos_encontrados-sku'
                        value={inputsDatatable.productos_encontrados.sku}
                        handleValue={handleDatatableInputs}
                        placeholder='SKU'
                        labelText=''
                        sizeDesk='col'
                        classes='text-capitalize sub-text-3 font-weight-normal'
                        margin='my-auto'
                      />
                    </div>
                  </th>
                </tr>

              </thead>
              <tbody>
                {productos.encontrados_filtrados?.length

                  ? productos.encontrados_filtrados?.map(data => (
                    <tr
                      className={`odd data-selector ${productos.seleccionados.find(({ id }) => id === data?.id) ? 'selected' : ''}`}
                      key={`producto-${data?.id}-${data?.nombre}`}
                      onClick={handleProductoSeleccionado}
                      data-name={`${data?.id}%-%${data?.nombre}`}
                    >
                      <td data-name={`${data?.id}%-%${data?.nombre}`}>
                        <span className='d-flex flex-column' data-name={`${data?.id}%-%${data?.nombre}`}>
                          <span data-name={`${data?.id}%-%${data?.nombre}`}>{data?.neu_categoria?.nombre}</span>
                          <b data-name={`${data?.id}%-%${data?.nombre}`}>{data?.nombre.toUpperCase()}</b>
                        </span>
                      </td>
                      <td data-name={`${data?.id}%-%${data?.nombre}`}>
                        {data?.sku}
                      </td>
                    </tr>
                  ))

                  : <tr><td colSpan={2}><NoResults /></td></tr>
                }
              </tbody>
            </Table>
          </AccordionTable>

          {/* ADICIONALES ENCONTRADOS */}
          <AccordionTable
            title={`${CONSTANT.asignacionTitle} (${metodosPago.encontrados?.length ?? 0})`}
            classes='col-12 col-md-6 mb-1'
          >
            <Table style={{ maxHeight: '1000px' }}>
              <thead className="table-light">
                <tr role="row">
                  <th>Nombre</th>
                  <th>Descripción</th>
                  <th>Estatus</th>
                </tr>
              </thead>
              <tbody>
                {metodosPago.encontrados?.length

                  ? metodosPago.encontrados.map((data, i) => (
                    <tr
                      className={`odd multi-data data-selector ${data?.id === metodosPago.seleccionado?.id ? 'selected' : ''}`}
                      key={`pe-${data.id}-${data.i}`}
                      onClick={handleAdicional}
                      data-name={`${data.id}_${data.nombre ?? i}`}
                    >
                      <td data-name={`${data.id}_${data.nombre ?? i}`}>
                        <p data-name={`${data.id}_${data.nombre ?? i}`}>{data.nombre ?? 'N/A'}</p>
                      </td>

                      <td data-name={`${data.id}_${data.nombre ?? i}`}>
                        <span data-name={`${data.id}_${data.nombre ?? i}`}>{data?.descripcion ?? 'Sin descripción'}</span>
                      </td>

                      <td data-name={`${data.id}_${data.nombre ?? i}`}>
                        <Switch
                          data-name={`${data.id}_${data.nombre ?? i}`}
                          value={data.estatus ? true : false}
                          labelText={''}
                          withIcons
                          handleValue={() => { }}
                        />
                      </td>
                    </tr>
                  ))

                  : <tr><td colSpan={3}><NoResults /></td></tr>
                }
              </tbody>
            </Table>
          </AccordionTable>

          {/* PRODUCTOS SELECCIONADOS */}
          <AccordionTable
            title={`Productos seleccionados (${productos.seleccionados?.length ?? 0})`}
            classes='col-12 col-md-12'
          >
            <Table>
              <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                <tr role="row">
                  <th className='del pe-0'>
                    <button
                      onClick={() => handleSeleccionarTodo(true)}
                      className='mt-2'
                    >
                      Limpiar
                    </button>
                  </th>

                  <th className='col-md-5'>
                    <div className='d-flex'>
                      <FormInput
                        name='productos_seleccionados-filtro'
                        value={inputsDatatable.productos_seleccionados.filtro}
                        placeholder='Buscar...'
                        labelText='PRODUCTO - SKU'
                        handleValue={handleDatatableInputs}
                        sizeDesk='col-12'
                        classes='text-capitalize sub-text-3 font-weight-normal'
                        margin='my-auto'
                      />

                      <div className='mt-2 pt-75'>
                        <Button
                          color={'danger'}
                          icon='Trash'
                          className={'ms-50 my-auto p-25'}
                          name='a'
                          onClick={(e) => handleDatatableInputs({ target: { name: 'productos_seleccionados-filtro' } }, true)}
                        />
                      </div>
                    </div>
                  </th>

                  <th className='text-center pt-2 col' style={{ minWidth: '150px' }}>{CONSTANT.title}</th>

                </tr>
              </thead>
              <tbody>
                {productos.seleccionados_filtrados?.map((data, i) => (
                  <tr className="odd multi-data" key={`${i}-producto-selected-${data.id}-${data.nombre}`}>

                    <td>
                      <CheckBoxInput
                        labelText=''
                        value={data?.seleccionado}
                        handleValue={handleProductoSeleccionado}
                        name={`${data?.id}%-%${data?.nombre}`}
                      />
                    </td>

                    <td>
                      <b>{data?.nombre.toUpperCase() ?? 'N/A'}</b>
                      <p className='sub-text-3 text-lgray my-0'>{data?.neu_categoria?.nombre ?? 'N/A'}</p>
                      <b className='sub-text-3 text-lgray'>SKU: {data?.sku ?? 'N/A'}</b>
                      <p className='sub-text-3 text-lgray'>$ {data?.precio_final ?? 'N/A'} - Stock: {data?.stock_propio ?? 'N/A'}</p>
                    </td>

                    <td className='text-center'>
                      {metodosPago.seleccionado
                        ? <>
                          <b>{metodosPago.seleccionado?.nombre.toUpperCase() ?? 'N/A'}</b>
                          <p className='sub-text-3 text-lgray'>{metodosPago.seleccionado?.descripcion ?? 'N/A'}</p>
                        </>
                        : 'N/A'
                      }
                    </td>

                  </tr>
                ))}
              </tbody>
            </Table>
          </AccordionTable>
        </div>
      </div >

      <div className='col'>
        <div className='d-flex justify-content-center mt-2'>
          <Button text={`Asignar ${CONSTANT.title} a Productos`} icon='Check' onClick={() => handleSend()} />
        </div>
      </div>
    </div >
  )
}

export default MetodosPagoAsignacionMasiva
import {
   FILTER_ADD_COMUNA,
   FILTER_ADD_METODO_ENTREGA,
   FILTER_ADD_TIPO_DESTINO,
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   FILTER_ESTATUS,
   FILTER_REMOVE_COMUNA,
   FILTER_REMOVE_METODO_ENTREGA,
   FILTER_REMOVE_TIPO_DESTINO,
   RESULTADOS_PUNTOS_ENTREGA,
} from "../redux-types";


const initialState = {
   filtros: {
      tipo_destino_id: [],
      comuna_id: [],
      metodo_entrega_id: [],
      busqueda: '',
      publicado: ''
   },
   resultados: null
}


export const puntosEntregaReducer = (state = initialState, action) => {
   const isListedTipoDestino = state.filtros.tipo_destino_id.find(el => el === action.payload)
   const isListedComuna = state.filtros.comuna_id.find(el => el === action.payload)
   const isListedMetodoEntrega = state.filtros.metodo_entrega_id.find(el => el === action.payload)

   switch (action.type) {
      case FILTER_ADD_TIPO_DESTINO:
         return {
            ...state, filtros: {
               ...state.filtros,
               tipo_destino_id: isListedTipoDestino ? state.filtros.tipo_destino_id : [...state.filtros.tipo_destino_id, action.payload]
            }
         };

      case FILTER_REMOVE_TIPO_DESTINO:
         return {
            ...state, filtros: {
               ...state.filtros,
               tipo_destino_id: state.filtros.tipo_destino_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_COMUNA:
         return {
            ...state, filtros: {
               ...state.filtros,
               comuna_id: isListedComuna ? state.filtros.comuna_id : [...state.filtros.comuna_id, action.payload]
            }
         };

      case FILTER_REMOVE_COMUNA:
         return {
            ...state, filtros: {
               ...state.filtros,
               comuna_id: state.filtros.comuna_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_METODO_ENTREGA:
         return {
            ...state, filtros: {
               ...state.filtros,
               metodo_entrega_id: isListedMetodoEntrega ? state.filtros.metodo_entrega_id : [...state.filtros.metodo_entrega_id, action.payload]
            }
         };

      case FILTER_REMOVE_METODO_ENTREGA:
         return {
            ...state, filtros: {
               ...state.filtros,
               metodo_entrega_id: state.filtros.metodo_entrega_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda: action.payload
            }
         };

      case FILTER_ESTATUS:
         return {
            ...state, filtros: {
               ...state.filtros,
               publicado: action.payload
            }
         };

      case RESULTADOS_PUNTOS_ENTREGA:
         return {
            ...state,
            resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
}
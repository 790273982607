import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoginData } from '../redux/actions/auth';


// Components
import Icon from './Icon';
import NameAvatar from './NameAvatar';


const UserNavBarDropdown = () => {
   const [isDisplayed, setIsDisplayed] = useState(false);
   const dropDown = useRef();
   const userCard = useRef();
   const { user } = useSelector(state => state.auth)
   const dispatch = useDispatch()


   const handleLogout = () => {
      if (localStorage.getItem('x-token')) localStorage.removeItem('x-token')
      dispatch(setLoginData({}))
   }


   const handleDropdown = (e) => {
      e.preventDefault();
      setIsDisplayed(!isDisplayed);
   };


   useEffect(() => {
      if (isDisplayed) dropDown.current.classList.add('show');
      else dropDown.current.classList.remove('show');
   }, [isDisplayed]);


   return (
      <li className={
         isDisplayed
            ? "nav-item dropdown dropdown-user show"
            : "nav-item dropdown dropdown-user"
      }
         ref={userCard}
      >
         <a
            className="nav-link dropdown-toggle dropdown-user-link"
            id="dropdown-user"
            href='/#'
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={handleDropdown}
         >
            <div className="user-nav d-sm-flex d-none">
               <span className="user-name fw-bolder">{user?.nombre}</span>

               <div className='d-flex gap-50'>
                  {user?.roles?.length
                     ? <span className="user-status">{user?.roles?.[0].name}</span>
                     : <span className="user-status">Sin roles</span>
                  }
               </div>
            </div>
            <NameAvatar name={user?.nombre} />
         </a>

         {/* data-bs-popper podría causar problemas con el dropdown (la posicion) */}
         <div
            ref={dropDown}
            className="dropdown-menu dropdown-menu-end"
            aria-labelledby="dropdown-user"
            data-bs-popper="none"
         >
            <a className="dropdown-item" href={`/usuarios/detalle/${user.id}`}>
               <Icon icon='List' />
               <i className="me-50"></i>
               Detalles
            </a>

            <button className="dropdown-item w-100" onClick={() => handleLogout()}>
               <Icon icon='Power' />
               <i className="me-50"></i>
               Salir
            </button>
         </div>
      </li >
   );
}



export default UserNavBarDropdown;
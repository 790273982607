import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import NotasVentasFiltros from '../../../components/AccordionFilter/NotasVentas'
import PlanificacionTable from '../../../components/Tables/PlanificacionTable'
import DropdownWrapper from '../../../components/Dropdown/Wrapper'
import DropdownMenuLink from '../../../components/Dropdown/Link'
import axios from 'axios'
import { fireToaster } from '../../../redux/actions/toaster'
import { useDispatch } from 'react-redux'
import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import FileUploaderB from "../../../components/FileUploader_B";
import { getAll } from '../../../endpoints/getAll'
import { BASE_URL, PATH_URL } from '../../../endpoints'


const CONSTANT = {
  planificacion: {
    title: 'Planificación',
    path: 'erp/planificacion',
    render: <>
      <NotasVentasFiltros isPlanificacion />
      <PlanificacionTable />
    </>
  },

  planificacion_masiva_btn: 'Planificacion masiva',

  urlPlanificacionMasiva: BASE_URL+ PATH_URL +  `/erp/notas-ventas/importar/planificacion-masiva`,

  fetchTarget: {
    planificacion_masiva: 'plani masiva',
  },

  modalState: { display: false, target: null }
}


const Listado = () => {
  const { pathname } = useLocation()
  const path = pathname.split('/')[2]
  const dispatch = useDispatch()
  const [modal, setModal] = useState(CONSTANT.modalState)
  const [excelFile, setExcelFile] = useState([])


  const handleFetch = async (objData, target) => {
    let config
    let url

    if (target === CONSTANT.fetchTarget.planificacion_masiva) {
      let modalData = new FormData()
      excelFile.length && modalData.append('excel', excelFile[0]?.file)

      url = CONSTANT.urlPlanificacionMasiva
      config = {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: modalData
      }
    }

    config = {
      ...config,
      headers: {
        ...config?.headers,
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    }

    await axios(url, config)
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: registro actualizado con éxito`,
          icon: 'success'
        }

        dispatch(fireToaster(toasterContent))
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
           Operación fallida
           (${err.response.status} - ${err.response.statusText})
         `,
          html: `
           <b>Detalle: </b>
           ${detalles.map(error => `<br /><i>-${error}</i>`)}
         `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
      })

      .finally(() => {
        setModal(CONSTANT.modalState)
      })
  }

  
  return (
    <div>
      <Modal display={modal.display} handleValue={() => setModal({ ...modal, display: false })}>
        {modal.target === CONSTANT.fetchTarget.planificacion_masiva &&
          <div className='m-2'>
            <h3 className='p-50 rounded-2 m-0'>Planificación masiva</h3>
            <hr className='mt-0 mb-1' />

            <div className='col-12 mb-50'>
              <FileUploaderB value={excelFile} handleValue={setExcelFile} />
            </div>

            <div className='d-flex'>
              <Button text='Guardar' className='mx-auto' color='success' onClick={() => handleFetch({}, CONSTANT.fetchTarget.planificacion_masiva)} />
            </div>
          </div>
        }
      </Modal>

      <h3 className="mb-1 d-flex justify-content-between">
        <p><b>{CONSTANT[path?.length ? path : 'listado'].title}</b> - Listado</p>
        <DropdownWrapper text="Acciones" classes="ms-auto">
          <DropdownMenuLink text={CONSTANT.planificacion_masiva_btn} onClick={() => setModal({ display: true, target: CONSTANT.fetchTarget.planificacion_masiva })} icon="Check" />
        </DropdownWrapper>
      </h3>


      {CONSTANT[path?.length ? path : 'listado'].render}
    </div>
  )
}

export default Listado
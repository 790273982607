import { Navigate, Route, Routes } from "react-router-dom";
import Listado from "../../views/Unidades/Listado";
import Create from "../../views/Unidades/Create";
import Update from "../../views/Unidades/Update";
import Detalle from "../../views/Unidades/Detalle";
import { useSelector } from "react-redux";

const UnidadesRouter = () => {
  const { user } = useSelector(state => state.auth)

  return (
    <Routes>
      <Route path="/" element={<Listado />} />

      {user.permisos['unidades.crear'] &&
        <Route path="/create" element={<Create />} />
      }

      {user.permisos['unidades.editar'] &&
        <Route path="/update/:id" element={<Update />} />
      }

      {user.permisos['unidades.detalle'] &&
        <Route path="/detalle/:id" element={<Detalle />} />
      }

      <Route path='*' element={<Navigate to='/home' replace />} />
    </Routes>
  );
};

export default UnidadesRouter;

import React from 'react'

const AccordionDetailData = ({ dataKey, value, last = false, classes = '', select = false }) => {
   return (
      <>
         <div className='d-flex py-25 w-100 justify-content-between'>
            <b className='col-6 m-0 w-50 text-center border-end my-auto'>{dataKey}</b>
            {select
               ? <div className='d-flex flex-grow-1 px-2'>
                  {value}
               </div>
               :
               <div className={`m-0 w-fit lista-detalle mx-auto h-fit px-2 ${classes}`}>{value}</div>
            }
         </div>
         {!last && <hr className='my-50' />}
      </>
   )
}

export default AccordionDetailData
import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   FILTER_TABLA,
   RESULTADOS_COMPONENTES,
} from "../redux-types";

const initialState = {
   filtros: {
      busqueda: '',
      tabla: ''
   },
   resultados: null
}


export const componentesReducer = (state = initialState, action) => {
   switch (action.type) {
      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda: action.payload
            }
         };

      case FILTER_TABLA:
         return {
            ...state, filtros: {
               ...state.filtros,
               tabla: action.payload
            }
         };

      case RESULTADOS_COMPONENTES:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
import axios from 'axios'
import { useEffect, useState } from 'react'


// Components
import Button from '../../../components/Button'
import CheckBoxInput from '../../../components/CheckboxInput'
import FormInput from '../../../components/FormInput'
import CustomLoader from '../../../components/CustomLoader'
import Table from '../../../components/Table'
import { NoResults } from '../../../components/Tables/Misc/NoResults'


// Layouts
import AccordionTable from '../../../layout/AccordionTable'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../../redux/actions/toaster'
import { useNavigate } from 'react-router-dom'


// Endpoints
import { BASE_URL, PATH_URL } from '../../../endpoints'
// import Switch from '../../../components/Switch'
// import Tooltip from '../../../components/Buttons/Tooltip'
import ProductosOfertasFiltros from '../../../components/AccordionFilter/ProductosOfertas'


const acceptedDateTimeFormat = (datetime) => {
  if (typeof datetime !== 'string') return console.warn('datetime, invalid format, check: ' + datetime)

  const [date, time] = datetime.split(' ')
  const [hh, mm] = time.split(':')
  return `${date}T${hh}:${mm}`
}


const productosEncontradosFormatter = (arr) => {
  if (typeof arr !== 'object') return

  return arr?.map(el => {

    return el = {
      ...el,
      id: el?.oferta_producto_id,
      nombre: el?.producto ?? 'Desconocido',
      oferta_inicio: acceptedDateTimeFormat(el?.inicio_oferta_producto) ?? null,
      oferta_final: acceptedDateTimeFormat(el?.final_oferta_producto) ?? null,
      cantidad_critica: el?.cantidad_critica ?? 0,
      cantidad_limite: el?.cantidad_limite ?? 0,
      filter_params: `
      ${el?.producto}
      ${el?.sku}
      `
    }
  })
}

const CONSTANT = {
  title: 'Ofertas',
  asignacionTitle: 'Ofertas encontradas',
  redirectPath: 'ofertas',
  reduxSelector: 'ofertas',
  urlUpdate: BASE_URL + PATH_URL + '/ofertas-productos/edicion-masiva',

  datatableInputsInitialState: {
    productos_encontrados: {
      nombre: '',
      sku: '',
      oferta: ''
    },
    productos_seleccionados: {
      filtro: '',
      estatus: '',
      oferta_inicio: '',
      oferta_final: '',
      cantidad_critica: '',
      cantidad_limite: '',
    }
  },
  resultados_productos: {
    encontrados: [],
    encontrados_filtrados: [],
    seleccionados: [],
    seleccionados_filtrados: []
  }
}


const OfertasEdicionMasiva = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const { resultados: response } = useSelector(state => state.productosOfertas)
  const [productos, setProductos] = useState(CONSTANT.resultados_productos)
  const [inputsDatatable, setInputsDatatables] = useState(CONSTANT.datatableInputsInitialState)


  // EFECTO QUE CARGA LAS RESPUESTAS Y LAS FORMATEA
  useEffect(() => {
    setProductos((productos) => ({
      ...productos,
      encontrados: productosEncontradosFormatter(response?.ofertas_productos ?? []), // PARA RICARDO: ARREGLAR ESTA RESPUESTA
      encontrados_filtrados: productosEncontradosFormatter(response?.ofertas_productos ?? [])
    }))
  }, [response])


  // EFECTOS QUE ACTUALIZAN Y RELLENAN LAS TABLAS DE RESULTADOS
  useEffect(() => {
    const encontrados_filtrados = productos.encontrados?.filter(({ nombre, sku, oferta }) => (
      nombre.toLowerCase().includes(inputsDatatable.productos_encontrados?.nombre?.toLowerCase()) &&
      sku.toLowerCase().includes(inputsDatatable.productos_encontrados?.sku?.toLowerCase()) &&
      oferta.toLowerCase().includes(inputsDatatable.productos_encontrados?.oferta?.toLowerCase())
    ))
    setProductos((productos) => ({ ...productos, encontrados_filtrados }))
  }, [productos.encontrados, inputsDatatable.productos_encontrados])

  useEffect(() => {
    const seleccionados_filtrados = productos.seleccionados?.filter(({ filter_params }) => filter_params.toLowerCase().includes(inputsDatatable.productos_seleccionados?.filtro?.toLowerCase()))
    setProductos((productos) => ({ ...productos, seleccionados_filtrados }))
  }, [productos.seleccionados, inputsDatatable.productos_seleccionados])


  // FUNCION MANEJADORA PARA LA SELECCION DE UN PRODUCTO
  const handleProductoSeleccionado = (e) => {
    const { name } = e.target?.dataset ?? e.target
    const id = name.split('%-%')[1]
    const isSelected = productos.seleccionados.find(obj => String(obj.id) === id)
    const selected = productos.encontrados.find(obj => String(obj.id) === id)

    setProductos({
      ...productos,
      seleccionados: isSelected
        ? productos.seleccionados.filter(obj => (obj.id !== isSelected.id))
        : [...productos.seleccionados, selected],
    })
  }

  const handleSeleccionarTodo = (remove = false) => {
    setProductos({
      ...productos,
      seleccionados: !remove ? productos.encontrados_filtrados : []
    })
  }


  // FUNCION MANEJADORA DE LOS INPUTS DE LA DATATABLE DE PRODUCTOS SELECCIONADOS
  const handleDatatableInputs = (e, isRemove = false) => {
    const { value, name } = e.target
    const [target, key] = name.split('-')

    if (key === 'estatus') {
      if (!['0', '1', ''].includes(String(value))) return
    }

    setInputsDatatables({
      ...inputsDatatable,
      [target]: {
        ...inputsDatatable[target],
        [key]: !isRemove ? value : ''
      }
    })
  }


  // FUNCION MANEJADORA QUE SETEA LOS VALORES DE LOS INPUTS EN TODOS LOS PRODUCTOS SELECCIONADOS
  const handleSetValueProductosSeleccionados = (target) => {
    setProductos({
      ...productos,
      seleccionados: productos.seleccionados.map(el => {
        const isListed = productos.seleccionados_filtrados.find(filtrado => el.id === filtrado.id)
        const newValue = inputsDatatable.productos_seleccionados[target]

        return { ...el, [target]: isListed ? newValue : el?.[target] }
      })
    })
  }


  // FUNCION MANEJADORA DE INPUTS DE UN PRODUCTO INDIVIDUAL EN LA TABLA DE PRODUCTOS SELECCIONADOS
  const handleInputProductoSeleccionado = (e, producto) => {
    const { value, name } = e.target
    const { id } = producto

    setProductos({
      ...productos,
      seleccionados: productos.seleccionados.map(el => el.id === id ? { ...el, [name]: value } : el)
    })
  }


  const handleSend = async () => {
    let ediciones, config
    // const isInvalidPrecio = productos.seleccionados.find(({ precio_instalacion }) => !precio_instalacion || Number(precio_instalacion) < 1)

    if (!productos.seleccionados.length) {
      const toasterContent = {
        title: 'No hay productos seleccionados',
        text: `Por favor, seleccione los productos para proseguir`,
        icon: 'info'
      }
      return dispatch(fireToaster(toasterContent))
    }
    // if (isInvalidPrecio) {
    //   const toasterContent = {
    //     title: `Error en producto: <br> ${isInvalidPrecio?.nombre.toUpperCase() ?? 'desconocido'}`,
    //     text: `El precio_instalacion seleccionado es inválido`,
    //     icon: 'info'
    //   }
    //   return dispatch(fireToaster(toasterContent))
    // }

    ediciones = productos.seleccionados.map(OP => {
      let newOP = {}

      Object.entries(OP).forEach(([k, v]) => {
        switch (k) {
          case 'oferta_producto_id':
            newOP = {
              ...newOP,
              id: v
            }
            break;

          case 'cantidad_critica':
            newOP = {
              ...newOP,
              [k]: Number(v)
            }
            break;

          case 'cantidad_limite':
            newOP = {
              ...newOP,
              [k]: Number(v)
            }
            break;

          case 'oferta_inicio':
            console.log(v);
            newOP = {
              ...newOP,
              fecha_hora_inicio: `${v.split('T')[0]} ${v.split('T')[1]}:00`
            }
            break;

          case 'oferta_final':
            console.log(v);
            newOP = {
              ...newOP,
              fecha_hora_final: `${v.split('T')[0]} ${v.split('T')[1]}:00`
            }
            break;

          default:
            break;
        }

      })
      return newOP
    })

    config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'PUT',
      data: { ediciones }
    }

    setLoader(true)

    await axios(CONSTANT.urlUpdate, config)
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `Puntos-Entrega-Productos editados con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        setLoader(false)
        return navigate(`/productos/${CONSTANT.redirectPath}`)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
             Operación fallida
             (${err.response.status} - ${err.response.statusText})
           `,
          html: `
             <b>Detalle: </b>
             ${detalles.map(error => `<br /><i>-${error}</i>`)}
           `,
          icon: 'error'
        }
        return dispatch(fireToaster(toasterContent))
      })
    setLoader(false)
  }


  return (
    <div className="row">
      {loader && <CustomLoader blocking={'full'} />}
      <div className="col-12 mx-auto">

        {/* COMPONENTE FILTROS - PRODUCTOS */}
        <ProductosOfertasFiltros />

        <div className='row'>
          {/* PRODUCTOS ENCONTRADOS */}
          <AccordionTable
            title={`Productos encontrados (${productos.encontrados?.length ?? 0} encontrados / ${productos.encontrados_filtrados?.length ?? 0} filtrados)`}
            classes='col-12 col-md-12 mb-1'
          >
            <Table style={{ maxHeight: '1000px' }}>
              <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                <tr role="row">

                  <th>
                    <div className='d-flex'>
                      <div>
                        <CheckBoxInput
                          labelText='Todos'
                          value={productos.encontrados_filtrados.length === productos.seleccionados.length}
                          handleValue={() => handleSeleccionarTodo(productos.encontrados_filtrados.length === productos.seleccionados.length)}
                          classes='mt-1 me-1'
                        />
                      </div>

                      <FormInput
                        name='productos_encontrados-nombre'
                        value={inputsDatatable.productos_encontrados.nombre}
                        handleValue={handleDatatableInputs}
                        placeholder='Producto'
                        labelText=''
                        sizeDesk='col'
                        classes='text-capitalize sub-text-3 font-weight-normal'
                        margin='my-auto'
                      />
                    </div>
                  </th>

                  <th>
                    <div className='d-flex'>
                      <FormInput
                        name='productos_encontrados-sku'
                        value={inputsDatatable.productos_encontrados.sku}
                        handleValue={handleDatatableInputs}
                        placeholder='SKU'
                        labelText=''
                        sizeDesk='col'
                        classes='text-capitalize sub-text-3 font-weight-normal'
                        margin='my-auto'
                      />
                    </div>
                  </th>

                  <th>
                    <div className='d-flex'>
                      <FormInput
                        name='productos_encontrados-oferta'
                        value={inputsDatatable.productos_encontrados.oferta}
                        handleValue={handleDatatableInputs}
                        placeholder='Oferta'
                        labelText=''
                        sizeDesk='col'
                        classes='text-capitalize sub-text-3 font-weight-normal'
                        margin='my-auto'
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {productos.encontrados_filtrados?.length
                  ? productos.encontrados_filtrados?.map(data => (
                    <tr
                      className={`odd data-selector ${productos.seleccionados.find(({ oferta_producto_id }) => oferta_producto_id === data?.id) ? 'selected' : ''}`}
                      key={`producto-${data?.oferta_producto_id}-${data?.nombre}`}
                      onClick={handleProductoSeleccionado}
                      data-name={`${data?.producto_id}%-%${data?.oferta_producto_id}%-%${data?.nombre}`}
                    >
                      <td data-name={`${data?.producto_id}%-%${data?.oferta_producto_id}%-%${data?.nombre}`}>
                        <span className='d-flex flex-column' data-name={`${data?.producto_id}%-%${data?.oferta_producto_id}%-%${data?.nombre}`}>
                          <span data-name={`${data?.producto_id}%-%${data?.oferta_producto_id}%-%${data?.nombre}`}>{data?.categoria}</span>
                          <b data-name={`${data?.producto_id}%-%${data?.oferta_producto_id}%-%${data?.nombre}`}>{data?.nombre.toUpperCase()}</b>
                        </span>
                      </td>

                      <td data-name={`${data?.producto_id}%-%${data?.oferta_producto_id}%-%${data?.nombre}`}>
                        {data?.sku}
                      </td>

                      <td data-name={`${data?.producto_id}%-%${data?.oferta_producto_id}%-%${data?.nombre}`}>
                        <div data-name={`${data?.producto_id}%-%${data?.oferta_producto_id}%-%${data?.nombre}`} className='d-flex flex-column'>
                          <b data-name={`${data?.producto_id}%-%${data?.oferta_producto_id}%-%${data?.nombre}`}>{data?.oferta.toUpperCase()}</b>
                          <span data-name={`${data?.producto_id}%-%${data?.oferta_producto_id}%-%${data?.nombre}`}>{data?.oferta}</span>
                          <span data-name={`${data?.producto_id}%-%${data?.oferta_producto_id}%-%${data?.nombre}`}>{data?.inicio_oferta_producto?.split(' ')[0] ?? '?'} / {data?.final_oferta_producto?.split(' ')[0] ?? '?'}</span>
                        </div>
                      </td>
                    </tr>
                  ))

                  : <tr><td colSpan={3}><NoResults /></td></tr>
                }
              </tbody>
            </Table>

          </AccordionTable>

          {/* PRODUCTOS SELECCIONADOS */}
          <AccordionTable
            title={`Productos seleccionados (${productos.seleccionados?.length ?? 0})`}
            classes='col-12 col-md-12'
          >
            <Table>
              <thead className="table-light ">
                <tr role="row">

                  <th className='del pe-0'>
                    <button
                      onClick={() => handleSeleccionarTodo(true)}
                      className='mt-2'
                    >
                      Limpiar
                    </button>
                  </th>

                  <th className='col-md-5'>
                    <div className='d-flex'>
                      <FormInput
                        name='productos_seleccionados-filtro'
                        value={inputsDatatable.productos_seleccionados.filtro}
                        placeholder='Buscar...'
                        labelText='PRODUCTO - SKU'
                        handleValue={handleDatatableInputs}
                        sizeDesk='col-12'
                        classes='text-capitalize sub-text-3 font-weight-normal'
                        margin='my-auto'
                      />

                      <div className='mt-2 pt-75'>
                        <Button
                          color={'danger'}
                          icon='Trash'
                          className={'ms-50 my-auto p-25'}
                          name='a'
                          onClick={(e) => handleDatatableInputs({ target: { name: 'productos_seleccionados-filtro' } }, true)}
                        />
                      </div>
                    </div>
                  </th>

                  <th className='text-center col' style={{ minWidth: '150px' }}>
                    {CONSTANT.title}
                    {/* <div className='d-flex'>
                      <FormInput
                        labelText={CONSTANT.title}
                        name='productos_seleccionados-estatus'
                        placeholder='Estatus'
                        sizeDesk='col'
                        margin='mb-0 me-25'
                        handleValue={handleDatatableInputs}
                        value={inputsDatatable.productos_seleccionados.estatus}
                      />

                      <div className='mt-1 pt-75'>
                        <Tooltip classes='mb-25' text={`Para activar o desactivar el estatus usar solo los valores '1' o '0' respectivamente`} />

                        <Button
                          icon='Plus'
                          className={'m-auto p-25'}
                          onClick={() => handleSetValueProductosSeleccionados('estatus')}
                        />
                      </div>
                    </div> */}
                  </th>

                  <th className='col-2'>
                    <div className='d-flex'>
                      <FormInput
                        type='datetime-local'
                        name='productos_seleccionados-oferta_inicio'
                        labelText='Oferta inicio'
                        sizeDesk='col'
                        margin='mb-0 me-25'
                        handleValue={handleDatatableInputs}
                        value={inputsDatatable.productos_seleccionados.oferta_inicio}
                      />

                      <div className='mt-2 pt-75'>
                        {/* <Tooltip classes='mb-25' text={`Para desactivar de manera masiva escribir alguna de estas palabras clave: 'nulo', 'null', 'vacio', 'nada', 'inactivo', 'no aplica'`} /> */}

                        <Button
                          icon='Plus'
                          className={'m-auto p-25'}
                          onClick={() => handleSetValueProductosSeleccionados('oferta_inicio')}
                        />
                      </div>
                    </div>
                  </th>

                  <th className='col-2'>
                    <div className='d-flex'>
                      <FormInput
                        type='datetime-local'
                        name='productos_seleccionados-oferta_final'
                        labelText='Oferta final'
                        sizeDesk='col'
                        margin='mb-0 me-25'
                        handleValue={handleDatatableInputs}
                        value={inputsDatatable.productos_seleccionados.oferta_final}
                      />

                      <div className='mt-2 pt-75'>
                        {/* <Tooltip classes='mb-25' text={`Para desactivar de manera masiva escribir alguna de estas palabras clave: 'nulo', 'null', 'vacio', 'nada', 'inactivo', 'no aplica'`} /> */}

                        <Button
                          icon='Plus'
                          className={'m-auto p-25'}
                          onClick={() => handleSetValueProductosSeleccionados('oferta_final')}
                        />
                      </div>
                    </div>
                  </th>

                  <th className='col-2'>
                    <div className='d-flex'>
                      <FormInput
                        name='productos_seleccionados-cantidad_critica'
                        labelText='Crítica'
                        placeholder='Cantidad'
                        sizeDesk='col'
                        margin='mb-0 me-25'
                        handleValue={handleDatatableInputs}
                        value={inputsDatatable.productos_seleccionados.cantidad_critica}
                      />

                      <div className='mt-2 pt-75'>
                        {/* <Tooltip classes='mb-25' text={`Para desactivar de manera masiva escribir alguna de estas palabras clave: 'nulo', 'null', 'vacio', 'nada', 'inactivo', 'no aplica'`} /> */}

                        <Button
                          icon='Plus'
                          className={'m-auto p-25'}
                          onClick={() => handleSetValueProductosSeleccionados('cantidad_critica')}
                        />
                      </div>
                    </div>
                  </th>

                  <th className='col-2'>
                    <div className='d-flex'>
                      <FormInput
                        name='productos_seleccionados-cantidad_limite'
                        labelText='Límite'
                        placeholder='Cantidad'
                        sizeDesk='col'
                        margin='mb-0 me-25'
                        handleValue={handleDatatableInputs}
                        value={inputsDatatable.productos_seleccionados.cantidad_limite}
                      />

                      <div className='mt-2 pt-75'>
                        {/* <Tooltip classes='mb-25' text={`Para desactivar de manera masiva escribir alguna de estas palabras clave: 'nulo', 'null', 'vacio', 'nada', 'inactivo', 'no aplica'`} /> */}

                        <Button
                          icon='Plus'
                          className={'m-auto p-25'}
                          onClick={() => handleSetValueProductosSeleccionados('cantidad_limite')}
                        />
                      </div>
                    </div>
                  </th>

                </tr>
              </thead>
              <tbody>
                {productos.seleccionados_filtrados?.map((data, i) => (
                  <tr className="odd multi-data" key={`${i}-producto-selected-${data.punto_entrega_producto_id}-${data?.nombre}`}>

                    <td>
                      <CheckBoxInput
                        labelText=''
                        value={data?.seleccionado}
                        handleValue={handleProductoSeleccionado}
                        name={`${data?.punto_entrega_producto_id}%-%${data?.nombre}`}
                      />
                    </td>

                    <td>
                      <div className='d-flex flex-column'>
                        <b>{data?.nombre.toUpperCase() ?? 'N/A'}</b>
                        <span className='sub-text-3 text-lgray'>{data.categoria ?? 'N/A'}</span>
                        <span className='sub-text-3 text-lgray'>$ {data.precio_final ?? 'N/A'} - Stock: {data.stock_propio ?? 'N/A'}</span>
                        <span className='sub-text-3 text-lgray'>SKU: {data.sku ?? 'N/A'}</span>
                      </div>
                    </td>

                    <td>
                      <div className='d-flex flex-column'>
                        <b>{data?.oferta.toUpperCase() ?? 'N/A'}</b>
                        <span className='sub-text-3 text-lgray'>{data?.oferta ?? 'N/A'}</span>
                        <span className='sub-text-3 text-lgray'>{data?.inicio_oferta.split(' ')[0] ?? 'N/A'} / {data?.final_oferta.split(' ')[0] ?? 'N/A'}</span>
                      </div>
                    </td>

                    <td className='px-50'>
                      <div className='d-flex flex-column'>
                        {/* <Switch
                          value={data.instalacion_apply}
                          name='instalacion_apply'
                          labelText='Instalación'
                          isList
                          handleValue={(e) => handleInputProductoSeleccionado(e, data)}
                        /> */}

                        <FormInput
                          type='datetime-local'
                          name='oferta_inicio'
                          value={data?.oferta_inicio ?? undefined}
                          // isDisabled={!data.instalacion_apply}
                          placeholder='0'
                          margin='mb-0'
                          handleValue={(e) => handleInputProductoSeleccionado(e, data)}
                        />
                      </div>
                    </td>

                    <td className='px-50'>
                      <div className='d-flex flex-column'>
                        {/* <Switch
                          value={data.recargo_apply}
                          name='recargo_apply'
                          labelText='Recargo'
                          isList
                          handleValue={(e) => handleInputProductoSeleccionado(e, data)}
                        /> */}

                        <FormInput
                          type='datetime-local'
                          name='oferta_final'
                          value={data?.oferta_final ?? undefined}
                          // isDisabled={!data.recargo_apply}
                          placeholder='0'
                          margin='mb-0'
                          handleValue={(e) => handleInputProductoSeleccionado(e, data)}
                        />
                      </div>
                    </td>

                    <td className='px-50'>
                      <div className='d-flex flex-column'>
                        {/* <Switch
                          value={data.instalacion_apply}
                          name='instalacion_apply'
                          labelText='Instalación'
                          isList
                          handleValue={(e) => handleInputProductoSeleccionado(e, data)}
                        /> */}

                        <FormInput
                          type='number'
                          name='cantidad_critica'
                          value={Number(data?.cantidad_critica) ?? undefined}
                          // isDisabled={!data.instalacion_apply}
                          placeholder='0'
                          margin='mb-0'
                          handleValue={(e) => handleInputProductoSeleccionado(e, data)}
                        />
                      </div>
                    </td>

                    <td className='px-50'>
                      <div className='d-flex flex-column'>
                        {/* <Switch
                          value={data.instalacion_apply}
                          name='instalacion_apply'
                          labelText='Instalación'
                          isList
                          handleValue={(e) => handleInputProductoSeleccionado(e, data)}
                        /> */}

                        <FormInput
                          type='number'
                          name='cantidad_limite'
                          value={Number(data?.cantidad_limite) ?? undefined}
                          // isDisabled={!data.instalacion_apply}
                          placeholder='0'
                          margin='mb-0'
                          handleValue={(e) => handleInputProductoSeleccionado(e, data)}
                        />
                      </div>
                    </td>

                  </tr>
                ))}
              </tbody>
            </Table>
          </AccordionTable>
        </div>
      </div >
      <div className='col'>
        <div className='d-flex justify-content-center mt-2'>
          <Button text={`Editar ${CONSTANT.title} a productos`} onClick={() => handleSend()} />
        </div>
      </div>
    </div >
  )
}

export default OfertasEdicionMasiva
import { Navigate, Route, Routes } from "react-router-dom"

import Create from "../views/Eventos/Create"
import Listado from "../views/Eventos/Listado"
import Detalle from "../views/Eventos/Detalle"
import Update from "../views/Eventos/Update"
import { useSelector } from "react-redux"


const EventosRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['eventos.crear'] &&
            <Route path='/create' element={<Create />} />
         }

         {user.permisos['eventos.editar'] &&
            <Route path='/update/:id' element={<Update />} />
         }

         {user.permisos['eventos.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}

export default EventosRouter;
import PropTypes from 'prop-types'


const CustomForm = ({title, titleColor, children, size, sizeTablet, sizeDesk}) => {
  return (
    <form className={`bg-white bg-gradient rounded-3 shadow-sm p-3 my-2 ${size} ${sizeTablet} ${sizeDesk}`}>
      <h4 className={`form-title ${titleColor}`}>{title}</h4>
      {children}
    </form>
  )
}

CustomForm.propTypes = {
   title : PropTypes.string.isRequired,
   titleColor : PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', '']),
   size: PropTypes.string,
   sizeDesk: PropTypes.string,
   sizeTablet: PropTypes.string
}

CustomForm.defaultProps = {
   title: 'Custom Form Component',
   titleColor: '',
   size: '', 
   sizeDesk: '',
   sizeTablet: ''
}

export default CustomForm
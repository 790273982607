import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';



// Components
import Icon from "../Icon";



const NestedLinkButton = ({ text, path, buttons, icon }) => {

   const [isOpen, setIsOpen] = useState(false)
   const [nestedButtons] = useState(buttons)

   const currentPath = useLocation().pathname;

   useEffect(() => {
      if (currentPath.includes(path)) setIsOpen(true)
      else setIsOpen(false);
   }, [currentPath, path])


   const handleOpen = (e) => {
      e.preventDefault()
      setIsOpen(!isOpen)
   }

   return (
      <li
         className={
            isOpen
               ? "nav-item has-sub open"
               : "nav-item has-sub"
         }
      >
         <a className="d-flex align-items-center" onClick={handleOpen} href="/#">
            <Icon icon={icon} />
            <span className="menu-title text-truncate" data-i18n="Page Layouts">{text}</span>
            <span className="badge badge-light-dark rounded-pill ms-auto me-1">{nestedButtons.length > 0 && nestedButtons.length}</span>
         </a>

         <ul className="menu-content" >
            {nestedButtons.length > 0 &&
               nestedButtons.map(({ btnText, icon, url }) => {
                  return (
                     <li className={"align-items-center rounded mb-25 " + (currentPath === `${path}${url}` && "active")} key={`${btnText}-nested-btn`} style={{ backgroundColor: '#e1f5fc' }}>
                        <Link className="d-flex align-items-center" to={`${path}${url}`}>
                           <Icon icon={icon} />

                           <span className="menu-item text-truncate" data-i18n="Collapsed Menu">{btnText}</span>
                        </Link>
                     </li>
                  )
               })
            }
         </ul>
      </li>
   )
}

NestedLinkButton.propTypes = {
   text: PropTypes.string.isRequired,
   buttons: PropTypes.arrayOf(
      PropTypes.shape(
         {
            btnText: PropTypes.string.isRequired
         }
      ).isRequired
   ).isRequired,
   icon: PropTypes.string.isRequired
}

NestedLinkButton.defaultProps = {
   btnText: "Nested Link",
   icon: 'circle',
}

export default NestedLinkButton;
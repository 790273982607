import {
   FILTER_ADD_CATEGORIA,
   FILTER_ADD_ELEMENTO,
   FILTER_REMOVE_CATEGORIA,
   FILTER_REMOVE_ELEMENTO,
   FILTER_NOMBRE,
   FILTER_CRITERIO,
   FILTER_BUSQUEDA_CRITERIO,
   FILTER_BUSQUEDA,
   FILTER_PRECIO_DESDE,
   FILTER_PRECIO_HASTA,
   FILTER_ADD_ETIQUETA,
   FILTER_REMOVE_ETIQUETA,
   RESULTADOS_PRODUCTOS_ETIQUETAS,
   FILTER_CLEAR_PRODUCTOS_ETIQUETAS,
   FILTER_SKUS_PRODUCTO
} from "../redux-types";


const initialState = {
   filtros: {
      parametros_busqueda: '',
      categorias_id: [],
      etiquetas_ids: [],
      elemento: [],
      nombre: '',
      criterio: '',
      busqueda_criterio: '',
      precio_desde: undefined,
      precio_hasta: undefined,
      skus: ''
   },
   resultados: null
}


export const productosEtiquetasReducer = (state = initialState, action) => {
   const isListedCategoria = state.filtros.categorias_id.find(el => el === action.payload)
   const isListedEtiqueta = state.filtros.etiquetas_ids.find(el => el === action.payload)
   const isListedElemento = state.filtros.elemento.find(el => el === action.payload)

   switch (action.type) {
      case FILTER_ADD_CATEGORIA:
         return {
            ...state, filtros: {
               ...state.filtros,
               categorias_id: isListedCategoria
                  ? state.filtros.categorias_id
                  : [...state.filtros.categorias_id, action.payload]
            }
         };

      case FILTER_REMOVE_CATEGORIA:
         return {
            ...state, filtros: {
               ...state.filtros,
               categorias_id: state.filtros.categorias_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_ETIQUETA:
         return {
            ...state, filtros: {
               ...state.filtros,
               etiquetas_ids: isListedEtiqueta
                  ? state.filtros.etiquetas_ids
                  : [...state.filtros.etiquetas_ids, action.payload]
            }
         };

      case FILTER_REMOVE_ETIQUETA:
         return {
            ...state, filtros: {
               ...state.filtros,
               etiquetas_ids: state.filtros.etiquetas_ids.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_ELEMENTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               elemento: isListedElemento ? state.filtros.elemento : [...state.filtros.elemento, action.payload]
            }
         };

      case FILTER_REMOVE_ELEMENTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               elemento: state.filtros.elemento.filter(el => el !== action.payload)
            }
         };

      case FILTER_NOMBRE:
         return {
            ...state, filtros: {
               ...state.filtros,
               nombre: action.payload
            }
         };

      case FILTER_CRITERIO:
         return {
            ...state, filtros: {
               ...state.filtros,
               criterio: action.payload
            }
         };

      case FILTER_BUSQUEDA_CRITERIO:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda_criterio: action.payload
            }
         };

      case FILTER_PRECIO_DESDE:
         return {
            ...state, filtros: {
               ...state.filtros,
               precio_desde: action.payload
            }
         };

      case FILTER_PRECIO_HASTA:
         return {
            ...state, filtros: {
               ...state.filtros,
               precio_hasta: action.payload
            }
         };

      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               parametros_busqueda: action.payload
            }
         };

      case FILTER_SKUS_PRODUCTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               skus: action.payload
            }
         };

      case RESULTADOS_PRODUCTOS_ETIQUETAS:
         return {
            ...state,
            resultados: action.payload
         };

      case FILTER_CLEAR_PRODUCTOS_ETIQUETAS:
         return initialState;

      default:
         return state;
   }
};
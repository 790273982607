import { Navigate, Route, Routes } from "react-router-dom";
import Listado from '../../views/FAQSubcategorias/Listado';
import Create from '../../views/FAQSubcategorias/Create';
import Update from '../../views/FAQSubcategorias/Update';
import Detalle from '../../views/FAQSubcategorias/Detalle';
import { useSelector } from "react-redux";


const FaqSubcategoriasRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['faq.crear'] &&
            <Route path='/create' element={<Create />} />
         }

         {user.permisos['faq.editar'] &&
            <Route path='/update/:id' element={<Update />} />
         }

         {user.permisos['faq.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   );
};

export default FaqSubcategoriasRouter;
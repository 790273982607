import React from 'react'
import OrdenesCompraFiltros from '../../components/AccordionFilter/OrdenesCompra'
import OrdenesCompraTable from '../../components/Tables/OrdenesCompraTable'

const Listado = () => {
   return (
      <>
         <OrdenesCompraFiltros />
         <OrdenesCompraTable />
      </>
   )
}

export default Listado
import { useEffect, useState } from "react";
import axios from "axios";

// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Enpoints
import { BASE_URL, CONFIG_URL } from "../../endpoints";


const CONSTANT = {
  redirectUrl: '/claves',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitleCreate: 'Crear clave',
  componentTitleUpdate: 'Editar clave',
  title: 'Claves',

  queryUrl: BASE_URL + CONFIG_URL + `/claves`,

  initialFormState: {
    nombre: '',
    estatus: true
  },
}


const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.initialFormState);
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const { id } = useParams()

  const [isConsulting, setIsConsulting] = useState(false)


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // EFFECTO QUE SETEA form
  useEffect(() => {
    if (!id) return

    axios(CONSTANT.queryUrl + `/${id}`)
      .then(({ data }) => {
        setForm({
          ...data,
        })
      })
      .catch(err => console.error(err))

    return () => {
      setForm(null)
    }
  }, [id])


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)

    let url = id ? CONSTANT.queryUrl + `/${id}` : CONSTANT.queryUrl
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: id ? 'PUT' : 'POST',
      data: {
        nombre: form.nombre,
        estatus: form.estatus
      }
    }

    if (config.method === 'PUT') return alert('Función no disponible')

    axios(url, config)
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: registro ${id ? 'actualizado' : 'creado'} con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);

        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
      })
      .finally(() =>
        setIsConsulting(false)
      )
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        <CustomForm size="" title={id ? CONSTANT.componentTitleUpdate : CONSTANT.componentTitleCreate}>
          <div className="row">
            <Switch
              labelText="Estatus"
              name="estatus"
              value={form?.estatus}
              handleValue={handleInputChange}
              size="col-12"
            />

            <FormInput
              labelText='* Nombre'
              name='nombre'
              value={form?.nombre ?? ''}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />
          </div>

          <Button disabled={isConsulting} type="submit" text={id ? 'Editar' : 'Crear'} color="primary" icon="Check" onClick={(e) => handleFetch(e)} />
        </CustomForm>
      </div>
    </div>
  );
};

export default Form;

import {
   FILTER_CREACION,
   FILTER_FECHA_FINAL,
   FILTER_FECHA_INICIO,
   FILTER_CLEAR,
   FILTER_NOMBRE,
   FILTER_ADD_ESTATUS,
   RESULTADOS_ETIQUETAS,
   RESULTADOS_OFERTAS
} from "../redux-types";


export const nombreFilter = (data) => ({ type: FILTER_NOMBRE, payload: data })

export const estatusFilter = (data) => ({ type: FILTER_ADD_ESTATUS, payload: data })

export const fechaHoraInicioFilter = (data) => ({ type: FILTER_FECHA_INICIO, payload: data })

export const fechaHoraFinalFilter = (data) => ({ type: FILTER_FECHA_FINAL, payload: data })

export const clearFilterOfertas = () => ({ type: FILTER_CLEAR });

export const resultadosOfertas = (data) => ({ type: RESULTADOS_OFERTAS, payload: data });

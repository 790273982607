import {
   FILTER_ADD_CATEGORIA,
   FILTER_ADD_ELEMENTO,
   FILTER_REMOVE_CATEGORIA,
   FILTER_REMOVE_ELEMENTO,
   FILTER_NOMBRE,
   FILTER_CRITERIO,
   FILTER_BUSQUEDA_CRITERIO,
   FILTER_REMOVE_BODEGA,
   FILTER_ADD_BODEGA,
   RESULTADOS_PRODUCTOS,
   FILTER_PRECIO_DESDE,
   FILTER_PRECIO_HASTA,
   FILTER_CLEAR_PRODUCTOS,
   FILTER_ADD_ETIQUETA,
   FILTER_REMOVE_ETIQUETA,
   FILTER_BUSQUEDA_PRODUCTO,
   FILTER_PUBLICADO,
   FILTER_SKUS_PRODUCTO,
   FILTROS_PERSISTENTES_PRODUCTOS,
} from "../redux-types";


const initialState = {
   filtros: {
      busqueda: '',
      categorias_id: [],
      etiqueta_id: [],
      elemento: [],
      bodega: [],
      nombre: '',
      criterio: '',
      busqueda_criterio: '',
      precio_desde: '',
      precio_hasta: '',
      publicado: 'null-Ambos',
      skus: ''
   },
   resultados: null
}


export const productosReducer = (state = initialState, action) => {
   const isListedCategoria = state.filtros.categorias_id.find(el => el === action.payload)
   const isListedEtiqueta = state.filtros.etiqueta_id.find(el => el === action.payload)
   const isListedElemento = state.filtros.elemento.find(el => el === action.payload)
   const isListedBodega = state.filtros.bodega.find(el => el === action.payload)

   switch (action.type) {

      case FILTER_ADD_CATEGORIA:
         return {
            ...state, filtros: {
               ...state.filtros,
               categorias_id: isListedCategoria
                  ? state.filtros.categorias_id
                  : [...state.filtros.categorias_id, action.payload]
            }
         };

      case FILTER_REMOVE_CATEGORIA:
         return {
            ...state, filtros: {
               ...state.filtros,
               categorias_id: state.filtros.categorias_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_ETIQUETA:
         return {
            ...state, filtros: {
               ...state.filtros,
               etiqueta_id: isListedEtiqueta
                  ? state.filtros.etiqueta_id
                  : [...state.filtros.etiqueta_id, action.payload]
            }
         };

      case FILTER_REMOVE_ETIQUETA:
         return {
            ...state, filtros: {
               ...state.filtros,
               etiqueta_id: state.filtros.etiqueta_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_ELEMENTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               elemento: isListedElemento ? state.filtros.elemento : [...state.filtros.elemento, action.payload]
            }
         };

      case FILTER_REMOVE_ELEMENTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               elemento: state.filtros.elemento.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_BODEGA:
         return {
            ...state, filtros: {
               ...state.filtros,
               bodega: isListedBodega ? state.filtros.bodega : [...state.filtros.bodega, action.payload]
            }
         };

      case FILTER_REMOVE_BODEGA:
         return {
            ...state, filtros: {
               ...state.filtros,
               bodega: state.filtros.bodega.filter(el => el !== action.payload)
            }
         };

      case FILTER_NOMBRE:
         return {
            ...state, filtros: {
               ...state.filtros,
               nombre: action.payload
            }
         };

      case FILTER_PUBLICADO:
         return {
            ...state, filtros: {
               ...state.filtros,
               publicado: action.payload
            }
         };

      case FILTER_CRITERIO:
         return {
            ...state, filtros: {
               ...state.filtros,
               criterio: action.payload
            }
         };

      case FILTER_BUSQUEDA_CRITERIO:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda_criterio: action.payload
            }
         };

      case FILTER_PRECIO_DESDE:
         return {
            ...state, filtros: {
               ...state.filtros,
               precio_desde: action.payload
            }
         };

      case FILTER_PRECIO_HASTA:
         return {
            ...state, filtros: {
               ...state.filtros,
               precio_hasta: action.payload
            }
         };

      case FILTER_BUSQUEDA_PRODUCTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda: action.payload
            }
         };

      case FILTER_SKUS_PRODUCTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               skus: action.payload
            }
         };

      case RESULTADOS_PRODUCTOS:
         return {
            ...state,
            resultados: action.payload
         };

      case FILTROS_PERSISTENTES_PRODUCTOS:
         return {
            ...state,
            filtros: action.payload
         };

      case FILTER_CLEAR_PRODUCTOS:
         return initialState;

      default:
         return state;
   }
};
import axios from 'axios'
import React, { useEffect, useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { FILTER_ADD_CATEGORIA, FILTER_ADD_CLAVE, FILTER_ADD_PUNTO_ENTREGA, FILTER_ADD_REGION, FILTER_BUSQUEDA, FILTER_PUBLICADO, FILTER_SKUS_PRODUCTO } from '../../redux/redux-types'
import { clavesFilter, displayModal } from '../../redux/actions/modalProductosFiltros'
import { busquedaFilter, categoriaFilter, clearFilterProductosPuntosEntrega, publicadoFilter, puntosEntregaFilter, regionesFilter, resultadosProductosPuntosEntrega, skusFilter } from '../../redux/actions/productosPuntosEntrega'

// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Components
import FiltroAplicado from './FiltroAplicado'
import Button from '../Button'
import FormInput from '../FormInput'
import SelectFilter from '../SelectFilter'
import ModalProductosFiltros from '../Modal/Productos'


// Helpers
import { lastPosition } from '../../helpers/lastPosition'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'


// Endpoints
import { getAll } from '../../endpoints/getAll'
import CustomLoader from '../CustomLoader'
import capitalize from '../../helpers/capitalize'
import DownloadFile from '../Download/DownloadFile'


const fecthDataFormatter = (filtrosObj, modalObj) => {
  let data = {}

  Object.entries(filtrosObj).forEach(([k, v]) => {
    if (!v?.length) return

    if (k === 'publicado' && v === 'null') return

    if (k === 'busqueda') return

    if (['skus'].includes(k)) {
      data = {
        ...data,
        [k]: v
      }
    }

    if (['categorias_id', 'puntos_entregas_ids', 'regiones'].includes(k)) {
      data = {
        ...data,
        [k]: v.map(el => Number(el.split('-')[0]))
      }
    }
  })

  if (Object.keys(modalObj).length) {
    let filtros_productos = {}

    Object.entries(modalObj).forEach(([k, v]) => {
      if (!v?.length) return
      filtros_productos = {
        ...filtros_productos,
        [k]: v.map(el => Number(el.split('-')[0]))
      }
    })

    data = { ...data, filtros_productos }
  }

  return data
}


const CONSTANT = {
  queryUrlGetAll: getAll.puntos_entrega_productos,
  redirectUrl: '/productos-puntos-entrega',
  title: 'Búsqueda de puntos de entrega',


  reduxClearFilters: clearFilterProductosPuntosEntrega,
  reduxSetResults: resultadosProductosPuntosEntrega,
  
  queryExportar: getAll.puntos_entrega_productos + `/exportar`,
  fetchCategorias: getAll.categorias + '/buscar',
  fetchPuntosEntrega: getAll.puntos_entrega + '/listar',
  fetchRegiones: getAll.regiones + '/buscar',

  selectFilterInitialState: {
    puntos_entrega: null,
    regiones: null,
    categorias: null,
    publicacion: [
      { id: 1, nombre: 'Publicado' },
      { id: 0, nombre: 'No publicado' },
      { id: 'null', nombre: 'Ambos' }
    ]
  }
}

const fetchSelectFiltersData = async () => {
  let config = {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
    method: 'POST'
  }

  const regiones = await axios(CONSTANT.fetchRegiones, config)
    .then(({ data }) => data?.regiones)
    .catch(err => {
      console.error(err)
      return []
    })

  const puntos_entrega = await axios(CONSTANT.fetchPuntosEntrega, config)
    .then(({ data }) => data ?? [])
    .catch(err => {
      console.error(err)
      return []
    })

  const categorias = await axios(CONSTANT.fetchCategorias, config)
    .then(({ data }) => data?.categorias ?? [])
    .catch(err => {
      console.error(err)
      return []
    })

  return { puntos_entrega, categorias, regiones }
}


const ProductosPuntosEntregaFiltros = ({ type = 'listar' }) => {
  const { filtros } = useSelector(state => state.productosPuntosEntrega)
  const { filtros_productos } = useSelector(state => state.modalProductosFiltros)
  const dispatch = useDispatch()
  const { categorias_id, puntos_entregas_ids, busqueda, publicado, regiones, skus } = filtros
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RELLENA LOS SELECTFILTERS
  useEffect(() => {
    fetchSelectFiltersData().then(({ puntos_entrega, categorias, regiones }) => {
      setSelectFilter(selectFilter => ({
        ...selectFilter,
        puntos_entrega,
        regiones,
        categorias
      }))
    })
  }, [dispatch])


  // EFECTO QUE LIMPIA LOS RESULTADOS AL DESMONTAR
  useEffect(() => { return () => dispatch(CONSTANT.reduxSetResults([])) }, [dispatch])


  // FUNCION MANEJADORA QUE CONSULTA EL ENDPOINT CON LOS FILTROS APLICADOS
  const handleFetchFilter = async (reset = false) => {
    let data = {}
    let paramPublicado = publicado.includes('null') ? '' : publicado?.split('-')?.[0]
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
      data
    }

    if (!Object.entries(filtros).find(([k, v]) => v?.length && k !== 'publicado')) return dispatch(fireToaster({ title: 'Por favor seleccione al menos un filtro', icon: 'warning' }))

    setIsConsulting(true)

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      setIsConsulting(false)
      return dispatch(CONSTANT.reduxSetResults([]))
    }

    // FORMATEADOR DE data
    else {
      data = fecthDataFormatter(filtros, filtros_productos)
      config = {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data
      }
    }

    dispatch(CONSTANT.reduxSetResults(null))

    await axios(CONSTANT.queryUrlGetAll + `/${type}?limit=99999&asignacion_masiva=1` + queryParamsFormatter({ parametros_busqueda: busqueda, publicado: paramPublicado }), config)
      .then(({ data }) => {
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
        return dispatch(CONSTANT.reduxSetResults(data ?? []))
      })
      .catch(err => {
        console.error(err)

        const { errores } = err.response.data
        let detalles = []

        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  // MANEJADORES DE FILTRO - PRODUCTOS
  const handleFilter = (e, filter) => {
    const { value } = e.target
    switch (filter) {

      case FILTER_ADD_CATEGORIA:
        dispatch(categoriaFilter(value))
        break;

      case FILTER_ADD_PUNTO_ENTREGA:
        dispatch(puntosEntregaFilter(value))
        break;

      case FILTER_BUSQUEDA:
        dispatch(busquedaFilter(value))
        break;

      case FILTER_ADD_REGION:
        dispatch(regionesFilter(value))
        break;

      case FILTER_PUBLICADO:
        dispatch(publicadoFilter(value))
        break;

      case FILTER_ADD_CLAVE:
        dispatch(clavesFilter(value))
        break;

      case FILTER_SKUS_PRODUCTO:
        dispatch(skusFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      {selectFilter?.categorias && selectFilter?.puntos_entrega && selectFilter?.regiones
        ? <>
          <ModalProductosFiltros />

          <ListadoHeader
            classes='border rounded-2'
            handleDisable={isConsulting}
            title={CONSTANT.title}
            handleClickSearch={() => handleFetchFilter()}
            handleClickClearFilter={() => handleFetchFilter(true)}
            exportBtn={
              <DownloadFile
                url={CONSTANT.queryExportar + '?asignacion_masiva=1' + queryParamsFormatter({ parametros_busqueda: busqueda, publicado: publicado.includes('null') ? '' : publicado?.split('-')?.[0] })}
                data={fecthDataFormatter(filtros, filtros_productos)}
              />
            }
          >
            <FormInput
              labelText='Búsqueda'
              placeholder='Búsqueda'
              size='col-12'
              sizeDesk='col-md-4'
              handleValue={(e) => handleFilter(e, FILTER_BUSQUEDA)}
              value={busqueda}
            />

            <FormInput
              labelText='SKUs'
              placeholder='SKUs'
              size='col-12'
              sizeDesk='col-md-4'
              handleValue={(e) => handleFilter(e, FILTER_SKUS_PRODUCTO)}
              value={skus}
            />

            <SelectFilter
              labelText='Estatus de publicación'
              size='col-12'
              sizeDesk='col-md-3'
              handleValue={(e) => handleFilter(e, FILTER_PUBLICADO)}
              optionObj={selectFilter.publicacion?.map(({ id, nombre }) => ({ id, name: nombre }))}
              value={publicado?.split('-')?.[1] ?? 'Seleccione'}
            />

            <SelectFilter
              labelText='Región'
              size='col-12'
              sizeDesk='col-md-3'
              handleValue={(e) => handleFilter(e, FILTER_ADD_REGION)}
              optionObj={selectFilter.regiones?.map(({ id, nombre }) => ({ id, name: nombre }))}
              value={lastPosition(regiones)}
            />

            <SelectFilter
              labelText='Categoría'
              size='col-12'
              sizeDesk='col-md-3'
              handleValue={(e) => handleFilter(e, FILTER_ADD_CATEGORIA)}
              optionObj={selectFilter.categorias?.map(({ id, nombre }) => ({ id, name: nombre }))}
              value={lastPosition(categorias_id)}
            />

            <SelectFilter
              labelText='Puntos de entrega'
              size='col-12'
              sizeDesk='col-md-10'
              handleValue={(e) => handleFilter(e, FILTER_ADD_PUNTO_ENTREGA)}
              optionObj={selectFilter.puntos_entrega?.map(({ id, direccion, tipo_destino, comuna, nombre }) => ({ id, name: `${tipo_destino?.nombre ?? 'N/A'} | ${direccion ?? 'N/A'}, ${nombre ?? 'N/A'} | ${comuna?.region?.nombre ?? 'N/A'}` }))}
              value={lastPosition(puntos_entregas_ids)}
            />

            <div className="col-12 col-md-1 my-auto">
              <Button icon={'Plus'} text='Filtros' className={'w-fit my-auto'} onClick={() => dispatch(displayModal(true))} />
            </div>

            {/* FILTROS APLICADOS - PRODUCTOS */}
            <p className='mb-25 text-black'>Filtros aplicados</p>
            <FiltroAplicado array={categorias_id} func={categoriaFilter} title={'Categorías'} />
            <FiltroAplicado array={puntos_entregas_ids} func={puntosEntregaFilter} title={'Puntos de entrega'} />
            <FiltroAplicado array={regiones} func={regionesFilter} title={'Regiones'} />

            {Object?.keys(filtros_productos)?.length
              ? Object?.entries(filtros_productos).map(([nombreFiltro, valoresArr]) => (valoresArr.length)
                ? <FiltroAplicado key={capitalize(nombreFiltro.split('_').join(' '))} array={valoresArr} title={capitalize(nombreFiltro.split('_').join(' '))} func={clavesFilter} />
                : <></>
              )

              : <></>
            }
            <hr className='my-1' />
          </ListadoHeader>
        </>
        : <CustomLoader />
      }

    </div>
  )
}

export default ProductosPuntosEntregaFiltros
//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import SelectFilter from "../../components/SelectFilter";


// Hooks
import FileUploaderB from "../../components/FileUploader_B";
import axios from "axios";
import { getOne } from "../../endpoints/getOne";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";
import { BASE_URL } from "../../endpoints";
import { getAll } from "../../endpoints/getAll";
import CustomLoader from "../../components/CustomLoader";


const CONSTANT = {
  redirectUrl: '/generales/regiones',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitle: 'Editar región',
  title: 'Regiones',
  queryUrl: getOne.region,
  fetchPaises: getAll.paises + '/buscar',
  fetchRegionesErp: getAll.erp_regiones,
  initialFormState: {
    pais_id: "",
    nombre: "",
    cod_maxi: "",
    imagen_bandera: "",
    estatus: 1,
  },
  selectFilterInitialState: {
    paises: [],
    // erpRegiones: []
  }
}


const handleSelectFiltersData = async () => {
  const paises = await axios(CONSTANT.fetchPaises,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.paises] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  // const erpRegiones = await axios(CONSTANT.fetchRegionesErp)
  //   .then(({ data }) => [CONSTANT.unselectedObject, ...data?.regiones] ?? [CONSTANT.unselectedObject])
  //   .catch(err => {
  //     console.error(err)
  //     return [CONSTANT.nullObject]
  //   })

  return { paises }
}


const Update = () => {
  const [imagen_bandera, setImagen_bandera] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetchData, setFetchData] = useState(CONSTANT.initialFormState)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const { id } = useParams()


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setFetchData({
      ...fetchData,
      [name]: value
    })
  }


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  useEffect(() => {
    axios(CONSTANT.queryUrl(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        const res = data?.region
        const pais_id = `${res?.neu_pais?.id ?? '*NULL*'}-${res?.neu_pais?.nombre ?? 'Error'}`
        setFetchData({
          ...res,
          pais_id
        })
      })
      .catch(err => {
        console.error(err)
        dispatch(fireToaster({ icon: 'info', title: 'Registro no encontrado' }))
        navigate(CONSTANT.redirectUrl)
      })

    return () => {
      setFetchData(null)
    }
  }, [id])


  const handleFetch = async (e) => {
    e.preventDefault()

    if (!fetchData.nombre.length) return dispatch(fireToaster({ title: 'El nombre es obligatorio', icon: 'info' }))
    if (!fetchData.cod_maxi.length) return dispatch(fireToaster({ title: 'El código maximise es obligatorio', icon: 'info' }))
    if (!fetchData.pais_id.length) return dispatch(fireToaster({ title: 'El país es obligatorio', icon: 'info' }))

    let data = new FormData()
    data.append('nombre', fetchData.nombre)
    data.append('cod_maxi', fetchData.cod_maxi)
    imagen_bandera.length && data.append('imagen_bandera', imagen_bandera[0].file)
    data.append('pais_id', fetchData.pais_id.split('-')[0])
    data.append('estatus', fetchData.estatus ? 1 : 0)
    data.append('_method', 'PUT')

    // for (const [k, v] of data) {
    //   console.log(k, v);
    // }
    // return
    axios(getOne.region(id), {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'multipart/form-data'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} actualizado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []

        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))
        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text='Regiones' icon='ArrowLeft' style='flat' isLink url='/regiones' />
        </div>

        {selectFilter.paises.length

          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                name="estatus"
                value={fetchData.estatus}
                labelText="Estatus"
                handleValue={handleInputChange}
                size="col-12"
              />

              <FormInput
                name="nombre"
                value={fetchData.nombre}
                labelText="* Nombre"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk="col-md-4"
              />

              <SelectFilter
                name="pais_id"
                value={fetchData.pais_id.split('-')[1]}
                labelText="* País"
                handleValue={handleInputChange}
                optionObj={selectFilter.paises.map(({ nombre, id }) => ({ name: nombre, id }))}
                size="col-12"
                sizeDesk="col-md-4"
              />

              <FormInput
                name="cod_maxi"
                value={fetchData.cod_maxi}
                labelText="* Cod. Maximise"
                handleValue={handleInputChange}
                // optionObj={selectFilter.erpRegiones?.map(({ cod_maxi, nombre }) => ({ name: `${nombre ?? 'Error'} | ${cod_maxi ?? ''}`, id: cod_maxi ?? '*NULL*' }))}
                size="col-12"
                sizeDesk="col-md-4"
              />

              <FileUploaderB labelText='* Bandera' handleValue={setImagen_bandera} value={imagen_bandera} />

              <div className="input-inset col-12">
                <label className="form-label">Imagen actual</label>
                <div className="form-outline border rounded-2 lista-detalle mb-2 d-flex justify-content-center p-2 w-fit">
                  {fetchData?.imagen_bandera &&
                    <img src={BASE_URL + 'storage/' + fetchData?.imagen_bandera} alt={`img-${fetchData.nombre}`} />
                  }
                </div>
              </div>
            </div>

            <Button type="submit" text="Actualizar" color="primary" icon="Circle" onClick={(e) => handleFetch(e)} />
          </CustomForm>

          : <CustomLoader />
        }

      </div>
    </div>
  );
};

export default Update;

import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_PASOS_COMPRA,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosPasosCompra = (data) => ({ type: RESULTADOS_PASOS_COMPRA, payload: data })

export const clearFilterPasosCompra = () => ({ type: FILTER_CLEAR });

import { useEffect, useState } from "react";
import axios from "axios";

// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import CustomLoader from "../../components/CustomLoader";
import TextareaInput from "../../components/TextareaInput";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Helpers
import { hourFormatter } from "../../helpers/hourFormatter";


const hF = (str) => str.split(' ')[1].split(':').map((t, i) => i < 2 ? t : null).filter(el => el !== null).join(':')


const CONSTANT = {
  title: 'Eventos',
  componentTitle: 'Editar evento',
  redirectUrl: '/eventos',
  queryUrl: getOne.eventos,
}


const Update = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetchData, setFetchData] = useState(null)
  const { id } = useParams()


  // MANEJADOR DE LOS INPUTS
  const handleInputChange = (e) => {
    const { value, name } = e.target

    setFetchData({
      ...fetchData,
      [name]: value
    })
  }


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS Y SETEA fetchData
  useEffect(() => {
    axios(CONSTANT.queryUrl(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        const horaInicio = hF(data?.fecha_hora_inicio)
        const horaFinal = hF(data?.fecha_hora_final)

        setFetchData({
          ...data,
          nombre: `${data?.nombre}`,
          descripcion: `${data?.descripcion}`,
          fecha_inicio: `${data?.fecha_hora_inicio.split(' ')[0]}`,
          hora_inicio: horaInicio,
          fecha_final: `${data?.fecha_hora_final.split(' ')[0]}`,
          hora_final: horaFinal,
        })

      })
      .catch(err => console.error(err))

    return () => {
      setFetchData(null)
    }
  }, [id])


  // FUNCIÓN MANEJADORA QUE EDITA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    if (!fetchData.nombre?.length) return dispatch(fireToaster({ title: 'El nombre no puede estar vacío', icon: 'info' }))
    if (!fetchData.fecha_inicio || !fetchData.hora_inicio) return dispatch(fireToaster({ title: 'Falta fecha/hora de inicio', icon: 'info' }))
    if (!fetchData.fecha_final || !fetchData.hora_final) return dispatch(fireToaster({ title: 'Falta fecha/hora final', icon: 'info' }))

    let requestData = {
      nombre: fetchData?.nombre,
      descripcion: fetchData?.descripcion,
      fecha_hora_inicio: `${fetchData?.fecha_inicio} ${hourFormatter(fetchData?.hora_inicio)}`,
      fecha_hora_final: `${fetchData?.fecha_final} ${hourFormatter(fetchData?.hora_final)}`,
    }

    // return console.log(requestData);

    axios(CONSTANT.queryUrl(id), {
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        _method: 'PUT'
      },
      data: requestData
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} actualizada con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {fetchData?.id
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <FormInput
                labelText="* Nombre"
                name='nombre'
                placeholder='Nombre...'
                handleValue={handleInputChange}
                value={fetchData?.nombre}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <TextareaInput
                labelText="Descripción"
                placeholder='Descripción...'
                name='descripcion'
                handleValue={handleInputChange}
                value={fetchData?.descripcion}
                size="col-12"
                sizeDesk="col-md-12"
                rows={2}
              />

              <FormInput
                labelText="* Fecha inicio"
                type="date"
                handleValue={handleInputChange}
                max={fetchData?.fecha_final}
                name='fecha_inicio'
                value={fetchData?.fecha_inicio}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Hora inicio"
                type="time"
                name='hora_inicio'
                handleValue={handleInputChange}
                value={fetchData?.hora_inicio}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Fecha final"
                type="date"
                min={fetchData?.fecha_inicio}
                name='fecha_final'
                handleValue={handleInputChange}
                value={fetchData?.fecha_final}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Hora final"
                type="time"
                name='hora_final'
                handleValue={handleInputChange}
                value={fetchData?.hora_final}
                size="col-12"
                sizeDesk="col-md-6"
              />
            </div>
            <Button type="submit" text="Actualizar" color="primary" icon="Circle" onClick={(e) => handleFetch(e)} />
          </CustomForm>
          : <CustomLoader />
        }
      </div>
    </div>
  );
};

export default Update;

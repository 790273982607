import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "../Icon";


const SideBarLink = ({ text, icon, url }) => {
	//TODO: mete la prop url
	const [isActive, setIsActive] = useState(false);
	const currentPath = useLocation().pathname;

	useEffect(() => {
		if (currentPath === url) setIsActive(true);
		else setIsActive(false);
	}, [url, currentPath]);


  return (
    <li className={"nav-item " + (isActive && "active")}>
      <Link className="d-flex align-items-center " to={url}>
        <Icon icon={icon} />
        <span className="menu-title text-truncate" data-i18n="Home">{text}</span>
      </Link>
    </li>
  );
};

export default SideBarLink;
import {
   FILTER_ADD_ESTATUS,
   FILTER_REMOVE_ESTATUS,
   FILTER_CLEAR,
   FILTER_CREACION_DESDE,
   FILTER_BUSQUEDA,
   FILTER_CREACION_HASTA,
   FILTER_REMOVE_NOMBRE,
   FILTER_ADD_NOMBRE,
   FILTER_REMOVE_TABLA,
   FILTER_ADD_TABLA,
   RESULTADOS_TIPOS_ELEMENTOS
} from "../redux-types";


export const estatusFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_ESTATUS, payload: data }
      : { type: FILTER_ADD_ESTATUS, payload: data };

export const nombreFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_NOMBRE, payload: data }
      : { type: FILTER_ADD_NOMBRE, payload: data };

export const tablaFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_TABLA, payload: data }
      : { type: FILTER_ADD_TABLA, payload: data }

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const creacionDesdeFilter = (data) => ({ type: FILTER_CREACION_DESDE, payload: data })

export const creacionHastaFilter = (data) => ({ type: FILTER_CREACION_HASTA, payload: data })

export const resultadosTiposElementos = (data) => ({ type: RESULTADOS_TIPOS_ELEMENTOS, payload: data })

export const clearFilterTiposElementos = () => ({ type: FILTER_CLEAR });

// Components
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import ValoresAtributosFiltros from "../../components/AccordionFilter/ValoresAtributos";
import ValoresAtributosTable from "../../components/Tables/ValoresAtributosTable";


const CONSTANT = {
  title: 'Valores/Atributos',
  path: 'valores-atributos',
  create_btn: 'Crear valor/atributo'
}


const Listado = () => {
  return (
    <>
      <h3 className="mb-1 d-flex justify-content-between">
        <p><b>{CONSTANT.title}</b> - Listado</p>
        <DropdownWrapper text="Acciones" classes="ms-auto">
          <DropdownMenuLink text={CONSTANT.create_btn} href={`/productos/${CONSTANT.path}/create`} icon="Plus" />
        </DropdownWrapper>
      </h3>

      <ValoresAtributosFiltros />

      <ValoresAtributosTable />
    </>
  );
};

export default Listado;

import { useEffect, useState } from "react";
import axios from "axios";

//	Components
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import FormInput from "../../components/FormInput";
import CustomLoader from "../../components/CustomLoader";


// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


// React-router-dom
import { useNavigate, useParams } from "react-router-dom";
import SelectFilter from "../../components/SelectFilter";
import { getAll } from "../../endpoints/getAll";
import { create } from "../../endpoints/create";
import { getOne } from "../../endpoints/getOne";
import { idExtractor } from "../../helpers/idExtractor";
import { fetchErp } from "../../helpers/fetchErp";
import moment from "moment";
import Switch from "../../components/Switch";


const CONSTANT = {
  redirectListado: `/erp/notas-ventas/`,
  redirectDetalle: (id) => `/erp/notas-ventas/detalle/${id}`,
  title: 'Notas de venta',
  titleDetalle: 'Detalle de la NV',
  componenteTitle: 'Editar NV',
  queryUrl: getAll.notas_ventas,
  fetchData: getOne.notas_ventas,
  authHeader: { 'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null },

  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },

  form: {
    enviar_ext_num: false,
    enviar_info_despacho: false,

    ext_num: '', // id del pedido
    com_text: '', // comentario de la nv

    sales_code: '', //codigo de vendedor ERP
    docshipvia_code: '', // modo de despacho id
    ud_tipo_desti: '', // tipo de destino id
    ud_tipo_facturacion: '', // tipo de facturacion nombre

    ord_text: '', // orden externa
    ord_date: '', // fecha de la orden
    ship_code: '', // RUT de a quien se le va a despachar
    ship_name_text: '', // NOMBRE de a quien se le va a despachar
    ship_addr_text: '', // DIRECCION a donde se va a despachar
  },

  selectFilter: {
    vendedores: [],
    modosDespacho: [],
    tiposDestinos: [],
    tiposFacturacion: []
  }
}


const handleSelectFiltersData = () => {
  const queries = {
    vendedores: fetchErp().vendedores(),
    modosDespacho: fetchErp().modosDespacho(),
    tiposDestinos: axios(getAll.tipos_destino, { headers: { ...CONSTANT.authHeader } }),
    tiposFacturacion: axios(getAll.tipos_facturacion, { headers: { ...CONSTANT.authHeader } }),
  }

  return Promise.all(
    Object.entries(queries).map(async ([k, v]) => await v.then((res) => ({ [k]: res?.data ?? null })))
  )
    .then(res => {
      let r = {}
      res.forEach((x) => {
        Object.entries(x).forEach(([k, v]) => r = { ...r, [k]: [CONSTANT.unselectedObject, ...v] })
      })
      return r
    })
}


const Update = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.form)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const { id } = useParams()


  // FUNCIÓN MANEJADORA QUE ACTUALIZA EL ESTADO DE 'FetchData'
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }


  // EFECTO QUE RELLENA LOS SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // EFECTO QUE CONSULTA LA DATA DEL REGISTRO Y ACTUALIZA EL ESTADO 'FetchData'
  useEffect(() => {
    axios(CONSTANT.fetchData(id), {
      headers: { ...CONSTANT.authHeader }
    })
      .then(({ data }) => {
        if (!selectFilter.vendedores.length || !selectFilter.modosDespacho.length || !selectFilter.tiposDestinos.length || !selectFilter.tiposFacturacion.length) return

        const dVendedor = selectFilter.vendedores?.find(({ codigo_vendedor }) => String(codigo_vendedor) === String(data?.detalle_nv?.SALES_CODE))
        const dTipoDesti = selectFilter.tiposDestinos?.find(({ nombre }) => String(nombre) === String(data?.detalle_nv?.UD_TIPO_DESTI))
        const dTipoFactura = selectFilter.tiposFacturacion?.find(({ nombre }) => String(nombre) === String(data?.detalle_nv?.UD_TIPO_FACTURACION).trim())
        const dModoDespa = selectFilter.modosDespacho?.find(({ cod_erp }) => String(cod_erp) === String(data?.detalle_nv?.DOCSHIPVIA_CODE))

        setForm({
          ...data.detalle_nv,
          ext_num: data?.detalle_nv?.EXT_NUM,
          com_text: data?.detalle_nv?.COM_TEXT,

          sales_code: `${dVendedor?.codigo_vendedor ?? '*NULL*'}%-%${dVendedor?.vendedor ?? 'Sin selección'}`,
          ud_tipo_desti: `${dTipoDesti?.nombre ?? '*NULL*'}%-%${dTipoDesti?.nombre ?? 'Sin selección'}`,
          ud_tipo_facturacion: `${dTipoFactura?.nombre ?? '*NULL*'}%-%${dTipoFactura?.nombre ?? 'Sin selección'}`,
          docshipvia_code: `${dModoDespa?.cod_erp ?? '*NULL*'}%-%${dModoDespa?.nombre ?? 'Sin selección'}`,

          ord_text: data?.detalle_nv?.ORD_TEXT,
          ord_date: moment(data?.detalle_nv?.ORDER_DATE.split('T')?.[0]).format('YYYY-MM-DD'),
          ship_code: data?.detalle_nv?.SHIP_CODE,
          ship_name_text: data?.detalle_nv?.SHIP_NAME_TEXT,
          ship_addr_text: data?.detalle_nv?.SHIP_ADDR_TEXT,
        })
      })
      .catch(err => console.log(err))
    return () => {
      setForm(null)
    }
  }, [id, selectFilter.vendedores, selectFilter.modosDespacho, selectFilter.tiposFacturacion, selectFilter.tiposDestinos])


  const handleFetch = async (e) => {
    e.preventDefault()

    let data = {
      com_text: form?.com_text,
      ord_date: moment(form?.ord_date).format('YYYY-MM-DD'),
    }

    if (!form.ud_tipo_desti.includes('Sin selección')) {
      data = { ...data, ud_tipo_desti: idExtractor(form?.ud_tipo_desti, true) }
    }

    if (!form.sales_code.includes('*NULL*')) {
      data = { ...data, sales_code: Number(idExtractor(form?.sales_code, true)) }
    }

    if (!form.ud_tipo_facturacion.includes('Sin selección')) {
      data = { ...data, ud_tipo_facturacion: idExtractor(form?.ud_tipo_facturacion, true) }
    }

    if (!form.docshipvia_code.includes('*NULL*')) {
      data = { ...data, docshipvia_code: Number(idExtractor(form?.docshipvia_code, true)) }
    }

    if (form.enviar_ext_num) {
      data = { ...data, ext_num: Number(form?.ext_num) }
    }
    if (form.enviar_info_despacho) {
      data = {
        ...data,
        ship_code: form?.ship_code,
        ship_name_text: form?.ship_name_text,
        ship_addr_text: form?.ship_addr_text
      }
    }

    axios(CONSTANT.queryUrl + `/editar/${id}`, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
      },
      data
    })
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: Registro actualizado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectDetalle(id))
      })
      .catch(err => {
        console.error(err);
        const { errores, msj } = err.response.data;
        const msjObj = { errores: [msj] };
        let detalles = [];

        Object.values(errores ?? msjObj).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
          Operación fallida
          (${err.response.status} - ${err.response.statusText})
        `,
          html: `
          <b>Detalle: </b>
          ${detalles.map(error => `<br /><i>-${error}</i>`)}
        `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  const handleFiltrosPersistentes = (location) => {
    return `?filtros_persistentes=${location?.search?.split('filtros_persistentes=')?.[1] ?? '0'}`
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='d-flex'>
          <Button className='col-12 col-md-6' text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectListado + handleFiltrosPersistentes(window.location)} />
          <Button className='col-12 col-md-6' text={CONSTANT.titleDetalle} icon='ArrowRight' style='flat' isLink url={CONSTANT.redirectDetalle(id) + handleFiltrosPersistentes(window.location)} />
        </div>

        {form?.ORDER_NUM
          ? <CustomForm size="" title={CONSTANT.componenteTitle}>
            <div className="row">
              <Switch
                name='enviar_ext_num'
                type='number'
                labelText="Editar Num. Pedido"
                size="col-12"
                sizeDesk="col-md-6 my-auto"
                handleValue={handleInputChange}
                value={form?.enviar_ext_num ? 1 : 0}
                isList
              />

              <FormInput
                isDisabled={!form?.enviar_ext_num}
                name='ext_num'
                type='number'
                labelText="Num. Pedido"
                size="col-12"
                sizeDesk="col-md-6"
                handleValue={handleInputChange}
                value={form?.ext_num}
              />

              <FormInput
                name='com_text'
                labelText="Comentario"
                size="col-12"
                sizeDesk="col-md-6"
                handleValue={handleInputChange}
                value={form?.com_text}
              />

              <FormInput
                name='ord_text'
                labelText="Orden Externa"
                size="col-12"
                sizeDesk="col-md-6"
                handleValue={handleInputChange}
                value={form?.ord_text}
              />

              <hr className="mt-2" />

              <Switch
                name='enviar_info_despacho'
                type='number'
                labelText="Editar info despacho"
                size="col-12"
                sizeDesk="col-md-6 my-auto"
                handleValue={handleInputChange}
                value={form?.enviar_info_despacho ? 1 : 0}
                isList
              />

              <FormInput
                isDisabled={!form?.enviar_info_despacho}
                name='ship_code'
                labelText="RUT de despacho"
                size="col-12"
                sizeDesk="col-md-6"
                handleValue={handleInputChange}
                value={form?.ship_code}
              />

              <FormInput
                isDisabled={!form?.enviar_info_despacho}
                name='ship_name_text'
                labelText="Nombre de despacho"
                size="col-12"
                sizeDesk="col-md-6"
                handleValue={handleInputChange}
                value={form?.ship_name_text}
              />

              <FormInput
                isDisabled={!form?.enviar_info_despacho}
                name='ship_addr_text'
                labelText="Dirección de despacho"
                size="col-12"
                sizeDesk="col-md-6"
                handleValue={handleInputChange}
                value={form?.ship_addr_text}
              />

              <hr className="mb-3" />

              <SelectFilter
                altSeparator
                name='sales_code'
                labelText="Vendedor"
                size="col-12"
                sizeDesk="col-md-6"
                handleValue={handleInputChange}
                value={form?.sales_code?.split('%-%')?.[1] ?? 'Seleccione'}
                optionObj={selectFilter?.vendedores?.map(({ id, nombre, codigo_vendedor, vendedor }) => ({ id: codigo_vendedor ?? id, name: vendedor ?? nombre }))}
              />

              <SelectFilter
                altSeparator
                name='ud_tipo_desti'
                labelText="Tipo de destino"
                size="col-12"
                sizeDesk="col-md-6"
                handleValue={handleInputChange}
                value={form?.ud_tipo_desti?.split('%-%')?.[1] ?? 'Seleccione'}
                optionObj={selectFilter?.tiposDestinos?.map(({ id, nombre }) => ({ id: nombre ?? id, name: nombre }))}
              />

              <SelectFilter
                altSeparator
                name='ud_tipo_facturacion'
                labelText="Tipo de facturación"
                size="col-12"
                sizeDesk="col-md-6"
                handleValue={handleInputChange}
                value={form?.ud_tipo_facturacion?.split('%-%')?.[1] ?? 'Seleccione'}
                optionObj={selectFilter?.tiposFacturacion?.map(({ id, nombre }) => ({ id: nombre ?? id, name: nombre }))}
              />

              <SelectFilter
                altSeparator
                name='docshipvia_code'
                labelText="Modo de despacho"
                size="col-12"
                sizeDesk="col-md-6"
                handleValue={handleInputChange}
                value={form?.docshipvia_code?.split('%-%')?.[1] ?? 'Seleccione'}
                optionObj={selectFilter?.modosDespacho?.map(({ cod_erp, nombre, id }) => ({ id: cod_erp ?? id, name: nombre }))}
              />

              <FormInput
                name='ord_date'
                type='date'
                labelText="Fecha"
                size="col-12"
                sizeDesk="col-md-6"
                handleValue={handleInputChange}
                value={form?.ord_date}
              />
            </div>

            <Button type="submit" text="Actualizar" color="primary" icon="Circle" onClick={handleFetch} />
          </CustomForm>
          : <CustomLoader />
        }
      </div>
    </div>
  );
};

export default Update;

import { useEffect, useState } from "react";
import axios from "axios";
import * as fas from '@fortawesome/free-solid-svg-icons';
import * as far from '@fortawesome/free-regular-svg-icons';
import * as fab from '@fortawesome/free-brands-svg-icons';
import * as MDI from '@mdi/js';

// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import CustomLoader from "../../components/CustomLoader";
import IconPicker from "../../components/IconPicker";
import TextareaInput from "../../components/TextareaInput";
import SelectFilter from "../../components/SelectFilter";


// React-router-dom
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { getOne } from "../../endpoints/getOne";
import { getAll } from "../../endpoints/getAll";
import { iconSanatizerFaMdi } from "../../helpers/iconSanatizerFaMdi";
import { idExtractor } from "../../helpers/idExtractor";


const iconsFA = { fas, far, fab }


const CONSTANT = {
  redirectUrl: '/template/faq-subcategorias',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitle: 'Editar Subcategoría - FAQ',
  title: 'Subcategorías - FAQ',
  queryUrl: getOne.faq,
  fetchFrontendId: getAll.frontend_id,
  fetchCategorias: getAll.categorias_faq,

  initialFormState: {
    frontend_id: '',
    nombre: '',
    descripcion: '',
    categoria_id: '',
    blog_url: '',
    icono_libreria: null,
    icono_fuente: null,
    icono_icono: null,
    estatus: true
  },

  selectFilterInitialState: {
    frontendId: [],
    categorias: []
  }
}


const handleSelectFiltersData = async () => {
  const frontendId = await axios(CONSTANT.fetchFrontendId,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const categorias = await axios(CONSTANT.fetchCategorias,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { frontendId, categorias }
}


const Update = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [icono, setIcono] = useState(null)
  const { id } = useParams()
  const [form, setForm] = useState(CONSTANT.initialFormState);
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [isConsulting, setIsConsulting] = useState(false)


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // EFECTO QUE CONSULTA LA DATA DEL REGISTRO Y ACTUALIZA EL ESTADO 'FetchData'
  useEffect(() => {
    let fetchIcon = null

    axios(getOne.subcategorias_faq(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {

        fetchIcon = data?.neu_icono?.libreria === 'fa'
          ? {
            prefix: data?.neu_icono?.fuente,
            path: data?.neu_icono?.icono,
            iconName: iconsFA[data?.neu_icono?.fuente][data?.neu_icono?.icono].iconName,
            icon: iconsFA[data?.neu_icono?.fuente][data?.neu_icono?.icono]
          }
          : data?.neu_icono?.libreria === 'mdi'
            ? {
              icon: {
                prefix: 'mdi',
                iconName: MDI[data?.neu_icono?.icono],
                path: MDI[data?.neu_icono?.icono].path
              },
              nativeIcon: MDI[data?.neu_icono?.icono]
            }
            : null

        setForm({
          ...data,
          categoria_id: `${data?.categoria_id}-${data?.categoria?.nombre}`,
          frontend_id: `${data?.frontend_id ?? '*NULL*'}-${data?.frontend?.nombre ?? 'Sin selección'}`,
        })
        setIcono(fetchIcon)
      })
      .catch(err => console.error(err))

    return () => {
      setForm(null)
    }
  }, [id])


  // FUNCIÓN MANEJADORA QUE ACTUALIZA LA DATA DEL REGISTRO
  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)

    const sanitazedIcon = iconSanatizerFaMdi(icono)


    let data = {
      nombre: form?.nombre,
      descripcion: form?.nombre,
      blog_url: form?.blog_url,
      categoria_id: form?.categoria_id?.split('-')[0],
      icono_libreria: sanitazedIcon?.libreria,
      icono_fuente: sanitazedIcon?.fuente ?? null,
      icono_icono: sanitazedIcon?.icono,
      estatus: Number(form?.estatus) ? true : false
    }

    if (!form?.frontend_id.includes('*NULL*')) {
      data = {
        ...data,
        frontend_id: idExtractor(form?.frontend_id),
      }
    }

    axios(getOne.subcategorias_faq(id), {
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
      },
      data
    })
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `Subcategoría: ${res.data.nombre} actualizada con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT?.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []

        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))
        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() =>
        setIsConsulting(false)
      )
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT?.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {form?.id && selectFilter.frontendId.length
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                labelText='Estatus'
                name='estatus'
                value={form?.estatus}
                handleValue={handleInputChange}
                size='col-12'
              />

              <FormInput
                labelText='Nombre'
                name='nombre'
                value={form?.nombre}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <IconPicker
                value={icono}
                handleValue={setIcono}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <SelectFilter
                name="frontend_id"
                value={form?.frontend_id?.split('-')[1] ?? ''}
                labelText="* Frontend"
                optionObj={selectFilter.frontendId.map(({ id, nombre }) => ({ id, name: nombre }))}
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <SelectFilter
                labelText='Categoría'
                name='categoria_id'
                value={form?.categoria_id.split('-')[1]}
                handleValue={handleInputChange}
                optionObj={selectFilter?.categorias?.map(({ id, nombre }) => ({ id, name: nombre }))}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='URL del blog'
                name='blog_url'
                value={form?.blog_url}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <TextareaInput
                labelText='Descripión'
                name='descripcion'
                value={form?.descripcion}
                handleValue={handleInputChange}
                rows={2}
                size='col-12'
              />
            </div>


            <Button disabled={isConsulting} type="submit" text="Editar" color="primary" icon="Check" onClick={(e) => handleFetch(e)} />

          </CustomForm>

          : <CustomLoader />
        }
      </div>
    </div>

  );
};

export default Update;

import axios from 'axios'
import { useEffect, useState } from 'react'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch, useSelector } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import Modal from '../../components/Modal'
import AccordionDetailData from '../../components/AccordionDetailData'
import Table from '../../components/Table'
import Button from '../../components/Button'
import Switch from '../../components/Switch'
import CustomLoader from '../../components/CustomLoader'
import FileUploaderB from '../../components/FileUploader_B'
import { NoResults } from '../../components/Tables/Misc/NoResults'
import FormInput from '../../components/FormInput'


// Router Dom
import { useNavigate, useParams } from 'react-router-dom'


// Layouts
import AccordionTable from '../../layout/AccordionTable'


// Endpoints
import { getOne } from '../../endpoints/getOne'
import { BASE_URL, PATH_URL } from '../../endpoints'
import { create } from '../../endpoints/create'
import TextareaInput from '../../components/TextareaInput'
import IconFAMDI from '../../components/Tables/Icons/IconFAMDI'
import { historicoData } from '../../helpers/historicoData'
import { ModalHistorico } from '../../components/Historico/ModalHistorico'
import TablaDetalle from '../../components/Historico/TablaDetalle'
import ProductosFiltros from '../../components/AccordionFilter/Productos'
import CheckBoxInput from '../../components/CheckboxInput'
import SelectFilter from '../../components/SelectFilter'
import { idExtractor } from '../../helpers/idExtractor'


const CONSTANT = {
  redirectListado: '/eventos',
  queryUrlGetOne: getOne.eventos,
  updateMenu: getOne.menus,
  updateEtiqueta: getOne.etiquetas,
  createEventoBanner: create.eventos_banner,
  createEventoFaq: create.eventos_faq,
  createEventoPasosCompra: create.eventos_pasos_compra,
  redirectUpdate: (id) => `/eventos/update/${id}`,
  redirectUpdateEtiqueta: (id) => `/etiquetas/update/${id}`,
  urlAsignarProductos: BASE_URL + PATH_URL + '/etiquetas-productos',
  urlEliminacionMasiva: BASE_URL + PATH_URL + '/etiquetas-productos/eliminacion-masiva',

  title: 'Eventos',
  AMtitle: 'Evento',
  faqFormInitalState: { evento_id: '', titulo: '', informacion: '', estatus: true },
  imagen: (data) => `${BASE_URL}storage/${data?.imagen}` ?? '/placeholder.png',
  pasosCompraFormInitalState: { evento_id: '', titulo: '', descripcion: '', numero: '', estatus: true },
  modelo: 'App\\Models\\NeuEvento',

  eliminacionMasivaForm: { status: false, productos: [] },
  productsSearch: { search: '', filter: [] },

  datatableInputsInitialState: {
    productos_encontrados: {
      nombre: '',
      sku: ''
    },
    productos_seleccionados: {
      filtro: '',
    }
  },
  resultados_productos: {
    encontrados: [],
    encontrados_filtrados: [],
    seleccionados: [],
    seleccionados_filtrados: []
  },

  fetchTarget: {
    estatus: 'estatus',
    estatus_etiqueta: 'estatus-etiqueta',
    eliminar_producto_etiqueta: 'eliminar-producto-etiqueta',
    estatus_menu: 'estatus-menu',
    banners: 'banners',
    banners_delete: 'banners-delete',
    faq: 'faq',
    pasos_compra: 'pasos_compra',
    evento_delete: 'delete',
    asignacion_masiva: 'asignacion-masiva',
    eliminacion_masiva: 'EMP'
  },
  frontendSelect: [
    { id: 1, nombre: 'Chileneumáticos' },
    { id: 2, nombre: 'Bridgestone' }
  ]
}


// FORMATEO DE RESPUESTAS
const productosFormatter = (arr) => {
  if (typeof arr !== 'object') return

  return arr?.map(el => ({
    ...el,
    estatus: 1,
    fecha_hora_inicio: '',
    fecha_hora_final: '',
    filter_params: `
    ${el?.nombre}
    ${el?.sku}
    `
  }))
}


const fetchData = async (setData, setHistorico, id, dispatch, redirect, setProductosAM) => {
  return await axios(CONSTANT.queryUrlGetOne(id),
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(async ({ data }) => {
      let urlAM = CONSTANT.urlAsignarProductos + `/visualizar?limit=99999&asignacion_masiva=1`
      setData(data)
      historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))

      await axios(urlAM, {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: { etiquetas_ids: [data?.etiqueta_id] }
      })
        .then(({ data }) => {
          setProductosAM(data?.etiquetas_productos)
        })
        .catch(err => {
          console.error(err);
          const { errores } = err.response.data
          let detalles = []
          Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

          const toasterContent = {
            title: `
              Operación fallida
              (${err.response.status} - ${err.response.statusText})
            `,
            html: `
              <b>Detalle: </b>
              ${detalles.map(error => `<br /><i>-${error}</i>`)}
            `,
            icon: 'error'
          }
          dispatch(fireToaster(toasterContent))
        })
    })
    .catch(err => {
      console.error(err)
      const toasterContent = {
        title: 'Registro no encontrado',
        icon: 'warning'
      }

      dispatch(fireToaster(toasterContent))
      redirect(CONSTANT.redirectListado)
    })
}


const Detalle = () => {
  const [data, setData] = useState(null)
  const [imagenMovilBanner, setImagenMovilBanner] = useState([])
  const [imagenEscritorioBanner, setImagenEscritorioBanner] = useState([])
  const [modalDisplay, setModalDisplay] = useState(false)
  const [modalData, setModalData] = useState({ target: '' })
  const [bannerForm, setBannerForm] = useState({ frontend: '1-Chileneumáticos', nombre: '' })
  const [faqForm, setFaqForm] = useState(CONSTANT.faqFormInitalState)
  const [pasosCompraForm, setPasosCompraForm] = useState(CONSTANT.pasosCompraFormInitalState)
  const { id } = useParams()
  const dispatch = useDispatch()
  const redirect = useNavigate()
  const [historico, setHistorico] = useState(null)
  const [modal, setModal] = useState({ id: null, display: false, data: null })

  const [AM, setAM] = useState(false)
  const [ProductosAM, setProductosAM] = useState([])
  const { resultados: reduxResultadosProductos } = useSelector(state => state.productos)
  const [productos, setProductos] = useState(CONSTANT.resultados_productos)
  const [inputsDatatable, setInputsDatatables] = useState(CONSTANT.datatableInputsInitialState)
  const [eliminacionMasiva, setEliminacionMasiva] = useState(CONSTANT.eliminacionMasivaForm)
  const [productsSearch, setProductsSearch] = useState(CONSTANT.productsSearch);

  // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
  useEffect(() => {
    fetchData(setData, setHistorico, id, dispatch, redirect, setProductosAM)

    return () => {
      setData(null)
    }
  }, [id, dispatch, redirect])


  const handleInputChange = (e) => {
    const { name, value } = e.target

    if (name.includes(CONSTANT.fetchTarget.banners)) {
      const nameKey = name.split('-')[0]

      setBannerForm({
        ...bannerForm,
        [nameKey]: value
      })
    }

    if (name.includes(CONSTANT.fetchTarget.faq)) {
      const nameKey = name.split('-')[0]

      setFaqForm({
        ...faqForm,
        [nameKey]: value
      })
    }

    if (name.includes(CONSTANT.fetchTarget.pasos_compra)) {
      const nameKey = name.split('-')[0]

      setPasosCompraForm({
        ...pasosCompraForm,
        [nameKey]: value
      })
    }

    if (name.includes(CONSTANT.fetchTarget.eliminacion_masiva)) {
      const id = Number(name.split('-')[1])

      setEliminacionMasiva({
        ...eliminacionMasiva,
        productos: value
          ? [...eliminacionMasiva.productos, id]
          : eliminacionMasiva.productos.filter(p => p !== id)
      })
    }
  }


  const handleModal = (target, data = null) => {
    if (target === CONSTANT.fetchTarget.banners) {
      setModalData({ target: CONSTANT.fetchTarget.banners })
      setBannerForm({
        ...bannerForm,
      })
    }

    if (target === CONSTANT.fetchTarget.faq) {
      setModalData({ target: CONSTANT.fetchTarget.faq })
      setFaqForm({
        ...faqForm
      })
    }

    if (target === 'faq-update') {
      setModalData({ target: 'faq-update' })
      setFaqForm({
        id: data?.id,
        titulo: data?.titulo,
        informacion: data?.informacion,
        estatus: data?.estatus,
        evento_id: Number(data?.id)
      })
    }

    if (target === CONSTANT.fetchTarget.pasos_compra) {
      setModalData({ target: CONSTANT.fetchTarget.pasos_compra })
      setPasosCompraForm({
        ...pasosCompraForm
      })
    }

    if (target === 'pasos_compra-update') {
      setModalData({ target: 'pasos_compra-update' })
      setPasosCompraForm({
        id: data?.id,
        titulo: data?.titulo,
        numero: data?.numero,
        descripcion: data?.descripcion,
        estatus: data?.estatus,
        evento_id: Number(data?.id)
      })
    }

    setModalDisplay(true)
  }


  // FUNCIÓN MANEJADORA PARA ACTUALIZACIÓN DEL ESTATUS
  const handleFetch = async (objData, target) => {
    let config, fetchUrl

    if (target === CONSTANT.fetchTarget.estatus) {
      let estatus = objData?.estatus ? 0 : 1

      config = {
        method: 'PUT',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        data: {
          estatus,
          fecha_hora_inicio: data?.fecha_hora_inicio,
          fecha_hora_final: data?.fecha_hora_final
        }
      }
      fetchUrl = CONSTANT.queryUrlGetOne(id)
    }

    if (target === CONSTANT.fetchTarget.estatus_etiqueta) {
      let estatus = objData?.estatus ? 0 : 1

      config = {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
          'Content-Type': 'multipart/form-data'
        },
        data: {
          estatus,
          _method: 'PUT'
        }
      }
      fetchUrl = CONSTANT.updateEtiqueta(objData?.id)
    }

    if (target === CONSTANT.fetchTarget.eliminacion_masiva) {
      if (!window.confirm('¿Está seguro que desea eliminar los registros?')) return
      console.log(objData);
      config = {
        method: 'DELETE',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        },
        data: { id: objData }
      }
      fetchUrl = CONSTANT.urlEliminacionMasiva
    }

    if (target === 'estatus-menu') {
      let estatus = objData?.estatus ? 0 : 1

      config = {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        data: {
          estatus,
          _method: 'PUT'
        }
      }
      fetchUrl = CONSTANT.updateMenu(objData?.id)
    }

    if (target === CONSTANT.fetchTarget.banners) {
      const isChnFront = idExtractor(objData.frontend) === 1
      if (!imagenMovilBanner?.length) return dispatch(fireToaster({ title: 'La imagen móvil es necesaria', icon: 'warn' }))
      if (!imagenEscritorioBanner?.length) return dispatch(fireToaster({ title: 'La imagen móvil es necesaria', icon: 'warn' }))
      if (!bannerForm?.nombre?.length) return dispatch(fireToaster({ title: 'El nombre es necesario', icon: 'warn' }))

      let data = new FormData()
      data.append(isChnFront ? 'evento_id' : 'evento_bridgestone_id', id)
      data.append('nombre', objData?.nombre)
      data.append('imagen_movil', imagenMovilBanner?.[0]?.file)
      data.append('imagen_escritorio', imagenEscritorioBanner?.[0]?.file)
      
      config = {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
          'Content-Type': 'multipart/form-data'
        },
        data
      }
      fetchUrl = CONSTANT.createEventoBanner
    }

    if (target === CONSTANT.fetchTarget.banners_delete) {
      if (!window.confirm('¿Está seguro que desea eliminar el banner?')) return

      config = {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: CONSTANT.fetchTarget.evento_delete,
      }
      fetchUrl = CONSTANT.createEventoBanner + `/${objData?.id}`
    }

    if (target.includes(CONSTANT.fetchTarget.faq)) {
      if (!objData?.titulo?.length) return dispatch(fireToaster({ title: 'El título es necesario', icon: 'warn' }))
      if (!objData?.informacion?.length) return dispatch(fireToaster({ title: 'La información es necesaria', icon: 'warn' }))

      config = {
        method: target.includes('faq-update') ? 'PUT' : target.includes('faq-delete') ? CONSTANT.fetchTarget.evento_delete : 'POST',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        data: {
          ...objData,
          evento_id: Number(data?.id)
        }
      }
      fetchUrl = `${CONSTANT.createEventoFaq}${['faq-delete', 'faq-update'].includes(target) ? `/${objData?.id}` : ``}`
    }

    if (target.includes(CONSTANT.fetchTarget.pasos_compra)) {
      if (!objData?.titulo?.length) return dispatch(fireToaster({ title: 'El título es necesario', icon: 'warn' }))
      if (!objData?.descripcion?.length) return dispatch(fireToaster({ title: 'La descripción es necesaria', icon: 'warn' }))
      if (!objData?.numero) return dispatch(fireToaster({ title: 'El número es necesario', icon: 'warn' }))

      config = {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: target.includes('pasos_compra-update') ? 'PUT' : target.includes('pasos_compra-delete') ? CONSTANT.fetchTarget.evento_delete : 'POST',
        data: {
          ...objData,
          evento_id: Number(data?.id)
        }
      }
      fetchUrl = `${CONSTANT.createEventoPasosCompra}${['pasos_compra-delete', 'pasos_compra-update'].includes(target) ? `/${objData?.id}` : ``}`
    }

    if (target === CONSTANT.fetchTarget.evento_delete) {
      if (!window.confirm('¿Está seguro que desea eliminar el registro?')) return
      config = {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: CONSTANT.fetchTarget.evento_delete
      }
      fetchUrl = CONSTANT.queryUrlGetOne(id)
    }

    if (target === CONSTANT.fetchTarget.asignacion_masiva) {
      fetchUrl = CONSTANT.urlAsignarProductos

      if (!productos.seleccionados.length) {
        const toasterContent = {
          title: 'No hay productos seleccionados',
          text: `Por favor, seleccione los productos para proseguir`,
          icon: 'info'
        }
        return dispatch(fireToaster(toasterContent))
      }

      if (!data?.etiqueta_id) {
        const toasterContent = {
          title: 'No hay una etiqueta asginada para este evento',
          text: `Por favor, asigne una etiqueta para proseguir`,
          icon: 'info'
        }
        return dispatch(fireToaster(toasterContent))
      }

      let asignaciones = []
      productos.seleccionados.forEach((el) => {
        let producto = {}

        Object.entries(el).forEach(([k, v]) => {
          if (['estatus'].includes(k)) producto = { ...producto, [k]: Number(v) }

          if (['fecha_hora_inicio', 'fecha_hora_final'].includes(k)) producto = { ...producto, [k]: data[k] }

          if (k === 'id') producto = { ...producto, producto_id: v }
        })

        asignaciones = [...asignaciones, producto]
      })
      config = {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: {
          etiqueta_id: data.etiqueta_id,
          asignaciones
        }
      }
    }

    if (target === CONSTANT.fetchTarget.eliminar_producto_etiqueta) {
      fetchUrl = CONSTANT.urlAsignarProductos + `/eliminacion-masiva`

      config = {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'DELETE',
        data: { id: [objData?.neu_etiqueta_producto?.[0]?.id] }
      }
    }

    await axios(fetchUrl, config)
      .then(res => {
        if (target === CONSTANT.fetchTarget.evento_delete) return redirect(CONSTANT.redirectListado)

        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: registro actualizado con éxito`,
          icon: 'success'
        }

        if (target === CONSTANT.fetchTarget.eliminacion_masiva) {
          setProductsSearch(CONSTANT.productsSearch)
        }

        dispatch(fireToaster(toasterContent))
        setData(null)
        setModalDisplay(false)
        setFaqForm(CONSTANT.faqFormInitalState)
        setPasosCompraForm(CONSTANT.pasosCompraFormInitalState)
        setImagenMovilBanner([])
        setImagenEscritorioBanner([])
        setAM(false)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
      })
      .finally(async () => {
        setEliminacionMasiva(CONSTANT.eliminacionMasivaForm)
        await fetchData(setData, setHistorico, id, dispatch, redirect, setProductosAM)
      })
  }


  // FUNCION MANEJADORA DE LA DATA DEL MODAL
  const handleModalHistorico = (data) => {
    const { id, anterior, nuevo } = data

    setModal({
      display: true,
      id,
      data: {
        anterior,
        nuevo
      }
    })
  }

  // ---- SECCION ASIGNACION MASIVA ----

  useEffect(() => {
    setProductos((productos) => ({
      ...productos,
      encontrados: productosFormatter(reduxResultadosProductos?.data ?? reduxResultadosProductos),
      encontrados_filtrados: productosFormatter(reduxResultadosProductos?.data ?? reduxResultadosProductos)
    }))
  }, [reduxResultadosProductos])


  useEffect(() => {
    const encontrados_filtrados = productos.encontrados?.filter(({ nombre, sku }) => nombre.toLowerCase().includes(inputsDatatable.productos_encontrados?.nombre?.toLowerCase()) && sku.toLowerCase().includes(inputsDatatable.productos_encontrados?.sku?.toLowerCase()))
    setProductos((productos) => ({ ...productos, encontrados_filtrados }))
  }, [productos.encontrados, inputsDatatable.productos_encontrados])


  useEffect(() => {
    const seleccionados_filtrados = productos.seleccionados?.filter(({ filter_params }) => filter_params.toLowerCase().includes(inputsDatatable.productos_seleccionados?.filtro?.toLowerCase()))
    setProductos((productos) => ({ ...productos, seleccionados_filtrados }))
  }, [productos.seleccionados, inputsDatatable.productos_seleccionados])


  const handleProductoSeleccionado = (e) => {
    const { name } = e.target?.dataset ?? e.target
    const [id] = name.split('%-%')
    const isSelected = productos.seleccionados.find(obj => String(obj.id) === id)
    const selected = productos.encontrados.find(obj => String(obj.id) === id)

    setProductos({
      ...productos,
      seleccionados: isSelected
        ? productos.seleccionados.filter(obj => (obj.id !== isSelected.id))
        : [...productos.seleccionados, selected],
    })
  }


  const handleSeleccionarTodo = (remove = false) => {
    setProductos({
      ...productos,
      seleccionados: !remove ? productos.encontrados_filtrados : []
    })
  }


  // FUNCION MANEJADORA DE LOS INPUTS DE LA DATATABLE DE PRODUCTOS SELECCIONADOS
  const handleDatatableInputs = (e, isRemove = false) => {
    const { value, name } = e.target
    const [target, key] = name.split('-')

    setInputsDatatables({
      ...inputsDatatable,
      [target]: {
        ...inputsDatatable[target],
        [key]: !isRemove ? value : ''
      }
    })
  }


  const handleSelectAll = () => {
    const filtered = productsSearch.filter.length > 0 ? ProductosAM.filter(pr => productsSearch.filter.includes(pr.sku || pr.producto)) : ProductosAM

    setEliminacionMasiva({ productos: filtered.map(({ neu_etiqueta_producto }) => Number(neu_etiqueta_producto?.[0]?.id)), status: true })
  }


  const handleProductsSearch = (key, value) => setProductsSearch(current => ({ ...current, [key]: value }))


  const handleSearch = (e) => {
    e.preventDefault();

    handleProductsSearch('filter', productsSearch.search.split('|'))
  }


  const handleClean = () => setProductsSearch(CONSTANT.productsSearch)


  const handleShowProducts = () => {
    return productsSearch.filter.length > 0 ? ProductosAM.filter(pr => productsSearch.filter.includes(pr.sku || pr.producto)) : ProductosAM
  }


  return (
    <div className='row'>

      <ModalHistorico modal={modal} fnHandleModal={setModal} />

      <Modal display={modalDisplay} handleValue={() => setModalDisplay(false)}>
        {/* BANNERS MODAL */}
        {modalData?.target?.includes(CONSTANT.fetchTarget.banners) &&
          <>
            <p className='fw-bolder fs-3 text-center'>Crear banner</p>

            <div className='row'>
              <SelectFilter
                labelText='* Frontend'
                optionObj={CONSTANT.frontendSelect.map(({ id, nombre }) => (({ id, name: nombre })))}
                name='frontend-banners'
                size='col-12'
                sizeDesk='col-md-6'
                handleValue={handleInputChange}
                value={bannerForm.frontend.split('-')[1]}
              />

              <FormInput
                name='nombre-banners'
                handleValue={handleInputChange}
                labelText='* Nombre'
                value={bannerForm.nombre}
              />

              <FileUploaderB value={imagenMovilBanner} handleValue={setImagenMovilBanner} labelText='* Imagen móvil' />

              <FileUploaderB value={imagenEscritorioBanner} handleValue={setImagenEscritorioBanner} labelText='* Imagen escritorio' />

              <div>
                <Button
                  className='mx-auto my-1'
                  type="submit"
                  text="Crear"
                  color="primary"
                  icon='Check'
                  onClick={() => handleFetch(bannerForm, CONSTANT.fetchTarget.banners)}
                />
              </div>
            </div>
          </>
        }

        {/* FAQ MODAL */}
        {modalData?.target?.includes(CONSTANT.fetchTarget.faq) &&
          <>
            <p className='fw-bolder fs-3 text-center'>{modalData?.target?.includes('faq-update') ? 'Editar' : 'Crear'} pregunta frecuente</p>

            <div className='row'>
              <Switch
                name="estatus-faq"
                value={faqForm?.estatus}
                labelText="Estatus"
                handleValue={handleInputChange}
                size="col-12"
              />

              <FormInput
                labelText="* Título"
                name='titulo-faq'
                handleValue={handleInputChange}
                value={faqForm?.titulo}
              />

              <TextareaInput
                labelText="* Información"
                name='informacion-faq'
                handleValue={handleInputChange}
                value={faqForm?.informacion}
              />

              <div>
                <Button
                  className='mx-auto my-1'
                  type="submit"
                  text={modalData?.target?.includes('faq-update') ? 'Guardar' : 'Crear'}
                  color="primary"
                  icon='Check'
                  onClick={() => handleFetch(faqForm, modalData?.target)}
                />
              </div>
            </div>
          </>
        }

        {/* PASOS COMPRA MODAL */}
        {modalData?.target?.includes(CONSTANT.fetchTarget.pasos_compra) &&
          <>
            <p className='fw-bolder fs-3 text-center'>{modalData?.target?.includes('pasos_compra-update') ? 'Editar' : 'Crear'} paso de compra</p>

            <div className='row'>
              <Switch
                name="estatus-pasos_compra"
                value={pasosCompraForm?.estatus}
                labelText="Estatus"
                handleValue={handleInputChange}
                size="col-12"
              />

              <FormInput
                labelText="* Título"
                name='titulo-pasos_compra'
                handleValue={handleInputChange}
                value={pasosCompraForm?.titulo}
              />

              <FormInput
                labelText="* Número"
                name='numero-pasos_compra'
                handleValue={handleInputChange}
                value={pasosCompraForm?.numero}
              />

              <TextareaInput
                labelText="* Descripción"
                name='descripcion-pasos_compra'
                handleValue={handleInputChange}
                value={pasosCompraForm?.descripcion}
              />

              <div>
                <Button
                  className='mx-auto my-1'
                  type="submit"
                  text={modalData?.target?.includes('pasos_compra-update') ? 'Guardar' : 'Crear'}
                  color="primary"
                  icon='Check'
                  onClick={() => handleFetch(pasosCompraForm, modalData?.target)}
                />
              </div>
            </div>
          </>
        }

      </Modal>

      <div className='col-12 mx-auto'>
        <div className='col mb-50'>
          <div className='d-flex gap-1'>
            <div className='col'>
              <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
            </div>
            <Button text='Asignar productos' icon={AM ? 'X' : 'Plus'} color='warning' onClick={() => setAM(!AM)} />
            <Button text='Editar etiqueta' icon='Settings' color='info' isLink url={CONSTANT.redirectUpdateEtiqueta(data?.etiqueta_id)} />
            <Button text='Editar' icon='Settings' color='success' isLink url={CONSTANT.redirectUpdate(id)} />
            <Button text='Borrar' icon='Trash' color='danger' onClick={() => handleFetch(data, CONSTANT.fetchTarget.evento_delete)} />
          </div>
        </div>

        {data
          ? <div className='row'>

            <div className='col-6'>
              <Accordion
                title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
                isOpen
                classes='col-12 mb-2'
              >
                <AccordionDetailData dataKey={'ID'} value={data?.id} />
                <AccordionDetailData dataKey={'Nombre'} value={data?.nombre ?? 'N/A'} />
                <AccordionDetailData dataKey={'Descripción'} value={data?.descripcion ?? 'N/A'} />
                <AccordionDetailData dataKey={'Fechas'} value={
                  <div className='d-flex flex-column'>
                    <span>Inicio: <b>{`${data?.fecha_hora_inicio.split(' ')[0]} ${data?.fecha_hora_inicio.split(' ')[1].split('.')[0]}` ?? "N/A"}</b></span>
                    <span>Final: <b>{`${data?.fecha_hora_final.split(' ')[0]} ${data?.fecha_hora_final.split(' ')[1].split('.')[0]}` ?? "N/A"}</b></span>
                  </div>
                } />
                <AccordionDetailData dataKey={'Estatus'} value={
                  <Switch
                    value={data?.estatus}
                    labelText={""}
                    margin='my-0'
                    handleValue={() => handleFetch(data, CONSTANT.fetchTarget.estatus)}
                  />
                } />
                <AccordionDetailData dataKey={'Creación'} value={data?.created_at?.split('T')[0] ?? 'N/A'} />
                <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at?.split('T')[0] ?? 'N/A'} last />
              </Accordion>
            </div>

            <div className='col-6'>
              {/* ETIQUETA */}
              <Accordion

                title={<h3 className='m-0 w-100 text-center text-white'>Datos etiqueta</h3>}
                isOpen
                classes='col-12 mb-2'
              >
                <AccordionDetailData dataKey={'ID'} value={data?.etiqueta?.id} />
                <AccordionDetailData dataKey={'Nombre'} value={data?.etiqueta?.nombre ?? 'N/A'} />
                <AccordionDetailData dataKey={'Descripción'} value={data?.etiqueta?.descripcion ?? 'N/A'} />
                <AccordionDetailData dataKey={'Fechas'} value={
                  <div className='d-flex flex-column'>
                    <span>Inicio: <b>{`${data?.etiqueta?.fecha_hora_inicio?.split('T')[0]} ${data?.etiqueta?.fecha_hora_inicio.split('T')[1]?.split('.')[0]}` ?? "N/A"}</b></span>
                    <span>Final: <b>{`${data?.etiqueta?.fecha_hora_final?.split('T')[0]} ${data?.etiqueta?.fecha_hora_final.split('T')[1]?.split('.')[0]}` ?? "N/A"}</b></span>
                  </div>
                } />
                <AccordionDetailData dataKey={'Imagen'} value={
                  <img className="height-listado" src={CONSTANT?.imagen(data?.etiqueta)} alt={data?.etiqueta?.nombre} />
                } last />
              </Accordion>

            </div>

            {/* MENU */}
            {data?.menus?.map(menu => (
              <Accordion
                title={<h3 className='m-0 w-100 text-center text-white text-capitalize'>Menú {menu?.frontend?.nombre}</h3>}
                isOpen
                classes='col-12 col-md-6 mb-1'
                key={`event-menu-${menu.id}`}
              >
                <AccordionDetailData dataKey={'ID'} value={menu?.id} />
                <AccordionDetailData dataKey={'Título'} value={menu?.titulo ?? 'N/A'} />
                <AccordionDetailData dataKey={'Frontend'} value={<span className={`tag px-50 ${menu?.frontend?.id === 2 ? 'bridgestone' : 'chileneumaticos'} mx-auto mt-50`}>{menu?.frontend?.nombre ?? 'chileneumaticos'}</span>} />
                <AccordionDetailData dataKey={'Descripción'} value={menu?.descripcion ?? 'N/A'} />
                <AccordionDetailData dataKey={'Ícono'} value={
                  menu?.icono?.icono
                    ? <IconFAMDI iconData={menu} module={menu?.icono?.libreria} />
                    : "N/A"
                } last />
              </Accordion>
            ))}

            {!AM
              ? <>

                {/* PRODUCTOS */}
                <AccordionTable
                  title={`Productos`}
                  classes='col-12 col-md-12 mb-1'
                >
                  <Table style={{ maxHeight: '1000px' }} pb={false}>
                    <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                      <tr role="row">
                        <th>ID</th>
                        <th>
                          <div>
                            <span>Descripción producto</span>

                            {
                              eliminacionMasiva.status && (
                                <form className='d-flex gap-1' onSubmit={handleSearch}>
                                  <FormInput
                                    type='string'
                                    placeholder='Filtrar productos'
                                    name='filter'
                                    value={productsSearch.search}
                                    handleValue={(e) => handleProductsSearch('search', e.target.value)}
                                    margin='m-0'
                                    classes='flex-grow-1'
                                  />

                                  <Button type='submit' text='Buscar' />

                                  <Button type='button' text='Limpiar' color='danger' onClick={handleClean} />
                                </form>
                              )
                            }
                          </div>
                        </th>
                        <th>Estatus</th>
                        <th>
                          {eliminacionMasiva.status
                            ?
                            <div className='d-flex gap-50 mx-auto w-fit'>
                              <div className='d-flex flex-column gap-25'>
                                <Button text='Todos' icon='Check' className='p-25 px-50' onClick={handleSelectAll} />
                                <Button text='Deseleccionar' icon='X' className='p-25 px-50' onClick={() => setEliminacionMasiva({ productos: [], status: true })} />
                              </div>

                              <div className='d-flex flex-column gap-25'>
                                <Button text='Cancelar' icon='X' color='warning' className='p-25 px-50' onClick={() => setEliminacionMasiva({ productos: [], status: false })} />
                                <Button text='Borrar' icon='Trash' color='danger' className='p-25 px-50' onClick={() => handleFetch(eliminacionMasiva.productos, CONSTANT.fetchTarget.eliminacion_masiva)} />

                              </div>
                            </div>

                            :
                            <Button text='Borrar varios' icon='Trash' color='danger' className='p-25 px-50 mx-auto' onClick={() => setEliminacionMasiva({ ...eliminacionMasiva, status: true })} />
                          }

                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ProductosAM?.length

                        ? handleShowProducts()?.map(item => (
                          <tr
                            className={`odd`}
                            key={`producto-${item?.id}-${item?.producto}`}
                          >
                            <td>{item?.neu_etiqueta_producto?.[0]?.id ?? 'N/A'}</td>
                            <td>
                              <div className='d-flex flex-column'>
                                <span className={`tag px-50 ${item.publicado ? 'success' : 'red'}`}>{item.publicado ? 'Publicado' : 'No publicado'}</span>
                                <a
                                  href={`/productos/detalle/${item?.id}`}
                                  rel='noreferrer'
                                  target='_blank'
                                ><b>{item?.producto ?? 'Error en descripción'}</b></a>
                                <span>SKU: <b>{item?.sku ?? 'Error en SKU'}</b></span>
                              </div>
                            </td>

                            <td>
                              <span className={`tag px-50 ${item?.neu_etiqueta_producto?.[0]?.estatus ? 'success' : 'red'}`}>{item?.neu_etiqueta_producto?.[0]?.estatus ? 'Publicado' : 'No publicado'}</span>
                            </td>
                            <td>
                              {eliminacionMasiva.status
                                ? <CheckBoxInput
                                  labelText=''
                                  name={`${CONSTANT.fetchTarget.eliminacion_masiva}-${item?.neu_etiqueta_producto?.[0]?.id}`}
                                  handleValue={handleInputChange}
                                  classes='mx-auto w-fit'
                                  value={eliminacionMasiva.productos.find((id) => Number(id) === Number(item?.neu_etiqueta_producto?.[0]?.id)) ? true : false}
                                />
                                : <Button icon='X' color='danger' className='p-25 mx-auto' onClick={() => handleFetch(item, CONSTANT.fetchTarget.eliminar_producto_etiqueta)} />
                              }
                            </td>
                          </tr>
                        ))

                        : <tr><td colSpan={4}><NoResults /></td></tr>
                      }
                    </tbody>
                  </Table>
                </AccordionTable>

                {/* FAQ */}
                <AccordionTable
                  classes='mb-2 col-12'
                  title='Preguntas frecuentes'
                >
                  <Table pb={false}>
                    <thead className="table-light">
                      <tr role="row">
                        <th className='col-1'>ID</th>
                        <th className='col'>Título</th>
                        <th className='col'>Información</th>
                        <th className='col-1'>Estatus</th>
                        <th className='col-1'>
                          <Button icon='Plus' color='success' text='Agregar' onClick={() => handleModal(CONSTANT.fetchTarget.faq)} className='p-25' />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.faq_subcategoria?.faqs?.length

                        ? data?.faq_subcategoria?.faqs?.map(faq => (

                          <tr key={`imagenes-${faq?.id}-${faq?.titulo ?? '?'}`}>
                            <td>{faq?.id}</td>

                            <td>{faq?.titulo}</td>

                            <td style={{ maxWidth: '200px' }}>{faq?.informacion}</td>

                            <td>
                              <Switch
                                value={faq?.estatus ? true : false}
                                labelText={null}
                                classes='mx-auto mt-0 mb-0'
                                handleValue={() => window.alert('No implementado')}
                              />
                            </td>

                            <td>
                              <Button icon='Settings' color='warning' text='Editar' onClick={() => handleModal('faq-update', faq)} className='p-25 mb-25' />
                              <Button icon='X' color='danger' text='Borrar' onClick={() => handleFetch(faq, 'faq-delete')} className='p-25' />
                            </td>
                          </tr>
                        ))

                        : <tr><td colSpan={6}><NoResults /></td></tr>
                      }
                    </tbody>
                  </Table>
                </AccordionTable>

                {/* PASOS COMPRA */}
                <AccordionTable
                  classes='mb-2 col-12'
                  title='Pasos de compra'
                >
                  <Table pb={false}>
                    <thead className="table-light">
                      <tr role="row">
                        <th className='col-1'>ID</th>
                        <th className='col'>Título</th>
                        <th className='col'>Número</th>
                        <th className='col'>Descripción</th>
                        <th className='col-1'>Estatus</th>
                        <th className='col-1'>
                          <Button icon='Plus' color='success' text='Agregar' onClick={() => handleModal(CONSTANT.fetchTarget.pasos_compra)} className='p-25' />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.pasos_compra?.length

                        ? data?.pasos_compra?.map(paso => (

                          <tr key={`imagenes-${paso?.id}-${paso?.titulo ?? '?'}`}>
                            <td>{paso?.id}</td>

                            <td>{paso?.titulo}</td>

                            <td>{paso?.numero}</td>

                            <td style={{ maxWidth: '200px' }}>{paso?.descripcion}</td>

                            <td>
                              <Switch
                                value={paso?.estatus ? true : false}
                                labelText={null}
                                classes='mx-auto mt-0 mb-0'
                                handleValue={() => window.alert('No implementado')}
                              />
                            </td>

                            <td>
                              <Button icon='Settings' color='warning' text='Editar' onClick={() => handleModal('pasos_compra-update', paso)} className='p-25 mb-25' />
                              <Button icon='X' color='danger' text='Borrar' onClick={() => handleFetch(paso, 'pasos_compra-delete')} className='p-25' />
                            </td>
                          </tr>
                        ))

                        : <tr><td colSpan={6}><NoResults /></td></tr>
                      }
                    </tbody>
                  </Table>
                </AccordionTable>

                {/* BANNER */}
                <AccordionTable
                  classes='mb-2'
                  title='Banner'
                >
                  <Table pb={false}>
                    <thead className="table-light">
                      <tr role="row">
                        <th className='col-1'>ID</th>
                        <th className='col'>Nombre</th>
                        <th className='col'>Imagen escritorio</th>
                        <th className='col'>Imagen móvil</th>
                        <th className='col-1'>Estatus</th>
                        <th className='col-1'>
                          <Button icon='Plus' disabled={data?.banner?.id ? true : false} color='success' text='Adjuntar' onClick={() => handleModal(CONSTANT.fetchTarget.banners, data)} className='p-25' />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.banner

                        ? <tr key={`imagenes-${data?.banner?.id}-${data?.banner?.nombre ?? '?'}`}>
                          <td>{data?.banner?.id}</td>

                          <td>{data?.banner?.nombre}</td>

                          <td>
                            <img
                              key={`producto-imagen-${data?.banner?.id}`}
                              className='height-listado'
                              src={BASE_URL + 'storage/' + data?.banner?.imagen_escritorio}
                              alt={`producto-imagen-escritorio-${data?.banner?.id}`}
                            />
                          </td>

                          <td>
                            <img
                              key={`producto-imagen-${data?.banner?.id}`}
                              className='height-listado'
                              src={BASE_URL + 'storage/' + data?.banner?.imagen_movil}
                              alt={`producto-imagen-movil-${data?.banner?.id}`}
                            />
                          </td>

                          <td>
                            <Switch
                              value={data?.banner?.estatus ? true : false}
                              labelText={null}
                              classes='mx-auto mt-0 mb-0'
                              handleValue={() => window.alert('No implementado')}
                            />
                          </td>

                          <td>
                            <Button icon='X' color='danger' text='Eliminar' onClick={() => handleFetch(data?.banner, CONSTANT.fetchTarget.banners_delete)} className='p-25' />
                          </td>
                        </tr>

                        : <tr><td colSpan={6}><NoResults /></td></tr>
                      }
                    </tbody>
                  </Table>
                </AccordionTable>

                {/* BANNER BRIDGESTONE */}
                <AccordionTable
                  classes='mb-2'
                  title='Banner Bridgestone'
                >
                  <Table pb={false}>
                    <thead className="table-light">
                      <tr role="row">
                        <th className='col-1'>ID</th>
                        <th className='col'>Nombre</th>
                        <th className='col'>Imagen escritorio</th>
                        <th className='col'>Imagen móvil</th>
                        <th className='col-1'>Estatus</th>
                        <th className='col-1'>
                          <Button icon='Plus' disabled={data?.banner?.id ? true : false} color='success' text='Adjuntar' onClick={() => handleModal(CONSTANT.fetchTarget.banners, data)} className='p-25' />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.banner_bridgestone

                        ? <tr key={`imagenes-${data?.banner_bridgestone?.id}-${data?.banner_bridgestone?.nombre ?? '?'}`}>
                          <td>{data?.banner_bridgestone?.id}</td>

                          <td>{data?.banner_bridgestone?.nombre}</td>

                          <td>
                            <img
                              key={`producto-imagen-${data?.banner_bridgestone?.id}`}
                              className='height-listado'
                              src={BASE_URL + 'storage/' + data?.banner_bridgestone?.imagen_escritorio}
                              alt={`producto-imagen-escritorio-${data?.banner_bridgestone?.id}`}
                            />
                          </td>

                          <td>
                            <img
                              key={`producto-imagen-${data?.banner_bridgestone?.id}`}
                              className='height-listado'
                              src={BASE_URL + 'storage/' + data?.banner_bridgestone?.imagen_movil}
                              alt={`producto-imagen-movil-${data?.banner_bridgestone?.id}`}
                            />
                          </td>

                          <td>
                            <Switch
                              value={data?.banner_bridgestone?.estatus ? true : false}
                              labelText={null}
                              classes='mx-auto mt-0 mb-0'
                              handleValue={() => window.alert('No implementado')}
                            />
                          </td>

                          <td>
                            <Button icon='X' color='danger' text='Eliminar' onClick={() => handleFetch(data?.banner_bridgestone, CONSTANT.fetchTarget.banners_delete)} className='p-25' />
                          </td>
                        </tr>

                        : <tr><td colSpan={6}><NoResults /></td></tr>
                      }
                    </tbody>
                  </Table>
                </AccordionTable>
                <TablaDetalle historicoData={historico} fnHandleModal={handleModalHistorico} />
              </>

              /* ASIGNACION MASIVA */
              : <>
                {/* COMPONENTES DE FILTROS */}
                <ProductosFiltros isAsignacionMasiva />

                {/* PRODUCTOS ENCONTRADOS */}
                <AccordionTable
                  title={`Productos encontrados (${productos.encontrados?.length ?? 0} encontrados / ${productos.encontrados_filtrados?.length ?? 0} filtrados)`}
                  classes='col-12 col-md-6 mb-1'
                >
                  <Table style={{ maxHeight: '1000px' }} pb={false}>
                    <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                      <tr role="row">

                        <th>
                          <div className='d-flex'>
                            <div>
                              <CheckBoxInput
                                labelText='Todos'
                                value={productos.encontrados_filtrados?.length === productos.seleccionados?.length}
                                handleValue={() => handleSeleccionarTodo(productos.encontrados_filtrados?.length === productos.seleccionados?.length)}
                                classes='mt-1 me-1'
                              />
                            </div>

                            <FormInput
                              name='productos_encontrados-nombre'
                              value={inputsDatatable.productos_encontrados.nombre}
                              handleValue={handleDatatableInputs}
                              placeholder='Producto'
                              labelText=''
                              sizeDesk='col'
                              classes='text-capitalize sub-text-3 font-weight-normal'
                              margin='my-auto'
                            />
                          </div>
                        </th>

                        <th>
                          <div className='d-flex'>
                            <FormInput
                              name='productos_encontrados-sku'
                              value={inputsDatatable.productos_encontrados.sku}
                              handleValue={handleDatatableInputs}
                              placeholder='SKU'
                              labelText=''
                              sizeDesk='col'
                              classes='text-capitalize sub-text-3 font-weight-normal'
                              margin='my-auto'
                            />
                          </div>
                        </th>
                      </tr>

                    </thead>
                    <tbody>
                      {productos.encontrados_filtrados?.length

                        ? productos.encontrados_filtrados?.map(data => (
                          <tr
                            className={`odd data-selector ${productos.seleccionados.find(({ id }) => id === data?.id) ? 'selected' : ''}`}
                            key={`producto-${data?.id}-${data?.nombre}`}
                            onClick={handleProductoSeleccionado}
                            data-name={`${data?.id}%-%${data?.nombre}`}
                          >
                            <td data-name={`${data?.id}%-%${data?.nombre}`}>
                              <span className='d-flex flex-column' data-name={`${data?.id}%-%${data?.nombre}`}>
                                <span data-name={`${data?.id}%-%${data?.nombre}`}>{data?.neu_categoria?.nombre}</span>
                                <b data-name={`${data?.id}%-%${data?.nombre}`}>{data?.nombre.toUpperCase()}</b>
                              </span>
                            </td>
                            <td data-name={`${data?.id}%-%${data?.nombre}`}>
                              {data?.sku}
                            </td>
                          </tr>
                        ))

                        : <tr><td colSpan={2}><NoResults /></td></tr>
                      }
                    </tbody>
                  </Table>
                </AccordionTable>


                {/* PRODUCTOS SELECCIONADOS */}
                <AccordionTable
                  title={`Productos seleccionados (${productos.seleccionados?.length ?? 0})`}
                  classes='col-12 col-md-6'
                >
                  <Table style={{ maxHeight: '1000px' }} pb={false}>
                    <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                      <tr role="row">
                        <th className='del pe-0' style={{ maxWidth: '100px' }}>
                          <button
                            onClick={() => handleSeleccionarTodo(true)}
                            className='mt-2 w-fit'
                          >
                            Limpiar
                          </button>
                        </th>

                        <th className='col-md-5'>
                          <div className='row'>
                            <FormInput
                              name='productos_seleccionados-filtro'
                              value={inputsDatatable.productos_seleccionados.filtro}
                              placeholder='Buscar...'
                              labelText='PRODUCTO - SKU'
                              handleValue={handleDatatableInputs}
                              sizeDesk='col-10'
                              classes='text-capitalize sub-text-3 font-weight-normal'
                              margin='my-auto'
                            />

                            <div className='col-2 mt-2 p-0 pt-75'>
                              <Button
                                color={'danger'}
                                icon='Trash'
                                className={'my-auto p-25'}
                                name='a'
                                onClick={(e) => handleDatatableInputs({ target: { name: 'productos_seleccionados-filtro' } }, true)}
                              />
                            </div>
                          </div>
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      {productos.seleccionados_filtrados?.map((data, i) => (
                        <tr className="odd multi-data" key={`${i}-producto-selected-${data.id}-${data.nombre}`}>

                          <td className='col-1'>
                            <div className='d-flex'>
                              <CheckBoxInput
                                classes='mx-auto'
                                labelText=''
                                value={data?.seleccionado}
                                handleValue={handleProductoSeleccionado}
                                name={`${data?.id}%-%${data?.nombre}`}
                              />
                            </div>
                          </td>

                          <td>
                            <b>{data?.nombre.toUpperCase() ?? 'N/A'}</b>
                            <p className='sub-text-3 text-lgray my-0'>{data?.neu_categoria?.nombre ?? 'N/A'}</p>
                            <b className='sub-text-3 text-lgray'>SKU: {data?.sku ?? 'N/A'}</b>
                            <p className='sub-text-3 text-lgray'>$ {data?.precio_final ?? 'N/A'} - Stock: {data?.stock_propio ?? 'N/A'}</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </AccordionTable>

                {/* BOTON */}
                <div className='col'>
                  <div className='d-flex justify-content-center my-2'>
                    <Button text={`Asignar ${CONSTANT.AMtitle} a Productos`} icon='Check' onClick={() => handleFetch({}, CONSTANT.fetchTarget.asignacion_masiva)} />
                  </div>
                </div>
              </>
            }

          </div>

          : <CustomLoader />
        }
      </div>
    </div >

  )
}

export default Detalle
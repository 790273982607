import {
   FILTER_BANCO_ID,
   FILTER_ESTADO_ID,
   FILTER_FECHA_FINAL,
   FILTER_FECHA_INICIO,
   FILTER_MOTIVO_ID,
   FILTER_PEDIDO_ID,
   FILTER_CLEAR,
   RESULTADOS_DEVOLUCIONES,
} from "../redux-types";

const initialState = {
   filtros: {
      pedido_id: '',
      estatus_id: '',
      motivo_id: '',
      banco_id: '',
      fecha_inicial: '',
      fecha_final: '',
   },
   resultados: null
}


export const devolucionesReducer = (state = initialState, action) => {
   switch (action.type) {
      case FILTER_PEDIDO_ID:
         return {
            ...state, filtros: {
               ...state.filtros,
               pedido_id: action.payload
            }
         };

      case FILTER_BANCO_ID:
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus_id: action.payload
            }
         };

      case FILTER_ESTADO_ID:
         return {
            ...state, filtros: {
               ...state.filtros,
               motivo_id: action.payload
            }
         };

      case FILTER_FECHA_FINAL:
         return {
            ...state, filtros: {
               ...state.filtros,
               banco_id: action.payload
            }
         };

      case FILTER_FECHA_INICIO:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_inicial: action.payload
            }
         };

      case FILTER_MOTIVO_ID:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_final: action.payload
            }
         };

      case RESULTADOS_DEVOLUCIONES:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
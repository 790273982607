
// Components
import UsuariosFiltros from "../../components/AccordionFilter/Usuarios";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import UsuariosTable from "../../components/Tables/UsuariosTable";

const CONSTANT = {
	title: 'Usuarios',
	path: 'usuarios',
	create_btn: 'Crear usuario'
}

const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<UsuariosFiltros />

			<UsuariosTable />
		</div>
	);
}

export default Listado;
import { Route, Routes } from "react-router-dom"
import Create from "../../views/ValoresAtributos/Create";
import Detalle from "../../views/ValoresAtributos/Detalle";
import Listado from "../../views/ValoresAtributos/Listado";
import Update from "../../views/ValoresAtributos/Update";


const ValoresAtributosRouter = () => {
   return (
      <Routes>
         <Route path='/' element={<Listado />} />
         <Route path='/create' element={<Create />} />
         <Route path='/update/:id' element={<Update />} />
         <Route path='/detalle/:id' element={<Detalle />} />
      </Routes>
   );
}

export default ValoresAtributosRouter;
import {
   FILTER_ADD_PRODUCTO,
   FILTER_ADD_PRODUCTO_PROVEEDOR,
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   FILTER_REMOVE_PRODUCTO,
   FILTER_REMOVE_PRODUCTO_PROVEEDOR,
   RESULTADOS_PRODUCTOS_PROPIOS_PROVEEDOR,
} from "../redux-types";

export const productoProveedorFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_PRODUCTO_PROVEEDOR, payload: data }
      : { type: FILTER_ADD_PRODUCTO_PROVEEDOR, payload: data }

export const productoFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_PRODUCTO, payload: data }
      : { type: FILTER_ADD_PRODUCTO, payload: data }

export const nombreFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosProductosPropiosProveedor = (data) => ({ type: RESULTADOS_PRODUCTOS_PROPIOS_PROVEEDOR, payload: data })

export const clearFilterProductosPropiosProveedor = () => ({ type: FILTER_CLEAR });

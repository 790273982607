import axios from 'axios'
import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { FILTER_ADD_CATEGORIA, FILTER_NOMBRE } from '../../redux/redux-types'
import { categoriaFilter, clearFilterClavesAtributos, nombreFilter, resultadosClavesAtributos } from '../../redux/actions/clavesAtributos'


// Components
import FormInput from '../FormInput'
import SelectFilter from '../SelectFilter'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'
import { lastPosition } from '../../helpers/lastPosition'
import FiltroAplicado from './FiltroAplicado'


const CONSTANT = {
  redirectUrl: '/claves-atributos',
  title: 'Búsqueda de claves/atributos',
  queryUrlGetAll: getAll.claves_atributos + '/listar',
  queryUrlGetAllCategorias: getAll.categorias + '/buscar',
  reduxClearFilters: (data) => clearFilterClavesAtributos(data),
  reduxSetResults: (data) => resultadosClavesAtributos(data),
  axiosData: (data) => data.length && { filtros: { categoria_id: data.map(el => Number(el.split('-')[0])) } }
}

const ClavesAtributosFiltros = () => {
  const { filtros } = useSelector(state => state.clavesAtributos)
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const [categorias, setCategorias] = useState([])
  const { nombre, categoria_id } = filtros

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    axios(CONSTANT.queryUrlGetAllCategorias, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
      .then(({ data }) => setCategorias(data?.categorias))
      .catch(err => console.error(err))

    axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
      .catch(err => console.error(err))
  }, [dispatch, showing_results])

  // EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
  useEffect(() => {
    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = (reset = false) => {
    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}`, {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST'
      })
        .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
        .catch(err => console.log(err))
    }

    setIsConsulting(true)

    axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}` + queryParamsFormatter({ nombre }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: CONSTANT.axiosData(categoria_id)
      })
      .then(res => {
        dispatch(CONSTANT.reduxSetResults(res.data))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
      })
      .catch(err => {
        console.error(err)

        const { errores } = err.response.data
        let detalles = []

        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_NOMBRE:
        dispatch(nombreFilter(value))
        break;

      case FILTER_ADD_CATEGORIA:
        dispatch(categoriaFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <FormInput
            labelText='Nombre'
            placeholder='Ingrese su búsqueda'
            size='col-12'
            sizeDesk='col-md-4'
            handleValue={(e) => handleAddFilter(e, FILTER_NOMBRE)}
            value={nombre}
          />

          <SelectFilter
            labelText='Categorías'
            placeholder='Selecciona'
            size='col-12'
            sizeDesk='col-md-3'
            optionObj={categorias?.map(({ id, nombre }) => ({ id, name: nombre }))}
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_CATEGORIA)}
            value={lastPosition(categoria_id)}
          />
        </>

        {/* FILTROS APLICADOS - ADICIONALES */}
        <p className='mb-25 text-black'>Filtros aplicados</p>
        <div className='row'>
          <FiltroAplicado array={categoria_id} func={categoriaFilter} title={'Categorías'} />
        </div>

        <hr className='my-1' />
      </ListadoHeader>
    </div>
  )
}

export default ClavesAtributosFiltros
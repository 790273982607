import {
   FILTER_CLEAR,
   FILTER_FECHA_INICIO,
   FILTER_FECHA_FINAL,
   RESULTADOS_NOTAS_VENTAS,
   FILTER_NV,
   FILTER_NP,
   FILTER_FACTURA,
   FILTER_RUT_CLIENTE,
   FILTER_TIPO_DESTINO,
   FILTER_PUNTO_ENTREGA,
   FILTER_DXC,
   FILTER_CODIGO_VENDEDOR,
   FILTER_ESTATUS,
   FILTER_COMUNA_ENTREGA,
   FILTER_PLANIFICACION_INICIO,
   FILTER_PLANIFICACION_FINAL,
   FILTER_CANTIDAD_RESULTADOS,
   FILTER_REMOVE_METODO_ENTREGA,
   FILTER_ADD_METODO_ENTREGA,
   RESULTADOS_PLANIFICACION,
   FILTROS_PERSISTENTES_NOTAS_VENTAS
} from "../redux-types";

export const clearFilterNotasVentas = () => ({ type: FILTER_CLEAR });
export const resultadosNotasVentas = (data) => ({ type: RESULTADOS_NOTAS_VENTAS, payload: data });
export const resultadosPlanificacion = (data) => ({ type: RESULTADOS_PLANIFICACION, payload: data });
export const filtrosPersistentesNotasVentas = (data) => ({ type: FILTROS_PERSISTENTES_NOTAS_VENTAS, payload: data })

// body
export const metodoEntregaFilter = (data, isRemove) =>
   isRemove
      ? { type: FILTER_REMOVE_METODO_ENTREGA, payload: data }
      : { type: FILTER_ADD_METODO_ENTREGA, payload: data };
export const nvFilter = (data) => ({ type: FILTER_NV, payload: data })
export const npFilter = (data) => ({ type: FILTER_NP, payload: data })
export const facturaFilter = (data) => ({ type: FILTER_FACTURA, payload: data })
export const rutClienteFilter = (data) => ({ type: FILTER_RUT_CLIENTE, payload: data })
export const destinoFilter = (data) => ({ type: FILTER_TIPO_DESTINO, payload: data })
export const puntoEntregaFilter = (data) => ({ type: FILTER_PUNTO_ENTREGA, payload: data })
export const dxcFilter = (data) => ({ type: FILTER_DXC, payload: data })
export const codigoVendedorFilter = (data) => ({ type: FILTER_CODIGO_VENDEDOR, payload: data })
export const estatusFilter = (data) => ({ type: FILTER_ESTATUS, payload: data })
export const comunaEntregaFilter = (data) => ({ type: FILTER_COMUNA_ENTREGA, payload: data })
export const fechaDesdeFilter = (data) => ({ type: FILTER_FECHA_INICIO, payload: data })
export const fechaHastaFilter = (data) => ({ type: FILTER_FECHA_FINAL, payload: data })
export const planificacionDesdeFilter = (data) => ({ type: FILTER_PLANIFICACION_INICIO, payload: data })
export const planificacionHastaFilter = (data) => ({ type: FILTER_PLANIFICACION_FINAL, payload: data })
export const cantidadResultadosFilter = (data) => ({ type: FILTER_CANTIDAD_RESULTADOS, payload: data })


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fas from '@fortawesome/free-solid-svg-icons';
import * as far from '@fortawesome/free-regular-svg-icons';
import * as MDI from '@mdi/js';
import * as fab from '@fortawesome/free-brands-svg-icons';
import Icon from '@mdi/react'
import React from 'react'

const iconsFA = { fas, far, fab }


const IconFAMDI = ({ iconData, module }) => {
   return (
      module === 'fa'
         ? <div className='icon-picker-square-listed rounded-2 m-25'>
            <div className='d-flex h-100 p-25'>
               <FontAwesomeIcon
                  className='m-auto h-100 w-100'
                  icon={iconsFA[iconData?.neu_icono?.fuente ?? iconData?.icono?.fuente][iconData?.neu_icono?.icono ?? iconData?.icono?.icono]}
               />
            </div>
         </div>
         : <div className='icon-picker-square-listed rounded-2 m-25'>
            <div className='d-flex'>
               <Icon
                  className='m-auto h-100 w-100'
                  path={MDI[iconData?.neu_icono?.icono ?? iconData?.icono?.icono]}
               />
            </div>
         </div>
   )
}

export default IconFAMDI
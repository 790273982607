import axios from 'axios'
import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { FILTER_ADD_COMUNA, FILTER_ADD_METODO_ENTREGA, FILTER_ADD_TIPO_DESTINO, FILTER_BUSQUEDA, FILTER_PUBLICADO } from '../../redux/redux-types'
import { busquedaFilter, clearFilterPuntosEntrega, comunaFilter, estatusFilter, metodoEntregaFilter, resultadosPuntosEntrega, tipoDestinoFilter } from '../../redux/actions/puntosEntrega'
import { resultadosProductos } from '../../redux/actions/productos'

// Components
import FormInput from '../FormInput'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'
import SelectFilter from '../SelectFilter'
import { lastPosition } from '../../helpers/lastPosition'
import FiltroAplicado from './FiltroAplicado'


const CONSTANT = {
  queryUrlGetAll: getAll.puntos_entrega + '/listar',
  redirectUrl: '/puntos-entrega',
  title: 'Búsqueda de puntos de entrega',
  reduxClearFilters: (data) => clearFilterPuntosEntrega(data),
  reduxSetResults: (data) => resultadosPuntosEntrega(data),
  reduxSetProductos: resultadosProductos,
  queryUrlGetAllComunas: getAll.comunas + '/buscar',
  queryUrlGetTipoDestino: getAll.tipos_destino,
  queryUrlGetMetodoEntrega: getAll.metodos_entrega,

  publicacionSelect: [
    { id: 1, nombre: 'Activo' },
    { id: 0, nombre: 'Inactivo' },
    { id: 'null', nombre: 'Ambos' },
  ]
}


const PuntosEntregaFiltros = ({ isAsignacionMasiva = false }) => {
  const [comunas, setComunas] = useState(null)
  const [tipoDestino, setTipoDestino] = useState(null)
  const [metodoEntrega, setMetodoEntrega] = useState(null)
  const { filtros } = useSelector(state => state.puntosEntrega)
  const { showing_results } = useSelector(state => state.listedResults)
  const [publicacion] = useState(CONSTANT.publicacionSelect)
  const dispatch = useDispatch()
  const { busqueda, comuna_id, metodo_entrega_id, tipo_destino_id, publicado } = filtros

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE TRAE RESULTADOS Y RELLENA SELECTFILTERS: comunas, tipoDestino, metodoEntrega
  useEffect(() => {
    axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
      .catch(err => console.error(err))

    axios(CONSTANT.queryUrlGetAllComunas, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
      .then(({ data }) => setComunas(data.comunas), {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .catch(err => console.error(err))

    axios(CONSTANT.queryUrlGetMetodoEntrega, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
      .then(({ data }) => setMetodoEntrega(data))
      .catch(err => console.error(err))

    axios(CONSTANT.queryUrlGetTipoDestino, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
      .then(({ data }) => setTipoDestino(data))
      .catch(err => console.error(err))
  }, [dispatch, showing_results])


  // EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
  useEffect(() => {
    return () => {
      dispatch(CONSTANT.reduxClearFilters())
      dispatch(CONSTANT.reduxSetProductos(null))
    }
  }, [dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = (reset = false) => {
    const url = CONSTANT.queryUrlGetAll + `?limit=${isAsignacionMasiva ? '99999' : showing_results}`
    let paramPublicado = publicado.includes('null') ? '' : publicado?.split('-')?.[0]

    setIsConsulting(true)

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return axios(url, {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST'
      })
        .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
        .catch(err => console.error(err))
        .finally(() => {
          setIsConsulting(false)
        })
    }

    let requestData = { busqueda: '' }

    Object.entries(filtros).forEach(([k, v]) => {
      if (v.length && ['tipo_destino_id', 'comuna_id', 'metodo_entrega_id'].includes(k)) {
        return requestData = {
          ...requestData,
          filtros: {
            ...requestData.filtros,
            [k]: v.map(el => Number(el.split('-')[0]))
          }
        }
      }
    })

    axios(url + queryParamsFormatter({ busqueda, estatus: paramPublicado }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: requestData
      })
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_BUSQUEDA:
        dispatch(busquedaFilter(value))
        break;

      case FILTER_ADD_COMUNA:
        dispatch(comunaFilter(value))
        break;

      case FILTER_ADD_TIPO_DESTINO:
        dispatch(tipoDestinoFilter(value))
        break;

      case FILTER_ADD_METODO_ENTREGA:
        dispatch(metodoEntregaFilter(value))
        break;

      case FILTER_PUBLICADO:
        dispatch(estatusFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className={`card mb-50 shadow-none bg-transparent ${isAsignacionMasiva ? 'col-md-6 col-12' : 'col-12'}`}>
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <FormInput
            labelText='Búsqueda'
            placeholder='Ingrese su búsqueda'
            size='col-12'
            sizeDesk={isAsignacionMasiva ? 'col-md-6' : 'col-md-3'}
            handleValue={(e) => handleAddFilter(e, FILTER_BUSQUEDA)}
            value={busqueda}
          />

          <SelectFilter
            labelText='Comuna'
            size='col-12'
            sizeDesk={isAsignacionMasiva ? 'col-md-6' : 'col-md-3'}
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_COMUNA)}
            optionObj={comunas?.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={lastPosition(comuna_id)}
          />

          <SelectFilter
            labelText='Estatus'
            size='col-12'
            sizeDesk={isAsignacionMasiva ? 'col-md-6' : 'col-md-3'}
            handleValue={(e) => handleAddFilter(e, FILTER_PUBLICADO)}
            optionObj={publicacion?.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={publicado?.split('-')?.[1] ?? 'Seleccione'}
          />

          <SelectFilter
            labelText='Método de entrega'
            size='col-12'
            sizeDesk={isAsignacionMasiva ? 'col-md-6' : 'col-md-3'}
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_METODO_ENTREGA)}
            optionObj={metodoEntrega?.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={lastPosition(metodo_entrega_id)}
          />

          <SelectFilter
            labelText='Tipo destino'
            size='col-12'
            sizeDesk={isAsignacionMasiva ? 'col-md-6' : 'col-md-2'}
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_TIPO_DESTINO)}
            optionObj={tipoDestino?.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={lastPosition(tipo_destino_id)}
          />
        </>
        {/* FILTROS APLICADOS - ADICIONALES */}
        <p className='mb-25 text-black'>Filtros aplicados</p>
        <div className='row'>
          <FiltroAplicado array={comuna_id} func={comunaFilter} title={'Comunas'} />
          <FiltroAplicado array={metodo_entrega_id} func={metodoEntregaFilter} title={'Métodos de entrega'} />
          <FiltroAplicado array={tipo_destino_id} func={tipoDestinoFilter} title={'Tipos de destino'} />
        </div>

        <hr className='my-1' />
      </ListadoHeader>
    </div>
  )
}

export default PuntosEntregaFiltros

// Components
import CodigosDescuentoFiltros from "../../components/AccordionFilter/CodigosDescuento";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import CodigosDescuentoTable from "../../components/Tables/CodigosDescuentoTable";

const CONSTANT = {
	title: 'Códigos de descuento',
	path: 'codigos-descuento',
	create_btn: 'Crear código'
}

const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<CodigosDescuentoFiltros />

			<CodigosDescuentoTable />
		</div>
	);
}

export default Listado;
import {
   FILTER_ADD_CATEGORIA,
   FILTER_REMOVE_CATEGORIA,
   FILTER_NOMBRE,
   FILTER_CLEAR,
   RESULTADOS_ADICIONALES,
   FILTROS_PERSISTENTES_ADICIONALES
} from "../redux-types";


export const categoriaFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_CATEGORIA, payload: data }
      : { type: FILTER_ADD_CATEGORIA, payload: data };

export const nombreFilter = (data) => ({ type: FILTER_NOMBRE, payload: data })

export const filtrosPersistentesAdicionales = (data) => ({ type: FILTROS_PERSISTENTES_ADICIONALES, payload: data })
export const resultadosAdicionales = (data) => ({ type: RESULTADOS_ADICIONALES, payload: data })

export const clearFilterAdicionales = () => ({ type: FILTER_CLEAR });

import {
   FILTER_ADD_EVENTO,
   FILTER_ADD_USUARIO,
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   FILTER_MODELO,
   FILTER_MODELO_ID,
   FILTER_REMOVE_EVENTO,
   FILTER_REMOVE_USUARIO,
   RESULTADOS_HISTORIAL,
} from "../redux-types";

const initialState = {
   filtros: {
      accion: '',
      usuario_id: [],
      modelos: '',
      modelos_id: '',
      evento_id: [],
   },
   resultados: null
}


export const historialCambiosReducer = (state = initialState, action) => {
   let isListed

   switch (action.type) {
      case FILTER_ADD_USUARIO:
         isListed = state.filtros.usuario_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               usuario_id: isListed
                  ? state.filtros.usuario_id
                  : [...state.filtros.usuario_id, action.payload]
            }
         };

      case FILTER_REMOVE_USUARIO:
         return {
            ...state, filtros: {
               ...state.filtros,
               usuario_id: state.filtros.usuario_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_EVENTO:
         isListed = state.filtros.evento_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               evento_id: isListed
                  ? state.filtros.evento_id
                  : [...state.filtros.evento_id, action.payload]
            }
         };

      case FILTER_REMOVE_EVENTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               evento_id: state.filtros.evento_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               accion: action.payload
            }
         };

      case FILTER_MODELO:
         return {
            ...state, filtros: {
               ...state.filtros,
               modelo: action.payload
            }
         };

      case FILTER_MODELO_ID:
         return {
            ...state, filtros: {
               ...state.filtros,
               modelo_id: action.payload
            }
         };

      case RESULTADOS_HISTORIAL:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
import { useEffect } from "react";
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";

const useAuth = (authNeeded = true) => {
  const { user } = useSelector(state => state.auth)
  const redirect = useNavigate()
  useEffect(() => {
    if (authNeeded && !user?.id) {
      redirect('/auth/login')
    }

    if (!authNeeded && user?.id) {
      redirect('/')
    }

  }, [user?.id, authNeeded, redirect])
}

export default useAuth
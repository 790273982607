import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_FAQ_SUBCATEGORIAS
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosFaqSubcategorias = (data) => ({ type: RESULTADOS_FAQ_SUBCATEGORIAS, payload: data })

export const clearFilterFaqSubcategorias = () => ({ type: FILTER_CLEAR });


// Components
import GarantiasFiltros from "../../components/AccordionFilter/Garantias";
// import DropdownMenuLink from "../../components/Dropdown/Link";
// import DropdownWrapper from "../../components/Dropdown/Wrapper";
import GarantiasTable from "../../components/Tables/GarantiasTable";

const CONSTANT = {
	title: 'Garantías',
	path: 'garantias/respuestas',
	create_btn: 'Crear garantía'
}

const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				{/* <DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper> */}
			</h3>

			<GarantiasFiltros />

			<GarantiasTable />
		</div>
	);
}

export default Listado;
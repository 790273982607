// Views
import Listado from "./Tabs/Listado"


const Index = ({ view = <Listado /> }) => {
	return (
		<div className="row">
			<h2>Cotizaciones</h2>

			<div className="col">

				<hr className="m-1" />

				{view}
			</div>
		</div>
	)
}

export default Index;
import { Navigate, Route, Routes } from "react-router-dom"

// Generales
import TiposAtencionRouter from './Generales/TiposAtencionRouter';
import FormasPagoRouter from './Generales/FormasPagoRouter';
import CiudadesRouter from './Generales/CiudadesRouter';
import DistritosRouter from './Generales/DistritosRouter';
import PaisesRouter from './Generales/PaisesRouter';
import RegionesRouter from './Generales/RegionesRouter';
import ComunasRouter from './Generales/ComunasRouter';
import TiposDestinosRouter from './Generales/TiposDestinosRouter';
import MetodosEntregaRouter from './Generales/MetodosEntregaRouter';
import MetadatosRouter from './Generales/MetadatosRouter';
import EstatusPedidoRouter from './Generales/EstatusPedidoRouter';
import TiposFacturacionRouter from './Generales/TiposFacturacionRouter';
import CriteriosComparacionRouter from './Generales/CriteriosComparacionRouter';
import DiasRouter from './Generales/DiasRouter';
import DivisasRouter from './Generales/DivisasRouter';
import UnidadesRouter from './Generales/UnidadesRouter';
import { useSelector } from "react-redux";
import ParametrosRecargoRouter from "./Generales/ParametrosRecargo";


const GeneralesRouter = () => {
  const { user } = useSelector(state => state.auth)

  return (
    <Routes>

      {user.permisos['tipos-atencion.listar'] &&
        <Route path='/tipos-atencion/*' element={<TiposAtencionRouter />} />
      }

      {user.permisos['formas-pago.listar'] &&
        <Route path='/formas-pago/*' element={<FormasPagoRouter />} />
      }

      {user.permisos['ciudades.listar'] &&
        <Route path='/ciudades/*' element={<CiudadesRouter />} />
      }

      {user.permisos['distritos.listar'] &&
        <Route path='/distritos/*' element={<DistritosRouter />} />
      }

      {user.permisos['paises.listar'] &&
        <Route path='/paises/*' element={<PaisesRouter />} />
      }

      {user.permisos['regiones.listar'] &&
        <Route path='/regiones/*' element={<RegionesRouter />} />
      }

      {user.permisos['comunas.listar'] &&
        <Route path='/comunas/*' element={<ComunasRouter />} />
      }

      {user.permisos['tipos-destino.listar'] &&
        <Route path='/tipos-destinos/*' element={<TiposDestinosRouter />} />
      }

      {user.permisos['metodos-entrega.listar'] &&
        <Route path='/metodos-entrega/*' element={<MetodosEntregaRouter />} />
      }

      {user.permisos['metadatos.listar'] &&
        <Route path='/metadatos/*' element={<MetadatosRouter />} />
      }

      {user.permisos['estatus-pedido.listar'] &&
        <Route path='/estatus-pedido/*' element={<EstatusPedidoRouter />} />
      }

      {user.permisos['tipos-facturacion.listar'] &&
        <Route path='/tipos-facturacion/*' element={<TiposFacturacionRouter />} />
      }

      {user.permisos['criterios-comparacion.listar'] &&
        <Route path='/criterios-comparacion/*' element={<CriteriosComparacionRouter />} />
      }

      {user.permisos['dias.listar'] &&
        <Route path='/dias/*' element={<DiasRouter />} />
      }

      {user.permisos['divisas.listar'] &&
        <Route path='/divisas/*' element={<DivisasRouter />} />
      }

      {user.permisos['unidades.listar'] &&
        <Route path='/unidades/*' element={<UnidadesRouter />} />
      }

      {user.permisos['puntos-entrega.listar'] &&
        <Route path='/parametros-recargos/*' element={<ParametrosRecargoRouter />} />
      }

      <Route path='*' element={<Navigate to='/home' replace />} />
    </Routes>
  )
}

export default GeneralesRouter;
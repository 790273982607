import ProductosStockFiltros from "../../components/AccordionFilter/ProductosStock"
import ProductosStockTable from "../../components/Tables/ProductosStockTable"
import Tabs from "../../components/Tabs"


const tabsList = [
   { name: 'Listado', url: '/stock' },
   { name: 'Stock', url: '#' },
   { name: 'Puntos de entrega', url: '#' },
   { name: 'Métodos de pago', url: '#' },
]

const Listado = () => {
   return (
      <>
         <div className="row">
            <h2>Consultar stock</h2>

            <div className="col">
               <Tabs
                  tabs={tabsList}
               />
               <hr className="m-1" />
               {/* <ProductosStockFiltros /> */}
               <ProductosStockTable />
            </div>
         </div>
      </>
   )
}

export default Listado
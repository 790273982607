import {
   FILTER_ADD_ESTATUS,
   FILTER_PATRON_IDENTIFICACION,
   FILTER_REMOVE_ESTATUS,
   FILTER_CLEAR,
   FILTER_CREACION_DESDE,
   FILTER_BUSQUEDA,
   FILTER_CREACION_HASTA,
   RESULTADOS_PAISES,
   FILTER_REMOVE_NOMBRE,
   FILTER_ADD_NOMBRE,
   FILTER_REMOVE_EXTENSION_TELEFONICA,
   FILTER_ADD_EXTENSION_TELEFONICA,
   FILTER_REMOVE_COD_MAXIMISE,
   FILTER_ADD_COD_MAXIMISE,
   FILTER_ADD_LOCALE,
   FILTER_REMOVE_LOCALE
} from "../redux-types";


export const estatusFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_ESTATUS, payload: data }
      : { type: FILTER_ADD_ESTATUS, payload: data };

export const nombreFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_NOMBRE, payload: data }
      : { type: FILTER_ADD_NOMBRE, payload: data };

export const extensionTelefonicaFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_EXTENSION_TELEFONICA, payload: data }
      : { type: FILTER_ADD_EXTENSION_TELEFONICA, payload: data }

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const patronIdentificacionFilter = (data) => ({ type: FILTER_PATRON_IDENTIFICACION, payload: data })


export const localeFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_LOCALE, payload: data }
      : { type: FILTER_ADD_LOCALE, payload: data }

export const codMaximiseFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_COD_MAXIMISE, payload: data }
      : { type: FILTER_ADD_COD_MAXIMISE, payload: data }

export const creacionDesdeFilter = (data) => ({ type: FILTER_CREACION_DESDE, payload: data })

export const creacionHastaFilter = (data) => ({ type: FILTER_CREACION_HASTA, payload: data })

export const resultadosPaises = (data) => ({ type: RESULTADOS_PAISES, payload: data })

export const clearFilterPaises = () => ({ type: FILTER_CLEAR });

import React from 'react';
import axios from 'axios';
import Button from '../Button';



const DownloadFile = ({ url, method = 'POST', data = {}, handleErrors = () => null, headersObj = {} }) => {
  const handleDownloadFile = async () => {
    try {
      if (handleErrors()) return;

      const response = await axios(url, {
        ...data,
        method,
        headers: {
          ...headersObj,
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        responseType: 'blob', // Importante para manejar archivos binarios,
      });
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      let fileName = response?.headers?.['content-disposition']?.split('=')?.[1] ?? 'descarga'

      link.href = URL.createObjectURL(blob);
      link.download = fileName; // Nombre del archivo y su extensión
      link.click();
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
    }
  };

  return <Button text='Exportar' icon='Download' color='success' className='w-fit my-auto' onClick={handleDownloadFile} />
}

export default DownloadFile;
import {
  FILTER_ADD_PAIS,
  FILTER_BUSQUEDA,
  FILTER_CLEAR,
  FILTER_REMOVE_PAIS,
  RESULTADOS_USUARIOS,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const paisFilter = (data, isRemove = false) =>
  isRemove
    ? { type: FILTER_REMOVE_PAIS, payload: data }
    : { type: FILTER_ADD_PAIS, payload: data }

export const resultadosUsuarios = (data) => ({ type: RESULTADOS_USUARIOS, payload: data })

export const clearFilterUsuarios = () => ({ type: FILTER_CLEAR });

import { Navigate, Route, Routes } from "react-router-dom"

import Create from "../views/Roles/Create"
import Detalle from "../views/Roles/Detalle"
import Listado from "../views/Roles/Listado"
import Update from "../views/Roles/Update"
import { useSelector } from "react-redux"


const RolesRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['roles.crear'] &&
            <Route path='/create' element={<Create />} />
         }

         {user.permisos['roles.editar'] &&
            <Route path='/update/:id' element={<Update />} />
         }

         {user.permisos['roles.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}

export default RolesRouter;
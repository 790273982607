import {
   FILTER_ADD_BODEGA,
   FILTER_ADD_PUNTO_ENTREGA,
   FILTER_ADD_REGION,
   FILTER_CLEAR,
   FILTER_REMOVE_BODEGA,
   FILTER_REMOVE_PUNTO_ENTREGA,
   FILTER_REMOVE_REGION,
   RESULTADOS_BODEGAS_PUNTO_ENTREGA,
} from "../redux-types";


export const puntoEntregaFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_PUNTO_ENTREGA, payload: data }
      : { type: FILTER_ADD_PUNTO_ENTREGA, payload: data }

export const bodegaFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_BODEGA, payload: data }
      : { type: FILTER_ADD_BODEGA, payload: data }

export const regionFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_REGION, payload: data }
      : { type: FILTER_ADD_REGION, payload: data }

export const resultadosBodegasPuntosEntrega = (data) => ({ type: RESULTADOS_BODEGAS_PUNTO_ENTREGA, payload: data })

export const clearFilterBodegasPuntosEntrega = () => ({ type: FILTER_CLEAR })

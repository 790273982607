import { useDispatch } from 'react-redux';



// Actions
import { toggleSidebar } from '../../redux/actions/ui';



// Components
import Icon from '../Icon';
import UserNavBarDropdown from '../UserNavBarDropdown';



const NavBar = () => {

   const dispatch = useDispatch();

   const handleClick = (e) => {
      // TODO: abrir sidebar
      e.preventDefault();

      dispatch(toggleSidebar());
   }

   return (
      <nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow container-xxl">
         <div className="navbar-container d-flex content justify-content-end">
            <div className="bookmark-wrapper d-flex align-items-center justify-content-between w-100">

               <ul className="nav navbar-nav d-xl-none">
                  <li className="nav-item">
                     <a
                        className='nav-link menu-toggle'
                        href='/#'
                        onClick={handleClick}
                     >
                        <Icon icon='Menu' />
                     </a>
                  </li>
               </ul>

               {/* <ul className="nav navbar-nav">
                  <li className="nav-item d-none d-lg-block">
                     <a className="nav-link nav-link-style" href="/#">
                     <Icon icon='Moon' />
                     </a>
                  </li>
               </ul> */}

               <ul className="nav navbar-nav align-items-center ms-auto">
                  <UserNavBarDropdown />
               </ul>
            </div>

         </div>
      </nav>
   );
}



export default NavBar;
import { Navigate, Route, Routes } from "react-router-dom"
import Listado from "../../views/FactoresDiferenciadores/Listado";
import Create from "../../views/FactoresDiferenciadores/Create";
import Update from "../../views/FactoresDiferenciadores/Update";
import Detalle from "../../views/FactoresDiferenciadores/Detalle";
import { useSelector } from "react-redux";



const FactoresDiferenciadores = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['factores-diferenciadores.crear'] &&
            <Route path='/create' element={<Create />} />
         }

         {user.permisos['factores-diferenciadores.editar'] &&
            <Route path='/update/:id' element={<Update />} />
         }

         {user.permisos['factores-diferenciadores.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}

export default FactoresDiferenciadores;
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch, useSelector } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import DropdownMenu from '../../components/DropdownMenu'
import Table from '../../components/Table'
import { NoResults } from '../../components/Tables/Misc/NoResults'
import CustomLoader from '../../components/CustomLoader'


// Layouts
import AccordionTable from '../../layout/AccordionTable'


// Endpoints
import { getOne } from '../../endpoints/getOne'


// Helpers
import Switch from '../../components/Switch'
import { BASE_URL, PATH_URL } from '../../endpoints'
import Modal from '../../components/Modal'
import FileUploaderB from '../../components/FileUploader_B'
import { create } from '../../endpoints/create'
import FormInput from '../../components/FormInput'
import TextareaInput from '../../components/TextareaInput'
import { getAll } from '../../endpoints/getAll'
import SelectFilter from '../../components/SelectFilter'
import { idExtractor } from '../../helpers/idExtractor'
import { historicoData } from '../../helpers/historicoData'
import { ModalHistorico } from '../../components/Historico/ModalHistorico'
import TablaDetalle from '../../components/Historico/TablaDetalle'
import CheckBoxInput from '../../components/CheckboxInput'
import Icon from '../../components/Icon'
import { setterAsignaciones } from '../../services/productos/getAsignaciones.service'
import { asignacionesPuntosEntregaFormat } from '../../helpers/productoAsignacionesFormatter'
import { postPuntosEntrega } from '../../services/productos/postPuntosEntrega.service'
import { deleteRelation } from '../../services/productos/puntosEntrega/deleteRelation.service'


const CONSTANT = {
	queryUrlGetOne: getOne.productos,
	urlCreateProductosImagenes: create.imagenes_productos,
	redirectUpdate: (id) => `/productos/update/${id}`,
	redirectListado: '/productos',
	title: 'Productos',
	modelo: 'App\\Models\\NeuProducto',

	permissionsPath: { puntos_entrega: 'puntos-entrega' },

	updateAtributos: (id) => BASE_URL + PATH_URL + `/atributos-productos/${id}`,
	urlAtributos: BASE_URL + PATH_URL + `/atributos-productos`,
	fetchProductoPuntoEntrega: BASE_URL + PATH_URL + '/puntos-entregas-productos/edicion-masiva',
	fetchProductoMetodoPago: BASE_URL + PATH_URL + '/metodos-pagos-productos/edicion-masiva',
	fetchProductoAdicionales: BASE_URL + PATH_URL + '/adicionales-productos/edicion-masiva',
	fetchClavesAtributos: (categoria) => getAll.claves_atributos + `/filtros?categoria_id=${categoria ?? 1}`,

	unselectedObject: { id: '*NULL*', name: 'Sin selección' },

	asignacionesInitialState: { puntos_entrega: [], metodos_pago: [], adicionales: [], ofertas: [], etiquetas: [] },
	selectedAsignacionesInitialState: { etiqueta: {}, oferta: {}, metodos_pago: [], puntos_entrega: [], adicionales: [] },

	modal: {
		display: false,
		target: ''
	},

	datatableHeadInputState: {
		adicionales: {
			busqueda: '',
			precio: undefined
		},
		puntos_entrega: {
			busqueda: '',
			precio_instalacion: undefined,
			recargo: undefined
		},
		ofertas: {
			busqueda: ''
		},
		etiquetas: {
			busqueda: ''
		}
	},

	intialForm: {
		edit: {
			state: false,
			form: {
				ultimo_costo: 0,
				precio_base: 0,
				precio_final: 0,
				parametros_busqueda: '',
				prioridad_resultados: '',
				stock: 0,
				nombre: ''
			}
		},
		editAtrib: {
			state: false,
			form: {
				producto_id: '',
				ediciones: []
			}
		},
		addAtrib: {
			state: false,
			early_form: {},
			form: {
				producto_id: '',
				asignaciones: []
			}
		},
		addPuntoEntrega: {
			state: false,
			early_form: {},
			form: {
				producto_id: '',
				asignaciones: []
			}
		},

		clavesAtributosSelect: []
	}
}


const valorAtributoArrayFormatter = (arr) => {
	if (typeof arr !== 'object') return new Error(`El dato no es un arreglo, dato recibido: ${typeof arr}`)

	return [CONSTANT.unselectedObject].concat(arr.map(valor => ({
		id: valor?.id,
		name: valor.nombre
	})))
}

// TODO: HAY QUE FILTRAR LOS PUNTOS DE ENTREGA QUE YA ESTAN ASIGNADOS

const Detalle = () => {
	const { user } = useSelector(state => state.auth)

	const [modal, setModal] = useState(false)
	const [edit, setEdit] = useState(CONSTANT.intialForm.edit)
	const [editAtrib, setEditAtrib] = useState(CONSTANT.intialForm.editAtrib)
	const [addAtrib, setAddAtrib] = useState(CONSTANT.intialForm.addAtrib)
	const [addPuntoEntrega, setAddPuntoEntrega] = useState(CONSTANT.intialForm.addPuntoEntrega)
	const [clavesAtributos, setClavesAtributos] = useState(CONSTANT.clavesAtributosSelect)
	const [displayAllAccordion, setDisplayAllAccodion] = useState(true)
	const [data, setData] = useState(null)
	const { id } = useParams()
	const dispatch = useDispatch()
	const [trackingForm, setTrackingForm] = useState([])
	const redirect = useNavigate()
	const [historico, setHistorico] = useState(null)
	const [modalHistorico, setModalHistorico] = useState({ id: null, display: false, data: null })

	const [unfilteredAsignaciones, setUnfilteredAsignaciones] = useState(CONSTANT.asignacionesInitialState)
	const [asignaciones, setAsignaciones] = useState(CONSTANT.asignacionesInitialState)
	const [selectedAsignaciones, setSelectedAsignaciones] = useState(CONSTANT.selectedAsignacionesInitialState)
	const [datatableHeadInput, setDatatableHeadInput] = useState(CONSTANT.datatableHeadInputState)

	// EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
	useEffect(() => {
		setterAsignaciones().then(data => setUnfilteredAsignaciones(data))
		setterAsignaciones().then(data => {
			setAsignaciones(data)
		})

		setProductData()

		return () => setData(null)
	}, [id, dispatch, redirect])


	const setProductData = async () => {
		await axios(CONSTANT.queryUrlGetOne(id),
			{
				headers: {
					'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
				},
			})
			.then(({ data }) => {
				let atributosForm

				data.atributos.forEach(({ nombre, id, neu_clave_atributo }) => {
					atributosForm = {
						...atributosForm,
						[neu_clave_atributo.nombre]: `${id}%-%${nombre}`
					}
				})

				setData(data)
				setEditAtrib(editAtrib => ({ ...editAtrib, atributosForm }))
				historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))

				// AQUI SE SETEAN LAS CLAVES/ATRIBUTOS PARA LA CATEGORIA ASOCIADA
				axios(CONSTANT.fetchClavesAtributos(data?.producto?.categoria_id),
					{
						headers: {
							'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
						},
					})
					.then(({ data }) => {
						setClavesAtributos(data)
					})
					.catch(err => console.error(err))

				setEdit(edit => ({
					...edit,
					form: {
						ultimo_costo: data?.producto?.ultimo_costo,
						precio_base: data?.producto?.precio_base,
						precio_final: data?.producto?.precio_final,
						parametros_busqueda: data?.producto?.parametros_busqueda,
						prioridad_resultados: data?.producto?.prioridad_resultados,
						stock: data?.producto?.neu_stock_propio?.[0]?.stock,
						nombre: data?.producto?.nombre?.toUpperCase()
					}
				}))
			})
			.catch(err => {
				console.error(err)
				const toasterContent = {
					title: 'Registro no encontrado',
					icon: 'warning'
				}

				dispatch(fireToaster(toasterContent))
				redirect(CONSTANT.redirectListado)
			})
	}

	const handleEdit = (target = null) => {
		if (target === 'cancel') {
			setEdit({ ...edit, state: false })
			return
		}

		if (edit.state) {
			handleFetch(null, 'update')
			return
		}

		setEdit({ ...edit, state: true })
	}


	const handleEditAtrib = (target = null) => {
		if (target === 'cancel') return setEditAtrib({ ...editAtrib, state: false })

		if (editAtrib.state) {
			handleFetch(null, 'update-atributos')
			setEditAtrib({ ...editAtrib, state: false })
			return
		}

		setEditAtrib({
			...editAtrib,
			state: !editAtrib.state,
			form: {
				ediciones: data?.atributos.map(({ id, nombre, neu_clave_atributo }) => ({
					atributo_id: id,
					clave_id: neu_clave_atributo?.id,
					selectValue: `${id}-${nombre}`
				}))
			}
		})
	}


	const handleInputChange = (e) => {
		const { name, value } = e.target

		if (name.includes('update')) {
			const key = name.split('-')[1]
			setEdit({
				...edit,
				form: {
					...edit.form,
					[key]: value
				}
			})
		}

		if (name.includes('select-clave')) {
			const claveNombre = name.split('%-%')[1]
			setEditAtrib({
				...editAtrib,
				atributosForm: {
					...editAtrib.atributosForm,
					[claveNombre]: value
				}
			})
		}

		if (name.includes('select-atributo')) {
			const claveNombre = name.split('%-%')[1]
			setAddAtrib({
				...addAtrib,
				early_form: {
					...addAtrib.early_form,
					[claveNombre]: value
				}
			})
		}
	}


	// FUNCIÓN MANEJADORA PARA ACTUALIZACIÓN DEL ESTATUS
	const handleFetch = async (objData, target) => {
		let config
		let url

		if (target === 'publicado') {
			let newEstatus = objData?.producto[target] ? 0 : 1
			url = CONSTANT.queryUrlGetOne(id)
			config = {
				method: 'PUT',
				data: { [target]: newEstatus, _method: 'PUT' }
			}
		}

		if (target === 'tracking') {
			// TODO: cambiar por el servicio Productos -> imagenes -> update
			url = CONSTANT.urlCreateProductosImagenes

			let dataForm = new FormData()
			trackingForm?.length && trackingForm.forEach(({ file }) => dataForm.append('imagenes[]', file))
			dataForm.append('estatus', 1)
			dataForm.append('producto_id', Number(id))

			config = {
				method: 'POST',
				headers: { 'Content-Type': 'multipart/form-data' },
				data: dataForm
			}
		}

		if (target === 'update') {
			url = CONSTANT.queryUrlGetOne(id)
			config = {
				method: 'PUT',
				data: { ...edit.form }
			}
		}

		if (target === 'estatus-punto_entrega') {
			let newEstatus = objData?.pivot?.estatus ? 0 : 1
			url = CONSTANT.fetchProductoPuntoEntrega
			config = {
				method: 'PUT',
				data: {
					ediciones: [{
						id: objData?.pivot?.id,
						precio_instalacion: objData?.pivot?.precio_instalacion,
						recargo: objData?.pivot?.recargo,
						estatus: newEstatus,
					}]
				}
			}
		}

		if (target === 'estatus-metodos_pago') {
			let newEstatus = objData?.pivot?.estatus ? 0 : 1
			url = CONSTANT.fetchProductoMetodoPago
			config = {
				method: 'PUT',
				data: {
					ediciones: [{
						id: objData?.pivot?.id,
						estatus: newEstatus,
					}]
				}
			}
		}

		if (target === 'estatus-adicionales') {
			let newEstatus = objData?.pivot?.estatus ? 0 : 1
			url = CONSTANT.fetchProductoAdicionales
			config = {
				method: 'PUT',
				data: {
					ediciones: [{
						id: objData?.pivot?.id,
						precio: objData?.pivot?.precio,
						estatus: newEstatus,
					}]
				}
			}
		}

		if (target === 'delete-imagen_producto') {
			url = CONSTANT.urlCreateProductosImagenes + `/${objData?.id}`
			config = { method: 'DELETE' }
		}

		if (target === 'add-atributos') {
			url = CONSTANT.urlAtributos
			config = {
				method: 'POST',
				data: {
					producto_id: Number(id),
					asignaciones: Object.values(addAtrib.early_form).map(str => ({
						atributo_valor_id: Number(idExtractor(str, true)),
						estatus: 1
					})).filter(el => !isNaN(el.atributo_valor_id))
				}
			}
		}

		if (target === 'update-atributos') {
			url = CONSTANT.updateAtributos(id)
			config = {
				method: 'PUT',
				data: {
					producto_id: Number(id),
					ediciones: Object.entries(editAtrib.atributosForm).map(([k, v]) => {
						const { pivot } = data?.atributos.find(({ neu_clave_atributo }) => neu_clave_atributo.nombre === k)

						return {
							id: pivot.id,
							atributo_valor_id: Number(idExtractor(v, true)),
							estatus: 1
						}
					})
				}
			}
		}

		if (target === 'delete-atributos') {
			if (objData?.isRequired) return dispatch(fireToaster({ title: 'No es posible realizar esta acción', text: 'El atributo que desea eliminar es obligatorio', icon: 'warning' }))
			if (!window.confirm('¿Está seguro que desea eliminar el atributo?')) return

			url = CONSTANT.updateAtributos(objData?.id)
			config = {
				method: 'DELETE',
			}
		}

		config = {
			...config,
			headers: {
				...config.headers,
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			}
		}

		// return console.log(url, config);

		await axios(url, config)
			.then(res => {
				const toasterContent = {
					title: 'Operación realizada',
					text: `${CONSTANT.title}: registro actualizado con éxito`,
					icon: 'success'
				}

				dispatch(fireToaster(toasterContent))
				setData(null)
				setModal({ ...modal, display: false })

				if (target === 'update') setEdit({ ...edit, state: false })
			})
			.catch(err => {
				console.error(err);
				const { errores } = err.response.data
				let detalles = []
				Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

				const toasterContent = {
					title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
					html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
					icon: 'error'
				}

				dispatch(fireToaster(toasterContent))
			})
			.finally(async () => {
				await axios(CONSTANT.queryUrlGetOne(id),
					{
						headers: {
							'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
						},
					})
					.then(({ data }) => {
						let atributosForm

						data.atributos.forEach(({ nombre, id, neu_clave_atributo }) => {
							atributosForm = {
								...atributosForm,
								[neu_clave_atributo.nombre]: `${id}%-%${nombre}`
							}
						})

						setData(data)
						setEditAtrib(editAtrib => ({ ...editAtrib, atributosForm }))
						historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))

						// AQUI SE SETEAN LAS CLAVES/ATRIBUTOS PARA LA CATEGORIA ASOCIADA
						axios(CONSTANT.fetchClavesAtributos(data?.producto?.categoria_id),
							{
								headers: {
									'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
								},
							})
							.then(({ data }) => {
								setClavesAtributos(data)
							})
							.catch(err => console.error(err))

						setEdit(edit => ({
							...edit,
							form: {
								ultimo_costo: data?.producto?.ultimo_costo,
								precio_base: data?.producto?.precio_base,
								precio_final: data?.producto?.precio_final,
								parametros_busqueda: data?.producto?.parametros_busqueda,
								prioridad_resultados: data?.producto?.prioridad_resultados,
								stock: data?.producto?.neu_stock_propio?.[0]?.stock,
								nombre: data?.producto?.nombre?.toUpperCase()
							}
						}))
					})
					.catch(err => {
						console.error(err)
						const toasterContent = {
							title: 'Registro no encontrado',
							icon: 'warning'
						}

						dispatch(fireToaster(toasterContent))
						redirect(CONSTANT.redirectListado)
					})
			})
	}


	// FUNCION MANEJADORA DE LA DATA DEL MODAL
	const handleModal = (data) => {
		const { id, anterior, nuevo } = data

		setModalHistorico({
			display: true,
			id,
			data: {
				anterior,
				nuevo
			}
		})
	}


	const handleFiltrosPersistentes = (location) => {
		return `?filtros_persistentes=${location?.search?.split('filtros_persistentes=')?.[1] ?? '0'}`
	}


	// FUNCION MANEJADORA PARA LAS SELECCIONAR LAS ASIGNACIONES
	const handleSetAsignacionesMultiples = (e, target) => {
		const { name } = e.target
		const [id] = name.split('-')
		const dictId = {
			metodos_pago: 'metodo_pago_id',
			puntos_entrega: 'punto_entrega_id',
			adicionales: 'adicional_id'
		}
		const isSelected = selectedAsignaciones[target].find(el => el[dictId[target]] === id)

		if (isSelected) {
			return setSelectedAsignaciones({
				...selectedAsignaciones,
				[target]: selectedAsignaciones[target].filter(el => el[dictId[target]] !== id)
			})
		}

		switch (target) {
			case 'metodos_pago':
				return setSelectedAsignaciones({
					...selectedAsignaciones,
					[target]: [
						...selectedAsignaciones[target],
						{
							[dictId[target]]: id,
							estatus: 1
						}
					]
				})

			case 'puntos_entrega':
				return setSelectedAsignaciones({
					...selectedAsignaciones,
					[target]: [
						...selectedAsignaciones[target],
						{
							[dictId[target]]: id,
							precio_instalacion: undefined,
							recargo: undefined,
							instalacion_apply: 1,
							recargo_apply: 1,
							estatus: 1
						}
					]
				})

			case 'adicionales':
				return setSelectedAsignaciones({
					...selectedAsignaciones,
					[target]: [
						...selectedAsignaciones[target],
						{
							[dictId[target]]: id,
							estatus: 1,
							precio: undefined
						}
					]
				})

			default:
				break;
		}
	}


	// FUNCION MANEJADORA PARA SELECCIONAR TODO EN LA TABLA DE ASIGNACIONES
	const handleSelectAll = (e) => {
		const { name, value } = e.target
		if (!value) {
			return setSelectedAsignaciones({
				...selectedAsignaciones,
				[name]: []
			})
		}

		switch (name) {
			case 'metodos_pago':
				return setSelectedAsignaciones({
					...selectedAsignaciones,
					[name]: asignaciones[name].map(({ id }) => ({
						metodo_pago_id: String(id),
						estatus: 1
					}))
				})

			case 'puntos_entrega':
				return setSelectedAsignaciones({
					...selectedAsignaciones,
					[name]: asignaciones[name].map(({ id }) => (
						{
							punto_entrega_id: String(id),
							precio_instalacion: undefined,
							recargo: undefined,
							instalacion_apply: true,
							recargo_apply: true,
							estatus: 1
						}
					))
				})

			case 'adicionales':
				return setSelectedAsignaciones({
					...selectedAsignaciones,
					[name]: asignaciones[name].map(({ id }) => (
						{
							adicional_id: String(id),
							estatus: 1,
							precio: undefined
						}
					))
				})

			default:
				break;
		}
	}


	// FUNCION MANEJADORA DE LOS INPUTS DE LAS ASIGNACIONES MASIVAS
	const handleAsignacionMasivasInput = (e) => {
		const { name, value } = e.target
		const [target, key] = name.split('-')

		if (e.key === 'Enter') return filterDatatable(target)

		setDatatableHeadInput({
			...datatableHeadInput,
			[target]: {
				...datatableHeadInput[target],
				[key]: value
			}
		})
	}

	// FUNCION QUE FILTRA LAS ASIGNACIONES SEGUN SU PARAMETRO DE BUSQUEDA
	const filterDatatable = (target) => {
		const query = datatableHeadInput[target].busqueda
		setAsignaciones(
			{
				...unfilteredAsignaciones,
				[target]: unfilteredAsignaciones[target].filter(el => el.filter_param.includes(query.toLowerCase()))
			})
	}


	// FUNCION MANEJADORA QUE SETEA LOS VALORES DE LAS ASIGNACIONES SELECCIONADAS SEGUN EL HEAD-INPUT RELACIONADO
	const handleSetAsignacionesValue = (name, isReset) => {
		const [target, key] = name.split('-')
		const d = {
			precio_instalacion: 'instalacion_apply',
			recargo: 'recargo_apply'
		}

		if (['nulo', 'no aplica', 'null', 'nada', 'vacio', 'inactivo'].includes(datatableHeadInput[target][key])) {

			setSelectedAsignaciones({
				...selectedAsignaciones,
				[target]: selectedAsignaciones[target].map(el => ({ ...el, [key]: undefined, [d[key]]: 0 }))
			})

			return
		}

		setSelectedAsignaciones({
			...selectedAsignaciones,
			[target]: selectedAsignaciones[target].map(el => ({ ...el, [key]: !isReset ? datatableHeadInput[target][key] : undefined, [d[key]]: 1 }))
		})
	}


	// FUNCION MANEJADORA DE LAS ASIGNACIONES
	const handleAsignacionesInput = (e) => {
		const { name, value } = e.target
		const [id, key, target] = name.split('-')
		const dictId = {
			puntos_entrega: 'punto_entrega_id',
			oferta: 'oferta_id',
			etiqueta: 'etiqueta_id',
			adicionales: 'adicional_id'
		}
		let isSelected = null

		if (['puntos_entrega', 'adicionales'].includes(target)) {
			isSelected = selectedAsignaciones[target]?.find(el => el[dictId[target]].includes(id))
		}

		if (['oferta', 'etiqueta'].includes(target)) {
			isSelected = selectedAsignaciones[target][dictId[target]] === Number(id) && selectedAsignaciones[target]
		}

		if (isSelected && ['puntos_entrega', 'adicionales'].includes(target)) {
			return setSelectedAsignaciones({
				...selectedAsignaciones,
				[target]: selectedAsignaciones[target].map(el => {
					return el[dictId[target]] === isSelected[dictId[target]]
						? {
							...isSelected,
							[key]: value
						}

						: el
				})
			})
		}

		if (isSelected && ['oferta', 'etiqueta'].includes(target)) {
			return setSelectedAsignaciones({
				...selectedAsignaciones,
				[target]: {
					...selectedAsignaciones[target],
					[key]: value
				}
			})
		}
	}


	const fetchAddPuntosEntrega = async (e) => {
		e.preventDefault()

		postPuntosEntrega(asignacionesPuntosEntregaFormat(selectedAsignaciones.puntos_entrega), id)
			.then(res => {
				dispatch(fireToaster(res))
			})
			.catch(err => {
				dispatch(fireToaster(err))
			})
			.finally(async () => {
				await axios(CONSTANT.queryUrlGetOne(id),
					{
						headers: {
							'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
						},
					})
					.then(({ data }) => {
						let atributosForm

						data.atributos.forEach(({ nombre, id, neu_clave_atributo }) => {
							atributosForm = {
								...atributosForm,
								[neu_clave_atributo.nombre]: `${id}%-%${nombre}`
							}
						})

						setData(data)
						setAddPuntoEntrega(CONSTANT.intialForm.addPuntoEntrega)
						setEditAtrib(editAtrib => ({ ...editAtrib, atributosForm }))
						historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))

						// AQUI SE SETEAN LAS CLAVES/ATRIBUTOS PARA LA CATEGORIA ASOCIADA
						axios(CONSTANT.fetchClavesAtributos(data?.producto?.categoria_id),
							{
								headers: {
									'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
								},
							})
							.then(({ data }) => {
								setClavesAtributos(data)
							})
							.catch(err => console.error(err))

						setEdit(edit => ({
							...edit,
							form: {
								ultimo_costo: data?.producto?.ultimo_costo,
								precio_base: data?.producto?.precio_base,
								precio_final: data?.producto?.precio_final,
								parametros_busqueda: data?.producto?.parametros_busqueda,
								prioridad_resultados: data?.producto?.prioridad_resultados,
								stock: data?.producto?.neu_stock_propio?.[0]?.stock,
								nombre: data?.producto?.nombre?.toUpperCase()
							}
						}))
					})
					.catch(err => {
						console.error(err)
						const toasterContent = {
							title: 'Registro no encontrado',
							icon: 'warning'
						}

						dispatch(fireToaster(toasterContent))
						redirect(CONSTANT.redirectListado)
					})
			})
	}


	const handleDeleteRelation = async (punto_entrega_producto_id) => {
		if (!punto_entrega_producto_id) return dispatch(fireToaster({ title: 'Error al realizar la acción', text: 'No se encontró el id pivote', icon: 'warning' }))

		await deleteRelation(punto_entrega_producto_id).then(res => {
			setProductData()
		})
	}


	return (
		<>
			{/* MODAL AGENDAMIENTO  ??*/}
			<Modal display={modal.display} handleValue={() => setModal({ ...modal, display: false })} sizeDesk='col-md-12 h-fit'>

				{/* TODO: arregla integracion para imagenes */}
				{modal.target === 'tracking' &&
					<div className='m-2'>
						<h3 className='p-50 rounded-2 m-0'>Adjuntar imagen a producto #{id}</h3>
						<hr className='mt-0 mb-1' />

						<div className='col-12 mb-50'>
							<FileUploaderB value={trackingForm} handleValue={setTrackingForm} />
						</div>

						<div className='col-3'>
							<Button text='Adjuntar' color='success' onClick={() => handleFetch(trackingForm, 'tracking')} />
						</div>
					</div>
				}

				{/* ATRIBUTOS */}
				{modal.target === 'atributos' &&
					<div className='m-2'>
						<h3 className='p-50 rounded-2 m-0'>Añadir atributos a producto #{id}</h3>
						<hr className='mt-0 mb-1' />

						<div className='row'>
							{clavesAtributos?.length &&
								clavesAtributos.filter(clave => !data?.atributos.find(el => el.neu_clave_atributo.nombre === clave.nombre) && clave.valores.length)
									.sort((a, b) => a.nombre.localeCompare(b.nombre))
									.map(clave => {
										return (
											<SelectFilter
												altSeparator
												key={clave.nombre + 'add'}
												labelText={clave.nombre_mostrar.toUpperCase()}
												name={`select-atributo%-%${clave.nombre}%-%${clave.id}`}
												handleValue={handleInputChange}
												value={addAtrib?.early_form?.[clave.nombre]?.split('%-%')[1] ?? 'Seleccione'}
												size='col-12'
												sizeDesk='col-md-6'
												optionObj={valorAtributoArrayFormatter(clave.valores)}
											/>
										)
									})
							}
						</div>

						<div className='col-3'>
							<Button text='Adjuntar' color='success' onClick={() => handleFetch({}, 'add-atributos')} />
						</div>
					</div>
				}
			</Modal>

			<ModalHistorico modal={modalHistorico} fnHandleModal={setModalHistorico} />

			<div className="card-body p-25 bg-white mb-50 rounded-2 border">
				<div className='col mb-50'>
					<div className='d-flex gap-1'>
						<div className='col'>
							<Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado + handleFiltrosPersistentes(window.location)} />
						</div>
					</div>
				</div>

				<div className="d-flex justify-content-between flex-md-row mt-0 p-25 align-items-center">
					<b className="mb-0 px-1 fs-3">Producto
						<span className="mx-50 mb-0">#{id}</span>
					</b>
					<div className="d-flex gap-1">

						<Button text='Agregar puntos de entrega' icon={addPuntoEntrega.state ? 'X' : 'Plus'} color={addPuntoEntrega.state ? 'danger' : 'success'} className='p-50' onClick={() => setAddPuntoEntrega(prev => ({ ...prev, state: !prev.state }))} />

						<Button icon={`${displayAllAccordion ? 'Minimize2' : 'Maximize2'}`} className='p-50' onClick={() => setDisplayAllAccodion(!displayAllAccordion)} />
					</div>
				</div>
			</div>

			{data
				? <div className='row'>

					{/* MARK: AGREGAR PUNTO DE ENTREGA */}
					{addPuntoEntrega.state && (
						<>
							<AccordionTable
								title={`Puntos de entrega(${asignaciones.puntos_entrega.filter(({ id }) => !data?.puntos_entregas.find(({ id: prevId }) => prevId === id))?.length} resultados)`}
								classes="col-12 mb-2"
							>
								<Table
									style={{
										maxHeight: '700px'
									}}
								>
									<thead className="table-light position-sticky top-0 w-100 z-100">
										<tr role="row">

											<th className="col-1">
												<div>
													<CheckBoxInput
														labelText="Todos"
														classes='w-fit mt-2 m-auto'
														handleValue={handleSelectAll}
														name='puntos_entrega'
														value={asignaciones.puntos_entrega?.length === selectedAsignaciones.puntos_entrega?.length}
													/>
												</div>
											</th>

											<th className="col">
												<div className="d-flex gap-1 position-relative">
													<FormInput
														labelText='Punto de entrega'
														placeholder="Punto de entrega"
														size="col-12"
														name='puntos_entrega-busqueda'
														value={datatableHeadInput.puntos_entrega.busqueda}
														handleValue={handleAsignacionMasivasInput}
														onKeyDown={handleAsignacionMasivasInput}
														sizeDesk="col-md-12"
														margin="mb-0"
													/>

													<button
														onClick={() => filterDatatable('puntos_entrega')}
														className="btn m-auto p-25"
														style={
															{
																backgroundColor: '#7367f0',
																position: 'absolute',
																color: '#FFF',
																top: '48%',
																right: '10px',
															}
														}
													>
														<Icon icon='Search' />
													</button>
												</div>
											</th>

											<th className="col-2">
												<div className="d-flex">
													<FormInput
														name='puntos_entrega-precio_instalacion'
														value={datatableHeadInput.puntos_entrega.precio_instalacion}
														handleValue={handleAsignacionMasivasInput}
														labelText='Instalación'
														placeholder="Cantidad"
														sizeDesk="col"
														margin="mb-0 me-50"
													/>
													<div className="d-flex flex-column">
														<Button
															color={"danger"}
															icon="Trash"
															className={"m-auto p-25"}
															onClick={() => handleSetAsignacionesValue('puntos_entrega-precio_instalacion', true)}
														/>
														<Button
															icon="Plus"
															className={"m-auto p-25"}
															onClick={() => handleSetAsignacionesValue('puntos_entrega-precio_instalacion')}
														/>
													</div>
												</div>
											</th>

											<th className="col-2">
												<div className="d-flex">
													<FormInput
														name='puntos_entrega-recargo'
														value={datatableHeadInput.puntos_entrega.recargo}
														handleValue={handleAsignacionMasivasInput}
														labelText='Recargo'
														placeholder="Cantidad"
														sizeDesk="col"
														margin="mb-0 me-50"
													/>
													<div className="d-flex flex-column">
														<Button
															color={"danger"}
															icon="Trash"
															className={"m-auto p-25"}
															onClick={() => handleSetAsignacionesValue('puntos_entrega-recargo', true)}
														/>
														<Button
															icon="Plus"
															className={"m-auto p-25"}
															onClick={() => handleSetAsignacionesValue('puntos_entrega-recargo')}
														/>
													</div>
												</div>
											</th>
										</tr>
									</thead>

									<tbody>
										{asignaciones.puntos_entrega?.length
											? asignaciones.puntos_entrega.filter(({ id }) => !data?.puntos_entregas.find(({ id: prevId }) => prevId === id)).map(({ rut_proveedor, nombre, descripcion, direccion, comuna, tipo_destino, id }, i) => (
												<tr className="odd" key={`${i}-punto-entrega-${nombre}-${rut_proveedor}`}>

													<td>
														<div className="d-flex h-100">
															<CheckBoxInput
																name={`${id}-${nombre}`}
																labelText=""
																handleValue={(e) => handleSetAsignacionesMultiples(e, 'puntos_entrega')}
																value={selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id) ? true : false}
																classes='m-auto'
															/>
														</div>
													</td>

													<td>
														<div className="d-flex flex-column gap-50">
															<b>Tipo destino: {tipo_destino?.nombre ?? 'N/A'} | Región: {comuna?.region?.nombre ?? 'N/A'} | Nombre: {nombre ?? 'N/A'} | Dirección: {direccion ?? 'N/A'}</b>
															<span>RUT: <b>{rut_proveedor}</b> | {descripcion ?? 'Sin descripción'}</span>
														</div>
													</td>

													<td>
														<div className='d-flex flex-column'>
															{selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id) &&
																<Switch
																	value={selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id)?.instalacion_apply}
																	name={`${id}-instalacion_apply-puntos_entrega`}
																	labelText='Instalación'
																	isList
																	handleValue={handleAsignacionesInput}
																/>
															}

															<FormInput
																type="number"
																placeholder="1000"
																name={`${id}-precio_instalacion-puntos_entrega`}
																value={selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id)?.precio_instalacion ?? undefined}
																handleValue={handleAsignacionesInput}
																isDisabled={
																	(selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id) &&
																		(selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id)?.instalacion_apply) ? false : true)
																}
																size="col-12"
																margin="mb-0"
															/>
														</div>

													</td>

													<td>
														<div className='d-flex flex-column'>
															{selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id) &&
																<Switch
																	value={selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id)?.recargo_apply}
																	name={`${id}-recargo_apply-puntos_entrega`}
																	labelText='Recargo'
																	isList
																	handleValue={handleAsignacionesInput}
																/>
															}

															<FormInput
																type="number"
																placeholder="2000"
																name={`${id}-recargo-puntos_entrega`}
																value={selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id)?.recargo ?? undefined}
																handleValue={handleAsignacionesInput}
																isDisabled={
																	(selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id) &&
																		(selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id)?.recargo_apply) ? false : true)
																}
																size="col-12"
																margin="mb-0"
															/>
														</div>
													</td>
												</tr>
											))
											: <tr><td colSpan={4}><i>Sin resultados...</i></td></tr>
										}
									</tbody>
								</Table>
							</AccordionTable>

							<div className='d-flex mx-auto justify-content-center gap-3 mb-2'>
								<Button text='Guardar' color='success' icon='Check' onClick={fetchAddPuntosEntrega} />
								<Button text='Cancelar' color='danger' icon='X' onClick={() => setAddPuntoEntrega(prev => ({ ...prev, state: false }))} />
							</div>
						</>
					)}

					{/* MARK: COLUMNA IZQUIERDA */}
					<div className='col-12 col-md-6'>

						{/* DATOS GENERALES */}
						<div className='col mb-1'>
							<Accordion
								title={<h3 className='m-0 w-100 text-center text-white'>Datos generales </h3>}
								isOpen={displayAllAccordion}
							>
								<div className='d-flex gap-1 justify-content-end'>
									{edit.state &&
										<Button
											icon={`X`}
											color={'danger'}
											text={'Cancelar'}
											className='p-50'
											onClick={() => handleEdit('cancel')}
										/>
									}

									<Button
										icon={`${edit.state ? 'Check' : 'Settings'}`}
										color={edit.state ? 'success' : 'warning'}
										text={edit.state ? 'Guardar' : 'Editar'}
										className='p-50'
										onClick={() => handleEdit()}
									/>
								</div>

								<hr className='my-50 mx-50' />

								<div className='col px-2 align-items-center'>
									<div className='row'>
										<AccordionDetailData dataKey={'Creación'} value={data?.producto?.created_at?.split('T')[0] ?? '?'} />
										<AccordionDetailData dataKey={'Nombre'} value={
											edit.state
												? <TextareaInput
													value={edit?.form?.nombre ?? ''}
													classes='col'
													labelText=''
													margin='mb-0'
													name='update-nombre'
													handleValue={handleInputChange}
												/>
												: data?.producto?.nombre?.toUpperCase() ?? 'N/A'
										}
										/>
										<AccordionDetailData dataKey={'SKU'} value={data?.producto?.sku ?? 'N/A'} />
										<AccordionDetailData dataKey={'Prioridad resultado'} value={data?.producto?.prioridad_resultados ?? 'N/A'} />
										<AccordionDetailData dataKey={'Publicado'} value={<span onClick={() => handleFetch(data, 'publicado')} className={`cursor-pointer tag px-50 ${data?.producto?.publicado ? 'success' : 'red'}`}>{data?.producto?.publicado ? 'Publicado' : 'No publicado'}</span>} />
										<AccordionDetailData dataKey={'Nuevo'} value={data?.producto?.nuevo ? 'Si' : 'No'} />
										<AccordionDetailData dataKey={'Variante'} value={data?.producto?.es_variante ? 'Si' : 'No'} />
										<AccordionDetailData dataKey={'Prioridad resultado'} value={
											edit.state
												? <FormInput
													type='number'
													value={edit?.form?.prioridad_resultados ?? ''}
													classes='col'
													margin='mb-0'
													name='update-prioridad_resultados'
													handleValue={handleInputChange}
												/>
												: data?.producto?.prioridad_resultados ?? 'N/A'
										} />
										<AccordionDetailData dataKey={'Precio base'} value={
											edit.state
												? <FormInput
													type='number'
													value={edit?.form?.precio_base ?? ''}
													classes='col'
													margin='mb-0'
													name='update-precio_base'
													handleValue={handleInputChange}
												/>
												: `${data?.producto?.precio_base ? '$ ' + data?.producto?.precio_base : 'N/A'}`
										} />
										<AccordionDetailData dataKey={'Precio final'} value={
											edit.state
												? <FormInput
													type='number'
													value={edit?.form?.precio_final ?? ''}
													classes='col'
													margin='mb-0'
													name='update-precio_final'
													handleValue={handleInputChange}
												/>
												: `${data?.producto?.precio_final ? '$ ' + data?.producto?.precio_final : 'N/A'}`
										} />
										<AccordionDetailData dataKey={'Precio oferta'} value={`${data?.producto?.precio_oferta ? '$ ' + data?.producto?.precio_oferta : 'N/A'}`} />
										<AccordionDetailData dataKey={'Último costo'} value={
											edit.state
												? <FormInput
													type='number'
													value={edit?.form?.ultimo_costo ?? ''}
													classes='col'
													margin='mb-0'
													name='update-ultimo_costo'
													handleValue={handleInputChange}
												/>
												: `${data?.producto?.ultimo_costo ? '$ ' + data?.producto?.ultimo_costo : 'N/A'}`
										} />
										<AccordionDetailData dataKey={'Stock'} value={
											edit.state
												? <FormInput
													type='number'
													value={edit?.form?.stock ?? ''}
													classes='col'
													margin='mb-0'
													name='update-stock'
													handleValue={handleInputChange}
												/>
												: data?.producto?.neu_stock_propio?.[0]?.stock ?? 'N/A'
										} />
										<AccordionDetailData dataKey={'SKU a mostrar'} value={data?.producto?.sku_a_mostrar ?? 'N/A'} />
										<AccordionDetailData dataKey={'Meta-título'} value={data?.producto?.['meta-titulo'] ?? 'N/A'} />
										<AccordionDetailData dataKey={'Meta-descripción'} value={data?.producto?.['meta-descripcion'] ?? 'N/A'} />
										<AccordionDetailData dataKey={'Datos estructurados'} value={data?.producto?.['datos-estructurados'] ?? 'N/A'} />
										<AccordionDetailData dataKey={'URL'} value={data?.producto?.url ?? 'N/A'} />
										<AccordionDetailData dataKey={'Parámetros de búsqueda'} value={
											edit.state
												? <TextareaInput
													value={edit?.form?.parametros_busqueda ?? ''}
													labelText=''
													classes='col'
													margin='mb-0'
													name='update-parametros_busqueda'
													handleValue={handleInputChange}
												/>
												: <TextareaInput
													labelText=''
													isReadOnly
													value={edit?.form?.parametros_busqueda ?? ''}
													margin='mb-0'
												/>
										} classes='w-100' last />
									</div>
								</div>
							</Accordion>
						</div>
					</div >

					{/* MARK: COLUMNA DERECHA */}
					< div className='col-12 col-md-6' >

						{/* MARK: TABLA ATRIBUTOS */}
						<div className='col mb-1' >
							<Accordion
								isOpen={displayAllAccordion}
								title={<h3 className='m-0 w-100 text-center text-white'>Atributos</h3>}
							>
								<div className='d-flex justify-content-between'>
									<Button
										icon='Plus'
										color='success'
										text='Añadir'
										className='p-50'
										onClick={() => setModal({ target: 'atributos', display: true })}
									/>

									<div className='d-flex gap-1'>
										{editAtrib.state &&
											<Button
												icon={`X`}
												color={'danger'}
												text={'Cancelar'}
												className='ms-auto p-50'
												onClick={() => handleEditAtrib('cancel')}
											/>
										}

										<Button
											icon={`${editAtrib.state ? 'Check' : 'Settings'}`}
											color={editAtrib.state ? 'success' : 'warning'}
											text={editAtrib.state ? 'Guardar' : 'Editar'}
											className='p-50'
											onClick={() => handleEditAtrib()}
										/>
									</div>
								</div>

								<hr className='my-50 mx-50' />

								<div className='col px-2 align-items-center'>
									<div className='row'>
										{data?.atributos?.length
											? data?.atributos.sort((a, b) => a.neu_clave_atributo.nombre.localeCompare(b.neu_clave_atributo.nombre)).map(({ nombre, neu_clave_atributo, id, pivot }, i, arr) => (
												<AccordionDetailData
													select
													key={`atributo-${i}`}
													dataKey={
														<div className='d-flex justify-content-between'>
															<span className='px-2'>{neu_clave_atributo?.nombre_mostrar?.toUpperCase()}</span>
														</div>
													}
													value={
														editAtrib.state && clavesAtributos?.length
															? <SelectFilter
																altSeparator
																key={`${nombre}`}
																labelText=''
																name={`select-clave%-%${neu_clave_atributo.nombre}%-%${neu_clave_atributo.id}`}
																handleValue={handleInputChange}
																value={editAtrib.atributosForm?.[neu_clave_atributo.nombre]?.split('%-%')?.[1] ?? 'Seleccione'}
																classes='mb-0 w-100'
																optionObj={clavesAtributos.find(el => el.nombre === neu_clave_atributo.nombre).valores.map(({ id, nombre }) => ({ id: id ?? '*NULL*', name: nombre ?? 'Error' }))}
															/>

															: <div className='d-flex gap-1'>
																<FormInput margin='mb-0' classes='w-100' value={nombre ?? '?'} isDisabled />

																<Button icon='X' className='p-25 my-auto' color='danger' disabled={neu_clave_atributo?.valor_obligatorio} onClick={() => handleFetch({ id: pivot?.id, isRequired: neu_clave_atributo?.valor_obligatorio }, 'delete-atributos')} />
															</div>

													}
												/>
											))

											: <p className='text-center'>Sin atributos</p>
										}
									</div>
								</div>
							</Accordion>
						</div >
					</div >

					{/* MARK: TABLA PUNTOS ENTREGAS */}
					<AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Puntos de entrega'
					>
						<Table pb={false} style={{ maxHeight: '700px' }}>
							<thead className="table-light">
								<tr role="row">
									<th>Nombre</th>
									<th>Instalación</th>
									<th>Recargo</th>
									<th className='text-center'>Estatus</th>
								</tr>
							</thead>
							<tbody>
								{data?.producto?.punto_entrega_productos?.length

									? data?.producto?.punto_entrega_productos?.map(({ id, punto_entrega, nombre, estatus, precio_instalacion, recargo }, i, arr) => (
										<tr key={`puntos_entregas-${id}-${nombre ?? '?'}`}>
											<td>
												<div className='d-flex flex-column'>
													<a target='_blank' rel='noreferrer' href={`/puntos-entrega/detalle/${id}`}><span>{punto_entrega?.nombre ?? '?'} | {punto_entrega?.direccion ?? 'Sin dirección'}</span></a>
													<span>RUT: <b>{punto_entrega?.rut_proveedor ?? '?'}</b></span>
												</div>
											</td>
											<td>{precio_instalacion}</td>
											<td>{recargo}</td>
											<td>
												<div className='d-flex gap-1'>

													<Switch
														value={estatus ? true : false}
														labelText=''
														classes='mt-0 mb-0'
														handleValue={() => handleFetch(arr[i], 'estatus-punto_entrega')}
													/>

													{user.permisos[`${CONSTANT.permissionsPath.puntos_entrega}.eliminar`] &&
														<Button icon={'X'} color={'danger'} className={'my-auto p-25'} onClick={() => handleDeleteRelation(id)} />
													}
												</div>
											</td>
										</tr>
									))

									: <tr><td colSpan={6}><NoResults /></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable >

					{/* MARK: METODOS PAGO */}
					< AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Métodos de pago'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead className="table-light">
								<tr role="row">
									<th>Nombre</th>
									<th>Descripción</th>
									<th>Estatus</th>
								</tr>
							</thead>
							<tbody>
								{data?.metodos_pagos?.length

									? data?.metodos_pagos?.map(({ id, nombre, descripcion, estatus, pivot }, i, arr) => (
										<tr key={`metodos_pagos-${id}-${nombre ?? '?'}`}>
											<td><a target='_blank' rel='noreferrer' href={`/metodos-pago/detalle/${id}`}>{nombre?.toUpperCase() ?? '?'}</a></td>
											<td>{descripcion ?? 'Sin descripción'}</td>
											<td>
												<Switch
													value={pivot?.estatus ? true : false}
													labelText={null}
													classes='mx-auto mt-0 mb-0'
													handleValue={() => handleFetch(arr[i], 'estatus-metodos_pago')}
												/>
											</td>
										</tr>
									))

									: <tr><td colSpan={6}><NoResults /></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable >

					{/* MARK: OFERTAS */}
					< AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Ofertas'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead className="table-light">
								<tr role="row">
									<th>Nombre</th>
									<th>Qty Límite/Crítica</th>
									<th>Descuento</th>
									<th>Fecha intérvalos</th>
									{/* <th>Estatus</th> */}
								</tr>
							</thead>
							<tbody>
								{data?.ofertas?.length

									? data?.ofertas?.map(({ id, nombre, porcentaje_descuento, monto_descuento, pivot }, i, arr) => (
										<tr key={`ofertas-${id}-${nombre ?? '?'}`}>
											<td><a target='_blank' rel='noreferrer' href={`/ofertas/detalle/${id}`}>{nombre?.toUpperCase() ?? '?'}</a></td>

											<td>
												<div>
													<p>Límite: <b>{pivot?.cantidad_limite ?? 'N/A'}</b></p>
													<p>Crítica: <b>{pivot?.cantidad_critica ?? 'N/A'}</b></p>
												</div>
											</td>

											<td>
												<b>{porcentaje_descuento + '%' ?? '$ ' + monto_descuento ?? '?'}</b>
											</td>

											<td>
												<div>
													<p>Inicio: <b>{pivot?.fecha_hora_inicio?.split(' ')?.[0] ?? '?'}</b> - <b>{pivot?.fecha_hora_inicio?.split(' ')?.[1].split('.')[0] ?? '?'}</b></p>
													<p>Final: <b>{pivot?.fecha_hora_final?.split(' ')?.[0] ?? '?'}</b> - <b>{pivot?.fecha_hora_final?.split(' ')?.[1].split('.')[0] ?? '?'}</b></p>
												</div>
											</td>

											{/* <td>
												<Switch
													value={pivot?.estatus ? true : false}
													labelText={null}
													classes='mx-auto mt-0 mb-0'
													handleValue={() => handleFetch(arr[i], 'estatus-ofertas')}
												/>
											</td> */}
										</tr>
									))

									: <tr><td colSpan={6}><NoResults /></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable >

					{/* MARK: ETIQUETAS */}
					< AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Etiquetas'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead className="table-light">
								<tr role="row">
									<th>Nombre</th>
									<th>Prioridad</th>
									<th>Descripción</th>
									<th>Fecha intérvalos</th>
									{/* <th>Estatus</th> */}
								</tr>
							</thead>
							<tbody>
								{data?.etiquetas?.length

									? data?.etiquetas?.map(({ id, nombre, descripcion, prioridad, estatus, pivot }, i) => (
										<tr key={`etiquetas-${id}-${nombre ?? '?'}-${i}`}>
											<td><a target='_blank' rel='noreferrer' href={`/etiquetas/detalle/${id}`}>{nombre?.toUpperCase() ?? '?'}</a></td>

											<td>{prioridad ?? '?'}</td>

											<td>{descripcion?.length ? descripcion : 'Sin descripción'}</td>

											<td>
												<div>
													<p>Inicio: <b>{pivot?.fecha_hora_inicio?.split(' ')?.[0] ?? '?'}</b> - <b>{pivot?.fecha_hora_inicio?.split(' ')?.[1]?.split('.')?.[0] ?? '?'}</b></p>
													<p>Final: <b>{pivot?.fecha_hora_final?.split(' ')?.[0] ?? '?'}</b> - <b>{pivot?.fecha_hora_final?.split(' ')?.[1]?.split('.')?.[0] ?? '?'}</b></p>
												</div>
											</td>

											{/* <td>
												<Switch
													value={estatus ? true : false}
													labelText={null}
													classes='mx-auto mt-0 mb-0'
												/>
											</td> */}
										</tr>
									))

									: <tr><td colSpan={6}><NoResults /></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable >

					{/* MARK: ADICIONALES */}
					< AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Adicionales'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead className="table-light">
								<tr role="row">
									<th>Nombre</th>
									<th>Descripción</th>
									<th>Precio</th>
									<th>Estatus</th>
								</tr>
							</thead>
							<tbody>
								{data?.adicionales?.length

									? data?.adicionales?.map(({ id, nombre, descripcion, pivot }, i, arr) => (
										<tr key={`adicionales-${id}-${nombre ?? '?'}`}>
											<td><a target='_blank' rel='noreferrer' href={`/adicionales/detalle/${id}`}>{nombre?.toUpperCase() ?? '?'}</a></td>

											<td>{descripcion ?? 'Sin descripción'}</td>

											<td>{pivot?.precio ?? 'Sin descripción'}</td>

											<td>
												<Switch
													value={pivot?.estatus ? true : false}
													labelText={null}
													classes='mx-auto mt-0 mb-0'
													handleValue={() => handleFetch(arr[i], 'estatus-adicionales')}
												/>
											</td>
										</tr>
									))

									: <tr><td colSpan={6}><NoResults /></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable >

					{/* MARK: IMAGENES */}
					<AccordionTable
						classes='mb-1'
						isOpen={displayAllAccordion}
						title='Imágenes'
					>
						<Table pb={false} style={{ maxHeight: '900px' }}>
							<thead className="table-light">
								<tr role="row">
									<th className='col-6'>Imagen</th>
									<th className='col-4'>Formatos</th>
									<th className='col-1'>
										<Button icon='Plus' color='success' text='Adjuntar' onClick={() => setModal({ target: 'tracking', display: true })} className='p-25' />
									</th>
								</tr>
							</thead>
							<tbody>
								{data?.imagenes?.length

									? data?.imagenes?.map(({ id, nombre, imagen, desktop, tablet, movil }, i, arr) => (
										<tr key={`imagenes-${id}-${nombre ?? '?'}`}>
											<td>
												<img
													key={`producto-imagen-${id}`}
													className='height-listado'
													src={BASE_URL + 'storage/' + imagen}
													alt={`producto-imagen-${id}`}
												/>
											</td>

											<td>
												<div>
													<p>Desktop: {desktop}</p>
													<p>Table: {tablet}</p>
													<p>Móvil: {movil}</p>
												</div>
											</td>

											<td><Button icon='X' color='danger' text='Borrar' onClick={() => handleFetch(arr[i], 'delete-imagen_producto')} className='p-25' /></td>
										</tr>
									))

									: <tr><td colSpan={6}><NoResults /></td></tr>
								}
							</tbody>
						</Table>
					</AccordionTable >

					<TablaDetalle historicoData={historico} fnHandleModal={handleModal} />

				</div >
				: <CustomLoader />
			}
		</>
	)
}

export default Detalle
import axios from "axios";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";
import { useEffect, useState } from "react";
import { idExtractor } from "../../helpers/idExtractor";
import SelectFilter from "../../components/SelectFilter";
import { getAll } from "../../endpoints/getAll";
import CustomLoader from "../../components/CustomLoader";
import { getOne } from "../../endpoints/getOne";
import { fecthSelectFiltersData } from "../../helpers/fetchSelectFilterData";


const CONSTANT = {
  redirectUrl: '/generales/parametros-recargos',
  componentTitle: id => `${id ? 'Editar' : 'Crear'} parámetros`,
  title: 'Parámetros de recargo',

  queryUrl: create.parametros_recargos,
  fecthData: getOne.parametros_recargos,

  selectFilter: {
    puntosEntrega: [],
    valoresAtributos: []
  },

  form: {
    atributo_valor_id: '',
    punto_entrega_id: '',
    recargo: '',
    precio_instalacion: ''
  }
}

const selectFilterQueries = [
  {
    url: getAll.puntos_entrega + `/listar`,
    options: { method: 'POST' },
    key: 'puntosEntrega',
  },
  {
    url: getAll.valores_atributos + `/listar`,
    options: { method: 'POST' },
    key: 'valoresAtributos',
  }
]


const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.form)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const { id } = useParams()

  const [isConsulting, setIsConsulting] = useState(false)


  useEffect(() => {
    if (!id || !selectFilter.puntosEntrega.length || !selectFilter.valoresAtributos.length) return

    axios(CONSTANT.fecthData(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        const puntoEntrega = selectFilter.puntosEntrega.find(({ id }) => String(data.punto_entrega_id) === String(id))
        const atributo = selectFilter.valoresAtributos.find(({ id }) => String(data.atributo_valor_id) === String(id))

        setForm({
          ...data,
          punto_entrega_id: `${puntoEntrega?.id ?? '*NULL*'}%-%${puntoEntrega?.neu_tipo_destino?.nombre ?? puntoEntrega?.tipo_destino?.nombre ?? 'Sin tipo destino'} | ${puntoEntrega?.direccion ?? 'Sin dirección'} | ${puntoEntrega?.comuna?.nombre ?? 'Sin comuna'}, ${puntoEntrega?.comuna?.neu_region?.nombre ?? puntoEntrega?.comuna?.region?.nombre ?? 'Sin región'}`,
          atributo_valor_id: `${atributo?.id ?? '*NULL*'}%-%${atributo?.neu_clave_atributo?.nombre ?? 'Desconocido'} | ${atributo?.nombre}`
        })
      })
      .catch(err => console.error(err))

    return () => {
      setForm(CONSTANT.form)
    }
  }, [id, selectFilter.puntosEntrega, selectFilter.valoresAtributos])


  // EFECTO QUE RECOJE LA DATA DE LOS SELECTFILTERS
  useEffect(() => {
    fecthSelectFiltersData(selectFilterQueries).then(res => setSelectFilter(res))
    return () => {
      setSelectFilter(CONSTANT.selectFilter)
    }
  }, [])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)
    const { atributo_valor_id, punto_entrega_id, recargo, precio_instalacion } = form

    if (atributo_valor_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, seleccione un atributo', icon: 'info' }))
    if (punto_entrega_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, seleccione un punto de entrega', icon: 'info' }))

    let url = id => id ? CONSTANT.queryUrl + `/${id}` : CONSTANT.queryUrl

    let data = {
      atributo_valor_id: Number(idExtractor(atributo_valor_id, true)),
      punto_entrega_id: Number(idExtractor(punto_entrega_id, true)),
      recargo: Number(recargo),
      precio_instalacion: Number(precio_instalacion)
    }

    axios(url(id), {
      method: id ? 'PUT' : 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data?.nombre} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() =>
        setIsConsulting(false)
      )
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto" >
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        {selectFilter?.puntosEntrega?.length && selectFilter.valoresAtributos?.length
          ? <CustomForm size="" title={CONSTANT.componentTitle(id)}>

            <div className="row">
              <SelectFilter
                altSeparator
                labelText="* Punto de entrega"
                name='punto_entrega_id'
                size="col-12"
                sizeDesk="col-md-12"
                handleValue={handleInputChange}
                optionObj={selectFilter?.puntosEntrega?.map(({ id, direccion, neu_tipo_destino, tipo_destino, comuna }) => ({ id, name: `${neu_tipo_destino?.nombre ?? tipo_destino?.nombre ?? 'Sin tipo destino'} | ${direccion ?? 'Sin dirección'} | ${comuna?.nombre ?? 'Sin comuna'}, ${comuna?.neu_region?.nombre ?? comuna?.region?.nombre ?? 'Sin región'}` }))}
                value={form?.punto_entrega_id.split('%-%')?.[1] ?? 'Seleccione'}
              />

              <SelectFilter
                altSeparator
                labelText='Atributos'
                name='atributo_valor_id'
                size='col-12'
                sizeDesk='col-md-6'
                handleValue={handleInputChange}
                optionObj={selectFilter?.valoresAtributos?.map(({ id, nombre, neu_clave_atributo }) => ({ id, name: `${neu_clave_atributo?.nombre ?? 'Desconocido'} | ${nombre}` }))}
                value={form?.atributo_valor_id.split('%-%')?.[1] ?? 'Seleccione'}
              />

              <FormInput
                labelText='* Recargo ($CLP)'
                placeholder='Recargo'
                name='recargo'
                value={form?.recargo ?? ''}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-3'
              />

              <FormInput
                labelText='* Precio inst. ($CLP)'
                placeholder='Precio inst.'
                name='precio_instalacion'
                value={form?.precio_instalacion ?? ''}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-3'
              />
            </div>

            <Button
              type="submit"
              text={id ? 'Actualizar' : "Crear"}
              color="primary"
              disabled={isConsulting}
              icon={id ? 'Check' : "Plus"}
              onClick={(e) => handleFetch(e)}
            />
          </CustomForm>
          : <CustomLoader />
        }
      </div >
    </div >
  );
};

export default Form;

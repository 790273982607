import axios from "axios"
import moment from "moment/moment"
import { getAll } from "../../endpoints/getAll"
import { queryParamsFormatter } from "../../helpers/queryParamsFormatter"
import { successResponse } from "../../helpers/successResponse"


const today = moment().format('YYYY-MM-DD')
const URL = getAll.dashboard_pedidos_estatus


export const getContadorPorEstatus = async (params) => {
  try {
    if (params?.fecha) params = { ...params, fecha: today }

    const response = await axios(URL + '?q=s' + queryParamsFormatter(params), {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })

    if (successResponse(response)) return response.data

    throw response
  } catch (err) {
    console.error(err)
    return err
  }
}
import { Navigate, Route, Routes } from "react-router-dom"
import Listado from "../../views/Componentes/Listado";
import Detalle from "../../views/Componentes/Detalle";
import { useSelector } from "react-redux";



const ComponentesRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['componentes.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}

export default ComponentesRouter;
// Components
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";


// Layouts
import PaisesTable from "../../components/Tables/PaisesTable";
import PaisesFiltros from "../../components/AccordionFilter/Paises";


const CONSTANT = {
  title: 'Paises',
  path: 'paises',
  create_btn: 'Crear país'
}


const Listado = () => {
  return (
    <>
      <h3 className="mb-1 d-flex justify-content-between">
        <p><b>{CONSTANT.title}</b> - Listado</p>
        <DropdownWrapper text="Acciones" classes="ms-auto">
          <DropdownMenuLink text={CONSTANT.create_btn} href={`/generales/${CONSTANT.path}/create`} icon="Plus" />
        </DropdownWrapper>
      </h3>

      <PaisesFiltros />

      <PaisesTable />
    </>
  );
};

export default Listado;


// Components
import CiudadesFiltros from "../../components/AccordionFilter/Ciudades";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import CiudadesTable from "../../components/Tables/CiudadesTable";

const CONSTANT = {
	title: 'Ciudades',
	path: 'ciudades',
	create_btn: 'Crear ciudad'
}

const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/generales/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<CiudadesFiltros />

			<CiudadesTable />
		</div>
	);
}

export default Listado;
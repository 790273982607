import { AUTH_LOGIN, AUTH_PERSISTENT, } from "../redux-types";

const initialState = {
  user: {},
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return {
        ...state,
        user: action.payload
      }

    case AUTH_PERSISTENT:
      return {
        ...state,
        user: action.payload
      }

    default:
      return state
  }
}
import React, { useEffect, useState } from 'react';
import axios from 'axios';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from "../../redux/actions/toaster";


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Table from "../Table";


// Layouts
import AccordionTable from "../../layout/AccordionTable";


// Endpoints
import { BASE_URL, PATH_URL } from '../../endpoints';
import { getAll } from '../../endpoints/getAll';
import Modal from '../Modal';
import { todayFormat } from '../../helpers/todayInputFormat';
import FormInput from '../FormInput';
import Button from '../Button';
import { resultadosNotasVentas } from '../../redux/actions/notasVentas';
import { PrecioFormatter } from '../../helpers/precioFormatter';
import { useCopyClipboard } from '../../hooks/useCopyClipboard';
import Icon from '../Icon';
import SelectFilter from '../SelectFilter';
import { idExtractor } from '../../helpers/idExtractor';


const Precio = (v) => new PrecioFormatter(v).producto()


const CONSTANT = {
	pathUrl: '/erp/notas-ventas',
	permissionsPath: 'planificar',
	title: 'Notas de venta planificadas',
	titleSecond: 'Resumen',
	titleThird: 'Resumen por categoría',

	reduxSetResults: resultadosNotasVentas,

	fecthMaximiseData: BASE_URL + PATH_URL + '/erp/notas-ventas/lineas/listar',
	fetchAgendamientos: getAll.agendamientos + '/listar?desde=2020-01-01&hasta=2031-01-01',
	createPlanificacion: BASE_URL + PATH_URL + `/erp/notas-ventas/planificar`,

	acordeones: { productos: false },

	resumenFiltros: [
		{ id: 'planificacion', name: 'Todos' },
		{ id: 'planificacion_productos', name: 'Productos' },
		{ id: 'planificacion_servicios', name: 'Servicios' },
	]
}


const fetchSecondaryDataNotaVenta = async (NV) => {
	const dataERP = NV?.nv?.map(({ ORDER_NUM }) => ORDER_NUM) ?? []
	const dataAgendamientos = NV?.nv?.map(({ EXT_NUM }) => Number(EXT_NUM)).filter(el => !isNaN(el)) ?? []

	const erpLineas = await axios(CONSTANT.fecthMaximiseData, {
		headers: {
			'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
		},
		method: 'POST',
		data: { notas_ventas: dataERP }
	})
		.then(({ data }) => NV?.nv?.map(({ ORDER_NUM }, i, arr) => {
			const erp_data = data.data[ORDER_NUM]
			return { ...arr[i], erp_data }
		}))
		.catch(err => {
			console.error(err)
			return NV
		})

	const agendamientos = await axios(CONSTANT.fetchAgendamientos,
		{
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
			method: 'POST',
			data: {
				filtros: {
					pedido_id: dataAgendamientos
				}
			}
		})
		.then(({ data }) => erpLineas.map(({ EXT_NUM }, i, arr) => {
			const selectedAgendamiento = data?.find(({ id }) => id === Number(EXT_NUM))
			return { ...arr[i], agenda: selectedAgendamiento ?? null }
		}))
		.catch(err => {
			console.error(err)
			return erpLineas
		})

	return agendamientos
}


const PlanificacionTable = () => {
	const { planificacion: response, filtros } = useSelector(state => state.notasVentas)
	const [resultados, setResultados] = useState([])
	const dispatch = useDispatch()
	const [modal, setModal] = useState({ display: false, target: '' })
	const [planificacionForm, setPlanificacionForm] = useState({ nota_venta: '', fecha_planificacion: todayFormat() })
	const { user } = useSelector(state => state.auth)
	const [acordeones, setAcordeones] = useState(CONSTANT.acordeones)
	const [resumenFiltro, setResumenFiltro] = useState('planificacion%-%Todos')

	const [copy] = useCopyClipboard()


	// EFECTO QUE FORMATEA LA RESPUESTA DE PRODUCTOS CON LA RESPUESTA DEL MAXIMISE
	useEffect(() => {
		if (!response?.nv) return
		dispatch(fireToaster({ title: 'Realizando consulta al ERP', icon: 'info', text: 'Buscando...' }))

		fetchSecondaryDataNotaVenta(response).then(data => setResultados(data))

		dispatch(fireToaster({ title: 'Consulta al ERP finalizada', icon: 'success' }))

		return setResultados(null)
	}, [response, dispatch])


	const handleInputChange = (e) => {
		const { name, value } = e.target

		if (name.includes('planificacion')) {
			const key = name.split('%-%')[1]
			setPlanificacionForm({
				...planificacionForm,
				[key]: value
			})
		}
	}


	const handleModalData = (data) => {
		setPlanificacionForm({
			...planificacionForm,
			nota_venta: Number(data?.id)
		})

		setModal({ target: 'planificacion', display: true })
	}


	const handleFetch = async (target) => {
		let config = {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		}
		let url

		if (target === 'planificacion-create') {
			url = CONSTANT.createPlanificacion
			config = {
				...config,
				method: 'POST',
				data: planificacionForm
			}
		}

		await axios(url, config)
			.then(res => {
				const toasterContent = {
					title: 'Operación realizada',
					text: `${CONSTANT.title}: registro actualizado con éxito`,
					icon: 'success'
				}
				dispatch(fireToaster(toasterContent))
				setModal({ target: '', display: false })
			})
			.catch(err => {
				console.error(err);
				const { msj, error } = err.response.data
				let toasterContent = {
					title: `
						Operación fallida
						(${err.response.status} - ${err.response.statusText})
					`,
					html: `
						<b>Detalle: </b>
						<br />
						${msj}: ${error}
					`,
					icon: 'error'
				}

				dispatch(fireToaster(toasterContent))
			})
	}


	// FUNCION MANEJADORA DE LOS ACORDEONES
	const handleInnerAccordion = (value, target) => {
		setAcordeones({ ...acordeones, [target]: !value })
	}


	return (
		<>
			{!resultados && <CustomLoader blocking={'partial'} />}

			<Modal display={modal.display} handleValue={() => setModal(false)}>
				{/* PLANIFICACION */}
				<p className='fw-bold fs-3'>Planificacion de nota de venta #{planificacionForm.nota_venta}</p>
				<hr className='my-2' />

				<FormInput
					type='date'
					handleValue={handleInputChange}
					name='planificacion%-%fecha_planificacion'
					value={planificacionForm.fecha_planificacion}
					size='col-12'
					sizeDesk='col-md-4'
					classes='mx-auto'
				/>

				<div>
					<Button className='mx-auto' text='Planificar' icon='Check' onClick={() => handleFetch('planificacion-create')} />
				</div>

			</Modal>


			<div className='d-flex gap-1 justify-content-end mb-50'>
				<div className='w-fit'>
					<Button onClick={() => handleInnerAccordion(acordeones.productos, 'productos')} text='Productos' icon={acordeones.productos ? 'Minimize2' : 'Maximize2'} />
				</div>
			</div>


			{/* PLANIFICACION */}
			<AccordionTable title={`${CONSTANT.title} (${resultados?.length ?? 0} resultados)`}>
				<div className='d-flex gap-1 p-50'>
					{filtros?.planificacion_desde &&
						<span className='tag px-50'><b>Planificacion desde:</b> {filtros?.planificacion_desde}</span>
					}

					{filtros?.planificacion_hasta &&
						<span className='tag px-50'><b>Planificacion hasta:</b> {filtros?.planificacion_hasta}</span>
					}

					{filtros?.nv &&
						<span className='tag px-50'><b>Nota venta</b> #{filtros?.nv}</span>
					}

					{filtros?.destino &&
						<span className='tag px-50'><b>Tipo destino:</b> {filtros?.destino?.split('-')?.[1] ?? 'Error'}</span>
					}

					{filtros?.metodo_entrega_id?.length ?
						<span className='tag px-50'><b>Método entrega:</b> {filtros?.metodo_entrega_id?.map(metodo => metodo?.split('-')?.[1] ?? 'Error').join(', ')}</span>
						: <></>
					}
				</div>

				<Table pb={false}>
					<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
						<tr role="row">
							<th>NV</th>
							<th>NP</th>
							<th>Cliente</th>
							<th>Destino</th>
							<th>Planificación</th>
							<th>Totales</th>
							<th>Vendedor</th>
							<th className="w-max">Acc.</th>
						</tr>
					</thead>
					<tbody>
						{resultados?.length
							? resultados?.map(({
								ORDER_NUM, EXT_NUM, ORDER_DATE, NOMBRE_VENDEDOR, STATUS_IND,
								CLIENTE_NOMBRE, CLIENTE_RUT, CLIENTE_EMAIL, CLIENTE_TELEFONO,
								SHIP_ADDR_TEXT, UD_TIPO_DESTI, COMUNA_ENTREGA, SHIP_NAME_TEXT,
								SHIP_DATE, UD_TIPO_FACTURACION, UD_MONTO_ABONADO, TOTAL_AMT,
								DXC, erp_data, agenda
							}, i) => {
								return (
									<React.Fragment key={`nv-rw-${i}`} >
										<tr
											className={`odd multi-data`}
											key={`nv-${i}-${ORDER_NUM}-${EXT_NUM}-${CLIENTE_RUT}`}
											style={{ backgroundColor: i % 2 > 0 ? '#f5f5f5' : '#fff' }}
										>

											{/* NV */}
											<td rowSpan={2} style={{ borderBottom: '1px solid #333' }} className='pt-3'>
												<div className='d-flex flex-column gap-50'>
													<a href={`/erp/notas-ventas/detalle/${ORDER_NUM}`} rel='noreferrer' target='_blank'>
														<b>{ORDER_NUM ?? '?'}</b>
													</a>
													<span>Estatus: <b>{STATUS_IND ?? '?'}</b></span>
													<span className='text-nowrap'>{ORDER_DATE?.split('T')[0] ?? '?'}</span>
												</div>

												{/* <div className='bg-white border border-primary rounded-2 py-25 px-50 w-100'>
													<p className='text-center'>Agenda</p>
													{agenda
														? <div className='tag dark-blue px-50 mx-auto'>
															<b>
																<p className='my-0 text-center'>{agenda?.agendamiento?.fecha?.split('T')[0] ?? '?'}</p>
																<p className='my-0 text-center'>{agenda?.agendamiento?.hora_agenda?.hora_inicio_intervalo ?? '?'} - {agenda?.agendamiento?.hora_agenda?.hora_final_intervalo ?? '?'}</p>
															</b>
														</div>
														: <p className='tag dark-blue px-50 mx-auto'><b>N/A</b></p>
													}
												</div> */}
											</td>

											{/* NP */}
											<td>
												<b>{EXT_NUM ?? 'N/A'}</b>
											</td>

											{/* CLIENTE */}
											<td className='pt-3'>
												<div className='d-flex flex-column'>
													<b>{CLIENTE_NOMBRE ?? '?'}</b>
													<span>{CLIENTE_EMAIL ?? 'N/A'}</span>
													<span>RUT: <b>{CLIENTE_RUT ?? 'N/A'}</b></span>
													<span>Tlf: <b>{CLIENTE_TELEFONO ?? 'N/A'}</b></span>
												</div>
											</td>

											{/* DESTINO */}
											<td className='pt-3'>
												<div className='d-flex flex-column'>
													<span>Entrega: <b>{SHIP_ADDR_TEXT ?? '?'}</b></span>
													<span>Tipo destino: <b>{UD_TIPO_DESTI ?? '?'}</b></span>
												</div>
											</td>

											{/* PLANIFICACIÓN */}
											<td className='pt-3'><b className='text-nowrap'>{SHIP_DATE?.split('T')?.[0] ?? 'N/A'}</b></td>

											{/* FACTURACION */}
											{/* <td>
												<div className='d-flex flex-column'>
													<span>Tipo: {UD_TIPO_FACTURACION ?? 'N/A'}</span>
													<b>DXC: {DXC ?? '?'}</b>
												</div>
											</td> */}

											{/* TOTALES */}
											<td className='pt-3'>
												<div className='d-flex flex-column'>
													<b className={`tag px-50 fs-3 w-max ${TOTAL_AMT - (UD_MONTO_ABONADO ?? 0) > 0 ? 'red' : 'success'}`}>{Precio(TOTAL_AMT)}</b>
													<span>Pagado: <b>{Precio(UD_MONTO_ABONADO)}</b></span>
												</div>
											</td>

											{/* VENDEDOR */}
											<td className='pt-3'>
												<span><b>{NOMBRE_VENDEDOR ?? '?'}</b></span>
											</td>

											<td className='pt-3'>
												<DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
													{user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
														<>
															<DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${ORDER_NUM}`} icon="Table" target='_blank' rel='noreferer' />

															{!SHIP_DATE && <DropdownMenuLink text='Planificar' onClick={() => handleModalData({ id: ORDER_NUM })} icon="Calendar" />}
														</>
													}
												</DropdownWrapper>
											</td>
										</tr>

										<tr className={`odd multi-data`} style={{ borderBottom: '1px solid #333' }}>
											<td style={{ borderBottom: '1px solid #555', backgroundColor: i % 2 > 0 ? '#f5f5f5' : '#fff' }} colSpan={11} className='p-0 pb-3'>
												<AccordionTable isOpen={acordeones.productos} title={`Productos ERP (${erp_data?.reduce((prev, { UOM_QTY }) => prev += Number(UOM_QTY), 0) ?? 0})`} style={{ backgroundColor: i % 2 > 0 ? '#456ca3' : "#942626" }}>
													<Table pb={false}>
														<thead className='table-light position-sticky top-0 w-100 z-100 shadow'>
															<tr>
																<th>Descripción</th>
																<th>SKU</th>
																<th>Bodega</th>
																<th>Qty</th>
																<th>Qty reservada</th>
																<th>Qty pdte.</th>
																<th>P. unit. neto</th>
																<th>P. subtotal neto</th>
															</tr>
														</thead>
														<tbody>
															{erp_data?.length
																? erp_data.map(({ DESC_TEXT, UD_MONTO_INSTALACION, UD_RECARGO, PART_CODE, WARE_CODE, UOM_QTY, SCHED_QTY, BACK_QTY, UNIT_PRICE_AMT, EXT_PRICE_AMT }, i) => (
																	<tr key={`lineas-pedido-erp-${DESC_TEXT ?? 'producto'}-${i ?? PART_CODE}`}>
																		<td>
																			{/* <a href='/producto/detalle'> */}
																			{DESC_TEXT.toUpperCase() ?? 'N/A'}
																			{/* </a> */}
																		</td>
																		<td>{PART_CODE ?? 'N/A'}</td>
																		<td className='text-center'>{WARE_CODE ?? 'N/A'}</td>
																		<td className='text-center'>{UOM_QTY ?? 'N/A'}</td>
																		<td className='text-center'>{SCHED_QTY ?? 'N/A'}</td>
																		<td className='text-center'>{BACK_QTY ?? 'N/A'}</td>
																		<td className='text-center'>$ {UNIT_PRICE_AMT ?? 'N/A'}</td>
																		<td className='text-center'>$ {EXT_PRICE_AMT ?? 'N/A'}</td>
																	</tr>
																))
																: <tr><td colSpan={5}><p className='tag dark-blue px-50 m-auto'><b>Error de conexión</b></p></td></tr>
															}
														</tbody>
													</Table>
												</AccordionTable>
											</td>
										</tr>
									</React.Fragment>
								)
							})
							: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
						}
					</tbody>
				</Table>
			</AccordionTable >

			{/* RESUMEN */}
			<AccordionTable title={`${CONSTANT.titleSecond} (${Object.values(response?.planificacion ?? [])?.length ?? 0} resultados)`} classes='mt-2'>
				<Table pb={false}>
					<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
						<tr role="row">
							<th className='col-2'>Tipo despacho</th>
							<th>
								<div className='d-flex gap-2'>
									Bodegas / Notas de venta
									<SelectFilter
										altSeparator
										sizeDesk={`col-md-2`}
										labelText={'Filtrar por:'}
										optionObj={CONSTANT.resumenFiltros}
										handleValue={({ target }) => setResumenFiltro(target.value)}
										value={resumenFiltro.split('%-%')[1] ?? 'Seleccione'}
									/>
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						{response !== null && Object?.entries(response?.[idExtractor(resumenFiltro, true)])?.length
							? Object?.entries(response?.[idExtractor(resumenFiltro, true)])?.map(([k, v], i) => {
								return (
									<tr key={`planificacion-rw-${i}`} className={`odd multi-data`}>
										{/* TIPO DESTINO*/}
										<td><b>{k === 'NULL' ? 'Sin asignar' : k}</b></td>

										{/* BODEGAS / NOTAS VENTAS */}
										<td>
											<AccordionTable title='Resumen'>
												<Table pb={false}>
													<thead>
														<tr>
															<th className='col-2' style={{ width: 0 }}>Bodega</th>
															<th style={{ width: 0, paddingLeft: 0, paddingRight: 0 }}></th>
															<th style={{ width: '100%' }}>Notas de venta</th>
														</tr>
													</thead>
													<tbody>
														{Object.entries(v).map(([bodega, notas_ventas]) => (
															<tr key={`notas-ventas-${bodega}-${notas_ventas}`} className={`odd multi-data`}>
																<td className='text-center fw-bold'>{bodega}</td>
																<td style={{ paddingLeft: 0, paddingRight: 0 }}>
																	<div className='d-flex' onClick={() => copy(notas_ventas)}>
																		<Icon icon='Clipboard' size={20} className='cursor-pointer' />
																	</div>
																</td>
																<td>{notas_ventas}</td>
															</tr>
														))}
													</tbody>
												</Table>
											</AccordionTable>
										</td>
									</tr>
								)
							})
							: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
						}
					</tbody>
				</Table>
			</AccordionTable >

			{/* RESUMEN PRODUCTOS POR CATEGORIA*/}
			<AccordionTable title={`${CONSTANT.titleThird}`} classes='mt-2'>
				<Table pb={false}>
					<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
						<tr role="row">
							<th className='col-2'>Tipo despacho</th>
							<th className='col'>Categoría</th>
						</tr>
					</thead>
					<tbody>
						{response && Object?.entries(response?.productos_por_categoria_destino)?.length
							? Object?.entries(response?.productos_por_categoria_destino)?.map(([k, v], i) => {

								return (
									<tr key={`planificacion-row-${i}`} className={`odd multi-data`}>
										{/* Método de entrega */}
										<td><b>{k === 'NULL' ? 'Sin asignar' : k}</b></td>

										{/* Punto de entrega */}
										<td>
											{Object?.entries(v)?.map(([k, v], i) => (
												<div className={`d-flex gap-2 p-25 border rounded mb-50`}>
													<b>{k}:</b>
													<span>{v === '' ? 'N/A' : v}</span>
												</div>
											))}
										</td>
									</tr>
								)
							})
							: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
						}
					</tbody>
				</Table>
			</AccordionTable >
		</>
	)
}

export default PlanificacionTable
import axios from "axios"
import { BASE_URL, REPORTERIA_URL } from "../../endpoints"

const URL = BASE_URL + REPORTERIA_URL + '/cotizaciones/resumen-mes'

export const getResumenCotizaciones = async (data) => {
  let toasterContent = {
    title: 'Error - Vacío',
    icon: 'info'
  }

  try {
    if (!data) throw Error({ title: 'Faltan datos', icon: 'error' })

    return await axios(URL,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: {
          fecha_inicial: data.desde,
          fecha_final: data.hasta
        }
      })
      .then(({ data }) => {
        return data.resumen_por_sku
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        toasterContent = {
          title: `
            Error en la asignación de puntos de entrega al producto
          (${err.response.status} - ${err.response.statusText})
        `,
          html: `
          <b>Detalle: </b>
          ${detalles.map(error => `<br /><i>-${error}</i>`)}
        `,
          icon: 'error'
        }

        throw toasterContent
      })

  } catch (error) {
    return error
  }
}
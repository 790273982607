import axios from 'axios';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from "../../redux/actions/toaster";
import { clearFilterBanners, resultadosBanners } from '../../redux/actions/banners';


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Switch from "../Switch";
import Table from "../Table";


// Endpoints
import { getOne } from "../../endpoints/getOne";
import { BASE_URL } from '../../endpoints';


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter';
import moment from 'moment';


const CONSTANT = {
  pathUrl: 'template/banners',
  permissionPath: 'banners',
  queryUrlGetOne: getOne.banners,
  title: 'Banners',
  reduxClearFilters: (data) => clearFilterBanners(data),
  reduxSetResults: (data) => resultadosBanners(data),
  imagen_movil: (data) => `${BASE_URL}storage/${data?.imagen_movil}` ?? '/placeholder.png',
  imagen_escritorio: (data) => `${BASE_URL}storage/${data?.imagen_escritorio}` ?? '/placeholder.png'
}


const BannersTable = () => {
  const { resultados: response, filtros } = useSelector(state => state.banners)
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const { busqueda } = filtros
  const { user } = useSelector(state => state.auth)


  // FUNCION MANEJADORA DE PAGINACIÓN
  const handleUrl = (url) => {
    dispatch(CONSTANT.reduxSetResults(null))
    axios(url + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(res => dispatch(CONSTANT.reduxSetResults(res.data)))
      .catch(err => console.error(err))
  }


  // FUNCIÓN MANEJADORA PARA ACTUALIZAR EL ESTATUS
  const handleFetch = async (objData, url, target) => {
    let config
    let fetchUrl = CONSTANT.queryUrlGetOne(objData.id)

    if (target === 'estatus') {
      let newEstatus = objData.estatus ? 0 : 1
      let data = new FormData()
      data.append('estatus', newEstatus)
      data.append('_method', 'PUT')

      config = {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
          'Content-Type': 'application/json'
        },
        data
      }
    }

    if (target === 'delete') {
      if (!window.confirm('¿Está seguro que desea eliminar el registro?')) return

      config = {
        method: 'DELETE',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
          'Content-Type': 'application/json'
        }
      }
    }

    await axios(fetchUrl, config)
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: registro ${target === 'delete' ? 'borrado' : 'actualizado'} con éxito`,
          icon: 'success'
        }

        dispatch(fireToaster(toasterContent))
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })

    dispatch(CONSTANT.reduxSetResults(null))

    axios(url, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
      }
    })
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
      })
      .catch(err => console.error(err))
  }


  return (
    <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
      {!response && <CustomLoader blocking={'partial'} />}

      <Table response={response} handlePagination={handleUrl}>
        <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
          <tr role="row">
            <th>ID</th>
            <th>Frontend</th>
            <th>Datos</th>
            <th>Fecha</th>
            <th>Imagen Móvil</th>
            <th>Imagen Escritorio</th>
            <th>Estatus</th>
            <th className="w-max">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {response?.data.length
            ? response.data?.map((data) => {
              return (
                <tr className="odd" key={`user-${data.id}-${data.titulo}`}>
                  <td>{data?.id}</td>
                  <td>{data?.frontend?.nombre ?? 'chileneumáticos'}</td>
                  <td>
                    <div className='d-flex flex-column text-nowrap'>
                      <span>Nombre: <b>{data?.nombre ?? "N/A"}</b></span>
                      <span>Componente: <b>{data?.componente?.nombre ?? "N/A"}</b></span>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column text-nowrap'>
                      <span>Inicio: <b>{moment(data?.fecha_hora_inicio).format('YY-MM-DD hh:mm')}</b></span>
                      <span>Final: <b>{moment(data?.fecha_hora_final).format('YY-MM-DD hh:mm')}</b></span>
                    </div>
                  </td>
                  <td className='px-25'><img className="height-listado" src={CONSTANT?.imagen_movil(data)} alt={data?.titulo} /></td>
                  <td className='px-25'><img className="height-listado" src={CONSTANT?.imagen_escritorio(data)} alt={data?.titulo} /></td>
                  <td>
                    <Switch
                      value={data.estatus ? 1 : 0}
                      labelText={""}
                      handleValue={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }), 'estatus')}
                    />
                  </td>
                  <td>
                    <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto">
                      {user.permisos[`${CONSTANT.permissionPath}.detalle`] &&
                        <DropdownMenuLink text='Detalle' href={`/${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
                      }
                      {user.permisos[`${CONSTANT.permissionPath}.editar`] &&
                        <DropdownMenuLink text='Editar' href={`/${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
                      }
                      {user.permisos[`${CONSTANT.permissionPath}.eliminar`] &&
                        <DropdownMenuLink
                          text='Borrar'
                          icon="X"
                          onClick={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }), 'delete')}
                        />
                      }
                    </DropdownWrapper>
                  </td>
                </tr>
              )
            })
            : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={9}><i>Sin datos para mostrar...</i></td></tr>
          }
        </tbody>
      </Table>
    </AccordionTable >
  )
}

export default BannersTable
import {
   FILTER_ADD_CATEGORIA,
   FILTER_REMOVE_CATEGORIA,
   FILTER_REMOVE_OFERTA,
   FILTER_ADD_OFERTA,
   FILTER_BUSQUEDA,
   RESULTADOS_PRODUCTOS_OFERTAS,
   FILTER_CLEAR_PRODUCTOS_OFERTAS,
   FILTER_SKUS_PRODUCTO
} from "../redux-types";


const initialState = {
   filtros: {
      categorias_id: [],
      ofertas_ids: [],
      busqueda: '',
      skus: ''
   },
   resultados: null
}


export const productosOfertasReducer = (state = initialState, action) => {
   let isListed

   switch (action.type) {
      case FILTER_ADD_CATEGORIA:
         isListed = state.filtros.categorias_id.find(el => el === action.payload)

         return {
            ...state, filtros: {
               ...state.filtros,
               categorias_id: isListed
                  ? state.filtros.categorias_id
                  : [...state.filtros.categorias_id, action.payload]
            }
         };

      case FILTER_REMOVE_CATEGORIA:
         return {
            ...state, filtros: {
               ...state.filtros,
               categorias_id: state.filtros.categorias_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_OFERTA:
         isListed = state.filtros.ofertas_ids.find(el => el === action.payload)

         return {
            ...state, filtros: {
               ...state.filtros,
               ofertas_ids: isListed
                  ? state.filtros.ofertas_ids
                  : [...state.filtros.ofertas_ids, action.payload]
            }
         };

      case FILTER_REMOVE_OFERTA:
         return {
            ...state, filtros: {
               ...state.filtros,
               ofertas_ids: state.filtros.ofertas_ids.filter(el => el !== action.payload)
            }
         };

      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda: action.payload
            }
         };

      case FILTER_SKUS_PRODUCTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               skus: action.payload
            }
         };

      case RESULTADOS_PRODUCTOS_OFERTAS:
         return {
            ...state,
            resultados: action.payload
         };

      case FILTER_CLEAR_PRODUCTOS_OFERTAS:
         return initialState;

      default:
         return state;
   }
};
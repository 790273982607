import { Navigate, Route, Routes } from "react-router-dom"
import { useSelector } from "react-redux";



// Routers
import RespuestasRouter from "./Garantias/RespuestasRouter";



// Views
import Detalle from '../views/Garantias/Detalle';
import Listado from '../views/Garantias/Listado';
import Form from "../views/Garantias/Form";



const GarantiasRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />


         {user.permisos['garantias.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         {user.permisos['garantias.editar'] &&
            <Route path='/update/:id' element={<Form />} />
         }

         <Route path='/respuestas/*' element={<RespuestasRouter />} />

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}

export default GarantiasRouter;
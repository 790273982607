import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import AccordionTable from '../../layout/AccordionTable'
import Accordion from '../Accordion'
import AccordionDetailData from '../AccordionDetailData'
import Button from '../Button'
import Modal from '../Modal'
import Table from '../Table'
import { NoResults } from './Misc/NoResults'

const ModalDetalle = ({ dataArr, display, handleDisplay }) => (
  <Modal display={display} handleValue={handleDisplay}>
    {dataArr.map(data => (
      <Accordion
        title={`Agendamiento #${data?.id}`}
        isOpen
        classes='mb-1'
      >
        <AccordionDetailData dataKey={'# Pedido'} value={
          <a href={`/erp/nota-venta/detalle/${data?.id ?? 'N/A'}`} rel='noreferrer' target='_blank'>
            {data?.id ?? 'N/A'}
          </a>}
        />
        <AccordionDetailData dataKey={'# Nota de venta'} value={
          data?.nota_venta
            ? <a href={`/erp/nota-venta/detalle/${data?.nota_venta}`} rel='noreferrer' target='_blank'>
              {data?.nota_venta ?? 'N/A'}
            </a>

            : 'N/A'
        }
        />
        <AccordionDetailData dataKey={'Dirección de entrega'} value={data?.direccion_entrega ?? 'N/A'} />
        <AccordionDetailData dataKey={'Cliente'} value={data?.usuario?.nombre ?? 'N/A'} />
        <AccordionDetailData dataKey={'Método de pago'} value={data?.metodo_pago?.nombre ?? 'N/A'} />
        <AccordionDetailData dataKey={'Monto total'} value={data?.monto_total_pedido ?? 'N/A'} />
        <AccordionDetailData dataKey={'Productos totales'} value={data?.total_productos ?? 'N/A'} />
        <AccordionDetailData dataKey={'Creación'} value={data?.created_at.split('T')[0] ?? 'N/A'} />
        <AccordionDetailData dataKey={'Obsevaciones'} value={data?.observaciones ?? 'Sin observaciones'} last />
      </Accordion>
    ))}
  </Modal>
)


const AgendamientosPuntoEntregaTable = () => {
  const { resultados } = useSelector(state => state.agendamientosPuntoEntrega)
  const [modalDetalleData, setModalDetalleData] = useState([])
  const [modalDisplay, setModalDisplay] = useState(false)

  const handleModal = (data) => {
    setModalDisplay(true)
    setModalDetalleData(data)
  }

  return (
    <div>
      <ModalDetalle dataArr={modalDetalleData} display={modalDisplay} handleDisplay={() => setModalDisplay(false)} />

      <AccordionTable title={`Horas disponibles (${resultados?.length ?? 0} resultados)`}>
        <Table pb={false}>
          <thead className='table-light position-sticky top-0 w-100 z-100 shadow'>
            <tr>
              <th>Fecha</th>
              <th className='text-center'>Intérvalos</th>
            </tr>
          </thead>
          <tbody>
            {typeof resultados === 'object' && resultados?.length
              ? resultados.map(({ fecha, nombre_dia, intervalos }) => (
                <tr key={`fecha-${fecha}-${nombre_dia}`} className='multi-data' style={{ border: 'none' }}>
                  <td
                    className='p-0 px-50 m-0 text-center'
                    style={{
                      backgroundColor: '#ffe6e6',
                      height: '100%',
                      borderRight: '1px solid #999'
                    }}
                  >
                    <p className='fw-bold text-black fs-3'>{nombre_dia?.toUpperCase()}</p>
                    <p className='fw-bold fs-5'>{fecha}</p>
                  </td>
                  <td className='p-0'>
                    <div className='d-flex gap-1 p-50'
                      style={{
                        backgroundColor: '#ebf5fa'
                      }}>
                      {intervalos.length
                        ? intervalos.map(({ hora_inicio, hora_final, max_clientes, agendamientos, sobrecupo, id }) => (
                          <div key={`hora-${id}-${hora_inicio}-${hora_final}`} className='p-1 d-flex rounded flex-column'
                            style={{
                              backgroundColor: agendamientos.length ? '#c4ebb7' : '#fff',
                              border: '1px solid #bbb'
                            }}
                          >
                            <b className='text-center'>{hora_inicio} - {hora_final}</b>
                            <span className='sub-text-2 mb-1 text-center'>Max. clientes: <b>{max_clientes} ({sobrecupo && `+${sobrecupo} sobrecupo`})</b></span>
                            {agendamientos.length
                              ? <Button text='Ver agendamientos' onClick={() => handleModal(agendamientos)} />
                              : <Button text='Sin agendamientos' className='justify-content-center' style='flat' color='danger' />
                            }
                          </div>
                        ))
                        : <p>Sin intérvalos asignados</p>
                      }
                    </div>
                  </td>
                </tr>
              ))
              : <tr><td colSpan={2} className='text-center'><NoResults /></td></tr>
            }
          </tbody>
        </Table>
      </AccordionTable>
    </div>
  )
}

export default AgendamientosPuntoEntregaTable
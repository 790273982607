import {
   RESULTADOS_PRODUCTOS_IMAGENES,
   FILTER_CLEAR_PRODUCTOS_IMAGENES
} from "../redux-types";


const initialState = {
   resultados: []
}


export const productosImagenesReducer = (state = initialState, action) => {
   switch (action.type) {
      case RESULTADOS_PRODUCTOS_IMAGENES:
         return {
            ...state,
            resultados: action.payload
         };

      case FILTER_CLEAR_PRODUCTOS_IMAGENES:
         return initialState;

      default:
         return state;
   }
};
import { Navigate, Route, Routes } from "react-router-dom"
import Create from "../../views/CriteriosComparacion/Create";
import Detalle from "../../views/CriteriosComparacion/Detalle";
import Listado from "../../views/CriteriosComparacion/Listado";
import Update from "../../views/CriteriosComparacion/Update";
import { useSelector } from "react-redux";



const CriteriosComparacionRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['criterios-comparacion.crear'] &&
            <Route path='/create' element={<Create />} />
         }

         {user.permisos['criterios-comparacion.editar'] &&
            <Route path='/update/:id' element={<Update />} />
         }

         {user.permisos['criterios-comparacion.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}

export default CriteriosComparacionRouter;
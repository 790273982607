import {
   FILTER_ADD_PAIS,
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   FILTER_REMOVE_PAIS,
   RESULTADOS_USUARIOS,
} from "../redux-types";

const initialState = {
   filtros: {
      busqueda: '',
      pais_id: []
   },
   resultados: null
}


export const usuariosReducer = (state = initialState, action) => {
   let isListed

   switch (action.type) {
      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda: action.payload
            }
         };

      case FILTER_ADD_PAIS:
         isListed = state.filtros.pais_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               pais_id: isListed
                  ? state.filtros.pais_id
                  : [...state.filtros.pais_id, action.payload]
            }
         };

      case FILTER_REMOVE_PAIS:
         return {
            ...state, filtros: {
               ...state.filtros,
               pais_id: state.filtros.pais_id.filter(el => el !== action.payload)
            }
         };

      case RESULTADOS_USUARIOS:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
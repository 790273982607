
// Components
import FaqSubcategoriasFiltros from "../../components/AccordionFilter/FAQSubcategorias";
import FaqSubcategoriasTable from "../../components/Tables/FaqSubcategoriasTable";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";


const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>Preguntas frecuentes - Subcategorías</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text='Crear subcategoría (FAQ)' href={`create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<FaqSubcategoriasFiltros />

			<FaqSubcategoriasTable />
		</div>

	);
}

export default Listado;
import { useEffect, useState } from "react";
import axios from "axios";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import CheckBoxInput from "../../components/CheckboxInput";
import CustomLoader from "../../components/CustomLoader";
import SelectFilter from "../../components/SelectFilter";


// React-router
import { useNavigate } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";
import { getAll } from "../../endpoints/getAll";


// Helpers
import capitalize from "../../helpers/capitalize";


const CONSTANT = {
  redirectUrl: '/roles',
  componentTitle: 'Crear rol',
  title: 'Roles',
  queryUrl: create.roles,
  fetchPermisos: getAll.permisos,
  initialFormState: {
    nombre: '',
    user_type: '',
    permisos: [],
  },
  selectFilterInitialState: {
    permisos: [],
    user_type: ['web', 'admin']
  }
}


const handleFetchSelectData = async () => {
  const permisos = await axios(CONSTANT.fetchPermisos,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => data)
    .catch(err => {
      console.error(err)
      return ['*NULL*-Error']
    })

  return { permisos }
}


const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.initialFormState)
  const [selectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [loader, setLoader] = useState(false)


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { value, name } = e.target

    if (name.includes('cb')) {
      const [_, permisoId, permisoData] = name.split('%-%')
      const [grupo] = permisoData.split('.')

      return setForm({
        ...form,
        permisos: {
          ...form.permisos,
          [grupo]: form.permisos[grupo].map(el => (
            el.id === Number(permisoId)
              ? { ...el, selected: !el.selected }
              : el
          ))
        }
      })
    }

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCION MANEJADORA DE BOTOS HEADERS DE PERMISOS
  const handleButtonsPermisos = (target) => {
    let newObj = form.permisos

    Object.entries(newObj).forEach(([grupoKey, grupoArr]) => {
      newObj = {
        ...newObj,
        [grupoKey]: grupoArr.map(el => ({
          ...el,
          selected: (target === 'seleccionar' && (form.user_type.split('-')[0] === el.user_type))
            ? true
            : false
        }))
      }
    })

    setForm({
      ...form,
      permisos: newObj
    })
  }


  // EFECTO QUE RELLENA LOS SELECT FILTERS
  useEffect(() => {
    handleFetchSelectData().then(({ permisos }) => {
      let permisosObj = {}

      permisos.forEach(({ id, group, name, user_type }) => {
        if (permisosObj?.[group]?.length) {
          return permisosObj = {
            ...permisosObj,
            [group]: [
              ...permisosObj[group],
              { id, name, user_type, selected: false }
            ]
          }
        }

        return permisosObj = {
          ...permisosObj,
          [group]: [{ id, name, user_type, selected: false }]
        }
      })

      setForm(form => (
        {
          ...form,
          permisos: permisosObj
        }
      ))
    })
  }, [])


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    setLoader(true)

    let data = {
      nombre: form.nombre,
      user_type: form.user_type.split('-')[0],
      permisos: Object.values(form.permisos).flat().filter(({ selected }) => selected).map(({ id }) => id),
    }

    axios(CONSTANT.queryUrl,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data
      })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.name} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })

    setLoader(false)
  }

  return (
    <div className="row">
      {loader && <CustomLoader blocking={'partial'} />}

      <div className="col col-md-12 mx-auto" >
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {Object.keys(form?.permisos)?.length
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <FormInput
                labelText='* Nombre'
                placeholder='Nombre...'
                name='nombre'
                value={form.nombre}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-3'
              />

              <SelectFilter
                labelText='* Tipo de usuario'
                name='user_type'
                value={form?.user_type?.split('-')[0]}
                handleValue={handleInputChange}
                optionObj={selectFilter.user_type.map(el => ({ id: el, name: el }))}
                size='col-12'
                sizeDesk='col-md-3'
              />

              <div className="d-flex justify-content-between mb-25">
                <b className="fs-4">* Permisos</b>
                <div className="d-flex gap-1">
                  <Button
                    className='p-25'
                    text='Seleccionar todo'
                    onClick={() => handleButtonsPermisos('seleccionar')}
                  />

                  <Button
                    className='p-25'
                    text='Deseleccionar todo'
                    onClick={() => handleButtonsPermisos('deseleccionar')}
                  />
                </div>
              </div>
              <hr className="m-0 mb-1" />

              <div className="row mb-1 gap-1 justify-content-around">
                {form.user_type.length
                  ? Object.entries(form.permisos).map(([k, v]) => (
                    <div key={k} className="col-3 mt-1 border rounded p-1">
                      <p className="fw-bold fs-3 text-center">{capitalize(k)}</p>

                      {v.map(permiso => (
                        permiso.user_type === form.user_type.split('-')[0]
                          ? <div className="col-12" key={`cb-${permiso.id}-${permiso.name}`}>
                            <CheckBoxInput
                              name={`cb%-%${permiso.id}%-%${permiso.name}`}
                              handleValue={handleInputChange}
                              labelText={`${permiso.name.split('.')[1]}`}
                              value={form?.permisos[k]?.find(el => el?.id === permiso.id)?.selected}
                            />
                          </div>

                          : null
                      ))}
                    </div>
                  )).filter(el => el !== null)

                  : <span>Seleccione un Guard Name...</span>
                }
              </div>
              <hr className="m-0 mb-1" />
            </div>

            <Button
              disabled={loader}
              type="submit"
              text="Crear"
              color="primary"
              icon="Plus"
              onClick={(e) => handleFetch(e)}
            />
          </CustomForm>

          : <CustomLoader />
        }
      </div>
    </div >
  );
};

export default Create;

import {
   FILTER_CLEAR,
   FILTER_DIRECCION,
   FILTER_NOMBRE,
   FILTER_RUT_CLIENTE,
   RESULTADOS_PROVEEDORES,
} from "../redux-types";

export const nombreFilter = (data) => ({ type: FILTER_NOMBRE, payload: data })

export const rutFilter = (data) => ({ type: FILTER_RUT_CLIENTE, payload: data })

export const direccionFilter = (data) => ({ type: FILTER_DIRECCION, payload: data })

export const resultadosProveedores = (data) => ({ type: RESULTADOS_PROVEEDORES, payload: data })

export const clearFilterProveedores = () => ({ type: FILTER_CLEAR });

import { CLEAR_MODAL_PRODUCTOS, DISPLAY_MODAL, FILTER_ADD_CLAVE, FILTER_REMOVE_CLAVE, FILTROS_PERSISTENTES_MODAL_PRODUCTOS, RESULTADOS_MODAL_PRODUCTOS } from "../redux-types";

export const clavesFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_CLAVE, payload: data }
      : { type: FILTER_ADD_CLAVE, payload: data };

export const clearFiltersModal = () => ({ type: CLEAR_MODAL_PRODUCTOS })

export const resultadosFiltersModal = (data) => ({ type: RESULTADOS_MODAL_PRODUCTOS, payload: data })
export const filtrosPersistentesModal = (data) => ({ type: FILTROS_PERSISTENTES_MODAL_PRODUCTOS, payload: data })

export const displayModal = (data) => ({ type: DISPLAY_MODAL, payload: data })
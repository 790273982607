import { Route, Routes } from "react-router-dom"
import Form from "../../views/ProductoEnProveedor/Form"
import Listado from "../../views/ProductoEnProveedor/Listado"
import Detalle from "../../views/ProductoEnProveedor/Detalle"


const ProductosEnProveedorRouter = () => {
   return (
      <Routes>
         <Route path='/' element={<Listado />} />
         <Route path='/create' element={<Form />} />
         <Route path='/update/:id' element={<Form />} />
         <Route path='/detalle/:id' element={<Detalle />} />
      </Routes>
   )
}

export default ProductosEnProveedorRouter
import axios from 'axios'
import { useEffect, useState } from 'react'


// Redux
// import { fireToaster } from '../../redux/actions/toaster'
// import { useDispatch } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
// import Switch from '../../components/Switch'
import CustomLoader from '../../components/CustomLoader'


// Router Dom
import {
  // useNavigate,
  useParams
} from 'react-router-dom'


// Endpoints
import { getOne } from '../../endpoints/getOne'
import { TablaRecursiva } from '../../components/Historico/TablaRecursiva'


const CONSTANT = {
  redirectListado: '/historial-cambios',
  // redirectUpdate: (id) => `/historial-cambios/update/${id}`,
  queryUrlGetOne: getOne.historialCambios,
  title: 'Historial de cambios',
}


const Detalle = () => {
  const [data, setData] = useState(null)
  const { id } = useParams()
  // const dispatch = useDispatch()
  // const redirect = useNavigate()


  // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
  useEffect(() => {
    axios(CONSTANT.queryUrlGetOne(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => setData({ ...data, anterior: CONSTANT.test }))
      .catch(err => console.error(err))

    return () => {
      setData(null)
    }
  }, [id])


  // FUNCIÓN MANEJADORA PARA EDICION RAPIDA
  // const handleFetch = async (objData, target) => {
  //   let config

  //   if (target === 'estatus') {
  //     let newEstatus = objData.estatus ? 0 : 1
  //     config = {
  //       method: 'POST',
  //       data: {
  //         [target]: newEstatus,
  //         _method: 'PUT'
  //       }
  //     }
  //   }

  //   if (target === 'delete') {
  //     config = {
  //       method: 'DELETE',
  //     }
  //   }

  //   config = {
  //     ...config,
  //     headers: {
  //       ...config.headers,
  //       'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
  //     }
  //   }

  //   await axios(CONSTANT.queryUrlGetOne(objData.id), config)
  //     .then(res => {
  //       const toasterContent = {
  //         title: 'Operación realizada',
  //         text: `${CONSTANT.title}: registro actualizado con éxito`,
  //         icon: 'success'
  //       }

  //       if (target === 'delete') {
  //         redirect(CONSTANT.redirectListado)
  //       }

  //       dispatch(fireToaster(toasterContent))
  //     })
  //     .catch(err => {
  //       console.error(err);
  //       const { errores } = err.response.data
  //       let detalles = []
  //       Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

  //       const toasterContent = {
  //         title: `
  //           Operación fallida
  //           (${err.response.status} - ${err.response.statusText})
  //         `,
  //         html: `
  //           <b>Detalle: </b>
  //           ${detalles.map(error => `<br /><i>-${error}</i>`)}
  //         `,
  //         icon: 'error'
  //       }

  //       dispatch(fireToaster(toasterContent))
  //     })

  //   if (target === 'delete') return redirect(CONSTANT.redirectListado)

  //   setData(null)
  //   axios(CONSTANT.queryUrlGetOne(id))
  //     .then(({ data }) => setData(data))
  //     .catch(err => console.error(err))
  // }

  return (
    <div className='row'>
      <div className='col-12'>
        <div className='col mb-50'>
          <div className='d-flex gap-1'>
            <div className='col'>
              <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
            </div>
            {/* <Button text='Editar' icon='Settings' color='success' isLink url={CONSTANT.redirectUpdate(id)} /> */}
            {/* <Button text='Borrar' icon='Trash' color='danger' onClick={() => handleFetch(data, 'delete')} /> */}
          </div>
        </div>


        {data
          ? <>
            <div className='col-12 col-md-6 mx-auto mb-2'>
              <Accordion
                title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
                isOpen
              >
                <AccordionDetailData dataKey={'ID'} value={data?.id} />
                <AccordionDetailData dataKey={'Usuario'} value={data?.usuario?.nombre ?? 'N/A'} />
                <AccordionDetailData dataKey={'Acción'} value={<b>{data?.accion ?? 'N/A'}</b>} />
                <AccordionDetailData dataKey={'Evento'} value={<b>{data?.evento?.nombre ?? 'N/A'}</b>} />
                <AccordionDetailData dataKey={'Modelo'} value={<b>{data?.modelo ?? 'N/A'}</b>} />
                <AccordionDetailData dataKey={'Creación'} value={data?.created_at?.split('T')[0] ?? 'N/A'} />
                <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at?.split('T')[0] ?? 'N/A'} last />
              </Accordion>

            </div>

            {/* TABLA NUEVO */}
            <div className='col-12 mb-2'>
              {data?.nuevo &&
                <Accordion
                  title={<h3 className='m-0 w-100 text-center text-white'>Nuevo</h3>}
                  isOpen
                >
                  {Object.entries(data?.nuevo).map(([k, v], i, arr) => (
                    <AccordionDetailData key={`data-nuevo-${i}`} dataKey={k.toUpperCase()} value={<TablaRecursiva data={v} />} last={i === arr.length - 1} />
                  ))}
                </Accordion>
              }
            </div>

            {/* TABLA ANTERIOR */}
            <div className='col-12 mb-2'>
              {data?.anterior &&
                <Accordion
                  title={<h3 className='m-0 w-100 text-center text-white'>Anterior</h3>}
                  isOpen
                >
                  {Object.entries(data?.anterior).map(([k, v], i, arr) => (
                    <AccordionDetailData key={`data-anterior-${i}`} dataKey={k.toUpperCase()} value={<TablaRecursiva data={v} />} last={i === arr.length - 1} />
                  ))}
                </Accordion>
              }
            </div>
          </>

          : <CustomLoader />
        }

      </div>
    </div >
  )
}

export default Detalle
import axios from 'axios'
import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { FILTER_ADD_ATRIBUTOS, FILTER_ADD_PUNTO_ENTREGA } from '../../redux/redux-types'
import { atributosFilter, clearFilterParametrosRecargos, filtrosPersistentesParametrosRecargos, puntosEntregaFilter, resultadosParametrosRecargos } from '../../redux/actions/parametrosRecargos'


// Components
import FiltroAplicado from './FiltroAplicado'
import SelectFilter from '../SelectFilter'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'
import { persistentFilters } from '../../helpers/pesistentFilters'
import { fecthSelectFiltersData } from '../../helpers/fetchSelectFilterData'
import { idExtractor } from '../../helpers/idExtractor'
import { lastPosition } from '../../helpers/lastPosition'


const CONSTANT = {
  queryUrlGetAll: getAll.parametros_recargos + `/listar`,
  redirectUrl: '/generales/parametros-recargos',
  title: 'Búsqueda de parámetros',

  reduxSelector: 'parametrosRecargos',
  reduxClearFilters: clearFilterParametrosRecargos,
  reduxSetResults: resultadosParametrosRecargos,

  queryUrl: (results) => CONSTANT.queryUrlGetAll + `?limit=${results}`,

  selectFilter: {
    puntosEntregas: [],
    atributos: []
  }
}


const selectFilterQueries = [
  {
    url: getAll.puntos_entrega + `/listar`,
    options: { method: 'POST' },
    key: 'puntosEntregas',
  },
  {
    url: getAll.valores_atributos + `/listar`,
    options: { method: 'POST' },
    key: 'atributos',
  }
]


const ParametrosRecargosFiltros = () => {
  const { filtros } = useSelector((redux) => redux[CONSTANT.reduxSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const {
    puntos_entregas_ids,
    atributos_ids
  } = filtros
  const dispatch = useDispatch()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RECOJE LA DATA DE LOS SELECTFILTERS
  useEffect(() => {
    fecthSelectFiltersData(selectFilterQueries).then(res => setSelectFilter(res))
    return () => {
      setSelectFilter(CONSTANT.selectFilter)
    }
  }, [])


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    const filtrosPersistentesObj = persistentFilters(window.location, filtros).obj
    let url = CONSTANT.queryUrl(showing_results)
    let config = {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    }

    if (filtrosPersistentesObj) {
      dispatch(filtrosPersistentesParametrosRecargos(filtrosPersistentesObj))
      url = CONSTANT.queryUrl(showing_results) + queryParamsFormatter({ busqueda: filtrosPersistentesObj.busqueda })
      config = { ...config }
    }

    axios(url, config)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
      })
      .catch(err => {
        console.error(err)
        dispatch(CONSTANT.reduxSetResults([]))
      })
  }, [dispatch, showing_results])


  // EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
  useEffect(() => {
    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = (reset = false) => {
    let config = {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    }

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}`, config)
        .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
        .catch(err => {
          console.error(err)
          dispatch(CONSTANT.reduxSetResults([]))
        })
    }

    config = {
      ...config,
      data: {
        puntos_entregas_ids: puntos_entregas_ids?.length ? puntos_entregas_ids.map(el => Number(idExtractor(el, true))) : undefined,
        atributos_ids: puntos_entregas_ids?.length ? atributos_ids.map(el => Number(idExtractor(el, true))) : undefined,
      }
    }

    setIsConsulting(true)

    axios(CONSTANT.queryUrl(showing_results), config)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
        window.history.pushState({}, '', persistentFilters(window.location, filtros).urlWithFilters)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
            `,
          html: `
            <b> Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
            `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
        CONSTANT.reduxSetResults([])
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_ADD_PUNTO_ENTREGA:
        dispatch(puntosEntregaFilter(value))
        break

      case FILTER_ADD_ATRIBUTOS:
        dispatch(atributosFilter(value))
        break

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <SelectFilter
            altSeparator
            labelText="Puntos de entrega"
            size="col-12"
            sizeDesk="col-md-11"
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_PUNTO_ENTREGA)}
            optionObj={selectFilter?.puntosEntregas?.map(({ id, direccion, neu_tipo_destino, tipo_destino, comuna }) => ({ id, name: `${neu_tipo_destino?.nombre ?? tipo_destino?.nombre ?? 'Sin tipo destino'} | ${direccion ?? 'Sin dirección'} | ${comuna?.nombre ?? 'Sin comuna'}, ${comuna?.neu_region?.nombre ?? comuna?.region?.nombre ?? 'Sin región'}` }))}
            value={lastPosition(puntos_entregas_ids, 'Seleccione', '%-%')}
          />

          <SelectFilter
            altSeparator
            labelText='Atributos'
            size='col-12'
            sizeDesk='col-md-3'
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_ATRIBUTOS)}
            optionObj={selectFilter?.atributos?.map(({ id, nombre, neu_clave_atributo }) => ({ id, name: `${neu_clave_atributo?.nombre ?? 'Desconocido'} | ${nombre}` }))}
            value={lastPosition(atributos_ids, 'Seleccione', '%-%')}
          />

        </>
        {/* FILTROS APLICADOS - PRODUCTOS */}
        <p className='mb-25 text-black'>Filtros aplicados</p>
        <div className='d-flex column'>
          <FiltroAplicado title='Atributos' array={atributos_ids} func={atributosFilter} separator='%-%' />
          <FiltroAplicado title='Puntos de entrega' array={puntos_entregas_ids} func={puntosEntregaFilter} separator='%-%' />
        </div>
        <hr className='my-1' />
      </ListadoHeader>
    </div>
  )
}

export default ParametrosRecargosFiltros
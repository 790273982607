import {
   FILTER_ADD_CATEGORIA,
   FILTER_REMOVE_CATEGORIA,
   FILTER_REMOVE_OFERTA,
   FILTER_ADD_OFERTA,
   FILTER_BUSQUEDA,
   RESULTADOS_PRODUCTOS_OFERTAS,
   FILTER_CLEAR_PRODUCTOS_OFERTAS,
   FILTER_SKUS_PRODUCTO
} from "../redux-types";


export const categoriaFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_CATEGORIA, payload: data }
      : { type: FILTER_ADD_CATEGORIA, payload: data };

export const ofertasFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_OFERTA, payload: data }
      : { type: FILTER_ADD_OFERTA, payload: data };

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const skusFilter = (data) => ({ type: FILTER_SKUS_PRODUCTO, payload: data })

export const resultadosProductosOfertas = (data) => ({ type: RESULTADOS_PRODUCTOS_OFERTAS, payload: data })

export const clearFilterProductosOfertas = () => ({ type: FILTER_CLEAR_PRODUCTOS_OFERTAS });

import axios from 'axios';

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { clearFilterClientes, resultadosClientes } from '../../redux/actions/busquedaClientes';


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Table from "../Table";


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter';


const CONSTANT = {
  pathUrl: 'busqueda-clientes',
  permissionsPath: 'busqueda-clientes',
  reduxSelector: 'busquedaClientes',
  queryUrlGetOne: getOne.busquedas_clientes,
  title: 'Búsqueda clientes',
  reduxClearFilters: clearFilterClientes,
  reduxSetResults: resultadosClientes
}


const BusquedaClientesTable = () => {
  const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const { busqueda } = filtros
  const { user } = useSelector(state => state.auth)


  const handleUrl = (url) => {
    dispatch(CONSTANT.reduxSetResults(null))
    axios(url + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
      .catch(err => console.error(err))
  }


  return (
    <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
      {!response && <CustomLoader blocking={'partial'} />}

      <Table response={response} handlePagination={handleUrl}>
        <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
          <tr role="row">
            <th>ID</th>
            <th>Búsqueda</th>
            <th>Filtro precio</th>
            <th>Medidas</th>
            <th>Comuna</th>
            {/* <th className="w-max">Acciones</th> */}
          </tr>
        </thead>
        <tbody>
          {response?.data?.length
            ? response.data?.map((data) => {
              return (
                <tr className="odd" key={`busqueda-cliente-${data.id}`}>
                  <td>{data?.id}</td>

                  <td>{data?.string_busqueda ?? "N/A"}</td>

                  <td>
                    <div className='d-flex flex-column'>
                      <span>Desde: <b>$ {`${data?.filtro_precio_desde ?? 'N/A'}`}</b></span>
                      <span>Hasta: <b>$ {`${data?.filtro_precio_hasta ?? 'N/A'}`}</b></span>
                    </div>
                  </td>

                  <td>
                    <div className='d-flex flex-column gap-50'>
                      <span>Ancho: <b>{data?.ancho ?? 'N/A'}</b></span>
                      <span>Alto: <b>{data?.alto ?? 'N/A'}</b></span>
                      <span>Aro: <b>{data?.aro ?? 'N/A'}</b></span>
                    </div>
                  </td>

                  <td>
                    <div className='d-flex flex-column'>
                      <span>Comuna: <b>{data?.comuna?.nombre ?? 'N/A'}</b></span>
                      <span>Región: <b>{data?.comuna?.region?.nombre ?? 'N/A'}</b></span>
                    </div>
                  </td>

                  {/* <td>
                    <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                      {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                        <DropdownMenuLink text='Detalle' href={`/${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
                      }
                    </DropdownWrapper>
                  </td> */}
                </tr>
              )
            })
            : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
          }
        </tbody>
      </Table>
    </AccordionTable >
  )
}

export default BusquedaClientesTable
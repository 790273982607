import { LISTED_RESULTS } from "../redux-types"

const initialState = {
   showing_results: 20
}

export const listedResultsReducer = (state = initialState, action) => {
   switch (action.type) {
      case LISTED_RESULTS:
         return {
            ...state,
            showing_results: action.payload
         }

      default:
         return state
   }
}
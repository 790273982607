import axios from "axios";
import { useEffect, useState } from "react";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import CustomLoader from "../../components/CustomLoader";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";
import { getAll } from "../../endpoints/getAll";
import SelectFilter from "../../components/SelectFilter";
import { idExtractor } from "../../helpers/idExtractor";
import { getOne } from "../../endpoints/getOne";


const CONSTANT = {
  redirectUrl: '/proveedores',
  componentTitle: id => `${id ? 'Editar' : 'Crear'} proveedor`,
  btnTitle: id => id ? 'Guardar' : 'Crear',
  title: 'Proveedor',
  queryUrl: create.proveedores,
  queryGet: getOne.proveedores,
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },

  fetchProveedoresErp: getAll.erp_proveedores + `/obtener`,

  initialForm: {
    nombre: '',
    rut: '',
    direccion: '',
    email: '',
    fono: '',
    estatus: true
  },

  selectFilter: {
    proveedoresErp: []
  }
}


const handleSelectFiltersData = async () => {
  const proveedoresErp = await axios(CONSTANT.fetchProveedoresErp,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { proveedoresErp }
}


const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.initialForm);
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const { id } = useParams()


  // EFECTO QUE CONSULTA LA DATA DEL REGISTRO (UPDATE)
  useEffect(() => {
    if (!id) return
    axios(CONSTANT.queryGet(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        const selectedRut = selectFilter.proveedoresErp.find(({ rut_proveedor }) => String(data?.rut) === String(rut_proveedor))
        setForm({
          ...data,
          rut: `${selectedRut?.rut_proveedor ?? '*NULL*'}%-% ${selectedRut?.rut_proveedor ?? 'N/A'} | ${selectedRut?.nombre_proveedor ?? 'Sin selección'}`
        })
      })
      .catch(err => console.error(err))

    return () => {
      setForm(null)
    }
  }, [id, selectFilter.proveedoresErp])


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    let url = id ? CONSTANT.queryUrl + `/${id}` : CONSTANT.queryUrl

    let data = {
      nombre: form?.nombre,
      rut: idExtractor(form?.rut, true),
      direccion: form?.direccion,
      fono: form?.fono,
      email: form?.email,
      estatus: Number(form?.estatus) ? true : false
    }

    axios(url, {
      method: id ? 'PUT' : 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data?.nombre} ${id ? 'creado' : 'actualizado'} con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto" >
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        {selectFilter.proveedoresErp.length
          ? <CustomForm size="" title={CONSTANT.componentTitle(id)}>
            <div className="row">
              <Switch
                labelText='Estatus'
                name='estatus'
                value={form?.estatus}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <FormInput
                labelText='* Nombre'
                name='nombre'
                value={form?.nombre}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <SelectFilter
                altSeparator
                labelText='* RUT'
                placeholder='RUT'
                name='rut'
                value={form?.rut?.split('%-%')[1] ?? 'Seleccione'}
                handleValue={handleInputChange}
                optionObj={selectFilter.proveedoresErp?.map(({ nombre_proveedor, rut_proveedor, id }) => ({ id: rut_proveedor ?? id ?? '*NULL*', name: `${rut_proveedor ?? 'N/A'} | ${nombre_proveedor ?? 'Error'}` }))}
                size="col-12"
                sizeDesk='col-md-12'
              />

              <FormInput
                labelText='* Dirección'
                name='direccion'
                value={form?.direccion}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <FormInput
                labelText='* Teléfono'
                type="number"
                name='fono'
                value={form?.fono}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='* Email'
                name='email'
                value={form?.email}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />
            </div>

            <Button type="submit" text={CONSTANT.btnTitle(id)} color="primary" icon="Check" onClick={handleFetch} />
          </CustomForm>

          : <CustomLoader />
        }
      </div >
    </div >
  );
};

export default Form;

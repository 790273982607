import { useState } from "react";
import axios from "axios";

// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import TextareaInput from "../../components/TextareaInput";


// React-router
import { useNavigate } from "react-router-dom";


// Enpoints
import { create } from "../../endpoints/create";


// Helpers
import { hourFormatter } from "../../helpers/hourFormatter";


const CONSTANT = {
  title: 'Eventos',
  componentTitle: 'Crear evento',
  redirectUrl: '/eventos',
  queryUrl: create.eventos,
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  initialFormState: {
    nombre: '',
    descripcion: '',
    fecha_inicio: '',
    hora_inicio: '',
    fecha_final: '',
    hora_final: '',
  },
  selectFilterInitialState: {
  }
}


const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.initialFormState);


  // MANEJADOR DE LOS INPUTS
  const dataFiller = (e) => {
    const { value, name } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    let requestData = {
      nombre: form.nombre,
      descripcion: form.descripcion,
      fecha_hora_inicio: `${form?.fecha_inicio} ${hourFormatter(form?.hora_inicio)}`,
      fecha_hora_final: `${form?.fecha_final} ${hourFormatter(form?.hora_final)}`,
    }

    axios(CONSTANT.queryUrl, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'application/json'
      },
      data: requestData
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data?.nombre} creada con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        <CustomForm size="" title={CONSTANT.componentTitle}>
          <div className="row">

            <FormInput
              labelText="* Nombre"
              name='nombre'
              placeholder='Nombre...'
              handleValue={dataFiller}
              value={form?.nombre}
              size="col-12"
              sizeDesk="col-md-6"
            />

            <TextareaInput
              labelText="Descripción"
              placeholder='Descripción...'
              name='descripcion'
              handleValue={dataFiller}
              value={form?.descripcion}
              size="col-12"
              sizeDesk="col-md-12"
              rows={2}
            />

            <FormInput
              labelText="* Fecha inicio"
              type="date"
              handleValue={dataFiller}
              max={form?.fecha_final}
              name='fecha_inicio'
              value={form?.fecha_inicio}
              size="col-12"
              sizeDesk="col-md-6"
            />

            <FormInput
              labelText="* Hora inicio"
              type="time"
              name='hora_inicio'
              handleValue={dataFiller}
              value={form?.hora_inicio}
              size="col-12"
              sizeDesk="col-md-6"
            />

            <FormInput
              labelText="* Fecha final"
              type="date"
              min={form?.fecha_inicio}
              name='fecha_final'
              handleValue={dataFiller}
              value={form?.fecha_final}
              size="col-12"
              sizeDesk="col-md-6"
            />

            <FormInput
              labelText="* Hora final"
              type="time"
              name='hora_final'
              handleValue={dataFiller}
              value={form?.hora_final}
              size="col-12"
              sizeDesk="col-md-6"
            />
          </div>

          <Button type="submit" text="Crear" color="primary" icon="Table" onClick={(e) => handleFetch(e)} />
        </CustomForm>

      </div>
    </div>
  );
};

export default Create;

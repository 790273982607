import axios from 'axios'
import { getAll } from '../../endpoints/getAll'


export const setterAsignaciones = async () => {
  const metodos_pago = await axios(getAll.metodos_pago,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => data)
    .catch(err => console.error(err))

  const puntos_entrega = await axios(getAll.puntos_entrega + '/listar?estatus=1',
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => data.map(el => (
      {
        ...el,
        filter_param: `
        ${el?.tipo_destino?.nombre?.toLowerCase() ?? 'N/A'}
        ${el?.comuna?.region?.nombre?.toLowerCase() ?? 'N/A'}
        ${el?.nombre?.toLowerCase() ?? 'N/A'}
        ${el?.direccion?.toLowerCase() ?? 'N/A'}
        ${el?.rut_proveedor?.toLowerCase() ?? 'N/A'}
        `
      }
    )))
    .catch(err => console.error(err))

  const ofertas = await axios(getAll.ofertas,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => data.ofertas.map(el => (
      {
        ...el,
        filter_param: `
        ${el?.nombre?.toLowerCase() ?? '?'} 
        ${el?.fecha_hora_inicio?.split('T')[0] ?? 'N/A'} 
        ${el?.fecha_hora_final?.split('T')[0] ?? 'N/A'} 
        ${el?.neu_oferta?.nombre ?? 'N/A'}
        `
      }
    )))
    .catch(err => console.error(err))

  const etiquetas = await axios(getAll.etiquetas + '/listar',
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => data.etiquetas.map(el => (
      {
        ...el,
        filter_param: `
        ${el?.nombre?.toLowerCase() ?? '?'}
        ${el?.fecha_hora_inicio?.split('T')[0] ?? 'N/A'} 
        ${el?.fecha_hora_final?.split('T')[0] ?? 'N/A'} 
        `
      }
    )))
    .catch(err => console.error(err))

  const adicionales = await axios(getAll.adicionales + '/listar',
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => data.adicionales.map(el => (
      {
        ...el,
        filter_param: `
        ${el?.nombre?.toLowerCase() ?? 'N/A'}
      `
      }
    )))
    .catch(err => console.error(err))

  return { metodos_pago, puntos_entrega, ofertas, etiquetas, adicionales }
}
import { Route, Routes } from "react-router-dom"
import Detalle from "../../views/Planificacion/Detalle";
import Index from "../../views/Planificacion/Index";


const PlanificacionRouter = () => {
   return (
      <Routes>
         <Route path='/' element={<Index />} />
         <Route path='/detalle/:id' element={<Detalle />} />
      </Routes>
   )
}

export default PlanificacionRouter;
import axios from 'axios'
import React, { useEffect, useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { busquedaFilter, clearFilterTiposElementos, creacionDesdeFilter, creacionHastaFilter, estatusFilter, nombreFilter, resultadosTiposElementos, tablaFilter } from '../../redux/actions/tiposElementos'
import { FILTER_ADD_ESTATUS, FILTER_ADD_NOMBRE, FILTER_ADD_TABLA, FILTER_BUSQUEDA, FILTER_CREACION_DESDE, FILTER_CREACION_HASTA } from '../../redux/redux-types'
import { fireToaster } from '../../redux/actions/toaster'


// Components
import FormInput from '../FormInput'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Hooks


// Helpers

import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'


const dataFormatter = (objFiltros) => {
  let data

  Object.entries(objFiltros).forEach(([k, v]) => {
    if (k === 'busqueda') return

    if (['created_at_desde', 'created_at_hasta'].includes(k) && v.length > 0) {
      data = { ...data, [k]: v }
    }

    if (v.length > 0 && k === 'estatus') {
      data = {
        ...data,
        filtros: {
          ...data?.filtros,
          [k]: v.map(el => el === 'Activo' ? 1 : 0)
        }
      }
      return
    }

    if (v.length > 0) {
      data = {
        ...data,
        filtros: {
          ...data?.filtros,
          [k]: v
        }
      }
    }
  })


  return data
}

const TiposElementosFiltros = () => {
  const { filtros } = useSelector(state => state.tiposElementos)
  const { showing_results } = useSelector(state => state.listedResults)
  const [nombres, setNombre] = useState(null)
  const dispatch = useDispatch()
  const { busqueda } = filtros

  const [isConsulting, setIsConsulting] = useState(false)


  useEffect(() => {
    axios(getAll.tipos_elementos + `/buscar?limit=${showing_results}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
      .then(res => dispatch(resultadosTiposElementos(res.data.tipos_elementos)))
      .catch(err => {
        console.error(err)
        dispatch(resultadosTiposElementos([]))
      })

    axios(getAll.tipos_elementos + '/buscar', {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
      .then(res => setNombre(res.data.tipos_elementos.data))
      .catch(err => {
        console.error(err)
      })
  }, [dispatch, showing_results])


  useEffect(() => {
    return () => dispatch(clearFilterTiposElementos())
  }, [dispatch])


  const handleFetchFilter = (reset = false) => {
    let data = {
      busqueda
    }

    setIsConsulting(true)

    if (reset) {
      dispatch(clearFilterTiposElementos())
      return axios(getAll.tipos_elementos + `/buscar?limit=${showing_results}`, {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST'
      })
        .then(res => dispatch(resultadosTiposElementos(res.data.tipos_elementos)))
        .catch(err => {
          console.error(err)
          dispatch(resultadosTiposElementos([]))
        })
        .finally(() => {
          setIsConsulting(false)
        })
    }

    data = { ...data, ...dataFormatter(filtros) }

    axios(getAll.tipos_elementos + `/buscar?limit=${showing_results}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      data
    })
      .then(res => {
        dispatch(resultadosTiposElementos(res.data.tipos_elementos))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO - ADICIONALES
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_BUSQUEDA:
        dispatch(busquedaFilter(value))
        break;

      case FILTER_ADD_ESTATUS:
        dispatch(estatusFilter(value))
        break;

      case FILTER_ADD_NOMBRE:
        dispatch(nombreFilter(value.split('-')[1]))
        break;

      case FILTER_CREACION_DESDE:
        dispatch(creacionDesdeFilter(value))
        break;

      case FILTER_CREACION_HASTA:
        dispatch(creacionHastaFilter(value))
        break;

      case FILTER_ADD_TABLA:
        dispatch(tablaFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`Búsqueda de tipos de elementos (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        {/* {filtrosPosibles && */}
        <>
          <FormInput
            labelText='Búsqueda'
            placeholder='Ingrese su búsqueda'
            size='col-12'
            sizeDesk='col-md-4'
            handleValue={(e) => handleAddFilter(e, FILTER_BUSQUEDA)}
            value={busqueda}
          />
          {/* 
               <Select
                  labelText='Estatus'
                  placeholder='Selecciona'
                  size='col-12'
                  sizeDesk='col-md-2'
                  options={mockEstatus}
                  handleValue={(e) => handleAddFilter(e, FILTER_ADD_ESTATUS)}
               />

               <SelectFilter
                  labelText='Nombres'
                  placeholder='Selecciona'
                  size='col-12'
                  sizeDesk='col-md-2'
                  optionObj={nombres?.map(({ nombre, id }) => ({ id, name: nombre }))}
                  handleValue={(e) => handleAddFilter(e, FILTER_ADD_NOMBRE)}
                  value={lastPosition(nombre)}
               /> */}

          {/* <SelectFilter
                  labelText='Tablas'
                  placeholder='Selecciona'
                  size='col-12'
                  sizeDesk='col-md-2'
                  optionObj={paises?.map(({ id, nombre }) => ({ id, name: nombre }))}
                  handleValue={(e) => handleAddFilter(e, FILTER_ADD_TABLA)}
                  value={lastPosition(tablas)}
               /> */}

          {/* <FormInput
                  type='date'
                  labelText='Creación (desde)'
                  size='col-12'
                  sizeDesk='col-md-2'
                  handleValue={(e) => handleAddFilter(e, FILTER_CREACION_DESDE)}
                  value={created_at_desde}
               />

               <FormInput
                  type='date'
                  labelText='Creación (hasta)'
                  size='col-12'
                  sizeDesk='col-md-2'
                  handleValue={(e) => handleAddFilter(e, FILTER_CREACION_HASTA)}
                  value={creacion_hasta}
               /> */}
        </>
        {/* } */}
        {/* FILTROS APLICADOS - ADICIONALES */}
        {/* <p className='mb-25 text-black'>Filtros aplicados</p>
            <div className='row'>
               <FiltroAplicado array={estatus} func={estatusFilter} title={'Estatus'} />
               <FiltroAplicado array={nombre} func={nombreFilter} title={'Nombres'} />
               <FiltroAplicado array={tablas} func={tablaFilter} title={'Tablas'} />
            </div> */}

        <hr className='my-1' />
      </ListadoHeader>
    </div>
  )
}

export default TiposElementosFiltros
import { useEffect, useState } from "react";
import axios from "axios";

//	Components
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import FormInput from "../../components/FormInput";
import CustomLoader from "../../components/CustomLoader";


// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


// React-router-dom
import { useNavigate, useParams } from "react-router-dom";
import SelectFilter from "../../components/SelectFilter";
import { getAll } from "../../endpoints/getAll";
import { create } from "../../endpoints/create";
import { getOne } from "../../endpoints/getOne";
import { idExtractor } from "../../helpers/idExtractor";


const CONSTANT = {
  redirectListado: `/pedidos/`,
  redirectDetalle: (id) => `/pedidos/detalle/${id}`,
  title: 'Pedidos',
  titleDetalle: 'Detalle del pedido',
  componenteTitle: 'Editar pedido',
  queryUrl: create.pedidos,
  fetchData: getOne.pedidos,
  queryUrlPuntosEntrega: getAll.puntos_entrega + '/listar?estatus=1',
  queryUrlMetodosPago: getAll.metodos_pago,
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },

  form: {
    pedido_id: '',
    puntoentrega_id: '',
    metodo_pago_id: '',
    direccion: ''
  },

  selectFilter: {
    puntosEntrega: [],
    metodosPago: [],
  }
}


const handleSelectFiltersData = async () => {
  const puntosEntrega = await axios(CONSTANT.queryUrlPuntosEntrega,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const metodosPago = await axios(CONSTANT.queryUrlMetodosPago,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'GET',
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { puntosEntrega, metodosPago }
}


const PedidosUpdate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.form)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const { id } = useParams()


  // FUNCIÓN MANEJADORA QUE ACTUALIZA EL ESTADO DE 'FetchData'
  const handleInputChange = (e) => {
    const { name, value } = e.target

    if (name === 'puntoentrega_id') {
      const selectedPuntoEntregaId = value.split('-')[0]
      const { direccion } = selectFilter.puntosEntrega.find(({ id }) => Number(id) === Number(selectedPuntoEntregaId)) ?? { direccion: '' }

      setForm({
        ...form,
        direccion: direccion ?? '',
        [name]: value
      })
      return
    }
    setForm({
      ...form,
      [name]: value,
    })
  }


  // EFECTO QUE RELLENA LOS SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // EFECTO QUE CONSULTA LA DATA DEL REGISTRO Y ACTUALIZA EL ESTADO 'FetchData'
  useEffect(() => {
    axios(CONSTANT.fetchData(id), {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
      }
    })
      .then(({ data }) => {
        if (!selectFilter.puntosEntrega.length) return

        const selectedPuntoEntrega = selectFilter?.puntosEntrega.find(({ id }) => id === data?.puntoentrega_id)
        const selectedMetodoPagoId = selectFilter?.metodosPago.find(({ id }) => id === data?.metodo_pago_id)

        setForm({
          ...data,
          pedido_id: data?.id,
          direccion: selectedPuntoEntrega?.direccion ?? '',
          puntoentrega_id: `${selectedPuntoEntrega.id ?? '*NULL*'}-${selectedPuntoEntrega?.neu_tipo_destino?.nombre ?? selectedPuntoEntrega?.tipo_destino?.nombre ?? 'Sin tipo destino'} | ${selectedPuntoEntrega?.direccion ?? 'Sin dirección'} | ${selectedPuntoEntrega?.comuna?.nombre ?? 'Sin comuna'}, ${selectedPuntoEntrega?.comuna?.neu_region?.nombre ?? selectedPuntoEntrega?.comuna?.region?.nombre ?? 'Sin región'}`,
          metodo_pago_id: `${selectedMetodoPagoId.id ?? '*NULL*'}-${selectedMetodoPagoId.nombre} | ${selectedMetodoPagoId.tipo_pago_erp ?? 'Sin código ERP'}`,
        })
      })
      .catch(err => console.log(err))

    return () => {
      setForm(null)
    }
  }, [id, selectFilter.puntosEntrega, selectFilter.metodosPago])


  const handleFetch = async (e) => {
    e.preventDefault()

    if (!form?.metodo_pago_id?.length || form.metodo_pago_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'Debe seleccionar un método de pago', icon: 'info' }))
    if (!form?.puntoentrega_id?.length || form.puntoentrega_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'Debe seleccionar un punto de entrega', icon: 'info' }))
    if (!form?.direccion?.length) return dispatch(fireToaster({ title: 'La dirección es necesaria', icon: 'info' }))

    let data = {
      pedido_id: form?.pedido_id,
      metodo_pago_id: idExtractor(form?.metodo_pago_id),
      puntoentrega_id: idExtractor(form?.puntoentrega_id),
      direccion_entrega: form?.direccion
    }

    axios(CONSTANT.queryUrl, {
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        '_method': 'PUT'
      },
      data
    })
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: Registro actualizado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectDetalle(id))
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
          Operación fallida
          (${err.response.status} - ${err.response.statusText})
        `,
          html: `
          <b>Detalle: </b>
          ${detalles.map(error => `<br /><i>-${error}</i>`)}
        `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  const handleFiltrosPersistentes = (location) => {
    return `?filtros_persistentes=${location?.search?.split('filtros_persistentes=')?.[1] ?? '0'}`
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='d-flex'>
          <Button className='col-6' text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectListado + handleFiltrosPersistentes(window.location)} />
          <Button className='col-6' text={CONSTANT.titleDetalle} icon='ArrowRight' style='flat' isLink url={CONSTANT.redirectDetalle(id) + handleFiltrosPersistentes(window.location)} />
        </div>

        {form?.pedido_id && selectFilter.puntosEntrega.length
          ? <CustomForm size="" title={CONSTANT.componenteTitle}>
            <div className="row">

              <FormInput
                name='direccion'
                labelText="* Dirección"
                size="col-12"
                sizeDesk="col-md-11"
                handleValue={handleInputChange}
                value={form?.direccion}
              />

              <SelectFilter
                name='puntoentrega_id'
                labelText="* Punto de entrega"
                size="col-12"
                sizeDesk="col-md-11"
                handleValue={handleInputChange}
                value={form?.puntoentrega_id?.split('-')?.[1] ?? 'Seleccione'}
                optionObj={selectFilter?.puntosEntrega?.map(({ id, direccion, neu_tipo_destino, tipo_destino, comuna }) => ({ id, name: `${neu_tipo_destino?.nombre ?? tipo_destino?.nombre ?? 'Sin tipo destino'} | ${direccion ?? 'Sin dirección'} | ${comuna?.nombre ?? 'Sin comuna'}, ${comuna?.neu_region?.nombre ?? comuna?.region?.nombre ?? 'Sin región'}` }))}
              />

              <SelectFilter
                name='metodo_pago_id'
                labelText="* Método de pago"
                size="col-12"
                sizeDesk="col-md-11"
                handleValue={handleInputChange}
                value={form?.metodo_pago_id?.split('-')?.[1] ?? 'Seleccione'}
                optionObj={selectFilter?.metodosPago?.map(({ id, nombre, tipo_pago_erp }) => ({ id, name: `${nombre} | ${tipo_pago_erp ?? 'Sin código ERP'}` }))}
              />

            </div>

            <Button type="submit" text="Actualizar" color="primary" icon="Circle" onClick={handleFetch} />
          </CustomForm>
          : <CustomLoader />
        }
      </div>
    </div>
  );
};

export default PedidosUpdate;

import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../Icon'

const DropdownDownloadLink = ({ href = '#', text, icon, ...props }) => {
  return (
    <a className="dropdown-item" href={href} key={text + "-dropdown-btn"} {...props}>
      <Icon icon={icon} />
      <i className="me-50"></i>
      {text}
    </a>
  )
}

export default DropdownDownloadLink
import { useEffect, useState } from 'react'

// Components
import FormInput from '../../components/FormInput'
import Button from '../../components/Button'
import SelectFilter from '../../components/SelectFilter'
import CheckBoxInput from '../../components/CheckboxInput'
import Modal from '../../components/Modal'
import Select from '../../components/Select'

// Layouts
import ListadoHeader from '../../layout/ListadoHeader'
import AccordionTable from '../../layout/AccordionTable'

//Mock
import roles from "../../mocks/roles.json";

// FORMATEO DE LA RESPUESTA PARA ROLES
const response = roles.map(({ id, name, guard_name, created_at}) => {
   return { id, name, guard_name, created_at, seleccionado: false }
})


const TablaRoles = () => {

   // FUNCIONALIDAD DE MODAL
   const [displayModal, setDisplayModal] = useState(false);
   const handleModal = () => setDisplayModal(!displayModal);

   // MANEJADORES DE FILTRO - PRODUCTOS ENCONTRADOS
   const [filtersCategoria, setFiltersCategoria] = useState([])
   const handleAddFilterCategoria = (e) => {
      const { value } = e.target
      setFiltersCategoria([...filtersCategoria.filter(tag => tag !== value), value])
   }

   const handleRemoveFilterCategoria = (e) => {
      const { value } = e.target
      setFiltersCategoria(filtersCategoria.filter(tag => tag !== value))
   }


   const [filtersElemento, setFilterElemento] = useState([])
   const handleAddFilterElemento = (e) => {
      const { value } = e.target
      setFilterElemento([...filtersElemento.filter(tag => tag !== value), value])
   }

   const handleRemoveFilterElemento = (e) => {
      const { value } = e.target
      setFilterElemento(filtersElemento.filter(tag => tag !== value))
   }

   // MANEJADORES DE ESTADO - PRODUCTOS SELECCIONADOS
   const [seleccionarTodo, setSeleccionarTodo] = useState(false)
   const [productosEncontrados, setProductosEncontrados] = useState([])
   const [productosSeleccionados, setProductosSeleccionados] = useState([])
   const [productosSeleccionadosFiltrados, setProductosSeleccionadosFiltrados] = useState(productosSeleccionados)
   const [filterCategoria, setFilterCategoria] = useState('')
   const [filterNombre, setFilterNombre] = useState('')


   useEffect(() => {
      setProductosEncontrados(response)
   }, [])


   /*
   *Función que cambia el valor [seleccionado]: <bool> de un objeto 
   *para luego cambiar el estado de "productosEncontrados" con el nuevo valor 
   */
   const handleProductoSeleccionado = (e) => {
      const { name } = e.target
      const [id, nombre] = name.split('-')

      const selectedIndex = productosEncontrados.findIndex(obj => obj.name === nombre && String(obj.id) === id)
      const newArr = productosEncontrados.map((obj, i) => {
         if (i === selectedIndex) return { ...obj, seleccionado: !obj.seleccionado }
         return { ...obj }
      })

      if (selectedIndex > -1) {
         setProductosEncontrados(newArr)
         // seleccionarTodo(false)
      }

   }


   const handleSeleccionarTodo = () => {
      if (seleccionarTodo === true && productosEncontrados.findIndex(obj => obj.seleccionado === false) === -1) {
         const newArr = response.map((obj) => ({ ...obj, seleccionado: false }))
         setProductosEncontrados([...newArr])
         return setSeleccionarTodo(false)
      }
      setSeleccionarTodo(!seleccionarTodo)
   }


   useEffect(() => {
      if (seleccionarTodo === false && productosEncontrados.findIndex(obj => obj.seleccionado === false) === -1) return

      if (seleccionarTodo === false && productosEncontrados.findIndex(obj => obj.seleccionado === false) === -1) {
         const newArr = response.map((obj) => ({ ...obj, seleccionado: false }))
         setProductosEncontrados([...newArr])
         setSeleccionarTodo(false)
      }

      if (seleccionarTodo) {
         const newArr = response.map((obj) => ({ ...obj, seleccionado: true }))
         setProductosEncontrados([...newArr])
      }
   }, [seleccionarTodo])



   // MANEJADORES DE INPUTS - DATATABLE
   const handleInputFilterCategoria = (e) => setFilterCategoria(e.target.value);
   const handleInputFilterNombre = (e) => setFilterNombre(e.target.value);

   // RENDERIZA LOS PRODUCTOS SELECCIONADOS EN LA DATATABLE
   useEffect(() => {
      setProductosSeleccionados([...productosEncontrados.filter(({ seleccionado }) => seleccionado === true)])
   }, [productosEncontrados])


 
   // RENDERIZA LOS PRODUCTOS LUEGO DE FILTRADOS
   useEffect(() => {
      if (productosEncontrados.findIndex(obj => obj.seleccionado === false) === -1 && productosSeleccionados.length > 0) setSeleccionarTodo(true)
      else setSeleccionarTodo(false)

      setProductosSeleccionadosFiltrados([...productosSeleccionados]);

      setProductosSeleccionadosFiltrados([...productosSeleccionados.filter(el => {
         return (
            el.guard_name.toLocaleLowerCase().includes(filterCategoria.toLocaleLowerCase()) &&
            el.name.toLocaleLowerCase().includes(filterNombre.toLocaleLowerCase()) 
         )
      })])
   }, [filterCategoria, filterNombre, productosSeleccionados, productosEncontrados])
 

   return (
      <div className='col-6'>
         {/* MODAL DE FILTROS (EN CONSTRUCCIÓN) */}
         <Modal display={displayModal} handleValue={handleModal}>
            <div className="row">
               <div className="col-12 d-flex my-1 justify-content-between">
                  <h3>Filtros</h3>
                  <div className="d-flex gap-1 me-1">
                     <Button text='Marcar todo' />
                     <Button text='Desmarcar todo' style='outline' />
                  </div>
               </div>
               <hr />
               <div className="col-12 d-flex gap-2 flex-wrap">
                  <div>
                     <CheckBoxInput />
                     <CheckBoxInput />
                     <CheckBoxInput />
                  </div>
               </div>
            </div>
         </Modal>

         <div className="col-12">

            {/* COMPONENTE FILTROS - PRODUCTOS */}
            <div className="card mb-50 shadow-none">
               <ListadoHeader
                  classes='border rounded-2'
                  title='Búsqueda de roles'
               >
                  <SelectFilter
                     labelText='Elemento'
                     size='col-12'
                     sizeDesk='col-md-4'
                     handleValue={handleAddFilterElemento}
                  />

                  <SelectFilter
                     labelText='Categoría'
                     size='col-12'
                     sizeDesk='col-md-4'
                     handleValue={handleAddFilterCategoria}
                  />

                  <FormInput
                     labelText='Búsqueda'
                     placeholder='Buscar...'
                     size='col-12'
                     sizeDesk='col-md-4'
                  />

                  <div className="col-12 col-md-1 my-auto">
                     <Button icon={'Plus'} text='Filtros' className={'w-fit my-auto'} onClick={handleModal} />
                  </div>

                  {/* FILTROS APLICADOS - PRODUCTOS */}
                  <p className='mb-25 text-black'>Filtros aplicados</p>

                  <div className='row'>
                     <div className='col-12 col'>
                        {filtersCategoria.length > 0 && <p className='mt-75 mb-25'>Categoría</p>}
                        <div className='d-flex gap-50 flex-wrap'>

                           {
                              filtersCategoria.length > 0 &&
                              filtersCategoria.map(tag => {
                                 return (
                                    <button
                                       key={`tag-${tag}`}
                                       className='tag px-50 d-flex align-items-center text-black'
                                       data-value={tag}
                                       onClick={handleRemoveFilterCategoria}
                                    >{tag} <b data-value={tag} className='ms-50'>x</b>
                                    </button>
                                 )
                              })
                           }
                        </div>
                     </div>

                     <div className='col-12 col'>
                        {filtersElemento.length > 0 && <p className='mt-75 mb-25'>Elemento</p>}
                        <div className='d-flex gap-50 flex-wrap'>
                           {
                              filtersElemento.length > 0 &&
                              filtersElemento.map(tag => {
                                 return (
                                    <button
                                       key={`tag-${tag}`}
                                       className='tag px-50 d-flex align-items-center text-black'
                                       data-value={tag}
                                       onClick={handleRemoveFilterElemento}
                                    >{tag} <b data-value={tag} className='ms-50'>x</b>
                                    </button>
                                 )
                              })
                           }
                        </div>
                     </div>
                  </div>
                  <hr className='my-1' />
               </ListadoHeader>
            </div>

            {/* TABLAS DE RESULTADOS */}
            <div className='row'>
               {/* ROLES ENCONTRADOS */}
               <AccordionTable
                  title={`Roles encontrados (${response.length})`}
                  classes='col-12 col-md-12'
               >
                  <div className={`card-datatable table-responsive pt-0 custom-scrollbar overflow-hidden flex-shrink-0`}>
                     <table className="user-list-table table dataTable no-footer dtr-column z-10 h-100">
                        <thead className="table-light ">
                           <tr role="row">
                              <th><CheckBoxInput labelText='' value={seleccionarTodo} handleValue={handleSeleccionarTodo} /></th>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Fecha creación</th>
                           </tr>
                        </thead>
                        <tbody>
                           {
                              productosEncontrados.map(data => {
                                 return (
                                    <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                                       <td>
                                          <CheckBoxInput
                                             labelText=''
                                             value={data.seleccionado}
                                             handleValue={handleProductoSeleccionado}
                                             name={`${data.id}-${data.name}`}
                                          />
                                       </td>
                                       <td>{data.name}</td>
                                       <td>{data.guard_name}</td>
                                       <td>{data.created_at}</td>
                                    </tr>
                                 )
                              })
                           }
                        </tbody>
                     </table>
                  </div>
               </AccordionTable>

               {/* ROLES SELECCIONADOS */}
               <AccordionTable
                  title={`Roles seleccionados (${productosSeleccionadosFiltrados.length})`}
                  classes='col-12 col-md-12'
               >
                  <div className={`card-datatable table-responsive pt-0 custom-scrollbar overflow-hidden flex-shrink-0`}>
                     <table className="user-list-table table dataTable no-footer dtr-column z-10 h-100">
                        <thead className="table-light ">
                           <tr role="row">
                              <th>Quitar</th>
                              <th>
                                 <FormInput
                                    name='nombre'
                                    value={filterNombre}
                                    placeholder='Nombre'
                                    handleValue={handleInputFilterNombre}
                                    sizeDesk='col'
                                    margin='mb-0'
                                 />
                              </th>
                              <th>
                                 <Select
                                    name='categoria'
                                    value={filterCategoria}
                                    placeholder='Tipo'
                                    handleValue={handleInputFilterCategoria}
                                    sizeDesk='col'
                                    margin='mb-0'            
                                 />
                              </th>
                              <th></th>
                           </tr>
                        </thead>
                        <tbody>
                           {
                              productosSeleccionadosFiltrados.map(data => {
                                 return (
                                    <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                                       <td>
                                          <CheckBoxInput
                                             labelText=''
                                             value={data.seleccionado}
                                             handleValue={handleProductoSeleccionado}
                                             name={`${data.id}-${data.name}`}
                                          />
                                       </td>
                                       <td>{data.name}</td>
                                       <td>{data.guard_name}</td>
                                       <td>{data.created_at}</td>
                                    </tr>
                                 )
                              })
                           }
                        </tbody>
                     </table>
                  </div>
               </AccordionTable>
            </div>
         </div>
      </div>
   )
}

export default TablaRoles
import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   FILTER_DAÑO_ID,
   FILTER_ESTADO_ID,
   FILTER_FACTURA,
   FILTER_FECHA_CREACION_DESDE,
   FILTER_FECHA_CREACION_HASTA,
   FILTER_FECHA_ENVIO_DESDE,
   FILTER_FECHA_ENVIO_HASTA,
   FILTER_FECHA_RECEPCION_DESDE,
   FILTER_FECHA_RECEPCION_HASTA,
   FILTER_PEDIDO_ID,
   FILTER_POSICION_ID,
   FILTER_RESPUESTA_ID,
   FILTER_RESULTADO_ID,
   FILTER_SKU,
   FILTROS_PERSISTENTES_GARANTIAS,
   RESULTADOS_GARANTIAS,
} from "../redux-types";

const initialState = {
   filtros: {
      // params
      busqueda_cliente: '',
      pedido_id: '',
      estado_id: '',
      posicion_id: '',
      daño_id: '',
      resultado_id: '',
      respuesta_id: '',
      producto_sku: '',
      numero_factura: '',
      fecha_creacion_desde: '',
      fecha_creacion_hasta: '',
      fecha_recepcion_desde: '',
      fecha_recepcion_hasta: '',
      fecha_envio_desde: '',
      fecha_envio_hasta: ''
   },
   resultados: null
}


export const garantiasReducer = (state = initialState, action) => {

   switch (action.type) {
      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda_cliente: action.payload
            }
         };

      case FILTER_PEDIDO_ID:
         return {
            ...state, filtros: {
               ...state.filtros,
               pedido_id: action.payload
            }
         };

      case FILTER_ESTADO_ID:
         return {
            ...state, filtros: {
               ...state.filtros,
               estado_id: action.payload
            }
         };

      case FILTER_POSICION_ID:
         return {
            ...state, filtros: {
               ...state.filtros,
               posicion_id: action.payload
            }
         };

      case FILTER_DAÑO_ID:
         return {
            ...state, filtros: {
               ...state.filtros,
               daño_id: action.payload
            }
         };

      case FILTER_RESULTADO_ID:
         return {
            ...state, filtros: {
               ...state.filtros,
               resultado_id: action.payload
            }
         };

      case FILTER_RESPUESTA_ID:
         return {
            ...state, filtros: {
               ...state.filtros,
               respuesta_id: action.payload
            }
         };

      case FILTER_SKU:
         return {
            ...state, filtros: {
               ...state.filtros,
               producto_sku: action.payload
            }
         }

      case FILTER_FACTURA:
         return {
            ...state, filtros: {
               ...state.filtros,
               numero_factura: action.payload
            }
         }

      case FILTER_FECHA_CREACION_DESDE:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_creacion_desde: action.payload
            }
         }

      case FILTER_FECHA_CREACION_HASTA:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_creacion_hasta: action.payload
            }
         }

      case FILTER_FECHA_RECEPCION_DESDE:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_recepcion_desde: action.payload
            }
         }

      case FILTER_FECHA_RECEPCION_HASTA:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_recepcion_hasta: action.payload
            }
         }

      case FILTER_FECHA_ENVIO_DESDE:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_envio_desde: action.payload
            }
         }

      case FILTER_FECHA_ENVIO_HASTA:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_envio_hasta: action.payload
            }
         }

      case FILTROS_PERSISTENTES_GARANTIAS:
         return {
            ...state, filtros: action.payload
         };

      case RESULTADOS_GARANTIAS:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
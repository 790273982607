import axios from 'axios'
import { BASE_URL, PATH_URL } from '../endpoints'

export const fetchErp = () => {
  const getConfig = {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
    method: 'GET',
  }

  const vendedores = async () => await axios(BASE_URL + PATH_URL + `/erp/vendedores/obtener`, getConfig)
    .then(({ data }) => data)
    .catch(err => {
      console.error(err);
      return null
    })

  const modosDespacho = async () => await axios(BASE_URL + PATH_URL + `/erp/modos-despachos/obtener`, getConfig)
    .then(({ data }) => data)
    .catch(err => {
      console.error(err);
      return null
    })

  return { vendedores, modosDespacho }
}

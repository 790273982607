import { Navigate, Route, Routes } from "react-router-dom"
import Templates from "../views/Templates";
import BannerRouter from "./Template/BannerRouter";
import CintaInformativaRouter from "./Template/CintaInformativaRouter";
import FactoresDiferenciadoresRouter from "./Template/FactoresDiferenciadoresRouter";
import ComponentesRouter from "./Template/ComponentesRouter";
import FaqRouter from "./Template/FaqRouter";
import FaqCategoriasRouter from "./Template/FaqCategoriasRouter";
import FaqSubcategoriasRouter from "./Template/FaqSubcategoriasRouter";
import PasosCompraRouter from "./Template/PasosCompraRouter";
import MenusRouter from "./Template/MenusRouter";
import TerminosCondicionesRouter from "./Template/TerminosCondicionesRouter";
import { useSelector } from "react-redux";



const TemplateRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/test' element={<Templates />} />

         {user.permisos['banners.listar'] &&
            <Route path='/banners/*' element={<BannerRouter />} />
         }

         {user.permisos['cinta-informativa.listar'] &&
            <Route path='/cinta-informativa/*' element={<CintaInformativaRouter />} />
         }

         {user.permisos['componentes.listar'] &&
            <Route path='/componentes/*' element={<ComponentesRouter />} />
         }

         {user.permisos['factores-diferenciadores.listar'] &&
            <Route path='/factores-diferenciadores/*' element={<FactoresDiferenciadoresRouter />} />
         }

         {user.permisos['faq.listar'] &&
            <Route path='/faq/*' element={<FaqRouter />} />
         }

         {user.permisos['faq.listar'] &&
            <Route path='/faq-categorias/*' element={<FaqCategoriasRouter />} />
         }

         {user.permisos['faq.listar'] &&
            <Route path='/faq-subcategorias/*' element={<FaqSubcategoriasRouter />} />
         }

         {user.permisos['pasos-compra.listar'] &&
            <Route path='/pasos-compra/*' element={<PasosCompraRouter />} />
         }

         {user.permisos['menus.listar'] &&
            <Route path='/menus/*' element={<MenusRouter />} />
         }

         {user.permisos['terminos-condiciones.listar'] &&
            <Route path='/terminos-condiciones/*' element={<TerminosCondicionesRouter />} />
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}

export default TemplateRouter;
import axios from "axios";


// Redux
import { fireToaster } from "../../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../../components/FormInput";
import Button from "../../../components/Button";
import CustomForm from "../../../components/CustomForm";
import Switch from "../../../components/Switch";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { create } from "../../../endpoints/create";
import { useEffect, useState } from "react";
import CustomLoader from "../../../components/CustomLoader";
import { getOne } from "../../../endpoints/getOne";
import TextareaInput from "../../../components/TextareaInput";


const CONSTANT = {
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  redirectUrl: '/garantias/respuestas',
  componentTitle: id => `${id ? 'Editar' : 'Crear'} respuesta de garantía`,
  title: 'Respuestas de garantía',

  queryUrl: create.garantia_respuestas,

  fecthData: getOne.garantia_respuestas,

  form: {
    titulo: '',
    texto: '',
  }
}


const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.form)
  const { id } = useParams()

  const [isConsulting, setIsConsulting] = useState(false)


  useEffect(() => {
    if (!id) return

    axios(CONSTANT.fecthData(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {

        setForm({
          ...data,
        })
      })
      .catch(err => console.error(err))

    return () => {
      setForm(CONSTANT.form)
    }
  }, [id])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)

    if (!form.titulo.length) return dispatch(fireToaster({ title: 'Por favor, ingrese un título', icon: 'info' }))
    if (!form.texto.length) return dispatch(fireToaster({ title: 'Por favor, ingrese un texto', icon: 'info' }))

    let url = id => id ? CONSTANT.queryUrl + `/${id}` : CONSTANT.queryUrl

    let data = {
      titulo: form.titulo,
      texto: form.texto,
    }

    axios(url(id), {
      method: id ? 'PUT' : 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: registro creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() =>
        setIsConsulting(false)
      )
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        <CustomForm size="" title={CONSTANT.componentTitle(id)}>
          <div className="row">
            <FormInput
              labelText='* Título'
              placeholder='Título'
              name='titulo'
              value={form.titulo}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-12'
            />

            <TextareaInput
              labelText='* Texto'
              placeholder='Texto'
              name='texto'
              value={form.texto}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-12'
            />
          </div>

          <Button
            type="submit"
            text={id ? 'Actualizar' : "Crear"}
            color="primary"
            disabled={isConsulting}
            icon={id ? 'Check' : "Plus"}
            onClick={(e) => handleFetch(e)}
          />
        </CustomForm>
      </div>
    </div>
  );
};

export default Form;

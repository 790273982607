import axios from 'axios'
import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { FILTER_BUSQUEDA, FILTER_DAÑO_ID, FILTER_ESTADO_ID, FILTER_FACTURA, FILTER_FECHA_CREACION_DESDE, FILTER_FECHA_CREACION_HASTA, FILTER_FECHA_ENVIO_DESDE, FILTER_FECHA_ENVIO_HASTA, FILTER_FECHA_RECEPCION_DESDE, FILTER_FECHA_RECEPCION_HASTA, FILTER_PEDIDO_ID, FILTER_POSICION_ID, FILTER_RESPUESTA_ID, FILTER_RESULTADO_ID, FILTER_SKU } from '../../redux/redux-types'
import { busquedaFilter, clearFilterGarantias, dañoFilter, estadoFilter, fechaCreacionDesdeFilter, fechaCreacionHastaFilter, fechaEnvioDesdeFilter, fechaEnvioHastaFilter, fechaRecepcionDesdeFilter, fechaRecepcionHastaFilter, filtrosPersistentesGarantias, numeroFacturaFilter, pedidoFilter, posicionFilter, respuestaFilter, resultadoFilter, resultadosGarantias, skuFilter } from '../../redux/actions/garantias'


// Components
import FormInput from '../FormInput'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'
import { persistentFilters } from '../../helpers/pesistentFilters'
import SelectFilter from '../SelectFilter'
import { fecthSelectFiltersData } from '../../helpers/fetchSelectFilterData'
import { idExtractor } from '../../helpers/idExtractor'


const CONSTANT = {
  queryUrlGetAll: getAll.garantias,
  redirectUrl: '/garantias',
  reduxSelector: 'garantias',
  title: 'Búsqueda de garantías',
  reduxClearFilters: clearFilterGarantias,
  reduxSetResults: resultadosGarantias,

  queryUrl: (results) => CONSTANT.queryUrlGetAll + `?limit=${results}`,

  selectFilter: {
    garantias_respuestas: [],
    garantias_daños: [],
    garantias_resultados: [],
    garantias_estados: [],
    garantias_posiciones: []
  }
}


const selectFilterQueries = [
  {
    url: getAll.garantia_respuestas,
    key: 'garantias_respuestas',
  },
  {
    url: getAll.garantia_daños,
    key: 'garantias_daños',
  },
  {
    url: getAll.garantia_resultados,
    key: 'garantias_resultados',
  },
  {
    url: getAll.garantia_estados,
    key: 'garantias_estados',
  },
  {
    url: getAll.garantia_posiciones,
    key: 'garantias_posiciones',
  }
]


const GarantiasFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const {
    busqueda_cliente,
    pedido_id,
    estado_id,
    posicion_id,
    daño_id,
    resultado_id,
    respuesta_id,
    producto_sku,
    numero_factura,
    fecha_creacion_desde,
    fecha_creacion_hasta,
    fecha_recepcion_desde,
    fecha_recepcion_hasta,
    fecha_envio_desde,
    fecha_envio_hasta } = filtros
  const dispatch = useDispatch()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const [isConsulting, setIsConsulting] = useState(false)

  // EFECTO QUE RECOJE LA DATA DE LOS SELECTFILTERS
  useEffect(() => {
    fecthSelectFiltersData(selectFilterQueries).then(res => setSelectFilter(res))
    return () => {
      setSelectFilter(CONSTANT.selectFilter)
    }
  }, [])


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    const filtrosPersistentesObj = persistentFilters(window.location, filtros).obj
    let url = CONSTANT.queryUrl(showing_results)
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    }

    if (filtrosPersistentesObj) {
      dispatch(filtrosPersistentesGarantias(filtrosPersistentesObj))
      url = CONSTANT.queryUrl(showing_results) + queryParamsFormatter({ busqueda: filtrosPersistentesObj.busqueda })
      config = { ...config }
    }

    axios(url, config)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
      })
      .catch(err => {
        console.error(err)
        dispatch(CONSTANT.reduxSetResults([]))
      })
  }, [dispatch, showing_results])


  // EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
  useEffect(() => {
    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = (reset = false) => {
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    }

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}`, config)
        .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
        .catch(err => {
          console.error(err)
          dispatch(CONSTANT.reduxSetResults([]))
        })
    }

    config = {
      ...config,
      data: { busqueda_cliente }
    }

    setIsConsulting(true)
    
    axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}` + queryParamsFormatter({
      busqueda_cliente,
      pedido_id,
      estado_id: idExtractor(estado_id),
      posicion_id: idExtractor(posicion_id),
      daño_id: idExtractor(daño_id),
      resultado_id: idExtractor(resultado_id),
      respuesta_id: idExtractor(respuesta_id),
      producto_sku,
      numero_factura,
      fecha_creacion_desde,
      fecha_creacion_hasta,
      fecha_recepcion_desde,
      fecha_recepcion_hasta,
      fecha_envio_desde,
      fecha_envio_hasta
    }), config)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
        window.history.pushState({}, '', persistentFilters(window.location, filtros).urlWithFilters)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
            `,
          html: `
            <b> Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
            `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
        CONSTANT.reduxSetResults([])
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_BUSQUEDA:
        dispatch(busquedaFilter(value))
        break;

      case FILTER_PEDIDO_ID:
        dispatch(pedidoFilter(value))
        break;

      case FILTER_ESTADO_ID:
        dispatch(estadoFilter(value))
        break;

      case FILTER_POSICION_ID:
        dispatch(posicionFilter(value))
        break;

      case FILTER_DAÑO_ID:
        dispatch(dañoFilter(value))
        break;

      case FILTER_RESULTADO_ID:
        dispatch(resultadoFilter(value))
        break;

      case FILTER_RESPUESTA_ID:
        dispatch(respuestaFilter(value))
        break;

      case FILTER_SKU:
        dispatch(skuFilter(value))
        break;

      case FILTER_FACTURA:
        dispatch(numeroFacturaFilter(value))
        break;

      case FILTER_FECHA_CREACION_DESDE:
        dispatch(fechaCreacionDesdeFilter(value))
        break;

      case FILTER_FECHA_CREACION_HASTA:
        dispatch(fechaCreacionHastaFilter(value))
        break;

      case FILTER_FECHA_RECEPCION_DESDE:
        dispatch(fechaRecepcionDesdeFilter(value))
        break;

      case FILTER_FECHA_RECEPCION_HASTA:
        dispatch(fechaRecepcionHastaFilter(value))
        break;

      case FILTER_FECHA_ENVIO_DESDE:
        dispatch(fechaEnvioDesdeFilter(value))
        break;

      case FILTER_FECHA_ENVIO_HASTA:
        dispatch(fechaEnvioHastaFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <FormInput
            labelText='Búsqueda'
            placeholder='Ingrese su búsqueda'
            size='col-12'
            sizeDesk='col-md-3'
            handleValue={(e) => handleAddFilter(e, FILTER_BUSQUEDA)}
            value={busqueda_cliente}
          />

          <FormInput
            labelText='Producto SKU'
            placeholder='Ingrese su búsqueda'
            size='col-12'
            sizeDesk='col-md-3'
            handleValue={(e) => handleAddFilter(e, FILTER_SKU)}
            value={producto_sku}
          />

          <FormInput
            labelText='Factura'
            placeholder='Ingrese su búsqueda'
            size='col-12'
            sizeDesk='col-md-3'
            handleValue={(e) => handleAddFilter(e, FILTER_FACTURA)}
            value={numero_factura}
          />

          <FormInput
            labelText='Pedido'
            placeholder='Ingrese su búsqueda'
            size='col-12'
            sizeDesk='col-md-3'
            handleValue={(e) => handleAddFilter(e, FILTER_PEDIDO_ID)}
            value={pedido_id}
          />

          <FormInput
            type='date'
            labelText='Creación desde'
            placeholder='Ingrese su búsqueda'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_FECHA_CREACION_DESDE)}
            value={fecha_creacion_desde}
          />

          <FormInput
            type='date'
            labelText='Creación hasta'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_FECHA_CREACION_HASTA)}
            value={fecha_creacion_hasta}
          />

          <FormInput
            type='date'
            labelText='Recepción desde'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_FECHA_RECEPCION_DESDE)}
            value={fecha_recepcion_desde}
          />

          <FormInput
            type='date'
            labelText='Recepción hasta'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_FECHA_RECEPCION_HASTA)}
            value={fecha_recepcion_hasta}
          />

          <FormInput
            type='date'
            labelText='Envío desde'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_FECHA_ENVIO_DESDE)}
            value={fecha_envio_desde}
          />

          <FormInput
            type='date'
            labelText='Envío hasta'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_FECHA_ENVIO_HASTA)}
            value={fecha_envio_hasta}
          />

          <SelectFilter
            labelText='Respuestas'
            size='col-12'
            sizeDesk='col-md-3'
            handleValue={(e) => handleAddFilter(e, FILTER_RESPUESTA_ID)}
            optionObj={selectFilter?.garantias_respuestas?.map(({ id, nombre, titulo }) => ({ id, name: titulo ?? nombre }))}
            value={respuesta_id?.split('-')?.[1] ?? 'seleccione'}
          />

          <SelectFilter
            labelText='Resultados'
            size='col-12'
            sizeDesk='col-md-3'
            handleValue={(e) => handleAddFilter(e, FILTER_RESULTADO_ID)}
            optionObj={selectFilter?.garantias_resultados?.map(({ id, nombre, resultado }) => ({ id, name: resultado ?? nombre }))}
            value={resultado_id?.split('-')?.[1] ?? 'seleccione'}
          />

          <SelectFilter
            labelText='Estados'
            size='col-12'
            sizeDesk='col-md-3'
            handleValue={(e) => handleAddFilter(e, FILTER_ESTADO_ID)}
            optionObj={selectFilter?.garantias_estados?.map(({ id, nombre, estado }) => ({ id, name: estado ?? nombre }))}
            value={estado_id?.split('-')?.[1] ?? 'seleccione'}
          />

          <SelectFilter
            labelText='Daños'
            size='col-12'
            sizeDesk='col-md-3'
            handleValue={(e) => handleAddFilter(e, FILTER_DAÑO_ID)}
            optionObj={selectFilter?.garantias_daños?.map(({ id, nombre, daño }) => ({ id, name: daño ?? nombre }))}
            value={daño_id?.split('-')?.[1] ?? 'seleccione'}
          />

          <SelectFilter
            labelText='Posición'
            size='col-12'
            sizeDesk='col-md-3'
            handleValue={(e) => handleAddFilter(e, FILTER_POSICION_ID)}
            optionObj={selectFilter?.garantias_posiciones?.map(({ id, nombre, posicion }) => ({ id, name: posicion ?? nombre }))}
            value={posicion_id?.split('-')?.[1] ?? 'seleccione'}
          />
        </>
      </ListadoHeader>
    </div>
  )
}

export default GarantiasFiltros

// Utils
import PropTypes from 'prop-types';


const CheckBoxInput = ({ labelText, name, value, handleValue, classes, isDisabled, ...props }) => {

   const handleCheck = (e) => {
      const { name, checked } = e.target
      const synthEvent = {
         target: {
            name,
            value: checked
         }
      }

      handleValue(synthEvent)
   }

   return (
      <div className={classes} key={`${name}`} >
         <div className={`form-check`}>
            <input
               name={name}
               className="form-check-input"
               disabled={isDisabled}
               type="checkbox"
               checked={value}
               onChange={handleCheck}
               id={`cb-${name}`}
               {...props}
            />

            <label className="form-check-label" htmlFor={`cb-${name}`}>
               {labelText}
            </label>
         </div>
      </div>
   );
}

CheckBoxInput.propTypes = {
   labelText: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   handleValue: PropTypes.func.isRequired
}

CheckBoxInput.defaultProps = {
   labelText: "Check",
   name: "Check",
   value: true,
   classes: '',
   handleValue: () => null
}


export default CheckBoxInput;
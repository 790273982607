import {
   FILTER_ADD_PRODUCTO,
   FILTER_ADD_PRODUCTO_PROVEEDOR,
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   FILTER_REMOVE_PRODUCTO,
   FILTER_REMOVE_PRODUCTO_PROVEEDOR,
   RESULTADOS_PRODUCTOS_PROPIOS_PROVEEDOR,
} from "../redux-types";

const initialState = {
   filtros: {
      producto_proveedor_id: [],
      producto_id: [],
      nombre: ''
   },
   resultados: null
}


export const productosPropiosProveedorReducer = (state = initialState, action) => {
   let isListed

   switch (action.type) {
      case FILTER_ADD_PRODUCTO_PROVEEDOR:
         isListed = state.filtros.producto_proveedor_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               producto_proveedor_id: isListed
                  ? state.filtros.producto_proveedor_id
                  : [...state.filtros.producto_proveedor_id, action.payload]
            }
         };

      case FILTER_REMOVE_PRODUCTO_PROVEEDOR:
         return {
            ...state, filtros: {
               ...state.filtros,
               producto_proveedor_id: state.filtros.producto_id.filter(el => el !== action.payload)
            }
         };


      case FILTER_ADD_PRODUCTO:
         isListed = state.filtros.producto_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               producto_id: isListed
                  ? state.filtros.producto_id
                  : [...state.filtros.producto_id, action.payload]
            }
         };

      case FILTER_REMOVE_PRODUCTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               producto_id: state.filtros.producto_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               nombre: action.payload
            }
         };

      case RESULTADOS_PRODUCTOS_PROPIOS_PROVEEDOR:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
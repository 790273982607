import axios from 'axios'
import React, { useEffect, useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { FILTER_CREACION_DESDE, FILTER_CREACION_HASTA, FILTER_NOMBRE, FILTER_ADD_OFERTA, FILTER_DESCRIPCION, FILTER_FECHA_INICIO, FILTER_FECHA_FINAL } from '../../redux/redux-types'
import { fireToaster } from '../../redux/actions/toaster'
import { clearFilterEtiquetas, creacionDesdeFilter, creacionHastaFilter, descripcionFilter, fechaHoraFinalFilter, fechaHoraInicioFilter, nombreFilter, ofertaFilter, resultadosEtiquetas } from '../../redux/actions/etiquetas'
import { resultadosProductos } from '../../redux/actions/productos'


// Components
import FormInput from '../FormInput'
import SelectFilter from '../SelectFilter'
import FiltroAplicado from './FiltroAplicado'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'
import { lastPosition } from '../../helpers/lastPosition'


const CONSTANT = {
   queryUrlGetAll: getAll.etiquetas,
   queryUrlGetAllOfertas: getAll.ofertas,
   redirectUrl: '/etiquetas',
   reduxSetProductos: resultadosProductos,
   title: 'Búsqueda de etiquetas',
   reduxClearFilters: (data) => clearFilterEtiquetas(data),
   reduxSetResults: (data) => resultadosEtiquetas(data)
}


const EtiquetasFiltros = ({ isAsignacionMasiva = false }) => {
   const { filtros } = useSelector(state => state.etiquetas)
   const { showing_results } = useSelector(state => state.listedResults)
   const [ofertas, setOfertas] = useState(null)
   const dispatch = useDispatch()
   const {
      oferta_id,
      nombre,
      descripcion,
      created_at_desde,
      created_at_hasta,
      fecha_hora_inicio,
      fecha_hora_final
   } = filtros


   useEffect(() => {
      axios(CONSTANT.queryUrlGetAll + `/listar?limit=${showing_results}`, {
         headers: {
            'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
         }, 
         method: 'POST'
      })
         .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data.etiquetas)))
         .catch(err => console.error(err))
   }, [showing_results, dispatch])


   useEffect(() => {
      axios(CONSTANT.queryUrlGetAllOfertas, {
         headers: {
            'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
         }
      })
         .then(({ data }) => setOfertas(data.ofertas))
         .catch(err => console.error(err))
   }, [])


   // EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
   useEffect(() => {
      return () => {
         dispatch(CONSTANT.reduxClearFilters())
         dispatch(CONSTANT.reduxSetProductos(null))
      }
   }, [dispatch])


   // FUNCION MANEJADORA QUE CONSULTA EL ENDPOINT CON LOS FILTROS APLICADOS
   const handleFetchFilter = async (reset = false) => {
      const url = CONSTANT.queryUrlGetAll + `/listar?limit=${isAsignacionMasiva ? '99999' : showing_results}`
      let data = { busqueda: null }

      if (reset) {
         dispatch(CONSTANT.reduxClearFilters())
         return axios(url, {
            headers: {
               'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
            method: 'POST'
         })
            .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data.etiquetas)))
            .catch(err => console.log(err))
      }

      else {
         Object.entries(filtros).forEach(([key, value]) => {
            if (key === 'oferta_id' && value.length > 0) {
               data = {
                  ...data,
                  filtros: {
                     ...data.filtros,
                     [key]: value.map(el => Number(el.split('-')[0]))
                  }
               }
            }
            else return
         })
      }

      dispatch(CONSTANT.reduxSetResults(null))

      await axios(url + queryParamsFormatter({
         nombre,
         descripcion,
         fecha_hora_inicio: fecha_hora_inicio?.split('T').join('%20'),
         fecha_hora_final: fecha_hora_final?.split('T').join('%20'), created_at_desde, created_at_hasta
      }),
         {
            method: 'POST',
            headers: {
               'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
            data
         })
         .then(({ data }) => {
            dispatch(CONSTANT.reduxSetResults(data.etiquetas))
            dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
         })
         .catch(err => {
            console.error(err);
            const { errores } = err.response.data
            let detalles = []
            Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

            const toasterContent = {
               title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
               html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
               icon: 'error'
            }
            dispatch(fireToaster(toasterContent))
         })
   }

   // MANEJADORES DE FILTRO - ADICIONALES
   const handleAddFilter = (e, filter) => {
      const { value } = e.target

      switch (filter) {
         case FILTER_NOMBRE:
            dispatch(nombreFilter(value))
            break;

         case FILTER_DESCRIPCION:
            dispatch(descripcionFilter(value))
            break;

         case FILTER_ADD_OFERTA:
            dispatch(ofertaFilter(value))
            break;

         case FILTER_CREACION_DESDE:
            dispatch(creacionDesdeFilter(value))
            break;

         case FILTER_CREACION_HASTA:
            dispatch(creacionHastaFilter(value))
            break;

         case FILTER_FECHA_INICIO:
            dispatch(fechaHoraInicioFilter(value))
            break;

         case FILTER_FECHA_FINAL:
            dispatch(fechaHoraFinalFilter(value))
            break;

         default:
            break;
      }
   }


   return (
      <div className={`card mb-50 shadow-none bg-transparent ${isAsignacionMasiva ? 'col-md-6 col-12' : 'col-12'}`}>
         <ListadoHeader
            classes='border rounded-2'
            title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
            handleClickSearch={() => handleFetchFilter()}
            handleClickClearFilter={() => handleFetchFilter(true)}
         >
            {ofertas &&
               <>
                  <FormInput
                     labelText='Nombre'
                     placeholder='Ingrese su búsqueda'
                     size='col-12'
                     sizeDesk={isAsignacionMasiva ? 'col-md-6' : 'col-md-3'}
                     handleValue={(e) => handleAddFilter(e, FILTER_NOMBRE)}
                     value={nombre}
                  />

                  <SelectFilter
                     labelText='Oferta'
                     size='col-12'
                     sizeDesk={isAsignacionMasiva ? 'col-md-6' : 'col-md-3'}
                     handleValue={(e) => handleAddFilter(e, FILTER_ADD_OFERTA)}
                     optionObj={ofertas?.map(({ id, nombre }) => ({ id, name: nombre }))}
                     value={lastPosition(oferta_id)}
                  />

                  <FormInput
                     labelText='Descripción'
                     placeholder='Ingrese su búsqueda'
                     size='col-12'
                     sizeDesk={isAsignacionMasiva ? 'col-md-6' : 'col-md-5'}
                     handleValue={(e) => handleAddFilter(e, FILTER_DESCRIPCION)}
                     value={descripcion}
                  />

                  <FormInput
                     type='date'
                     labelText='Creación (desde)'
                     max={created_at_hasta}
                     size='col-12'
                     sizeDesk={isAsignacionMasiva ? 'col-md-6' : 'col-md-2'}
                     handleValue={(e) => handleAddFilter(e, FILTER_CREACION_DESDE)}
                     value={created_at_desde}
                  />

                  <FormInput
                     type='date'
                     labelText='Creación (hasta)'
                     min={created_at_desde}
                     size='col-12'
                     sizeDesk={isAsignacionMasiva ? 'col-md-6' : 'col-md-2'}
                     handleValue={(e) => handleAddFilter(e, FILTER_CREACION_HASTA)}
                     value={created_at_hasta}
                  />

                  <FormInput
                     type='datetime-local'
                     labelText='Fecha hora inicio'
                     max={fecha_hora_final}
                     size='col-12'
                     sizeDesk={isAsignacionMasiva ? 'col-md-6' : 'col-md-2'}
                     handleValue={(e) => handleAddFilter(e, FILTER_FECHA_INICIO)}
                     value={fecha_hora_inicio}
                  />

                  <FormInput
                     type='datetime-local'
                     labelText='Fecha hora final'
                     min={fecha_hora_inicio}
                     size='col-12'
                     sizeDesk={isAsignacionMasiva ? 'col-md-6' : 'col-md-2'}
                     handleValue={(e) => handleAddFilter(e, FILTER_FECHA_FINAL)}
                     value={fecha_hora_final}
                  />
               </>
            }
            {/* FILTROS APLICADOS - ADICIONALES */}
            <p className='mb-25 text-black'>Filtros aplicados</p>
            <div className='row'>
               <FiltroAplicado array={oferta_id} func={ofertaFilter} title={'Ofertas'} />
            </div>

            <hr className='my-1' />

         </ListadoHeader>
      </div>
   )
}

export default EtiquetasFiltros
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


// Redux
import { useDispatch } from "react-redux";


//Components
import Accordion from "../../components/Accordion";
import SelectFilter from "../../components/SelectFilter";
import FormInput from "../../components/FormInput";
import FormInputNumber from "../../components/FormInputNumber";
import Button from "../../components/Button";
import CheckBoxInput from "../../components/CheckboxInput";
import TextareaInput from "../../components/TextareaInput";
import FileUploaderB from "../../components/FileUploader_B";
import Table from "../../components/Table";


//Layouts
import AccordionTable from "../../layout/AccordionTable";


// Endpoints
import { getAll } from "../../endpoints/getAll";
import { fireToaster } from "../../redux/actions/toaster";
import { idExtractor } from "../../helpers/idExtractor";
import { getOne } from "../../endpoints/getOne";
import { BASE_URL } from "../../endpoints";
import { create } from "../../endpoints/create";
import Icon from "../../components/Icon";
import CustomLoader from "../../components/CustomLoader";
import Switch from "../../components/Switch";


const CONSTANT = {
  fetchTiposElementos: getAll.tipos_elementos + '/buscar?limit=10000',
  fetchCategorias: getAll.categorias + `/buscar?limit=99999`,
  fetchClavesAtributos: (categoria) => getAll.claves_atributos + `/filtros?categoria_id=${categoria?.split('-')[0] ?? 1}`,
  redirectProducto: (id) => `/productos/detalle/${id}`,
  fetchERPData: getOne.producto_erp,

  createImagenesProducto: create.imagenes_productos,
  createProducto: create.productos,
  asignacionAdicionales: create.productos + '/asignar/adicionales',
  asignacionEtiqueta: create.productos + '/asignar/etiqueta',
  asignacionOferta: create.productos + '/asignar/oferta',
  asignacionPuntosEntrega: create.productos + '/asignar/puntos-entregas',
  asignacionMetodosPago: create.productos + '/asignar/metodos-pagos',

  unselectedObject: { id: '*NULL*', name: 'Sin selección' },

  initialFormState: {
    erp_descripcion: '',
    tipo_elemento_id: '',
    categoria_id: '',
    sku: '',
    nombre: '',
    descripcion: '',
    ultimo_costo: '',
    precio_final: '',
    nuevo: true,
    es_variante: false,
    publicado: true,
    'meta-descripcion': '',
    'meta-titulo': '',
    stock: '',
    atributos: {},
  },

  asignacionesInitialState: { puntos_entrega: [], metodos_pago: [], adicionales: [], ofertas: [], etiquetas: [] },
  selectedAsignacionesInitialState: { etiqueta: {}, oferta: {}, metodos_pago: [], puntos_entrega: [], adicionales: [] },

  datatableHeadInputState: {
    adicionales: {
      busqueda: '',
      precio: undefined
    },
    puntos_entrega: {
      busqueda: '',
      precio_instalacion: undefined,
      recargo: undefined
    },
    ofertas: {
      busqueda: ''
    },
    etiquetas: {
      busqueda: ''
    }
  }
}



const valorAtributoNameFormatter = (obj) => {
  let { codigo, valor, nombre } = obj

  if (codigo && codigo?.includes('-')) {
    let sanitizedCodigo = codigo.split('-').join('_')
    codigo = sanitizedCodigo
  }

  if (valor?.includes('-')) {
    let sanitizedValor = valor.split('-').join('_')
    valor = sanitizedValor
  }

  if (nombre?.includes('-')) {
    let sanitizedNombre = nombre.split('-').join('_')
    nombre = sanitizedNombre
  }

  return `${codigo ?? valor}-${nombre}`
}


const valorAtributoArrayFormatter = (arr) => {
  if (typeof arr !== 'object') return new Error(`El dato no es un arreglo, dato recibido: ${typeof arr}`)

  return [CONSTANT.unselectedObject].concat(arr.map(valor => ({
    id: valor?.id,
    name: valorAtributoNameFormatter(valor)
  })))
}

// TODO: BORRAR PARA USAR EL SERVICIO, LUEGO PROBAR SI SIGUE FUNCIONANDO
const setterAsignaciones = async () => {
  const metodos_pago = await axios(getAll.metodos_pago,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => data)
    .catch(err => console.error(err))

  const puntos_entrega = await axios(getAll.puntos_entrega + '/listar?estatus=1',
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => data.map(el => (
      {
        ...el,
        filter_param: `
        ${el?.tipo_destino?.nombre?.toLowerCase() ?? 'N/A'}
        ${el?.comuna?.region?.nombre?.toLowerCase() ?? 'N/A'}
        ${el?.nombre?.toLowerCase() ?? 'N/A'}
        ${el?.direccion?.toLowerCase() ?? 'N/A'}
        ${el?.rut_proveedor?.toLowerCase() ?? 'N/A'}
        `
      }
    )))
    .catch(err => console.error(err))

  const ofertas = await axios(getAll.ofertas,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => data.ofertas.map(el => (
      {
        ...el,
        filter_param: `
        ${el?.nombre?.toLowerCase() ?? '?'} 
        ${el?.fecha_hora_inicio?.split('T')[0] ?? 'N/A'} 
        ${el?.fecha_hora_final?.split('T')[0] ?? 'N/A'} 
        ${el?.neu_oferta?.nombre ?? 'N/A'}
        `
      }
    )))
    .catch(err => console.error(err))

  const etiquetas = await axios(getAll.etiquetas + '/listar',
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => data.etiquetas.map(el => (
      {
        ...el,
        filter_param: `
        ${el?.nombre?.toLowerCase() ?? '?'}
        ${el?.fecha_hora_inicio?.split('T')[0] ?? 'N/A'} 
        ${el?.fecha_hora_final?.split('T')[0] ?? 'N/A'} 
        `
      }
    )))
    .catch(err => console.error(err))

  const adicionales = await axios(getAll.adicionales + '/listar',
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => data.adicionales.map(el => (
      {
        ...el,
        filter_param: `
        ${el?.nombre?.toLowerCase() ?? 'N/A'}
      `
      }
    )))
    .catch(err => console.error(err))

  return { metodos_pago, puntos_entrega, ofertas, etiquetas, adicionales }
}


const Create = () => {
  const dispatch = useDispatch()
  const redirect = useNavigate()
  const [form, setForm] = useState(CONSTANT.initialFormState)
  const [tiposElementosSelect, setTiposElementosSelect] = useState([])
  const [categoriasSelect, setCategoriasSelect] = useState([])
  const [clavesAtributosSelect, setClavesAtributosSelect] = useState([])
  const [nombreArmado, setNombreArmado] = useState([])
  const [imagenesAtributos, setImagenesAtributos] = useState({})
  const [imagenesProducto, setImagenesProducto] = useState([])
  const [unfilteredAsignaciones, setUnfilteredAsignaciones] = useState(CONSTANT.asignacionesInitialState)
  const [asignaciones, setAsignaciones] = useState(CONSTANT.asignacionesInitialState)
  const [selectedAsignaciones, setSelectedAsignaciones] = useState(CONSTANT.selectedAsignacionesInitialState)
  const [datatableHeadInput, setDatatableHeadInput] = useState(CONSTANT.datatableHeadInputState)


  // FUNCION MANEJADORA PARA SELECCIONAR TODO EN LA TABLA DE ASIGNACIONES
  const handleSelectAll = (e) => {
    const { name, value } = e.target
    if (!value) {
      return setSelectedAsignaciones({
        ...selectedAsignaciones,
        [name]: []
      })
    }

    switch (name) {
      case 'metodos_pago':
        return setSelectedAsignaciones({
          ...selectedAsignaciones,
          [name]: asignaciones[name].map(({ id }) => ({
            metodo_pago_id: String(id),
            estatus: 1
          }))
        })

      case 'puntos_entrega':
        return setSelectedAsignaciones({
          ...selectedAsignaciones,
          [name]: asignaciones[name].map(({ id }) => (
            {
              punto_entrega_id: String(id),
              precio_instalacion: undefined,
              recargo: undefined,
              instalacion_apply: true,
              recargo_apply: true,
              estatus: 1
            }
          ))
        })

      case 'adicionales':
        return setSelectedAsignaciones({
          ...selectedAsignaciones,
          [name]: asignaciones[name].map(({ id }) => (
            {
              adicional_id: String(id),
              estatus: 1,
              precio: undefined
            }
          ))
        })

      default:
        break;
    }
  }


  // FUNCION MANEJADORA PARA LAS SELECCIONAR LAS ASIGNACIONES
  const handleSetAsignacionesMultiples = (e, target) => {
    const { name } = e.target
    const [id] = name.split('-')
    const dictId = {
      metodos_pago: 'metodo_pago_id',
      puntos_entrega: 'punto_entrega_id',
      adicionales: 'adicional_id'
    }
    const isSelected = selectedAsignaciones[target].find(el => el[dictId[target]] === id)

    if (isSelected) {
      return setSelectedAsignaciones({
        ...selectedAsignaciones,
        [target]: selectedAsignaciones[target].filter(el => el[dictId[target]] !== id)
      })
    }

    switch (target) {
      case 'metodos_pago':
        return setSelectedAsignaciones({
          ...selectedAsignaciones,
          [target]: [
            ...selectedAsignaciones[target],
            {
              [dictId[target]]: id,
              estatus: 1
            }
          ]
        })

      case 'puntos_entrega':
        return setSelectedAsignaciones({
          ...selectedAsignaciones,
          [target]: [
            ...selectedAsignaciones[target],
            {
              [dictId[target]]: id,
              precio_instalacion: undefined,
              recargo: undefined,
              instalacion_apply: 1,
              recargo_apply: 1,
              estatus: 1
            }
          ]
        })

      case 'adicionales':
        return setSelectedAsignaciones({
          ...selectedAsignaciones,
          [target]: [
            ...selectedAsignaciones[target],
            {
              [dictId[target]]: id,
              estatus: 1,
              precio: undefined
            }
          ]
        })

      default:
        break;
    }
  }


  // FUNCION MANEJADORA PARA LAS SELECCIONAR LAS ASIGNACIONES
  const handleSetAsignacionesUnicas = (obj, target) => {
    const { id } = obj
    const dictId = {
      oferta: 'oferta_id',
      etiqueta: 'etiqueta_id'
    }

    const route = selectedAsignaciones[target][dictId[target]] === id ? 'selected' : target

    switch (route) {
      case 'selected':
        return setSelectedAsignaciones({
          ...selectedAsignaciones,
          [target]: {}
        })

      case 'oferta':
        return setSelectedAsignaciones({
          ...selectedAsignaciones,
          [target]: {
            [dictId[target]]: id,
            cantidad_limite: undefined,
            cantidad_critica: undefined,
            fecha_hora_inicio: undefined,
            fecha_hora_final: undefined,
            estatus: 1
          }
        })

      case 'etiqueta':
        return setSelectedAsignaciones({
          ...selectedAsignaciones,
          [target]: {
            [dictId[target]]: id,
            prioridad_resultado: obj.prioridad,
            fecha_hora_inicio: undefined,
            fecha_hora_final: undefined,
            estatus: 1
          }
        })

      default:
        break;
    }
  }


  // FUNCION MANEJADORA DE LAS ASIGNACIONES
  const handleAsignacionesInput = (e) => {
    const { name, value } = e.target
    const [id, key, target] = name.split('-')
    const dictId = {
      puntos_entrega: 'punto_entrega_id',
      oferta: 'oferta_id',
      etiqueta: 'etiqueta_id',
      adicionales: 'adicional_id'
    }
    let isSelected = null

    if (['puntos_entrega', 'adicionales'].includes(target)) {
      isSelected = selectedAsignaciones[target]?.find(el => el[dictId[target]].includes(id))
    }

    if (['oferta', 'etiqueta'].includes(target)) {
      isSelected = selectedAsignaciones[target][dictId[target]] === Number(id) && selectedAsignaciones[target]
    }

    if (isSelected && ['puntos_entrega', 'adicionales'].includes(target)) {
      return setSelectedAsignaciones({
        ...selectedAsignaciones,
        [target]: selectedAsignaciones[target].map(el => {
          return el[dictId[target]] === isSelected[dictId[target]]
            ? {
              ...isSelected,
              [key]: value
            }

            : el
        })
      })
    }

    if (isSelected && ['oferta', 'etiqueta'].includes(target)) {
      return setSelectedAsignaciones({
        ...selectedAsignaciones,
        [target]: {
          ...selectedAsignaciones[target],
          [key]: value
        }
      })
    }
  }


  // FUNCION MANEJADORA DE LOS INPUTS DE LAS ASIGNACIONES MASIVAS
  const handleAsignacionMasivasInput = (e) => {
    const { name, value } = e.target
    const [target, key] = name.split('-')

    if (e.key === 'Enter') return filterDatatable(target)

    setDatatableHeadInput({
      ...datatableHeadInput,
      [target]: {
        ...datatableHeadInput[target],
        [key]: value
      }
    })
  }


  // FUNCION MANEJADORA QUE SETEA LOS VALORES DE LAS ASIGNACIONES SELECCIONADAS SEGUN EL HEAD-INPUT RELACIONADO
  const handleSetAsignacionesValue = (name, isReset) => {
    const [target, key] = name.split('-')
    const d = {
      precio_instalacion: 'instalacion_apply',
      recargo: 'recargo_apply'
    }

    if (['nulo', 'no aplica', 'null', 'nada', 'vacio', 'inactivo'].includes(datatableHeadInput[target][key])) {

      setSelectedAsignaciones({
        ...selectedAsignaciones,
        [target]: selectedAsignaciones[target].map(el => ({ ...el, [key]: undefined, [d[key]]: 0 }))
      })

      return
    }

    setSelectedAsignaciones({
      ...selectedAsignaciones,
      [target]: selectedAsignaciones[target].map(el => ({ ...el, [key]: !isReset ? datatableHeadInput[target][key] : undefined, [d[key]]: 1 }))
    })
  }


  // FUNCION QUE FILTRA LAS ASIGNACIONES SEGUN SU PARAMETRO DE BUSQUEDA
  const filterDatatable = (target) => {
    const query = datatableHeadInput[target].busqueda
    setAsignaciones(
      {
        ...unfilteredAsignaciones,
        [target]: unfilteredAsignaciones[target].filter(el => el.filter_param.includes(query.toLowerCase()))
      })
  }


  // FUNCION MANEJADORA DEL INPUT DE NOMBRE ARMADO
  const handleNombreArmado = (e, claveAtributo) => {
    const { value: atributoValue } = e.target
    const { nombre, posicion_en_nombre, incluir_en_nombre } = claveAtributo
    const [id, name] = atributoValue.split('-')

    const isSelectedAtributoIndex = nombreArmado.findIndex(el => el?.nombre_clave === nombre)
    const newAtributo = { id, name: id === '*NULL*' ? '' : name, nombre_clave: nombre, posicion_en_nombre }

    if (!incluir_en_nombre) return

    if (isSelectedAtributoIndex >= 0) {
      const updatedArr = nombreArmado.map((atributo, current) => isSelectedAtributoIndex === current ? newAtributo : atributo)
        .sort((a, b) => (a.posicion_en_nombre ?? 0) - (b.posicion_en_nombre ?? 0))

      return setNombreArmado(updatedArr)
    }

    return setNombreArmado([...nombreArmado, newAtributo].sort((a, b) => (a.posicion_en_nombre ?? 0) - (b.posicion_en_nombre ?? 0)))
  }


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputForm = (e, claveAtributo = false) => {
    const { name, value } = e.target

    // condicional para las claves atributos
    if (name.includes('select-clave')) {
      const claveName = name.split('%-%')[1]
      const [idValor, atributoValor] = value.split('-')
      let imagenesArr = claveAtributo?.valores.find(({ valor, codigo, id }) => (codigo === atributoValor || valor === atributoValor) && id === Number(idValor))?.imagenes_productos?.split(',') ?? []

      setImagenesAtributos({
        ...imagenesAtributos,
        [claveName]: imagenesArr
      })

      setForm({
        ...form,
        atributos: {
          ...form.atributos,
          [claveName]: (idValor === '*NULL*') ? undefined : value
        }
      })

      return handleNombreArmado(e, claveAtributo)
    }

    setForm({
      ...form,
      [name]: value
    })
  }


  // EFECTO QUE SETEA EL NOMBRE DE ACUERDO A LOS ATRIBUTOS SELECCIONADOS
  useEffect(() => {
    let nombre = nombreArmado.map(({ name, nombre_clave }, i, arr) => {
      if (name === '') return false
      if (nombre_clave?.includes('alto') && (arr[i - 1].nombre_clave.includes('ancho'))) return `/${name}`
      if (nombre_clave?.includes('aro')) return `%%1${name}`
      if (nombre_clave?.includes('indice_velocidad') && (arr[i - 1]?.nombre_clave?.includes('indice_carga'))) return `%%2${name}`
      return name
    })
      .filter(el => el !== false)
      .join(' ')

    if (nombre.includes('/')) {
      let [beforeAlto, afterAlto] = nombre.split('/')
      nombre = [beforeAlto.trimEnd(), afterAlto].join('/')
    }

    if (nombre.includes('%%1')) {
      let [beforeAro, afterAro] = nombre.split('%%1')
      nombre = [beforeAro.trimEnd(), afterAro].join(' ')
    }

    if (nombre.includes('%%2')) {
      let [beforeAro, afterAro] = nombre.split('%%2')
      nombre = [beforeAro.trimEnd(), afterAro].join('')
    }

    setForm((form) => ({ ...form, nombre: `${form.tipo_elemento_id.split('-')[1] ?? ''} ${nombre}` }))
  }, [nombreArmado])


  // EFECTO QUE SETEA EL SELECT DE TIPOS-ELEMENTOS Y LOS RESULTADOS DE LAS ASIGNACIONES MASIVAS
  useEffect(() => {
    axios(CONSTANT.fetchTiposElementos,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST'
      })
      .then(({ data }) => setTiposElementosSelect(data?.tipos_elementos?.data))
      .catch(err => {
        console.error(err)
        setTiposElementosSelect([])
      })

    setterAsignaciones().then(data => setUnfilteredAsignaciones(data))
    setterAsignaciones().then(data => {
      setAsignaciones(data)

      setSelectedAsignaciones({
        ...selectedAsignaciones,
        metodos_pago: data.metodos_pago.filter(el => el.nombre.toLocaleLowerCase() !== 'efectivo').map(el => ({
          metodo_pago_id: el.id,
          estatus: 1
        }))
      })
    })

    return () => {
      setForm(CONSTANT.initialFormState)
    }
  }, [])


  // EFECTO QUE SETEA EL SELECT DE CATEGORIAS LUEGO DE SELECCIONAR EL TIPO-ELEMENTO
  useEffect(() => {
    if (!form.tipo_elemento_id) return
    axios(CONSTANT.fetchCategorias,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: {
          filtros: {
            tipo_elemento_id: [Number(form?.tipo_elemento_id?.split('-')[0])]
          }
        }
      })
      .then(({ data }) => setCategoriasSelect(data?.categorias?.data))
      .catch(err => {
        console.error(err)
        setCategoriasSelect([])
      })
  }, [form.tipo_elemento_id])


  // EFECTO QUE SETEA LOS SELECTS SE CLAVES ATRIBUTOS LUEGO DE SELECCIONAR LA CATEGORIA
  useEffect(() => {
    if (!form.categoria_id) return
    axios(CONSTANT.fetchClavesAtributos(form.categoria_id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        setClavesAtributosSelect(data)

        setForm(form => {
          let atributos = {}

          data.forEach(({ valores, nombre }) => {
            const defaultAtributo = valores.find(({ valor_default }) => valor_default)
            if (defaultAtributo) atributos = {
              ...atributos, [nombre]: `${defaultAtributo.id}-${defaultAtributo.valor.split('-').length > 1
                ? defaultAtributo.valor.split('-').join('_') + '-' + defaultAtributo.nombre
                : defaultAtributo.valor + '-' + defaultAtributo.nombre}`
            }
          })

          return { ...form, atributos }
        })
      })
      .catch(err => {
        console.error(err)
        setClavesAtributosSelect([])
      })
  }, [form.categoria_id])


  // FUNCION MANEJADORA PARA LA CONSULTA EN EL ERP POR SKU
  const handleFecthErp = (e) => {
    axios(CONSTANT.fetchERPData(form.sku),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        const { descripcion, ultimo_costo, stock_disponible } = data?.data[0]
        dispatch(fireToaster({ title: 'ERP: Producto encontrado', html: `<b>${descripcion}</b>`, icon: 'success' }))
        setForm({
          ...form,
          stock: Number(stock_disponible),
          erp_descripcion: descripcion,
          ultimo_costo
        })
      })
      .catch(({ response }) => {
        console.error(response)
        const { error } = response?.data ?? 'No se encontraron productos'
        dispatch(fireToaster({ title: 'ERP', text: error, icon: 'info' }))
        setForm({
          ...form,
          stock: undefined,
          ultimo_costo: undefined
        })
      })
  }


  // FUNCION MANJEADORA QUE CREA EL REGISTRO EN LA BBDD Y REALIZA LAS ASIGNACIONES MASIVAS
  const handleCreateProducto = async () => {
    let producto_id = null

    // if (!form.categoria_id.length) {
    //   const toasterContent = {
    //     title: 'Debe seleccionar una categoría',
    //     icon: 'warning'
    //   }
    //   return dispatch(fireToaster(toasterContent))
    // }

    // if (!form.sku.length) {
    //   const toasterContent = {
    //     title: 'El SKU es obligatorio',
    //     icon: 'warning'
    //   }
    //   return dispatch(fireToaster(toasterContent))
    // }

    // if (!form.nombre.length) {
    //   const toasterContent = {
    //     title: 'El nombre no debe estar vacío',
    //     icon: 'warning'
    //   }
    //   return dispatch(fireToaster(toasterContent))
    // }

    // if (!form.descripcion.length) {
    //   const toasterContent = {
    //     title: 'La descripcíon no debe estar vacío',
    //     icon: 'warning'
    //   }
    //   return dispatch(fireToaster(toasterContent))
    // }

    // if (!form.precio_final.length) {
    //   const toasterContent = {
    //     title: 'El precio final es obligatorio',
    //     icon: 'warning'
    //   }
    //   return dispatch(fireToaster(toasterContent))
    // }

    // if (!form['meta-descripcion'].length) {
    //   const toasterContent = {
    //     title: 'La meta-descripción no puede estar vacía',
    //     icon: 'warning'
    //   }
    //   return dispatch(fireToaster(toasterContent))
    // }

    // if (!form['meta-titulo'].length) {
    //   const toasterContent = {
    //     title: 'El meta-título no puede estar vacío',
    //     icon: 'warning'
    //   }
    //   return dispatch(fireToaster(toasterContent))
    // }

    // if (!form.stock.length) {
    //   const toasterContent = {
    //     title: 'El stock es obligatorio',
    //     icon: 'warning'
    //   }
    //   return dispatch(fireToaster(toasterContent))
    // }

    // if (!Object.values(form.atributos).length) {
    //   const toasterContent = {
    //     title: 'Error en la selección de atributos, por favor asegúrese de seleccionar los mínimos obligatorios',
    //     icon: 'warning'
    //   }
    //   return dispatch(fireToaster(toasterContent))
    // }

    let dataProducto = {
      categoria_id: idExtractor(form.categoria_id),
      sku: form.sku,
      nombre: form.nombre,
      descripcion: form.descripcion,
      ultimo_costo: Number(form.ultimo_costo),
      precio_final: Number(form.precio_final),
      nuevo: form.nuevo ? 1 : 0,
      es_variante: form.es_variante ? 1 : 0,
      publicado: form.publicado ? 1 : 0,
      'meta-descripcion': form['meta-descripcion'],
      'meta-titulo': form['meta-titulo'],
      'datos-estructurados': form['meta-descripcion'],
      stock: Number(form.stock),
      atributos: Object.values(form.atributos).map((v) => idExtractor(v)).filter(el => el !== null),
      imagenes: Object.values(imagenesAtributos)
        .filter(el => el.length)
        .reduce((prev, current) => prev = [...prev, ...current.map(url => url)], [])
    }

    let imagenesProductoData = new FormData()
    imagenesProductoData.append('estatus', 1)
    imagenesProducto.length ? imagenesProducto.forEach(({ file }) => imagenesProductoData.append('imagenes[]', file)) : imagenesProductoData = null

    let asignacionesProducto = {
      adicionales: [],
      etiqueta: {},
      oferta: {},
      puntos_entrega: [],
      metodos_pago: []
    }

    if (selectedAsignaciones.adicionales.length) {
      asignacionesProducto.adicionales = selectedAsignaciones.adicionales.map(({ adicional_id, precio, estatus }) => ({
        adicional_id: Number(adicional_id),
        precio: Number(precio),
        estatus: Number(estatus)
      }))
    }

    if (selectedAsignaciones.puntos_entrega.length) {
      asignacionesProducto.puntos_entrega = selectedAsignaciones.puntos_entrega.map(({ punto_entrega_id, recargo, precio_instalacion, recargo_apply, instalacion_apply, estatus }) => ({
        punto_entrega_id: Number(punto_entrega_id),
        recargo: recargo_apply ? Number(recargo) : null,
        precio_instalacion: instalacion_apply ? Number(precio_instalacion) : null,
        estatus: Number(estatus)
      }))
    }

    if (selectedAsignaciones.metodos_pago.length) {
      asignacionesProducto.metodos_pago = selectedAsignaciones.metodos_pago.map(({ metodo_pago_id, estatus }) => ({
        metodo_pago_id: Number(metodo_pago_id),
        estatus: Number(estatus)
      }))
    }

    if (Object.keys(selectedAsignaciones.oferta).length) {
      asignacionesProducto.oferta = {
        oferta_id: Number(selectedAsignaciones.oferta.oferta_id),
        cantidad_critica: Number(selectedAsignaciones.oferta.cantidad_critica),
        cantidad_limite: Number(selectedAsignaciones.oferta.cantidad_limite),
        fecha_hora_inicio: (selectedAsignaciones.oferta?.fecha_hora_inicio?.split('T').join(' ') + ':00') ?? null,
        fecha_hora_final: (selectedAsignaciones.oferta?.fecha_hora_final?.split('T').join(' ') + ':00') ?? null
      }
    }

    if (Object.keys(selectedAsignaciones.etiqueta).length) {
      asignacionesProducto.etiqueta = {
        etiqueta_id: selectedAsignaciones.etiqueta.etiqueta_id,
        prioridad_resultado: Number(selectedAsignaciones.etiqueta.prioridad_resultado),
        fecha_hora_inicio: `${selectedAsignaciones.etiqueta?.fecha_hora_inicio?.split('T').join(' ')}:00`,
        fecha_hora_final: `${selectedAsignaciones.etiqueta?.fecha_hora_final?.split('T').join(' ')}:00`
      }
    }

    try {
      await axios(CONSTANT.createProducto,
        {
          headers: {
            'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
          },
          method: 'POST',
          data: dataProducto
        })
        .then(({ data }) => {
          // console.log(data);
          producto_id = data.producto.id
          imagenesProductoData && imagenesProductoData.append('producto_id', producto_id)
          dispatch(fireToaster({ title: `Producto creado con exito: ID #${producto_id} `, icon: 'success' }))
        })
        .catch(err => {
          console.error(err)
          const { errores } = err.response.data
          let detalles = []
          Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

          const toasterContent = {
            title: `
              Error en la creación de producto
            (${err.response.status} - ${err.response.statusText})
          `,
            html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
            icon: 'error'
          }

          dispatch(fireToaster(toasterContent))

          throw new Error('El producto no pudo ser creado')
        })

      if (!producto_id) throw new Error('No se encontró el ID del producto')

      if (imagenesProductoData) {
        await axios(CONSTANT.createImagenesProducto,
          {
            headers: {
              'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
            method: 'POST',
            data: imagenesProductoData
          })
          .then(({ data }) => {
            // console.log(data)
            dispatch(fireToaster({ title: 'Imágenes asignadas al producto con éxito', icon: 'success' }))
          })
          .catch(err => {
            console.error(err)
            const { errores } = err.response.data
            let detalles = []
            Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

            const toasterContent = {
              title: `
              Error en la asignación de imágenes al producto
            (${err.response.status} - ${err.response.statusText})
          `,
              html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
              icon: 'error'
            }

            dispatch(fireToaster(toasterContent))

          })
      }

      if (asignacionesProducto.adicionales.length) {
        await axios(CONSTANT.asignacionAdicionales,
          {
            headers: {
              'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
            method: 'POST',
            data: { asignaciones: asignacionesProducto.adicionales, producto_id }
          })
          .then(({ data }) => {
            // console.log(data)
            dispatch(fireToaster({ title: 'Asignación de adicionales a producto con éxito', icon: 'success' }))
          })
          .catch(err => {
            console.error(err)
            const { errores } = err.response.data
            let detalles = []
            Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

            const toasterContent = {
              title: `
              Error en la asignación de adicionales al producto
            (${err.response.status} - ${err.response.statusText})
          `,
              html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
              icon: 'error'
            }

            dispatch(fireToaster(toasterContent))
          })
      }

      if (asignacionesProducto.etiqueta.etiqueta_id) {
        await axios(CONSTANT.asignacionEtiqueta,
          {
            headers: {
              'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
            method: 'POST',
            data: { ...asignacionesProducto.etiqueta, producto_id }
          })
          .then(({ data }) => {
            // console.log(data)
            dispatch(fireToaster({ title: 'Asignación de etiqueta a producto con éxito', icon: 'success' }))
          })
          .catch(err => {
            console.error(err)
            const { errores } = err.response.data
            let detalles = []
            Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

            const toasterContent = {
              title: `
              Error en la asignación de etiqueta al producto
            (${err.response.status} - ${err.response.statusText})
          `,
              html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
              icon: 'error'
            }

            dispatch(fireToaster(toasterContent))
          })
      }

      if (asignacionesProducto.oferta.oferta_id) {
        await axios(CONSTANT.asignacionOferta,
          {
            headers: {
              'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
            method: 'POST',
            data: { ...asignacionesProducto.oferta, producto_id }
          })
          .then(({ data }) => {
            // console.log(data)
            dispatch(fireToaster({ title: 'Asignación de oferta a producto con éxito', icon: 'success' }))
          })
          .catch(err => {
            console.error(err)
            const { errores } = err.response.data
            let detalles = []
            Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

            const toasterContent = {
              title: `
              Error en la asignación de oferta al producto
            (${err.response.status} - ${err.response.statusText})
          `,
              html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
              icon: 'error'
            }

            dispatch(fireToaster(toasterContent))
          })
      }

      if (asignacionesProducto.puntos_entrega.length) {
        await axios(CONSTANT.asignacionPuntosEntrega,
          {
            headers: {
              'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
            method: 'POST',
            data: { asignaciones: asignacionesProducto.puntos_entrega, producto_id }
          })
          .then(({ data }) => {
            // console.log(data)
            dispatch(fireToaster({ title: 'Asignación de puntos de entrega a producto con éxito', icon: 'success' }))
          })
          .catch(err => {
            console.error(err)
            const { errores } = err.response.data
            let detalles = []
            Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

            const toasterContent = {
              title: `
              Error en la asignación de puntos de entrega al producto
            (${err.response.status} - ${err.response.statusText})
          `,
              html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
              icon: 'error'
            }

            dispatch(fireToaster(toasterContent))
          })
      }

      if (asignacionesProducto.metodos_pago.length) {
        await axios(CONSTANT.asignacionMetodosPago,
          {
            headers: {
              'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
            method: 'POST',
            data: { asignaciones: asignacionesProducto.metodos_pago, producto_id }
          })
          .then(({ data }) => {
            // console.log(data)
            dispatch(fireToaster({ title: 'Asignación de métodos de pago a producto con éxito', icon: 'success' }))
          })
          .catch(err => {
            console.error(err)
            const { errores } = err.response.data
            let detalles = []
            Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

            const toasterContent = {
              title: `
              Error en la asignación de métodos de pago al producto
            (${err.response.status} - ${err.response.statusText})
          `,
              html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
              icon: 'error'
            }

            dispatch(fireToaster(toasterContent))
          })
      }

      redirect(CONSTANT.redirectProducto(producto_id))
    } catch (err) {
      console.error(err);
    }
  }


  return (
    <>
      {/* TIPO DE PRODUCTO */}
      <div className="col mb-1">
        {tiposElementosSelect.length

          ? <Accordion title="Tipo de producto" isOpen>
            <div className="card-body">
              <div className="row">
                <div className="d-flex gap-2">
                  <SelectFilter
                    labelText="Elemento"
                    size="col-12"
                    name='tipo_elemento_id'
                    sizeDesk="col-md-2"
                    optionObj={tiposElementosSelect?.map(({ id, nombre }) => ({ id, name: nombre }))}
                    value={form?.tipo_elemento_id?.split('-')[1] ?? 'Seleccione'}
                    handleValue={handleInputForm}
                  />

                  {categoriasSelect.length
                    ? <SelectFilter
                      labelText="Categoría"
                      size="col-12"
                      sizeDesk="col-md-2"
                      name='categoria_id'
                      optionObj={categoriasSelect?.map(({ id, nombre }) => ({ id, name: nombre }))}
                      value={form?.categoria_id?.split('-')[1] ?? 'Seleccione'}
                      handleValue={handleInputForm}
                    />
                    : <FormInput
                      labelText="Categoría"
                      value='Seleccione un elemento'
                      size="col-12"
                      sizeDesk="col-md-2"
                      isDisabled
                    />
                  }
                </div>
              </div>
            </div>
          </Accordion>

          : <CustomLoader />
        }
      </div>

      {/* ATRIBUTOS */}
      <div className="col mb-1">
        <Accordion title="Atributos" isOpen={form.categoria_id}>
          <div className="card-body">
            {clavesAtributosSelect.length
              ? <div className="row">
                {clavesAtributosSelect &&
                  clavesAtributosSelect.filter(el => el.valores.length).sort((a, b) => (b.valor_obligatorio ? 1 : 0) - (a.valor_obligatorio ? 1 : 0)).map(clave => (
                    <SelectFilter
                      key={`${clave?.nombre}`}
                      labelText={`${clave?.valor_obligatorio ? '* ' : ''}${clave?.nombre?.split('_')?.join(' ').toUpperCase()} `}
                      size='col-12'
                      name={`select-clave%-%${clave?.nombre}`}
                      sizeDesk='col-md-2'
                      handleValue={(e) => handleInputForm(e, clave)}
                      value={form?.atributos[clave?.nombre]?.split('-')[2] ?? 'Seleccione'}
                      optionObj={valorAtributoArrayFormatter(clave.valores)}
                    />
                  ))}
              </div>

              : <span className="my-auto">Seleccione una categoría</span>
            }
          </div>
        </Accordion>
      </div>

      {/* IMÁGENES */}
      <div className="col mb-1">
        <Accordion title='Imágenes' isOpen={form.categoria_id}>
          <div className="card-body">
            <div className="row">
              <div className="col">
                <p className="fs-4">Imágenes (<b>atributos</b>)</p>
                {Object.values(imagenesAtributos).filter(el => el.length).length
                  ? Object.values(imagenesAtributos).filter(el => el.length).map(key => (
                    key.map((imagen, i) => (
                      <img
                        key={`clave-atributo-imagen-${i}-${imagen?.nombre ?? 'unk'} `}
                        className='height-listado'
                        src={BASE_URL + 'storage/' + imagen}
                        alt="img"
                      />
                    ))
                  ))
                  : <i className="my-auto">Sin imágenes para mostrar...</i>
                }
              </div>

              <div className="col border-start">
                <p className="fs-4">Imágenes (<b>producto</b>)</p>
                <FileUploaderB handleValue={setImagenesProducto} value={imagenesProducto} />
              </div>
            </div>
          </div>
        </Accordion>
      </div>

      {/* PRODUCTO ARMADO */}
      <div className="col mb-1 card py-75 mb-2">
        <div className="card-body">
          <div className="card-title fs-2 text-center">Producto armado</div>

          {/* CHECKS INICIALES */}
          <div className="row border-bottom border-3 m-1 my-0 pb-1">
            <CheckBoxInput
              labelText="Nuevo"
              classes="col-md-1 ms-2"
              name='nuevo'
              handleValue={handleInputForm}
              value={form.nuevo}
            />

            <CheckBoxInput
              labelText="Publicado"
              classes="col-md-1 mx-75"
              name='publicado'
              handleValue={handleInputForm}
              value={form.publicado}
            />

            <CheckBoxInput
              labelText="Variante"
              classes="col-md-1 mx-75"
              name='es_variante'
              handleValue={handleInputForm}
              value={form.es_variante}
            />
          </div>

          {/* META-DATA */}
          <div className="row border-bottom border-3 m-1">
            <b className="text-black fs-4 text-center">Metadatos</b>

            <TextareaInput
              labelText="* Meta título"
              placeholder="Ingrese un metatítulo"
              name='meta-titulo'
              value={form['meta-titulo']}
              handleValue={handleInputForm}
              size="col-12"
              sizeDesk="col-md-4"
              rows={1}
            />

            <TextareaInput
              labelText="* Meta descripción"
              placeholder="Ingrese una metadescripción"
              name='meta-descripcion'
              value={form['meta-descripcion']}
              handleValue={handleInputForm}
              size="col-12"
              sizeDesk="col-md-4"
              rows={1}
            />

            <TextareaInput
              labelText="* Descripción"
              placeholder="Descripción"
              name='descripcion'
              value={form.descripcion}
              handleValue={handleInputForm}
              size="col-12"
              sizeDesk="col-md-4"
              rows={1}
            />
          </div>

          {/* ATRIBUTOS SELECCIONADOS */}
          <div className="row m-1 mb-0">
            <b className="text-black fs-4 mt-1 text-center">Atributos</b>

            {clavesAtributosSelect.length
              ? <div className="row">
                {clavesAtributosSelect &&
                  clavesAtributosSelect.filter(el => el.valores.length).sort((a, b) => (b.valor_obligatorio ? 1 : 0) - (a.valor_obligatorio ? 1 : 0)).map(clave => (
                    <FormInput
                      key={`NomAr-${clave?.nombre}`}
                      labelText={`${clave?.valor_obligatorio ? '* ' : ''}${clave?.nombre?.split('_')?.join(' ').toUpperCase()} `}
                      value={form.atributos[clave?.nombre]?.split('-')[1] ?? ''}
                      handleValue={(e) => handleNombreArmado(e, clave.posicion_en_nombre)}
                      size="col-12"
                      sizeDesk="col-md-2"
                      isDisabled
                    />
                  ))}
              </div>

              : <span className="my-auto">Seleccione una categoría</span>
            }

          </div>

          {/* NOMBRE ARMADO */}
          <div className="row border-bottom border-3 m-1 mt-0">
            <span className="text-black fs-4 mt-1"><b>Nombre armado </b> (Categoría: {form.categoria_id.split('-')[1] ?? 'Seleccione una categoría'})</span>
            <FormInput
              value={form.nombre}
              size="col-12"
              sizeDesk="col-md-9"
              name='nombre'
              isDisabled={!form.categoria_id ?? true}
              handleValue={handleInputForm}
            />
          </div>

          {/* SKU */}
          <div className="row border-bottom border-3 m-1">
            <div className="d-flex align-items-center col-md-3 col-sm-12">
              <FormInput
                labelText="* SKU"
                placeholder="SKU"
                name='sku'
                value={form.sku}
                handleValue={handleInputForm}
                size="col-8"
                sizeDesk="col-md-8"
              />
              <Button
                className="mx-50 py-1"
                text="Consultar"
                onClick={handleFecthErp}
              />

            </div>

            <FormInput
              labelText="ERP Nombre"
              placeholder="Haga su consulta"
              value={form.erp_descripcion}
              size="col-12"
              sizeDesk="col-md-8"
            />

            <FormInput
              labelText="Stock propio"
              name='stock'
              value={form.stock}
              handleValue={handleInputForm}
              placeholder="10"
              size="col-12"
              sizeDesk="col-md-2"
            />

            <FormInput
              labelText="Último costo ($ CLP)"
              name='ultimo_costo'
              value={form.ultimo_costo}
              handleValue={handleInputForm}
              placeholder="$10000"
              size="col-12"
              sizeDesk="col-md-2"
            />

            {/* <FormInput NO EXISTE
              labelText="Costo Prov."
              placeholder="$10000"
              name='costo_provisional'
              value={form.costo_provisional}
              handleValue={handleInputForm}
              size="col-12"
              sizeDesk="col-md-2"
            /> */}

            <FormInput
              labelText="* Precio final ($ CLP)"
              placeholder="$20000"
              name='precio_final'
              value={form.precio_final}
              handleValue={handleInputForm}
              size="col-12"
              sizeDesk="col-md-2"
            />

          </div>

          {/* CHECKS PRECIO FINAL  ---TODAVIA LE FALTA INFO */}
          <div className="row border-bottom border-3 m-1">
            <b className="text-black fs-4 text-center">Precio Final</b>

            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center justify-content-center col-md-2 col-sm-12">
                <CheckBoxInput
                  labelText=""
                  classes="col-md-1 me-50"
                />
                <FormInput
                  labelText="Fórmula"
                  placeholder="10000"
                  size="col-10"
                  sizeDesk="col-md-11"
                />
              </div>

              <div className="d-flex align-items-center justify-content-center col-md-2 col-sm-12">
                <CheckBoxInput
                  labelText=""
                  classes="col-md-1 me-50"
                />
                <FormInput
                  labelText="Map"
                  placeholder="10000"
                  size="col-10"
                  sizeDesk="col-md-11"
                />
              </div>

              <div className="d-flex align-items-center justify-content-center col-md-2 col-sm-12">
                <CheckBoxInput
                  labelText=""
                  classes="col-md-1 me-50" />
                <FormInput
                  labelText="Sugerido Prov."
                  placeholder="10000"
                  size="col-10"
                  sizeDesk="col-md-11"
                />
              </div>

              <div className="d-flex align-items-center justify-content-center col-md-2 col-sm-12">
                <CheckBoxInput
                  labelText=""
                  classes="col-md-1 me-50"
                />
                <FormInput
                  labelText="Fob"
                  placeholder="10000"
                  size="col-10"
                  sizeDesk="col-md-11"
                />
              </div>

              <div className="d-flex align-items-center justify-content-center col-md-2 col-sm-12">
                <CheckBoxInput
                  labelText=""
                  classes="col-md-1 me-50"
                />
                <FormInput
                  labelText="Compra"
                  placeholder="10000"
                  size="col-10"
                  sizeDesk="col-md-11"
                />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="row">

        {/* MÉTODOS DE PAGO*/}
        <AccordionTable
          title={`Métodos de pago(${asignaciones.metodos_pago?.length} resultados)`}
          classes="col-12 col-md-6 mb-2"
        >
          <Table>
            <thead className="table-light position-sticky top-0 w-100 z-100">
              <tr role="row">

                <th className="h-100 d-flex col">
                  <CheckBoxInput
                    labelText="Todos"
                    classes='m-auto'
                    handleValue={handleSelectAll}
                    name='metodos_pago'
                    value={asignaciones.metodos_pago.length === selectedAsignaciones.metodos_pago.length}
                  />
                </th>

                <th className="col-6">Nombre</th>

                <th className="col-5">Descripción</th>

              </tr>
            </thead>
            <tbody>
              {asignaciones.metodos_pago?.length
                ? asignaciones.metodos_pago.map(({ nombre, imagen, estatus, id, descripcion }) => (
                  <tr className="odd" key={`metodo-pago-${nombre}`}>
                    <td>
                      <CheckBoxInput
                        name={`${id}-${nombre}`}
                        labelText=""
                        handleValue={(e) => handleSetAsignacionesMultiples(e, 'metodos_pago')}
                        value={selectedAsignaciones.metodos_pago.find(el => Number(el.metodo_pago_id) === id) ? true : false}
                      />
                    </td>
                    <td>{nombre ?? '?'}</td>
                    <td>{descripcion ?? 'Sin descripción'}</td>
                  </tr>
                ))
                : <tr><td colSpan={3}><i>Sin resultados...</i></td></tr>
              }
            </tbody>
          </Table>
        </AccordionTable>

        {/* ADICIONALES */}
        <AccordionTable
          title={`Adicionales(${asignaciones.adicionales?.length} resultados)`}
          classes="col-12 col-md-6 mb-2"
        >
          <Table>
            <thead className="table-light position-sticky top-0 w-100 z-100">
              <tr role="row">

                <th>
                  <div>
                    <CheckBoxInput
                      labelText="Todos"
                      classes='w-fit mt-2 m-auto'
                      handleValue={handleSelectAll}
                      name='adicionales'
                      value={asignaciones.adicionales.length === selectedAsignaciones.adicionales.length}
                    />
                  </div>
                </th>

                <th className="col">
                  <div className="d-flex gap-1 w-fit position-relative">
                    <FormInput
                      labelText='Adicional'
                      placeholder="Adicional"
                      name='adicionales-busqueda'
                      value={datatableHeadInput.adicionales.busqueda}
                      handleValue={handleAsignacionMasivasInput}
                      onKeyDown={handleAsignacionMasivasInput}
                      size="col-12"
                      sizeDesk="col-md-12"
                      margin="mb-0"
                    />
                    <button
                      onClick={() => filterDatatable('adicionales')}
                      className="btn m-auto p-25"
                      style={
                        {
                          backgroundColor: '#7367f0',
                          position: 'absolute',
                          color: '#FFF',
                          top: '48%',
                          right: '10px',
                        }
                      }
                    >
                      <Icon icon='Search' />
                    </button>
                  </div>
                </th>

                <th>
                  <div className=" w-fit">
                    <div className="mx-auto d-flex w-fit">
                      <FormInputNumber
                        labelText='Precio'
                        placeholder="Cantidad"
                        name='adicionales-precio'
                        value={datatableHeadInput.adicionales.precio}
                        handleValue={handleAsignacionMasivasInput}
                        sizeDesk="col-md-12"
                        margin="mb-0 me-50"
                      />
                      <div className="d-flex flex-column">
                        <Button
                          color={"danger"}
                          icon="Trash"
                          className={"m-auto p-25"}
                          onClick={() => handleSetAsignacionesValue('adicionales-precio', true)}
                        />
                        <Button
                          icon="Plus"
                          className={"m-auto p-25"}
                          onClick={() => handleSetAsignacionesValue('adicionales-precio')}
                        />
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {asignaciones.adicionales?.length
                ? asignaciones.adicionales.map(({ id, nombre, descripcion }, i) => (
                  <tr className="odd" key={`${id}-adicional-${nombre} `}>

                    <td>
                      <CheckBoxInput
                        name={`${id}-${nombre}`}
                        labelText=""
                        handleValue={(e) => handleSetAsignacionesMultiples(e, 'adicionales')}
                        value={selectedAsignaciones.adicionales.find(el => Number(el.adicional_id) === id) ? true : false}
                      />
                    </td>

                    <td>
                      <div className="d-flex flex-column">
                        <b>{nombre?.toUpperCase() ?? '?'}</b>
                        <span>{descripcion ?? 'Sin descripción'}</span>
                      </div>
                    </td>

                    <td>
                      <div className="d-flex w-100">
                        <FormInput
                          type="number"
                          placeholder="1000"
                          name={`${id}-precio-adicionales`}
                          value={selectedAsignaciones.adicionales.find(el => Number(el.adicional_id) === id)?.precio ?? undefined}
                          handleValue={handleAsignacionesInput}
                          isDisabled={!selectedAsignaciones.adicionales.find(el => Number(el.adicional_id) === id)}
                          size="col-12"
                          margin="mb-0"
                        />
                      </div>
                    </td>
                  </tr>
                ))
                : <tr><td colSpan={3}><i>Sin resultados...</i></td></tr>
              }
            </tbody>
          </Table>
        </AccordionTable>

      </div>

      {/* PUNTOS DE ENTREGA*/}
      <AccordionTable
        title={`Puntos de entrega(${asignaciones.puntos_entrega?.length} resultados)`}
        classes="col-12 mb-2"
      >
        <Table>
          <thead className="table-light position-sticky top-0 w-100 z-100">
            <tr role="row">

              <th className="col-1">
                <div>
                  <CheckBoxInput
                    labelText="Todos"
                    classes='w-fit mt-2 m-auto'
                    handleValue={handleSelectAll}
                    name='puntos_entrega'
                    value={asignaciones.puntos_entrega?.length === selectedAsignaciones.puntos_entrega?.length}
                  />
                </div>
              </th>

              <th className="col">
                <div className="d-flex gap-1 position-relative">
                  <FormInput
                    labelText='Punto de entrega'
                    placeholder="Punto de entrega"
                    size="col-12"
                    name='puntos_entrega-busqueda'
                    value={datatableHeadInput.puntos_entrega.busqueda}
                    handleValue={handleAsignacionMasivasInput}
                    onKeyDown={handleAsignacionMasivasInput}
                    sizeDesk="col-md-12"
                    margin="mb-0"
                  />

                  <button
                    onClick={() => filterDatatable('puntos_entrega')}
                    className="btn m-auto p-25"
                    style={
                      {
                        backgroundColor: '#7367f0',
                        position: 'absolute',
                        color: '#FFF',
                        top: '48%',
                        right: '10px',
                      }
                    }
                  >
                    <Icon icon='Search' />
                  </button>
                </div>
              </th>

              <th className="col-2">
                <div className="d-flex">
                  <FormInput
                    name='puntos_entrega-precio_instalacion'
                    value={datatableHeadInput.puntos_entrega.precio_instalacion}
                    handleValue={handleAsignacionMasivasInput}
                    labelText='Instalación'
                    placeholder="Cantidad"
                    sizeDesk="col"
                    margin="mb-0 me-50"
                  />
                  <div className="d-flex flex-column">
                    <Button
                      color={"danger"}
                      icon="Trash"
                      className={"m-auto p-25"}
                      onClick={() => handleSetAsignacionesValue('puntos_entrega-precio_instalacion', true)}
                    />
                    <Button
                      icon="Plus"
                      className={"m-auto p-25"}
                      onClick={() => handleSetAsignacionesValue('puntos_entrega-precio_instalacion')}
                    />
                  </div>
                </div>
              </th>

              <th className="col-2">
                <div className="d-flex">
                  <FormInput
                    name='puntos_entrega-recargo'
                    value={datatableHeadInput.puntos_entrega.recargo}
                    handleValue={handleAsignacionMasivasInput}
                    labelText='Recargo'
                    placeholder="Cantidad"
                    sizeDesk="col"
                    margin="mb-0 me-50"
                  />
                  <div className="d-flex flex-column">
                    <Button
                      color={"danger"}
                      icon="Trash"
                      className={"m-auto p-25"}
                      onClick={() => handleSetAsignacionesValue('puntos_entrega-recargo', true)}
                    />
                    <Button
                      icon="Plus"
                      className={"m-auto p-25"}
                      onClick={() => handleSetAsignacionesValue('puntos_entrega-recargo')}
                    />
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {asignaciones.puntos_entrega?.length
              ? asignaciones.puntos_entrega.map(({ rut_proveedor, nombre, descripcion, direccion, comuna, tipo_destino, id }, i) => (
                <tr className="odd" key={`${i}-punto-entrega-${nombre}-${rut_proveedor}`}>

                  <td>
                    <div className="d-flex h-100">
                      <CheckBoxInput
                        name={`${id}-${nombre}`}
                        labelText=""
                        handleValue={(e) => handleSetAsignacionesMultiples(e, 'puntos_entrega')}
                        value={selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id) ? true : false}
                        classes='m-auto'
                      />
                    </div>
                  </td>

                  <td>
                    <div className="d-flex flex-column gap-50">
                      <b>Tipo destino: {tipo_destino?.nombre ?? 'N/A'} | Región: {comuna?.region?.nombre ?? 'N/A'} | Nombre: {nombre ?? 'N/A'} | Dirección: {direccion ?? 'N/A'}</b>
                      <span>RUT: <b>{rut_proveedor}</b> | {descripcion ?? 'Sin descripción'}</span>
                    </div>
                  </td>

                  <td>
                    <div className='d-flex flex-column'>
                      {selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id) &&
                        <Switch
                          value={selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id)?.instalacion_apply}
                          name={`${id}-instalacion_apply-puntos_entrega`}
                          labelText='Instalación'
                          isList
                          handleValue={handleAsignacionesInput}
                        />
                      }

                      <FormInput
                        type="number"
                        placeholder="1000"
                        name={`${id}-precio_instalacion-puntos_entrega`}
                        value={selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id)?.precio_instalacion ?? undefined}
                        handleValue={handleAsignacionesInput}
                        isDisabled={
                          (selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id) &&
                            (selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id)?.instalacion_apply) ? false : true)
                        }
                        size="col-12"
                        margin="mb-0"
                      />
                    </div>

                  </td>

                  <td>
                    <div className='d-flex flex-column'>
                      {selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id) &&
                        <Switch
                          value={selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id)?.recargo_apply}
                          name={`${id}-recargo_apply-puntos_entrega`}
                          labelText='Recargo'
                          isList
                          handleValue={handleAsignacionesInput}
                        />
                      }

                      <FormInput
                        type="number"
                        placeholder="2000"
                        name={`${id}-recargo-puntos_entrega`}
                        value={selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id)?.recargo ?? undefined}
                        handleValue={handleAsignacionesInput}
                        isDisabled={
                          (selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id) &&
                            (selectedAsignaciones.puntos_entrega.find(el => Number(el.punto_entrega_id) === id)?.recargo_apply) ? false : true)
                        }
                        size="col-12"
                        margin="mb-0"
                      />
                    </div>
                  </td>
                </tr>
              ))
              : <tr><td colSpan={4}><i>Sin resultados...</i></td></tr>
            }
          </tbody>
        </Table>
      </AccordionTable>

      {/* OFERTAS*/}
      <AccordionTable
        title={`Ofertas(${asignaciones.ofertas?.length ?? 0} resultados)`}
        classes='col-12 mb-2'
      >
        <Table>
          <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
            <tr role="row">

              <th className='col-md-3'>
                <div className="d-flex gap-1 position-relative">
                  <FormInput
                    labelText='Oferta'
                    name='ofertas-busqueda'
                    value={datatableHeadInput.ofertas.busqueda}
                    handleValue={handleAsignacionMasivasInput}
                    onKeyDown={handleAsignacionMasivasInput}
                    placeholder='Buscar...'
                    sizeDesk='col-12'
                    classes='text-capitalize sub-text-3 font-weight-normal'
                    margin='my-auto'
                  />

                  <button
                    onClick={() => filterDatatable('ofertas')}
                    className="btn m-auto p-25"
                    style={
                      {
                        backgroundColor: '#7367f0',
                        position: 'absolute',
                        color: '#FFF',
                        top: '48%',
                        right: '10px',
                      }
                    }
                  >
                    <Icon icon='Search' />
                  </button>
                </div>
              </th>

              <th className="text-center"><p className="mt-2">Fecha inicio</p></th>
              <th className="text-center"><p className="mt-2">Fecha final</p></th>
              <th className="text-center"><p className="mt-2">Cantidad crítica</p></th>
              <th className="text-center"><p className="mt-2">Cantidad límite</p></th>
            </tr>
          </thead>
          <tbody>
            {asignaciones.ofertas?.length
              ? asignaciones.ofertas.map(({ descripcion, id, nombre, fecha_hora_inicio, fecha_hora_final }, i) => {
                return (
                  <tr className="odd multi-data" key={`oferta-${id}-${nombre}`}>

                    <td>
                      <div className="d-flex">
                        <CheckBoxInput
                          name={`${id}-${nombre}`}
                          labelText=""
                          handleValue={() => handleSetAsignacionesUnicas({ id, nombre }, 'oferta')}
                          value={Number(selectedAsignaciones.oferta.oferta_id) === id}
                          classes='my-auto mx-50'
                        />
                        <div className="d-flex flex-column">
                          <b>{nombre?.toUpperCase() ?? 'N/A'}</b>
                          <span className='sub-text-3 text-lgray'>
                            Inicio: <b>{fecha_hora_inicio?.split('T')[0] ?? 'N/A'}</b> | Final: <b>{fecha_hora_final?.split('T')[0] ?? 'N/A'}</b>
                          </span>
                          <span className='sub-text-3 text-lgray'>{descripcion ?? 'Sin descripción'}</span>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className='d-flex fs-2'>
                        <FormInput
                          type='datetime-local'
                          name={`${id}-fecha_hora_inicio-oferta`}
                          value={Number(selectedAsignaciones.oferta?.oferta_id) === id ? selectedAsignaciones.oferta?.fecha_hora_inicio : undefined}
                          handleValue={handleAsignacionesInput}
                          isDisabled={!(selectedAsignaciones.oferta?.oferta_id === id)}
                          placeholder='N/A'
                          margin='mb-0'
                        />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex fs-2'>
                        <FormInput
                          type='datetime-local'
                          name={`${id}-fecha_hora_final-oferta`}
                          value={Number(selectedAsignaciones.oferta?.oferta_id) === id ? selectedAsignaciones.oferta?.fecha_hora_final : undefined}
                          handleValue={handleAsignacionesInput}
                          isDisabled={!(selectedAsignaciones.oferta?.oferta_id === id)}
                          placeholder='N/A'
                          margin='mb-0'
                        />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex fs-2'>
                        <FormInput
                          type='number'
                          name={`${id}-cantidad_critica-oferta`}
                          value={Number(selectedAsignaciones.oferta?.oferta_id) === id ? selectedAsignaciones.oferta?.cantidad_critica : undefined}
                          handleValue={handleAsignacionesInput}
                          isDisabled={!(selectedAsignaciones.oferta?.oferta_id === id)}
                          placeholder='0'
                          margin='mb-0'
                        />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex fs-2'>
                        <FormInput
                          type='number'
                          name={`${id}-cantidad_limite-oferta`}
                          value={Number(selectedAsignaciones.oferta?.oferta_id) === id ? selectedAsignaciones.oferta?.cantidad_limite : undefined}
                          handleValue={handleAsignacionesInput}
                          isDisabled={!(selectedAsignaciones.oferta?.oferta_id === id)}
                          placeholder='0'
                          margin='mb-0'
                        />
                      </div>
                    </td>
                  </tr>
                )
              })
              : <tr><td colSpan={5}><i>Sin resultados...</i></td></tr>
            }
          </tbody>
        </Table>
      </AccordionTable>

      {/*ETIQUETAS*/}
      < AccordionTable
        title={`Etiquetas(${asignaciones.etiquetas.length ?? 0} resultados)`}
        classes="col-12 mb-2"
      >
        <Table>
          <thead className="table-light position-sticky top-0 w-100 z-100">
            <tr role="row">

              <th>
                <div className="d-flex gap-1 position-relative">
                  <FormInput
                    name='etiquetas-busqueda'
                    value={datatableHeadInput.etiquetas.busqueda}
                    handleValue={handleAsignacionMasivasInput}
                    onKeyDown={handleAsignacionMasivasInput}
                    placeholder="Buscar..."
                    labelText='Etiquetas'
                    size="col-12"
                    sizeDesk="col-md-12"
                    margin="mb-0"
                  />

                  <button
                    onClick={() => filterDatatable('ofertas')}
                    className="btn m-auto p-25"
                    style={
                      {
                        backgroundColor: '#7367f0',
                        position: 'absolute',
                        color: '#FFF',
                        top: '48%',
                        right: '10px',
                      }
                    }
                  >
                    <Icon icon='Search' />
                  </button>
                </div>
              </th>

              <th><p className="mt-2 text-center">Fecha inicio</p></th>
              <th><p className="mt-2 text-center">Fecha final</p></th>
            </tr>
          </thead>
          <tbody>
            {asignaciones.etiquetas?.length
              ? asignaciones.etiquetas.map(({
                descripcion, nombre, fecha_hora_final, fecha_hora_inicio, id, neu_oferta, prioridad
              }) => (
                <tr className="odd" key={`etiquetas-${id}-${nombre}`}>

                  <td>
                    <div className="d-flex">
                      <CheckBoxInput
                        name={`${id}-${nombre}`}
                        labelText=""
                        handleValue={() => handleSetAsignacionesUnicas({ id, nombre, prioridad }, 'etiqueta')}
                        value={Number(selectedAsignaciones.etiqueta.etiqueta_id) === id ? true : false}
                        classes='my-auto mx-50'
                      />

                      <div className="d-flex flex-column">
                        <b>{nombre?.toUpperCase() ?? '?'}</b>
                        <span>
                          Inicio: <b>{fecha_hora_inicio?.split('T')[0] ?? 'N/A'}</b> | Final: <b>{fecha_hora_final?.split('T')[0] ?? 'N/A'}</b>
                        </span>
                        <b>{neu_oferta?.nombre ?? 'Sin oferta'}</b>
                        <span>{descripcion?.length ? descripcion : 'Sin descripción'}</span>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className='d-flex fs-2'>
                      <FormInput
                        type='datetime-local'
                        name={`${id}-fecha_hora_inicio-etiqueta`}
                        value={Number(selectedAsignaciones.etiqueta?.etiqueta_id) === id ? selectedAsignaciones.etiqueta?.fecha_hora_inicio : undefined}
                        handleValue={handleAsignacionesInput}
                        isDisabled={!(selectedAsignaciones.etiqueta?.etiqueta_id === id)}
                        placeholder='N/A'
                        margin='mb-0'
                        classes='mx-auto'
                      />
                    </div>
                  </td>

                  <td>
                    <div className='d-flex fs-2'>
                      <FormInput
                        type='datetime-local'
                        name={`${id}-fecha_hora_final-etiqueta`}
                        value={Number(selectedAsignaciones.etiqueta?.etiqueta_id) === id ? selectedAsignaciones.etiqueta?.fecha_hora_final : undefined}
                        handleValue={handleAsignacionesInput}
                        isDisabled={!(selectedAsignaciones.etiqueta?.etiqueta_id === id)}
                        placeholder='N/A'
                        margin='mb-0'
                        classes='mx-auto'
                      />
                    </div>
                  </td>
                </tr>
              ))
              : <tr><td colSpan={3}><i>Sin resultados...</i></td></tr>
            }
          </tbody>
        </Table>
      </AccordionTable>

      <Button text='Crear producto' onClick={() => handleCreateProducto()} />
    </ >
  );
};

export default Create;

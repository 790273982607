import { Navigate, Route, Routes } from 'react-router-dom'
import Listado from '../views/Proveedores/Listado'
import Detalle from '../views/Proveedores/Detalle'
import { useSelector } from 'react-redux'

import ProductosEnProveedorRouter from './Proveedores/ProductosEnProveedor'
import ProveedoresCategoriaRouter from './Proveedores/ProveedoresCategoriaRouter'
import ProductoPropioEnProveedorRouter from './Proveedores/ProductoPropioEnProveedor'
import Form from '../views/Proveedores/Form'

const ProveedoresRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['proveedores.crear'] &&
            <Route path='/create' element={<Form />} />
         }

         {user.permisos['proveedores.editar'] &&
            <Route path='/update/:id' element={<Form />} />
         }

         {user.permisos['proveedores.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         <Route path='/productos/*' element={<ProductosEnProveedorRouter />} />

         <Route path='/categorias/*' element={<ProveedoresCategoriaRouter />} />

         <Route path='/productos-propios-proveedor/*' element={<ProductoPropioEnProveedorRouter />} />

         <Route path='*' element={<Navigate to='/home' replace />} />

      </Routes>
   )
}

export default ProveedoresRouter
import { useEffect, useState } from "react";
import { fireToaster } from "../../redux/actions/toaster";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import FileUploaderB from "../../components/FileUploader_B";


// Endpoints
import { getOne } from "../../endpoints/getOne";
import { BASE_URL } from "../../endpoints";
import { getAll } from "../../endpoints/getAll";
import SelectFilter from "../../components/SelectFilter";
import CustomLoader from "../../components/CustomLoader";


const CONSTANT = {
  redirectUrl: '/generales/paises',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitle: 'Editar país',
  title: 'Países',
  queryUrl: getOne.pais,
  fetchPaisesErp: getAll.erp_paises,
  initialFormState: {
    locale: '',
    extension_telefonica: '',
    nombre: '',
    cod_maxi: '',
    patron_identificacion: '',
    estatus: 1
  },
  selectFilterInitialState: {
    erpPaises: [],
  }
}


const handleSelectFiltersData = async () => {
  const erpPaises = await axios(CONSTANT.fetchPaisesErp,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.paises] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { erpPaises }
}


const Update = () => {
  const [imagen_bandera, setImagen_bandera] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetchData, setFetchData] = useState(CONSTANT.initialFormState)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const { id } = useParams()


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { target } = e

    setFetchData({
      ...fetchData,
      [target.name]: target.value,
    })
  }


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  useEffect(() => {
    axios(CONSTANT.queryUrl(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        const res = data.datos.pais //MAL FORMATEO DE RESPUESTA DEL BACKEND
        const selectedCodMaxi = selectFilter.erpPaises.find(({ cod_maxi }) => cod_maxi === res?.cod_maxi)

        setFetchData({
          ...res,
          cod_maxi: `${selectedCodMaxi?.cod_maxi ?? '*NULL*'}-${selectedCodMaxi?.nombre ?? 'Error'} | ${selectedCodMaxi?.cod_maxi ?? ''}`
        })
      })
      .catch(err => console.error(err))
    return () => {
      setFetchData(null)
    }
  }, [id, selectFilter.erpPaises])


  const handleFetch = async (e) => {
    e.preventDefault()

    if (!fetchData.nombre.length) return dispatch(fireToaster({ title: 'El nombre es obligatorio', icon: 'info' }))
    if (!fetchData.locale.length) return dispatch(fireToaster({ title: 'Locale es obligatorio', icon: 'info' }))
    if (!fetchData.cod_maxi.length) return dispatch(fireToaster({ title: 'El código maximise es obligatorio', icon: 'info' }))
    if (!fetchData.extension_telefonica.length) return dispatch(fireToaster({ title: 'La extensión telefónica es obligatoria', icon: 'info' }))
    if (!fetchData.patron_identificacion.length) return dispatch(fireToaster({ title: 'El patrón identificación es obligatorio', icon: 'info' }))

    let data = new FormData()
    data.append('nombre', fetchData?.nombre)
    data.append('cod_maxi', fetchData?.cod_maxi.split('-')[0])
    imagen_bandera.length && data.append('imagen_bandera', imagen_bandera[0].file ?? null)
    data.append('extension_telefonica', fetchData?.extension_telefonica)
    data.append('patron_identificacion', fetchData?.patron_identificacion)
    data.append('locale', fetchData?.locale)
    data.append('_method', 'PUT')

    //ERROR DEL BACKEND: EL ESTATUS ESTÁ GENERANDO ERROR 500
    // data.append('estatus', fetchData?.estatus) 

    axios(getOne.pais(id), {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'multipart/form-data'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `Pais: ${data.nombre} actualizado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate('/paises')
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {selectFilter.erpPaises.length && fetchData
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                name="estatus"
                value={fetchData.estatus}
                labelText="Estatus"
                handleValue={handleInputChange}
                size="col-12"
              />

              <FormInput
                name="nombre"
                value={fetchData.nombre}
                labelText="Nombre"
                handleValue={handleInputChange}
                sizeDesk='col-md-6'
                size="col-12"
              />

              <FormInput
                name="locale"
                value={fetchData.locale}
                labelText="Locale"
                handleValue={handleInputChange}
                sizeDesk='col-md-6'
                size="col-12"
              />

              <SelectFilter
                name="cod_maxi"
                value={fetchData.cod_maxi?.split('-')[1] ?? ''}
                labelText="* Cod. Maximise"
                optionObj={selectFilter.erpPaises.map(({ cod_maxi, nombre }) => ({ id: cod_maxi ?? '*NULL*', name: `${nombre ?? 'Error'} | ${cod_maxi ?? ''}` }))}
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-4'
              />

              <FormInput
                name="extension_telefonica"
                value={fetchData.extension_telefonica}
                labelText="Extensión telefónica"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-4'
              />

              <FormInput
                name="patron_identificacion"
                value={fetchData.patron_identificacion}
                labelText="Patrón de identificación"
                handleValue={handleInputChange}
                sizeDesk='col-md-4'
                size="col-12"
              />

              <FileUploaderB handleValue={setImagen_bandera} value={imagen_bandera} labelText='Imagen bandera' />

              <div className="input-inset col-12">
                <label className="form-label">Imagen actual</label>
                <div className="form-outline border rounded-2 lista-detalle mb-2 d-flex justify-content-center p-2 w-fit">
                  <img src={BASE_URL + 'storage/' + fetchData.imagen_bandera} alt={`img-${fetchData.nombre}`} />
                </div>
              </div>

            </div>

            <Button type="submit" text="Actualizar" color="primary" icon="Circle" onClick={(e) => handleFetch(e)} />
          </CustomForm>

          : <CustomLoader />
        }

      </div>
    </div>
  );
};

export default Update;

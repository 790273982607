import axios from "axios"
import { fireToaster } from "../redux/actions/toaster"
import { useDispatch } from "react-redux"
import { toasterContent } from "../helpers/toasterContent"

const CONSTANT = {
  genericErrorFormat: (err) => {
    console.error(err)
    const { errores } = err?.response?.data ?? undefined
    let detalles = []
    Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

    return {
      title: `
        Operación fallida
        (${err.response.status} - ${err.response.statusText})
      `,
      html: `
        <b>Detalle: </b>
        ${detalles.map(error => `<br /><i>-${error}</i>`)}
      `,
      icon: 'error'
    }
  },
}

/**
 * options: 
 * @param {String | undefined} method  
 * @param {String | undefined} title  
 * @param {String | undefined} action  
 * @param {String | undefined} content_type
 * @returns 
 */

export const useCustomFetch = () => {
  const dispatch = useDispatch()

  const customFetch = async (url, options) => {
    const token = localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    
    const config = {
      method: options.method ?? 'GET',
      headers: {
        'Content-Type': options.content_type ?? 'application/json',
        'Authorization': token
      },
      data: options.data
    }

    return await axios(url, config)
      .then(res => {
        dispatch(fireToaster(toasterContent(options.action, 'success', options.title)))
        return res
      })
      .catch(err => {
        dispatch(fireToaster(CONSTANT.genericErrorFormat(err)))
        return err
      })
  }

  return [customFetch]
}

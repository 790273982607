import {
   FILTER_CLEAR,
   FILTER_FECHA_INICIO,
   FILTER_FECHA_FINAL,
   FILTER_PUNTO_ENTREGA,
   RESULTADOS_AGENDAMIENTOS_PUNTOS_ENTREGA,
} from "../redux-types";


const initialState = {
   filtros: {
      punto_entrega_id: undefined,
      desde: '',
      hasta: ''
   },
   resultados: null
}


export const agendamientosPuntoEntregaReducer = (state = initialState, action) => {
   switch (action.type) {
      case FILTER_PUNTO_ENTREGA:
         return {
            ...state, filtros: {
               ...state.filtros,
               punto_entrega_id: action.payload
            }
         };

      case FILTER_FECHA_INICIO:
         return {
            ...state, filtros: {
               ...state.filtros,
               desde: action.payload
            }
         };

      case FILTER_FECHA_FINAL:
         return {
            ...state, filtros: {
               ...state.filtros,
               hasta: action.payload
            }
         };

      case RESULTADOS_AGENDAMIENTOS_PUNTOS_ENTREGA:
         return {
            ...state,
            resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
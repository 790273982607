export const persistentFilters = (locationObj, filtersObj = null, queryParam = 'filtros_persistentes') => {
  const filtros_persistentes = locationObj?.search?.split(`${queryParam}=`)?.[1] ?? ''
  let json
  let obj
  let urlWithFilters = new Error('No hay filtros para aplicar a la url')

  if (filtersObj){
    urlWithFilters = `${locationObj.origin}${locationObj.pathname}?${queryParam}=${JSON.stringify(filtersObj)}`
  } 

  if (filtros_persistentes.length) {
    json = filtros_persistentes.replace(/%22/g, `"`).replace(/%20/g, ` `)
    obj = JSON.parse(json)
  }

  return { obj, json, urlWithFilters }
}
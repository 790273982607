import {
   FILTER_ADD_CATEGORIA,
   FILTER_REMOVE_CATEGORIA,
   FILTER_BUSQUEDA,
   FILTER_REMOVE_PUNTO_ENTREGA,
   FILTER_ADD_PUNTO_ENTREGA,
   RESULTADOS_PRODUCTOS_PUNTOS_ENTREGA,
   FILTER_CLEAR_PRODUCTOS_PUNTOS_ENTREGA,
   FILTER_PUBLICADO,
   FILTER_REMOVE_REGION,
   FILTER_ADD_REGION,
   FILTER_SKUS_PRODUCTO
} from "../redux-types";


export const categoriaFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_CATEGORIA, payload: data }
      : { type: FILTER_ADD_CATEGORIA, payload: data };

export const puntosEntregaFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_PUNTO_ENTREGA, payload: data }
      : { type: FILTER_ADD_PUNTO_ENTREGA, payload: data };

export const regionesFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_REGION, payload: data }
      : { type: FILTER_ADD_REGION, payload: data };

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const skusFilter = (data) => ({ type: FILTER_SKUS_PRODUCTO, payload: data })

export const publicadoFilter = (data) => ({ type: FILTER_PUBLICADO, payload: data })

export const resultadosProductosPuntosEntrega = (data) => ({ type: RESULTADOS_PRODUCTOS_PUNTOS_ENTREGA, payload: data })

export const clearFilterProductosPuntosEntrega = () => ({ type: FILTER_CLEAR_PRODUCTOS_PUNTOS_ENTREGA });

import React, { useEffect, useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { FILTER_ADD_PROVEEDOR_CATEGORIA } from '../../redux/redux-types'
import { fireToaster } from '../../redux/actions/toaster'
import { clearFilterProductosProveedores, proveedorCategoriaFilter, resultadosProductosProveedores } from '../../redux/actions/productosProveedores'


// Components
import SelectFilter from '../SelectFilter'
import FiltroAplicado from './FiltroAplicado'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import axios from 'axios'
import { idExtractor } from '../../helpers/idExtractor'
import { lastPosition } from '../../helpers/lastPosition'


const CONSTANT = {
  reduxStateSelector: 'productosProveedores',
  queryUrlGetAll: (results) => getAll.productos_proveedores + `/listar?limit=${results}`,
  unselectedObject: { id: '*NULL*', nombre: 'Error', categoria: 'N/A' },
  redirectUrl: '/productos-proveedores',
  title: 'Búsqueda de productos en proveedor',

  reduxClearFilters: clearFilterProductosProveedores,
  reduxSetResults: resultadosProductosProveedores,

  fetchProveedorCategoria: getAll.proveedores_categorias,

  selectFilterInitialState: {
    proveedoresCategoria: []
  }
}


const handleSelectFiltersData = async () => {
  const proveedoresCategoria = await axios(CONSTANT.fetchProveedorCategoria, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
  })
    .then(({ data }) => [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.unselectedObject]
    })

  return { proveedoresCategoria }
}


const dataFormatter = (filtrosObj) => {
  let data = {}

  Object.entries(filtrosObj).forEach(([key, value]) => {
    if (['proveedor_categoria_id'].includes(key) && value.length) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          [key]: value.map(str => idExtractor(str))
        }
      }
    }
  })

  return data
}


const ProductosProveedoresFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const dispatch = useDispatch()
  const { proveedor_categoria_id } = filtros

  const [isConsulting, setIsConsulting] = useState(false)


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // EFFECTO QUE HACE LA CONSULTA A LA BBDD
  useEffect(() => {
    axios(CONSTANT.queryUrlGetAll(showing_results), {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)));
    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [dispatch, showing_results])


  const handleFetchFilter = async (reset = false) => {
    let url = CONSTANT.queryUrlGetAll(showing_results)
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    }

    setIsConsulting(true)

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return axios(url, config)
        .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
        .catch(err => {
          console.error(err)
          dispatch(CONSTANT.reduxSetResults([]))
        })
        .finally(() => {
          setIsConsulting(false)
        })
    }

    config = { ...config, data: dataFormatter(filtros) }

    await axios(url, config)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  // MANEJADORES DE FILTRO - ADICIONALES
  const handleAddFilter = (e, filter) => {
    const { value } = e.target
    if (value.includes('*NULL*')) return

    switch (filter) {
      case FILTER_ADD_PROVEEDOR_CATEGORIA:
        dispatch(proveedorCategoriaFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <SelectFilter
            labelText="Categoría proveedor"
            name="proveedor_categoria_id"
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_PROVEEDOR_CATEGORIA)}
            optionObj={selectFilter?.proveedoresCategoria?.map(({ categoria, proveedor, id }) => ({ name: `${proveedor?.nombre ?? 'N/A'} | ${categoria?.nombre ?? 'Error'}`, id }))}
            size="col-12"
            sizeDesk="col-md-4"
            value={lastPosition(proveedor_categoria_id)}
          />
        </>

        {/* FILTROS APLICADOS - ADICIONALES */}
        <p className='mb-25 text-black'>Filtros aplicados</p>
        <div className='row'>
          <FiltroAplicado array={proveedor_categoria_id} func={proveedorCategoriaFilter} title={'Proveedores categorías'} />
        </div>

        <hr className='my-1' />

      </ListadoHeader>
    </div>
  )
}

export default ProductosProveedoresFiltros
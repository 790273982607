
// Components
import { useSelector } from "react-redux";
import GarantiasRespuestasFiltros from "../../../components/AccordionFilter/GarantiasRespuestas";
import DropdownMenuLink from "../../../components/Dropdown/Link";
import DropdownWrapper from "../../../components/Dropdown/Wrapper";
import GarantiasRespuestasTable from '../../../components/Tables/GarantiasRespuestasTable';

const CONSTANT = {
	title: 'Respuestas de garantía',
	path: 'garantias/respuestas',
	permissionsPath: 'garantias',
	create_btn: 'Crear respuesta de garantía'
}

const Listado = () => {
	const { user } = useSelector(state => state.auth)

	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>

				<DropdownWrapper text="Acciones" classes="ms-auto">
					{user.permisos[`${CONSTANT.permissionsPath}.crear`] &&
						<DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
					}
				</DropdownWrapper>
			</h3>

			<GarantiasRespuestasFiltros />

			<GarantiasRespuestasTable />
		</div>
	);
}

export default Listado;
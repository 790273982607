import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';


const Select = ({
   options, handleValue, labelText,
   name, placeholder, value,
   size, sizeDesk, sizeTablet,
   classes, margin
}) => {
   const [selectOptions, setSelectOptions] = useState(options);
   const [selectValue, setSelectValue] = useState("");

   const handleChange = (e) => {
      handleValue(e)
      setSelectValue(e.target.value)
   }

   useEffect(() => {
      setSelectOptions(options);
   }, [options, value]);

   return (
      <div className={`${margin ?? 'mb-2'} ${size} ${sizeDesk} ${sizeTablet}`}>

         {
            labelText &&
            <label className='form-label'>{labelText}</label>
         }

         <select
            className={"form-select form-control-lg " + (classes || '')}
            value={selectValue}
            onChange={(e) => handleChange(e)}
            name={name}
         >
            {
               placeholder && (
                  <option value={""} disabled>{placeholder}</option>
               )
            }

            {
               selectOptions.length &&
               selectOptions.map(({ name }) => (
                  <option
                     value={name}
                     key={`opt-${name}`}
                  >
                     {name}
                  </option>
               ))
            }
         </select>
      </div>
   );
};



Select.propTypes = {
   options: PropTypes.arrayOf(
      PropTypes.shape(
         {
            name: PropTypes.string.isRequired,
         }
      ).isRequired
   ).isRequired,
   placeholder: PropTypes.string,
   labelText: PropTypes.string,
   value: PropTypes.string.isRequired,
   handleValue: PropTypes.func.isRequired,
   size: PropTypes.string,
   sizeDesk: PropTypes.string,
   sizeTablet: PropTypes.string
};

Select.defaultProps = {
   options: [{ name: 'Ejemplo' }],
   labelText: "",
   size: "",
   sizeDesk: "",
   sizeTablet: "",
   value: "",
   handleValue: () => { }
};



export default Select;
import {
   FILTER_DESC_PRODUCTO,
   FILTER_FECHA_INICIO,
   FILTER_FECHA_FINAL,
   FILTER_CLEAR,
   FILTER_ADD_PROVEEDOR,
   FILTER_REMOVE_PROVEEDOR,
   FILTER_ADD_OC,
   FILTER_REMOVE_OC,
   FILTER_REMOVE_SKU,
   FILTER_ADD_SKU,
   FILTER_ADD_NOTA_VENTA,
   FILTER_REMOVE_NOTA_VENTA,
   FILTER_ADD_VENDEDOR,
   FILTER_REMOVE_VENDEDOR,
   FILTER_LIMITE,
   FILTER_ESTATUS,
   RESULTADOS_ORDENES_COMPRA,
} from "../redux-types";

const initialState = {
   filtros: {
      arr_proveedores: [],
      arr_vendedores: [],
      arr_oc: '',
      arr_nv: '',
      arr_sku: '',
      limite: '100',
      desc_prod: '',
      oc_creada_desde: '',
      oc_creada_hasta: '',
      estatus_oc: '',
   },
   resultados: null
}


export const ordenesCompraReducer = (state = initialState, action) => {
   let isListed

   switch (action.type) {
      case FILTER_ADD_PROVEEDOR:
         isListed = state.filtros.arr_proveedores.find(el => el === action.payload)

         return {
            ...state, filtros: {
               ...state.filtros,
               arr_proveedores: isListed
                  ? state.filtros.arr_proveedores
                  : [...state.filtros.arr_proveedores, action.payload]
            }
         };

      case FILTER_REMOVE_PROVEEDOR:
         return {
            ...state, filtros: {
               ...state.filtros,
               arr_proveedores: state.filtros.arr_proveedores.filter(el => el !== action.payload)
            }
         };

      // case FILTER_ADD_OC:
      //    isListed = state.filtros.arr_oc.find(el => el === action.payload)

      //    return {
      //       ...state, filtros: {
      //          ...state.filtros,
      //          arr_oc: isListed
      //             ? state.filtros.arr_oc
      //             : [...state.filtros.arr_oc, action.payload]
      //       }
      //    };

      // case FILTER_REMOVE_OC:
      //    return {
      //       ...state, filtros: {
      //          ...state.filtros,
      //          arr_oc: state.filtros.arr_oc.filter(el => el !== action.payload)
      //       }
      //    };

      // case FILTER_ADD_NOTA_VENTA:
      //    isListed = state.filtros.arr_nv.find(el => el === action.payload)

      //    return {
      //       ...state, filtros: {
      //          ...state.filtros,
      //          arr_nv: isListed
      //             ? state.filtros.arr_nv
      //             : [...state.filtros.arr_nv, action.payload]
      //       }
      //    };

      // case FILTER_REMOVE_NOTA_VENTA:
      //    return {
      //       ...state, filtros: {
      //          ...state.filtros,
      //          arr_nv: state.filtros.arr_nv.filter(el => el !== action.payload)
      //       }
      //    };

      // case FILTER_ADD_SKU:
      //    isListed = state.filtros.arr_sku.find(el => el === action.payload)

      //    return {
      //       ...state, filtros: {
      //          ...state.filtros,
      //          arr_sku: isListed
      //             ? state.filtros.arr_sku
      //             : [...state.filtros.arr_sku, action.payload]
      //       }
      //    };

      // case FILTER_REMOVE_SKU:
      //    return {
      //       ...state, filtros: {
      //          ...state.filtros,
      //          arr_sku: state.filtros.arr_sku.filter(el => el !== action.payload)
      //       }
      //    };

      case FILTER_ADD_VENDEDOR:
         isListed = state.filtros.arr_vendedores.find(el => el === action.payload)

         return {
            ...state, filtros: {
               ...state.filtros,
               arr_vendedores: isListed
                  ? state.filtros.arr_vendedores
                  : [...state.filtros.arr_vendedores, action.payload]
            }
         };

      case FILTER_REMOVE_VENDEDOR:
         return {
            ...state, filtros: {
               ...state.filtros,
               arr_vendedores: state.filtros.arr_vendedores.filter(el => el !== action.payload)
            }
         };

      // ESTOS SON MOMENTANEOS, SE DEBEN CAMBIAR A ARREGLOS
      case FILTER_ADD_NOTA_VENTA:
         return {
            ...state, filtros: {
               ...state.filtros,
               arr_nv: action.payload
            }
         };

      case FILTER_ADD_OC:
         return {
            ...state, filtros: {
               ...state.filtros,
               arr_oc: action.payload
            }
         };

      case FILTER_ADD_SKU:
         return {
            ...state, filtros: {
               ...state.filtros,
               arr_sku: action.payload
            }
         };
      // 

      case FILTER_LIMITE:
         return {
            ...state, filtros: {
               ...state.filtros,
               limite: action.payload
            }
         };


      case FILTER_DESC_PRODUCTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               desc_prod: action.payload
            }
         };

      case FILTER_FECHA_INICIO:
         return {
            ...state, filtros: {
               ...state.filtros,
               oc_creada_desde: action.payload
            }
         };

      case FILTER_FECHA_FINAL:
         return {
            ...state, filtros: {
               ...state.filtros,
               oc_creada_hasta: action.payload
            }
         };

      case FILTER_ESTATUS:
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus_oc: action.payload
            }
         };

      case RESULTADOS_ORDENES_COMPRA:
         return {
            ...state,
            resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
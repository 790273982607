import { Navigate, Route, Routes } from "react-router-dom";
import Listado from "../views/PuntosEntrega/Listado";
import Create from "../views/PuntosEntrega/Create";
import Bodegas from "../views/PuntosEntrega/Bodegas/Listado";
import Update from "../views/PuntosEntrega/Update";
import Detalle from "../views/PuntosEntrega/Detalle";
import { useSelector } from "react-redux";



const PuntosEntregaRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['puntos-entrega.crear'] &&
            <Route path='/create' element={<Create />} />
         }

         {user.permisos['puntos-entrega.editar'] &&
            <Route path='/update/:id' element={<Update />} />
         }

         {user.permisos['puntos-entrega.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         <Route path='/bodegas' element={<Bodegas />} />

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   );
}

export default PuntosEntregaRouter;
import { Navigate, Route, Routes } from "react-router-dom";
// import Construction from '../components/Construction';
import Detalle from "../views/Cotizaciones/Detalle";
import Create from "../views/Cotizaciones/Create";
import Index from "../views/Cotizaciones/Index";
import { useSelector } from "react-redux";

const CotizacionesRouter = () => {
  const { user } = useSelector(state => state.auth)

  return (
    <Routes>
      <Route path="/" element={<Index />} />

      {user.permisos['cotizaciones.crear'] &&
        <Route path="/create" element={<Create />} />
      }

      {user.permisos['cotizaciones.detalle'] &&
        <Route path="/detalle/:id" element={<Detalle />} />
      }
      {/* NO IMPLEMENTADOS */}
      {/* <Route path="/stock" element={<Index view={<Construction />} />} /> */}
      {/* <Route path="/puntos-entrega" element={<Index view={<Construction />} />} /> */}
      {/* <Route path="/metodos-pago" element={<Index view={<Construction />} />} /> */}
      {/* <Route path="/imagenes" element={<Index view={<Construction />} />} /> */}
      {/* <Route path="/etiquetas" element={<Index view={<Construction />} />} /> */}
      {/* <Route path="/ofertas" element={<Index view={<Construction />} />} /> */}

      <Route path='*' element={<Navigate to='/home' replace />} />
    </Routes>
  );
};

export default CotizacionesRouter;

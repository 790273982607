import React from "react"
import AccordionDetailData from "../AccordionDetailData"


export const TablaRecursiva = ({ data }) => {
  const id = new Date()

  if (!data) return <span>N/A</span>

  if (typeof data === 'string' || typeof data === 'number') return <span key={`${id}-${data}`}>{String(data)}</span>

  if (Array.isArray(data)) return (
    <div className='d-flex flex-column gap-50'>{data.map((el, i, arr) => (
      <React.Fragment key={`arr-${id}`}>
        <TablaRecursiva data={el} /> {i === arr.length - 1 || <hr className='my-0' style={{ color: 'rgb(161 161 161)', height: '2px' }} />}
      </React.Fragment>
    ))}
    </div>
  )

  if (!Array.isArray(data) && typeof data === 'object') return (
    <div className='d-flex flex-column'>
      {Object.entries(data).map(([k, v], i, arr) => (
        <AccordionDetailData key={`data-${k}-${id}`} dataKey={k.toUpperCase()} value={<TablaRecursiva data={v} />} last={i === arr.length - 1} />
      ))}
    </div>
  )
}
import { Route, Routes } from "react-router-dom"
import Form from "../../views/ProductoPropioEnProveedor/Form"
import Listado from "../../views/ProductoPropioEnProveedor/Listado"
import Detalle from "../../views/ProductoPropioEnProveedor/Detalle"


const ProductoPropioEnProveedorRouter = () => {
   return (
      <Routes>
         <Route path='/' element={<Listado />} />
         <Route path='/create' element={<Form />} />
         {/* <Route path='/update/:id' element={<Form />} /> */}
         <Route path='/detalle/:id' element={<Detalle />} />
      </Routes>
   )
}

export default ProductoPropioEnProveedorRouter
import axios from 'axios';

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from "../../redux/actions/toaster";


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Switch from "../Switch";
import Table from "../Table";


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { resultadosCintaInformativa } from '../../redux/actions/cintaInformativa';
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter';


const CONSTANT = {
   pathUrl: '/template/cinta-informativa',
   permissionsPath: 'cinta-informativa',
   queryUrlGetOne: getOne.cinta_informativa,
   title: 'Cintas informativas',
   reduxSetResults: resultadosCintaInformativa,
}



const CintaInformativaTable = () => {
   const { resultados: response, filtros } = useSelector(state => state.cintaInformativa)
   const { showing_results } = useSelector(state => state.listedResults)
   const dispatch = useDispatch()
   const { user } = useSelector(state => state.auth)


   const handleUrl = (url) => {
      dispatch(CONSTANT.reduxSetResults(null))
      axios(url + `&limit=${showing_results}` + queryParamsFormatter({ busqueda: filtros.busqueda }),
         {
            headers: {
               'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
         })
         .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
         .catch(err => console.error(err))
   }


   const handleFetch = async (objData, url, target) => {
      let query = CONSTANT.queryUrlGetOne(objData.id)
      let config = {
         headers: {
            'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
         },
      }
      
      if (target === 'estatus') {
         config = {
            ...config,
            method: 'PUT',
            data: {
               estatus: !objData?.estatus
            }
         }
      }

      if (target === 'delete') {
         if (!window.confirm('¿Está seguro que desea eliminar el registro?')) return

         config = { ...config, method: 'DELETE' }
      }

      await axios(query, config)
         .then(({ data }) => {
            const toasterContent = {
               title: 'Operación realizada',
               text: `Cinta informativa: ${data?.texto} - estatus actualizado con éxito`,
               icon: 'success'
            }

            dispatch(fireToaster(toasterContent))
         })
         .catch(err => {
            console.error(err);
            const { errores } = err.response.data
            let detalles = []
            Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

            const toasterContent = {
               title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
               html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
               icon: 'error'
            }
            dispatch(fireToaster(toasterContent))
         })

      dispatch(CONSTANT.reduxSetResults(null))

      axios(url,
         {
            headers: {
               'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
            }
         })
         .then(({ data }) => {
            dispatch(CONSTANT.reduxSetResults(data))
         })
         .catch(err => console.error(err))
   }


   return (
      <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
         {!response && <CustomLoader blocking={'partial'} />}

         <Table response={response} handlePagination={handleUrl}>
            <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
               <tr role="row">
                  <th>ID</th>
                  <th>Texto</th>
                  <th>Frontend</th>
                  <th>Componente</th>
                  <th>Fecha-Hora Inicio/Final</th>
                  <th>Creación</th>
                  <th>Estatus</th>
                  <th className="w-max">Acciones</th>
               </tr>
            </thead>
            <tbody>
               {response?.data.length
                  ? response.data?.map((data) => {
                     return (
                        <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                           <td>{data?.id}</td>
                           <td>{data?.texto ?? "N/A"}</td>
                           <td>{data?.frontend?.nombre ?? 'chileneumáticos'}</td>
                           <td>{data?.componente_id ?? "N/A"}</td>
                           <td>
                              <span>
                                 {data?.fecha_hora_inicio ?? "N/A"} / {data?.fecha_hora_final ?? "N/A"}
                              </span>
                           </td>
                           <td>{data?.created_at.split("T")[0] || 'N/A'}</td>
                           <td>
                              <Switch value={data.estatus ? 1 : 0} labelText={""} handleValue={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`, 'estatus')} />
                           </td>
                           <td>
                              <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                                 {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                                    <DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />

                                 }
                                 {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
                                    <DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
                                 }

                                 {user.permisos[`${CONSTANT.permissionsPath}.borrar`] &&
                                    <DropdownMenuLink text='Borrar' icon="X" onClick={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}` + queryParamsFormatter({ busqueda: filtros.busqueda }), 'delete')} />
                                 }
                              </DropdownWrapper>
                           </td>
                        </tr>
                     )
                  })
                  : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
               }
            </tbody>
         </Table>
      </AccordionTable >
   )
}

export default CintaInformativaTable
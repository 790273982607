import { useEffect, useState } from "react";
import axios from "axios";

// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import SelectFilter from "../../components/SelectFilter";
import FileUploaderB from "../../components/FileUploader_B";
import CustomLoader from "../../components/CustomLoader";
import TextareaInput from "../../components/TextareaInput";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { getAll } from "../../endpoints/getAll";
import { getOne } from "../../endpoints/getOne";
import { BASE_URL } from "../../endpoints";


// Helpers
import { hourFormatter } from "../../helpers/hourFormatter";


const hF = (s) => s.split('T')[1].split(':').map((t, i) => i < 2 ? t : null).filter(el => el !== null).join(':')


const CONSTANT = {
  title: 'Etiquetas',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitle: 'Editar etiqueta',
  redirectUrl: '/etiquetas',
  queryUrl: getOne.etiquetas,
  queryGetAllOfertas: getAll.ofertas,
  selectFilterInitialState: {
    ofertas: [],
  }
}


const handleSelectFiltersData = async () => {
  const ofertas = await axios(CONSTANT.queryGetAllOfertas,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data.ofertas] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { ofertas }
}


const Update = () => {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { id } = useParams()

  const [imagen, setImagen] = useState([])
  const [fetchData, setFetchData] = useState(null)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [isEventTag, setIsEventTag] = useState(false);


  // MANEJADOR DE LOS INPUTS
  const dataFiller = (e) => {
    const { value, name } = e.target

    setFetchData({
      ...fetchData,
      [name]: value
    })
  }


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // EFFECTO QUE CONSULTA LA DATA DEL REGISTRO EN LA DDBB
  useEffect(() => {
    axios(CONSTANT.queryUrl(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        const res = data?.etiqueta
        const horaInicio = hF(res?.fecha_hora_inicio)
        const horaFinal = hF(res?.fecha_hora_final)

        setFetchData(fetchData => ({
          ...fetchData,
          nombre: `${res?.nombre ?? ''}`,
          oferta_id: `${res?.oferta?.id ?? res?.neu_oferta?.id ?? '*NULL*'}-${res?.oferta?.nombre ?? res?.neu_oferta?.nombre ?? 'Sin seleccion'}`,
          prioridad: `${res?.prioridad ?? ''}`,
          descripcion: `${res?.descripcion ?? ''}`,
          fecha_inicio: `${res?.fecha_hora_inicio.split('T')[0]}`,
          hora_inicio: horaInicio,
          fecha_final: `${res?.fecha_hora_final.split('T')[0]}`,
          hora_final: horaFinal,
          imagen: `${BASE_URL}storage/${res?.imagen}`,
          estatus: res?.estatus ? 1 : 0,
          es_evento: res?.evento ? true : false
        }))

        setIsEventTag(res?.evento ? true : false)
      })
      .catch(err => console.error(err))

    return () => {
      setFetchData(null)
      setIsEventTag(false)
    }
  }, [id])


  // FUNCIÓN MANEJADORA QUE EDITA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    if (!fetchData.prioridad) return dispatch(fireToaster({ title: 'La prioridad es obligatoria', icon: 'info' }))
    if (!fetchData.nombre.length) return dispatch(fireToaster({ title: 'El nombre es obligatorio', icon: 'info' }))

    let requestData = new FormData()
    requestData.append('nombre', fetchData?.nombre)
    fetchData?.descripcion.length && requestData.append('descripcion', fetchData?.descripcion)
    requestData.append('prioridad', fetchData?.prioridad)
    imagen.length > 0 && requestData.append('imagen', imagen[0]?.file)
    requestData.append('oferta_id', fetchData.oferta_id.includes('*NULL*') ? '' : fetchData?.oferta_id?.split('-')[0])
    // En caso de que no sea una etiqueta de evento
    if (!isEventTag) {
      requestData.append('fecha_hora_inicio', `${fetchData?.fecha_inicio} ${hourFormatter(fetchData?.hora_inicio)}`)
      requestData.append('fecha_hora_final', `${fetchData?.fecha_final} ${hourFormatter(fetchData?.hora_final)}`)
      requestData.append('estatus', Number(fetchData?.estatus ? 1 : 0))
    }
    requestData.append('_method', 'PUT')

    axios(CONSTANT.queryUrl(id), {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'multipart/form-data',
        _method: 'PUT'
      },
      data: requestData
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} actualizada con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>
        {selectFilter.ofertas.length && fetchData
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              {
                !isEventTag && (
                  <>
                    <Switch
                      name="estatus"
                      value={fetchData?.estatus}
                      labelText="Estatus"
                      handleValue={dataFiller}
                      size="col-12"
                    />
                  
                    <SelectFilter
                      labelText="Oferta"
                      name="oferta_id"
                      value={fetchData?.oferta_id?.split('-')[1] ?? ''}
                      handleValue={dataFiller}
                      optionObj={selectFilter.ofertas?.map(({ nombre, id }) => ({ name: nombre, id }))}
                      size="col-12"
                      sizeDesk="col-md-5"
                    />
                  </>
                )
              }

              <FormInput
                labelText="* Nombre"
                name='nombre'
                handleValue={dataFiller}
                value={fetchData?.nombre ?? ''}
                size="col-12"
                sizeDesk="col-md-5"
              />

              <FormInput
                labelText="* Prioridad"
                type="number"
                name='prioridad'
                handleValue={dataFiller}
                value={fetchData?.prioridad ?? undefined}
                size="col-12"
                sizeDesk="col-md-2"
              />

              <TextareaInput
                labelText="Descripción"
                name='descripcion'
                placeholder='Descripción...'
                handleValue={dataFiller}
                value={fetchData?.descripcion ?? ''}
                size="col-12"
                sizeDesk="col-md-12"
                rows={2}
              />

              {
                !isEventTag && (
                  <>
                    <FormInput
                      labelText="* Fecha inicio"
                      type="date"
                      handleValue={dataFiller}
                      max={fetchData?.fecha_final}
                      name='fecha_inicio'
                      value={fetchData?.fecha_inicio ?? ''}
                      size="col-12"
                      sizeDesk="col-md-6"
                    />
      
                    <FormInput
                      labelText="* Hora inicio"
                      type="time"
                      name='hora_inicio'
                      handleValue={dataFiller}
                      value={fetchData?.hora_inicio ?? ''}
                      size="col-12"
                      sizeDesk="col-md-6"
                    />
      
                    <FormInput
                      labelText="* Fecha final"
                      type="date"
                      min={fetchData?.fecha_inicio ?? ''}
                      name='fecha_final'
                      handleValue={dataFiller}
                      value={fetchData?.fecha_final ?? ''}
                      size="col-12"
                      sizeDesk="col-md-6"
                    />
      
                    <FormInput
                      labelText="* Hora final"
                      type="time"
                      name='hora_final'
                      handleValue={dataFiller}
                      value={fetchData?.hora_final ?? ''}
                      size="col-12"
                      sizeDesk="col-md-6"
                    />
                  </>
                )
              }

              <div className="input-inset col-12">
                <label className="form-label">Imagen actual</label>
                <div className="form-outline border rounded-2 lista-detalle mb-2 d-flex justify-content-center p-2 w-fit">
                  {fetchData.imagen &&
                    <img src={fetchData?.imagen} alt={`img-${fetchData?.nombre}`} />
                  }
                </div>
              </div>

              <FileUploaderB labelText='Imagen nueva' handleValue={setImagen} value={imagen} />
            </div>

            <Button
              type="submit"
              text="Actualizar"
              color="primary"
              icon="Circle"
              onClick={handleFetch}
            />
          </CustomForm>

          : <CustomLoader />
        }
      </div>
    </div>
  );
};

export default Update;

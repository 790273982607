import React, { useState } from 'react'
import FileUploaderB from '../../../FileUploader_B'
import { updateExcel } from '../../../../services/productos/puntosEntrega/updateExcel.service'
import Button from '../../../Button'
import Modal from '../../../Modal'
import { fireToaster } from '../../../../redux/actions/toaster'
import { useDispatch } from 'react-redux'

const ActualizaciónMasivaExcel = ({ show, handleShow }) => {
  const [excelFile, setExcelFile] = useState([])
  const dispatch = useDispatch()

  const handleSubmit = async (e) => {
    e.preventDefault()

    await updateExcel(excelFile).then(res => {
      dispatch(fireToaster(res))

      if (res.icon === 'success') {
        handleShow(false)
      }
    })
  }

  return (
    <Modal display={show} handleValue={handleShow}>
      <div className='m-2'>
        <h3 className='p-50 rounded-2 m-0'>Actualizar productos en puntos de entrega</h3>
        <hr className='mt-0 mb-1' />

        <div className='col-12 mb-50'>
          <FileUploaderB value={excelFile} handleValue={setExcelFile} />
        </div>

        <div className='d-flex'>
          <Button text='Subir archivo' className='mx-auto' color='success' onClick={handleSubmit} />
        </div>
      </div>
    </Modal>
  )
}

export default ActualizaciónMasivaExcel
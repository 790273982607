import React from 'react'
import Pagination from './PaginationR'

const Table = ({ children, response, handlePagination, pb = true, ...props }) => {
   return (
      <div className={`card-datatable table-responsive pt-0 custom-scrollbar flex-shrink-0`} {...props}>
         <table className="diff position-relative user-list-table table no-footer dtr-column z-10 mb-0" >
            {children}
         </table>

         {pb &&
            <div className='py-2'></div>
         }
         
         {response?.data?.length > 0 && handlePagination &&
            <div className="mx-auto my-1 w-fit mt-3 h-fit">
               <Pagination
                  firstPageUrl={response.first_page_url}
                  lastPageUrl={response.last_page_url}
                  links={response.links}
                  currentPage={response.current_page}
                  handleUrl={handlePagination}
               />
            </div>
         }
      </div>
   )
}

export default Table
import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_UNIDADES,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosUnidades = (data) => ({ type: RESULTADOS_UNIDADES, payload: data })

export const clearFilterUnidades = () => ({ type: FILTER_CLEAR });


// Components
import ParametrosRecargosFiltros from "../../components/AccordionFilter/ParametrosRecargo";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import ParametrosRecargosTable from "../../components/Tables/ParametrosRecargosTable";

const CONSTANT = {
	title: 'Parámetros de recargos',
	path: 'parametros-recargos',
	create_btn: 'Crear parámetros'
}

const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/generales/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<ParametrosRecargosFiltros />

			<ParametrosRecargosTable />
		</div>
	);
}

export default Listado;
import axios from 'axios'
import { useEffect, useState } from 'react'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import Switch from '../../components/Switch'
import CustomLoader from '../../components/CustomLoader'


// Router Dom
import { useNavigate, useParams } from 'react-router-dom'


// Endpoints
import { getOne } from '../../endpoints/getOne'
import TablaDetalle from '../../components/Historico/TablaDetalle'
import { ModalHistorico } from '../../components/Historico/ModalHistorico'
import { historicoData } from '../../helpers/historicoData'


const CONSTANT = {
  queryUrlGetOne: getOne.productos_propios_proveedor,
  redirectUpdate: (id) => `/proveedores/productos-propios-proveedor/update/${id}`,
  redirectListado: '/proveedores/productos-propios-proveedor',
  title: 'Producto propio en proveedor',
  modelo: 'App\\Models\\NeuProductoPropioProveedor',
}


const Detalle = () => {
  const [data, setData] = useState(null)
  const { id } = useParams()
  const dispatch = useDispatch()
  const redirect = useNavigate()
  const [historico, setHistorico] = useState(null)
  const [modal, setModal] = useState({ id: null, display: false, data: null })

  // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
  useEffect(() => {
    axios(CONSTANT.queryUrlGetOne(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        setData(data)
        historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
      })
      .catch(err => console.error(err))

    return () => {
      setData(null)
    }
  }, [id])


  // FUNCIÓN MANEJADORA PARA EDICION RAPIDA
  const handleFetch = async (objData, target) => {
    let config

    if (target === 'estatus') {
      let newEstatus = objData.estatus ? 0 : 1
      config = {
        method: 'POST',
        data: {
          [target]: newEstatus,
          _method: 'PUT'
        }
      }
    }

    if (target === 'delete') {
      config = {
        method: 'DELETE',
      }
    }

    config = {
      ...config,
      headers: {
        ...config.headers,
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    }

    await axios(CONSTANT.queryUrlGetOne(objData.id), config)
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: registro actualizado con éxito`,
          icon: 'success'
        }

        if (target === 'delete') {
          redirect(CONSTANT.redirectListado)
        }

        dispatch(fireToaster(toasterContent))
        setData(null)

        if (target === 'delete') return redirect(CONSTANT.redirectListado)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
      })
      .finally(async () => {
        await axios(CONSTANT.queryUrlGetOne(id),
          {
            headers: {
              'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
          })
          .then(({ data }) => {
            setData(data)
            historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
          })
          .catch(err => {
            console.error(err)
            const toasterContent = {
              title: 'Registro no encontrado',
              icon: 'warning'
            }

            dispatch(fireToaster(toasterContent))
            redirect(CONSTANT.redirectListado)
          })
      })
  }

  // FUNCION MANEJADORA DE LA DATA DEL MODAL
  const handleModal = (data) => {
    const { id, anterior, nuevo } = data

    setModal({
      display: true,
      id,
      data: {
        anterior,
        nuevo
      }
    })
  }

  return (
    <div className='row'>

      <ModalHistorico modal={modal} fnHandleModal={setModal} />

      <div className='col-12'>
        <div className='col mb-50'>
          <div className='d-flex gap-1'>
            <div className='col'>
              <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
            </div>
            {/* <Button text='Editar' icon='Settings' color='success' isLink url={CONSTANT.redirectUpdate(id)} /> */}
            <Button text='Borrar' icon='Trash' color='danger' onClick={() => handleFetch(data, 'delete')} />
          </div>
        </div>


        {data
          ? <div className='row'>
            {/* DATOS GENERALES */}
            <Accordion
              title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
              classes='col-12 col-md-6 mb-2'
              isOpen
            >
              <AccordionDetailData dataKey={'ID'} value={data?.id} />
              <AccordionDetailData dataKey={'Proveedor'} value={data?.producto_proveedor?.descripcion ?? 'N/A'} />
              <AccordionDetailData dataKey={'SKU proveedor'} value={data?.producto_proveedor?.sku_proveedor ?? 'N/A'} />
              <AccordionDetailData dataKey={'Precio al dólar'} value={data?.producto_proveedor?.precio_al_dolar ?? 'N/A'} />
              <AccordionDetailData dataKey={'Precio lista'} value={data?.producto_proveedor?.precio_lista ?? 'N/A'} />
              <AccordionDetailData dataKey={'Precio mínimo venta'} value={data?.producto_proveedor?.precio_minimo_venta ?? 'N/A'} />
              <AccordionDetailData dataKey={'Precio sugerido'} value={data?.producto_proveedor?.precio_sugerido ?? 'N/A'} />
              <AccordionDetailData dataKey={'Precio producto'} value={data?.producto_proveedor?.precio_producto ?? 'N/A'} />
              <AccordionDetailData dataKey={'Stock producto'} value={data?.producto_proveedor?.stock_producto ?? 'N/A'} />

              <AccordionDetailData dataKey={'Estatus'} value={
                <Switch
                  value={data?.estatus}
                  labelText={""}
                  margin='my-0'
                  handleValue={() => handleFetch(data, 'estatus')}
                />} />
              <AccordionDetailData dataKey={'Creación'} value={data?.created_at?.split('T')[0] ?? 'N/A'} />
              <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at?.split('T')[0] ?? 'N/A'} last />
            </Accordion>

            {/* DATOS PRODUCTO PROVEEDORES */}
            <Accordion
              title={<h3 className='m-0 w-100 text-center text-white'>Producto</h3>}
              classes='col-12 col-md-6 mb-2'
              isOpen
            >
              <AccordionDetailData dataKey={'Producto'} value={<a href={`/productos/detalle/${data?.producto?.id}`} rel='noreferrer' target='_blank'>{data?.producto?.nombre ?? 'N/A'}</a>} />
              <AccordionDetailData dataKey={'SKU'} value={data?.producto?.sku ?? 'N/A'} />
              <AccordionDetailData dataKey={'SKU a mostrar'} value={data?.producto?.sku_a_mostrar ?? 'N/A'} />
              <AccordionDetailData dataKey={'Precio base'} value={data?.producto?.precio_base ?? 'N/A'} />
              <AccordionDetailData dataKey={'Creación'} value={data?.producto?.created_at?.split('T')[0] ?? 'N/A'} />
              <AccordionDetailData dataKey={'Modicación'} value={data?.producto?.updated_at?.split('T')[0] ?? 'N/A'} last />
            </Accordion>

            <TablaDetalle historicoData={historico} fnHandleModal={handleModal} />

          </div>

          : <CustomLoader />
        }

      </div>
    </div>
  )
}

export default Detalle

// Components
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import EventosFiltros from "../../components/AccordionFilter/Eventos";
import EventosTable from "../../components/Tables/EventosTable";


const CONSTANT = {
   title: 'Eventos',
   path: 'eventos',
   create_btn: 'Crear evento'
}


const Listado = () => {
   return (
      <div className="row">
         <h3 className="mb-1 d-flex justify-content-between">
            <p><b>{CONSTANT.title}</b> - Listado</p>
            <DropdownWrapper text="Acciones" classes="ms-auto">
               <DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
            </DropdownWrapper>
         </h3>

         <EventosFiltros />

         <EventosTable />
      </div>
   );
}

export default Listado;
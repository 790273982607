// Redux
import { useDispatch, useSelector } from 'react-redux'


// Components
import CustomLoader from "../CustomLoader";
import Table from "../Table";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { getAllImagenesProductos } from '../../services/productos/imagenes/getAll.service';
import { clearFilterProductosImagenes, resultadosProductosImagenes } from '../../redux/actions/productosImagenes';
import { BASE_URL } from '../../endpoints';


const CONSTANT = {
	permissionsPath: 'productos',
	reduxSelector: 'productosImagenes',
	pathUrl: '/productos/imagenes',
	title: 'Imágenes',
	reduxClearFilters: clearFilterProductosImagenes,
	reduxSetResults: resultadosProductosImagenes,
}


export const ProductosImagenesTable = () => {
	const { resultados: response } = useSelector(state => state[CONSTANT.reduxSelector])
	const { showing_results } = useSelector(state => state.listedResults)
	const dispatch = useDispatch()


	// FUNCION MANEJADORA DE PAGINACIÓN
	const handleUrl = (url) => {
		dispatch(CONSTANT.reduxSetResults(null))

		getAllImagenesProductos(showing_results, url).then(res => {
			if (res.status >= 400) {
				// dispatch(fireToaster(toasterErrorFormatter({ errors: res.data, response: res })))
				dispatch(CONSTANT.reduxSetResults([]))
				return
			}

			dispatch(CONSTANT.reduxSetResults(res))
		})
	}

	return (
		<>
			<AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
				{!response && <CustomLoader blocking={'partial'} />}

				<Table response={response} handlePagination={handleUrl}>
					<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
						<tr role="row">
							<th>ID</th>
							<th>Nombre</th>
							<th>Imagen</th>
							<th>Creación</th>
						</tr>
					</thead>

					<tbody>
						{response?.data?.length
							? response.data?.map((data) => {
								const imagen = `${BASE_URL}storage/${data?.ruta}` ?? '/placeholder.png'

								return (
									<tr className="odd" key={`user-${data.id}-${data.nombre}`}>
										<td className='col-1'>{data?.id}</td>
										<td>{data?.nombre ?? "N/A"}</td>
										<td><img className="height-listado" src={imagen} alt={data.nombre} /></td>
										<td className='col-2'>{data?.created_at?.split("T")[0] || 'N/A'}</td>
									</tr>
								)
							})
							: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
						}
					</tbody>
				</Table>
			</AccordionTable >
		</>
	)
}
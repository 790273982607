import { Route, Routes } from "react-router-dom"
import Main from "../Main";
import PersistentLogin from "../PersistentLogin";
import AuthRouter from "./AuthRouter";


const AppRouter = () => {

  return (
    <PersistentLogin>
      <Routes>
        <Route path='/auth/*' element={<AuthRouter />} />
        <Route path='*' element={<Main />} />
      </Routes>
    </PersistentLogin>
  )
}

export default AppRouter;
import axios from "axios"
import { BASE_URL, PATH_URL } from "../../endpoints"

const URL = (id) => BASE_URL + PATH_URL + `/pedidos/estatus/${id}`

export const updateEstatusPedido = async (data) => {
  try {
    if (!data) throw Error('Missing all data')
    if (!data?.id) throw Error('Missing data id')
    if (!data?.estatus_pedido_id) throw Error('Missing estatus_pedido_id')

    const { estatus_pedido_id } = data
    const config = {
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      data: {
        estatus_pedido_id
      }
    }

    return await axios(URL(data.id), config)
      .then(res => res)
      .catch(err => { throw err })
  } catch (err) {
    return { ok: false, msg: err }
  }
}
import Accordion from "../Accordion"
import AccordionDetailData from "../AccordionDetailData"
import Modal from "../Modal"
import { TablaRecursiva } from "./TablaRecursiva"

export const ModalHistorico = ({ modal, fnHandleModal }) => {
  return (
    <Modal sizeDesk='col-md-12' display={modal.display} handleValue={() => fnHandleModal({ ...modal, display: false })}>
      <h4>Detalle de historico de cambios #{modal?.id}</h4>

      <div className='row'>
        <div className='col-12'>

        </div>
      </div>
      {/* TABLA NUEVO */}
      <div className='col-12 mb-2'>
        {modal?.data?.nuevo &&
          <Accordion
            title={<h3 className='m-0 w-100 text-center text-white'>Nuevo</h3>}
            isOpen
          >
            {Object.entries(modal.data.nuevo).map(([k, v], i, arr) => (
              <AccordionDetailData key={`data-nuevo-${i}`} dataKey={k.toUpperCase()} value={<TablaRecursiva data={v} />} last={i === arr.length - 1} />
            ))}
          </Accordion>
        }
      </div>

      {/* TABLA ANTERIOR */}
      <div className='col-12 mb-2'>
        {modal?.data?.anterior &&
          <Accordion
            title={<h3 className='m-0 w-100 text-center text-white'>Anterior</h3>}
            isOpen
          >
            {Object.entries(modal?.data?.anterior).map(([k, v], i, arr) => (
              <AccordionDetailData key={`data-anterior-${i}`} dataKey={k.toUpperCase()} value={<TablaRecursiva data={v} />} last={i === arr.length - 1} />
            ))}
          </Accordion>
        }
      </div>
    </Modal>
  )
}

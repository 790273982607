import { useEffect, useState } from "react";
import axios from "axios";

// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import SelectFilter from "../../components/SelectFilter";
import FileUploaderB from "../../components/FileUploader_B";
import CustomLoader from "../../components/CustomLoader";
import TextareaInput from "../../components/TextareaInput";


// React-router
import { useNavigate } from "react-router-dom";


// Enpoints
import { getAll } from "../../endpoints/getAll";
import { create } from "../../endpoints/create";


// Helpers
import { hourFormatter } from "../../helpers/hourFormatter";


const CONSTANT = {
  title: 'Ofertas',
  componentTitle: 'Crear oferta',
  redirectUrl: '/ofertas',
  queryUrl: create.ofertas,
  queryGetAllCriteriosComparacion: getAll.criterios_comparacion
}


const initialformState = {
  nombre: undefined,
  descripcion: undefined,
  criterio_comparacion_id: undefined,
  cantidad_limite: undefined,
  cantidad_venta_caducidad: undefined,
  porcentaje_descuento: undefined,
  monto_descuento: undefined,
  fecha_hora_inicio: undefined,
  fecha_hora_final: undefined,
  estatus: 1,
};


const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [criteriosComparacionSelect, setCriteriosComparacionSelect] = useState(null)
  const [data, setData] = useState(initialformState);


  // MANEJADOR DE LOS INPUTS
  const handleInputChange = (e) => {
    const { value, name } = e.target

    setData({
      ...data,
      [name]: value
    })

  }


  // MANEJADOR PARA RELLENAR LA DATA DE LOS SELECTFILTERS
  const handleSelects = () => {
    axios(CONSTANT.queryGetAllCriteriosComparacion,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => setCriteriosComparacionSelect(data))
      .catch(err => console.error(err))
  }


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelects()
  }, [])


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    if (!data?.criterio_comparacion_id) return dispatch(fireToaster({ title: 'Falta el criterio de comparación', icon: 'info' }))
    if (data?.cantidad_limite?.includes('.')) return dispatch(fireToaster({ title: 'La cantidad límite debe ser un entero', icon: 'info' }))
    if (data?.cantidad_venta_caducidad?.includes('.')) return dispatch(fireToaster({ title: 'La cantidad venta/caducidad debe ser un entero', icon: 'info' }))
    if (!data?.fecha_inicio || !data?.hora_inicio) return dispatch(fireToaster({ title: 'Falta fecha/hora de inicio', icon: 'info' }))
    if (!data?.fecha_final || !data?.hora_final) return dispatch(fireToaster({ title: 'Falta fecha/hora final', icon: 'info' }))

    let requestData = {
      nombre: data?.nombre,
      descripcion: data?.descripcion,

      criterio_comparacion_id: Number(data?.criterio_comparacion_id?.split('-')[0]),
      cantidad_limite: Number(data?.cantidad_limite) ?? null,
      cantidad_venta_caducidad: Number(data?.cantidad_venta_caducidad) ?? null,

      fecha_hora_inicio: `${data?.fecha_inicio} ${hourFormatter(data?.hora_inicio)}`,
      fecha_hora_final: `${data?.fecha_final} ${hourFormatter(data?.hora_final)}`,
      estatus: Number(data?.estatus)
    }

    if (data?.porcentaje_descuento) requestData = { ...requestData, porcentaje_descuento: Number(data?.porcentaje_descuento) }
    if (data?.monto_descuento) requestData = { ...requestData, monto_descuento: Number(data?.monto_descuento) }

    // return console.log(requestData);

    axios(CONSTANT.queryUrl,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: requestData
      })
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: registro creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {criteriosComparacionSelect
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                name="estatus"
                value={data?.estatus}
                labelText="Estatus"
                handleValue={handleInputChange}
                size="col-12"
              />

              <SelectFilter
                labelText="* Criterio de comparación"
                name="criterio_comparacion_id"
                value={data?.criterio_comparacion_id?.split('-')[1]}
                handleValue={handleInputChange}
                optionObj={criteriosComparacionSelect?.map(({ nombre, id }) => ({ name: nombre, id }))}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Nombre"
                name='nombre'
                handleValue={handleInputChange}
                value={data?.nombre}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="Cantidad límite"
                type="number"
                name='cantidad_limite'
                handleValue={handleInputChange}
                value={data?.cantidad_limite}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="Cantidad venta/caducidad"
                type="number"
                name='cantidad_venta_caducidad'
                handleValue={handleInputChange}
                value={data?.cantidad_venta_caducidad}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                isDisabled={data?.monto_descuento}
                labelText="Porcentaje descuento"
                type="number"
                name='porcentaje_descuento'
                handleValue={handleInputChange}
                value={data?.monto_descuento ? undefined : data?.porcentaje_descuento}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                isDisabled={data?.porcentaje_descuento}
                labelText="Monto descuento"
                type="number"
                name='monto_descuento'
                handleValue={handleInputChange}
                value={data?.porcentaje_descuento ? undefined : data?.monto_descuento}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <TextareaInput
                labelText="Descripción"
                name='descripcion'
                handleValue={handleInputChange}
                value={data?.descripcion}
                size="col-12"
                sizeDesk="col-md-12"
                rows={2}
              />

              <FormInput
                labelText="* Fecha inicio"
                type="date"
                handleValue={handleInputChange}
                max={data?.fecha_final}
                name='fecha_inicio'
                value={data?.fecha_inicio}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Hora inicio"
                type="time"
                name='hora_inicio'
                handleValue={handleInputChange}
                value={data?.hora_inicio}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Fecha final"
                type="date"
                min={data?.fecha_inicio}
                name='fecha_final'
                handleValue={handleInputChange}
                value={data?.fecha_final}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Hora final"
                type="time"
                name='hora_final'
                handleValue={handleInputChange}
                value={data?.hora_final}
                size="col-12"
                sizeDesk="col-md-6"
              />
            </div>

            <Button type="submit" text="Crear" color="primary" icon="Table" onClick={(e) => handleFetch(e)} />
          </CustomForm>

          : <CustomLoader />
        }
      </div>
    </div>
  );
};

export default Create;

import { useEffect, useState } from 'react'


// Components
import Button from '../../../components/Button'
import FormInput from '../../../components/FormInput'
import CustomLoader from '../../../components/CustomLoader'
import Table from '../../../components/Table'
import { NoResults } from '../../../components/Tables/Misc/NoResults'


// Layouts
import AccordionTable from '../../../layout/AccordionTable'


// Redux
import { useSelector } from 'react-redux'

// Endpoints
import { BASE_URL, PATH_URL } from '../../../endpoints'
import { ProductosPuntosEntregaUpdateFiltros } from '../../../components/AccordionFilter/ProductosPuntosEntregaUpdate'
import ProductosFiltros from '../../../components/AccordionFilter/Productos'
import { queryParamsFormatter } from '../../../helpers/queryParamsFormatter'
import { idExtractor } from '../../../helpers/idExtractor'
import DownloadFile from '../../../components/Download/DownloadFile'
import ActualizaciónMasivaExcel from '../../../components/Modal/Productos/PuntosEntrega/ActualizaciónMasivaExcel'


const CONSTANT = {
  redirectPath: 'puntos-entrega',
  urlDownload: BASE_URL + PATH_URL + '/puntos-entregas-productos/exportar-actualizacion',

  datatableInputsInitialState: {
    productos_encontrados: {
      nombre: '',
      sku: '',
      adicional: ''
    },
    productos_seleccionados: {
      filtro: '',
      precio: ''
    }
  },

  resultados_productos: {
    encontrados: [],
    encontrados_filtrados: [],
    seleccionado: null,
  },

  form: {
    producto_id: '',
    punto_entrega_id: '',
    estatus: ''
  }
}


// FORMATEO DE RESPUESTAS
const productosFormatter = (arr) => {
  if (typeof arr !== 'object') return

  return arr?.map(el => ({
    ...el,
    precio_instalacion: '',
    recargo: '',
    instalacion_permanente: 0,
    instalacion_apply: true,
    recargo_apply: true,
    estatus: 1,
    filter_params: `
    ${el?.nombre}
    ${el?.sku}
    `
  }))
}

export const PuntosEntregaActualizacionMasiva = () => {
  const { resultados: reduxResultadosProductos } = useSelector(state => state.productos)
  const [loader, setLoader] = useState(false)
  const [productos, setProductos] = useState(CONSTANT.resultados_productos)
  const [inputsDatatable, setInputsDatatables] = useState(CONSTANT.datatableInputsInitialState)
  const [form, setForm] = useState(CONSTANT.form)
  const [downloadUrl, setDownloadUrl] = useState('') 
  const [show, setShow] = useState(false)

  // EFECTOS QUE ACTUALIZAN Y RELLENAN LAS TABLAS DE RESULTADOS
  useEffect(() => {
    setProductos((productos) => ({
      ...productos,
      encontrados: productosFormatter(reduxResultadosProductos?.data ?? reduxResultadosProductos),
      encontrados_filtrados: productosFormatter(reduxResultadosProductos?.data ?? reduxResultadosProductos)
    }))
  }, [reduxResultadosProductos])

  useEffect(() => {
    setForm(prev => ({
      ...prev,
      producto_id: `${productos?.seleccionado?.id ?? '*NULL*'}%-%${productos?.seleccionado?.nombre ?? 'Sin selección'}`,
    }))
  }, [productos.seleccionado])


  useEffect(() => {
    const encontrados_filtrados = productos.encontrados?.filter(({ nombre, sku }) => nombre.toLowerCase().includes(inputsDatatable.productos_encontrados?.nombre?.toLowerCase()) && sku.toLowerCase().includes(inputsDatatable.productos_encontrados?.sku?.toLowerCase()))
    setProductos((productos) => ({ ...productos, encontrados_filtrados }))
  }, [productos.encontrados, inputsDatatable.productos_encontrados])


  useEffect(() => {
    const { producto_id, estatus, punto_entrega_id } = form;

    const validParams = {
      producto_id: idExtractor(producto_id, true),
      estatus: idExtractor(estatus, true),
      punto_entrega_id: idExtractor(punto_entrega_id, true)
    }

    setDownloadUrl(CONSTANT.urlDownload + '?q=1' + queryParamsFormatter(validParams))
  }, [form])


  // FUNCION MANEJADORA PARA LA SELECCION DE UN PRODUCTO
  const handleProductoSeleccionado = (e) => {
    const { name } = e.target?.dataset ?? e.target
    const [id] = name.split('%-%')
    const selected = productos.encontrados.find(obj => String(obj.id) === id)
    const isSelected = Number(id) === Number(productos?.seleccionado?.id)

    setProductos({
      ...productos,
      seleccionado: !isSelected ? selected : null,
    })
  }


  // FUNCION MANEJADORA DE LOS INPUTS DE LA DATATABLE DE PRODUCTOS SELECCIONADOS
  const handleDatatableInputs = (e, isRemove = false) => {
    const { value, name } = e.target
    const [target, key] = name.split('-')

    setInputsDatatables({
      ...inputsDatatable,
      [target]: {
        ...inputsDatatable[target],
        [key]: !isRemove ? value : ''
      }
    })
  }

  return (
    <div className="row">
      <ActualizaciónMasivaExcel show={show} handleShow={() => setShow(false)} />

      <div className='d-flex justify-content-between py-50'>
        <h3>Puntos de Entrega - Actualización Masiva</h3>

        <Button className='' text='Subir Excel' color='warning' icon={'Upload'} onClick={() => setShow(true)} />
      </div>

      {loader && <CustomLoader blocking={'full'} />}
      <div className="col-12 mx-auto">

        {/* COMPONENTE FILTROS - PRODUCTOS */}
        <ProductosPuntosEntregaUpdateFiltros form={form} handleForm={setForm} />

        {/* TABLAS */}
        <div className='row'>
          <ProductosFiltros
            isAsignacionMasiva
            isOpen={false}
            mobile exportar={false}
          />

          {/* PRODUCTOS ENCONTRADOS */}
          <AccordionTable
            title={`Productos encontrados (${productos.encontrados?.length ?? 0} encontrados / ${productos.encontrados_filtrados?.length ?? 0} filtrados)`}
            classes='col-12 col-md-6 mb-1'
            isOpen={false}
          >
            <Table style={{ maxHeight: '1000px' }}>
              <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                <tr role="row">

                  <th>
                    <div className='d-flex'>
                      <FormInput
                        name='productos_encontrados-nombre'
                        value={inputsDatatable.productos_encontrados.nombre}
                        handleValue={handleDatatableInputs}
                        placeholder='Producto'
                        labelText=''
                        sizeDesk='col'
                        classes='text-capitalize sub-text-3 font-weight-normal'
                        margin='my-auto'
                      />
                    </div>
                  </th>

                  <th>
                    <div className='d-flex'>
                      <FormInput
                        name='productos_encontrados-sku'
                        value={inputsDatatable.productos_encontrados.sku}
                        handleValue={handleDatatableInputs}
                        placeholder='SKU'
                        labelText=''
                        sizeDesk='col'
                        classes='text-capitalize sub-text-3 font-weight-normal'
                        margin='my-auto'
                      />
                    </div>
                  </th>
                </tr>

              </thead>
              <tbody>
                {productos.encontrados_filtrados?.length

                  ? productos.encontrados_filtrados?.map(data => (
                    <tr
                      className={`odd data-selector ${productos.seleccionado?.id === data?.id ? 'selected' : ''}`}
                      key={`producto-${data?.id}-${data?.nombre}`}
                      onClick={handleProductoSeleccionado}
                      data-name={`${data?.id}%-%${data?.nombre}`}
                    >
                      <td data-name={`${data?.id}%-%${data?.nombre}`}>
                        <span className='d-flex flex-column' data-name={`${data?.id}%-%${data?.nombre}`}>
                          <span data-name={`${data?.id}%-%${data?.nombre}`}>{data?.neu_categoria?.nombre}</span>
                          <b data-name={`${data?.id}%-%${data?.nombre}`}>{data?.nombre.toUpperCase()}</b>
                        </span>
                      </td>
                      <td data-name={`${data?.id}%-%${data?.nombre}`}>
                        {data?.sku}
                      </td>
                    </tr>
                  ))

                  : <tr><td colSpan={2}><NoResults /></td></tr>
                }
              </tbody>
            </Table>
          </AccordionTable>
        </div>

        <div className='d-flex justify-content-center mt-2'>
          <DownloadFile
            url={downloadUrl}
            method='GET'
          />
        </div>
      </div >
    </div >
  )
}
import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { FILTER_BUSQUEDA, FILTER_TIPO_ID } from '../../redux/redux-types'


// Components
import FormInput from '../FormInput'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { getAllEmpresas } from '../../services/empresas/getAllEmpresas.service'
import { clearFilterEmpresas, resultadosEmpresas, busquedaFilter, TipoIdFilter } from '../../redux/actions/empresas'
import { toasterErrorFormatter } from '../../helpers/toasterErrorFormatter'
import SelectFilter from '../SelectFilter'
import { getAllEmpresasTipos } from '../../services/empresas/getAllEmpresasTipo.service'
import { idExtractor } from '../../helpers/idExtractor'


const CONSTANT = {
  redirectUrl: '/empresas',
  reduxStateSelector: 'empresas',
  title: 'Búsqueda de empresas',
  reduxClearFilters: clearFilterEmpresas,
  reduxSetResults: resultadosEmpresas,
  nuevasEmpresas: { id: 'nuevas', nombre: 'Nuevas' },
  allEmpresas: { id: 'NULL', nombre: 'Todas' },

  selectFilterInitialState: {
    empresasTipos: [],
  },
}


export const filtrosFormatter = (filtros) => {
  let validFormat = {}

  Object.entries(filtros).forEach(([k, v]) => {
    if (k === `tipo_id` && v.length && !v.includes('NULL')) {
      validFormat = {
        ...validFormat,
        [k]: idExtractor(v, true)
      }
    }

    if (k === 'busqueda' && v.length) {
      validFormat = {
        ...validFormat,
        [k]: v
      }
    }
  })

  return validFormat
}

export const handleSelectsData = async () => {
  const empresasTipos = await getAllEmpresasTipos().then(res => {
    if (res.status >= 400) return []
    return [...res]
  })

  return { empresasTipos }
}

export const EmpresasFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const { busqueda, tipo_id } = filtros
  const dispatch = useDispatch()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    setIsConsulting(true)

    getAllEmpresas(showing_results).then(res => dispatch(CONSTANT.reduxSetResults(res)))

    handleSelectsData().then(res => setSelectFilter(res))

    setIsConsulting(false)

    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [showing_results, dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return getAllEmpresas(showing_results).then(res => dispatch(CONSTANT.reduxSetResults(res)))
    }
    setIsConsulting(true)

    await getAllEmpresas(showing_results, filtrosFormatter(filtros))
      .then(res => {
        if (res.status >= 400) {
          dispatch(fireToaster(toasterErrorFormatter({ errors: res.data, response: res })))
          dispatch(CONSTANT.reduxSetResults([]))
          return
        }

        dispatch(CONSTANT.reduxSetResults(res))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_BUSQUEDA:
        dispatch(busquedaFilter(value))
        break;

      case FILTER_TIPO_ID:
        dispatch(TipoIdFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title}(${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <FormInput
            labelText='Búsqueda'
            placeholder='Ingrese su búsqueda'
            size='col-12'
            sizeDesk='col-md-4'
            handleValue={(e) => handleAddFilter(e, FILTER_BUSQUEDA)}
            value={busqueda}
          />

          <SelectFilter
            labelText="Tipo empresa"
            altSeparator
            size="col-12"
            sizeDesk={"col-md-2"}
            optionObj={selectFilter?.empresasTipos?.concat([CONSTANT.allEmpresas, CONSTANT.nuevasEmpresas]).map(({ id, nombre }) => ({ id, name: nombre }))}
            handleValue={(e) => handleAddFilter(e, FILTER_TIPO_ID)}
            value={tipo_id.split('%-%')[1] ?? 'Seleccione'}
          />
        </>
      </ListadoHeader>
    </div>
  )
}
import { useEffect, useState } from "react";
import axios from "axios";

// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import CustomLoader from "../../components/CustomLoader";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";


// React-router-dom
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { getOne } from "../../endpoints/getOne";
import { idExtractor } from "../../helpers/idExtractor";
import SelectFilter from "../../components/SelectFilter";
import { getAll } from "../../endpoints/getAll";


const CONSTANT = {
  redirectUrl: '/generales/distritos',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitle: 'Editar distrito',
  title: 'Distrito',
  queryUrl: getOne.distritos,
  fetchPaises: getAll.paises + '/buscar',
  fetchDistritosErp: getAll.erp_distritos,
  initialFormState: {
    pais_id: '',
    nombre: undefined,
    cod_maxi: undefined,
    estatus: 1
  },
  selectFilterInitialState: {
    paises: [],
    erpDistritosCodMaxi: []
  }
}


const handleSelectFiltersData = async () => {
  const paises = await axios(CONSTANT.fetchPaises,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.paises] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const erpDistritosCodMaxi = await axios(CONSTANT.fetchDistritosErp,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.Distritos] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })
  return { paises, erpDistritosCodMaxi }
}


const Update = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetchData, setFetchData] = useState(null)
  const { id } = useParams()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)


  // EFECTO QUE SETEA LOS VALORES DE LOS SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])

  // FUNCIÓN MANEJADORA QUE ACTUALIZA EL ESTADO DE 'FetchData'
  const handleInputChange = (e) => {
    const { target } = e

    setFetchData({
      ...fetchData,
      [target.name]: target.value,
    })
  }


  // EFECTO QUE CONSULTA LA DATA DEL REGISTRO Y ACTUALIZA EL ESTADO 'FetchData'
  useEffect(() => {
    axios(CONSTANT.queryUrl(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        const selectedCodMaxi = selectFilter.erpDistritosCodMaxi.find(({ cod_maxi }) => cod_maxi === data?.cod_maxi)
        setFetchData({
          ...data,
          pais_id: `${data?.pais?.id}-${data?.pais?.nombre}`,
          cod_maxi: `${selectedCodMaxi?.cod_maxi ?? '*NULL*'}-${selectedCodMaxi?.nombre ?? 'Error'} | ${selectedCodMaxi?.cod_maxi ?? ''}`
        })
      })
      .catch(err => console.error(err))

    return () => {
      setFetchData(null)
    }
  }, [id, selectFilter.erpDistritosCodMaxi])


  const handleFetch = async (e) => {
    e.preventDefault()

    let data = {
      nombre: fetchData?.nombre ?? null,
      cod_maxi: fetchData?.cod_maxi?.split('-')[0] ?? null,
      pais_id: idExtractor(fetchData?.pais_id) ?? null,
      estatus: Number(fetchData?.estatus) ? true : false
    }

    axios(CONSTANT.queryUrl(id), {
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'application/json',
        '_method': 'PUT'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} actualizado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (

    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>
        {fetchData && selectFilter.paises.length && selectFilter.erpDistritosCodMaxi.length
          ? <CustomForm size="" title={CONSTANT.componentTitle}>

            <div className="row">
              <Switch
                labelText='Estatus'
                name='estatus'
                value={fetchData?.estatus}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <SelectFilter
                labelText='* Cod. maximise'
                name='cod_maxi'
                value={fetchData?.cod_maxi?.split('-')[1] ?? ''}
                handleValue={handleInputChange}
                optionObj={selectFilter.erpDistritosCodMaxi.map(({ cod_maxi, nombre }) => ({ id: cod_maxi ?? '*NULL*', name: `${nombre ?? 'Error'} | ${cod_maxi ?? ''}` }))}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <SelectFilter
                labelText='* Región'
                name='pais_id'
                value={fetchData?.pais_id?.split('-')[1]}
                handleValue={handleInputChange}
                optionObj={selectFilter.paises.map(({ id, nombre }) => ({ id, name: nombre }))}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='* Nombre'
                name='nombre'
                value={fetchData?.nombre}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />
            </div>

            <Button
              type="submit"
              text="Guardar"
              color="primary"
              icon="Check"
              onClick={(e) => handleFetch(e)}
            />
          </CustomForm>
          : <CustomLoader />
        }
      </div>
    </div>

  );
};

export default Update;

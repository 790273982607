// Views
import Listado from "./Tabs/Listado";


const ProductosIndex = ({ view = <Listado /> }) => {
	return (
		<div className="row">
			<h2>Productos</h2>

			<div className="col">
			

				<hr className="m-1" />

				{view}
			</div>
		</div>
	)
}

export default ProductosIndex;


const Tooltip = ({ text, classes, position = 'bl' }) => {
  const styles = {
    btn: {
      position: 'relative',
      cursor: 'pointer',
    },

    text: {
      maxWidth: '300px',
      minWidth: '200px',
      backgroundColor: '#e9fcdc',
      border: '1px solid #555',
      borderRadius: '4px',
      padding: '.3rem',
      zIndex: '1000000',
      textTransform: 'lowercase',
      position: 'absolute',
      [position.includes('t') ? 'bottom' : 'top']: '100%',
      [position.includes('r') ? 'left' : 'right']: '100%',
    }
  }

  return (
    <div className={`tooltip-btn text-center border rounded-2 bg-warning ${classes}`} style={styles.btn}>?
      <p className="tooltip-text" style={styles.text}>{text}</p>
    </div>
  )
}

export default Tooltip
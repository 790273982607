import axios from 'axios';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from "../../redux/actions/toaster";
import { clearFilterRoles, resultadosRoles } from '../../redux/actions/roles';


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Table from "../Table";


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter';


const CONSTANT = {
   pathUrl: '/roles',
   permissionsPath: 'roles',
   queryUrlGetOne: getOne.roles,
   title: 'Roles',
   reduxClearFilters: (data) => clearFilterRoles(data),
   reduxSetResults: (data) => resultadosRoles(data),
}


const RolesTable = () => {
   const { resultados: response, filtros } = useSelector(state => state.roles)
   const { showing_results } = useSelector(state => state.listedResults)
   const { busqueda } = filtros
   const dispatch = useDispatch()
   const { user } = useSelector(state => state.auth)


   // FUNCION MANEJADORA DE PAGINACIÓN
   const handleUrl = (url) => {
      dispatch(CONSTANT.reduxSetResults(null))
      axios(url + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }),
         {
            headers: {
               'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
         })
         .then(res => dispatch(CONSTANT.reduxSetResults(res.data)))
         .catch(err => console.error(err))
   }


   // FUNCIÓN MANEJADORA PARA BORRAR UN REGISTRO
   const handleFetch = async (id, url, target) => {
      let config = {
         headers: {
            'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
         },
      }

      if (target === 'delete') {
         config = {
            ...config,
            method: 'DELETE'
         }
      }

      await axios(CONSTANT.queryUrlGetOne(id), config)
         .then(res => {
            const toasterContent = {
               title: 'Operación realizada',
               text: `${CONSTANT.title}: registro eliminado con éxito`,
               icon: 'info'
            }

            dispatch(fireToaster(toasterContent))
         })
         .catch(err => {
            console.error(err);
            const { errores } = err.response.data
            let detalles = []
            Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

            const toasterContent = {
               title: `
         Operación fallida
         (${err.response.status} - ${err.response.statusText})
       `,
               html: `
         <b>Detalle: </b>
         ${detalles.map(error => `<br /><i>-${error}</i>`)}
       `,
               icon: 'error'
            }
            dispatch(fireToaster(toasterContent))
         })

      dispatch(CONSTANT.reduxSetResults(null))
      axios(url, config)
         .then(res => {
            dispatch(CONSTANT.reduxSetResults(res.data))
         })
         .catch(err => console.error(err))
   }


   return (
      <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
         {!response && <CustomLoader blocking={'partial'} />}

         <Table response={response} handlePagination={handleUrl}>
            <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
               <tr role="row">
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Información</th>
                  <th>Creación</th>
                  <th className="w-max">Acciones</th>
               </tr>
            </thead>
            <tbody>
               {response?.data?.length
                  ? response.data?.map((data) => {
                     return (
                        <tr className="odd" key={`user-${data.id}-${data.titulo}`}>
                           <td className='col-1'>{data?.id}</td>
                           <td>{data?.name ?? "N/A"}</td>
                           <td>
                              <div className='d-flex flex-column'>
                                 <span>Guard name: <b>{data?.guard_name ?? "N/A"}</b></span>
                                 <span>User type: <b>{data?.user_type ?? "N/A"}</b></span>
                              </div>
                           </td>
                           <td className='col-2'>{data?.created_at?.split("T")[0] || 'N/A'}</td>
                           <td className='col-1'>
                              <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                                 {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                                    <DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
                                 }

                                 {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
                                    <DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
                                 }

                                 {user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
                                    <DropdownMenuLink text='Borrar' icon="X" onClick={() => handleFetch(data.id, response.path + '?page=' + response.current_page + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }), 'delete')} />
                                 }
                              </DropdownWrapper>
                           </td>
                        </tr>
                     )
                  })
                  : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={5}><i>Sin datos para mostrar...</i></td></tr>
               }
            </tbody>
         </Table>
      </AccordionTable >
   )
}

export default RolesTable
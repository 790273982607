
// Components
import BodegasPuntosEntregaFiltros from "../../../components/AccordionFilter/BodegasPuntosEntrega";
import BodegasPuntoEntregaTable from "../../../components/Tables/BodegasPuntoEntregaTable";


const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>Bodegas</b> - Listado</p>
			</h3>

			<BodegasPuntosEntregaFiltros />

			<BodegasPuntoEntregaTable />
		</div>

	);
}

export default Listado;
import { Navigate, Route, Routes } from "react-router-dom"
import Listado from "../views/MetodosPago/Listado";
import Form from "../views/MetodosPago/Form";
import Detalle from "../views/MetodosPago/Detalle";
import { useSelector } from "react-redux";


const MetodosPagoRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['metodos-pago.crear'] &&
            <Route path='/create' element={<Form />} />
         }

         {user.permisos['metodos-pago.editar'] &&
            <Route path='/update/:id' element={<Form />} />
         }

         {user.permisos['metodos-pago.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   );
}

export default MetodosPagoRouter;
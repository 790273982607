
// Components
import FactoresDiferenciadoresFiltros from "../../components/AccordionFilter/FactoresDiferenciadores";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import FactoresDiferenciadoresTable from "../../components/Tables/FactoresDiferenciadoresTable";

const CONSTANT = {
	title: 'Factores Diferenciadores',
	path: 'template/factores-diferenciadores',
	create_btn: 'Crear factor diferenciador'
}

const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>
			
			<FactoresDiferenciadoresFiltros />

			<FactoresDiferenciadoresTable />
		</div>
	);
}

export default Listado;
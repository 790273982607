// Redux
import { useDispatch, useSelector } from 'react-redux'
import { clearFilterAutogestion, resultadosAutogestion } from '../../redux/actions/autogestion';


// Components
import CustomLoader from "../CustomLoader";
import Table from "../Table";


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { getAllEmpresas } from '../../services/empresas/getAllEmpresas.service';
import { filtrosFormatter } from '../AccordionFilter/Empresas';
import { Link } from 'react-router-dom';


const CONSTANT = {
	permissionsPath: 'autogestion',
	pathUrl: '/autogestion',
	queryUrlGetOne: getOne.empresas,
	title: 'Autogestión',
	reduxClearFilters: clearFilterAutogestion,
	reduxSetResults: resultadosAutogestion,
}


export const AutogestionTable = () => {
	const { resultados: response, filtros } = useSelector(state => state.autogestion)
	const { showing_results } = useSelector(state => state.listedResults)
	const dispatch = useDispatch()


	// FUNCION MANEJADORA DE PAGINACIÓN
	const handleUrl = (url) => {
		console.log(url);
		dispatch(CONSTANT.reduxSetResults(null))

		getAllEmpresas(showing_results, filtrosFormatter(filtros), url).then(res => {
			if (res.status >= 400) {
				// dispatch(fireToaster(toasterErrorFormatter({ errors: res.data, response: res })))
				dispatch(CONSTANT.reduxSetResults([]))
				return
			}

			dispatch(CONSTANT.reduxSetResults(res))
		})
	}


	return (
		<>
			<AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
				{!response && <CustomLoader blocking={'partial'} />}

				<Table response={response} handlePagination={handleUrl}>
					<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
						<tr role="row">
							<th>ID</th>
							<th>Pedido</th>
							<th>Nota de venta</th>
							<th>Patente</th>
							<th>Creación</th>
						</tr>
					</thead>

					<tbody>
						{response?.data.length
							? response.data?.map((data) => {
								return (
									<tr className="odd" key={`user-${data.id}-${data.titulo}`}>
										<td className='col-1'>{data?.id}</td>
										<td className='col-1'><Link to={`/pedidos/detalle/${data?.pedido_id}`}>{data?.pedido_id ?? 'N/A'}</Link></td>
										<td className='col-1'><Link to={`/erp/notas-ventas/detalle/${data?.nota_venta}`}>{data?.nota_venta ?? 'N/A'}</Link></td>
										<td className='col-1'>{data?.patente}</td>
										<td className='col-1'>{data?.created_at?.split("T")[0] || 'N/A'}</td>
									</tr>
								)
							})
							: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
						}
					</tbody>
				</Table>
			</AccordionTable >
		</>
	)
}
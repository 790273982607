import axios from "axios"
import { BASE_URL, PATH_URL } from "../endpoints"

const CONSTANT = {
  query: BASE_URL + PATH_URL + '/historial/historico'
}

export const historicoData = async (id, model) => {
  const config = {
    method: 'POST',
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
    data: {
      modelo: model,
      modelo_id: id
    }
  }

  return await axios(CONSTANT.query, config)
    .then(({ data }) => data)
    .catch(err => {
      console.log(err)
      return []
    })
}
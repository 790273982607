import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

//TODO: Acomoda los estilos

const AccordionTable = ({ children, title, classes, isOpen = true, isSmall = true, ...props }) => {
   const [isCollapsed, setIsCollapsed] = useState(isOpen);

   const handleCollapse = () => setIsCollapsed(!isCollapsed)

   useEffect(() => setIsCollapsed(isOpen), [isOpen])

   return (
      <div className={`accordion ${classes}`}  >
         <div className="accordion-item">
            <h2 className="accordion-header" >
               <div
                  className={`py-50 title fs-4 fw-bolder card-title accordion-button mb-0 ${isCollapsed ? 'collapsed' : ''}`}
                  type="button"
                  onClick={handleCollapse}
                  {...props}
               >
                  {title}
               </div>
            </h2>

            <div className={`accordion-collapse collapse ${isCollapsed ? 'show' : ''} `} >
               <div className="accordion-body px-0 py-0 " style={{ minHeight: isSmall ? '0px' : '150px' }}>
                  {children}
               </div>
            </div>
         </div>
      </div>
   );
}

AccordionTable.propTypes = {
   classes: PropTypes.string
}

AccordionTable.defaultProps = {
   classes: ''
}

export default AccordionTable;
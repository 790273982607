import React from 'react';
import ReactDOM from 'react-dom/client';



// Styles
import './styles/index.css'
import './styles/spinner.css'


// Components
import App from './App';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  	<App />
);
import React from 'react'
import Icon from './Icon'

const Construction = () => {
   return (
      <div className='row'>
         <div className='col'>
            <div className='building bg-white rounded-3 shadow'>
               <div className='rounded-3 overflow-hidden mx-auto d-flex'>
                  <div className='bg-primary pb-3 d-flex flex-column shadow'>
                     <div className='my-auto'>
                        <h3 className='fs-1 px-3 py-1 text-white text-end me-1'>En construcción<Icon className='ms-25' icon='Tool' size={20} /></h3>
                        <div className='my-auto mx-5 border p-2 rounded-3 shadow bg-white'>
                           <p className='my-0'><i>Lo sentimos, esta sección aún está en desarrollo.</i></p>
                           <p className='my-0'><i>Se está trabajando duro en su construcción </i></p>
                           <p><i>y poder estar disponible lo antes posible...</i></p>
                           <p><i>Mientras tanto pruebe a volver al <a href='/'><u>Inicio</u></a>.</i></p>
                        </div>
                        <p className='mt-2 ms-5 fs-5 text-white'><i>- Atentamente, el equipo de desarrollo.</i></p>
                     </div>
                  </div>
                  <div className='w-50 mx-auto'>
                     <img src='/development.jpg' className='img-fluid' alt='development' />
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Construction
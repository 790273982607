import axios from "axios";


// Redux


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";


// React-router
import { useNavigate } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";
import { useEffect, useState } from "react";
import { idExtractor } from "../../helpers/idExtractor";
import SelectFilter from "../../components/SelectFilter";
import { getAll } from "../../endpoints/getAll";
import CustomLoader from "../../components/CustomLoader";
import Table from "../../components/Table";
import AccordionTable from "../../layout/AccordionTable";
import { useCustomFetch } from "../../hooks/useCustomFetch";
import { contractFormat } from "./misc/contractFormat";


const CONSTANT = {
  redirectUrl: '/agendamientos',
  componentTitle: 'Crear agendamiento',
  title: 'Agendamientos',
  queryUrl: create.agendamientos,
  selectFilterInitialState: { pedidos: [], puntos_entrega: [] },
  fetchPedidos: getAll.pedidos + '/listar',
  fetchPuntosEntrega: getAll.puntos_entrega + '/listar',
}


const initialformState = {
  pedido_id: '',
  pedido_data: {},
  hora_agenda_id: undefined,
  punto_entrega_data: '',
  punto_entrega_id: '',
  fecha: undefined,
  estatus: 1
};


const fetchSelectFiltersData = async () => {
  const pedidos = await axios(CONSTANT.fetchPedidos,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => data ?? [])
    .catch(err => console.error(err))

  const puntos_entrega = await axios(CONSTANT.fetchPuntosEntrega,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => data ?? [])
    .catch(err => console.error(err))

  return { pedidos, puntos_entrega }
}


const Create = () => {
  const navigate = useNavigate()
  const [form, setForm] = useState(initialformState)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [customFetch] = useCustomFetch()

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE SETEA LOS VALORES DE LOS SELECTFILTERS
  useEffect(() => {
    fetchSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)

    const { nombre, region_id, cod_maxi, estatus } = form

    let data = {
      nombre,
      region_id: idExtractor(region_id),
      cod_maxi,
      estatus: Number(estatus) ? true : false
    }

    const { statusText } = await customFetch(CONSTANT.queryUrl, contractFormat('create', data))
    if (statusText === 'OK') navigate(CONSTANT.redirectUrl)

    setIsConsulting(false)
  }


  return (
    <div className="row">
      < div className="col col-md-12 mx-auto" >
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>
        {selectFilter.pedidos.length && selectFilter.puntos_entrega.length
          ? <CustomForm size="" title={CONSTANT.componentTitle}>

            <div className="row">
              {/* <SelectFilter
                labelText='* Pedido'
                name='pedido_id'
                value={form.selectFilter?.split('-')[1]}
                handleValue={handleInputChange}
                optionObj={selectFilter.pedidos.map(({ id, numero_seguimiento }) => ({ id, name: String(numero_seguimiento) }))}
                size='col-12'
                sizeDesk='col-md-6'
              /> */}
              <AccordionTable
                title={`Puntos de entrega (${selectFilter.pedidos?.length} resultados)`}
                classes="col-12 mb-2"
              >
                <Table>
                  <thead className="table-light position-sticky top-0 w-100 z-100">
                    <tr>
                      <th>ID</th>
                      <th>Número de seguimiento</th>
                      <th>Dirección</th>
                      <th>Cliente</th>
                      <th>Facturación</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectFilter.pedidos.length
                      ? selectFilter.pedidos.map((
                        { id, numero_seguimiento, created_at, estatus_pedido, punto_entrega, usuario, tipo_facturacion, metodo_entrega, metodo_pago, divisa }
                      ) => (
                        <tr>
                          <td>{id ?? '?'}</td>
                          <td>
                            <div className="d-flex flex-column gap-50">
                              <span>{numero_seguimiento ?? '?'}</span>
                              <span className="tag px-50">{estatus_pedido?.nombre ?? '?'}</span>
                              <span>{created_at?.split('T')[0] ?? '?'}</span>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              <span>Comuna: <b>{punto_entrega?.comuna?.nombre ?? 'N/A'}</b></span>
                              <span>Región: <b>{punto_entrega?.comuna?.region?.nombre}</b></span>
                              <span>Dirección: <b>{punto_entrega?.descripcion + ', ' ?? ''}{punto_entrega?.direccion + ', ' ?? ''}{punto_entrega?.nombre ?? 'N/A'}</b></span>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              <span>Nombre: <b>{usuario?.nombre ?? 'N/A'}</b></span>
                              <span>RUT: <b>{usuario?.rut ?? 'N/A'}</b></span>
                              <span>Email: <b>{usuario?.email ?? 'N/A'}</b></span>
                              <span>Teléfono: <b>{usuario?.fono ?? 'N/A'}</b></span>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              <span>Tipo: <b>{tipo_facturacion?.nombre ?? 'N/A'}</b></span>
                              <span>Método entrega: <b>{metodo_entrega?.nombre ?? 'N/A'}</b></span>
                              <span>Método pago: <b>{metodo_pago?.nombre ?? 'N/A'}</b></span>
                              <span>Divisa: <b>{divisa?.nombre ?? 'N/A'}</b></span>
                            </div>
                          </td>
                        </tr>
                      ))
                      : <tr><td className="text-center" colSpan={7}>Sin resultados...</td></tr>
                    }
                  </tbody>
                </Table>
              </AccordionTable>

              <SelectFilter
                labelText='* Punto de entrega'
                name='punto_entrega_id'
                value={form.punto_entrega_id?.split('-')[1]}
                handleValue={handleInputChange}
                optionObj={selectFilter.puntos_entrega.map(({ id, direccion, neu_tipo_destino, comuna }) => ({ id, name: `${neu_tipo_destino?.nombre ?? 'N/A'} | ${direccion ?? 'N/A'} | ${comuna?.neu_region?.nombre ?? 'N/A'}` }))}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                type='date'
                labelText='* Fecha'
                name='fecha'
                value={form.fecha}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />
            </div>

            <Button
              type="submit"
              text="Crear"
              disabled={isConsulting}
              color="primary"
              icon="Plus"
              onClick={(e) => handleFetch(e)}
            />
          </CustomForm>
          : <CustomLoader />
        }
      </div >
    </div >
  );
};

export default Create;

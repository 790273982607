import {
   FILTER_ADD_PROVEEDOR_CATEGORIA,
   FILTER_CLEAR,
   FILTER_REMOVE_PROVEEDOR_CATEGORIA,
   RESULTADOS_PRODUCTOS_PROVEEDORES,
} from "../redux-types";

const initialState = {
   filtros: {
      proveedor_categoria_id: []
   },
   resultados: null
}


export const productosProveedoresReducer = (state = initialState, action) => {
   let isListed
   switch (action.type) {
      case FILTER_ADD_PROVEEDOR_CATEGORIA:
         isListed = state.filtros.proveedor_categoria_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               proveedor_categoria_id: isListed
                  ? state.filtros.proveedor_categoria_id
                  : [...state.filtros.proveedor_categoria_id, action.payload]
            }
         };

      case FILTER_REMOVE_PROVEEDOR_CATEGORIA:
         return {
            ...state, filtros: {
               ...state.filtros,
               proveedor_categoria_id: state.filtros.proveedor_categoria_id.filter(el => el !== action.payload)
            }
         };

      case RESULTADOS_PRODUCTOS_PROVEEDORES:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';



// store
import { store } from './redux/store/store';
import AppRouter from './routers/AppRouter';
import ScrollToTop from './components/hud/ScrollToTop';


const App = () => {

   return (
      <Provider store={store}>
         <Router>
            <AppRouter />
         </Router>
         <ScrollToTop />
      </Provider>
   )
}



export default App;
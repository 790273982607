import {
   FILTER_ADD_ESTATUS,
   FILTER_EXTENSION_TELEFONICA,
   FILTER_PATRON_IDENTIFICACION,
   FILTER_REMOVE_ESTATUS,
   FILTER_CLEAR,
   FILTER_CREACION_DESDE,
   FILTER_CREACION_HASTA,
   FILTER_BUSQUEDA,
   RESULTADOS_PAISES,
   FILTER_ADD_NOMBRE,
   FILTER_REMOVE_NOMBRE,
   FILTER_ADD_EXTENSION_TELEFONICA,
   FILTER_REMOVE_EXTENSION_TELEFONICA,
   FILTER_ADD_COD_MAXIMISE,
   FILTER_REMOVE_COD_MAXIMISE,
   FILTER_ADD_LOCALE,
   FILTER_REMOVE_LOCALE
} from "../redux-types";


const initialState = {
   filtros: {
      busqueda: '',
      extension_telefonica: [],
      patron_identificacion: '',
      locale: [],
      cod_maxi: [],
      estatus: [],
      creacion_desde: '',
      creacion_hasta: '',
      nombre: []
   },
   resultados: null
}


export const paisesReducer = (state = initialState, action) => {
   const isListedEstatus = state.filtros.estatus.find(el => el === action.payload)
   const isListedNombre = state.filtros.nombre.find(el => el === action.payload)
   const isListedExtensiontelefonica = state.filtros.extension_telefonica.find(el => el === action.payload)
   const isListedCodMaxi = state.filtros.cod_maxi.find(el => el === action.payload)
   const isListedLocale = state.filtros.locale.find(el => el === action.payload)

   switch (action.type) {
      case FILTER_ADD_ESTATUS:
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus: isListedEstatus
                  ? state.filtros.estatus
                  : [...state.filtros.estatus, action.payload]
            }
         };

      case FILTER_REMOVE_ESTATUS:
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus: state.filtros.estatus.filter(el => el !== action.payload)
            }
         };

      case FILTER_CREACION_DESDE:
         return {
            ...state, filtros: {
               ...state.filtros,
               creacion_desde: action.payload
            }
         };

      case FILTER_CREACION_HASTA:
         return {
            ...state, filtros: {
               ...state.filtros,
               creacion_hasta: action.payload
            }
         };

      case FILTER_ADD_NOMBRE:
         return {
            ...state, filtros: {
               ...state.filtros,
               nombre: isListedNombre
                  ? state.filtros.nombre
                  : [...state.filtros.nombre, action.payload]
            }
         };

      case FILTER_REMOVE_NOMBRE:
         return {
            ...state, filtros: {
               ...state.filtros,
               nombre: state.filtros.nombre.filter(el => el !== action.payload)
            }
         };

      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda: action.payload
            }
         };

      case FILTER_EXTENSION_TELEFONICA:
         return {
            ...state, filtros: {
               ...state.filtros,
               extension_telefonica: action.payload
            }
         };

      case FILTER_PATRON_IDENTIFICACION:
         return {
            ...state, filtros: {
               ...state.filtros,
               patron_identificacion: action.payload
            }
         };

      case FILTER_ADD_EXTENSION_TELEFONICA:
         return {
            ...state, filtros: {
               ...state.filtros,
               extension_telefonica: isListedExtensiontelefonica
                  ? state.filtros.extension_telefonica
                  : [...state.filtros.extension_telefonica, action.payload]
            }
         };

      case FILTER_REMOVE_EXTENSION_TELEFONICA:
         return {
            ...state, filtros: {
               ...state.filtros,
               extension_telefonica: state.filtros.extension_telefonica.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_LOCALE:
         return {
            ...state, filtros: {
               ...state.filtros,
               locale: isListedLocale
                  ? state.filtros.locale
                  : [...state.filtros.locale, action.payload]
            }
         };

      case FILTER_REMOVE_LOCALE:
         return {
            ...state, filtros: {
               ...state.filtros,
               locale: state.filtros.locale.filter(el => el !== action.payload)
            }
         };

      case RESULTADOS_PAISES:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_ADD_COD_MAXIMISE:
         return {
            ...state, filtros: {
               ...state.filtros,
               cod_maxi: isListedCodMaxi
                  ? state.filtros.cod_maxi
                  : [...state.filtros.cod_maxi, action.payload]
            }
         };

      case FILTER_REMOVE_COD_MAXIMISE:
         return {
            ...state, filtros: {
               ...state.filtros,
               cod_maxi: state.filtros.cod_maxi.filter(el => el !== action.payload)
            }
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
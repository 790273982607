import { useEffect, useState } from "react";
import { fireToaster } from "../../redux/actions/toaster";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import FileUploaderB from "../../components/FileUploader_B";


// Endpoints
import { create } from "../../endpoints/create";
import { getAll } from "../../endpoints/getAll";
import SelectFilter from "../../components/SelectFilter";
import CustomLoader from "../../components/CustomLoader";


const CONSTANT = {
  redirectUrl: '/productos/valores-atributos',
  componentTitle: 'Crear valor/atributo',
  title: 'Valores/Atributos',
  queryUrl: create.valores_atributos,
  fecthUnidades: getAll.unidades,
  fetchClavesAtributos: (categoria) => getAll.claves_atributos + `/filtros?categoria_id=${categoria?.split('-')[0] ?? 1}`,
  fetchCategorias: getAll.categorias + `/buscar?limit=99999`,
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  initialFormState: {
    nombre: '',
    categoria_id: '',
    clave_atributo_id: '',
    unidad_id: '',
    simbolo: '',
    codigo: '',
    valor_default: 0,
    estatus: 1,
  },
  selectFilterInitialState: {
    unidades: [],
    clavesAtributos: [],
    categorias: []
  }
}


const handleSelectFiltersData = async () => {
  const unidades = await axios(CONSTANT.fecthUnidades,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const categorias = await axios(CONSTANT.fetchCategorias,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => [...data?.categorias?.data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { unidades, categorias }
}


const Create = () => {
  const [logo, setLogo] = useState([])
  const [imagenesProductos, setImagenesProductos] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.initialFormState);
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // EFECTO QUE RELLENA LOS SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // EFECTO QUE RELLENA EL SELECTFILTER DE CLAVES-ATRIBUTOS
  useEffect(() => {
    if (!form.categoria_id) return
    axios(CONSTANT.fetchClavesAtributos(form.categoria_id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => setSelectFilter(selectFilter => ({ ...selectFilter, clavesAtributos: data })))
      .catch(err => {
        console.warn(err)
      })
  }, [form.categoria_id])


  // FUNCION MANEJADORA PARA LA CREACION DEL REGISTRO EN LA DDBB
  const handleFetch = async (e) => {
    e.preventDefault()

    if (!form.nombre?.length) return dispatch(fireToaster({ title: 'El nombre es obligatorio', icon: 'info' }))
    if (!form.clave_atributo_id?.length) return dispatch(fireToaster({ title: 'La clave-atributo es obligatoria', icon: 'info' }))
    // if (!form.simbolo?.length) return dispatch(fireToaster({ title: 'El símbolo es obligatorio', icon: 'info' }))
    // if (!form.codigo?.length) return dispatch(fireToaster({ title: 'El código es obligatorio', icon: 'info' }))
    // if (!imagenesProductos?.length) return dispatch(fireToaster({ title: 'La imagen es obligatoria', icon: 'info' }))
    // if (!logo?.length) return dispatch(fireToaster({ title: 'El logo es obligatorio', icon: 'info' }))

    let data = new FormData()
    data.append('nombre', form?.nombre)
    data.append('clave_atributo_id', Number(form?.clave_atributo_id?.split('-')[0]))
    form.unidad_id.length && !form.unidad_id.includes('*NULL*') && data.append('unidad_id', Number(form?.unidad_id?.split('-')[0]))
    data.append('simbolo', form?.simbolo)
    data.append('codigo', form?.codigo)
    logo.length && data.append('logo', logo[0].file)
    imagenesProductos.length && imagenesProductos.forEach(({ file }) => data.append('imagenes_productos[]', file))
    data.append('estatus', Number(form.estatus))
    data.append('valor_default', Number(form.valor_default))

    axios(CONSTANT.queryUrl, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'multipart/form-data'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {selectFilter.categorias.length && selectFilter.unidades.length

          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                name="estatus"
                value={form.estatus ?? ''}
                labelText="Estatus"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='justify-content-end'
              />

              <Switch
                name="valor_default"
                value={form.valor_default ?? ''}
                labelText="Valor por defecto"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='justify-content-end'
              />

              <SelectFilter
                name="categoria_id"
                value={form.categoria_id?.split('-')[1] ?? ''}
                labelText="* Categoría"
                handleValue={handleInputChange}
                optionObj={selectFilter.categorias?.map(({ id, nombre }) => ({ id, name: nombre }))}
                sizeDesk='col-md-4'
                size="col-12"
              />

              {selectFilter?.clavesAtributos?.length

                ? <SelectFilter
                  name="clave_atributo_id"
                  value={form.clave_atributo_id?.split('-')[1] ?? ''}
                  labelText="* Clave/Atributo"
                  handleValue={handleInputChange}
                  optionObj={selectFilter.clavesAtributos?.map(({ id, nombre }) => ({ id, name: nombre }))}
                  sizeDesk='col-md-4'
                  size="col-12"
                />

                : <FormInput
                  isDisabled
                  labelText="* Clave/Atributo"
                  size="col-12"
                  sizeDesk='col-md-4'
                />
              }

              <FormInput
                name="nombre"
                value={form.nombre ?? ''}
                labelText="* Nombre"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-4'
              />

              <SelectFilter
                name="unidad_id"
                value={form.unidad_id?.split('-')[1] ?? ''}
                labelText="Unidad"
                handleValue={handleInputChange}
                optionObj={selectFilter.unidades?.map(({ id, nombre }) => ({ id, name: nombre }))}
                size="col-12"
              />

              <FormInput
                name="simbolo"
                value={form.simbolo ?? ''}
                labelText="Símbolo"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-2'
              />

              <FormInput
                name="codigo"
                value={form.codigo ?? ''}
                labelText="Código"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-4'
              />

              <FileUploaderB labelText='Logo' handleValue={setLogo} value={logo} />

              <FileUploaderB labelText='Productos' handleValue={setImagenesProductos} value={imagenesProductos} />
            </div>

            <Button type="submit" text="Crear" color="primary" icon="Table" onClick={(e) => handleFetch(e)} />
          </CustomForm>

          : <CustomLoader />}

      </div>
    </div>
  );
};

export default Create;

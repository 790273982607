import {
   FILTER_ADD_CLAVE_ATRIBUTO,
   FILTER_ADD_UNIDAD,
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   FILTER_REMOVE_CLAVE_ATRIBUTO,
   FILTER_REMOVE_UNIDAD,
   RESULTADOS_VALORES_ATRIBUTOS,
} from "../redux-types";

const initialState = {
   filtros: {
      busqueda: '',
      clave_atributo_id: [],
      unidad_id: []
   },
   resultados: null
}


export const valoresAtributoReducer = (state = initialState, action) => {
   const isListedClaveAtributo = state.filtros.clave_atributo_id.find(el => el === action.payload)
   const isListedUnidad = state.filtros.unidad_id.find(el => el === action.payload)

   switch (action.type) {
      case FILTER_ADD_CLAVE_ATRIBUTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               clave_atributo_id: isListedClaveAtributo
                  ? state.filtros.clave_atributo_id
                  : [...state.filtros.clave_atributo_id, action.payload]
            }
         };
      case FILTER_REMOVE_CLAVE_ATRIBUTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               clave_atributo_id: state.filtros.clave_atributo_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_UNIDAD:
         return {
            ...state, filtros: {
               ...state.filtros,
               unidad_id: isListedUnidad
                  ? state.filtros.unidad_id
                  : [...state.filtros.unidad_id, action.payload]
            }
         };
      case FILTER_REMOVE_UNIDAD:
         return {
            ...state, filtros: {
               ...state.filtros,
               unidad_id: state.filtros.unidad_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda: action.payload
            }
         };

      case RESULTADOS_VALORES_ATRIBUTOS:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
import axios from "axios";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";
import SelectFilter from "../../components/SelectFilter";
import { useEffect, useState } from "react";
import { BASE_URL, PATH_URL } from "../../endpoints";
import FileUploaderB from "../../components/FileUploader_B";
import { idExtractor } from "../../helpers/idExtractor";
import CustomLoader from "../../components/CustomLoader";


const CONSTANT = {
  redirectUrl: '/metodos-pago',
  componentTitle: 'Crear método de pago',
  componentTitleEdit: 'Editar método de pago',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  title: 'Métodos de pago',
  queryUrl: create.metodos_pago,

  fetchCuentasCajaErp: BASE_URL + PATH_URL + `/erp/cuentas-cajas/obtener`,
  fetchTiposPagoErp: BASE_URL + PATH_URL + `/erp/tipos-pagos/obtener`,

  initialFormState: {
    nombre: '',
    cuenta_caja_erp: '',
    tipo_pago_erp: '',
    estatus: true
  },

  selectFilterInitialState: {
    cuentasCajaErp: [],
    tiposPagoErp: []
  }
}


const handleSelectFiltersData = async () => {
  const cuentasCajaErp = await axios(CONSTANT.fetchCuentasCajaErp,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => {
      return [CONSTANT.unselectedObject, ...data?.data.map(({ nombre, cod_erp }) => ({ id: cod_erp, nombre }))] ?? [CONSTANT.unselectedObject]
    })
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const tiposPagoErp = await axios(CONSTANT.fetchTiposPagoErp,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...Object.entries(data).map(([k, v]) => ({ id: k, nombre: v }))] ?? [CONSTANT.unselectedObject])

    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { cuentasCajaErp, tiposPagoErp }
}


const Form = () => {
  const [imagen, setImagen] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.initialFormState)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const { id } = useParams()


  // EFECTO QUE SETEA LOS VALORES DE LOS SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // EFFECTO QUE SETEA form
  useEffect(() => {
    if (!id || !selectFilter.cuentasCajaErp.length || !selectFilter.tiposPagoErp.length) return

    axios(CONSTANT.queryUrl + `/${id}`,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        const tipo_pago = selectFilter.tiposPagoErp.find(({ id }) => id === data?.tipo_pago_erp)
        const cuenta_caja = selectFilter.cuentasCajaErp.find(({ id }) => id === data?.cuenta_caja_erp)

        setForm({
          ...data,
          cuenta_caja_erp: `${cuenta_caja?.id ?? '*NULL*'}%-%${cuenta_caja?.id ?? '*NULL*'} | ${cuenta_caja?.nombre ?? 'Sin selección'}`,
          tipo_pago_erp: `${tipo_pago?.id ?? '*NULL*'}%-%${tipo_pago?.nombre ?? 'Sin selección'}`
        })
      })
      .catch(err => console.error(err))

    return () => {
      setForm(null)
    }
  }, [id, selectFilter.cuentasCajaErp, selectFilter.tiposPagoErp])


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    let url = id ? CONSTANT.queryUrl + `/${id}` : CONSTANT.queryUrl

    if (!form.cuenta_caja_erp.length || form.cuenta_caja_erp.includes('*NULL*')) return dispatch(fireToaster({ title: 'La cuenta de caja es obligatoria', icon: 'warning' }))
    if (!form.nombre.length) return dispatch(fireToaster({ title: 'El nombre es obligatorio', icon: 'warning' }))
    if (!imagen.length && !id) return dispatch(fireToaster({ title: 'La imagen es obligatoria', icon: 'warning' }))

    let data = new FormData()
    data.append('nombre', form.nombre)
    imagen.length && data.append('imagen', imagen?.[0].file)
    data.append('cuenta_caja_erp', idExtractor(form.cuenta_caja_erp, true))
    data.append('estatus', form.estatus ? 1 : 0)
    form.tipo_pago_erp.length && !form.tipo_pago_erp.includes('*NULL*') && data.append('tipo_pago_erp', idExtractor(form.tipo_pago_erp, true))
    id && data.append('_method', 'PUT')

    axios(url, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'multipart/form-data',
        _method: id ? 'PUT' : 'POST'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto" >
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {id && !form.id &&
          <CustomLoader blocking={'partial'} />
        }

        {selectFilter.cuentasCajaErp.length && selectFilter.tiposPagoErp.length
          ? <CustomForm size="" title={id ? CONSTANT.componentTitleEdit : CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                labelText='Estatus'
                name='estatus'
                value={form.estatus}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <SelectFilter
                altSeparator
                labelText='* Cuenta caja'
                name='cuenta_caja_erp'
                value={form.cuenta_caja_erp?.split('%-%')[1] ?? 'Seleccione'}
                handleValue={handleInputChange}
                optionObj={selectFilter.cuentasCajaErp.map(({ id, nombre }) => ({ id: id ?? '*NULL*', name: `${id} | ${nombre}` ?? 'Error' }))}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='* Nombre'
                name='nombre'
                value={form.nombre}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <SelectFilter
                altSeparator
                labelText='Tipo pago'
                name='tipo_pago_erp'
                value={form.tipo_pago_erp?.split('%-%')[1] ?? 'Seleccione'}
                handleValue={handleInputChange}
                optionObj={selectFilter.tiposPagoErp.map(({ id, nombre }) => ({ id: id ?? '*NULL*', name: nombre ?? 'Error' }))}
                size='col-12'
                sizeDesk='col-md-6'
              />

              {id && form.id &&
                <div className="input-inset col-12">
                  <label className="form-label">Imagen actual</label>
                  <div className="form-outline border rounded-2 lista-detalle mb-2 d-flex justify-content-center p-2 w-fit">
                    <img src={BASE_URL + 'storage/' + form.imagen} alt={`img-${form.nombre}`} />
                  </div>
                </div>
              }

              <FileUploaderB handleValue={setImagen} value={imagen} labelText='* Imagen' />

            </div>

            <Button
              type="submit"
              text={id ? 'Editar' : 'Crear'}
              color="primary"
              icon="Check"
              onClick={(e) => handleFetch(e)}
            />
          </CustomForm>

          : <CustomLoader />
        }


      </div >
    </div >
  );
};

export default Form;

import { CLEAR_MODAL_PRODUCTOS, DISPLAY_MODAL, FILTER_ADD_CLAVE, FILTER_REMOVE_CLAVE, FILTROS_PERSISTENTES_MODAL_PRODUCTOS, RESULTADOS_MODAL_PRODUCTOS } from "../redux-types";

const initialState = {
  display: false,
  resultados: null,
  filtros_productos: {}
}

export const modalProductosFiltrosReducer = (state = initialState, action) => {

  switch (action.type) {
    case FILTER_ADD_CLAVE:
      const [valorId, valorName, keyName] = action.payload.split('-')
      const isListedClave = Object.keys(state.filtros_productos).find(el => el === keyName)

      return !isListedClave
        ? {
          ...state,
          filtros_productos: !isListedClave
            ? {
              ...state.filtros_productos,
              [keyName]: [action.payload]
            }
            : { ...state.filtros_productos }

        }
        : {
          ...state,
          filtros_productos: {
            ...state.filtros_productos,
            [keyName]: state?.filtros_productos[keyName]?.find(el => el === action.payload)
              ? [...state.filtros_productos[keyName]]
              : [...state.filtros_productos[keyName], action.payload]
          }

        }

    case FILTER_REMOVE_CLAVE:
      const [valorIdRemove, valorNameRemove, keyNameRemove] = action.payload.split('-')

      return {
        ...state,
        filtros_productos: {
          ...state.filtros_productos,
          [keyNameRemove]: state?.filtros_productos[keyNameRemove].filter(el => el !== action.payload)
        }

      };

    case DISPLAY_MODAL:
      return {
        ...state,
        display: action.payload
      };

    case RESULTADOS_MODAL_PRODUCTOS:
      return {
        ...state,
        resultados: action.payload
      };

    case FILTROS_PERSISTENTES_MODAL_PRODUCTOS:
      return {
        ...state,
        filtros_productos: action.payload
      };

    case CLEAR_MODAL_PRODUCTOS:
      return {
        ...state,
        display: false,
        filtros_productos: {}
      };

    default:
      return state;
  }
}
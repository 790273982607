import { useEffect, useState } from 'react'


// Redux
import { useSelector } from 'react-redux'


// Components
import ProductosAdicionalesFiltros from '../../../components/AccordionFilter/ProductosAdicionales'
import Button from '../../../components/Button'


// Layouts
import AccordionTable from '../../../layout/AccordionTable'


const CONSTANT = {
	path: 'adicionales',
	title: 'Adicionales'
}


const Adicionales = () => {
	const { resultados: response } = useSelector(state => state.productosAdicionales)
	const [r, setR] = useState([])
	const [adicionales, setAdicionales] = useState([])
	const [resultadosFormatted, setResutadosFormatted] = useState([])

	
	useEffect(() => {
		setResutadosFormatted(null)
		setAdicionales([])
		if (!response) {
			setAdicionales([])
			return setR([])
		}
		setR(response?.puntos_entregas_productos ?? []) //TODO: decirle a Ricardo que arregle la key
	}, [response])


	// EFECTO QUE SETEA EL HEADER DE ADICIONALES DE LA TABLA
	useEffect(() => {
		const adicionalesArr = []

		r.forEach(PA => {
			Object.entries(PA).forEach(([k, v]) => {
				if (k === 'neu_producto_adicionales') {
					v.forEach(ap => {
						if (!adicionalesArr.length) adicionalesArr.push(ap)
						adicionalesArr.find(({ adicional_id }) => ap.adicional_id === adicional_id) ?? adicionalesArr.push(ap)
					})
				}
			})
		})

		setAdicionales(adicionalesArr);
	}, [r])


	// EFECTO QUE RELACIONA LOS ADICIONALES CON EL PRODUCTO EN LA TABLA
	useEffect(() => {
		const newResultados = r?.map(productoOferta => {
			let newObj = {
				...productoOferta,
				neu_producto_adicionales: adicionales.map(() => ({}))
			}

			adicionales.forEach((currentAdicional, i) => {
				const isIncludedOferta = productoOferta.neu_producto_adicionales.find(el => el.adicional_id === currentAdicional.adicional_id)

				if (isIncludedOferta) {
					newObj.neu_producto_adicionales.splice(i, 1, isIncludedOferta)
					newObj = {
						...newObj,
						neu_producto_adicionales: newObj.neu_producto_adicionales
					}
				}
			})

			return newObj
		}) ?? []

		setResutadosFormatted(newResultados)
	}, [adicionales, r])


	return (
		<div className="row">
			<div className='d-flex justify-content-between mb-1'>
				<h3>{CONSTANT.title} - Listado</h3>

				<div className='d-flex gap-1'>
					<div className='w-fit'>
						<Button text='Asignación masiva' className='m-auto p-50' color='success' url={`/productos/${CONSTANT.path}/asignacion-masiva`} isLink />
					</div>
					<div className='w-fit'>
						<Button text='Edición masiva' className='m-auto p-50' color='warning' url={`/productos/${CONSTANT.path}/edicion-masiva`} isLink />
					</div>
				</div>
			</div>

			<div className="col-12 mx-auto">
				{/* COMPONENTE FILTROS - PRODUCTOS */}
				<ProductosAdicionalesFiltros type='visualizar' />

				{/* TABLA DE RESULTADOS PRODUCTOS - ADICIONALES */}
				<AccordionTable
					title={`Tabla de resultados (${r?.length ?? 0})`}
				>
					<div className='d-flex'>

						{/* PRODUCTOS */}
						<div
							className={`card-datatable table-responsive pt-0 custom-scrollbar overflow-auto shadow flex-shrink-0 dataTable w-100`}
						>
							<table className="user-list-table table position-relative no-footer dtr-column z-10">
								<thead className="table-light position-sticky top-0 z-100" >
									<tr role="row">
										{resultadosFormatted &&
											<th className='border-end position-sticky left-0 z-100 w-fit' style={{ height: '85px', width: '180px' }}>
												<p className='text-center mt-2'>Producto</p>
											</th>
										}

										{adicionales?.map(({ neu_adicional }, i) => (
											<th style={{ height: '85px', minWidth: '210px' }} className='border-end' key={`${i}-${neu_adicional?.nombre}`}>
												<div className='mx-auto w-fit my-auto'>
													<a href={`/adicionales/detalle/${neu_adicional?.id}`} target='_blank' rel='noreferrer'>
														<p className='my-0'>{neu_adicional?.nombre ?? 'N/A'} <span className='tag px-50 sub-text-4'>{neu_adicional?.estatus ? 'Activo' : 'Inactivo'}</span></p>
													</a>
													<p className='sub-text-4 my-0'>{neu_adicional?.neu_adicional?.nombre ?? 'N/A'}</p>
												</div>
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{resultadosFormatted?.length
										? resultadosFormatted?.map(data => {
											return (
												<tr className="odd" key={`product-${data?.producto_id}-${data?.producto}`}>
													{/* RESUMEN PRODUCTO - DETALLE */}
													<td className='p-0 position-sticky left-0 border-end z-10'>
														<div className='px-2 py-1 bg-product'>
															<div className='my-0 d-flex flex-column'>
																<span>
																	<a href={`/productos/detalle/${data?.id}`} target='_blank' rel='noreferrer'>
																		<b>{data?.producto.toUpperCase()}</b>
																	</a>
																</span>
															</div>
															<div className='my-0 sub-text-3'>{data?.categoria}</div>
															<div className='my-0 sub-text-3'>{data?.sku}</div>
															<span className={`my-auto tag px-50 sub-text-3 w-fit ${data?.publicado ? 'success' : 'red'}`}>{data?.publicado ? 'Publicado' : 'Sin publicar'}</span>
														</div>
													</td>
													{data?.neu_producto_adicionales.map((adicional, i) => (
														<td className='bg-white border-end' key={`${i}-adicional-${data?.producto}`}>
															{Object.entries(adicional).length
																? <div className='m-auto w-fit z-10 '>
																	<div className='d-flex'>
																		<span className={`tag ${adicional?.estatus ? 'success' : 'red'}  px-50 mx-auto`}>{adicional?.estatus ? 'Activo' : 'Inactivo'}</span>
																	</div>
																	<div><b>$ {adicional?.precio ?? 'N/A'}</b></div>
																	{/* <div>Editar</div> */}
																</div>
																: <p className='text-center'>N/A</p>
															}
														</td>
													))}
												</tr>
											)
										})
										: <tr><td colSpan={2}><p className='text-center fw-bolder fs-2 mt-1'>Realice una búsqueda</p></td></tr>
									}
								</tbody>
							</table>
						</div>
					</div>

				</AccordionTable>
			</div>
		</div >
	)
}

export default Adicionales
import React, { useEffect, useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { FILTER_ADD_PRODUCTO, FILTER_ADD_PRODUCTO_PROVEEDOR, FILTER_ADD_PROVEEDOR_CATEGORIA, FILTER_BUSQUEDA } from '../../redux/redux-types'
import { fireToaster } from '../../redux/actions/toaster'


// Components
import SelectFilter from '../SelectFilter'
import FiltroAplicado from './FiltroAplicado'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import axios from 'axios'
import { idExtractor } from '../../helpers/idExtractor'
import { lastPosition } from '../../helpers/lastPosition'
import { clearFilterProductosPropiosProveedor, nombreFilter, productoFilter, productoProveedorFilter, resultadosProductosPropiosProveedor } from '../../redux/actions/productosPropiosProveedor'
import FormInput from '../FormInput'
import CustomLoader from '../CustomLoader'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'


const CONSTANT = {
  reduxStateSelector: 'productosPropiosProveedor',
  queryUrlGetAll: (results) => getAll.productos_propios_proveedor + `/listar?limit=${results}`,
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  errorObject: { id: '*NULL*', nombre: 'Error' },
  redirectUrl: '/proveedores/productos-propios-proveedor',
  title: 'Búsqueda de productos propios',

  reduxClearFilters: clearFilterProductosPropiosProveedor,
  reduxSetResults: resultadosProductosPropiosProveedor,

  fetchProductosProveedores: getAll.productos_proveedores + `/listar`,
  fetchProductos: getAll.productos + `/listar?limit=99999&asignacion_masiva=true&bodegas_con_saldo=0&stock_erp=0`,

  selectFilter: {
    productosProveedores: [],
    productos: []
  }
}


const handleSelectFiltersData = async () => {
  const productosProveedores = await axios(CONSTANT.fetchProductosProveedores, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
    method: 'POST'
  })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data])
    .catch(err => {
      console.error(err)
      return [CONSTANT.errorObject]
    })

  const productos = await axios(CONSTANT.fetchProductos, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
    method: 'POST'
  })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.productos?.data])
    .catch(err => {
      console.error(err)
      return [CONSTANT.errorObject]
    })

  return { productosProveedores, productos }
}


const dataFormatter = (filtrosObj) => {
  let data = {}

  Object.entries(filtrosObj).forEach(([key, value]) => {
    if (['nombre'].includes(key) && value.length) {
      data = {
        ...data,
        [key]: value
      }
    }

    if (['producto_proveedor_id', 'producto_id'].includes(key) && value.length) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          [key]: value.map(str => idExtractor(str))
        }
      }
    }
  })

  return data
}


const ProductosPropiosProveedorFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const dispatch = useDispatch()
  const { producto_id, producto_proveedor_id, nombre } = filtros

  const [isConsulting, setIsConsulting] = useState(false)


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // EFFECTO QUE HACE LA CONSULTA A LA BBDD
  useEffect(() => {
    axios(CONSTANT.queryUrlGetAll(showing_results), {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
      .catch(err => console.error(err))
    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [dispatch, showing_results])


  // FUNCION MANEJADORA QUE CONSULTA LA BBDD CON LOS FILTROS
  const handleFetchFilter = async (reset = false) => {
    let url = CONSTANT.queryUrlGetAll(showing_results) + queryParamsFormatter({ nombre })
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    }

    setIsConsulting(true)

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return axios(url, config)
        .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
        .catch(err => {
          console.error(err)
          dispatch(CONSTANT.reduxSetResults([]))
        })
        .finally(() => {
          setIsConsulting(false)
        })
    }

    config = { ...config, data: dataFormatter(filtros) }

    await axios(url, config)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
      })
      .catch(err => {
        console.error(err)

        const { errores } = err.response.data
        let detalles = []

        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  // MANEJADORES DE FILTRO - ADICIONALES
  const handleAddFilter = (e, filter) => {
    const { value } = e.target
    if (value.includes('*NULL*')) return

    switch (filter) {
      case FILTER_ADD_PRODUCTO:
        dispatch(productoFilter(value))
        break;

      case FILTER_ADD_PRODUCTO_PROVEEDOR:
        dispatch(productoProveedorFilter(value))
        break;

      case FILTER_BUSQUEDA:
        dispatch(nombreFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      {selectFilter.productos.length && selectFilter.productosProveedores.length
        ? <ListadoHeader
          classes='border rounded-2'
          title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
          handleDisable={isConsulting}
          handleClickSearch={() => handleFetchFilter()}
          handleClickClearFilter={() => handleFetchFilter(true)}
        >
          <>
            <FormInput
              labelText="Nombre"
              placeholder='Búsqueda...'
              name="nombre"
              handleValue={(e) => handleAddFilter(e, FILTER_BUSQUEDA)}
              size="col-12"
              sizeDesk="col-md-4"
              value={nombre}
            />

            <SelectFilter
              labelText="Producto-Proveedor"
              name="producto_proveedor_id"
              handleValue={(e) => handleAddFilter(e, FILTER_ADD_PRODUCTO_PROVEEDOR)}
              optionObj={selectFilter?.productosProveedores?.map(({ descripcion, nombre, sku_proveedor, id }) => ({ name: `${descripcion ?? nombre ?? 'N/A'} | ${sku_proveedor ?? 'N/A'}`, id }))}
              size="col-12"
              sizeDesk="col-md-4"
              value={lastPosition(producto_proveedor_id)}
            />

            <SelectFilter
              labelText="Productos"
              name="producto_id"
              handleValue={(e) => handleAddFilter(e, FILTER_ADD_PRODUCTO)}
              optionObj={selectFilter?.productos?.map(({ nombre, sku, id }) => ({ name: `${nombre ?? 'N/A'} | ${sku ?? 'N/A'}`, id }))}
              size="col-12"
              sizeDesk="col-md-4"
              value={lastPosition(producto_id)}
            />
          </>

          {/* FILTROS APLICADOS - ADICIONALES */}
          <p className='mb-25 text-black'>Filtros aplicados</p>
          <div className='row'>
            <FiltroAplicado array={producto_id} func={productoFilter} title={'Productos'} />
            <FiltroAplicado array={producto_proveedor_id} func={productoProveedorFilter} title={'Producto-Proveedor'} />
          </div>

          <hr className='my-1' />

        </ListadoHeader>

        : <CustomLoader />
      }


    </div>
  )
}

export default ProductosPropiosProveedorFiltros
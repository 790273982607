import { BASE_URL, PATH_URL } from "..";

export const create = {
   paises: BASE_URL + PATH_URL + '/paises',
   parametros_recargos: BASE_URL + PATH_URL + '/parametros-recargos',
   datos_facturacion: BASE_URL + PATH_URL + '/datos-facturacion',
   productos_proveedor: BASE_URL + PATH_URL + '/productos-proveedor',
   productos_propios_proveedor: BASE_URL + PATH_URL + '/productos-propios-proveedor',
   contactos_punto_entrega: BASE_URL + PATH_URL + '/contactos-punto-entrega',
   bodegas_punto_entrega: BASE_URL + PATH_URL + '/bodegas-punto-entrega',
   eventos: BASE_URL + PATH_URL + '/eventos',
   eventos_banner: BASE_URL + PATH_URL + '/banners-evento',
   eventos_pasos_compra: BASE_URL + PATH_URL + '/pasos-evento',
   eventos_faq: BASE_URL + PATH_URL + '/faqs-evento',
   usuarios: BASE_URL + PATH_URL + '/usuarios',
   usuarios_roles: BASE_URL + PATH_URL + '/usuarios-roles',
   distritos: BASE_URL + PATH_URL + '/distritos',
   nota_venta: BASE_URL + PATH_URL + '/erp/notas-ventas/crear-desde-pedido',
   pedidos: BASE_URL + PATH_URL + '/pedidos',
   ciudades: BASE_URL + PATH_URL + '/ciudades',
   garantia_respuestas: BASE_URL + PATH_URL + '/garantia-respuestas',
   garantia: BASE_URL + PATH_URL + '/garantias',
   agendamientos: BASE_URL + PATH_URL + '/agendamientos',
   pago_pedido: BASE_URL + PATH_URL + '/pagos-pedido',
   productos: BASE_URL + PATH_URL + '/productos',
   imagenes_productos: BASE_URL + PATH_URL + '/imagenes-productos',
   adicionales: BASE_URL + PATH_URL + '/adicionales',
   ofertas: BASE_URL + PATH_URL + '/ofertas',
   cotizaciones: BASE_URL + PATH_URL + '/cotizaciones',
   region: BASE_URL + PATH_URL + '/regiones',
   tipo_elemento: BASE_URL + PATH_URL + '/tipos-elementos',
   categorias_faq: BASE_URL + PATH_URL + '/categorias-faq',
   subcategorias_faq: BASE_URL + PATH_URL + '/subcategorias-faq',
   faq: BASE_URL + PATH_URL + '/preguntas-frecuentes',
   estatus_pedido: BASE_URL + PATH_URL + '/estatus-pedido',
   cinta_informativa: BASE_URL + PATH_URL + '/cinta-informativa',
   codigos_descuento: BASE_URL + PATH_URL + '/codigos-descuento',
   tipos_facturacion: BASE_URL + PATH_URL + '/tipos-facturacion',
   categorias: BASE_URL + PATH_URL + '/categorias',
   banners: BASE_URL + PATH_URL + '/banners',
   etiquetas: BASE_URL + PATH_URL + '/etiquetas',
   metadatos: BASE_URL + PATH_URL + '/metadatos',
   pasos_compra: BASE_URL + PATH_URL + '/pasos-compra',
   tipos_destino: BASE_URL + PATH_URL + '/tipos-destino',
   tipos_atencion: BASE_URL + PATH_URL + '/tipos-atencion',
   metodos_entrega: BASE_URL + PATH_URL + '/metodos-entrega',
   menus: BASE_URL + PATH_URL + '/menus',
   roles: BASE_URL + PATH_URL + '/roles',
   metodos_pago: BASE_URL + PATH_URL + '/metodos-pago',
   formas_pago: BASE_URL + PATH_URL + '/formas-pago',
   puntos_entrega: BASE_URL + PATH_URL + '/puntos-entrega',
   dias: BASE_URL + PATH_URL + '/dias',
   agendas: BASE_URL + PATH_URL + '/agendas',
   dias_agenda: BASE_URL + PATH_URL + '/dias-agenda',
   horas_agenda: BASE_URL + PATH_URL + '/horas-agenda',
   divisas: BASE_URL + PATH_URL + '/divisas',
   factores_diferenciadores: BASE_URL + PATH_URL + '/factores-diferenciadores',
   criterios_comparacion: BASE_URL + PATH_URL + '/criterios-comparacion',
   terminos_condiciones: BASE_URL + PATH_URL + '/terminos-condiciones',
   unidades: BASE_URL + PATH_URL + '/unidades',
   claves_atributos: BASE_URL + PATH_URL + '/claves-atributos',
   valores_atributos: BASE_URL + PATH_URL + '/valores-atributos',
   proveedores: BASE_URL + PATH_URL + '/proveedores',
   proveedores_categorias: BASE_URL + PATH_URL + '/proveedores-categorias',
   comunas: BASE_URL + PATH_URL + '/comunas'
}
import axios from "axios";

// Redux
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import TextareaInput from "../../components/TextareaInput";
import SelectFilter from "../../components/SelectFilter";


// Hooks
import { useForm } from "../../hooks/useForm";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { create } from "../../endpoints/create";
import { useEffect, useState } from "react";
import { getAll } from "../../endpoints/getAll";


const categorias = {
  nombre: '',
  tipo_elemento_id: '',
  descripcion: '',
  estatus: 1,
};

const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [tiposElementos, setTiposElementos] = useState(null)
  const [{ nombre, tipo_elemento_id, descripcion, estatus }, handleInputChange, reset] = useForm(categorias);

  const [isConsulting, setIsConsulting] = useState(false)


  useEffect(() => {
    axios(getAll.tipos_elementos + '/buscar',
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST'
      })
      .then(res => setTiposElementos(res.data.tipos_elementos.data))
      .catch(err => console.error(err))
  }, [])


  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)

    let data = {
      nombre, tipo_elemento_id: tipo_elemento_id.split('-')[0], descripcion, estatus: Number(estatus)
    }

    axios(create.categorias,
      {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
          'Content-Type': 'application/json'
        },
        data
      })
      .then(res => {
        reset()

        const toasterContent = {
          title: 'Operación realizada',
          text: `Categoría: ${res.data.nombre} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate('/productos/categorias')
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() =>
        setIsConsulting(false)
      )
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text='Categorías' icon='ArrowLeft' style='flat' isLink url='/productos/categorias' />
        </div>

        <CustomForm size="" title="Crear categoría">
          <div className="row">
            <Switch
              name="estatus"
              value={estatus}
              labelText="Estatus"
              handleValue={handleInputChange}
              size="col-12"
            />

            <FormInput
              name="nombre"
              value={nombre}
              labelText="* Nombre"
              handleValue={handleInputChange}
              size="col-12"
              sizeDesk='col-md-6'
            />

            <SelectFilter
              name="tipo_elemento_id"
              value={tipo_elemento_id.split('-')[1]}
              labelText="* Tipo elemento"
              handleValue={handleInputChange}
              optionObj={tiposElementos?.map(({ nombre, id }) => ({ id, name: nombre }))}
              size="col-12"
              sizeDesk='col-md-6'
            />

            <TextareaInput
              name="descripcion"
              value={descripcion}
              labelText="Descripción"
              handleValue={handleInputChange}
              size="col-12"
              rows={2}
              sizeDesk='col-md-12'
            />
          </div>

          <Button disabled={isConsulting} type="submit" text="Crear" color="primary" icon="Plus" onClick={(e) => handleFetch(e)} />
        </CustomForm>
      </div>
    </div>
  );
};

export default Create;

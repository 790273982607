import axios from 'axios'
import { useEffect, useState } from 'react'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import Switch from '../../components/Switch'
import CustomLoader from '../../components/CustomLoader'


// Router Dom
import { useNavigate, useParams } from 'react-router-dom'


// Endpoints
import { getOne } from '../../endpoints/getOne'
import { getAll } from '../../endpoints/getAll'
import capitalize from '../../helpers/capitalize'
import CheckBoxInput from '../../components/CheckboxInput'
import { BASE_URL, PATH_URL } from '../../endpoints'


const CONSTANT = {
  queryUrlGetOne: getOne.roles,
  title: 'Roles',
  redirectUpdate: (id) => `/roles/update/${id}`,
  redirectListado: '/roles',
  fetchPermisos: getAll.permisos,
  urlRolesPermisos: BASE_URL + PATH_URL + '/roles-permisos',
}


const Detalle = () => {
  const [data, setData] = useState(null)
  const [permisos, setPermisos] = useState({})
  const { id } = useParams()
  const dispatch = useDispatch()
  const redirect = useNavigate()


  // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
  useEffect(() => {
    axios(CONSTANT.fetchPermisos,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        let permisos = {}

        data.forEach(({ id, group, name, user_type }) => {
          if (permisos?.[group]?.length) {
            return permisos = {
              ...permisos,
              [group]: [
                ...permisos[group],
                { id, name: name.split('.')[1], user_type, group, selected: false }
              ]
            }
          }

          return permisos = {
            ...permisos,
            [group]: [{ id, name: name.split('.')[1], user_type, group, selected: false }]
          }
        })

        setPermisos(permisos)

        axios(CONSTANT.queryUrlGetOne(id),
          {
            headers: {
              'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
          })
          .then(({ data }) => {
            setData(data)

            setPermisos(permisos => {
              let permisosSetter = {}

              Object.entries(permisos).forEach(([group, permisosArr]) => {
                permisosArr.forEach(permiso => {
                  const isListed = data?.permissions?.find(listedPermiso => {
                    const listedName = listedPermiso.name.split('.')[1]
                    return listedName === permiso.name && listedPermiso.group === permiso.group
                  })
                  if (permisosSetter?.[group]?.length) {
                    return permisosSetter = {
                      ...permisosSetter,
                      [group]: [...permisosSetter[group], { ...permiso, selected: isListed ? true : false }]
                    }
                  }

                  permisosSetter = {
                    ...permisosSetter,
                    [group]: [{ ...permiso, selected: isListed ? true : false }]
                  }
                })
              })

              return permisosSetter
            })
          })
          .catch(err => console.error(err))
      })
      .catch(err => console.error(err))

    return () => {
      setData(null)
      setPermisos({})
    }
  }, [id])


  // FUNCIÓN MANEJADORA PARA ACTUALIZACIÓN DEL ESTATUS
  const handleFetch = async (objData, target) => {
    let config
    let url = CONSTANT.queryUrlGetOne(objData.id)

    if (target === 'estatus') {
      let newEstatus = objData[target] ? 0 : 1

      config = {
        data: {
          method: 'POST',
          [target]: newEstatus,
          _method: 'PUT'
        }
      }
    }

    if (target === 'delete') {
      if (!window.confirm('¿Estás seguro que desea borrar el registro?')) return
      config = {
        method: 'DELETE',
      }
    }

    if (target === 'permiso') {
      url = CONSTANT.urlRolesPermisos
      config = {
        method: objData.selected ? 'DELETE' : 'POST',
        data: {
          rol_id: data.id,
          permiso_id: objData.id
        }
      }
    }

    config = {
      ...config,
      headers: {
        ...config.headers,
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    }

    await axios(url, config)
      .then(res => {
        let toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${target} actualizado con éxito`,
          icon: 'success'
        }

        if (target === 'delete') {
          toasterContent = {
            title: 'Operación realizada',
            text: `Registro borrado con éxito`,
            icon: 'success'
          }

          return dispatch(fireToaster(toasterContent))
        }

        dispatch(fireToaster(toasterContent))
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
           Operación fallida
           (${err.response.status} - ${err.response.statusText})
         `,
          html: `
           <b>Detalle: </b>
           ${detalles.map(error => `<br /><i>-${error}</i>`)}
         `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
      })

    if (target === 'delete') return redirect(CONSTANT.redirectListado)

    window.location.reload() //TODO: mejorar a recarga SPA
  }

  return (
    <div className='row'>
      <div className='col-12 mx-auto'>
        <div className='col mb-50'>
          <div className='d-flex gap-1'>
            <div className='col'>
              <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
            </div>
            <Button text='Editar' icon='Settings' color='success' isLink url={CONSTANT.redirectUpdate(id)} />
            <Button text='Borrar' icon='Trash' color='danger' onClick={() => handleFetch(data, 'delete')} />
          </div>
        </div>

        {data
          ? <div className='row'>
            <div className='col col-md-6 mb-2'>

              <Accordion
                title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
                isOpen
              >
                <AccordionDetailData dataKey={'ID'} value={data?.id} />
                <AccordionDetailData dataKey={'Nombre'} value={data?.name ?? 'N/A'} />
                <AccordionDetailData dataKey={'Guard Name'} value={data?.guard_name ?? 'N/A'} />
                <AccordionDetailData dataKey={'User Type'} value={data?.user_type ?? 'N/A'} />
                <AccordionDetailData dataKey={'Estatus'} value={
                  <Switch
                    value={data?.estatus}
                    labelText={""}
                    margin='my-0'
                    handleValue={() => handleFetch(data, 'estatus')}
                  />}
                />
                <AccordionDetailData dataKey={'Creación'} value={data?.created_at?.split('T')[0] ?? 'N/A'} />
                <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at?.split('T')[0] ?? 'N/A'} last />
              </Accordion>

            </div>

            <div className="col-12">

              <div className='accordion mb-1 gap-1 justify-content-around bg-white rounded border'>
                <p className='text-center fw-bold fs-2 mt-2'>Permisos</p>
                <hr className='mb-2 mx-1' />
                <div className='row justify-content-center gap-1'>
                  {Object.entries(permisos).length
                    ? Object.entries(permisos).map(([k, v]) => (
                      <div key={k} className="col-3 mt-1 border rounded p-1">
                        <p className="fw-bold fs-3 text-center">{capitalize(k)}</p>

                        {v.map(permiso => (
                          permiso.user_type === data.user_type &&
                          <div className="col-12" key={`cb-${permiso.id}-${permiso.name}`}>
                            <CheckBoxInput
                              name={`cb%-%${permiso.id}%-%${permiso.name}`}
                              handleValue={() => handleFetch(permiso, 'permiso')}
                              labelText={`${permiso.name}`}
                              value={permiso?.selected}
                            />
                          </div>
                        ))}
                      </div>
                    )).filter(el => el !== null)

                    : <span>No hay permisos...</span>
                  }
                </div>
              </div>
            </div>
          </div>


          : <CustomLoader />
        }
      </div>
    </div>
  )
}

export default Detalle
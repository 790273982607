import {
  FILTER_EMAIL_CLIENTE,
  FILTER_NOMBRE_CLIENTE,
  FILTER_APELLIDO_CLIENTE,
  FILTER_TELEFONO_CLIENTE,
  FILTER_ADD_PUNTO_ENTREGA,
  FILTER_REMOVE_PUNTO_ENTREGA,
  FILTER_ADD_METODO_PAGO,
  FILTER_REMOVE_METODO_PAGO,
  FILTER_CLEAR,
  FILTER_OBSERVACIONES,
  FILTER_RUT_EMPRESA,
  FILTER_REMOVE_TIPO_ATENCION,
  FILTER_ADD_TIPO_ATENCION,
  FILTER_ADD_FORMA_PAGO,
  FILTER_REMOVE_FORMA_PAGO,
  FILTER_REMOVE_FACTURACION,
  FILTER_ADD_FACTURACION,
  FILTER_DIAS_VIGENCIA,
} from "../redux-types";

const initialState = {
  filters: {
    rut_empresa: "",
    nombre_cliente: "",
    apellido_cliente: "",
    email_cliente: "",
    telefono_cliente: "",
    facturacion: [],
    punto_entrega: [],
    tipo_atencion: [],
    forma_pago: [],
    metodo_pago: [],
    dias_vigencia: "",
    observaciones: "",
  },
};

export const generarCotizacionReducer = (state = initialState, action) => {
  const isListedMetodoPago = state.filters.metodo_pago.find((el) => el === action.payload);
  const isListedFacturacion = state.filters.facturacion.find((el) => el === action.payload);
  const isListedPuntoEntrega = state.filters.punto_entrega.find((el) => el === action.payload);
  const isListedFormaPago = state.filters.forma_pago.find((el) => el === action.payload);
  const isListedTipoAtencion = state.filters.tipo_atencion.find((el) => el === action.payload);

  switch (action.type) {
    case FILTER_ADD_FORMA_PAGO:
      return {
        ...state,
        filters: {
          ...state.filters,
          forma_pago: isListedFormaPago ? state.filters.forma_pago : [...state.filters.forma_pago, action.payload],
        },
      };

    case FILTER_REMOVE_FORMA_PAGO:
      return {
        ...state,
        filters: {
          ...state.filters,
          forma_pago: state.filters.forma_pago.filter((el) => el !== action.payload),
        },
      };

    case FILTER_ADD_TIPO_ATENCION:
      return {
        ...state,
        filters: {
          ...state.filters,
          tipo_atencion: isListedTipoAtencion ? state.filters.tipo_atencion : [...state.filters.tipo_atencion, action.payload],
        },
      };

    case FILTER_REMOVE_TIPO_ATENCION:
      return {
        ...state,
        filters: {
          ...state.filters,
          tipo_atencion: state.filters.tipo_atencion.filter((el) => el !== action.payload),
        },
      };

    case FILTER_ADD_FACTURACION:
      return {
        ...state,
        filters: {
          ...state.filters,
          facturacion: isListedFacturacion ? state.filters.facturacion : [...state.filters.facturacion, action.payload],
        },
      };

    case FILTER_REMOVE_FACTURACION:
      return {
        ...state,
        filters: {
          ...state.filters,
          facturacion: state.filters.facturacion.filter((el) => el !== action.payload),
        },
      };

    case FILTER_ADD_METODO_PAGO:
      return {
        ...state,
        filters: {
          ...state.filters,
          metodo_pago: isListedMetodoPago ? state.filters.metodo_pago : [...state.filters.metodo_pago, action.payload],
        },
      };

    case FILTER_REMOVE_METODO_PAGO:
      return {
        ...state,
        filters: {
          ...state.filters,
          metodo_pago: state.filters.metodo_pago.filter((el) => el !== action.payload),
        },
      };

    case FILTER_ADD_PUNTO_ENTREGA:
      return {
        ...state,
        filters: {
          ...state.filters,
          punto_entrega: isListedPuntoEntrega ? state.filters.punto_entrega : [...state.filters.punto_entrega, action.payload],
        },
      };

    case FILTER_REMOVE_PUNTO_ENTREGA:
      return {
        ...state,
        filters: {
          ...state.filters,
          punto_entrega: state.filters.punto_entrega.filter((el) => el !== action.payload),
        },
      };

    case FILTER_RUT_EMPRESA:
      return {
        ...state,
        filters: {
          ...state.filters,
          rut_empresa: action.payload,
        },
      };

    case FILTER_NOMBRE_CLIENTE:
      return {
        ...state,
        filters: {
          ...state.filters,
          nombre_cliente: action.payload,
        },
      };

    case FILTER_APELLIDO_CLIENTE:
      return {
        ...state,
        filters: {
          ...state.filters,
          apellido_cliente: action.payload,
        },
      };

    case FILTER_EMAIL_CLIENTE:
      return {
        ...state,
        filters: {
          ...state.filters,
          email_cliente: action.payload,
        },
      };

    case FILTER_TELEFONO_CLIENTE:
      return {
        ...state,
        filters: {
          ...state.filters,
          telefono_cliente: action.payload,
        },
      };

    case FILTER_OBSERVACIONES:
      return {
        ...state,
        filters: {
          ...state.filters,
          observaciones: action.payload,
        },
      };

    case FILTER_DIAS_VIGENCIA:
      return {
        ...state,
        filters: {
          ...state.filters,
          dias_vigencia: action.payload,
        },
      };

    case FILTER_CLEAR:
      return initialState;

    default:
      return state;
  }
};

/**Helper que valida si un RUT cumple con el patrón que se le está asignando por parámetros, retorna true o false */
export const validateRut = (rut, pattern) => {
  if (!rut || typeof rut !== 'string') {
    console.error(`Debe ingresar un RUT válido`)
    return false
  }
  if (!pattern || typeof pattern !== 'string' || !pattern?.length) {
    console.error(`Debe ingresar un patrón válido`)
    return false
  }
  const regExp = new RegExp(pattern);

  return regExp.test(rut)
}
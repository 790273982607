import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fas from '@fortawesome/free-solid-svg-icons';
import * as far from '@fortawesome/free-regular-svg-icons';
import * as fab from '@fortawesome/free-brands-svg-icons';
import * as MDI from '@mdi/js';
import Icon from '@mdi/react';
import FormInput from './FormInput';


let iconsFormat = { fa: [], mdi: [] }

const iconsDict = {
   fa: 'Font Awesome',
   mdi: 'Material Design Icons'
}

const iconsFA = { fas, far, fab }

const IconPicker = ({ classes = '', size, sizeDesk, value, handleValue, labelText = 'Ícono' }) => {
   const [allIcons, setAllIcons] = useState(iconsFormat)
   const [displayList, setDisplayList] = useState(false)
   const [currentIconsPrefix, setCurrentIconsPrefix] = useState('fa')
   const [searchInput, setSearchInput] = useState('')
   const [selectedIcon, setSelectedIcon] = useState(null)

   const handleInputSearch = (e) => {
      e.preventDefault()
      setSearchInput(e.target.value)
   }


   const handleSelectedIcon = (icon) => {
      handleValue(icon)
      setDisplayList(false)
   }


   const handleDisplayList = (e) => {
      e.preventDefault()
      setDisplayList(!displayList)
   }


   useEffect(() => {
      let iconsFormat = { fa: [], mdi: [] }

      Object.entries(iconsFA).forEach(([keyModule, valueModule]) => {
         Object.entries(valueModule).forEach(([iconKey, iconValue]) => {
            typeof iconValue === 'object' && Object.keys(iconValue).length < 10 && iconsFormat.fa.push({ prefix: keyModule, path: iconKey, iconName: iconValue.iconName, icon: iconValue })
         })
      })

      Object.entries(MDI).forEach(([key, value]) => value ? iconsFormat.mdi.push({ prefix: 'mdi', iconName: key, path: value }) : null)

      setAllIcons(iconsFormat)
   }, [])


   useEffect(() => {
      setSelectedIcon(value)
   }, [value])


   return (
      <div className={`mb-2 ${size ? size : 'col'} ${sizeDesk ? sizeDesk : 'col-md-12'}` + classes}>
         <label className='form-label'>{labelText}</label>
         <div className='icon-picker-input d-flex bg-white w-fit justify-content-between rounded-2 w-100'>
            <button
               className={`btn icon-picker px-50 py-75 ${displayList ? 'active' : ''}`}
               onClick={handleDisplayList}>
               {displayList ? 'Contraer' : 'Desplegar'} lista
            </button>
            <div className='d-flex'>
               <div className='icon-picker-square rounded-2 m-25 my-auto' key={`icon-selected`} onClick={handleDisplayList}>
                  <div className='d-flex'>
                     {selectedIcon &&
                        <>
                           {selectedIcon?.path?.includes('fa')
                              ? <FontAwesomeIcon
                                 className='p-25 m-auto'
                                 icon={iconsFA[selectedIcon.prefix][selectedIcon.path]}
                              />
                              : <Icon
                                 data-prefix='mdi'
                                 className='p-25 m-auto'
                                 path={selectedIcon.path}
                              />
                           }
                        </>
                     }
                  </div>
               </div>
            </div>
         </div>
         <div className='position-relative'>
            {displayList &&
               <div className='position-absolute icon-picker-list z-100'>
                  <div className='row'>
                     <div className='col'>
                        <div className='bg-white rounded-2 border p-1'>
                           <div className='d-flex gap-1 pb-1'>
                              <span
                                 className={`btn icon-list border rounded-2 p-50 ${currentIconsPrefix === 'fa' ? 'active' : ''}`}
                                 onClick={() => setCurrentIconsPrefix('fa')}>
                                 Font Awesome
                              </span>
                              <span
                                 className={`btn icon-list border rounded-2 p-50 ${currentIconsPrefix === 'mdi' ? 'active' : ''}`}
                                 onClick={() => setCurrentIconsPrefix('mdi')}>
                                 Material Design Icons
                              </span>
                           </div>
                           <FormInput
                              placeholder={`Buscar en ${iconsDict[currentIconsPrefix]}...`}
                              classes={'fs-1'}
                              value={searchInput}
                              handleValue={handleInputSearch}
                           />
                           <div className='icon-picker-container mh-50 overflow-auto border-top border-bottom custom-scrollbar' >
                              <div></div>
                              {currentIconsPrefix === 'fa' &&
                                 allIcons.fa.filter((icon) => icon.iconName.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())).map((icon, i) => (
                                    <div
                                       className='icon-picker-square rounded-2 m-25'
                                       key={`${i}-${icon.prefix}-${icon.iconName}`}
                                       onClick={() => handleSelectedIcon(icon)}
                                    >
                                       <div>
                                          <div className='d-flex'>
                                             <FontAwesomeIcon
                                                className='p-25 m-auto'
                                                icon={iconsFA[icon.prefix][icon.path]}
                                             />
                                          </div>
                                       </div>
                                    </div>
                                 ))
                              }

                              {currentIconsPrefix === 'mdi' &&
                                 allIcons.mdi.filter(({ iconName }) => iconName.toLocaleLowerCase().includes(searchInput)).map((icon, i) => (
                                    <div className='icon-picker-square rounded-2 m-25' key={`mdi-icon-${i}`} onClick={(e) => handleSelectedIcon(icon, MDI[icon.path])}>
                                       <div className='d-flex'>
                                          <Icon
                                             data-prefix='mdi'
                                             className='p-25 m-auto'
                                             path={icon.path}
                                          />
                                       </div>
                                    </div>
                                 ))
                              }
                           </div>
                        </div>
                     </div>
                  </div >
               </div>
            }
         </div>
      </div>
   )
}

export default IconPicker
export const contractFormat = (target, data = {}) => {
  if (!target) return new Error('Missing contract target');

  let options = {
    title: 'Banners',
  }

  switch (target) {
    case 'estatus':
      return options = {
        ...options,
        method: 'PUT',
        action: 'update',
        content_type: 'multipart/form-data',
        data: {
          estatus: !data?.estatus
        }
      }

    case 'create': {
      return options = {
        ...options,
        method: 'POST',
        action: 'create',
        content_type: 'multipart/form-data',
        data
      }
    }

    case 'detail': {
      return options = {
        ...options,
        method: 'GET',
        content_type: 'multipart/form-data',
        action: 'detail',
      }
    }

    case 'update': {
      return options = {
        ...options,
        method: 'POST',
        action: 'update',
        content_type: 'multipart/form-data',
        data
      }
    }

    case 'delete': {
      return options = {
        ...options,
        method: 'DELETE',
        action: 'delete',
      }
    }

    case 'listar': {
      return options = {
        ...options,
        method: 'POST',
        action: 'get',
        data: data.length && {
          filtros: {
            categoria_id: data.map(el => Number(el.split('-')[0]))
          }
        }
      }
    }

    default:
      break;
  }

  return options
}

import { Navigate, Route, Routes } from "react-router-dom";
import Create from "../../views/Divisas/Create";
import Listado from "../../views/Divisas/Listado";
import Update from "../../views/Divisas/Update";
import { useSelector } from "react-redux";

const DivisasRouter = () => {
  const { user } = useSelector(state => state.auth)

  return (
    <Routes>
      <Route path="/" element={<Listado />} />

      {user.permisos['divisas.crear'] &&
        <Route path="/create" element={<Create />} />
      }

      {user.permisos['divisas.editar'] &&
        <Route path="/update/:id" element={<Update />} />
      }

      <Route path='*' element={<Navigate to='/home' replace />} />
    </Routes>
  );
};

export default DivisasRouter;

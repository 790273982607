import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_TIPOS_DESTINO,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosTiposDestino = (data) => ({ type: RESULTADOS_TIPOS_DESTINO, payload: data })

export const clearFilterTiposDestino = () => ({ type: FILTER_CLEAR });

import { Route, Routes } from "react-router-dom"
import Create from "../views/Agendamientos/Create";
import Index from "../views/Agendamientos/Index";



const AgendamientosRouter = () => {
   return (
      <Routes>
         <Route path='/create' element={<Create />} />
         <Route path='*' element={<Index />} />
      </Routes>
   )
}

export default AgendamientosRouter;
import axios from "axios"

const d = {
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  authHeader: { 'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null },
}

/**
 const querieExample = [
   {
     url: 'algo.com',
     options: {},
     key: 'algo',
     hasUnselected: false
     hasNull: false
   }
 ]
 */

/**Servicio que retorna un arreglo de datos para ser usados en el componente SelectFilter */
export const fecthSelectFiltersData = async (queriesArr = []) => {
  if (!queriesArr.length) return console.warn('Nothing to fetch')

  const data = await Promise.all(
    queriesArr.map(async ({ url, options, key, hasUnselected, hasNull }) => {
      const validOptions = {
        ...options,
        headers: {
          ...options?.headers,
          ...d.authHeader
        }
      }

      return axios(url, validOptions)
        .then(({ data }) => ({ [key]: hasUnselected ? [...data, d?.unselectedObject] : [...data] }))
        .catch(err => {
          console.error(err)
          return hasNull ? [d.nullObject] : []
        })
    })
  )
    .then(res => {
      let obj = {}
      res.forEach(q => {
        Object.entries(q).forEach(([k, v]) => obj = { ...obj, [k]: v })
      })
      return obj
    })

  return data

}
import axios from 'axios'
import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { FILTER_ADD_CATEGORIA, FILTER_NOMBRE } from '../../redux/redux-types'
import { categoriaFilter, clearFilterAdicionales, filtrosPersistentesAdicionales, nombreFilter, resultadosAdicionales } from '../../redux/actions/adicionales'
import { resultadosProductos } from '../../redux/actions/productos'


// Components
import FormInput from '../FormInput'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
// import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'
import SelectFilter from '../SelectFilter'
import { lastPosition } from '../../helpers/lastPosition'
import FiltroAplicado from './FiltroAplicado'
import { idExtractor } from '../../helpers/idExtractor'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'
import { persistentFilters } from '../../helpers/pesistentFilters'


const CONSTANT = {
	queryUrlGetAll: getAll.adicionales,
	redirectUrl: '/adicionales',
	title: 'Búsqueda de adicionales',
	reduxClearFilters: (data) => clearFilterAdicionales(data),
	reduxSetProductos: resultadosProductos,
	reduxSetResults: (data) => resultadosAdicionales(data),
	queryUrlGetAllCategorias: getAll.categorias + `/buscar?limit=99999`,

	queryUrl: (results) => CONSTANT.queryUrlGetAll + `/listar?limit=${results}`
}


const dataFormatter = (filtrosObj) => {
	let data = {}

	Object.entries(filtrosObj).forEach(([key, value]) => {
		if (['categoria_id'].includes(key) && value.length) {
			data = {
				...data,
				filtros: {
					...data.filtros,
					[key]: value.map(str => idExtractor(str))
				}
			}
		}
	})

	return data
}


const AdicionalesFiltros = ({ isAsignacionMasiva = false }) => {
	const { filtros } = useSelector(state => state.adicionales)
	const { showing_results } = useSelector(state => state.listedResults)
	const [inputSelect, setInputSelect] = useState({ categorias: [] })
	const dispatch = useDispatch()
	const { nombre, categoria_id } = filtros
	const [isConsulting, setIsConsulting] = useState(false)


	// EFECTO QUE SETEA LOS SELECTFILTER
	useEffect(() => {
		axios(CONSTANT.queryUrlGetAllCategorias, {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
			},
			method: 'POST'
		})
			.then(({ data }) => setInputSelect({ categorias: data?.categorias?.data ?? [] }))
			.catch(err => {
				console.error(err)
			})
	}, [dispatch])


	// EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
	useEffect(() => {
		const filtrosPersistentesObj = persistentFilters(window.location, filtros).obj
		let url = CONSTANT.queryUrl(showing_results)
		let config = {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
			method: 'POST',
		}

		if (filtrosPersistentesObj) {
			dispatch(filtrosPersistentesAdicionales(filtrosPersistentesObj))
			url = CONSTANT.queryUrl(showing_results) + queryParamsFormatter({ nombre: filtrosPersistentesObj.nombre })
			config = { ...config, data: dataFormatter(filtrosPersistentesObj) }
		}

		axios(url, config)
			.then(({ data }) => dispatch(CONSTANT.reduxSetResults(data?.adicionales)))
			.catch(err => {
				console.error(err)
				dispatch(CONSTANT.reduxSetResults([]))
			})

		return () => dispatch(CONSTANT.reduxClearFilters())
	}, [dispatch, showing_results])


	// EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
	useEffect(() => {
		return () => {
			dispatch(CONSTANT.reduxClearFilters())
			dispatch(CONSTANT.reduxSetProductos(null))
		}
	}, [dispatch])


	// FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
	const handleFetchFilter = (reset = false) => {
		const url = CONSTANT.queryUrlGetAll + `/listar?limit=${isAsignacionMasiva ? '99999' : showing_results}`
		let config = {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			}, method: 'POST'
		}

		if (reset) {
			dispatch(CONSTANT.reduxClearFilters())
			return axios(url, config)
				.then(({ data }) => dispatch(CONSTANT.reduxSetResults(data?.adicionales)))
				.catch(err => {
					console.error(err)
					dispatch(CONSTANT.reduxSetResults([]))
				})
		}

		config = { ...config, data: dataFormatter(filtros) }
		setIsConsulting(true)

		axios(url + queryParamsFormatter({ nombre }), config)
			.then(({ data }) => {
				dispatch(CONSTANT.reduxSetResults(data?.adicionales))
				dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
				window.history.pushState({}, '', persistentFilters(window.location, filtros).urlWithFilters)
			})
			.catch(err => {
				console.error(err)
				const { errores } = err.response.data
				let detalles = []
				Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

				const toasterContent = {
					title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
					html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
					icon: 'error'
				}
				dispatch(fireToaster(toasterContent))
				dispatch(CONSTANT.reduxSetResults([]))
			})
			.finally(() => {
				setIsConsulting(false)
			})
	}


	// MANEJADORES DE FILTRO
	const handleAddFilter = (e, filter) => {
		const { value } = e.target

		switch (filter) {
			case FILTER_NOMBRE:
				dispatch(nombreFilter(value))
				break;

			case FILTER_ADD_CATEGORIA:
				dispatch(categoriaFilter(value))
				break;

			default:
				break;
		}
	}


	return (
		<div className={`card mb-50 shadow-none bg-transparent ${isAsignacionMasiva ? 'col-md-6 col-12' : 'col-12'}`}>
			<ListadoHeader
				classes='border rounded-2'
				title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
				handleDisable={isConsulting}
				handleClickSearch={() => handleFetchFilter()}
				handleClickClearFilter={() => handleFetchFilter(true)}
			>
				<>
					<FormInput
						labelText='Búsqueda'
						placeholder='Ingrese su búsqueda'
						size='col-12'
						sizeDesk={isAsignacionMasiva ? 'col-md-6' : 'col-md-4'}
						handleValue={(e) => handleAddFilter(e, FILTER_NOMBRE)}
						value={nombre}
					/>

					<SelectFilter
						labelText='Categoría'
						name='categoria_id'
						handleValue={(e) => handleAddFilter(e, FILTER_ADD_CATEGORIA)}
						optionObj={inputSelect.categorias.map(({ id, nombre }) => ({ id, name: nombre }))}
						size='col-12'
						sizeDesk={isAsignacionMasiva ? 'col-md-6' : 'col-md-3'}
						value={lastPosition(categoria_id)}
					/>
				</>

				{/* FILTROS APLICADOS - ADICIONALES */}
				<p className='mb-25 text-black'>Filtros aplicados</p>
				<div className='row'>
					<FiltroAplicado array={categoria_id} func={categoriaFilter} title={'Categorías'} />
				</div>

				<hr className='my-1' />
			</ListadoHeader>

		</div>
	)
}

export default AdicionalesFiltros
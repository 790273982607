import axios from 'axios';

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from "../../redux/actions/toaster";


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Switch from "../Switch";
import Table from "../Table";


// Endpoints
import { BASE_URL } from "../../endpoints";
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { resultadosComunas } from '../../redux/actions/comunas';
import { idExtractor } from '../../helpers/idExtractor';


const CONSTANT = {
  pathUrl: '/generales/comunas',
  permissionsPath: 'comunas',
  queryUrlGetOne: getOne.comunas,
  title: 'Comunas',
  reduxSetResults: resultadosComunas,
}


const dataFormatter = (filtrosObj) => {
  let data = {}

  Object.entries(filtrosObj).forEach(([key, value]) => {
    if (key === 'busqueda') {
      data = {
        ...data,
        busqueda: value
      }
      return
    }

    if (key === 'creacion_desde' && value.length > 0) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          created_at_desde: value
        }
      }
      return
    }

    if (key === 'creacion_hasta' && value.length > 0) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          created_at_hasta: value
        }
      }
      return
    }

    if (key === 'pais_id' && value.length > 0) {
      data = {
        ...data,
        filtros_externos: {
          ...data.filtros_externos,
          pais_id: value.map(el => idExtractor(el))
        }
      }
      return
    }

    if (key === 'region' && value.length > 0) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          region_id: value.map(el => idExtractor(el))
        }
      }
      return
    }

    if (value.length > 0 && key === 'estatus') {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          [key]: value.map(value => value === 'Activo' ? 1 : 0)
        }
      }
      return
    }

    if (value.length > 0) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          [key]: value
        }
      }
    }
  })

  return data
}


const ComunasTable = () => {
  const { resultados: response, filtros } = useSelector(state => state.comunas)
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const { busqueda } = filtros
  const { user } = useSelector(state => state.auth)


  const handleUrl = (url) => {
    let data = {
      busqueda, ...dataFormatter(filtros)
    }

    dispatch(resultadosComunas(null))
    axios(url + `&limit=${showing_results}`,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        }, method: 'POST', data
      })
      .then(({ data }) => dispatch(resultadosComunas(data.comunas)))
      .catch(err => console.log(err))
  }


  const handleEstatus = async (objData, url, target) => {
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    }

    if (target === 'estatus') {
      let newEstatus = objData.estatus ? 0 : 1
      config = {
        ...config,
        method: 'POST',
        data: { _method: 'PUT', [target]: newEstatus }
      }
    }

    await axios(getOne.comunas(objData.id), config)
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `Comuna: ${res.data.nombre} - estatus actualizado con éxito`,
          icon: 'success'
        }

        dispatch(fireToaster(toasterContent))
      })
      .catch(err => {
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
        console.log(err);
      })

    dispatch(resultadosComunas(null))
    axios(url + `&limit=${showing_results}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
      data: { busqueda, ...dataFormatter(filtros) }
    })
      .then(({ data }) => {
        dispatch(resultadosComunas(data.comunas))
      })
      .catch(err => console.error(err))
  }


  return (
    <AccordionTable title={`Comunas (${response?.data?.length ?? 0} resultados)`}>
      {!response && <CustomLoader blocking={'partial'} />}

      <Table response={response} handlePagination={handleUrl}>
        <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
          <tr role="row">
            <th>ID</th>
            <th>Nombre</th>
            <th>Generales</th>
            <th>Cod Maximise</th>
            <th>Bandera</th>
            <th>Creación</th>
            <th>Estatus</th>
            <th className="w-max">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {response?.data.length
            ? response.data?.map((data) => {
              const imagen_bandera = `${BASE_URL}storage/${data.imagen_bandera}` ?? '/placeholder.png'

              return (
                <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                  <td>{data?.id}</td>
                  <td>{data?.nombre ?? "N/A"}</td>
                  <td>
                    <div className='d-flex flex-column'>
                      <span>Región: <b>{data?.neu_region?.nombre ?? "N/A"}</b></span>
                      <span>Ciudad: <b>{data?.neu_ciudad?.nombre ?? "N/A"}</b></span>
                      <span>País: <b>{data?.neu_region?.neu_pais?.nombre ?? "N/A"}</b></span>
                      <span>Distrito: <b>{data?.neu_distrito?.nombre ?? "N/A"}</b></span>
                    </div>
                  </td>
                  <td>{data?.cod_maxi ?? "N/A"}</td>
                  <td>{data?.imagen_bandera ? <img className="height-listado" src={imagen_bandera} alt={data.nombre} /> : "N/A"}</td>
                  <td>{data?.created_at.split("T")[0] || 'N/A'}</td>
                  <td>
                    <Switch value={data.estatus} labelText={""} handleValue={() => handleEstatus(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`, 'estatus')} />
                  </td>
                  <td>
                    <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                      {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                        <DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
                      }

                      {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
                        <DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
                      }

                      {/* {user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
                        <DropdownMenuLink text='Borrar' icon="X" onClick={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}` + queryParamsFormatter({ nombre }), 'delete')} />
                      } */}
                    </DropdownWrapper>
                  </td>
                </tr>
              )
            })
            : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={8}><i>Sin datos para mostrar...</i></td></tr>
          }
        </tbody>
      </Table>
    </AccordionTable >
  )
}

export default ComunasTable
import { useEffect, useState } from 'react'


// Components
import Button from '../../../components/Button'
import CheckBoxInput from '../../../components/CheckboxInput'
import Switch from '../../../components/Switch'
import FormInput from '../../../components/FormInput'


// Layouts
import AccordionTable from '../../../layout/AccordionTable'


// Mocks
import { useDispatch, useSelector } from 'react-redux'
import CustomLoader from '../../../components/CustomLoader'
import { NoResults } from '../../../components/Tables/Misc/NoResults'
import ProductosEtiquetasFiltros from '../../../components/AccordionFilter/ProductosEtiquetas'
import axios from 'axios'
import { BASE_URL, PATH_URL } from '../../../endpoints'
import { fireToaster } from '../../../redux/actions/toaster'
import { useNavigate } from 'react-router-dom'


// Helpers
const acceptedDateTimeFormat = (datetime) => {
  if (typeof datetime !== 'string') return console.warn('datetime, invalid format, check: ' + datetime)

  const [date, time] = datetime.split(' ')
  const [hh, mm] = time.split(':')
  return `${date}T${hh}:${mm}`
}

const productosEncontradosformatter = (arr) => {
  if (typeof arr !== 'object') return

  return arr?.map(el => {

    return el = {
      ...el,
      nombre: el.producto,
      seleccionado: false,
      etiqueta_inicio: acceptedDateTimeFormat(el?.inicio_etiqueta_producto) ?? null,
      etiqueta_final: acceptedDateTimeFormat(el?.final_etiqueta_producto) ?? null,
      prioridad_resultado: 1,
      estatus: el?.estatus_etiqueta_producto ?? 1
    }
  })
}


const EtiquetasEdicionMasiva = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)

  // MANEJADORES DE ESTADO - PRODUCTOS SELECCIONADOS
  const { resultados: response } = useSelector(state => state.productosEtiquetas)
  const [productosEncontrados, setProductosEncontrados] = useState(false)
  const [seleccionarTodo, setSeleccionarTodo] = useState(false)
  const [filteredProductosEncontrados, setFilteredProductosEncontrados] = useState(null)
  const [nombreInputProductoEncontrado, setNombreInputProductoEncontrado] = useState('')
  const [skuInputProductoEncontrado, setSkuInputProductoEncontrado] = useState('')
  const [etiquetaInputProductoEncontrado, setEtiquetaInputProductoEncontrado] = useState('')
  const [productosSeleccionados, setProductosSeleccionados] = useState([])
  const [productosSeleccionadosFiltrados, setProductosSeleccionadosFiltrados] = useState(productosSeleccionados)

  const [filterProducto, setFilterProducto] = useState('')
  const [inicioInput, setInicioInput] = useState(undefined)
  const [finalInput, setFinalInput] = useState(undefined)


  // EFECTO QUE CARGA LAS RESPUESTAS Y LAS FORMATEA
  useEffect(() => {
    setProductosEncontrados(productosEncontradosformatter(response?.etiquetas_productos))
  }, [response])


  // EFECTO QUE FILTRA A TRAVES DE LOS INPUTS DE LA DATATABLE DE PRODUCTOS ENCONTRADOS
  useEffect(() => {
    if (!productosEncontrados) return
    const filtered = productosEncontrados?.filter(producto => (
      producto.nombre.toLowerCase().includes(nombreInputProductoEncontrado.toLowerCase()) &&
      producto.sku.toLowerCase().includes(skuInputProductoEncontrado.toLowerCase()) &&
      producto.etiqueta.toLowerCase().includes(etiquetaInputProductoEncontrado.toLowerCase())
    ))
    setFilteredProductosEncontrados(filtered)
  }, [productosEncontrados, nombreInputProductoEncontrado, skuInputProductoEncontrado, etiquetaInputProductoEncontrado])


  /* Añade el producto seleccionado desde el arreglo de productos encontrado */
  // TODO: REVISAR QUE HACE LA ELIMINACION 2 VECES
  const handleProductoSeleccionado = (e) => {
    const { name } = e.target?.dataset ?? e.target
    const [id] = name.split('%-%')

    const selected = productosEncontrados.find(obj => String(obj.etiqueta_producto_id) === id)
    setProductosEncontrados(productosEncontrados.map((obj) =>
      (obj?.etiqueta_producto_id === selected.etiqueta_producto_id)
        ? { ...obj, seleccionado: !obj.seleccionado }
        : obj
    ))

    selected.seleccionado
      ? setProductosSeleccionados([...productosSeleccionados, selected])
      : setProductosSeleccionados(productosSeleccionados.filter(obj => (obj?.etiqueta_producto_id === selected.etiqueta_producto_id)))
  }


  const handleSeleccionarTodo = () => {
    const isListedUnselected = productosEncontrados.find(obj => obj.seleccionado === false)
    const unselectAll = productosEncontrados.map((obj) => ({ ...obj, seleccionado: false }))
    const selectAll = productosEncontrados.map((obj) => ({ ...obj, seleccionado: true }))

    if (seleccionarTodo && !isListedUnselected) {
      setProductosEncontrados(unselectAll)
      return setSeleccionarTodo(false)
    }

    if (!seleccionarTodo) {
      setProductosEncontrados(selectAll)
      return setSeleccionarTodo(true)
    }
  }


  // MANEJADORES DE INPUTS - DATATABLE
  const handleInputFilterProducto = (e) => setFilterProducto(e.target.value);


  // ACTUALIZA LOS PRODUCTOS SELECCIONADOS 
  useEffect(() => {
    if (!productosEncontrados) return
    setProductosSeleccionados([...productosEncontrados.filter(({ seleccionado }) => seleccionado === true)])
  }, [productosEncontrados])



  // RENDERIZA LOS PRODUCTOS LUEGO DE FILTRADOS EN LA DATATABLE
  useEffect(() => {
    if (!productosEncontrados) return
    const isListedUnselected = productosEncontrados.find(obj => obj.seleccionado === false)

    if (!isListedUnselected && productosSeleccionados.length > 0) setSeleccionarTodo(true)
    else setSeleccionarTodo(false)

    setProductosSeleccionadosFiltrados([...productosSeleccionados
      .map(el => ({ ...el, filter_params: `${el.nombre} ${el.sku}` }))
      .filter(el => el.filter_params.toLocaleLowerCase().includes(filterProducto.toLocaleLowerCase()))
    ])
  }, [filterProducto, productosSeleccionados, productosEncontrados])


  const handleInicioInput = (e) => {
    const { value } = e.target
    setInicioInput(value)
  }


  const handleFinalInput = (e) => {
    const { value } = e.target
    setFinalInput(value)
  }


  const handleInicioTodosProductosSeleccionados = () => {
    setProductosEncontrados(productosEncontrados.map(el => {
      let isListed = productosSeleccionadosFiltrados.find(selected => (selected.id === el.id && selected.nombre === el.nombre))
      return isListed ? { ...el, etiqueta_inicio: inicioInput } : el
    }))
  }


  const handleFinalTodosProductosSeleccionados = () => {
    setProductosEncontrados(productosEncontrados.map(el => {
      let isListed = productosSeleccionadosFiltrados.find(selected => (selected.id === el.id && selected.nombre === el.nombre))
      return isListed ? { ...el, etiqueta_final: finalInput } : el
    }))
  }


  const handleFinalProductoSeleccionado = (e, producto) => {
    const { value } = e.target
    const { etiqueta_producto_id } = producto

    setProductosEncontrados(productosEncontrados.map(el => {
      return (el.etiqueta_producto_id === etiqueta_producto_id)
        ? { ...el, etiqueta_final: value }
        : el
    }))
  }


  const handleInicioProductoSeleccionado = (e, producto) => {
    const { value } = e.target
    const { etiqueta_producto_id } = producto

    setProductosEncontrados(productosEncontrados.map(el => {
      return (el.etiqueta_producto_id === etiqueta_producto_id)
        ? { ...el, etiqueta_inicio: value }
        : el
    }))
  }


  const handleEstatusProductoSeleccionado = (e, producto) => {
    const { value } = e.target
    const { etiqueta_producto_id } = producto

    setProductosEncontrados(productosEncontrados.map(el => {
      return (el.etiqueta_producto_id === etiqueta_producto_id)
        ? { ...el, estatus: value }
        : el
    }))
  }


  const handleQuitarProductosSeleccionados = () => {
    const unselectAll = productosEncontrados.map((obj) => ({ ...obj, seleccionado: false }))
    setProductosEncontrados(unselectAll)
    setSeleccionarTodo(false)
  }


  const handleSend = async () => {
    let ediciones = productosSeleccionados.map(PE => {
      let newPE = {}
      Object.entries(PE).forEach(([k, v]) => {
        switch (k) {
          case 'etiqueta_producto_id':
            newPE = {
              ...newPE,
              id: v
            }
            break;

          case 'prioridad_resultado':
            newPE = {
              ...newPE,
              prioridad_resultado: 1
            }
            break;

          case 'etiqueta_inicio':
            newPE = {
              ...newPE,
              fecha_hora_inicio: `${v.split('T')[0]} ${v.split('T')[1]}:00`
            }
            break;

          case 'etiqueta_final':
            newPE = {
              ...newPE,
              fecha_hora_final: `${v.split('T')[0]} ${v.split('T')[1]}:00`
            }
            break;

          case 'estatus':
            newPE = {
              ...newPE,
              estatus: v
            }
            break;

          default:
            break;
        }

      })
      return newPE
    })

    setLoader(true)

    await axios(BASE_URL + PATH_URL + '/etiquetas-productos/edicion-masiva',
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'PUT',
        data: { ediciones }
      })
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `Etiquetas-Productos editados con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        setLoader(false)
        return navigate('/productos/etiquetas')
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
             Operación fallida
             (${err.response.status} - ${err.response.statusText})
           `,
          html: `
             <b>Detalle: </b>
             ${detalles.map(error => `<br /><i>-${error}</i>`)}
           `,
          icon: 'error'
        }
        return dispatch(fireToaster(toasterContent))
      })
    setLoader(false)
  }


  return (
    <div className="row">
      {loader && <CustomLoader blocking={'full'} />}
      <div className="col-12 mx-auto">

        {/* COMPONENTE FILTROS - PRODUCTOS */}
        <ProductosEtiquetasFiltros />

        <div className='row'>
          {/* PRODUCTOS ENCONTRADOS */}
          <AccordionTable
            title={`Productos encontrados (${productosEncontrados?.length ?? 0} encontrados / ${filteredProductosEncontrados?.length ?? 0} filtrados)`}
            classes='col-12 col-md-12 mb-1'
          >
            <div className={`card-datatable table-responsive pt-0 custom-scrollbar flex-shrink-0 dataTable custom-scrollbar overflow-auto`}>
              <table className="position-relative user-list-table table no-footer dtr-column z-10" style={{ maxHeight: '1000px' }}>
                <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                  <tr role="row">
                    <th>
                      <div className='d-flex'>
                        <div>
                          <CheckBoxInput
                            labelText='Todos'
                            value={seleccionarTodo}
                            handleValue={handleSeleccionarTodo}
                            classes='mt-1 me-1'
                            name='select-all-products'
                          />
                        </div>
                        <FormInput
                          value={nombreInputProductoEncontrado}
                          placeholder='Producto'
                          labelText=''
                          handleValue={(e) => setNombreInputProductoEncontrado(e.target.value)}
                          sizeDesk='col'
                          classes='text-capitalize sub-text-3 font-weight-normal'
                          margin='my-auto'
                        />
                      </div>
                    </th>
                    <th>
                      <div className='d-flex'>
                        <FormInput
                          value={skuInputProductoEncontrado}
                          placeholder='SKU'
                          labelText=''
                          handleValue={(e) => setSkuInputProductoEncontrado(e.target.value)}
                          sizeDesk='col'
                          classes='text-capitalize sub-text-3 font-weight-normal'
                          margin='my-auto'
                        />
                      </div>
                    </th>
                    <th>
                      <div className='d-flex'>
                        <FormInput
                          value={etiquetaInputProductoEncontrado}
                          placeholder='Etiqueta'
                          labelText=''
                          handleValue={(e) => setEtiquetaInputProductoEncontrado(e.target.value)}
                          sizeDesk='col'
                          classes='text-capitalize sub-text-3 font-weight-normal'
                          margin='my-auto'
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {productosEncontrados?.length
                    ? filteredProductosEncontrados?.map(data => (
                      <tr
                        className={`odd data-selector ${data?.seleccionado ? 'selected' : ''}`}
                        key={`producto-${data?.etiqueta_producto_id}-${data?.nombre}`}
                        onClick={handleProductoSeleccionado}
                        data-name={`${data?.etiqueta_producto_id}%-%${data?.nombre}`}
                      >
                        <td data-name={`${data?.etiqueta_producto_id}%-%${data?.nombre}`}>
                          <span className='d-flex flex-column' data-name={`${data?.etiqueta_producto_id}%-%${data?.nombre}`}>
                            <span data-name={`${data?.etiqueta_producto_id}%-%${data?.nombre}`}>{data?.categoria}</span>
                            <b data-name={`${data?.etiqueta_producto_id}%-%${data?.nombre}`}>{data?.nombre.toUpperCase()}</b>
                          </span>
                        </td>
                        <td data-name={`${data?.etiqueta_producto_id}%-%${data?.nombre}`}>
                          {data?.sku}
                        </td>
                        <td data-name={`${data?.etiqueta_producto_id}%-%${data?.nombre}`}>
                          <div data-name={`${data?.etiqueta_producto_id}%-%${data?.nombre}`} className='d-flex flex-column'>
                            <b data-name={`${data?.etiqueta_producto_id}%-%${data?.nombre}`}>{data?.etiqueta.toUpperCase()}</b>
                            <span data-name={`${data?.etiqueta_producto_id}%-%${data?.nombre}`}>{data?.oferta}</span>
                            <span data-name={`${data?.etiqueta_producto_id}%-%${data?.nombre}`}>{data?.inicio_etiqueta_producto.split(' ')[0]} / {data?.final_etiqueta_producto.split(' ')[0]}</span>
                          </div>
                        </td>
                      </tr>
                    ))
                    : productosEncontrados === false
                      ? <tr><td colSpan={3}><CustomLoader /></td></tr>
                      : <tr><td colSpan={3}><NoResults /></td></tr>
                  }
                </tbody>
              </table>
            </div>
          </AccordionTable>

          {/* PRODUCTOS SELECCIONADOS */}
          <AccordionTable
            title={`Productos seleccionados (${productosSeleccionados.length})`}
            classes='col-12 col-md-12'
          >
            <div className={`card-datatable table-responsive pt-0 custom-scrollbar overflow-auto flex-shrink-0`} style={{ minHeight: '500px', maxHeight: '850px' }}>
              <table className="user-list-table table dataTable no-footer dtr-column z-10 h-100" >
                <thead className="table-light ">
                  <tr role="row">
                    <th className='del pe-0'>
                      <button
                        onClick={handleQuitarProductosSeleccionados}
                        className='mt-2'
                      >
                        Limpiar
                      </button>
                    </th>
                    <th className='col-md-3'>
                      <div className='d-flex'>
                        <FormInput
                          name='producto'
                          value={filterProducto}
                          placeholder='Buscar...'
                          labelText='PRODUCTO - SKU'
                          handleValue={handleInputFilterProducto}
                          sizeDesk='col-12'
                          classes='text-capitalize sub-text-3 font-weight-normal'
                          margin='my-auto'
                        />
                        <div className='mt-2 pt-75'>
                          <Button color={'danger'} icon='Trash' className={'ms-50 my-auto p-25'} onClick={() => setFilterProducto('')} />
                        </div>
                      </div>
                    </th>
                    <th className='text-center'>Etiqueta</th>
                    <th className='col-md-2'>
                      <div className='d-flex'>
                        <FormInput
                          type='datetime-local'
                          name='instalacion'
                          labelText='Fecha inicio'
                          sizeDesk='col'
                          margin='mb-0 me-25'
                          handleValue={(e) => handleInicioInput(e)}
                          value={inicioInput}
                        />
                        <div className='mt-2 pt-75'>
                          <Button icon='Plus' className={'ms-50 my-auto p-25'} onClick={() => handleInicioTodosProductosSeleccionados()} />
                        </div>
                      </div>
                    </th>
                    <th className='col-md-2'>
                      <div className='d-flex'>
                        <FormInput
                          type='datetime-local'
                          name='despacho'
                          labelText='Fecha final'
                          sizeDesk='col'
                          margin='mb-0 me-25'
                          handleValue={(e) => handleFinalInput(e)}
                          value={finalInput}
                        />
                        <div className='mt-2 pt-75'>
                          <Button icon='Plus' className={'ms-50 m-auto p-25'} onClick={() => handleFinalTodosProductosSeleccionados()} />
                        </div>
                      </div>
                    </th>
                    <th className='col-1'>Estatus</th>
                  </tr>
                </thead>
                <tbody>
                  {productosSeleccionadosFiltrados.map((data, i) => (
                    <tr className="odd multi-data" key={`${i}-producto-selected-${data.etiqueta_producto_id}-${data.nombre}`}>
                      <td>
                        <CheckBoxInput
                          labelText=''
                          value={data.seleccionado}
                          handleValue={handleProductoSeleccionado}
                          name={`${data.etiqueta_producto_id}%-%${data.nombre}`}
                        />
                      </td>
                      <td>
                        <div className='d-flex flex-column'>
                          <b>{data.nombre.toUpperCase() ?? 'N/A'}</b>
                          <span className='sub-text-3 text-lgray'>{data.categoria ?? 'N/A'}</span>
                          <span className='sub-text-3 text-lgray'>$ {data.precio_final ?? 'N/A'} - Stock: {data.stock_propio ?? 'N/A'}</span>
                          <span className='sub-text-3 text-lgray'>SKU: {data.sku ?? 'N/A'}</span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex flex-column'>
                          <b>{data?.etiqueta.toUpperCase() ?? 'N/A'}</b>
                          <span className='sub-text-3 text-lgray'>{data?.oferta ?? 'N/A'}</span>
                          <span className='sub-text-3 text-lgray'>{data?.inicio_etiqueta.split(' ')[0] ?? 'N/A'} / {data?.final_etiqueta.split(' ')[0] ?? 'N/A'}</span>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex fs-2'>
                          <FormInput
                            type='datetime-local'
                            value={data.etiqueta_inicio ?? undefined}
                            placeholder='N/A'
                            margin='mb-0'
                            handleValue={(e) => handleInicioProductoSeleccionado(e, data)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='d-flex fs-2'>
                          <FormInput
                            type='datetime-local'
                            value={data.etiqueta_final ?? undefined}
                            placeholder='N/A'
                            margin='mb-0'
                            handleValue={(e) => handleFinalProductoSeleccionado(e, data)}
                          />
                        </div></td>
                      <td>
                        <Switch value={data.estatus ? true : false} labelText={''} withIcons handleValue={(e) => handleEstatusProductoSeleccionado(e, data)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </AccordionTable>
        </div>
      </div>
      <div className='col'>
        <div className='d-flex justify-content-center mt-2'>
          <Button text='Asignación masiva de métodos de pago a productos' onClick={() => handleSend(productosSeleccionadosFiltrados)} />
        </div>
      </div>
    </div>
  )
}

export default EtiquetasEdicionMasiva
import {
   FILTER_ADD_METODO_PAGO,
   FILTER_REMOVE_METODO_PAGO,
   FILTER_ADD_ESTATUS,
   FILTER_REMOVE_ESTATUS,
   FILTER_CREACION,
   FILTER_CLEAR,
   FILTER_BUSQUEDA,
   RESULTADOS_METODOS_PAGO
} from "../redux-types";


export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })


export const metodoPagoFilter = (data, isRemove = false) => {
   return isRemove
      ? { type: FILTER_REMOVE_METODO_PAGO, payload: data }
      : { type: FILTER_ADD_METODO_PAGO, payload: data };
}

export const estatusFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_ESTATUS, payload: data }
      : { type: FILTER_ADD_ESTATUS, payload: data };

export const creacionFilter = (data) => ({ type: FILTER_CREACION, payload: data })

export const resultadosMetodosPago = (data) => ({ type: RESULTADOS_METODOS_PAGO, payload: data })

export const clearFilterMetodosPago = () => ({ type: FILTER_CLEAR })


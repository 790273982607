import { useEffect, useState } from "react"

const colors = ['red', 'blue', 'green', 'yellow', 'orange', 'pink', 'brown', 'purple']
const randomPicker = (arr) => arr[Math.floor(Math.random() * arr.length)]


const nameFormatter = (name) => {
   let [pos1, pos2] = name.split(' ')

   if (pos2) {
      return `${pos1[0]}${pos2[0]}`.toUpperCase()
   }

   return `${pos1[0]}${pos1[1] ?? pos1[0]}`.toUpperCase()
}


const NameAvatar = ({ name = 'DT' }) => {
   const [color, setColor] = useState('')
   const [displayName, setDisplayName] = useState(name)

   useEffect(() => {
      setDisplayName(nameFormatter(name))
      setColor(randomPicker(colors))
   }, [name])


   return (
      <div className={`name-avatar color-${color} me-50 my-auto`}>
         <div className='d-flex justify-content-center w-100 h-100'>
            <span className="w-max">{displayName}</span>
         </div>
      </div>
   )
}

export default NameAvatar
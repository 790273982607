import React from 'react'
import Button from '../Button'

const ScrollToTop = () => {
  return (
    <div >
      <Button icon='ArrowUp' color='secondary' className='scrollToTop' onClick={() => window.scrollTo(0, 0)} />
    </div>
  )
}

export default ScrollToTop
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


// Components
import Icon from './Icon';


const Button = ({
   isLink, isDownload = false,
   url,
   newTab,
   type, onClick, disabled,
   color, style, isRounded, className,
   text, icon
}) => {

   const handleClassName = () => {

      let classText = 'd-flex align-items-center btn';

      if (!text && icon) {
         classText = classText + ' btn-icon'
      }

      if (style) {
         classText = classText + ` btn-${style}-${color}`
      } else {
         classText = classText + ` btn-${color}`;
      }

      if (isRounded) {
         classText = classText + ' round'
      }

      if (className) {
         classText = `${classText} ${className}`
      }

      return classText;
   }

   return (
      <Tag
         isLink={isLink}
         isDownload={isDownload}
         url={url}
         type={type}
         newTab={newTab}
         onClick={onClick}
         disabled={disabled}
         handleClassName={handleClassName}
      >
         {icon && <Icon icon={icon} />}

         {text?.length ? <span className={icon && 'ms-25'}>{text}</span> : <></>}
      </Tag>
   );
}

const Tag = ({
   children,
   isLink, isDownload,
   newTab,
   url,
   type, onClick, disabled,
   handleClassName
}) => {

   if (isDownload) {
      return (
         <a
            href={url}
            target='_blank'
            rel='noreferrer'
            className={handleClassName()}
         >
            {children}
         </a>
      );
   }

   if (isLink) {
      return (
         <Link
            target={newTab ? '_blank' : ''}
            rel={newTab ? 'noreferrer' : ''}
            to={url}
            className={handleClassName()}
         >
            {children}
         </Link>
      );
   }

   return (
      <button
         type={type}
         className={handleClassName()}
         disabled={disabled}
         onClick={onClick ? onClick : undefined}
      >
         {children}
      </button>
   );
}



Button.propTypes = {
   isLink: PropTypes.bool,


   type: PropTypes.oneOf(['button', 'submit']),
   onClick: PropTypes.func,
   disabled: PropTypes.bool,

   text: PropTypes.string,
   // icon: validateFeatherIcon,

   color: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark']),
   style: PropTypes.oneOf(['outline', 'flat', 'gradient', 'relief']),
   isRounded: PropTypes.bool,
}

Button.defaultProps = {
   isLink: false,

   url: '/',

   type: 'button',
   onClick: null,
   disabled: false,

   text: null,
   icon: null,

   color: 'primary',
   style: null,
   isRounded: false,
}



export default Button;
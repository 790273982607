import Button from "../../Button"
import FormInput from "../../FormInput"

export const ModalPlanificacion = ({handleInputChange, form, id, handleFetch}) => {
  return (
    <>
      <p className='fw-bold fs-3'>Planificacion de nota de venta #{id}</p>
      <hr className='my-2' />

      <FormInput
        type='date'
        handleValue={handleInputChange}
        name='planificacion%-%fecha_planificacion'
        value={form.fecha_planificacion}
        size='col-12'
        sizeDesk='col-md-4'
        classes='mx-auto'
      />

      <div>
        <Button className='mx-auto' text='Planificar' icon='Check' onClick={() => handleFetch(null, 'planificacion-create')} />
      </div>
    </>
  )
}

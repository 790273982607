import { useSelector } from 'react-redux'


// Redux
import { useEffect, useState } from 'react'


// Components


// Layouts
import AccordionTable from '../../../layout/AccordionTable'
import ProductosMetodosPagosFiltros from '../../../components/AccordionFilter/ProductosMetodosPago'
import Button from '../../../components/Button'


const CONSTANT = {
	path: 'metodos-pago',
	title: 'Métodos de pago'
}


const Etiquetas = () => {
	const { resultados: response } = useSelector(state => state.productosMetodosPago)
	const [r, setR] = useState(null)
	const [metodosPago, setMetodosPago] = useState([])
	const [resultadosFormatted, setResutadosFormatted] = useState(null)


	useEffect(() => {
		setResutadosFormatted(null)
		setMetodosPago([])
		if (!response) return
		setR(response?.puntos_entregas_productos ?? [])
	}, [response])


	useEffect(() => {
		if (!r?.length) return
		const arrMP = []

		r.forEach(MPP => {
			Object.entries(MPP).forEach(([k, v]) => {
				if (k === 'neu_metodo_pago_producto') {
					v.forEach(mpp => {
						if (!arrMP.length) arrMP.push(mpp)
						arrMP.find(({ metodo_pago_id }) => mpp.metodo_pago_id === metodo_pago_id) ?? arrMP.push(mpp)
					})
				}
			})
		})
		setMetodosPago(arrMP);
	}, [r])


	useEffect(() => {
		if (!r?.length) return
		const newResultados = r.map(productoMetodoPago => {
			let newObj = {
				...productoMetodoPago,
				neu_metodo_pago_producto: metodosPago.map(() => ({}))
			}
			metodosPago.forEach((currentMetodoPago, i) => {
				const isIncludedMetodoPago = productoMetodoPago.neu_metodo_pago_producto.find(el => el.metodo_pago_id === currentMetodoPago.metodo_pago_id)

				if (isIncludedMetodoPago) {
					newObj.neu_metodo_pago_producto.splice(i, 1, isIncludedMetodoPago)
					newObj = {
						...newObj,
						neu_metodo_pago_producto: newObj.neu_metodo_pago_producto
					}
				}
			})

			return newObj
		})
		setResutadosFormatted(newResultados)
	}, [metodosPago])


	return (
		<div className="row">
			<div className='d-flex justify-content-between mb-1'>
				<h3>{CONSTANT.title} - Listado</h3>

				<div className='d-flex gap-1'>
					<div className='w-fit'>
						<Button text='Asignación masiva' className='m-auto p-50' color='success' url={`/productos/${CONSTANT.path}/asignacion-masiva`} isLink />
					</div>
					<div className='w-fit'>
						<Button text='Edición masiva' className='m-auto p-50' color='warning' url={`/productos/${CONSTANT.path}/edicion-masiva`} isLink />
					</div>
				</div>
			</div>

			<div className="col-12 mx-auto">
				{/* COMPONENTE FILTROS - PRODUCTOS */}
				<ProductosMetodosPagosFiltros type='visualizar' />

				{/* TABLA DE RESULTADOS PRODUCTOS - METODOS PAGO */}
				<AccordionTable
					title={`Tabla de resultados (${r?.length ?? 0})`}
				>
					<div className='d-flex'>

						{/* PRODUCTOS */}
						<div
							className={`card-datatable table-responsive pt-0 custom-scrollbar overflow-auto shadow flex-shrink-0 dataTable w-100`}
						>
							<table className="user-list-table table position-relative no-footer dtr-column z-10">
								<thead className="table-light position-sticky top-0 z-100" >
									<tr role="row">
										{resultadosFormatted &&
											<th className='border-end position-sticky left-0 z-100 w-fit' style={{ height: '85px', width: '180px' }}>
												<p className='text-center mt-2'>Producto</p>
											</th>
										}

										{metodosPago?.map(({ neu_metodo_pago }, i) => (
											<th style={{ height: '85px', minWidth: '210px' }} className='border-end' key={`${i}-${neu_metodo_pago?.nombre}`}>
												<div className='mx-auto w-fit my-auto'>
													<a href={`/metodos-pago/detalle/${neu_metodo_pago?.id}`} target='_blank' rel='noreferrer'>
														<p className='my-0'>{neu_metodo_pago?.nombre ?? 'N/A'} <span className='tag px-50 sub-text-4'>{neu_metodo_pago?.estatus ? 'Activo' : 'Inactivo'}</span></p>
													</a>
												</div>
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{resultadosFormatted
										? resultadosFormatted?.map((data, i) => {
											return (
												<tr className="odd" key={`product-${i}-${data?.producto}`}>

													{/* RESUMEN PRODUCTO - DETALLE */}
													<td className='p-0 position-sticky left-0 border-end z-10'>
														<div className='px-2 py-1 bg-product'>
															<div className='my-0 d-flex flex-column'>
																<span>
																	<a href={`/productos/detalle/${data?.id}`} target='_blank' rel='noreferrer'>
																		<b>{data?.producto.toUpperCase()}</b>
																	</a>
																</span>
															</div>
															<div className='my-0 sub-text-3'>{data?.categoria}</div>
															<div className='my-0 sub-text-3'>{data?.sku}</div>
															<span className={`my-auto tag px-50 sub-text-3 w-fit ${data?.publicado ? 'success' : 'red'}`}>{data?.publicado ? 'Publicado' : 'Sin publicar'}</span>
														</div>
													</td>

													{data?.neu_metodo_pago_producto.map((metodoPago, i) => (
														<td className='bg-white border-end' key={`${i}-metodoPago-${data?.producto}`}>
															{Object.entries(metodoPago).length
																? <div className='m-auto w-fit z-10 '>
																	<div>
																		<span className={`tag ${metodoPago?.estatus ? 'success' : 'red'}  px-50`}>{metodoPago?.estatus ? 'Activo' : 'Inactivo'}</span>
																	</div>
																	{/* <div>Editar</div> */}
																</div>
																: <p className='text-center'>N/A</p>
															}
														</td>
													))}
												</tr>
											)
										})

										: <tr><td><p className='text-center fw-bolder fs-2 mt-1'>Realice una búsqueda</p></td></tr>
									}
								</tbody>
							</table>
						</div>
					</div>

				</AccordionTable>
			</div>
		</div >
	)
}

export default Etiquetas
import {
   FILTER_BUSQUEDA,
   FILTER_ADD_ESTATUS,
   FILTER_REMOVE_ESTATUS,
   FILTER_CLEAR,
   FILTER_CREACION_DESDE,
   FILTER_CREACION_HASTA,
   FILTER_ADD_TEXTO,
   FILTER_REMOVE_TEXTO,
   RESULTADOS_CINTA_INFORMATIVA,
   FILTER_ADD_FRONTEND,
   FILTER_REMOVE_FRONTEND
} from "../redux-types";

const initialState = {
   filtros: {
      busqueda: '',
      frontend_id: [],
      texto: [],
      estatus: [],
      creacion_desde: '',
      creacion_hasta: '',
   },
   resultados: null
}


export const cintaInformativaReducer = (state = initialState, action) => {
   const isListedEstatus = state.filtros.estatus.find(el => el === action.payload)
   const isListedTexto = state.filtros.texto.find(el => el === action.payload)
   const isListedFrontend = state.filtros.frontend_id.find(el => el === action.payload)

   switch (action.type) {
      case FILTER_ADD_ESTATUS:
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus: isListedEstatus
                  ? state.filtros.estatus
                  : [...state.filtros.estatus, action.payload]
            }
         };

      case FILTER_REMOVE_ESTATUS:
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus: state.filtros.estatus.filter(el => el !== action.payload)
            }
         };

      case FILTER_CREACION_DESDE:
         return {
            ...state, filtros: {
               ...state.filtros,
               creacion_desde: action.payload
            }
         };

      case FILTER_CREACION_HASTA:
         return {
            ...state, filtros: {
               ...state.filtros,
               creacion_hasta: action.payload
            }
         };

      case FILTER_ADD_FRONTEND:
         return {
            ...state, filtros: {
               ...state.filtros,
               frontend_id: isListedFrontend
                  ? state.filtros.frontend_id
                  : [...state.filtros.frontend_id, action.payload]
            }
         };

      case FILTER_REMOVE_FRONTEND:
         return {
            ...state, filtros: {
               ...state.filtros,
               frontend_id: state.filtros.frontend_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_TEXTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               texto: isListedTexto
                  ? state.filtros.texto
                  : [...state.filtros.texto, action.payload]
            }
         };

      case FILTER_REMOVE_TEXTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               texto: state.filtros.texto.filter(el => el !== action.payload)
            }
         };

      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda: action.payload
            }
         };

      case RESULTADOS_CINTA_INFORMATIVA:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
import axios from 'axios'
import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { FILTER_ADD_CLAVE_ATRIBUTO, FILTER_ADD_UNIDAD, FILTER_BUSQUEDA } from '../../redux/redux-types'
import { busquedaFilter, claveAtributoFilter, clearFilterValoresAtributos, resultadosValoresAtributos, unidadFilter } from '../../redux/actions/valoresAtributos'


// Components
import FiltroAplicado from './FiltroAplicado'
import FormInput from '../FormInput'
import SelectFilter from '../SelectFilter'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'
import { lastPosition } from '../../helpers/lastPosition'


const CONSTANT = {
  redirectUrl: '/valores-atributos',
  title: 'Búsqueda de valores/atributos',
  queryUrlGetAll: getAll.valores_atributos + '/listar',
  queryUrlGetAllClavesAtributos: getAll.claves_atributos + '/listar',
  queryUrlGetAllUnidades: getAll.unidades,
  reduxClearFilters: clearFilterValoresAtributos,
  reduxSetResults: resultadosValoresAtributos,
}

const ValoresAtributosFiltros = () => {
  const { filtros } = useSelector(state => state.valoresAtributos)
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const [categorias, setCategorias] = useState([])
  const [unidades, setUnidades] = useState([])
  const { busqueda, clave_atributo_id, unidad_id } = filtros

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
      .catch(err => console.error(err))

    axios(CONSTANT.queryUrlGetAllClavesAtributos, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
      .then(({ data }) => setCategorias(data))
      .catch(err => console.error(err))

    axios(CONSTANT.queryUrlGetAllUnidades, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
      .then(({ data }) => setUnidades(data))
      .catch(err => console.error(err))
  }, [dispatch, showing_results])

  // EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
  useEffect(() => {
    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = (reset = false) => {
    setIsConsulting(true)

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}`, {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST'
      })
        .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
        .catch(err => console.error(err))
        .finally(() => {
          setIsConsulting(false)
        })
    }

    let data = {
      filtros: {}
    }

    Object.entries(filtros).forEach(([k, v]) => {
      if (k === 'busqueda') return


      if (v.length) {
        data = {
          ...data,
          filtros: {
            ...data.filtros,
            [k]: v.map(el => el.split('-')[0])
          }
        }
      }
    })

    axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}` + queryParamsFormatter({ busqueda }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data
      })
      .then(res => {
        dispatch(CONSTANT.reduxSetResults(res.data))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_BUSQUEDA:
        dispatch(busquedaFilter(value))
        break;

      case FILTER_ADD_CLAVE_ATRIBUTO:
        dispatch(claveAtributoFilter(value))
        break;

      case FILTER_ADD_UNIDAD:
        dispatch(unidadFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <FormInput
            labelText='Nombre'
            placeholder='Ingrese su búsqueda'
            size='col-12'
            sizeDesk='col-md-4'
            handleValue={(e) => handleAddFilter(e, FILTER_BUSQUEDA)}
            value={busqueda}
          />

          <SelectFilter
            labelText='Claves/Atributos'
            placeholder='Selecciona'
            size='col-12'
            sizeDesk='col-md-3'
            optionObj={categorias?.map(({ id, nombre }) => ({ id, name: nombre }))}
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_CLAVE_ATRIBUTO)}
            value={lastPosition(clave_atributo_id)}
          />

          <SelectFilter
            labelText='Unidades'
            placeholder='Selecciona'
            size='col-12'
            sizeDesk='col-md-3'
            optionObj={unidades?.map(({ id, nombre }) => ({ id, name: nombre }))}
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_UNIDAD)}
            value={lastPosition(unidad_id)}
          />
        </>

        {/* FILTROS APLICADOS - ADICIONALES */}
        <p className='mb-25 text-black'>Filtros aplicados</p>
        <div className='row'>
          <FiltroAplicado array={clave_atributo_id} func={claveAtributoFilter} title={'Claves/Atributos'} />
          <FiltroAplicado array={unidad_id} func={unidadFilter} title={'Unidades'} />
        </div>

        <hr className='my-1' />
      </ListadoHeader>
    </div>
  )
}

export default ValoresAtributosFiltros
import {
   FILTER_ADD_ESTATUS_PEDIDO,
   FILTER_ADD_VENDEDOR,
   FILTER_CLEAR,
   FILTER_DEPARTAMENTO,
   FILTER_EMPRESA,
   FILTER_ESTADO_PAGO,
   FILTER_FECHA_FINAL,
   FILTER_FECHA_INICIO,
   FILTER_PERIODO,
   FILTER_REMOVE_ESTATUS_PEDIDO,
   FILTER_REMOVE_VENDEDOR,
   RESULTADOS_REPORTE_VENTAS,
} from "../redux-types";

const initialState = {
   filtros: {
      departamento: '',
      empresa: '',
      periodo: '',
      estado_pago: '',
      fecha_inicial: '',
      fecha_final: '',
      vendedores: [],
      estatus_pedido: []
   },
   resultados: null
}


export const reporteVentasReducer = (state = initialState, action) => {
   let isListed

   switch (action.type) {
      case FILTER_ADD_VENDEDOR:
         isListed = state.filtros.vendedores.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               vendedores: isListed
                  ? state.filtros.vendedores
                  : [...state.filtros.vendedores, action.payload]
            }
         };

      case FILTER_REMOVE_VENDEDOR:
         return {
            ...state, filtros: {
               ...state.filtros,
               vendedores: state.filtros.vendedores.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_ESTATUS_PEDIDO:
         isListed = state.filtros.estatus_pedido.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus_pedido: isListed
                  ? state.filtros.estatus_pedido
                  : [...state.filtros.estatus_pedido, action.payload]
            }
         };

      case FILTER_REMOVE_ESTATUS_PEDIDO:
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus_pedido: state.filtros.estatus_pedido.filter(el => el !== action.payload)
            }
         };

      case FILTER_DEPARTAMENTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               departamento: action.payload
            }
         };

      case FILTER_FECHA_INICIO:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_inicial: action.payload
            }
         };

      case FILTER_FECHA_FINAL:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_final: action.payload
            }
         };

      case FILTER_PERIODO:
         return {
            ...state, filtros: {
               ...state.filtros,
               periodo: action.payload
            }
         };

      case FILTER_EMPRESA:
         return {
            ...state, filtros: {
               ...state.filtros,
               empresa: action.payload
            }
         };

      case FILTER_ESTADO_PAGO:
         return {
            ...state, filtros: {
               ...state.filtros,
               estado_pago: action.payload
            }
         };

      case RESULTADOS_REPORTE_VENTAS:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
import React, { useEffect, useState } from 'react'


// Components
import AccordionTable from '../../layout/AccordionTable'
// import Pagination from '../PaginationR'


// Hooks
import Button from '../Button'
// import Modal from '../Modal'
// import TextareaInput from '../TextareaInput'
import Table from '../Table'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { BASE_URL, PATH_URL } from '../../endpoints'
import { fireToaster } from '../../redux/actions/toaster'
import Modal from '../Modal'
import FileUploaderB from '../FileUploader_B'
import { PrecioFormatter } from '../../helpers/precioFormatter'
// import { renderRecargoInstalacion } from '../../helpers/renderRecargoInstalacion'
import Icon from '../Icon'
import { useCopyClipboard } from '../../hooks/useCopyClipboard'
import DropdownWrapper from '../Dropdown/Wrapper'
import DropdownMenuLink from '../Dropdown/Link'
import SelectFilter from '../SelectFilter'
import { getAll } from '../../endpoints/getAll'
import { updateEstatusPedido } from '../../services/pedidos/updateEstatusPedido.service'
import { idExtractor } from '../../helpers/idExtractor'
import { getAllAgendamientos } from '../../services/agendamientos/getAll.service'
import { clearFilterAgendamientos, resultadosAgendamientos } from '../../redux/actions/agendamientos'


const CONSTANT = {
  title: 'Agendamientos',
  path: 'agendamientos',
  titleSecond: 'Resumen',
  titleThird: 'Pedidos agendados sin nota de venta',
  fecthMaximiseData: BASE_URL + PATH_URL + '/erp/notas-ventas/lineas/listar',
  fetchNvPdf: (nv) => `${BASE_URL}${PATH_URL}/erp/notas-ventas/${nv}/pdf`,
  acordeones: { productos: false, productosErp: false },
  ordenForm: { nota_venta: '' },
  fetchEstatusPedido: getAll.estatus_pedido,
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  reduxClearFilters: clearFilterAgendamientos,
  reduxSetResults: resultadosAgendamientos,

  modalTarget: {
    orden: 'orden',
    productos: 'productos'
  },

  editForm: {
    estatus_pedido: {
      target: 'estatus_pedido',
      state: false,
      form: {
        estatus_pedido_id: ''
      }
    },
  },

  select: {
    estatusPedido: []
  },

  modalState: { target: '', display: false, data: null }
}


const fetchSelectFiltersData = async () => {
  const estatusPedido = await axios(CONSTANT.fetchEstatusPedido,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { estatusPedido }
}

const Precio = (v) => new PrecioFormatter(v).producto()
const PrecioRaw = (v) => new PrecioFormatter(v).producto(true)


const AgendamientosTable = () => {
  const dispatch = useDispatch()
  const { resultados: response, filtros } = useSelector(state => state.agendamientos)
  const { user } = useSelector(state => state.auth)
  const [resultados, setResultados] = useState([])
  const [acordeones, setAcordeones] = useState(CONSTANT.acordeones)
  const [modal, setModal] = useState(CONSTANT.modalState)
  const [ordenImg, setOrdenImg] = useState([])
  const [ordenForm, setOrdenForm] = useState(CONSTANT.ordenForm)
  const [select, setSelect] = useState(CONSTANT.select)
  const [copy] = useCopyClipboard()

  const [editForm, setEditForm] = useState(CONSTANT.editForm)

  useEffect(() => {
    fetchSelectFiltersData()
      .then(x => setSelect(x))
  }, [])


  // EFECTO QUE FORMATEA LA RESPUESTA DE AGENDAMIENTOS CON LA RESPUESTA DEL MAXIMISE
  useEffect(() => {
    if (!response?.agendamientos?.data) return
    const data = response?.agendamientos?.data.map(({ nota_venta }) => nota_venta) ?? []

    axios(CONSTANT.fecthMaximiseData, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
      data: { notas_ventas: data }
    })
      .then(({ data }) => {
        setResultados(
          response?.agendamientos?.data.map(({ nota_venta }, i, arr) => {
            const erp_data = data.data[nota_venta]
            return { ...arr[i], erp_data }
          })
        )
      })
      .catch(err => {
        if (!data.length) {
          setResultados(response?.data)
          return dispatch(fireToaster({ title: 'No se encontraron resultados para los filtros seleccionados', icon: 'info' }))
        }
        console.error(err)
        dispatch(fireToaster({ title: 'No se pudo establacer conexión con el Maximise', icon: 'info' }))
        setResultados(response?.data)
      })
  }, [response, dispatch])


  const handleInputChange = (e) => {
    const { name, value } = e.target

    if (name.includes('editForm')) {
      const [_, targetForm, key] = name.split('%-%')

      setEditForm({
        ...editForm,
        [targetForm]: {
          ...editForm[targetForm],
          form: {
            ...editForm[targetForm].form,
            [key]: value
          }
        }
      })
    }
  }

  // FUNCION MANEJADORA DE LOS ACORDEONES
  const handleInnerAccordion = (value, target) => {
    setAcordeones({ ...acordeones, [target]: !value })
  }


  const handleModalData = (data, target) => {
    if (target === 'orden') {
      const selectedData = resultados.find(({ nota_venta }) => data === nota_venta)
      const pedido_id = selectedData.id
      const { id: idEstatusPedido, nombre: nombreEstatusPedido } = selectedData?.estatus_pedido

      setOrdenForm({
        ...ordenForm,
        nota_venta: data
      })

      setEditForm(prev => ({
        ...prev,
        estatus_pedido: {
          ...prev.estatus_pedido,
          form: {
            ...prev.form,
            estatus_pedido_id: `${idEstatusPedido ?? '*NULL*'}%-%${nombreEstatusPedido ?? 'Seleccione'}`,
            pedido_id
          }
        }
      }))
    }

    setModal({ target, display: true, data })
  }


  const handleFetch = async (objData, target) => {
    let url

    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    }

    if (target === 'orden') {

      // if (!ordenImg?.length) dispatch(fireToaster({ title: 'Debe enviar al menos una imagen', icon: 'info' }))
      if (!ordenForm.nota_venta) dispatch(fireToaster({ title: 'El agendamiento debe tener una nota de venta asociada', icon: 'info' }))

      url = `${BASE_URL}${PATH_URL}/erp/notas-ventas/cierre-orden/evidencia/${ordenForm.nota_venta}`
      let data = new FormData()
      ordenImg.forEach(({ file }) => {
        data.append('imagenes[]', file)
      })

      const estatusPedidoForm = {
        id: editForm.estatus_pedido.form.pedido_id,
        estatus_pedido_id: Number(idExtractor(editForm.estatus_pedido.form.estatus_pedido_id, true))
      }

      config = {
        ...config,
        headers: {
          ...config.headers,
          'Content-Type': 'multipart/form-data'
        },
        method: 'POST',
        data
      }

      await updateEstatusPedido(estatusPedidoForm)

      if (!ordenImg.length) {
        await getAllAgendamientos(undefined, filtros)
          .then(({ data }) => {
            const toasterContent = {
              title: 'Operación realizada',
              text: `${CONSTANT.title}: ${target} actualizado con éxito`,
              icon: 'success'
            }

            dispatch(fireToaster(toasterContent))
            dispatch(CONSTANT.reduxSetResults(data))
            setModal({ ...modal, display: false })
          })
          .catch(err => {
            console.error(err)
            dispatch(CONSTANT.reduxSetResults([]))
          })

        return
      }
    }

    await axios(url, config)
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${target} actualizado con éxito`,
          icon: 'success'
        }

        dispatch(fireToaster(toasterContent))
        setModal({ ...modal, display: false })
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))
        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
      })

  }

  return (
    <>
      <Modal size='w-100' display={modal.display} handleValue={() => setModal({ data: null, display: false })}>
        {modal.target === CONSTANT.modalTarget.orden &&
          <>
            <div className='m-2' style={{ minHeight: '400px' }}>
              <h3 className='text-primary p-50 rounded-2 m-0'>Cerrar Orden {ordenForm?.nota_venta ?? 'N/A'}</h3>
              <hr className='mt-0 mb-2' />

              <div className='d-flex justify-content-between gap-50 col-3'>
                <SelectFilter
                  altSeparator
                  labelText='Estatus'
                  name='editForm%-%estatus_pedido%-%estatus_pedido_id'
                  value={editForm.estatus_pedido.form.estatus_pedido_id?.split('%-%')[1] ?? 'Seleccione'}
                  handleValue={handleInputChange}
                  optionObj={select.estatusPedido.map(({ id, nombre }) => ({ id: id ?? '*NULL*', name: nombre ?? 'Error' }))}
                  size='col-12'
                  sizeDesk='col-md-12'
                />
              </div>

              <hr className='my-1' />

              <div className='col mb-50'>
                <FileUploaderB value={ordenImg} handleValue={setOrdenImg} />
              </div>

              <div className='w-fit mx-auto'>
                <Button text='Adjuntar' color='success' onClick={() => handleFetch(ordenForm, 'orden')} />
              </div>
            </div>
          </>
        }

        {modal.target === CONSTANT.modalTarget.productos &&
          <>
            <AccordionTable title={`Productos`} classes='mb-2'>
              <Table pb={false}>
                <thead>
                  <tr>
                    <th>Descripción</th>
                    <th>SKU</th>
                    <th>Qty</th>
                    <th>Precio instalacion</th>
                    <th>Recargo</th>
                    <th>P. unit. C/IVA</th>
                    <th>P. total C/IVA</th>
                  </tr>
                </thead>
                <tbody>
                  {modal?.data?.lineas_pedido.length
                    ? modal?.data?.lineas_pedido.map(({ cantidad_comprada, producto, precio_unidad, precio_total, recargo, precio_instalacion }, i) => (
                      <tr key={`lineas-pedido-local-${producto?.nombre ?? 'producto'}-${producto?.sku ?? i}`}>
                        <td>
                          <a href={`/producto/detalle/${producto?.id}`} target='_blank' rel='noreferrer'>
                            {producto?.nombre.toUpperCase() ?? 'N/A'}
                          </a>
                        </td>
                        <td>{producto?.sku ?? 'N/A'}</td>
                        <td>{cantidad_comprada?.split('.')[0] ?? 'N/A'}</td>
                        <td>{Precio(recargo)}</td>
                        <td>{Precio(precio_instalacion)}</td>
                        <td>{Precio(precio_unidad)}</td>
                        <td>{Precio(precio_total)}</td>
                      </tr>
                    ))
                    : <tr><td colSpan={8}><p className='tag dark-blue px-50 m-md-auto'><b>Sin productos</b></p></td></tr>
                  }
                </tbody>
              </Table>
            </AccordionTable>

            <AccordionTable title={`Productos ERP`}>
              <Table pb={false}>
                <thead>
                  <tr>
                    <th>Descripción</th>
                    <th>SKU</th>
                    <th>Bodega</th>
                    <th>Qty</th>
                    <th>Qty reservada</th>
                    <th>Qty pdte.</th>
                    <th>P. unit. C/IVA</th>
                    <th>P. total C/IVA</th>
                  </tr>
                </thead>
                <tbody>
                  {modal.data.erp_data?.length
                    ? modal.data.erp_data?.map(({ DESC_TEXT, PART_CODE, WARE_CODE, UD_MONTO_INSTALACION, UD_RECARGO, UOM_QTY, SCHED_QTY, BACK_QTY, UNIT_PRICE_AMT, EXT_PRICE_AMT }, i) => (
                      <tr key={`lineas-pedido-erp-${DESC_TEXT ?? 'producto'}-${i ?? PART_CODE}`}>
                        <td>{DESC_TEXT.toUpperCase() ?? 'N/A'}</td>
                        <td>{PART_CODE ?? 'N/A'}</td>
                        <td>{WARE_CODE ?? 'N/A'}</td>
                        <td>{UOM_QTY ?? 'N/A'}</td>
                        <td>{SCHED_QTY ?? 'N/A'}</td>
                        <td>{BACK_QTY ?? 'N/A'}</td>
                        <td>{Precio(UNIT_PRICE_AMT)}</td>
                        <td>{Precio(EXT_PRICE_AMT)}</td>
                      </tr>
                    ))
                    : <tr><td colSpan={8}><p className='tag dark-blue px-50 m-md-auto'><b>Sin productos</b></p></td></tr>
                  }
                </tbody>
              </Table>
            </AccordionTable>
          </>
        }
      </Modal>

      <div className='d-flex gap-1 justify-content-end mb-1'>
        <div className='w-fit'>
          <Button onClick={() => handleInnerAccordion(acordeones.productos, 'productos')} text='Productos' icon={acordeones.productos ? 'Minimize2' : 'Maximize2'} />
        </div>

        <div className='w-fit'>
          <Button onClick={() => handleInnerAccordion(acordeones.productosErp, 'productosErp')} text='Productos ERP' icon={acordeones.productosErp ? 'Minimize2' : 'Maximize2'} />
        </div>
      </div>

      <AccordionTable
        title={`Pedidos agendados (${resultados?.length ?? '?'})`}
        classes='mb-2'
      >
        <Table pb={false}>
          <thead className="table-light position-sticky top-0 w-100 z-100 shadow text-center">
            <tr role="row">
              <th>Datos</th>
              <th className='px-25'>Cliente</th>
              <th className='px-25'>Punto Entrega</th>
              <th className='px-25'>Método Pago</th>
              <th>Total</th>
              <th>Productos</th>
              <th>Pago | Deuda</th>
              <th>Agenda</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {resultados?.length
              ? resultados?.map((
                { agendamiento, usuario, punto_entrega, metodo_pago, tipo_facturacion, nota_venta, numero_seguimiento, observaciones, erp_data,
                  direccion_entrega, monto_total_pedido, monto_subtotal, monto_pagado, estatus_pedido, total_productos, lineas_pedido, created_at },
                i, arr) => (
                <tr key={`tr-instalada-${agendamiento?.pedido_id ?? agendamiento?.fecha}`} style={{ borderBottom: '1px solid #555' }} className={`odd multi-data text-nowrap text-center ${monto_total_pedido - monto_pagado > 0 ? 'table-oc' : ''}`}>

                  {/* DATOS */}
                  <td className='text-black py-2'>
                    {nota_venta
                      ? <a className='fs-4' href={`/erp/notas-ventas/detalle/${nota_venta}`} rel='noreferrer' target='_blank'>
                        <b>{nota_venta ?? 'N/A'}</b>
                      </a>

                      : <b>Sin NV</b>
                    }

                    {agendamiento?.pedido_id
                      ? <p>Pedido: <a href={`/pedidos/detalle/${agendamiento?.pedido_id}`} target='_blank' rel='noreferrer'>
                        #{agendamiento?.pedido_id}
                      </a></p>
                      : <p>Sin pedido</p>
                    }

                    <p className='tag success px-25'>{estatus_pedido?.nombre ?? '?'}</p>
                    {agendamiento.observacion &&
                      <Button
                        text='Observación'
                        className='p-25 my-25 mx-auto'
                        style={'outline'}
                        onClick={() => { }}
                      />
                    }

                    <span className='subtext-5'>{created_at.split('T')[0] ?? '?'}</span>

                    <Button
                      onClick={() => handleModalData(nota_venta, CONSTANT.modalTarget.orden)}
                      text='Cerrar Orden'
                      className='p-25 px-50 mx-auto mt-1'
                    />
                  </td>

                  {/* CLIENTE */}
                  <td className='px-25'>
                    <p className='text-black text-wrap'><b>{usuario?.nombre ?? 'N/A'}</b></p>
                    <p className='sub-text-2'>{usuario?.rut ?? 'N/A'}</p>
                    <p className='sub-text-2'>{usuario?.pais?.nombre ?? 'N/A'}</p>
                  </td>

                  {/* PUNTO ENTREGA */}
                  <td className='px-25'>
                    <p className='text-black'><b>{direccion_entrega ?? 'N/A'}</b></p>
                    <p className='sub-text-2'>{punto_entrega?.tipo_destino?.nombre ?? 'N/A'}</p>
                    <p className='sub-text-2'>{punto_entrega?.comuna?.region?.nombre ?? 'N/A'}</p>
                    <p className='sub-text-2'>{punto_entrega?.comuna?.nombre ?? 'N/A'}</p>
                  </td>

                  {/* METODO PAGO */}
                  <td className='text-wrap px-25'>
                    <p className='text-black'><b>{metodo_pago?.nombre ?? 'N/A'}</b></p>
                    <p className='sub-text-2'>Facturación: <b>{tipo_facturacion?.nombre ?? 'N/A'}</b></p>
                  </td>

                  {/* TOTAL */}
                  <td >
                    <div>
                      <p className='text-black text-center fs-3 mb-50'> <b>{Precio(monto_total_pedido)}</b></p>
                      <p className='text-black text-center text-wrap'>Subtotal: <b className='text-nowrap'>{Precio(monto_subtotal)}</b></p>
                    </div>
                  </td>

                  {/* PRODUCTOS */}
                  <td>
                    <Button onClick={() => handleModalData(arr[i], CONSTANT.modalTarget.productos)} text='Productos' icon='Plus' className='mt-1 p-50' />
                  </td>

                  {/* DEUDA */}
                  <td className='text-center'>
                    <div className='d-flex flex-column'>
                      <span className='text-lgrey text-center'>Pagado: <b>{PrecioRaw(monto_pagado)}</b></span>
                      <span className='text-red mt-75'>Deuda:<b> {Precio(monto_total_pedido - monto_pagado)}</b></span>
                    </div>
                  </td>

                  {/* FECHA */}
                  <td className='text-nowrap'>
                    <div className='py-25 px-50 mt-50'>
                      {agendamiento
                        ? <div className='tag dark-blue px-50 mx-auto'>
                          <b>
                            <p className='text-center text-nowrap'>{agendamiento?.fecha?.split('T')[0] ?? '?'}</p>
                            <p className='text-center text-nowrap'>{agendamiento?.hora_agenda?.hora_inicio_intervalo} - {agendamiento?.hora_agenda?.hora_final_intervalo}</p>
                          </b>
                        </div>
                        : <p className='tag dark-blue px-50 mx-auto'><b>No agendado</b></p>
                      }
                    </div>
                  </td>

                  <td>
                    <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                      <>
                        {(user.permisos['notas-venta.exportar'] && nota_venta) &&
                          <DropdownMenuLink
                            text='Exportar'
                            href={CONSTANT.fetchNvPdf(nota_venta)}
                            isInnerLink={false}
                            icon="Download"
                          />
                        }
                      </>
                    </DropdownWrapper>
                  </td>
                </tr>
              ))
              : <tr><td colSpan={9}><p className='tag dark-blue px-50 mx-md-auto'><b>Sin resultados para mostrar</b></p></td></tr>
            }
          </tbody>
        </Table>

      </AccordionTable>

      {/* RESUMEN */}
      <AccordionTable title={`${CONSTANT.titleSecond} (${response ? Object.entries(response?.agendamientos_por_punto)?.length : 0} resultados)`} classes='mt-2'>
        <Table pb={false}>
          <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
            <tr role="row">
              <th className='col table-col-min'>Método de entrega</th>
              <th className='col'>Punto de entrega | Notas de venta</th>
            </tr>
          </thead>
          <tbody>
            {response && response?.agendamientos_nv
              &&
              <tr key={`planificacion-rw-todos`} className={`odd multi-data`} style={{backgroundColor: '#e9ffd6'}}>
                {/* Método de entrega */}
                <td><b>TODOS</b></td>

                {/* Punto de entrega */}
                <td>
                  <div key={`planificacion-ry-todos`} className={`d-flex gap-2 p-25 border rounded mb-50`}>
                    <b>TODOS</b>
                    <div className='d-flex' onClick={() => copy(response?.agendamientos_nv)}>
                      <Icon icon='Clipboard' size={20} className='cursor-pointer' />
                    </div>
                    <span>{response?.agendamientos_nv}</span>
                  </div>
                </td>
              </tr>
            }

            {response && Object?.entries(response?.agendamientos_por_punto)?.length
              ? Object?.entries(response?.agendamientos_por_punto)?.map(([k, v], i) => {

                return (
                  <tr key={`planificacion-rw-${i}`} className={`odd multi-data`}>
                    {/* Método de entrega */}
                    <td><b>{k === '' ? 'Sin asignar' : k}</b></td>

                    {/* Punto de entrega */}
                    <td>
                      {Object?.entries(v)?.map(([k, v], i) => (
                        <div key={`planificacion-ry-${i}`} className={`d-flex gap-2 p-25 border rounded mb-50`}>
                          <b>{k}</b>
                          {
                            v !== '' && (
                              <div className='d-flex' onClick={() => copy(v)}>
                                <Icon icon='Clipboard' size={20} className='cursor-pointer' />
                              </div>
                            )
                          }
                          <span>{v === '' ? 'Sin notas de venta' : v}</span>
                        </div>
                      ))}
                    </td>
                  </tr>
                )
              })
              : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
            }
          </tbody>
        </Table>
      </AccordionTable >

      {/* RESUMEN */}
      <AccordionTable title={`${CONSTANT.titleThird}`} classes='mt-2'>
        <Table pb={false}>
          <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
            <tr role="row">
              <th className='col table-col-min'>Punto de entrega</th>
              <th className='col'>Punto de entrega | Notas de venta</th>
            </tr>
          </thead>
          <tbody>
            {response && Object?.entries(response?.agendamientos_sin_nv)?.length
              ? Object?.entries(response?.agendamientos_sin_nv)?.map(([k, v], i) => {

                return (
                  <tr key={`planificacion-row-${i}`} className={`odd multi-data`}>
                    {/* Método de entrega */}
                    <td><b>{k === '' ? 'Sin asignar' : k}</b></td>

                    {/* Punto de entrega */}
                    <td>
                      {Object?.entries(v)?.map(([k, v], i) => (
                        <div key={`planificacion-row-y-${i}`} className={`d-flex gap-2 p-25 border rounded mb-50`}>
                          <b>{k}</b>
                          {
                            v !== '' && (
                              <div className='d-flex' onClick={() => copy(v)}>
                                <Icon icon='Clipboard' size={20} className='cursor-pointer' />
                              </div>
                            )
                          }
                          <span>{v === '' ? 'Sin notas de venta' : v}</span>
                        </div>
                      ))}
                    </td>
                  </tr>
                )
              })
              : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
            }
          </tbody>
        </Table>
      </AccordionTable >
    </>
  )
}

export default AgendamientosTable
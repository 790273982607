/**
 * Función que verifica y acorta un número, de no ser un 'number' retorna 'NaN'
 * 
 */
export const sanitaze = (number) => {
   if (typeof number !== 'number') {
      console.error(new Error(`The argument provided ins't a number. Error cause: ${number}`))
      return 'NaN'
   }
   if (number > 1000 && number < 1000000) return `${Math.floor(number).toPrecision(3) / 1000}k`
   if (number > 1000000) return `${Math.floor(number).toPrecision(3) / 1000000}M`
   else return number
}
import { Navigate, Route, Routes } from "react-router-dom"
import Create from "../views/Usuarios/Create";
import Update from "../views/Usuarios/Update";
import Listado from "../views/Usuarios/Listado";
import Detalle from "../views/Usuarios/Detalle";
import { useSelector } from "react-redux";


const UsuariosRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['usuarios.crear'] &&
            <Route path='/create' element={<Create />} />
         }

         {user.permisos['usuarios.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         {user.permisos['usuarios.editar'] &&
            <Route path='/update/:id' element={<Update />} />
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}

export default UsuariosRouter;
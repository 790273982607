import { useEffect, useState } from "react";
import axios from "axios";

// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import SelectFilter from "../../components/SelectFilter";
import CustomLoader from "../../components/CustomLoader";
import TextareaInput from "../../components/TextareaInput";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { getAll } from "../../endpoints/getAll";
import { getOne } from "../../endpoints/getOne";


// Helpers
import { hourFormatter } from "../../helpers/hourFormatter";


const hF = (s) => s.split('T')[1].split(':').map((t, i) => i < 2 ? t : null).filter(el => el !== null).join(':')


const CONSTANT = {
  title: 'Ofertas',
  componentTitle: 'Editar oferta',
  redirectUrl: '/ofertas',
  queryUrl: getOne.ofertas,
  queryGetAllCriteriosComparacion: getAll.criterios_comparacion
}


const Update = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetchData, setFetchData] = useState(null)
  const [criteriosComparacionSelect, setCriteriosComparacionSelect] = useState(null)
  const { id } = useParams()


  // MANEJADOR DE LOS INPUTS
  const handleInputChange = (e) => {
    const { value, name } = e.target

    setFetchData({
      ...fetchData,
      [name]: value
    })
  }

  // MANEJADOR PARA RELLENAR LA DATA DE LOS SELECTFILTERS
  const handleSelects = () => {
    axios(CONSTANT.queryGetAllCriteriosComparacion,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => setCriteriosComparacionSelect(data))
      .catch(err => console.error(err))
  }


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS Y SETEA fetchData
  useEffect(() => {
    handleSelects()

    axios(CONSTANT.queryUrl(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        const res = data.oferta
        const horaInicio = hF(res?.fecha_hora_inicio)
        const horaFinal = hF(res?.fecha_hora_final)

        setFetchData({
          ...fetchData,
          nombre: `${res?.nombre}`,
          criterio_comparacion_id: `${res?.criterio_comparacion_id}-${res?.criterio_comparacion.nombre}`,
          descripcion: `${res?.descripcion}`,
          fecha_inicio: `${res?.fecha_hora_inicio.split('T')[0]}`,
          hora_inicio: horaInicio,
          fecha_final: `${res?.fecha_hora_final.split('T')[0]}`,
          hora_final: horaFinal,
          cantidad_limite: res?.cantidad_limite,
          cantidad_venta_caducidad: `${res?.cantidad_venta_caducidad}`,
          porcentaje_descuento: res?.porcentaje_descuento,
          monto_descuento: res?.monto_descuento,

          estatus: 1,
        })

      })
      .catch(err => console.error(err))

    return () => {
      setFetchData(null)
    }
  }, [id])


  // FUNCIÓN MANEJADORA QUE EDITA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    if (!fetchData.criterio_comparacion_id) return dispatch(fireToaster({ title: 'Falta el criterio de comparación', icon: 'info' }))
    // if (fetchData.cantidad_limite || fetchData.cantidad_limite?.includes('.')) return dispatch(fireToaster({ title: 'La cantidad límite debe ser un entero', icon: 'info' }))
    // if (fetchData.cantidad_limite || fetchData.cantidad_venta_caducidad?.includes('.')) return dispatch(fireToaster({ title: 'La cantidad venta/caducidad debe ser un entero', icon: 'info' }))
    if (!fetchData.fecha_inicio || !fetchData.hora_inicio) return dispatch(fireToaster({ title: 'Falta fecha/hora de inicio', icon: 'info' }))
    if (!fetchData.fecha_final || !fetchData.hora_final) return dispatch(fireToaster({ title: 'Falta fecha/hora final', icon: 'info' }))

    let requestData = {
      nombre: fetchData?.nombre,
      descripcion: fetchData?.descripcion,

      criterio_comparacion_id: Number(fetchData?.criterio_comparacion_id?.split('-')[0]),
      cantidad_limite: Math.round(Number(fetchData?.cantidad_limite)) ?? null,
      cantidad_venta_caducidad: Math.round(Number(fetchData?.cantidad_venta_caducidad)) ?? null,

      fecha_hora_inicio: `${fetchData?.fecha_inicio} ${hourFormatter(fetchData?.hora_inicio)}`,
      fecha_hora_final: `${fetchData?.fecha_final} ${hourFormatter(fetchData?.hora_final)}`,
      estatus: Number(fetchData?.estatus)
    }

    if (fetchData?.porcentaje_descuento?.length) requestData = { ...requestData, porcentaje_descuento: Number(fetchData?.porcentaje_descuento) }
    if (fetchData?.monto_descuento?.length) requestData = { ...requestData, monto_descuento: Number(fetchData?.monto_descuento) }

    // return console.log(requestData);

    axios(CONSTANT.queryUrl(id), {
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        _method: 'PUT'
      },
      data: requestData
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} actualizada con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>
        {criteriosComparacionSelect && fetchData
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                name="estatus"
                value={fetchData?.estatus}
                labelText="Estatus"
                handleValue={handleInputChange}
                size="col-12"
              />

              <SelectFilter
                labelText="* Criterio de comparación"
                name="criterio_comparacion_id"
                value={fetchData?.criterio_comparacion_id?.split('-')[1]}
                handleValue={handleInputChange}
                optionObj={criteriosComparacionSelect?.map(({ nombre, id }) => ({ name: nombre, id }))}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Nombre"
                name='nombre'
                handleValue={handleInputChange}
                value={fetchData?.nombre}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="Cantidad límite"
                type="number"
                name='cantidad_limite'
                handleValue={handleInputChange}
                value={fetchData?.cantidad_limite}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="Cantidad venta/caducidad"
                type="number"
                name='cantidad_venta_caducidad'
                handleValue={handleInputChange}
                value={fetchData?.cantidad_venta_caducidad}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                isDisabled={fetchData?.monto_descuento}
                labelText="Porcentaje descuento"
                type="number"
                name='porcentaje_descuento'
                handleValue={handleInputChange}
                value={fetchData?.monto_descuento ? undefined : fetchData?.porcentaje_descuento}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                isDisabled={fetchData?.porcentaje_descuento}
                labelText="Monto descuento"
                type="number"
                name='monto_descuento'
                handleValue={handleInputChange}
                value={fetchData?.porcentaje_descuento ? undefined : fetchData?.monto_descuento}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <TextareaInput
                labelText="Descripción"
                name='descripcion'
                handleValue={handleInputChange}
                value={fetchData?.descripcion}
                size="col-12"
                sizeDesk="col-md-12"
                rows={2}
              />

              <FormInput
                labelText="* Fecha inicio"
                type="date"
                handleValue={handleInputChange}
                max={fetchData?.fecha_final}
                name='fecha_inicio'
                value={fetchData?.fecha_inicio}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Hora inicio"
                type="time"
                name='hora_inicio'
                handleValue={handleInputChange}
                value={fetchData?.hora_inicio}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Fecha final"
                type="date"
                min={fetchData?.fecha_inicio}
                name='fecha_final'
                handleValue={handleInputChange}
                value={fetchData?.fecha_final}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Hora final"
                type="time"
                name='hora_final'
                handleValue={handleInputChange}
                value={fetchData?.hora_final}
                size="col-12"
                sizeDesk="col-md-6"
              />
            </div>
            <Button type="submit" text="Actualizar" color="primary" icon="Circle" onClick={(e) => handleFetch(e)} />
          </CustomForm>
          : <CustomLoader />
        }
      </div>
    </div>
  );
};

export default Update;

import {
   FILTER_ADD_ESTATUS,
   FILTER_ADD_PAIS,
   FILTER_REMOVE_PAIS,
   FILTER_REMOVE_ESTATUS,
   FILTER_CLEAR,
   FILTER_CREACION_DESDE,
   FILTER_BUSQUEDA,
   FILTER_CREACION_HASTA,
   FILTER_REMOVE_COD_MAXIMISE,
   FILTER_ADD_COD_MAXIMISE,
   FILTER_REMOVE_REGION,
   FILTER_ADD_REGION,
   FILTER_ADD_NOMBRE,
   FILTER_REMOVE_NOMBRE,
   RESULTADOS_COMUNAS
} from "../redux-types";


export const estatusFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_ESTATUS, payload: data }
      : { type: FILTER_ADD_ESTATUS, payload: data };

export const regionFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_REGION, payload: data }
      : { type: FILTER_ADD_REGION, payload: data };

export const nombreFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_NOMBRE, payload: data }
      : { type: FILTER_ADD_NOMBRE, payload: data };

export const paisFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_PAIS, payload: data }
      : { type: FILTER_ADD_PAIS, payload: data }

export const codMaximiseFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_COD_MAXIMISE, payload: data }
      : { type: FILTER_ADD_COD_MAXIMISE, payload: data }

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const creacionDesdeFilter = (data) => ({ type: FILTER_CREACION_DESDE, payload: data })

export const creacionHastaFilter = (data) => ({ type: FILTER_CREACION_HASTA, payload: data })

export const resultadosComunas = (data) => ({ type: RESULTADOS_COMUNAS, payload: data })

export const clearFilterComunas = () => ({ type: FILTER_CLEAR });

import axios from "axios"
import { getOne } from "../../endpoints/getOne"


export const getDetailedServicio = async (id) => {
  try {
    if (!id) throw Error(`Missing "id" servicio`)

    const response = await axios(getOne.servicios(id), {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    })

    if (response.status >= 400) throw response

    return response
  } catch (err) {
    console.error(`Error fetching servicio detail data`);
    return err
  }
}

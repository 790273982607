import axios from 'axios'
import { useEffect, useState } from 'react'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import Switch from '../../components/Switch'
import CustomLoader from '../../components/CustomLoader'


// Router Dom
import { useNavigate, useParams } from 'react-router-dom'


// Endpoints
import { getOne } from '../../endpoints/getOne'
import { historicoData } from '../../helpers/historicoData'
import { ModalHistorico } from '../../components/Historico/ModalHistorico'
import TablaDetalle from '../../components/Historico/TablaDetalle'
import { PrecioFormatter } from '../../helpers/precioFormatter'


const CONSTANT = {
  queryUrlGetOne: getOne.parametros_recargos,
  title: 'Parametros de recargo',
  redirectListado: '/generales/parametros-recargos',
  redirectUpdate: (id) => `/generales/parametros-recargos/update/${id}`,
  modelo: 'App\\Models\\NeuCiudad',
}

const Precio = (v) => new PrecioFormatter(v).producto()


const Detalle = () => {
  const [data, setData] = useState(null)
  const { id } = useParams()
  const dispatch = useDispatch()
  const redirect = useNavigate()
  const [historico, setHistorico] = useState(null)
  const [modal, setModal] = useState({ id: null, display: false, data: null })


  // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
  useEffect(() => {
    console.log(CONSTANT.queryUrlGetOne(id));
    axios(CONSTANT.queryUrlGetOne(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => setData(data))
      .catch(err => {
        console.error(err)
        const toasterContent = {
          title: 'Registro no encontrado',
          icon: 'warning'
        }

        dispatch(fireToaster(toasterContent))
        redirect(CONSTANT.redirectListado)
      })

    historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))

    return () => {
      setData(null)
    }
  }, [id])


  // FUNCIÓN MANEJADORA PARA ACTUALIZACIÓN DEL ESTATUS
  const handleFetch = async (objData, target) => {
    const newEstatus = objData[target] ? 0 : 1
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    }

    if (target === 'estatus') {
      config = {
        ...config,
        method: 'POST',
        data: { [target]: newEstatus, _method: 'PUT' }
      }
    }

    if (target === 'delete') {
      config = {
        ...config,
        method: 'DELETE',
      }
    }

    await axios(CONSTANT.queryUrlGetOne(objData.id), config)
      .then(res => {
        let toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${target} actualizado con éxito`,
          icon: 'success'
        }

        if (target === 'delete') {
          toasterContent = {
            title: 'Operación realizada',
            text: `${CONSTANT.title}: registro borrado con éxito`,
            icon: 'success'
          }
        }

        dispatch(fireToaster(toasterContent))

        if (target === 'delete') {
          redirect(CONSTANT.redirectListado)
        }
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
          Operación fallida
          (${err.response.status} - ${err.response.statusText})
        `,
          html: `
          <b>Detalle: </b>
          ${detalles.map(error => `<br /><i>-${error}</i>`)}
        `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
      })
      .finally(async () => {
        setData(null)

        await axios(CONSTANT.queryUrlGetOne(id),
          {
            headers: {
              'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
          })
          .then(({ data }) => {
            setData(data)
            historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
          })
          .catch(err => {
            console.error(err)
            const toasterContent = {
              title: 'Registro no encontrado',
              icon: 'warning'
            }

            dispatch(fireToaster(toasterContent))
            redirect(CONSTANT.redirectListado)
          })
      })
  }


  // FUNCION MANEJADORA DE LA DATA DEL MODAL
  const handleModal = (data) => {
    const { id, anterior, nuevo } = data

    setModal({
      display: true,
      id,
      data: {
        anterior,
        nuevo
      }
    })
  }

  
  return (
    <div className='row'>

      <ModalHistorico modal={modal} fnHandleModal={handleModal} />

      <div className='col-12'>
        <div className='col mb-50'>
          <div className='d-flex gap-1'>
            <div className='col'>
              <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
            </div>
            <Button text='Editar' icon='Settings' color='success' isLink url={CONSTANT.redirectUpdate(id)} />
            <Button text='Borrar' icon='Trash' color='danger' onClick={() => handleFetch(data, 'delete')} />
          </div>
        </div>

        {data
          ? <>
            <Accordion
              title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
              classes='col-md-6 mx-auto mb-2'
              isOpen
            >
              <AccordionDetailData dataKey={'ID'} value={data?.id} />
              <AccordionDetailData dataKey={'Nombre'} value={data?.atributo?.nombre ?? "N/A"} />
              <AccordionDetailData dataKey={'Punto de entrega'} value={`${data?.punto_entrega?.descripcion ?? "Sin descripción"} | ${data?.punto_entrega?.direccion ?? "Sin dirección"}, ${data?.punto_entrega?.nombre ?? "sin nombre"}`} />
              <AccordionDetailData dataKey={'Recargo'} value={Precio(data?.recargo)} />
              <AccordionDetailData dataKey={'Precio inst.'} value={Precio(data?.precio_instalacion)} />
              <AccordionDetailData dataKey={'Creación'} value={data?.created_at?.split('T')[0] ?? 'N/A'} />
              <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at?.split('T')[0] ?? 'N/A'} last />
            </Accordion>

            <TablaDetalle historicoData={historico} fnHandleModal={handleModal} />
          </>

          : <CustomLoader />
        }
      </div>
    </div>
  )
}

export default Detalle
export const toasterErrorFormatter = ({ errors = {}, response }) => {
  let detalles = []
  let errorTitle = response.statusText.length ? response.statusText : 'Error desconocido'
  const errorsObj = Object.values(errors)

  if (!errorsObj.length) {
    detalles = ['Ha ocurrido un error desconocido']
  }

  if (typeof errorsObj?.[0] === 'string') {
    detalles = [errorsObj?.[0]]
  }

  else {
    errorsObj?.forEach(errorArray => errorArray.forEach(error => detalles.push(error)))
  }

  return {
    title: `
            Operación fallida
            (${response.status} - ${errorTitle})
            `,
    html: `
            <b> Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
            `,
    icon: 'error'
  }
}
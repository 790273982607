import axios from 'axios'
import { useEffect, useState } from 'react'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import Switch from '../../components/Switch'
import CustomLoader from '../../components/CustomLoader'


// Router Dom
import { useNavigate, useParams } from 'react-router-dom'


// Endpoints
import { getOne } from '../../endpoints/getOne'
import { BASE_URL } from '../../endpoints'
import { getAll } from '../../endpoints/getAll'
import Table from '../../components/Table'
import { historicoData } from '../../helpers/historicoData'
import { ModalHistorico } from '../../components/Historico/ModalHistorico'
import TablaDetalle from '../../components/Historico/TablaDetalle'


const CONSTANT = {
  queryUrlGetOne: getOne.etiquetas,
  queryUrlGetProductosEtiqueta: getAll.etiquetas_productos,
  title: 'Etiquetas',
  redirectUpdate: (id) => `/etiquetas/update/${id}`,
  redirectListado: '/etiquetas',
  imagen: (data) => `${BASE_URL}storage/${data?.imagen}` ?? '/placeholder.png',
  modelo: 'App\\Models\\NeuEtiqueta',
}


const Detalle = () => {
  const [data, setData] = useState(null)
  const [productos, setProductos] = useState(null)
  const { id } = useParams()
  const dispatch = useDispatch()
  const redirect = useNavigate()
  const [historico, setHistorico] = useState(null)
  const [modal, setModal] = useState({ id: null, display: false, data: null })


  // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
  useEffect(() => {
    axios(CONSTANT.queryUrlGetOne(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        setData(data?.etiqueta)
        historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
      })
      .catch(err => {
        console.error(err)
        const toasterContent = {
          title: 'Registro no encontrado',
          icon: 'warning'
        }

        dispatch(fireToaster(toasterContent))
        redirect(CONSTANT.redirectListado)
      })

    axios(CONSTANT.queryUrlGetProductosEtiqueta + '/listar?limit=99999',
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: { etiquetas_ids: [id] }
      })
      .then(({ data }) => setProductos(data?.etiquetas_productos))
      .catch(err => console.error(err))


    return () => {
      setData(null)
      setProductos(null)
    }
  }, [id, dispatch, redirect])


  // FUNCIÓN MANEJADORA PARA ACTUALIZACIÓN DEL ESTATUS
  const handleFetch = async (objData, target) => {
    let newEstatus = objData.estatus ? 0 : 1
    let data = { [target]: newEstatus, _method: 'PUT' }
    let method = 'POST'
    let url = CONSTANT.queryUrlGetOne(objData.id)
    let headers = {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
      'Content-Type': 'multipart/form-data'
    }

    if (target === 'estatus-etiqueta-producto') {
      headers = {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'application/json'
      }
      url = CONSTANT.queryUrlGetProductosEtiqueta + '/edicion-masiva'
      method = 'PUT'
      data = {
        ediciones: [
          {
            estatus: newEstatus,
            id: objData.id,
            fecha_hora_inicio: objData.fecha_hora_inicio,
            fecha_hora_final: objData.fecha_hora_final,
            prioridad_resultado: Number(objData.prioridad)
          }
        ]
      }
    }

    await axios(url, {
      method,
      headers,
      data
    })
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: estatus actualizado con éxito`,
          icon: 'success'
        }

        dispatch(fireToaster(toasterContent))
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
      })
      .finally(async () => {
        await axios(CONSTANT.queryUrlGetOne(id),
          {
            headers: {
              'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
          })
          .then(({ data }) => {
            setData(data?.etiqueta)
            historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
          })
          .catch(err => {
            console.error(err)
            const toasterContent = {
              title: 'Registro no encontrado',
              icon: 'warning'
            }

            dispatch(fireToaster(toasterContent))
            redirect(CONSTANT.redirectListado)
          })
      })
  }


  // FUNCION MANEJADORA DE LA DATA DEL MODAL
  const handleModal = (data) => {
    const { id, anterior, nuevo } = data

    setModal({
      display: true,
      id,
      data: {
        anterior,
        nuevo
      }
    })
  }


  return (
    <div className='row'>

      <ModalHistorico modal={modal} fnHandleModal={setModal} />

      <div className='col-12'>
        <div className='col mb-50'>
          <div className='d-flex gap-1'>
            <div className='col'>
              <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
            </div>
            <Button text='Editar' icon='Settings' color='success' isLink url={CONSTANT.redirectUpdate(id)} />
            {/* <Button text='Borrar' icon='Trash' color='danger' onClick={() => alert('Implementar borrado, luego de reparar el endpoint')} /> */}
          </div>
        </div>

        {data
          ? <div className='row'>
            <div className='col-12 col-md-6 mx-auto mb-2'>
              <Accordion
                title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
                isOpen
              >
                <AccordionDetailData dataKey={'ID'} value={data?.id} />
                <AccordionDetailData dataKey={'Nombre'} value={data?.nombre ?? 'N/A'} />
                <AccordionDetailData dataKey={'Fecha-hora Inicio/Final'} value={
                  <div className='d-flex flex-column'>
                    <span>Inicio: <b>{`${data?.fecha_hora_inicio?.split('T')[0]} | ${data?.fecha_hora_inicio?.split('T')[1].split('.')[0]}`}</b></span>
                    <span>Final: <b>{`${data?.fecha_hora_final?.split('T')[0]} | ${data?.fecha_hora_final?.split('T')[1].split('.')[0]}`}</b></span>
                  </div>
                } />
                <AccordionDetailData dataKey={'Oferta'} value={data?.neu_oferta?.nombre ?? data?.oferta?.nombre ?? 'N/A'} />
                <AccordionDetailData dataKey={'Prioridad'} value={data?.prioridad ?? 'N/A'} />
                <AccordionDetailData dataKey={'Descripción'} value={data?.descripcion ?? 'N/A'} />
                <AccordionDetailData dataKey={'Estatus'} value={
                  <Switch
                    value={data?.estatus}
                    labelText={""}
                    margin='my-0'
                    handleValue={() => handleFetch(data, 'estatus')}
                  />} />
                <AccordionDetailData dataKey={'Imagen'} value={
                  <img className="height-listado" src={CONSTANT?.imagen(data)} alt={data?.nombre} />
                } />
                <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at?.split('T')[0] ?? 'N/A'} />
                <AccordionDetailData dataKey={'Creación'} value={data?.created_at?.split('T')[0] ?? 'N/A'} last />
              </Accordion>
            </div>

            <div className='col-12 col-md-6 mx-auto mb-2'>
              <Accordion
                title={<h3 className='m-0 w-100 text-center text-white'>Productos relacionados</h3>}
                isOpen>
                <Table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Producto</th>
                      <th>Fecha</th>
                      <th>Estatus</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productos?.length
                      ? productos.map(({ categoria, estatus_etiqueta_producto, etiqueta_producto_id, final_etiqueta_producto, inicio_etiqueta_producto, precio_final, producto, sku, stock_propio }) => (
                        <tr key={`etq-prod-${etiqueta_producto_id}`}>
                          <td>{etiqueta_producto_id}</td>
                          <td>
                            <div className='d-flex flex-column'>
                              <span >{categoria ?? 'N/A'}</span>
                              <b>{producto.toUpperCase() ?? 'N/A'}</b>
                              <span >{sku ?? 'N/A'}</span>
                              <span >Stock: <b>{stock_propio ?? 'N/A'}</b> || Precio: <b>${precio_final ?? '?'}</b></span>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex flex-column'>
                              <span>Inicio:</span>
                              <b>{inicio_etiqueta_producto}</b>
                              <span className='mt-50'>Final:</span>
                              <b>{final_etiqueta_producto}</b>
                            </div>
                          </td>
                          <td>
                            <Switch
                              value={estatus_etiqueta_producto}
                              labelText={""}
                              margin='my-0'
                              handleValue={() => handleFetch(
                                {
                                  id: etiqueta_producto_id,
                                  estatus: estatus_etiqueta_producto,
                                  fecha_hora_inicio: inicio_etiqueta_producto,
                                  fecha_hora_final: final_etiqueta_producto,
                                  prioridad: data?.prioridad ?? 1
                                },
                                'estatus-etiqueta-producto')
                              }
                            />
                          </td>
                        </tr>
                      ))
                      : <tr>
                        <td colSpan={4}><i>No hay productos relacionados...</i></td>
                      </tr>
                    }
                  </tbody>
                </Table>
              </Accordion>
            </div>

            <TablaDetalle historicoData={historico} fnHandleModal={handleModal} />

          </div>

          : <CustomLoader />
        }
      </div>
    </div>

  )
}

export default Detalle
import Accordion from "../components/Accordion"
import Button from "../components/Button"

const ListadoHeader = ({ children, title = 'Búsqueda y Filtros', titleClasses, classes, handleClickClearFilter, handleClickSearch, isOpen = false, handleDisable = false, exportBtn }) => {
   return (
      <Accordion
         title={<p className={`card-title fs-4 ${titleClasses ? titleClasses : ''}`}>{title}</p>}
         classes={`list-header ${classes ? classes : ''}`}
         isOpen
      >
         <div className="card-body border-bottom">
            <div className="row">
               {children}
            </div>

            <div className="row">
               <div className="d-flex gap-1 col-12 col-md-4">
                  <Button text='Buscar' onClick={handleClickSearch} disabled={handleDisable} />
                  <Button text='Borrar filtros' color='secondary' style='outline' onClick={handleClickClearFilter} disabled={handleDisable} />
                  {exportBtn}
               </div>
            </div>
         </div>
      </Accordion>
   )
}

export default ListadoHeader
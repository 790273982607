import axios from "axios";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";
import { useEffect, useState } from "react";
import { idExtractor } from "../../helpers/idExtractor";
import SelectFilter from "../../components/SelectFilter";
import { getAll } from "../../endpoints/getAll";
import CustomLoader from "../../components/CustomLoader";
import { getOne } from "../../endpoints/getOne";


const CONSTANT = {
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  redirectUrl: '/generales/ciudades',
  componentTitle: id => `${id ? 'Editar' : 'Crear'} ciudad`,
  title: 'Ciudades',

  queryUrl: create.ciudades,
  fecthData: getOne.ciudades,
  fetchRegiones: getAll.regiones + '/buscar?limit=99999',
  fetchCiudades: getAll.erp_ciudades,

  selectFilter: {
    regiones: [],
    ciudades: []
  },

  form: {
    region_id: '',
    nombre: undefined,
    cod_maxi: undefined,
    estatus: undefined
  }
}


const handleSelectFiltersData = async () => {
  const regiones = await axios(CONSTANT.fetchRegiones,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.regiones?.data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const ciudades = await axios(CONSTANT.fetchCiudades,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.ciudades] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })
  return { regiones, ciudades }
}


const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.form)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const { id } = useParams()

  const [isConsulting, setIsConsulting] = useState(false)


  useEffect(() => {
    if (!id || !selectFilter.ciudades.length || !selectFilter.regiones.length) return

    axios(CONSTANT.fecthData(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        const ciudad = selectFilter.ciudades.find(({ cod_maxi }) => String(data.cod_maxi) === String(cod_maxi))

        setForm({
          ...data,
          estatus: data?.estatus ?? 1,
          region_id: `${data?.neu_region?.id ?? '*NULL*'}-${data?.neu_region?.nombre ?? 'Error'}`,
          cod_maxi: `${ciudad?.cod_maxi ?? '*NULL*'}%-%${ciudad?.nombre ?? 'Error'} | ${ciudad?.cod_maxi ?? '*NULL*'}`
        })
      })
      .catch(err => console.error(err))

    return () => {
      setForm(CONSTANT.form)
    }
  }, [id, selectFilter.ciudades])


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)
    const { nombre, region_id, cod_maxi, estatus } = form

    if (cod_maxi.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, ingrese un Cod. maxi', icon: 'info' }))
    if (region_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, ingrese una región', icon: 'info' }))
    if (!nombre.length) return dispatch(fireToaster({ title: 'Por favor, ingrese un nombre', icon: 'info' }))

    let url = id => id ? CONSTANT.queryUrl + `/${id}` : CONSTANT.queryUrl

    let data = {
      nombre,
      region_id: idExtractor(region_id),
      cod_maxi: idExtractor(cod_maxi, true),
      estatus: Number(estatus) ? 1 : 0
    }


    axios(url(id), {
      method: id ? 'PUT' : 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data?.nombre} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() =>
        setIsConsulting(false)
      )
  }

  return (
    <div className="row">
      < div className="col col-md-6 mx-auto" >
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        {selectFilter.regiones.length && selectFilter.ciudades.length
          ? <CustomForm size="" title={CONSTANT.componentTitle(id)}>

            <div className="row">
              <Switch
                labelText='Estatus'
                name='estatus'
                value={form.estatus ? true : false}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <SelectFilter
                altSeparator
                labelText='* Cod. maxi'
                name='cod_maxi'
                value={form.cod_maxi?.split('%-%')[1] ?? 'Seleccione'}
                handleValue={handleInputChange}
                optionObj={selectFilter.ciudades.map(({ cod_maxi, nombre, id }) => ({ id: cod_maxi ?? id, name: `${nombre} | ${cod_maxi ?? id}` }))}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <SelectFilter
                labelText='* Región'
                name='region_id'
                value={form.region_id?.split('-')[1] ?? 'Seleccione'}
                handleValue={handleInputChange}
                optionObj={selectFilter.regiones.map(({ id, nombre }) => ({ id, name: nombre }))}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='* Nombre'
                placeholder='Nombre'
                name='nombre'
                value={form.nombre}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />
            </div>

            <Button
              type="submit"
              text={id ? 'Actualizar' : "Crear"}
              color="primary"
              disabled={isConsulting}
              icon={id ? 'Check' : "Plus"}
              onClick={(e) => handleFetch(e)}
            />
          </CustomForm>
          : <CustomLoader />
        }
      </div >
    </div >
  );
};

export default Form;

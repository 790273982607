import {
   TOASTER_CLEAR,
   TOASTER_CONTENT
} from '../redux-types'

/** Acción que dispara el toaster
 * @example {title, text, icon}
 * 
 * @param title  string
 * @param text string
 * @param html HTML
 * @param icon 'info' || 'warning' || 'success' || 'error' || 'question' || undefined
 * @param {number} timer Duración del toaster. Default 4000
 */
export const fireToaster = (data) => ({ type: TOASTER_CONTENT, payload: data })

export const toasterClear = () => ({ type: TOASTER_CLEAR })
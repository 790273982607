import axios from 'axios'
import { useEffect, useState } from 'react'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import Switch from '../../components/Switch'
import CustomLoader from '../../components/CustomLoader'


// Router Dom
import { useNavigate, useParams } from 'react-router-dom'


// Endpoints
import { getOne } from '../../endpoints/getOne'
import IconFAMDI from '../../components/Tables/Icons/IconFAMDI'
import { historicoData } from '../../helpers/historicoData'
import TablaDetalle from '../../components/Historico/TablaDetalle'
import { ModalHistorico } from '../../components/Historico/ModalHistorico'


const CONSTANT = {
  queryUrlGetOne: getOne.factores_diferenciadores,
  title: 'Factores diferenciadores',
  redirectListado: '/template/factores-diferenciadores',
  modelo: 'App\\Models\\TemplateFactorDiferenciador',
  redirectUpdate: (id) => `/template/factores-diferenciadores/update/${id}`,
}


const Detalle = () => {
  const [data, setData] = useState(null)
  const { id } = useParams()
  const dispatch = useDispatch()
  const redirect = useNavigate()
  const [historico, setHistorico] = useState(null)
  const [modal, setModal] = useState({ id: null, display: false, data: null })


  // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
  useEffect(() => {
    axios(CONSTANT.queryUrlGetOne(id), {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
      },
    })
      .then(({ data }) => {
        setData(data)
        historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
      })
      .catch(err => {
        console.error(err)
        const toasterContent = {
          title: 'Registro no encontrado',
          icon: 'warning'
        }

        dispatch(fireToaster(toasterContent))
        redirect(CONSTANT.redirectListado)
      })

    return () => {
      setData(null)
    }
  }, [id, dispatch, redirect])


  // FUNCIÓN MANEJADORA PARA ACTUALIZACIÓN DEL ESTATUS
  const handleFetch = async (objData, target) => {
    const newEstatus = objData[target] ? 0 : 1

    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    }

    if (target === 'estatus') {
      config = {
        ...config,
        method: 'POST',
        data: { [target]: newEstatus, _method: 'PUT' }
      }
    }

    if (target === 'delete') {
      config = {
        ...config,
        method: 'DELETE',
      }
    }

    await axios(CONSTANT.queryUrlGetOne(objData.id), config)
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data?.titulo} - ${target} actualizado con éxito`,
          icon: 'success'
        }

        dispatch(fireToaster(toasterContent))
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
      })
      .finally(async () => {
        await axios(CONSTANT.queryUrlGetOne(id),
          {
            headers: {
              'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
          })
          .then(({ data }) => {
            setData(data)
            historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
          })
          .catch(err => {
            console.error(err)
            const toasterContent = {
              title: 'Registro no encontrado',
              icon: 'warning'
            }

            dispatch(fireToaster(toasterContent))
            redirect(CONSTANT.redirectListado)
          })
      })
  }


  // FUNCION MANEJADORA DE LA DATA DEL MODAL
  const handleModal = (data) => {
    const { id, anterior, nuevo } = data

    setModal({
      display: true,
      id,
      data: {
        anterior,
        nuevo
      }
    })
  }


  return (
    <div className='row'>

      <ModalHistorico modal={modal} fnHandleModal={setModal} />

      <div className='col-12 '>
        <div className='col mb-50'>
          <div className='d-flex gap-1'>
            <div className='col'>
              <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
            </div>
            <Button text='Editar' icon='Settings' color='success' isLink url={CONSTANT.redirectUpdate(id)} />
            <Button text='Borrar' icon='Trash' color='danger' onClick={() => handleFetch(data, 'delete')} />
          </div>
        </div>

        {data
          ? <>
            <Accordion
              title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
              classes='col-md-6 mx-auto mb-2'
              isOpen
            >
              <AccordionDetailData dataKey={'ID'} value={data?.id} />
              <AccordionDetailData dataKey={'Título'} value={data?.titulo ?? 'N/A'} />
              <AccordionDetailData dataKey={'Frontend'} value={data?.frontend?.nombre ?? "chileneumáticos"} />
              <AccordionDetailData dataKey={'Componente'} value={data?.componente_id ?? 'N/A'} />
              <AccordionDetailData dataKey={'URL de comprobación'} value={data?.url_comprobar ?? 'N/A'} />
              <AccordionDetailData dataKey={'Descripción'} value={data?.descripcion ?? 'N/A'} />
              <AccordionDetailData dataKey={'Ícono'} value={
                data?.neu_icono?.icono
                  ? <IconFAMDI iconData={data} module={data?.neu_icono?.libreria} />
                  : "N/A"
              } />
              <AccordionDetailData dataKey={'Estatus'} value={
                <Switch
                  value={data?.estatus}
                  labelText={""}
                  margin='my-0'
                  handleValue={() => handleFetch(data, 'estatus')}
                />}
              />
              <AccordionDetailData dataKey={'Creación'} value={data?.created_at?.split('T')[0] ?? 'N/A'} />
              <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at?.split('T')[0] ?? 'N/A'} last />
            </Accordion>

            <TablaDetalle historicoData={historico} fnHandleModal={handleModal} />

          </>

          : <CustomLoader />
        }
      </div>
    </div>
  )
}

export default Detalle
import axios from 'axios';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from "../../redux/actions/toaster";
import { clearFilterParametrosRecargos, resultadosParametrosRecargos } from '../../redux/actions/parametrosRecargos'


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Switch from "../Switch";
import Table from "../Table";


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { PrecioFormatter } from '../../helpers/precioFormatter';


const Precio = (v) => new PrecioFormatter(v).producto()


const CONSTANT = {
	permissionsPath: 'puntos-entrega',
	pathUrl: '/generales/parametros-recargos',
	queryUrlGetOne: getOne.parametros_recargos,
	title: 'Parámetros de recargo',
	reduxSelector: 'parametrosRecargos',
	reduxClearFilters: clearFilterParametrosRecargos,
	reduxSetResults: resultadosParametrosRecargos,
}


const ParametrosRecargosTable = () => {
	const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxSelector])
	const { showing_results } = useSelector(state => state.listedResults)
	const { busqueda } = filtros
	const dispatch = useDispatch()
	const { user } = useSelector(state => state.auth)


	// FUNCION MANEJADORA DE PAGINACIÓN
	const handleUrl = (url) => {
		dispatch(CONSTANT.reduxSetResults(null))
		axios(url + `&limit=${showing_results}`, {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
			},
			method: 'POST',
			data: { busqueda }
		})
			.then(({ data }) => dispatch(CONSTANT.reduxSetResults(data?.ciudades)))
			.catch(err => {
				console.error(err)
				dispatch(CONSTANT.reduxSetResults([]))
			})
	}


	// FUNCIÓN MANEJADORA PARA ACTUALIZAR EL ESTATUS
	const handleFetch = async (id, url, target) => {
		let config
		let query = CONSTANT.queryUrlGetOne(id)

		if (target === 'DELETE') {
			if (!window.confirm('¿Está seguro que desea eliminar el registro?')) return
			config = {
				method: 'DELETE'
			}
		}

		config = {
			...config,
			headers: {
				...config?.headers,
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			}
		}

		await axios(query, config)
			.then(res => {
				if (target === 'DELETE') {
					const toasterContent = {
						title: 'Operación realizada',
						text: `${CONSTANT.title}: registro eliminado con éxito`,
						icon: 'info'
					}

					dispatch(fireToaster(toasterContent))
				}

				dispatch(CONSTANT.reduxSetResults(null))
			})
			.catch(err => {
				console.error(err)
				const { errores } = err.response.data
				let detalles = []
				Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

				const toasterContent = {
					title: `
         Operación fallida
         (${err.response.status} - ${err.response.statusText})
       `,
					html: `
         <b>Detalle: </b>
         ${detalles.map(error => `<br /><i>-${error}</i>`)}
       `,
					icon: 'error'
				}
				dispatch(fireToaster(toasterContent))
			})
			.finally(async () => {
				await axios(url, {
					method: 'POST',
					headers: {
						'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
					},
				})
					.then(({ data }) => {
						dispatch(CONSTANT.reduxSetResults(data))
					})
					.catch(err => console.error(err))
			})
	}


	return (
		<AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
			{!response && <CustomLoader blocking={'partial'} />}

			<Table response={response} handlePagination={handleUrl}>
				<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
					<tr role="row">
						<th>ID</th>
						<th>Nombre</th>
						<th>Punto de entrega</th>
						<th>Recargo</th>
						<th>Precio inst.</th>
						<th>Creación</th>
						<th className="w-max">Acciones</th>
					</tr>
				</thead>
				<tbody>
					{response?.data?.length
						? response.data?.map((data) => {
							return (
								<tr className="odd" key={`user-${data.id}-${data.titulo}`}>
									<td className='col-1'>{data?.id}</td>
									<td>{data?.atributo?.nombre ?? "N/A"}</td>

									<td>{data?.punto_entrega?.descripcion ?? "Sin descripción"} | {data?.punto_entrega?.direccion ?? "Sin dirección"}, {data?.punto_entrega?.nombre ?? "sin nombre"}</td>

									<td className='col-1 fw-bold'>{Precio(data?.recargo)}</td>

									<td className='col-1 fw-bold'>{Precio(data?.precio_instalacion)}</td>

									<td className='col-1'>{data?.created_at?.split("T")[0] || 'N/A'}</td>

									<td className='col-1'>
										<DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
											{user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
												<DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
											}

											{user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
												<DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
											}

											{user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
												<DropdownMenuLink text='Borrar' icon="X" onClick={() => handleFetch(data.id, response.path + '?page=' + response.current_page + `&limit=${showing_results}`, 'DELETE')} />
											}
										</DropdownWrapper>
									</td>
								</tr>
							)
						})
						: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
					}
				</tbody>
			</Table>
		</AccordionTable >
	)
}

export default ParametrosRecargosTable
import {
   FILTER_ADD_ATRIBUTOS,
   FILTER_ADD_PUNTO_ENTREGA,
   FILTER_CLEAR,
   FILTER_REMOVE_ATRIBUTOS,
   FILTER_REMOVE_PUNTO_ENTREGA,
   FILTROS_PERSISTENTES_PARAMETROS_RECARGOS,
   RESULTADOS_PARAMETROS_RECARGOS,
} from "../redux-types";


export const puntosEntregaFilter = (data, isRemove = false) => {
   return isRemove
      ? { type: FILTER_REMOVE_PUNTO_ENTREGA, payload: data }
      : { type: FILTER_ADD_PUNTO_ENTREGA, payload: data };
}

export const atributosFilter = (data, isRemove = false) => {
   return isRemove
      ? { type: FILTER_REMOVE_ATRIBUTOS, payload: data }
      : { type: FILTER_ADD_ATRIBUTOS, payload: data };
}

export const resultadosParametrosRecargos = (data) => ({ type: RESULTADOS_PARAMETROS_RECARGOS, payload: data })

export const filtrosPersistentesParametrosRecargos = (data) => ({ type: FILTROS_PERSISTENTES_PARAMETROS_RECARGOS, payload: data })

export const clearFilterParametrosRecargos = () => ({ type: FILTER_CLEAR });

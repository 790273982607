import {
   FILTER_CLEAR,
   FILTER_DIRECCION,
   FILTER_NOMBRE,
   FILTER_RUT_CLIENTE,
   RESULTADOS_PROVEEDORES,
} from "../redux-types";

const initialState = {
   filtros: {
      nombre: '',
      rut: '',
      direccion: '',
   },
   resultados: null
}


export const proveedoresReducer = (state = initialState, action) => {
   switch (action.type) {
      case FILTER_NOMBRE:
         return {
            ...state, filtros: {
               ...state.filtros,
               nombre: action.payload
            }
         };

      case FILTER_RUT_CLIENTE:
         return {
            ...state, filtros: {
               ...state.filtros,
               rut: action.payload
            }
         };

      case FILTER_DIRECCION:
         return {
            ...state, filtros: {
               ...state.filtros,
               direccion: action.payload
            }
         };

      case RESULTADOS_PROVEEDORES:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
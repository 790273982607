import {
   FILTER_ADD_CATEGORIA,
   FILTER_CLEAR,
   FILTER_NOMBRE,
   FILTER_REMOVE_CATEGORIA,
   RESULTADOS_CLAVES_ATRIBUTOS,
} from "../redux-types";

const initialState = {
   filtros: {
      nombre: '',
      categoria_id: []
   },
   resultados: null
}


export const clavesAtributoReducer = (state = initialState, action) => {
   const isListedCategoria = state.filtros.categoria_id.find(el => el === action.payload)

   switch (action.type) {
      case FILTER_ADD_CATEGORIA:
         return {
            ...state, filtros: {
               ...state.filtros,
               categoria_id: isListedCategoria
                  ? state.filtros.categoria_id
                  : [...state.filtros.categoria_id, action.payload]
            }
         };
      case FILTER_REMOVE_CATEGORIA:
         return {
            ...state, filtros: {
               ...state.filtros,
               categoria_id: state.filtros.categoria_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_NOMBRE:
         return {
            ...state, filtros: {
               ...state.filtros,
               nombre: action.payload
            }
         };

      case RESULTADOS_CLAVES_ATRIBUTOS:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
import {
   FILTER_ADD_BODEGA,
   FILTER_ADD_PUNTO_ENTREGA,
   FILTER_ADD_REGION,
   FILTER_CLEAR,
   FILTER_REMOVE_BODEGA,
   FILTER_REMOVE_PUNTO_ENTREGA,
   FILTER_REMOVE_REGION,
   RESULTADOS_BODEGAS_PUNTO_ENTREGA,
   RESULTADOS_CLAVES_ATRIBUTOS,
} from "../redux-types";

const initialState = {
   filtros: {
      region_id: [],
      bodega_id: [],
      punto_entrega_id: []
   },
   resultados: null
}


export const bodegasPuntoEntregaReducer = (state = initialState, action) => {
   let isListed

   switch (action.type) {
      case FILTER_ADD_REGION:
         isListed = state.filtros.region_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               region_id: isListed
                  ? state.filtros.region_id
                  : [...state.filtros.region_id, action.payload]
            }
         };
      case FILTER_REMOVE_REGION:
         return {
            ...state, filtros: {
               ...state.filtros,
               region_id: state.filtros.region_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_BODEGA:
         isListed = state.filtros.bodega_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               bodega_id: isListed
                  ? state.filtros.bodega_id
                  : [...state.filtros.bodega_id, action.payload]
            }
         };
      case FILTER_REMOVE_BODEGA:
         return {
            ...state, filtros: {
               ...state.filtros,
               bodega_id: state.filtros.bodega_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_PUNTO_ENTREGA:
         isListed = state.filtros.punto_entrega_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               punto_entrega_id: isListed
                  ? state.filtros.punto_entrega_id
                  : [...state.filtros.punto_entrega_id, action.payload]
            }
         };
      case FILTER_REMOVE_PUNTO_ENTREGA:
         return {
            ...state, filtros: {
               ...state.filtros,
               punto_entrega_id: state.filtros.punto_entrega_id.filter(el => el !== action.payload)
            }
         };

      case RESULTADOS_BODEGAS_PUNTO_ENTREGA:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
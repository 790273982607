import { Navigate, Route, Routes } from 'react-router-dom';
// import Construction from '../components/Construction';
import Generate from '../views/Pedidos/Cotizacion';
import PedidosIndex from '../views/Pedidos/Index';
import Detalle from '../views/Pedidos/Tabs/Detalle';
import { useSelector } from 'react-redux';
import PedidosUpdate from '../views/Pedidos/Update';


const PedidosRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<PedidosIndex />} />

         {user.permisos['pedidos.detalle'] &&
            <Route path='/detalle/:id' element={<PedidosIndex view={<Detalle />} />} />
         }

         {user.permisos['pedidos.editar'] &&
            <Route path='/update/:id' element={<PedidosUpdate />} />
         }

         {user.permisos['pedidos.crear'] &&
            <Route path='/cotizacion/:id' element={<Generate />} />
         }

         {/* <Route path='/detalle/nota-venta' element={<PedidosIndex view={<NotaVenta />} />} /> */}

         {/* <Route path='/metodos-pago' element={<PedidosIndex view={<Construction />} />} /> */}
         {/* <Route path='/stock' element={<PedidosIndex view={<Construction />} />} /> */}
         {/* <Route path='/puntos-entrega' element={<PedidosIndex view={<Construction />} />} /> */}
         {/* <Route path='/imagenes' element={<PedidosIndex view={<Construction />} />} /> */}
         {/* <Route path='/etiquetas' element={<PedidosIndex view={<Construction />} />} /> */}
         {/* <Route path='/ofertas' element={<PedidosIndex view={<Construction />} />} /> */}

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   );
}

export default PedidosRouter;
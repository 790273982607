import {
   FILTER_ADD_CATEGORIA,
   FILTER_ADD_ELEMENTO,
   FILTER_REMOVE_CATEGORIA,
   FILTER_REMOVE_ELEMENTO,
   FILTER_NOMBRE,
   FILTER_CRITERIO,
   FILTER_BUSQUEDA_CRITERIO,
   FILTER_REMOVE_BODEGA,
   FILTER_ADD_BODEGA,
   RESULTADOS_PRODUCTOS,
   FILTER_PRECIO_DESDE,
   FILTER_PRECIO_HASTA,
   FILTER_CLEAR_PRODUCTOS,
   FILTER_REMOVE_ETIQUETA,
   FILTER_ADD_ETIQUETA,
   FILTER_REMOVE_CLAVE,
   FILTER_ADD_CLAVE,
   FILTER_BUSQUEDA_PRODUCTO,
   FILTER_PUBLICADO,
   FILTER_SKUS_PRODUCTO,
   FILTROS_PERSISTENTES_PRODUCTOS
} from "../redux-types";


export const categoriaFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_CATEGORIA, payload: data }
      : { type: FILTER_ADD_CATEGORIA, payload: data };

export const elementoFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_ELEMENTO, payload: data }
      : { type: FILTER_ADD_ELEMENTO, payload: data };

export const bodegaFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_BODEGA, payload: data }
      : { type: FILTER_ADD_BODEGA, payload: data };

export const etiquetaFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_ETIQUETA, payload: data }
      : { type: FILTER_ADD_ETIQUETA, payload: data };

export const clavesFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_CLAVE, payload: data }
      : { type: FILTER_ADD_CLAVE, payload: data };


export const nombreFilter = (data) => ({ type: FILTER_NOMBRE, payload: data })

export const publicadoFilter = (data) => ({ type: FILTER_PUBLICADO, payload: data })

export const precioDesdeFilter = (data) => ({ type: FILTER_PRECIO_DESDE, payload: data })

export const precioHastaFilter = (data) => ({ type: FILTER_PRECIO_HASTA, payload: data })

export const busquedaCriterioFilter = (data) => ({ type: FILTER_BUSQUEDA_CRITERIO, payload: data })

export const criterioFilter = (data) => ({ type: FILTER_CRITERIO, payload: data })

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA_PRODUCTO, payload: data })

export const skusFilter = (data) => ({ type: FILTER_SKUS_PRODUCTO, payload: data })

export const resultadosProductos = (data) => ({ type: RESULTADOS_PRODUCTOS, payload: data })

export const filtrosPersistentesProductos = (data) => ({ type: FILTROS_PERSISTENTES_PRODUCTOS, payload: data })

export const clearFilterProductos = () => ({ type: FILTER_CLEAR_PRODUCTOS });


// Components
import CategoriasFiltros from "../../components/AccordionFilter/Categorias";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import CategoriasTable from "../../components/Tables/CategoriasTable";


const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>Categorias</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text='Crear categorias' href={`/productos/categorias/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<CategoriasFiltros />

			<CategoriasTable />
		</div>

	);
}

export default Listado;
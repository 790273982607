import axios from "axios";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import TextareaInput from "../../components/TextareaInput";
import SelectFilter from "../../components/SelectFilter";
import CustomLoader from "../../components/CustomLoader";


// Hooks / Helpers
import { idExtractor } from "../../helpers/idExtractor";


// React-router
import { useNavigate } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";
import { getAll } from "../../endpoints/getAll";


const CONSTANT = {
  redirectUrl: '/template/terminos-condiciones',
  componentTitle: 'Crear término/condicion',
  title: 'Términos y condiciones',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  queryUrl: create.terminos_condiciones,
  fetchFrontendId: getAll.frontend_id,

  initialFormState: {
    frontend_id: '',
    numero: '',
    titulo: '',
    informacion: '',
    estatus: true
  },

  selectFilterInitialState: {
    frontendId: [],
  }
}


const handleSelectFiltersData = async () => {
  const frontendId = await axios(CONSTANT.fetchFrontendId,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { frontendId }
}


const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.initialFormState)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)

  const [isConsulting, setIsConsulting] = useState(false)


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => {
      const defaultFrontend = data.frontendId.find(x => x.id === 1)
      setSelectFilter(data)
      setForm(form => ({
        ...form,
        frontend_id: `${defaultFrontend?.id}-${defaultFrontend?.nombre}`
      }))
    })
  }, [])


  const handleInputChange = (e) => {
    const { target } = e

    setForm({
      ...form,
      [target.name]: target.value,
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)

    let data = {
      titulo: form.titulo,
      informacion: form.informacion,
      numero: Number(form.numero),
      estatus: Number(form.estatus) ? true : false
    }

    if (!form?.frontend_id.includes('*NULL*')) {
      data = {
        ...data,
        frontend_id: idExtractor(form?.frontend_id),
      }
    }

    axios(CONSTANT.queryUrl, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.titulo} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() =>
        setIsConsulting(false)
      )
  }

  return (
    <div className="row">
      < div className="col col-md-6 mx-auto" >
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {selectFilter.frontendId.length
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                labelText='Estatus'
                name='estatus'
                value={form.estatus}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <FormInput
                labelText='* Título'
                name='titulo'
                value={form.titulo}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <FormInput
                type='number'
                labelText='* Número'
                name='numero'
                value={form.numero}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <SelectFilter
                name="frontend_id"
                value={form?.frontend_id?.split('-')[1] ?? ''}
                labelText="* Frontend"
                optionObj={selectFilter.frontendId.map(({ id, nombre }) => ({ id, name: nombre }))}
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <TextareaInput
                labelText='* Información'
                name='informacion'
                value={form.informacion}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
                rows={2}
              />
            </div>

            <Button disabled={isConsulting} type="submit" text="Crear" color="primary" icon="Plus" onClick={(e) => handleFetch(e)} />
          </CustomForm>

          : <CustomLoader />
        }
      </div >
    </div >
  );
};

export default Create;

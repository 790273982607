
// Components
import FaqCategoriasFiltros from "../../components/AccordionFilter/FAQCategorias";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import FaqCategoriasTable from "../../components/Tables/FaqCategoriasTable";


const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>Preguntas frecuentes - Categorías</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text='Crear categoría (FAQ)' href={`create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<FaqCategoriasFiltros />

			<FaqCategoriasTable />
		</div>

	);
}

export default Listado;
import {
  FILTER_BUSQUEDA,
  FILTER_ADD_COMUNA,
  FILTER_REMOVE_COMUNA,
  FILTER_ADD_ANCHO,
  FILTER_REMOVE_ANCHO,
  FILTER_ADD_ALTO,
  FILTER_REMOVE_ALTO,
  FILTER_ADD_ARO,
  FILTER_REMOVE_ARO,
  FILTER_CLEAR,
} from "../redux-types";

const initialState = {
  filters: {
    busqueda: "",
    comuna: [],
    ancho: [],
    alto: [],
    aro: [],
  },
};

export const historialBusquedaClienteReducer = (state = initialState, action) => {
  const isListedComuna = state.filters.comuna.find((el) => el === action.payload);
  const isListedAncho = state.filters.ancho.find((el) => el === action.payload);
  const isListedAlto = state.filters.alto.find((el) => el === action.payload);
  const isListedAro = state.filters.aro.find((el) => el === action.payload);

  switch (action.type) {
    case FILTER_ADD_ANCHO:
      return {
        ...state,
        filters: {
          ...state.filters,
          ancho: isListedAncho ? state.filters.ancho : [...state.filters.ancho, action.payload],
        },
      };

    case FILTER_REMOVE_ANCHO:
      return {
        ...state,
        filters: {
          ...state.filters,
          ancho: state.filters.ancho.filter((el) => el !== action.payload),
        },
      };

    case FILTER_ADD_ALTO:
      return {
        ...state,
        filters: {
          ...state.filters,
          alto: isListedAlto ? state.filters.alto : [...state.filters.alto, action.payload],
        },
      };

    case FILTER_REMOVE_ALTO:
      return {
        ...state,
        filters: {
          ...state.filters,
          alto: state.filters.alto.filter((el) => el !== action.payload),
        },
      };

    case FILTER_ADD_ARO:
      return {
        ...state,
        filters: {
          ...state.filters,
          aro: isListedAro ? state.filters.aro : [...state.filters.aro, action.payload],
        },
      };

    case FILTER_REMOVE_ARO:
      return {
        ...state,
        filters: {
          ...state.filters,
          aro: state.filters.aro.filter((el) => el !== action.payload),
        },
      };

    case FILTER_ADD_COMUNA:
      return {
        ...state,
        filters: {
          ...state.filters,
          comuna: isListedComuna ? state.filters.comuna : [...state.filters.comuna, action.payload],
        },
      };

    case FILTER_REMOVE_COMUNA:
      return {
        ...state,
        filters: {
          ...state.filters,
          comuna: state.filters.comuna.filter((el) => el !== action.payload),
        },
      };

    case FILTER_BUSQUEDA:
      return {
        ...state,
        filters: {
          ...state.filters,
          busqueda: action.payload,
        },
      };

    case FILTER_CLEAR:
      return initialState;

    default:
      return state;
  }
};

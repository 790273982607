import PropTypes from 'prop-types';



const TextareaInput = ({ labelText, name, value, handleValue, size, sizeDesk, sizeTablet, rows, placeholder, isReadOnly, ...props }) => {

   return (
      <div className={`${size} ${sizeDesk} ${sizeTablet}`}>
         <div className='form-outline mb-2'>
            <label className='form-label' >{labelText}</label>

            <textarea
               readOnly={isReadOnly}
               className="form-control no-resize custom-scrollbar"
               name={name}
               rows={rows}
               value={value}
               placeholder={placeholder}
               onChange={(e) => handleValue(e)}
               {...props}
            ></textarea>
         </div>
      </div>
   )
}



TextareaInput.propTypes = {
   labelText: PropTypes.string.isRequired,
   value: PropTypes.string.isRequired,
   rows: PropTypes.number.isRequired,
   handleValue: PropTypes.func.isRequired,
   size: PropTypes.string,
   sizeDesk: PropTypes.string,
   sizeTablet: PropTypes.string
}

TextareaInput.defaultProps = {
   labelText: "text",
   size: "",
   sizeDesk: "",
   sizeTablet: "",
   value: "",
   rows: 4,
   handleValue: () => { }
}



export default TextareaInput;
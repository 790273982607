import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAll } from '../../endpoints/getAll'
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { lastPosition } from '../../helpers/lastPosition'
import { clavesFilter, displayModal, resultadosFiltersModal } from '../../redux/actions/modalProductosFiltros'
import { FILTER_ADD_CLAVE } from '../../redux/redux-types'
import FiltroAplicado from '../AccordionFilter/FiltroAplicado'
import Modal from '../Modal'
import SelectFilter from '../SelectFilter'


const CONSTANT = {
	queryGetAllClavesAtributosFiltros: getAll.claves_atributos + '/filtros'
}

const ModalProductosFiltros = () => {
	const { display, filtros_productos, resultados } = useSelector(state => state.modalProductosFiltros)
	const [clavesAtributos, setClavesAtributos] = useState(null)
	const dispatch = useDispatch()

	useEffect(() => {
		axios(CONSTANT.queryGetAllClavesAtributosFiltros,
			{
				headers: {
					'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
				},
			})
			.then(({ data }) => dispatch(resultadosFiltersModal(data)))
			.catch(err => console.error(err))
	}, [])


	// MANEJADORES DE FILTRO - PRODUCTOS
	const handleFilter = (e, filter) => {
		const { value } = e.target
		switch (filter) {
			case FILTER_ADD_CLAVE:
				dispatch(clavesFilter(value))
				break;
			default:
				break;
		}
	}

	return (
		<Modal display={display} handleValue={() => dispatch(displayModal(!display))} style={{ width: '100%' }} >
			<div className="row">
				<div className="col-12 d-flex my-1 justify-content-between">
					<h3>Filtros ({filtrosAplicadosTotal(filtros_productos)})</h3>
				</div>

				<hr />

				{resultados &&
					resultados.filter(el => el.valores.length).sort((a, b) => (b.nombre > a.nombre) ? -1 : 1).map((clave, i) => (
						<div key={`${i}-${clave.nombre}`} className='col-md-6 col-12'>
							<SelectFilter
								labelText={<b>{clave?.nombre?.split('_')?.join(' ').toUpperCase()}</b>}
								size='col-12'
								sizeDesk='col-md-12'
								handleValue={(e) => handleFilter({ target: { value: `${e.target.value}-${clave.nombre}` } }, FILTER_ADD_CLAVE)}
								optionObj={clave.valores?.map(({ id, nombre }) => ({ id, name: nombre.split('-').length > 1 ? `${nombre.split('-').join('_')}` : nombre }))}
								value={lastPosition(filtros_productos?.[clave?.nombre])}
							/>

							<div className='col-12 col-md-12'>
								<FiltroAplicado array={filtros_productos?.[clave?.nombre] ?? []} func={clavesFilter} size='col' />
							</div>
							<hr className='mt-50 mb-1' />
						</ div>
					))}
			</div>
		</Modal >
	)
}

export default ModalProductosFiltros
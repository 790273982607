import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_TIPOS_FACTURACION,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosTiposFacturacion = (data) => ({ type: RESULTADOS_TIPOS_FACTURACION, payload: data })

export const clearFilterTiposFacturacion = () => ({ type: FILTER_CLEAR });

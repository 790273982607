import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_CLIENTES,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosClientes = (data) => ({ type: RESULTADOS_CLIENTES, payload: data })

export const clearFilterClientes = () => ({ type: FILTER_CLEAR });

import {
   FILTER_ADD_CATEGORIA,
   FILTER_CLEAR,
   FILTER_NOMBRE,
   FILTER_REMOVE_CATEGORIA,
   RESULTADOS_CLAVES_ATRIBUTOS,
} from "../redux-types";

export const categoriaFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_CATEGORIA, payload: data }
      : { type: FILTER_ADD_CATEGORIA, payload: data };

export const nombreFilter = (data) => ({ type: FILTER_NOMBRE, payload: data })

export const resultadosClavesAtributos = (data) => ({ type: RESULTADOS_CLAVES_ATRIBUTOS, payload: data })

export const clearFilterClavesAtributos = () => ({ type: FILTER_CLEAR });

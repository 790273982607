import {
   FILTER_ADD_ESTATUS,
   FILTER_ADD_PAIS,
   FILTER_REMOVE_PAIS,
   FILTER_REMOVE_ESTATUS,
   FILTER_CLEAR,
   FILTER_CREACION_DESDE,
   FILTER_BUSQUEDA,
   FILTER_CREACION_HASTA,
   FILTER_REMOVE_COD_MAXIMISE,
   FILTER_ADD_COD_MAXIMISE,
   RESULTADOS_REGIONES,
   FILTER_REMOVE_REGION,
   FILTER_ADD_REGION
} from "../redux-types";

const initialState = {
   filtros: {
      busqueda: '',
      cod_maxi: [],
      pais_id: [],
      estatus: [],
      creacion_desde: '',
      creacion_hasta: '',
      nombre: []
   },
   resultados: null
}


export const regionesReducer = (state = initialState, action) => {
   const isListedEstatus = state.filtros.estatus.find(el => el === action.payload)
   const isListedRegion = state.filtros.nombre.find(el => el === action.payload)
   const isListedCodMaxi = state.filtros.cod_maxi.find(el => el === action.payload)
   const isListedPais = state.filtros.pais_id.find(el => el === action.payload)

   switch (action.type) {
      case FILTER_ADD_ESTATUS:
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus: isListedEstatus
                  ? state.filtros.estatus
                  : [...state.filtros.estatus, action.payload]
            }
         };

      case FILTER_REMOVE_ESTATUS:
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus: state.filtros.estatus.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_PAIS:
         return {
            ...state, filtros: {
               ...state.filtros,
               pais_id: isListedPais
                  ? state.filtros.pais_id
                  : [...state.filtros.pais_id, action.payload]
            }
         };

      case FILTER_REMOVE_PAIS:
         return {
            ...state, filtros: {
               ...state.filtros,
               pais_id: state.filtros.pais_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_CREACION_DESDE:
         return {
            ...state, filtros: {
               ...state.filtros,
               creacion_desde: action.payload
            }
         };

      case FILTER_CREACION_HASTA:
         return {
            ...state, filtros: {
               ...state.filtros,
               creacion_hasta: action.payload
            }
         };

      case FILTER_ADD_REGION:
         return {
            ...state, filtros: {
               ...state.filtros,
               nombre: isListedRegion
                  ? state.filtros.nombre
                  : [...state.filtros.nombre, action.payload]
            }
         };

      case FILTER_REMOVE_REGION:
         return {
            ...state, filtros: {
               ...state.filtros,
               nombre: state.filtros.nombre.filter(el => el !== action.payload)
            }
         };

      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda: action.payload
            }
         };

      case RESULTADOS_REGIONES:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_ADD_COD_MAXIMISE:
         return {
            ...state, filtros: {
               ...state.filtros,
               cod_maxi: isListedCodMaxi
                  ? state.filtros.cod_maxi
                  : [...state.filtros.cod_maxi, action.payload]
            }
         };

      case FILTER_REMOVE_COD_MAXIMISE:
         return {
            ...state, filtros: {
               ...state.filtros,
               cod_maxi: state.filtros.cod_maxi.filter(el => el !== action.payload)
            }
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
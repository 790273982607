import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fas from '@fortawesome/free-solid-svg-icons';
import * as far from '@fortawesome/free-regular-svg-icons';
import * as fab from '@fortawesome/free-brands-svg-icons';
import * as MDI from '@mdi/js';
import Icon from '@mdi/react';

// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import Switch from '../../components/Switch'
import CustomLoader from '../../components/CustomLoader'


// Router Dom
import { useNavigate, useParams } from 'react-router-dom'


// Endpoints
import { getOne } from '../../endpoints/getOne'
import { contractFormat } from './misc/contractFormat';
import { useCustomFetch } from '../../hooks/useCustomFetch';
import { historicoData } from '../../helpers/historicoData';
import { ModalHistorico } from '../../components/Historico/ModalHistorico';
import TablaDetalle from '../../components/Historico/TablaDetalle';


const iconsFA = { fas, far, fab }


const CONSTANT = {
  queryUrlGetOne: getOne.adicionales,
  title: 'Adicionales',
  modelo: 'App\\Models\\NeuAdicional',
  redirectListado: '/adicionales',
  redirectUpdate: (id) => `/adicionales/update/${id}`,
}


const getData = async (id, fn) => {
  const { data, statusText } = await fn(CONSTANT.queryUrlGetOne(id), contractFormat('detail'))
  return { data, statusText }
}


const Detalle = () => {
  const [data, setData] = useState(null)
  const [historico, setHistorico] = useState(null)
  const [modal, setModal] = useState({ id: null, display: false, data: null })
  const { id } = useParams()
  const dispatch = useDispatch()
  const redirect = useNavigate()
  const [customFetch] = useCustomFetch()


  useEffect(() => {
    getData(id, customFetch)
      .then(({ data }) => {
        setData(data?.adicional)
        historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
      })
      .catch(err => {
        console.error(err)
        const toasterContent = {
          title: 'Registro no encontrado',
          icon: 'warning'
        }

        dispatch(fireToaster(toasterContent))
        redirect(CONSTANT.redirectListado)
      })


    return () => {
      setData(null)
    }
  }, [id, dispatch, redirect])


  const handleFetch = async (objData, target) => {
    if (target === 'delete') if (!window.confirm('¿Está seguro que desea eliminar el registro?')) return

    let fetchUrl = CONSTANT.queryUrlGetOne(id)

    const { data } = await customFetch(fetchUrl, contractFormat(target, objData))

    historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))


    if (data) {
      if (target === 'delete') return redirect(CONSTANT.redirectListado)

      customFetch(fetchUrl, contractFormat('detail'))
    }
  }


  const handleModal = (data) => {
    const { id, anterior, nuevo } = data

    setModal({
      display: true,
      id,
      data: {
        anterior,
        nuevo
      }
    })
  }

  return (
    <div className='row'>

      <ModalHistorico modal={modal} fnHandleModal={setModal} />

      <div className='col-12'>
        <div className='col mb-50'>
          <div className='d-flex gap-1'>
            <div className='col'>
              <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
            </div>
            <Button text='Editar' icon='Settings' color='success' isLink url={CONSTANT.redirectUpdate(id)} />
            <Button text='Borrar' icon='Trash' color='danger' onClick={() => handleFetch(data, 'delete')} />
          </div>
        </div>


        {data
          ?
          <>
            <Accordion
              title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
              classes='col-12 col-md-6 mx-auto mb-2'
              isOpen
            >
              <AccordionDetailData dataKey={'ID'} value={data?.id} />
              <AccordionDetailData dataKey={'Nombre'} value={data?.nombre ?? 'N/A'} />
              <AccordionDetailData dataKey={'Creación'} value={data?.created_at?.split('T')[0]} />
              <AccordionDetailData dataKey={'Estatus'} value={
                <Switch
                  value={data?.estatus}
                  labelText={""}
                  margin='my-0'
                  handleValue={() => handleFetch(data, 'estatus')}
                />} />
              <AccordionDetailData dataKey={'Descripción'} value={data?.descripcion ?? 'N/A'} />
              <AccordionDetailData dataKey={'Categoría'} value={data?.categoria?.nombre ?? 'N/A'} />
              <AccordionDetailData dataKey={'Ícono'} value=
                {data?.neu_icono?.icono
                  ? data?.neu_icono?.libreria === 'fa'
                    ? <FontAwesomeIcon
                      className='p-25 m-auto'
                      icon={iconsFA[data?.neu_icono?.fuente][data?.neu_icono?.icono]}
                    />

                    : <Icon
                      data-prefix='mdi'
                      className='p-25 m-auto'
                      path={MDI[data?.neu_icono?.icono]?.path}
                    />

                  : "N/A"
                } />
              <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at?.split('T')[0] ?? 'N/A'} last />
            </Accordion>

            <TablaDetalle historicoData={historico} fnHandleModal={handleModal} />
          </>

          : <CustomLoader />
        }
      </div>
    </div >
  )
}

export default Detalle
import { useEffect, useState } from "react";
import axios from "axios";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import FileUploaderB from "../../components/FileUploader_B";
import CustomLoader from "../../components/CustomLoader";
import SelectFilter from "../../components/SelectFilter";


// Helpers/Hooks
import { idExtractor } from "../../helpers/idExtractor";
import { hourFormatter } from "../../helpers/hourFormatter";
import { useCustomFetch } from "../../hooks/useCustomFetch";


// React-router
import { useNavigate } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";
import { getAll } from "../../endpoints/getAll";
import { contractFormat } from "./misc/contractFormat";


const CONSTANT = {
  redirectUrl: '/template/banners',
  componentTitle: 'Crear banner',
  title: 'Banners',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  queryUrl: create.banners,
  fetchFrontendId: getAll.frontend_id,
  queryUrlGetAllComponentes: getAll.componentes,

  initialFormState: {
    frontend_id: '',
    nombre: '',
    nombre_componente: '',
    url_redireccion: '',
    es_url: 0,
    es_url_externo: 0,
    fecha_inicio: '',
    hora_inicio: '',
    fecha_final: '',
    hora_final: '',
    estatus: true
  },

  selectFilterInitialState: {
    frontendId: [],
    componentes: []
  }
}


const handleSelectFiltersData = async () => {
  const frontendId = await axios(CONSTANT.fetchFrontendId,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const componentes = await axios(CONSTANT.queryUrlGetAllComponentes, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
    }
  })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { frontendId, componentes }
}


const Create = () => {
  const navigate = useNavigate()
  const [imagen_movil, setImagenMovil] = useState([])
  const [imagen_escritorio, setImagenEscritorio] = useState([])
  const [customFetch] = useCustomFetch()
  const dispatch = useDispatch()
  const [form, setForm] = useState(CONSTANT.initialFormState);
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)

  const [isConsulting, setIsConsulting] = useState(false)


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)

    if (!form?.nombre?.length) return dispatch(fireToaster({ title: 'El nombre es necesario', icon: 'info' }))
    if (!form?.nombre_componente?.length) return dispatch(fireToaster({ title: 'El nombre del componente es necesario', icon: 'info' }))
    if (!imagen_movil?.length) return dispatch(fireToaster({ title: 'La imagen móvil es necesaria', icon: 'info' }))
    if (!imagen_escritorio?.length) return dispatch(fireToaster({ title: 'La imagen de escritorio es necesaria', icon: 'info' }))
    // if (!url_redireccion?.length) return dispatch(fireToaster({ title: 'La URL es necesaria', icon: 'info' }))
    if (!form?.fecha_inicio?.length) return dispatch(fireToaster({ title: 'La fecha de inicio es necesaria', icon: 'info' }))
    if (!form?.fecha_final?.length) return dispatch(fireToaster({ title: 'La fecha final es necesaria', icon: 'info' }))

    let data = new FormData()
    data.append('nombre', form?.nombre)
    data.append('nombre_componente', form?.nombre_componente.split('-')[1])
    data.append('imagen_movil', imagen_movil[0].file)
    data.append('imagen_escritorio', imagen_escritorio[0].file)
    data.append('url_redireccion', form?.url_redireccion)
    data.append('es_url', Number(form?.es_url))
    data.append('es_url_externo', Number(form?.es_url_externo))
    data.append('fecha_hora_inicio', `${form?.fecha_inicio} ${hourFormatter(form?.hora_inicio)}`)
    data.append('fecha_hora_final', `${form?.fecha_final} ${hourFormatter(form?.hora_final)}`)
    data.append('estatus', Number(form?.estatus))
    !form.frontend_id.includes('*NULL*') && data.append('frontend_id', idExtractor(form?.frontend_id))

    const { data: res } = await customFetch(CONSTANT.queryUrl, contractFormat('create', data))
    if (res) navigate(CONSTANT.redirectUrl)

    setIsConsulting(false)
  }

  return (
    <div className="row">
      < div className="col col-md-6 mx-auto" >
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {selectFilter.componentes.length && selectFilter.frontendId.length
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                labelText='Estatus'
                name='estatus'
                value={form?.estatus}
                handleValue={handleInputChange}
                size='col-12'
                classes='justify-content-end mt-25'
              />

              <Switch
                labelText='Es URL'
                name='es_url'
                value={form?.es_url}
                handleValue={handleInputChange}
                size='col-12'
                classes='justify-content-end mt-25'
              />

              <Switch
                labelText='URL externo'
                name='es_url_externo'
                value={form?.es_url_externo}
                handleValue={handleInputChange}
                size='col-12'
                classes='justify-content-end mt-25'
              />

              <FormInput
                labelText='* Nombre'
                name='nombre'
                value={form?.nombre}
                handleValue={handleInputChange}
                size='col-12'
              />

              <SelectFilter
                labelText='* Componente'
                name='nombre_componente'
                value={form?.nombre_componente.split('-')[1]}
                handleValue={handleInputChange}
                optionObj={selectFilter?.componentes?.map(({ id, nombre }) => ({ id, name: nombre }))}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <SelectFilter
                name="frontend_id"
                value={form?.frontend_id?.split('-')[1] ?? ''}
                labelText="* Frontend"
                optionObj={selectFilter.frontendId.map(({ id, nombre }) => ({ id, name: nombre }))}
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='URL de redirección'
                name='url_redireccion'
                value={form?.url_redireccion}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='* Fecha inicial'
                type='date'
                max={form?.fecha_final}
                name='fecha_inicio'
                value={form?.fecha_inicio}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='* Hora inicial'
                type='time'
                name='hora_inicio'
                value={form?.hora_inicio}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='* Fecha final'
                type='date'
                min={form?.fecha_inicio}
                name='fecha_final'
                value={form?.fecha_final}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='* Hora final'
                type='time'
                name='hora_final'
                value={form?.hora_final}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FileUploaderB labelText='* Imagen móvil' handleValue={setImagenMovil} value={imagen_movil} />

              <FileUploaderB labelText='* Imagen escritorio' handleValue={setImagenEscritorio} value={imagen_escritorio} />
            </div>

            <Button disabled={isConsulting} type="submit" text="Crear" color="primary" icon="Plus" onClick={(e) => handleFetch(e)} />
          </CustomForm>
          : <CustomLoader />
        }
      </div >
    </div >
  );
};

export default Create;

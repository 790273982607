import axios from "axios"
import { create } from "../../endpoints/create"

const URL = create.productos + '/asignar/puntos-entregas'

export const postPuntosEntrega = async (data, producto_id) => {
  let toasterContent = {
    title: 'Error - Vacío',
    icon: 'info'
  }

  try {
    if (!data || !data.length) throw Error({ title: 'Faltan datos', icon: 'error' })
    if (!producto_id) throw Error({ title: 'Falta ID del producto', icon: 'error' })

    return await axios(URL,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: { asignaciones: data, producto_id }
      })
      .then(({ data }) => {
        toasterContent = {
          title: 'Operación realizada',
          text: `Asignación de puntos de entrega a producto con éxito`,
          icon: 'success'
        }
        return toasterContent
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        toasterContent = {
          title: `
            Error en la asignación de puntos de entrega al producto
          (${err.response.status} - ${err.response.statusText})
        `,
          html: `
          <b>Detalle: </b>
          ${detalles.map(error => `<br /><i>-${error}</i>`)}
        `,
          icon: 'error'
        }

        throw toasterContent
      })

  } catch (error) {
    return error
  }
}
// import axios from 'axios';

// Redux
import { useSelector } from 'react-redux'
// import { fireToaster } from "../../redux/actions/toaster";
import { clearFilterProductosProveedores, resultadosProductosProveedores } from '../../redux/actions/productosProveedores';


// Components
import CustomLoader from "../CustomLoader";
// import DropdownMenuLink from "../Dropdown/Link";
// import DropdownWrapper from "../Dropdown/Wrapper";
import Table from "../Table";


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
// import Switch from '../Switch';
// import { idExtractor } from '../../helpers/idExtractor';
import React, { useState } from 'react';
import Button from '../Button';


const CONSTANT = {
  reduxStateSelector: 'ordenesCompra',
  queryUrlGetOne: getOne.productos_proveedor,
  pathUrl: 'productos',
  title: 'Ordenes de compra',

  reduxClearFilters: clearFilterProductosProveedores,
  reduxSetResults: resultadosProductosProveedores,

  acordeones: { productos: false }
}


// const dataFormatter = (filtrosObj) => {
//   let data = {}

//   Object.entries(filtrosObj).forEach(([key, value]) => {
//     if (['proveedor_categoria_id'].includes(key) && value.length) {
//       data = {
//         ...data,
//         filtros: {
//           ...data.filtros,
//           [key]: value.map(el => idExtractor(el))
//         }
//       }
//     }
//   })

//   return data
// }


const OrdenesCompraTable = () => {
  const { resultados: response } = useSelector(state => state[CONSTANT.reduxStateSelector])
  // const dispatch = useDispatch()


  // const handleUrl = (url) => {
  //   dispatch(CONSTANT.reduxSetResults(null))
  //   axios(url + `&limit=${showing_results}`,
  //     {
  //       headers: {
  //         'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
  //       },
  //       method: 'POST',
  //       data: dataFormatter(filtros)
  //     })
  //     .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
  //     .catch(err => console.error(err))
  // }


  // const handleFetch = async (objData, url, target) => {
  //   let config = {
  //     headers: {
  //       'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
  //     },
  //   }

  //   if (target === 'estatus') {
  //     config = {
  //       ...config,
  //       method: 'PUT',
  //       data: {
  //         estatus: !objData?.estatus
  //       }
  //     }
  //   }

  //   if (target === 'delete') {
  //     if (!window.confirm('¿Está seguro que desea eliminar el registro?')) return

  //     config = { ...config, method: 'DELETE' }
  //   }

  //   await axios(CONSTANT.queryUrlGetOne(objData.id), config)
  //     .then(res => {
  //       const toasterContent = {
  //         title: 'Operación realizada',
  //         text: `${CONSTANT.title}: registro actualizado con éxito`,
  //         icon: 'success'
  //       }

  //       dispatch(fireToaster(toasterContent))
  //     })
  //     .catch(err => {
  //       console.error(err);
  //       const { errores } = err.response.data
  //       let detalles = []
  //       Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

  //       const toasterContent = {
  //         title: `
  //           Operación fallida
  //           (${err.response.status} - ${err.response.statusText})
  //         `,
  //         html: `
  //           <b>Detalle: </b>
  //           ${detalles.map(error => `<br /><i>-${error}</i>`)}
  //         `,
  //         icon: 'error'
  //       }
  //       dispatch(fireToaster(toasterContent))
  //     })

  //   dispatch(CONSTANT.reduxSetResults(null))
  //   axios(url + `&limit=${showing_results}`,
  //     {
  //       headers: {
  //         'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
  //       },
  //       method: 'POST',
  //       data: dataFormatter(filtros)
  //     })
  //     .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
  //     .catch(err => console.error(err))
  // }
  const [acordeones, setAcordeones] = useState(CONSTANT.acordeones)


  // FUNCION MANEJADORA DE LOS ACORDEONES
  const handleInnerAccordion = (value, target) => {
    setAcordeones({ ...acordeones, [target]: !value })
  }


  return (
    <>

      <div className='d-flex gap-1 justify-content-end mb-50'>
        <div className='w-fit'>
          <Button onClick={() => handleInnerAccordion(acordeones.productos, 'productos')} text='Productos' icon={acordeones.productos ? 'Minimize2' : 'Maximize2'} />
        </div>
      </div>

      <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
        {!response && <CustomLoader blocking={'partial'} />}

        <Table>
          <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
            <tr role="row">
              <th>ID</th>
              <th>Descripción</th>
              <th>Proveedor</th>
              <th>Creación</th>
              <th>Estatus</th>
              {/* <th className="w-max">Acciones</th> */}
            </tr>
          </thead>
          <tbody>
            {response?.data?.length
              ? response.data?.map((data, i) => {
                return (
                  <React.Fragment key={`nv-rw-${i}`}>
                    <tr style={{ borderBottom: '1px solid #555', backgroundColor: i % 2 > 0 ? '#f5f5f5' : '#fff' }} className="odd" key={`producto-proveedor-${data.id}`}>
                      <td style={{ borderBottom: '1px solid #333' }} rowSpan={2}>{data?.oc}</td>

                      <td>
                        <div className='d-flex flex-column'>
                          <span>Dirección: <b>{data?.DEL_ADDR_TEXT ?? 'N/A'}</b></span>
                          <span>Bodega: <b>{data?.bodega ?? 'N/A'}</b></span>
                          <span>Solicitante: <b>{data?.solicitante ?? 'N/A'}</b></span>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex flex-column'>
                          <span>Nombre: <b>{data?.nombre_prov ?? 'N/A'}</b></span>
                          <span>Código: <b>{data?.prov_code ?? 'N/A'}</b></span>
                        </div>
                      </td>

                      <td className='fw-bolder text-nowrap'>{data?.ORDER_DATE?.split('T')?.[0] ?? 'N/A'}</td>

                      <td>{data?.estatus === 'A'
                        ? <span className='tag px-50 success'>Abierta</span>
                        : <span className='tag px-50 red'>Cerrada</span>
                      }
                      </td>

                      {/* <td>
                    <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                      <DropdownMenuLink text='Detalle' href={`/proveedores/${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
                      <DropdownMenuLink text='Editar' href={`/proveedores/${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
                      <DropdownMenuLink
                        text='Borrar'
                        icon="X"
                        onClick={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`, 'delete')}
                      />
                    </DropdownWrapper>
                  </td> */}
                    </tr>

                    <tr className={`odd multi-data`} style={{ borderBottom: '1px solid #555', backgroundColor: i % 2 > 0 ? '#f5f5f5' : '#fff' }}>
                      <td colSpan={5} className='p-0 pb-75'>
                        <AccordionTable isOpen={acordeones.productos} title={`Líneas OC (${data?.LINEAS_OC?.length ?? '?'})`}>
                          <Table pb={false}>
                            <thead className='table-light position-sticky top-0 w-100 z-100 shadow'>
                              <tr>
                                <th>Descripción</th>
                                <th>SKU</th>
                                <th>Qty</th>
                                <th>Qty recibida</th>
                                <th>Datos</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.LINEAS_OC?.length
                                ? data?.LINEAS_OC?.map(({ sku, desc, cant, cant_recibida, total, origen, num_origen, num_item }, i) => (
                                  <tr key={`lineas-oc-${desc ?? 'producto'}-${i ?? sku}`}>
                                    <td>
                                      {/* <a href='/producto/detalle'> */}
                                      {desc.toUpperCase() ?? 'N/A'}
                                      {/* </a> */}
                                    </td>
                                    <td>{sku ?? 'N/A'}</td>
                                    <td className='text-center'>{cant ?? 'N/A'}</td>
                                    <td className='text-center'>{cant_recibida ?? 'N/A'}</td>
                                    <td className=''>
                                      <div className='d-flex flex-column'>
                                        <span>Origen: <b>{origen}</b></span>
                                        <span>Núm. origen: <b>{origen === 'O'
                                          ? <a href={`/erp/notas-ventas/detalle/${num_origen}`} target='_blank' rel='noreferrer'>
                                            {num_origen}
                                          </a>
                                          : num_origen
                                        }
                                        </b>
                                        </span>
                                        <span>Núm. item: <b>{num_item}</b></span>
                                      </div>
                                    </td>
                                    <td><b>{total}</b></td>
                                  </tr>
                                ))
                                : <tr><td colSpan={6}><p className='tag dark-blue px-50 m-auto'><b>Sin datos...</b></p></td></tr>
                              }
                            </tbody>
                          </Table>
                        </AccordionTable>
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })
              : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
            }
          </tbody>
        </Table>
      </AccordionTable >
    </>
  )
}

export default OrdenesCompraTable
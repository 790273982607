import axios from 'axios';


// Redux
import { clearFilterDistritos, resultadosDistritos } from '../../redux/actions/distritos';
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from "../../redux/actions/toaster";


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Switch from "../Switch";
import Table from "../Table";


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";


const CONSTANT = {
	reduxStateSelector: 'distritos',
	permissionsPath: 'adicionales',
	pathUrl: '/generales/distritos',
	queryUrlGetOne: getOne.distritos,
	title: 'Distritos',
	reduxClearFilters: clearFilterDistritos,
	reduxSetResults: resultadosDistritos,
}


const DistritosTable = () => {
	const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
	const { showing_results } = useSelector(state => state.listedResults)
	const { busqueda } = filtros
	const dispatch = useDispatch()
	const { user } = useSelector(state => state.auth)


	// FUNCION MANEJADORA DE PAGINACIÓN
	const handleUrl = (url) => {
		dispatch(CONSTANT.reduxSetResults(null))
		axios(url + `&limit=${showing_results}`, {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
			method: 'POST',
			data: { busqueda }
		})
			.then(({ data }) => dispatch(CONSTANT.reduxSetResults(data?.distritos)))
			.catch(err => {
				console.error(err)
				dispatch(CONSTANT.reduxSetResults([]))
			})
	}


	// FUNCIÓN MANEJADORA PARA ACTUALIZAR EL ESTATUS
	const handleFetch = async (objData, url, target) => {
		let config = {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
				'Content-Type': 'application/json'
			}
		}

		if (target === 'estatus') {
			let newEstatus = objData[target] ? 0 : 1
			config = {
				...config,
				method: 'PUT',
				data: { [target]: newEstatus, _method: 'PUT' }
			}
		}

		if (target === 'DELETE') {
			config = { method: target, headers: { _method: target } }
			// if (!window.confirm('¿Está seguro que desea eliminar el registro?')) 
			return alert('El endpoint no sirve')
		}

		await axios(CONSTANT.queryUrlGetOne(objData.id), config)
			.then(res => {
				let toasterContent = {
					title: 'Operación realizada',
					text: `${CONSTANT.title}: ${target} actualizado con éxito`,
					icon: 'success'
				}

				if (target === 'DELETE') {
					toasterContent = {
						title: 'Operación realizada',
						text: `${CONSTANT.title}: registro eliminado con éxito`,
						icon: 'info'
					}
				}

				dispatch(fireToaster(toasterContent))
			})
			.catch(err => {
				console.error(err);
				const { errores } = err.response.data
				let detalles = []
				Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

				const toasterContent = {
					title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
					html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
					icon: 'error'
				}
				dispatch(fireToaster(toasterContent))
			})

		dispatch(CONSTANT.reduxSetResults(null))
		axios(url, { method: 'POST', data: { busqueda } })
			.then(({ data }) => {
				dispatch(CONSTANT.reduxSetResults(data?.distritos))
			})
			.catch(err => {
				dispatch(CONSTANT.reduxSetResults([]))
				console.log(err)
			})
	}


	return (
		<AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
			{!response && <CustomLoader blocking={'partial'} />}

			<Table response={response} handlePagination={handleUrl}>
				<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
					<tr role="row">
						<th>ID</th>
						<th>Nombre</th>
						<th>País</th>
						<th>Cod Maxi</th>
						<th>Creación</th>
						<th>Estatus</th>
						<th className="w-max">Acciones</th>
					</tr>
				</thead>
				<tbody>
					{response?.data.length
						? response.data?.map((data) => {
							return (
								<tr className="odd" key={`user-${data.id}-${data.nombre}`}>
									<td className='col-1'>{data?.id}</td>
									<td>{data?.nombre ?? "N/A"}</td>
									<td>{data?.pais?.nombre ?? "N/A"}</td>
									<td className='col-1'>{data?.cod_maxi ?? "N/A"}</td>
									<td>{data?.created_at?.split("T")[0] || 'N/A'}</td>
									<td className='col-1'>
										<Switch
											value={data.estatus ? 1 : 0}
											labelText={""}
											handleValue={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`, 'estatus')}
										/>
									</td>
									<td className='col-1'>
										<DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
											{user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
												<DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
											}

											{user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
												<DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
											}

											{user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
												<DropdownMenuLink text='Borrar' icon="X" onClick={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`, 'DELETE')} />
											}
										</DropdownWrapper>
									</td>
								</tr>
							)
						})
						: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
					}
				</tbody>
			</Table>
		</AccordionTable >
	)
}

export default DistritosTable
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CustomLoader from './components/CustomLoader'
import { BASE_URL, PATH_URL } from './endpoints'
import { setPersistentData } from './redux/actions/auth'


const CONSTANT = {
  queryUrl: BASE_URL + PATH_URL + '/auth',
  reduxSetPersistentData: setPersistentData
}

const PersistentLogin = ({ children }) => {
  const [loader, setLoader] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    handleToken()
  }, [])


  const handleToken = () => {
    if (!sessionStorage.getItem('x-token') && !localStorage.getItem('x-token')) return setLoader(false)

    axios(CONSTANT.queryUrl,
      {
        headers: {
          'Authorization': sessionStorage.getItem('x-token') ?? localStorage.getItem('x-token')
        }
      }
    )
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetPersistentData(data))
        setLoader(false)
      })
      .catch(err => {
        console.error(err);
        dispatch(CONSTANT.reduxSetPersistentData({}))
        sessionStorage.removeItem('x-token')
        localStorage.removeItem('x-token')
        window.location.reload()
      })
  }

  if (loader) {
    return (
      <>
        <CustomLoader blocking='full' />
      </>
    )
  }

  return (
    <>
      {children}
    </>
  )
}

export default PersistentLogin
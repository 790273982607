import React, { useEffect, useState } from 'react'


// Components
import AccordionTable from '../../layout/AccordionTable'
import DropdownMenu from '../DropdownMenu'
import Modal from '../Modal'
import Button from '../Button'
import SelectFilter from '../SelectFilter'


// Hooks
import { useDispatch, useSelector } from 'react-redux'
import Table from '../Table'
import { getOne } from '../../endpoints/getOne'
import { clearFilterPedidos, resultadosPedidos } from '../../redux/actions/pedidos'
import axios from 'axios'
import { fireToaster } from '../../redux/actions/toaster'
import DropdownWrapper from '../Dropdown/Wrapper'
import DropdownMenuLink from '../Dropdown/Link'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'
import { dataFormatter } from '../AccordionFilter/Pedidos'
import { BASE_URL, PATH_URL } from '../../endpoints'
import { PrecioFormatter } from '../../helpers/precioFormatter'
import { renderRecargoInstalacion } from '../../helpers/renderRecargoInstalacion'
import { getAll } from '../../endpoints/getAll'
import DownloadFile from '../Download/DownloadFile'
import moment from 'moment'



const CONSTANT = {
  pathUrl: '/pedidos',
  permissionsPath: 'pedidos',
	queryExportar: getAll.pedidos + '/exportar',
  queryUrlGetOne: getOne.pedidos,
  title: 'Pedidos',
  reduxClearFilters: clearFilterPedidos,
  reduxSetResults: resultadosPedidos,
  fecthMaximiseData: BASE_URL + PATH_URL + '/erp/notas-ventas/lineas/listar',
  acordeones: { productos_erp: false, productos: false }
}


const Precio = (v) => new PrecioFormatter(v).producto()
const PrecioRaw = (v) => new PrecioFormatter(v).producto(true)


const ModalDetalle = ({ obj, display, handleDisplay }) => (
  <Modal display={display} handleValue={handleDisplay} sizeDesk='col-md-12'>
    <div className='m-2'>
      <a href='/pedidos/detalle'><h3 className='text-primary p-50 rounded-2 m-0'>Agendar pedido #1000</h3></a>
      <hr className='mt-0 mb-2' />

      <div className='row'>
        <div className='col-6'>
          <p className='fs-4 fw-bold'>Punto de entrega:</p>
          {/* <p>{mock[0].punto_entrega.direccion}</p>
					<p>{mock[0].punto_entrega.region}</p>
					<p>{mock[0].punto_entrega.proveedor}</p> */}
        </div>
        <div className='col-6'>
          <p className='fs-4 fw-bold'>Datos del cliente:</p>
          <p>+58 4143421561</p>
          <p>danietenos@gmail.com</p>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6'>
          <p className='my-25 d-flex fw-bold'>Próximas fechas (<button className='m-0 p-0 text-blue bg-white'>cargar más</button>)</p>
          <SelectFilter
            sizeDesk='col-md-12'
            value='2022-12-05 Lunes'
          />
        </div>
        <div className='col-md-6'>
          <p className='my-25 fw-bold'>Horas disponibles</p>
          <SelectFilter
            value='09:00 - 10:00 (2 cupos)'
            sizeDesk='col-md-12'
          />
        </div>
      </div>

      <Button text='Agendar pedido' className='m-auto mt-3' />
    </div>
  </Modal>
)


const PedidosTable = () => {
  const { resultados: response, filtros } = useSelector(state => state.pedidos)
  const { showing_results } = useSelector(state => state.listedResults)
  const {
    busqueda_usuario,
    pais_id,
    desde,
    hasta,
    fecha,
  } = filtros
  const dispatch = useDispatch()
  const [resultados, setResultados] = useState([])
  const { user } = useSelector(state => state.auth)
  const [acordeones, setAcordeones] = useState(CONSTANT.acordeones)
  const [modalProductos, setModalProductos] = useState({ data: null, display: false })


  // EFECTO QUE FORMATEA LA RESPUESTA DE AGENDAMIENTOS CON LA RESPUESTA DEL MAXIMISE
  useEffect(() => {
    const data = response?.data?.map(({ nota_venta }) => nota_venta) ?? []
    if (!data?.length) return setResultados(response)
    axios(CONSTANT.fecthMaximiseData,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: { notas_ventas: data }
      })
      .then(({ data }) => {
        setResultados(
          response?.data.map(({ nota_venta }, i, arr) => {
            const erp_data = data.data[nota_venta]
            return { ...arr[i], erp_data }
          })
        )
      })
      .catch(err => {
        console.error(err)
        setResultados(response?.data)
        dispatch(fireToaster({ title: 'No se pudo establacer conexión con el Maximise', icon: 'info' }))
      })
  }, [response, dispatch])


  // FUNCION MANEJADORA DE PAGINACIÓN
  const handleUrl = (url) => {
    dispatch(CONSTANT.reduxSetResults(null))
    axios(url + `&limit=${showing_results}` + queryParamsFormatter({ busqueda_usuario, pais_id, desde, hasta, fecha }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: dataFormatter(filtros)
      })
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
      .catch(err => {
        console.error(err)
        dispatch(CONSTANT.reduxSetResults([]))
      })
  }


  // FUNCION MANEJADORA DE LOS ACORDEONES
  const handleInnerAccordion = (value, target) => {
    setAcordeones({ ...acordeones, [target]: !value })
  }


  const handleModalProductos = (data) => {
    setModalProductos({ display: true, data })
  }


  return (
    <>
      <ModalDetalle />

      <Modal size='w-100' handleValue={() => setModalProductos({ data: null, display: false })} display={modalProductos.display}>
        {modalProductos.display === true &&
          <>
            <AccordionTable title={`Productos ERP (${modalProductos?.data?.total_productos})`} classes='mb-2'>
              <Table pb={false}>
                <thead>
                  <tr>
                    <th>SKU | Descripción</th>
                    <th>Bodega</th>
                    <th>Qty</th>
                    <th>Qty reservada</th>
                    <th>Qty pdte.</th>
                    <th>P. unit. neto</th>
                    <th>P. subtotal neto</th>
                  </tr>
                </thead>
                <tbody>
                  {modalProductos?.data?.erp_data?.length
                    ? modalProductos?.data?.erp_data.map(({ DESC_TEXT, UD_MONTO_INSTALACION, UD_RECARGO, PART_CODE, WARE_CODE, UOM_QTY, SCHED_QTY, BACK_QTY, UNIT_PRICE_AMT, EXT_PRICE_AMT }, i) => (
                      <tr key={`lineas-pedido-erp-${DESC_TEXT ?? 'producto'}-${i ?? PART_CODE}`} className='text-center'>
                        <td>
                          <div className='d-flex flex-column'>
                            <b>{DESC_TEXT.toUpperCase() ?? 'N/A'}</b>

                            <b>{PART_CODE ?? 'N/A'}</b>
                          </div>
                        </td>
                        <td >{WARE_CODE ?? 'N/A'}</td>
                        <td>{UOM_QTY ?? 'N/A'}</td>
                        <td>{SCHED_QTY ?? 'N/A'}</td>
                        <td>{BACK_QTY ?? 'N/A'}</td>
                        <td className='text-nowrap'>{Precio(UNIT_PRICE_AMT)}</td>
                        <td className='text-nowrap'>{Precio(EXT_PRICE_AMT)}</td>
                      </tr>
                    ))
                    : <tr><td colSpan={8}><p className='tag dark-blue px-50 m-auto'><b>Error de conexión</b></p></td></tr>
                  }
                </tbody>
              </Table>
            </AccordionTable>

            <AccordionTable title={`Productos (${modalProductos?.data?.total_productos})`}>
              <Table pb={false}>
                <thead>
                  <tr>
                    <th>SKU | Descripción</th>
                    <th>Qty</th>
                    <th>P. unit.</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  {modalProductos?.data?.lineas_pedido.length
                    ? modalProductos?.data?.lineas_pedido.map(({ cantidad_comprada, precio_unidad, producto, precio_instalacion, recargo, subtotal, subtotal_neto, valor_impuesto }, i) => (
                      <tr key={`lineas-pedido-local-${producto?.nombre ?? 'producto'}-${producto?.sku ?? i}`}>
                        <td>
                          <div className='d-flex flex-column'>
                            <b className='mb-25'>{producto?.sku ?? 'N/A'}</b>

                            <a href={`/productos/detalle/${producto?.id}`} target='_blank' rel='noreferrer'>
                              <b>{producto?.nombre.toUpperCase() ?? 'N/A'}</b>
                            </a>
                          </div>
                        </td>

                        <td className='text-center'>{cantidad_comprada?.split('.')[0] ?? 'N/A'}</td>

                        <td>
                          <div className="d-flex flex-column text-nowrap">

                            <span className="fw-bolder fs-3 text-black">
                              {Precio(precio_unidad)}
                            </span>

                            <span className='text-black'>
                              Neto: {Precio(precio_unidad / ((100 + valor_impuesto) / 100))}
                            </span>

                            <span>
                              Instalación: {renderRecargoInstalacion(precio_instalacion)}
                            </span>

                            <span>
                              Recargo: {renderRecargoInstalacion(recargo)}
                            </span>
                          </div>
                        </td>

                        <td>
                          <div className="d-flex flex-column text-nowrap">

                            <span className="fw-bolder fs-3 text-black">
                              {Precio(subtotal)}
                            </span>

                            <span className='text-black'>
                              Neto: {Precio(subtotal_neto)}
                            </span>

                            <span>
                              Instalación: {precio_instalacion === null
                                ? renderRecargoInstalacion(precio_instalacion)
                                : renderRecargoInstalacion(precio_instalacion * cantidad_comprada?.split('.')[0])
                              }
                            </span>

                            <span>
                              Recargo: {recargo === null
                                ? renderRecargoInstalacion(recargo)
                                : renderRecargoInstalacion(recargo * cantidad_comprada?.split('.')[0])
                              }
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))
                    : <tr><td colSpan={7}><p className='tag dark-blue px-50 mx-auto'><b>Sin productos</b></p></td></tr>
                  }
                </tbody>
              </Table>
            </AccordionTable>
          </>
        }
      </Modal>

      <div className='d-flex gap-1 justify-content-end mb-1'>
        <div className='w-fit'>
        <DownloadFile
            url={CONSTANT.queryExportar + '?q=0' + queryParamsFormatter({ busqueda_usuario, pais_id, desde, hasta, fecha })}
            data={dataFormatter(filtros)}
          />
        </div>
        <div className='w-fit'>
          <Button onClick={() => handleInnerAccordion(acordeones.productos, 'productos')} text='Productos' icon={acordeones.productos ? 'Minimize2' : 'Maximize2'} />
        </div>

        <div className='w-fit'>
          <Button onClick={() => handleInnerAccordion(acordeones.productos_erp, 'productos_erp')} text='Productos ERP' icon={acordeones.productos_erp ? 'Minimize2' : 'Maximize2'} />
        </div>
      </div>

      <AccordionTable title={`Pedidos (${resultados?.length ?? 0} resultados)`}>
        <Table response={response} handlePagination={handleUrl}>
          <thead className="table-light position-sticky top-0 w-100 z-100">
            <tr role="row" className='text-center'>
              <th>ID</th>
              <th>Cliente</th>
              <th>Punto Entrega / Método Pago</th>
              <th>Qty</th>
              <th>Facturación</th>
              {/* <th>Seguimiento</th> */}
              <th>Total</th>
              <th>Agenda</th>
              <th className='px-50'>Acc.</th>
            </tr>
          </thead>
          <tbody>
            {typeof resultados === 'object' && resultados?.length
              ? resultados?.map(({
                id, agendamiento, frontend, costo_envio, estatus_pedido, lineas_pedido, orden_proveedor, estado_pago,
                metodo_pago, monto_pagado, costo_instalacion, monto_total_pedido, nota_venta, cotizacion_id, total_productos, cotizacion,
                numero_seguimiento, punto_entrega, tipo_facturacion, usuario, created_at, direccion_entrega, monto_descuento
              }, i, arr) => {
                return (
                  <tr key={`tr-${id}`} className={`odd multi-data ${orden_proveedor ? 'table-oc' : ''}`} style={{ backgroundColor: i % 2 > 0 ? '#e8eaeb' : '#fff', borderBottom: '1px solid #555' }} >

                    <td className='text-black text-center py-3'>
                      <p className='fs-4'><b>{id}</b></p>

                      {orden_proveedor && <p className='tag dark-blue px-25 mx-auto'>OC</p>}

                      <p className='text-black'>NV: <b>{nota_venta
                        ? <a href={`/erp/notas-ventas/detalle/${nota_venta}`} target='_blank' rel='noreferrer'>{nota_venta}</a>
                        : 'N/A'
                      }
                      </b></p>

                      <p className='text-black'>Cotización: <b>{cotizacion_id
                        ? <a href={`/cotizaciones/detalle/${cotizacion_id}`} target='_blank' rel='noreferrer'>{cotizacion_id}</a>
                        : 'N/A'
                      }
                      </b></p>

                      <b className='text-black text-center'>{moment(new Date(created_at)).format('DD/MM/YYYY') ?? 'N/A'}</b>

                      <p className='tag blue px-25 mx-auto mt-50'>{estatus_pedido?.nombre ?? 'N/A'}</p>
                    </td>

                    <td className='col-md-2'>
                      <div className='gap-50 pb-50 text-center'>
                        <div>
                          <p className='text-black'><b>{usuario?.nombre ?? 'N/A'}</b></p>
                          <p className='sub-text-2'>RUT: <b>{usuario?.rut ?? 'N/A'}</b></p>
                          <p className='sub-text-2'>País: <b>{usuario?.pais?.nombre ?? 'N/A'}</b></p>
                          <p className='sub-text-2'>Ejecutivo: <b>{cotizacion?.ejecutivo?.nombre ?? 'N/A'}</b></p>
                          <p className={`tag px-50 ${frontend?.id === 2 ? 'bridgestone' : 'chileneumaticos'} mx-auto mt-50`}>{frontend?.nombre ?? 'chileneumaticos'}</p>
                        </div>
                      </div>
                    </td>

                    <td className='col-md-2 text-center'>
                      <div className='d-flex flex-column gap-50 pb-50 px-25'>
                        <div className='d-flex flex-column'>
                          <span className='text-black'><b>{direccion_entrega ?? 'N/A'}</b></span>
                          <span className='text-black'>{punto_entrega?.metodo_entrega?.nombre ?? 'N/A' ?? 'N/A'}</span>
                          <span className='text-black'>{punto_entrega?.comuna?.nombre ?? 'N/A'}</span>
                        </div>

                        <DropdownWrapper icon={"Plus"} classes="w-fit mx-auto my-auto" buttonClasses={'p-25'}>
                          <DropdownMenuLink text={punto_entrega?.comuna?.region?.nombre ?? 'N/A'} icon="Tag" style={{ cursor: 'initial' }} />
                          <DropdownMenuLink text={punto_entrega?.tipo_destino?.nombre ?? 'N/A'} icon="Tool" style={{ cursor: 'initial' }} />
                        </DropdownWrapper>
                      </div>
                    </td>

                    <td className='text-black text-center'>
                      <b>{total_productos ?? 'N/A'}</b>
                      <Button onClick={() => handleModalProductos(arr[i])} text='Productos' icon='Plus' className='mt-1 p-50' />
                    </td>

                    <td className='text-black text-center'>
                      <p className='text-black'><b>{metodo_pago?.nombre ?? 'N/A'}</b></p>
                      <p>Facturación: {tipo_facturacion?.nombre ?? 'N/A'}</p>
                    </td>

                    {/* <td className='text-black text-center'><b>{numero_seguimiento ?? 'N/A'}</b></td> */}

                    <td className='text-nowrap'>
                      <div className={`tag ${estado_pago ? 'success' : 'red'} px-50 m-auto`}>
                        <span className='fs-4 text-center py-25'><b>{Precio(monto_total_pedido)}</b></span>
                      </div>
                      <p className='sub-text-2 m-0 text-center'><b>Pagado: {PrecioRaw(monto_pagado)}</b></p>

                      <DropdownWrapper icon={"Plus"} classes="w-fit mx-auto mt-50" buttonClasses={'p-25'}>
                        <DropdownMenuLink text={`Desc.: ${PrecioRaw(monto_descuento)}`} icon="Tag" style={{ cursor: 'initial' }} />
                        <DropdownMenuLink text={`Envío: ${PrecioRaw(costo_envio)}`} icon="Truck" style={{ cursor: 'initial' }} />
                        <DropdownMenuLink text={`Inst.: ${PrecioRaw(costo_instalacion)}`} icon="Tool" style={{ cursor: 'initial' }} />
                      </DropdownWrapper>
                    </td>

                    <td>
                      {agendamiento
                        ? <div className='tag dark-blue px-50 py-25 mx-auto'>
                          <b>
                            <p className='my-0 text-center'>{agendamiento?.fecha?.split('T')[0] ?? '?'}</p>
                            <p className='my-0 text-center'>{agendamiento?.hora_agenda?.hora_inicio_intervalo ?? '?'} - {agendamiento?.hora_agenda?.hora_final_intervalo ?? '?'}</p>
                          </b>
                        </div>
                        : <p className='tag px-50 mx-auto'><b>Sin agenda</b></p>
                      }
                    </td>

                    <td className='px-50 w-fit'>
                      <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                        {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                          <DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${id}?filtros_persistentes=${JSON.stringify(filtros)}`} icon="Table" />
                        }
                      </DropdownWrapper>
                    </td>
                  </tr>
                )
              })
              : <tr><td colSpan={9}>Sin resultados...</td></tr>
            }
          </tbody>
        </Table>
      </AccordionTable >
    </>
  )
}

export default PedidosTable
import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_METADATOS,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosMetadatos = (data) => ({ type: RESULTADOS_METADATOS, payload: data })

export const clearFilterMetadatos = () => ({ type: FILTER_CLEAR });

import axios from "axios"
import { create } from "../../../endpoints/create"

const URL = create.imagenes_productos


export const deleteImgProducto = async (id) => {
  try {
    if (!id) return console.error(new Error(`Error: Falta el id`))

    const response = await axios(URL + `/${id}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'DELETE'
    })

    if (!response.status) throw response

    return response
  } catch (err) {
    console.error(err)
    return err
  }
}
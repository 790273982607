import React, { useEffect, useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { FILTER_ADD_EVENTO, FILTER_ADD_USUARIO, FILTER_BUSQUEDA, FILTER_MODELO, FILTER_MODELO_ID } from '../../redux/redux-types'
import { fireToaster } from '../../redux/actions/toaster'


// Components
import SelectFilter from '../SelectFilter'
import FiltroAplicado from './FiltroAplicado'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import axios from 'axios'
import { idExtractor } from '../../helpers/idExtractor'
import { lastPosition } from '../../helpers/lastPosition'
import { busquedaFilter, clearFilterHistorial, eventoFilter, modeloFilter, modeloIdFilter, resultadosHistorial, usuarioFilter } from '../../redux/actions/historialCambios'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'
import FormInput from '../FormInput'


const CONSTANT = {
  reduxStateSelector: 'historialCambios',
  queryUrlGetAll: (results) => getAll.historial + `?limit=${results}`,
  unselectedObject: { id: '*NULL*', nombre: 'Sin seleccion', categoria: 'N/A' },
  redirectUrl: '/historial',
  title: 'Búsqueda de historial',

  reduxClearFilters: clearFilterHistorial,
  reduxSetResults: resultadosHistorial,

  fetchUsuarios: getAll.usuarios + '/listar',
  fetchModelos: getAll.modelos + '?limit=999999',
  fetchEventos: getAll.eventos,

  selectFilterInitialState: {
    usuarios: [],
    eventos: [],
    modelos: []
  }
}


const handleSelectFiltersData = async () => {
  const usuarios = await axios(CONSTANT.fetchUsuarios, {
    method: 'POST',
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
  })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data])
    .catch(err => {
      console.error(err)
      return [CONSTANT.unselectedObject]
    })

  const modelos = await axios(CONSTANT.fetchModelos, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
  })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data?.data])
    .catch(err => {
      console.error(err)
      return [CONSTANT.unselectedObject]
    })

  const eventos = await axios(CONSTANT.fetchEventos, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
  })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data])
    .catch(err => {
      console.error(err)
      return [CONSTANT.unselectedObject]
    })

  return { usuarios, eventos, modelos }
}


const dataFormatter = (filtrosObj) => {
  let data = {}

  Object.entries(filtrosObj).forEach(([key, value]) => {
    if (value.includes('*NULL*')) return

    if (['modelo'].includes(key) && value.length) {
      return data = {
        ...data,
        [key]: idExtractor(value, true)
      }
    }

    if (['modelo_id'].includes(key) && value.length) {
      return data = {
        ...data,
        [key]: Number(value)
      }
    }

    if (['evento_id', 'usuario_id'].includes(key) && value.length) {
      return data = {
        ...data,
        filtros: {
          ...data.filtros,
          [key]: value.map(str => idExtractor(str))
        }
      }
    }
  })

  return data
}


const HistorialCambiosFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const dispatch = useDispatch()
  const { evento_id, usuario_id, modelo, accion, modelo_id } = filtros

  const [isConsulting, setIsConsulting] = useState(false)


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // EFFECTO QUE HACE LA CONSULTA A LA BBDD
  useEffect(() => {
    axios(CONSTANT.queryUrlGetAll(showing_results), {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)));
    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [dispatch, showing_results])


  const handleFetchFilter = async (reset = false) => {
    let url = CONSTANT.queryUrlGetAll(showing_results)
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    }

    setIsConsulting(true)

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return axios(url, config)
        .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
        .catch(err => {
          console.error(err)
          dispatch(CONSTANT.reduxSetResults([]))
        })
        .finally(() => {
          setIsConsulting(false)
        })
    }

    config = { ...config, data: dataFormatter(filtros) }

    await axios(url + queryParamsFormatter({ accion }), config)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
      })
      .catch(err => {
        console.error(err)

        const { errores } = err.response.data
        let detalles = []

        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  // MANEJADORES DE FILTRO - ADICIONALES
  const handleAddFilter = (e, filter) => {
    const { value } = e.target
    if (value.includes('*NULL*')) return

    switch (filter) {
      case FILTER_ADD_USUARIO:
        dispatch(usuarioFilter(value))
        break;

      case FILTER_ADD_EVENTO:
        dispatch(eventoFilter(value))
        break;

      case FILTER_BUSQUEDA:
        dispatch(busquedaFilter(value))
        break;

      case FILTER_MODELO:
        dispatch(modeloFilter(value))
        break;

      case FILTER_MODELO_ID:
        dispatch(modeloIdFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <FormInput
            labelText='Acción'
            placeholder='Ingrese su búsqueda'
            size='col-12'
            sizeDesk='col-md-4'
            handleValue={(e) => handleAddFilter(e, FILTER_BUSQUEDA)}
            value={accion}
          />

          <SelectFilter
            labelText="Usuarios"
            name="usuario_id"
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_USUARIO)}
            optionObj={selectFilter?.usuarios?.map(({ nombre, id }) => ({ name: nombre ?? 'N/A', id }))}
            size="col-12"
            sizeDesk="col-md-4"
            value={lastPosition(usuario_id)}
          />

          <SelectFilter
            altSeparator
            labelText="Modelo"
            name="modelo"
            handleValue={(e) => handleAddFilter(e, FILTER_MODELO)}
            optionObj={selectFilter?.modelos?.map(({ modelo, nombre }) => ({ name: modelo ?? nombre ?? 'N/A', id: modelo ?? nombre ?? '*NULL*' }))}
            size="col-12"
            sizeDesk="col-md-4"
            value={modelo?.split('%-%')?.[0] ?? 'Seleccione'}
          />

          <FormInput
            labelText='Modelo ID'
            placeholder='Ingrese su búsqueda'
            size='col-12'
            sizeDesk='col-md-4'
            handleValue={(e) => handleAddFilter(e, FILTER_MODELO_ID)}
            value={modelo_id}
          />

          <SelectFilter
            labelText="Eventos"
            name="evento_id"
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_EVENTO)}
            optionObj={selectFilter?.eventos?.map(({ nombre, id }) => ({ name: nombre ?? 'N/A', id }))}
            size="col-12"
            sizeDesk="col-md-4"
            value={lastPosition(evento_id)}
          />
        </>

        {/* FILTROS APLICADOS - ADICIONALES */}
        <p className='mb-25 text-black'>Filtros aplicados</p>
        <div className='row'>
          <FiltroAplicado array={evento_id} func={eventoFilter} title={'Eventos'} />
          <FiltroAplicado array={usuario_id} func={usuarioFilter} title={'Usuarios'} />
        </div>

        <hr className='my-1' />

      </ListadoHeader>
    </div>
  )
}

export default HistorialCambiosFiltros
import { Navigate, Route, Routes } from "react-router-dom";
import Create from "../../views/Regiones/Create";
import Detalle from "../../views/Regiones/Detalle";
import Listado from "../../views/Regiones/Listado";
import Update from "../../views/Regiones/Update";
import { useSelector } from "react-redux";

const RegionesRouter = () => {
  const { user } = useSelector(state => state.auth)

  return (
    <Routes>
      <Route path="/" element={<Listado />} />

      {user.permisos['regiones.crear'] &&
        <Route path="/create" element={<Create />} />
      }

      {user.permisos['regiones.editar'] &&
        <Route path="/update/:id" element={<Update />} />
      }

      {user.permisos['regiones.detalle'] &&
        <Route path="/detalle/:id" element={<Detalle />} />
      }

      <Route path='*' element={<Navigate to='/home' replace />} />
    </Routes>
  );
};

export default RegionesRouter;

import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_CIUDADES,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosCiudades = (data) => ({ type: RESULTADOS_CIUDADES, payload: data })

export const clearFilterCiudades = () => ({ type: FILTER_CLEAR });


// Components
import Tabs from "../../components/Tabs"
import Listado from "./Tabs/Listado";

const tabsList = [
   { name: 'Listado', url: '/agendamientos' },
   { name: 'Puntos de entrega', url: '/agendamientos/puntos-entrega' },
]

const Index = ({ view = <Listado /> }) => {
   return (
      <div className="row">
         <h2>Agendamientos</h2>
         <div className="col">
            <Tabs
               tabs={tabsList}
            />

            <hr className="m-1" />

            {view}
         </div>
      </div>
   )
}

export default Index;
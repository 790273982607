import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_CRITERIOS_COMPARACION,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosCriteriosComparacion = (data) => ({ type: RESULTADOS_CRITERIOS_COMPARACION, payload: data })

export const clearFilterCriteriosComparacion = () => ({ type: FILTER_CLEAR });

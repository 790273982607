import React from 'react';
import axios from 'axios';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from "../../redux/actions/toaster";
import { clearFilterClavesAtributos, resultadosClavesAtributos } from '../../redux/actions/clavesAtributos';


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Switch from "../Switch";
import Table from "../Table";


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter';
import IconFAMDI from './Icons/IconFAMDI';


const CONSTANT = {
   pathUrl: 'productos/claves-atributos',
   permissionsPath: 'atributos',
   queryUrlGetOne: getOne.claves_atributos,
   title: 'Claves/Atributos',
   reduxClearFilters: clearFilterClavesAtributos,
   reduxSetResults: resultadosClavesAtributos,
   axiosData: (data) => data.length && { filtros: { categoria_id: data.map(el => Number(el.split('-')[0])) } }
}


const ClavesAtributosTable = () => {
   const { resultados: response, filtros } = useSelector(state => state.clavesAtributos)
   const { showing_results } = useSelector(state => state.listedResults)
   const dispatch = useDispatch()
   const { nombre, categoria_id } = filtros
   const { user } = useSelector(state => state.auth)


   // FUNCION MANEJADORA DE PAGINACIÓN
   const handleUrl = (url) => {
      dispatch(CONSTANT.reduxSetResults(null))
      axios(url + `&limit=${showing_results}` + queryParamsFormatter({ nombre }),
         {
            headers: {
               'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
            method: 'POST',
            data: CONSTANT.axiosData(categoria_id)
         })
         .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
         .catch(err => console.log(err))
   }


   // FUNCIÓN MANEJADORA PARA ACTUALIZAR EL ESTATUS
   const handleFetch = async (objData, url, target) => {
      let config = {
         headers: {
            'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
         }
      }

      if (target === 'estatus') {
         let newEstatus = objData[target] ? 0 : 1
         config = {
            ...config,
            method: 'PUT',
            data: { [target]: newEstatus, _method: 'PUT' }
         }
      }

      if (target === 'delete') {
         config = {
            ...config,
            method: 'DELETE',
            data: { _method: 'DELETE' }
         }
      }

      await axios(CONSTANT.queryUrlGetOne(objData.id), config)
         .then(res => {
            const toasterContent = {
               title: 'Operación realizada',
               text: `${CONSTANT.title}: ${res.data.nombre} - ${target} actualizado con éxito`,
               icon: 'success'
            }

            dispatch(fireToaster(toasterContent))
         })
         .catch(err => {
            const { errores } = err.response.data
            let detalles = []
            Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

            const toasterContent = {
               title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
               html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
               icon: 'error'
            }
            dispatch(fireToaster(toasterContent))
            console.log(err);
         })

      dispatch(CONSTANT.reduxSetResults(null))

      axios(url + queryParamsFormatter({ nombre }),
         {
            method: 'POST',
            data: CONSTANT.axiosData(categoria_id)
         })
         .then(res => {
            dispatch(CONSTANT.reduxSetResults(res.data))
         })
         .catch(err => console.log(err))
   }


   return (
      <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
         {!response && <CustomLoader blocking={'partial'} />}

         <Table response={response} handlePagination={handleUrl}>
            <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
               <tr role="row">
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Categoría</th>
                  <th>Posición en nombre</th>
                  <th>Proridad</th>
                  <th>Creación</th>
                  <th>Ícono</th>
                  <th>Estatus</th>
                  <th className="w-max">Acciones</th>
               </tr>
            </thead>
            <tbody>
               {response?.data.length
                  ? response.data?.map((data) => {
                     return (
                        <React.Fragment key={`user-${data.id}-${data.nombre}`}>
                           <tr className="odd">
                              <td rowSpan={2} className='bg-index'>{data.id}</td>
                              <td>{data?.nombre_mostrar ?? 'N/A'}</td>
                              <td>{data?.neu_categoria?.nombre ?? 'N/A'}</td>
                              <td>{data?.posicion_en_nombre ?? 'N/A'}</td>
                              <td>{data.prioridad ?? 'N/A'}</td>
                              <td>{data.created_at.split('T')[0]}</td>
                              <td rowSpan={2} className='action-td'>
                                 {data?.icono?.icono
                                    ? <IconFAMDI iconData={data} module={data?.icono?.libreria} />
                                    : "N/A"
                                 }
                              </td>
                              <td rowSpan={2} className='action-td'>
                                 <Switch
                                    value={data.estatus ? true : false}
                                    labelText={''} withIcons
                                    handleValue={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`, 'estatus')}
                                 />
                              </td>
                              <td rowSpan={2} className='action-td'>
                                 <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                                    {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                                       <DropdownMenuLink text='Detalle' href={`/${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
                                    }

                                    {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
                                       <DropdownMenuLink text='Editar' href={`/${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
                                    }

                                    {user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
                                       <DropdownMenuLink text='Borrar' icon="X" onClick={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}` + queryParamsFormatter({ nombre }), 'delete')} />
                                    }
                                 </DropdownWrapper>
                              </td>
                           </tr>

                           <tr>
                              <td colSpan={5} className='tags'>
                                 <div>
                                    {data?.atributo_comparable && <span className='px-50 tag'>Atributo comparable</span>}
                                    {data?.atributo_filtrable && <span className='px-50 tag'>Atributo filtrable</span>}
                                    {data?.incluir_en_nombre && <span className='px-50 tag'>Incluir en nombre</span>}
                                    {data?.incluir_en_descripcion && <span className='px-50 tag'>Incluir en descripción</span>}
                                    {data?.incluir_en_especificaciones && <span className='px-50 tag'>Incluir en especificaciones</span>}
                                    {data?.incluir_en_busqueda && <span className='px-50 tag'>Incluir en búsqueda</span>}
                                    {data?.incluir_en_url && <span className='px-50 tag'>Incluir en URL</span>}
                                    {data?.incluir_en_comparacion && <span className='px-50 tag'>Incluir en comparación</span>}
                                    {data?.valor_obligatorio && <span className='px-50 tag'>Valor obligatorio</span>}
                                    {data?.valor_unico_producto && <span className='px-50 tag'>Valor único producto</span>}
                                 </div>
                              </td>
                           </tr>
                        </React.Fragment>
                     )
                  })
                  : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={9}><i>Sin datos para mostrar...</i></td></tr>
               }
            </tbody>
         </Table>
      </AccordionTable >
   )
}

export default ClavesAtributosTable
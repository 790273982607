import React, { useEffect } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'


// Helpers
import { getAllImagenesProductos } from '../../services/productos/imagenes/getAll.service'
import { clearFilterProductosImagenes, resultadosProductosImagenes } from '../../redux/actions/productosImagenes';


const CONSTANT = {
  redirectUrl: '/productos/imagenes',
  reduxStateSelector: 'productosImagenes',
  reduxClearFilters: clearFilterProductosImagenes,
  reduxSetResults: resultadosProductosImagenes,
  nuevasEmpresas: { id: 'nuevas', nombre: 'Nuevas' },
  allEmpresas: { id: 'NULL', nombre: 'Todas' },
}


export const ProductosImagenesFiltros = () => {
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()

  
  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    getAllImagenesProductos(showing_results).then(res => dispatch(CONSTANT.reduxSetResults(res)))

    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [showing_results, dispatch])


  return (
    <></>
  )
}
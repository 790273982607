import axios from "axios"
import { getAll } from "../../endpoints/getAll"


export const getAllEmpresasTipos = async () => {
  try {
    const response = await axios(getAll.empresas_tipos, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    })

    if (!response.status) throw response

    return response.data
  } catch (err) {
    console.error(err)
    return err.response
  }
}
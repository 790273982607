import axios from "axios";

// Redux
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";


// Hooks
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { create } from "../../endpoints/create";
import { useState } from "react";


const CONSTANT = {
  redirectUrl: '/productos/tipos-elementos',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitle: 'Crear tipo elemento',
  title: 'Tipos elementos',
  queryUrl: create.tipo_elemento,
  initialFormState: {
    tabla: '',
    nombre: undefined,
    estatus: 1
  },
}

const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.initialFormState);


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  const handleFetch = async (e) => {
    e.preventDefault()

    let data = {
      nombre: form.nombre,
      tabla: form.tabla,
      estatus: Number(form.estatus)
    }

    axios(CONSTANT.queryUrl, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'application/json'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        <CustomForm size="" title={CONSTANT.componentTitle}>
          <div className="row">
            <Switch
              name="estatus"
              value={form.estatus}
              labelText="Estatus"
              handleValue={handleInputChange}
              size="col-12"
            />

            <FormInput
              name="nombre"
              value={form.nombre}
              labelText="* Nombre"
              handleValue={handleInputChange}
              size="col-12"
              sizeDesk='col-md-6'
            />

            <FormInput
              name="tabla"
              value={form.tabla}
              labelText="Tabla"
              handleValue={handleInputChange}
              size="col-12"
              sizeDesk='col-md-6'
            />
          </div>

          <Button type="submit" text="Crear" color="primary" icon="Plus" onClick={(e) => handleFetch(e)} />
        </CustomForm>
      </div>
    </div>
  );
};

export default Create;

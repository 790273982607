import axios from 'axios';
import React, { useEffect, useState } from 'react';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { clearFilterProductos, resultadosProductos } from '../../redux/actions/productos';


// Components
import CustomLoader from "../CustomLoader";
import Table from "../Table";
import FormInput from '../FormInput';
import CheckBoxInput from '../CheckboxInput';


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter';
import { BASE_URL } from '../../endpoints';
import Button from '../Button';
import { PrecioFormatter } from '../../helpers/precioFormatter';


const CONSTANT = {
  pathUrl: 'producto',
  queryUrlGetOne: getOne.productos,
  title: 'Productos',
  reduxClearFilters: clearFilterProductos,
  reduxSetResults: (data) => resultadosProductos(data),
  acordeones: { stock: false }
}


const Precio = (v) => new PrecioFormatter(v).producto()


const fecthDataFormatterProductos = (filtrosObj, modalObj) => {
  let data = {}

  Object.entries(filtrosObj).forEach(([k, v]) => {
    if (!v?.length) return
    if (k === 'busqueda') return

    if (k === 'categorias_id') {
      data = {
        ...data,
        [k]: v.map(el => Number(el.split('-')[0]))
      }
    }

    if (k === 'etiqueta_id') {
      data = {
        ...data,
        [k]: v.map(el => Number(el.split('-')[0]))
      }
    }

    if (k.includes('precio_desde') || k.includes('precio_hasta')) {
      data = {
        ...data,
        filtro_precio: {
          ...data.filtro_precio,
          [k]: Number(v)
        }
      }
    }
  })

  if (Object.keys(modalObj).length) {
    let filtros = {}
    Object.entries(modalObj).forEach(([k, v]) => {
      filtros = {
        ...filtros,
        [k]: v.map(el => Number(el.split('-')[0]))
      }
    })
    data = { ...data, filtros }
  }

  return data
}


const ProductosTable = () => {
  const dispatch = useDispatch()
  const { resultados: response, filtros } = useSelector(state => state.productos)
  const { filtros_productos } = useSelector(state => state.modalProductosFiltros)
  const { showing_results } = useSelector(state => state.listedResults)
  const { busqueda } = filtros

  const [productosEncontrados, setProductosEncontrados] = useState(false)
  const [filteredProductosEncontrados, setFilteredProductosEncontrados] = useState(null)
  const [filteredProductosStockDisponible, setFilteredProductosStockDisponible] = useState(true)
  const [orderProductosPrecio, setOrderProductosPrecio] = useState(false)
  const [nombreInputProductoEncontrado, setNombreInputProductoEncontrado] = useState('')
  const [skuInputProductoEncontrado, setSkuInputProductoEncontrado] = useState('')
  const [acordeones, setAcordeones] = useState(CONSTANT.acordeones)


  // EFECTO QUE FORMATEA LA LOS PRODUCTOS PARA SER UTILIZADOS EN LA DATATABLE
  useEffect(() => {
    if (!response?.data) return
    if (response?.data.length === 0) return setProductosEncontrados([])

    const orderedResults = response.data.map(prod => {
      const marca = prod?.neu_producto_atributos?.find(el => el?.neu_valor_atributo?.neu_clave_atributo?.nombre === 'marca')?.neu_valor_atributo?.nombre
      const stockDisponible = prod?.stock_erp?.length
        ? prod.stock_erp.reduce((prev, current) => prev += Number(current.stock_disponible), 0)
        : 0
      return prod = { ...prod, filtro_stock: stockDisponible, marca }
    })

    orderedResults.sort((a, b) => b.filtro_stock - a.filtro_stock)

    setProductosEncontrados(orderedResults)
  }, [response])


  useEffect(() => {
    if (!productosEncontrados.length) return setFilteredProductosEncontrados([])

    let filtered = productosEncontrados?.filter(producto => producto.nombre.toLowerCase().includes(nombreInputProductoEncontrado.toLowerCase()) &&
      producto.sku.toLowerCase().includes(skuInputProductoEncontrado.toLowerCase()))

    if (filteredProductosStockDisponible) filtered = filtered.filter(el => el.filtro_stock > 0)
    filtered = filtered.sort((a, b) => orderProductosPrecio
      ? b.precio_final - a.precio_final
      : a.precio_final - b.precio_final
    )

    setFilteredProductosEncontrados(filtered)
  }, [productosEncontrados, nombreInputProductoEncontrado, skuInputProductoEncontrado, filteredProductosStockDisponible, orderProductosPrecio])


  // EFECTO QUE FILTRA LOS PRODUCTOS DE LA TABLA DE PRODUCTOS ENCONTRADOS
  useEffect(() => {
    if (!productosEncontrados.length) return setFilteredProductosEncontrados([])

    let filtered = productosEncontrados?.filter(producto => producto.nombre.toLowerCase().includes(nombreInputProductoEncontrado.toLowerCase()) &&
      producto.sku.toLowerCase().includes(skuInputProductoEncontrado.toLowerCase()))

    if (filteredProductosStockDisponible) filtered = filtered.filter(el => el.filtro_stock > 0)
    filtered = filtered.sort((a, b) => orderProductosPrecio
      ? b.precio_final - a.precio_final
      : a.precio_final - b.precio_final
    )

    setFilteredProductosEncontrados(filtered)
  }, [productosEncontrados, nombreInputProductoEncontrado, skuInputProductoEncontrado, filteredProductosStockDisponible, orderProductosPrecio])


  // FUNCION MANEJADORA DE PAGINACIÓN
  const handleUrl = (url) => {
    dispatch(CONSTANT.reduxSetResults(null))

    axios(url + `&limit=${showing_results}&bodegas_con_saldo=1&stock_erp=1` + queryParamsFormatter({ busqueda, publicado: filtros?.publicado.includes('null') ? '' : filtros?.publicado?.split('-')?.[0] }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: fecthDataFormatterProductos(filtros, filtros_productos)
      }
    )
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data?.productos ?? [])))
      .catch(err => console.error(err))
  }


  // FUNCION MANEJADORA DE LOS ACORDEONES
  const handleInnerAccordion = (value, target) => {
    setAcordeones({ ...acordeones, [target]: !value })
  }


  const handleFiltrosPersistentes = (location) => {
    return `?filtros_persistentes=${location?.search?.split('filtros_persistentes=')?.[1] ?? '0'}`
  }

  return (
    <>
      <div className='d-flex gap-1 justify-content-end mb-1'>
        <div className='w-fit'>
          <Button onClick={() => handleInnerAccordion(acordeones.stock, 'stock')} text='Stock' icon={acordeones.stock ? 'Minimize2' : 'Maximize2'} />
        </div>
      </div>

      <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados / ${filteredProductosEncontrados?.length ?? 0} mostrados)`}>
        {response
          ? <Table response={response} handlePagination={handleUrl}>
            <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
              <tr role="row">
                <th>ID</th>
                <th className='px-25'>
                  <div className='d-flex gap-1'>
                    <FormInput
                      value={nombreInputProductoEncontrado}
                      placeholder='Producto'
                      labelText=''
                      handleValue={(e) => setNombreInputProductoEncontrado(e.target.value)}
                      sizeDesk='col'
                      classes='text-capitalize sub-text-3 font-weight-normal'
                      margin='my-auto'
                    />
                    <div className="d-flex flex-column">
                      <p className="mb-25">Stock</p>
                      <CheckBoxInput
                        labelText=''
                        value={filteredProductosStockDisponible}
                        handleValue={() => setFilteredProductosStockDisponible(!filteredProductosStockDisponible)}
                        classes='mx-auto'
                      />
                    </div>
                    <div className="d-flex flex-column">
                      <p className="mb-25">Precio ({orderProductosPrecio ? 'DESC' : 'ASC'})</p>
                      <CheckBoxInput
                        labelText=''
                        value={orderProductosPrecio}
                        handleValue={() => setOrderProductosPrecio(!orderProductosPrecio)}
                        classes='mx-auto'
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div className='d-flex'>
                    <FormInput
                      value={skuInputProductoEncontrado}
                      placeholder='SKU'
                      labelText=''
                      handleValue={(e) => setSkuInputProductoEncontrado(e.target.value)}
                      sizeDesk='col'
                      classes='text-capitalize sub-text-3 font-weight-normal'
                      margin='my-auto'
                    />
                  </div>
                </th>
                <th>Imagen</th>
                <th className='px-25'>Stock web</th>
                <th>Precio</th>
                <th>Costos</th>
              </tr>
            </thead>
            <tbody>
              {filteredProductosEncontrados?.length
                ? filteredProductosEncontrados?.map((data, i) => {
                  const imagen = `${BASE_URL}storage/${data?.neu_producto_imagenes?.[0]?.imagen}` ?? '/placeholder.png'

                  return (
                    <React.Fragment key={`user-${data.id}-${data.titulo}`}>
                      <tr className="odd" style={{ backgroundColor: i % 2 > 0 ? '#e8eaeb' : '#fff', borderBottom: 'transparent' }}>
                        <td>{data?.id}</td>

                        <td>
                          <div className='d-flex flex-column'>
                            <span className={`tag px-50 text-nowrap ${data?.publicado ? 'success' : 'red'}`}>{data?.publicado ? 'Publicado' : "No publicado"}</span>
                            <a href={`/productos/detalle/${data?.id}` + handleFiltrosPersistentes(window.location)}><b>{data?.nombre.toUpperCase() ?? "N/A"}</b></a>
                            <span>{data?.marca ?? 'N/A'}</span>
                            <span>{data?.neu_categoria?.descripcion?.toUpperCase() ?? 'N/A'}</span>
                            <b>{data?.neu_producto_atributos?.find(({ neu_valor_atributo }) => neu_valor_atributo?.neu_clave_atributo?.nombre === 'neumatico_carga')?.neu_valor_atributo?.nombre ?? ''}</b>
                          </div>
                        </td>

                        <td><b>{data?.sku ?? "N/A"}</b></td>

                        <td>{data?.neu_producto_imagenes?.length
                          ? <img className="height-listado" src={imagen} alt={data.titulo} />
                          : "N/A"}
                        </td>

                        <td>{data?.stock_propio ?? "N/A"}</td>

                        <td>
                          <div className='text-nowrap d-flex flex-column'>
                            <span>Final:  <b>{Precio(data?.precio_final)}</b></span>
                            <span>Oferta:  <b>{Precio(data?.precio_oferta)}</b></span>
                            <span>Nombre oferta:  <b>{data?.neu_oferta_producto?.[0]?.neu_oferta?.nombre ?? "N/A"}</b></span>
                          </div>
                        </td>

                        <td>
                          <div className='text-nowrap d-flex flex-column'>
                            <span className='fs-4'>Último costo:  <b>{Precio(data?.ultimo_costo)}</b></span>
                            <span>Costo prom.:  <b>{Precio(data?.costo_promedio)}</b></span>
                          </div>
                        </td>
                      </tr>

                      <tr style={{ borderBottom: '2px solid #041c62', backgroundColor: i % 2 > 0 ? '#e8eaeb' : '#fff' }} key={`tr2-producto-${data?.id}-${data?.nombre}`}>
                        <td colSpan={8} className="pt-0 px-50">
                          <AccordionTable
                            title={`Stock bodegas: ${data.filtro_stock}`}
                            isOpen={acordeones.stock}
                          >
                            <Table pb={false}>
                              <thead className='table-light position-sticky top-0 w-100 z-100 shadow'>
                                <tr key={`tr3-producto-${data?.id}-${data?.nombre}`}>
                                  <th>Bodega</th>
                                  <th>Disponible</th>
                                  <th>Física</th>
                                  <th>Reservado</th>
                                  <th>Facturado sin despachar</th>
                                  <th>Por llegar</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.stock_erp?.length
                                  ? data.stock_erp.map(({ bodega, stock_disponible, stock_fisico, stock_facturado_sin_despachar, stock_por_llegar, stock_reservado }) => (
                                    <tr key={`erp-${data?.id}-${bodega}`}>
                                      <td>
                                        <b>{bodega}</b>
                                      </td>
                                      <td>
                                        <b>{stock_disponible}</b>
                                      </td>
                                      <td>
                                        <b>{stock_fisico}</b>
                                      </td>
                                      <td>
                                        <b>{stock_reservado}</b>
                                      </td>
                                      <td>
                                        <b>{stock_facturado_sin_despachar}</b>
                                      </td>
                                      <td>
                                        <b>{stock_por_llegar}</b>
                                      </td>
                                    </tr>
                                  ))
                                  : <tr><td colSpan={5}>No hay disponibilidad</td></tr>
                                }
                              </tbody>
                            </Table>
                          </AccordionTable>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })
                : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={8}><i>Sin datos para mostrar...</i></td></tr>
              }
            </tbody>
          </Table>

          : <CustomLoader />
        }
      </AccordionTable >
    </>
  )
}

export default ProductosTable
import { Route, Routes } from "react-router-dom"
import Listado from "../../views/OrdenesCompra/Listado";


const OrdenesCompraRouter = () => {
   return (
      <Routes>
         <Route path='/' element={<Listado />} />
      </Routes>
   );
}

export default OrdenesCompraRouter;
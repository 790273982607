import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_BANNERS,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosBanners = (data) => ({ type: RESULTADOS_BANNERS, payload: data })

export const clearFilterBanners = () => ({ type: FILTER_CLEAR });

import { Line } from 'react-chartjs-2'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'

import { useEffect, useState } from 'react'
import { getAll } from '../../endpoints/getAll'
import axios from 'axios'
import { todayFormat } from '../../helpers/todayInputFormat'
import { dateRangeCounter } from '../../helpers/dateRangeCounter'
import { sanitaze } from '../../helpers/numberShorter'
import Card from '../Card'
import SmallChart from './SmallChart'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)


const options = {
  scales: {
    x: {
      grid: {
        color: "rgba(0, 0, 0, 0.05)",
      }
    },
    y: {
      grid: {
        color: "rgba(0, 0, 0, 0.05)",
      }
    }
  }
}

const CONSTANT = {
  fetchData: getAll.dashboard + '/pedidos',
  today: todayFormat(),

  formPedidos: {
    fecha_inicio: new Date(todayFormat()),
    fecha_final: new Date(todayFormat()),
    estatus_pago: 1
  },

  data: null,

  chartData: null
}


const fetchData = async (fechaImicial, estatusPago, fechaFinal) => {
  const { daysDiff, dates } = dateRangeCounter(fechaImicial, fechaFinal)
  let x = []

  await Promise.all(
    dates.map(async (date, i) => axios(CONSTANT.fetchData + `?fecha=${date}&estado_pago=${estatusPago}`,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => ({ fecha: date, orden: i, data }))
      .catch(err => {
        console.error(`Error en fecha: ${date} | ${err}`)
        return ({ fecha: date, orden: i, data: [] })
      })
    )
  )
    .then(data => x = data)

  return { metrics: x, daysDiff, fechas: dates }
}


export const HomeChart = () => {
  const [data, setData] = useState(CONSTANT.data)
  const [chartData, setChartData] = useState(CONSTANT.chartData)
  const [form, setForm] = useState(CONSTANT.formPedidos)
  const [loader, setLoader] = useState(true)


  // useEffect(() => {
  //   setForm(form => ({
  //     ...form,
  //     fecha_inicio: new Date(todayFormat()),
  //     fecha_final: new Date(todayFormat())
  //   }))
  // }, [])


  useEffect(() => {
    const weekBefore = new Date(form.fecha_inicio.getTime())
    weekBefore.setDate(weekBefore.getDate() - 7)

    fetchData(weekBefore, form.estatus_pago, form.fecha_final).then(x => setData(x))
  }, [form.fecha_inicio, form.estatus_pago, form.fecha_final])


  useEffect(() => {
    if (!data) return
    const ingresos = data?.metrics?.map(({ data, fecha }) => ({
      ingresos: data.ingresos,
      fecha: `${fecha.split('-')[1]}/${fecha.split('-')[2]}`
    }))

    const ventas = data?.metrics?.map(({ data, fecha }) => ({
      ventas: data.ventas,
      fecha: `${fecha.split('-')[1]}/${fecha.split('-')[2]}`
    }))

    const clientes = data?.metrics?.map(({ data, fecha }) => ({
      clientes: data.clientes,
      fecha: `${fecha.split('-')[1]}/${fecha.split('-')[2]}`
    }))

    const productos = data?.metrics?.map(({ data, fecha }) => ({
      productos: data.productos,
      fecha: `${fecha.split('-')[1]}/${fecha.split('-')[2]}`
    }))

    setChartData(({
      ingresos: {
        labels: ingresos.map(({ fecha }) => fecha),
        datasets: [
          {
            label: 'Ingresos',
            data: ingresos.map(({ ingresos }) => ingresos),
            tension: 0.5,
            fill: true,
            borderColor: `rgb(114, 252, 109)`,
            backgroundColor: `rgba(114, 252, 109, 0.5)`,
            pointRadius: 5,
            pointBorderColor: `rgba(114, 252, 109, 0.5)`,
            pointBackgroundColor: `rgba(114, 252, 109, 0.5)`
          }
        ]
      },
      ventas: {
        labels: ventas.map(({ fecha }) => fecha),
        datasets: [
          {
            label: 'Ventas',
            data: ventas.map(({ ventas }) => ventas),
            tension: 0.5,
            fill: true,
            borderColor: `rgba(247, 252, 109)`,
            backgroundColor: `rgba(247, 252, 109, 0.4)`,
            pointRadius: 5,
            pointBorderColor: `rgba(247, 252, 109, 0.4)`,
            pointBackgroundColor: `rgba(247, 252, 109, 0.4)`
          },
          {
            label: 'Clientes',
            data: clientes.map(({ clientes }) => clientes),
            tension: 0.5,
            fill: true,
            borderColor: `rgba(252, 109, 183)`,
            backgroundColor: `rgba(252, 109, 183, 0.4)`,
            pointRadius: 5,
            pointBorderColor: `rgba(252, 109, 183, 0.4)`,
            pointBackgroundColor: `rgba(252, 109, 183, 0.4)`
          }
        ]
      },
      productos: {
        labels: productos.map(({ fecha }) => fecha),
        datasets: [
          {
            label: 'Productos',
            data: productos.map(({ productos }) => productos),
            tension: 0.5,
            fill: true,
            borderColor: `rgba(100, 120, 250)`,
            backgroundColor: `rgba(100, 120, 250, 0.5)`,
            pointRadius: 5,
            pointBorderColor: `rgba(100, 120, 250, 0.5)`,
            pointBackgroundColor: `rgba(100, 120, 250, 0.5)`
          }
        ]
      },
    }))
  }, [data])



  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    if (name.split('%-%').length > 1) {
      const [target, key] = name.split('%-%')

      setForm({

      })
    }
  }


  const handleFetch = async (target) => {
    let config, url

    if (target === 'pedidos-fecha') {
      config = {
        method: 'GET',
      }
      url = CONSTANT.fetchHomePedidos + `?fecha=${form.fecha_final}&estado_pago=${form.estado_pago}`
    }
    console.log(url);
    setLoader(true)

    config = {
      ...config,
      headers: {
        ...config?.header,
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    }

    axios(url, config)
      .then(({ data }) => {
        if (target === 'pedidos-fecha') {

        }
      })
      .catch(err => {
        console.error(err)
      })
    setLoader(false)
  }

  return (
    !chartData
      ?
      <></>

      : <div className='row'>
        <Card title='Gráficas semanales'>
          <Line
            data={chartData.ingresos}
            options={options}
          />
        </Card>

        <div className='col-4'>
          <SmallChart >
            <Line
              data={chartData.ventas}
              options={options}
            />
          </SmallChart>

          <SmallChart >
            <Line
              data={chartData.productos}
              options={options}
            />
          </SmallChart>
        </div>


      </div>
  )
}

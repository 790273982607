import { useWindowDimensions } from '../../hooks/useDimensions'
import LoginForm from '../../layout/LoginForm'

const Login = () => {
   const { width } = useWindowDimensions()
   
   return (
      <div className={`blocked-modal full row mx-auto ${width > 500 && 'p-5'}`}>
         <LoginForm size='col' sizeDesk='col-md-8' />

         <div className='position-absolute bg-login-img p-0 vw-100'>
            {/* Esto es solo una imagen de mientras */}
            <img className='p-0 w-100 fit-cover' src={process.env.PUBLIC_URL + "/12.jfif"} alt='bg-login-modal' />
         </div>
      </div>
   )
}

export default Login
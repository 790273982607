import { Navigate, Route, Routes } from "react-router-dom";
import Listado from "../../views/MetodosEntrega/Listado";
import Create from "../../views/MetodosEntrega/Create";
import Update from "../../views/MetodosEntrega/Update";
import Detalle from "../../views/MetodosEntrega/Detalle";
import { useSelector } from "react-redux";



const MetodosEntregaRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['metodos-entrega.crear'] &&
            <Route path='/create' element={<Create />} />
         }

         {user.permisos['metodos-entrega.editar'] &&
            <Route path='/update/:id' element={<Update />} />
         }

         {user.permisos['metodos-entrega.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   );
}

export default MetodosEntregaRouter;
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';


const Accordion = ({ children, title, classes, isOpen = false }) => {
   const [isCollapsed, setIsCollapsed] = useState(isOpen);

   const handleCollapse = () => setIsCollapsed(!isCollapsed)

   useEffect(() => setIsCollapsed(isOpen), [isOpen])


   return (
      <div className={`accordion ${classes}`} >
         <div className="accordion-item">
            <h2 className="accordion-header" >
               <button className={`py-50 title fs-4 accordion-button ${isCollapsed ? 'collapsed' : ''}`} type="button" onClick={handleCollapse}>
                  {title}
               </button>
            </h2>

            <div className={`accordion-collapse collapse ${isCollapsed ? 'show' : ''} `} >
               <div className="accordion-body p-50 overflow-clip">
                  {children}
               </div>
            </div>
         </div>
      </div>
   );
}

Accordion.propTypes = {
   classes: PropTypes.string
}

Accordion.defaultProps = {
   classes: ''
}

export default Accordion;
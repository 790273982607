import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   RESULTADOS_DIVISAS,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosDivisas = (data) => ({ type: RESULTADOS_DIVISAS, payload: data })

export const clearFilterDivisas = () => ({ type: FILTER_CLEAR });

import axios from 'axios';

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { clearFilterEtiquetas, resultadosEtiquetas } from '../../redux/actions/etiquetas';
import { fireToaster } from "../../redux/actions/toaster";


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Switch from "../Switch";
import Table from "../Table";


// Endpoints
import { BASE_URL } from "../../endpoints";
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";


const CONSTANT = {
   pathUrl: '/etiquetas',
   permissionsPath: 'etiquetas',
   queryUrlGetOne: getOne.etiquetas,
   title: 'Etiquetas',
   reduxClearFilters: (data) => clearFilterEtiquetas(data),
   reduxSetResults: (data) => resultadosEtiquetas(data),
   imagen: (data) => BASE_URL + 'storage/' + data?.imagen
}


const EtiquetasTable = () => {
   const { resultados: response, filtros } = useSelector(state => state.etiquetas)
   const { showing_results } = useSelector(state => state.listedResults)
   const dispatch = useDispatch()
   const { user } = useSelector(state => state.auth)


   const handleUrl = (url) => {
      dispatch(CONSTANT.reduxSetResults(null))
      axios(url + `&limit=${showing_results}`,
         {
            headers: {
               'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
            method: 'POST'
         })
         .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data.etiquetas)))
         .catch(err => console.error(err))
   }


   const handleEstatus = async (objData, url) => {
      let newEstatus = objData.estatus ? 0 : 1

      let data = new FormData()
      data.append('estatus', newEstatus)
      data.append('_method', 'PUT')

      let filtrosData = {
         busqueda: null,
      }

      Object.entries(filtros).forEach(([key, value]) => {
         if (key === 'oferta_id' && value.length > 0) {
            data = {
               ...data,
               filtros: {
                  ...data.filtros,
                  [key]: value.map(el => el.split('-')[0])
               }
            }
         }
         else return
      })

      await axios(CONSTANT.queryUrlGetOne(objData.id), {
         method: 'POST',
         headers: {
            'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
            'Content-Type': 'multipart/form-data'
         },
         data
      })
         .then(res => {
            const toasterContent = {
               title: 'Operación realizada',
               text: `${CONSTANT.title}: estatus actualizado con éxito`,
               icon: 'success'
            }

            dispatch(fireToaster(toasterContent))
         })
         .catch(err => {
            console.error(err);
            const { errores } = err.response.data
            let detalles = []
            Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

            const toasterContent = {
               title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
               html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
               icon: 'error'
            }
            dispatch(fireToaster(toasterContent))
         })

      dispatch(CONSTANT.reduxSetResults(null))
      axios(url + `&limit=${showing_results}`, { method: 'POST', data: filtrosData })
         .then(({ data }) => {
            dispatch(CONSTANT.reduxSetResults(data.etiquetas))
         })
         .catch(err => console.log(err))
   }


   return (
      <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
         {!response && <CustomLoader blocking={'partial'} />}

         <Table response={response} handlePagination={handleUrl}>
            <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
               <tr role="row">
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Prioridad</th>
                  <th>Oferta</th>
                  <th>Fecha-hora inicio/final</th>
                  <th>Imagen</th>
                  <th>Creación</th>
                  <th>Estatus</th>
                  <th className="w-max">Acciones</th>
               </tr>
            </thead>
            <tbody>
               {response?.data.length
                  ? response.data?.map((data) => {
                     return (
                        <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                           <td>{data?.id}</td>
                           <td>{data?.nombre ?? "N/A"}</td>
                           <td>{data?.prioridad ?? "N/A"}</td>
                           <td>{data?.neu_oferta?.nombre ?? "N/A"}</td>
                           <td>
                              <div className='d-flex flex-column'>
                                 <span>Inicio: <b>{`${data?.fecha_hora_inicio?.split('T')[0]} | ${data?.fecha_hora_inicio?.split('T')[1].split('.')[0]}`}</b></span>
                                 <span>Final: <b>{`${data?.fecha_hora_final?.split('T')[0]} | ${data?.fecha_hora_final?.split('T')[1].split('.')[0]}`}</b></span>
                              </div>
                           </td>
                           <td>{data.imagen ? <img className="height-listado" src={CONSTANT.imagen(data)} alt={data?.nombre} /> : "N/A"}</td>
                           <td>{data?.created_at.split("T")[0] || 'N/A'}</td>
                           <td>
                              <Switch value={data.estatus} labelText={""} handleValue={() => handleEstatus(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`)} />
                           </td>
                           <td>
                              <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                                 {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                                    <DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
                                 }

                                 {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
                                    <DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
                                 }
                              </DropdownWrapper>
                           </td>
                        </tr>
                     )
                  })
                  : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={9}><i>Sin datos para mostrar...</i></td></tr>
               }
            </tbody>
         </Table>
      </AccordionTable >
   )
}

export default EtiquetasTable
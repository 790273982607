import { Navigate, Route, Routes } from "react-router-dom";
import Create from "../../views/TiposDestinos/Create";
import Update from "../../views/TiposDestinos/Update"
import Listado from "../../views/TiposDestinos/Listado";
import { useSelector } from "react-redux";

const TiposDestinosRouter = () => {
  const { user } = useSelector(state => state.auth)

  return (
    <Routes>
      <Route path="/" element={<Listado />} />

      {user.permisos['tipos-destino.crear'] &&
        <Route path="/create" element={<Create />} />
      }

      {user.permisos['tipos-destino.editar'] &&
        <Route path="/update/:id" element={<Update />} />
      }

      <Route path='*' element={<Navigate to='/home' replace />} />
    </Routes>
  );
};

export default TiposDestinosRouter;

import { useEffect, useState } from "react";
import axios from "axios";
import * as fas from '@fortawesome/free-solid-svg-icons';
import * as far from '@fortawesome/free-regular-svg-icons';
import * as fab from '@fortawesome/free-brands-svg-icons';
import * as MDI from '@mdi/js';


// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import CustomLoader from "../../components/CustomLoader";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import TextareaInput from "../../components/TextareaInput";
import CheckBoxInput from "../../components/CheckboxInput";
import IconPicker from "../../components/IconPicker";
import SelectFilter from "../../components/SelectFilter";


// React-router-dom
import { useNavigate, useParams } from "react-router-dom";


// Helpers
import { iconSanatizerFaMdi } from "../../helpers/iconSanatizerFaMdi";


// Endpoints
import { getOne } from "../../endpoints/getOne";
import { getAll } from "../../endpoints/getAll";


const CONSTANT = {
  redirectUrl: '/productos/claves-atributos',
  componentTitle: 'Editar clave/atributo',
  title: 'Claves/Atributos',
  queryUrl: getOne.claves_atributos,
  queryGetAllCategorias: getAll.categorias + '/buscar',
  fetchClaves: getAll.claves
}

const iconsFA = { fas, far, fab }


const Update = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [icono, setIcono] = useState('')
  const [allCategorias, setAllCategorias] = useState(null)
  const [categoria, setCategoria] = useState(null)
  const [fetchData, setFetchData] = useState(null)
  const [claves, setClaves] = useState(null)
  const { id } = useParams()

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RELLENA EL SELECTFILTER DE CATEGORIAS
  useEffect(() => {
    axios(CONSTANT.queryGetAllCategorias,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST'
      })
      .then(({ data }) => setAllCategorias(data?.categorias))
      .catch(err => console.error(err));

    axios(CONSTANT.fetchClaves,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => setClaves(data))
      .catch(err => console.error(err))
  }, [])



  // FUNCIÓN MANEJADORA QUE ACTUALIZA EL ESTADO DE 'FetchData'
  const handleInputChange = (e) => {
    const { name, value } = e.target

    if (name === 'categoria_id') {
      return setCategoria(value)
    }

    else setFetchData({
      ...fetchData,
      categoria_id: Number(categoria.split('-')[0]),
      [name]: value,
    })
  }


  // EFECTO QUE CONSULTA LA DATA DEL REGISTRO Y ACTUALIZA EL ESTADO 'FetchData'
  useEffect(() => {
    let fetchIcon = null

    axios(CONSTANT.queryUrl(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        setFetchData({
          ...data,
          nombre: data?.nombre_mostrar
        })

        fetchIcon = data?.icono?.libreria === 'fa'
          ? {
            prefix: data?.icono?.fuente,
            path: data?.icono?.icono,
            iconName: iconsFA[data?.icono?.fuente][data?.icono?.icono].iconName,
            icon: iconsFA[data?.icono?.fuente][data?.icono?.icono]
          }
          : data?.icono?.libreria === 'mdi'
            ? {
              prefix: 'mdi',
              iconName: data?.icono?.icono,
              path: MDI[data?.icono?.icono]
            }
            : null

        setIcono(fetchIcon)

        setCategoria(`${data?.neu_categoria?.id}-${data?.neu_categoria?.nombre}`)
      })
      .catch(err => console.error(err))

    return () => {
      setFetchData(null)
    }
  }, [id])


  const handleFetch = async (e) => {
    e.preventDefault()

    const sanitazedIcon = iconSanatizerFaMdi(icono)

    let data = {
      categoria_id: Number(categoria.split('-')[0]),
      descripcion: fetchData?.descripcion,
      informacion: fetchData?.informacion,
      nombre_mostrar: fetchData?.nombre.split('-')[0],
      posicion_en_nombre: fetchData?.posicion_en_nombre,

      //valores de Switch
      atributo_filtrable: fetchData?.atributo_filtrable,
      atributo_comparable: fetchData?.atributo_comparable,
      incluir_en_nombre: fetchData?.incluir_en_nombre,
      incluir_en_descripcion: fetchData?.incluir_en_descripcion,
      incluir_en_especificaciones: fetchData?.incluir_en_especificaciones,
      incluir_en_busqueda: fetchData?.incluir_en_busqueda,
      incluir_en_url: fetchData?.incluir_en_url,
      incluir_en_comparacion: fetchData?.incluir_en_comparacion,
      valor_unico_producto: fetchData?.valor_unico_producto,
      valor_obligatorio: fetchData?.valor_obligatorio,

      // valores de IconPicker
      icono_libreria: sanitazedIcon?.libreria,
      icono_fuente: sanitazedIcon?.fuente ?? null,
      icono_icono: sanitazedIcon?.icono,

      estatus: Number(fetchData?.estatus) ? true : false
    }

    setIsConsulting(true)

    axios(CONSTANT.queryUrl(id),
      {
        method: 'PUT',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
          'Content-Type': 'application/json',
          '_method': 'PUT'
        },
        data
      })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data?.nombre} actualizado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() =>
        setIsConsulting(false)
      )
  }

  return (

    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>
        {fetchData && allCategorias && categoria
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className='row'>
              <Switch
                name='estatus'
                value={fetchData?.estatus}
                labelText='Estatus'
                handleValue={handleInputChange}
                size='col-12'
              />

              <div className="col-12 col-md-6">
                <SelectFilter
                  name='categoria_id'
                  value={categoria?.split('-')[1] ?? ''}
                  labelText='* Categoria'
                  placeholder='Seleccione una categoría'
                  handleValue={handleInputChange}
                  optionObj={allCategorias?.map(({ id, nombre }) => ({ id, name: nombre }))}
                  size='col-12'
                  sizeDesk='col-md-12'
                />

                <SelectFilter
                  name='nombre'
                  value={fetchData?.nombre?.split('-')[0] ?? 'Seleccione'}
                  labelText='* Nombre'
                  handleValue={handleInputChange}
                  optionObj={claves?.map(({ nombre }) => ({ id: nombre, name: nombre }))}
                  size='col-12'
                  sizeDesk='col-md-12'
                />

                <FormInput
                  value={fetchData?.nombre.split('-')[0].split('_').join(' ').toLowerCase() ?? ''}
                  labelText='Nombre a mostrar'
                  handleValue={handleInputChange}
                  size='col-12'
                  isDisabled
                />

                <FormInput
                  type='number'
                  name='posicion_en_nombre'
                  value={fetchData?.posicion_en_nombre ?? ''}
                  labelText='Posición en nombre'
                  handleValue={handleInputChange}
                  size='col-12'
                />

                <FormInput
                  name='informacion'
                  value={fetchData?.informacion ?? ''}
                  labelText='Información'
                  handleValue={handleInputChange}
                  size='col-12'
                />
              </div>

              <div className='col-12 col-md-6'>
                <IconPicker
                  labelText="* Ícono"
                  value={icono ?? false}
                  handleValue={setIcono}
                />

                <CheckBoxInput
                  name='atributo_filtrable'
                  value={fetchData?.atributo_filtrable ?? false}
                  labelText='Atributo filtrable'
                  handleValue={handleInputChange}
                  classes='col-12 mb-50'
                />

                <CheckBoxInput
                  name='atributo_comparable'
                  value={fetchData?.atributo_comparable ?? false}
                  labelText='Atributo comparable'
                  handleValue={handleInputChange}
                  classes='col-12 mb-50'
                />

                <CheckBoxInput
                  name='incluir_en_nombre'
                  value={fetchData?.incluir_en_nombre ?? false}
                  labelText='Incluir en nombre'
                  handleValue={handleInputChange}
                  classes='col-12 mb-50'
                />

                <CheckBoxInput
                  name='incluir_en_descripcion'
                  value={fetchData?.incluir_en_descripcion ?? false}
                  labelText='Incluir en descripcion'
                  handleValue={handleInputChange}
                  classes='col-12 mb-50'
                />

                <CheckBoxInput
                  name='incluir_en_especificaciones'
                  value={fetchData?.incluir_en_especificaciones ?? false}
                  labelText='Incluir en especificaciones'
                  handleValue={handleInputChange}
                  classes='col-12 mb-50'
                />

                <CheckBoxInput
                  name='incluir_en_busqueda'
                  value={fetchData?.incluir_en_busqueda ?? false}
                  labelText='Incluir en búsqueda'
                  handleValue={handleInputChange}
                  classes='col-12 mb-50'
                />

                <CheckBoxInput
                  name='incluir_en_url'
                  value={fetchData?.incluir_en_url ?? false}
                  labelText='Incluir en URL'
                  handleValue={handleInputChange}
                  classes='col-12 mb-50'
                />

                <CheckBoxInput
                  name='incluir_en_comparacion'
                  value={fetchData?.incluir_en_comparacion ?? false}
                  labelText='Incluir en comparacion'
                  handleValue={handleInputChange}
                  classes='col-12 mb-50'
                />

                <CheckBoxInput
                  name='valor_unico_producto'
                  value={fetchData?.valor_unico_producto ?? false}
                  labelText='Valor único producto'
                  handleValue={handleInputChange}
                  classes='col-12 mb-50'
                />

                <CheckBoxInput
                  name='valor_obligatorio'
                  value={fetchData?.valor_obligatorio ?? false}
                  labelText='Valor obligatorio'
                  handleValue={handleInputChange}
                  classes='col-12 mb-50'
                />
              </div>

              <TextareaInput
                name='descripcion'
                value={fetchData?.descripcion ?? ''}
                labelText='Descripción'
                handleValue={handleInputChange}
                rows={2}
                size='col-12'
              />
            </div>

            <Button
              disabled={isConsulting}
              type="submit"
              text="Actualizar"
              color="primary"
              icon="Circle"
              onClick={handleFetch}
            />
          </CustomForm>
          : <CustomLoader />
        }
      </div>
    </div>

  );
};

export default Update;

import { useEffect } from 'react';
import { useState } from 'react';
import React from 'react';

// Redux
import { clearFilterDivisas, resultadosDivisas } from '../../redux/actions/divisas';
import { useSelector } from 'react-redux';


// Components
import CustomLoader from "../CustomLoader";
import Table from "../Table";


// Helpers
import { PrecioFormatter } from '../../helpers/precioFormatter';


//Layouts
import AccordionTable from "../../layout/AccordionTable";


// Components
import FormInput from '../FormInput';
import { ReportePorSkuTable } from './ReporteVentas/ReportePorSku';


const PrecioRaw = (v) => new PrecioFormatter(v).producto(true)


const sortFecha = (a, b) => {
  const aTimestamp = new Date(a).getTime()
  const bTimestamp = new Date(b).getTime()

  return (aTimestamp > bTimestamp) ? 1 : 0
}


const CONSTANT = {
  pathUrl: '/reporte-ventas',
  permissionsPath: 'reporte-ventas',
  title: 'Reporte de ventas por día',
  thrdTitle: 'Resumen por día',
  scndTitle: 'Resumen por SKU',
  reduxClearFilters: clearFilterDivisas,
  reduxSetResults: resultadosDivisas,

  datatableHeadInputState: {
    ventas: {
      fecha: '',
      filtro_pedido: '',
      cot: '',
      nv: '',
      descripcion: '',
      frontend: '',
      vendedor: '',
      cliente: ''
    },
    dia: {
      fecha: ''
    },
  }
}


const DivisasTable = () => {
  const { resultados } = useSelector(state => state.reporteVentas)
  const [datatableHeadInput, setDatatableHeadInput] = useState(CONSTANT.datatableHeadInputState)
  const [response, setResponse] = useState(resultados)


  useEffect(() => {
    if (!resultados || !Object?.values(resultados)?.length) setResponse(null)
    setResponse({
      ...resultados,
      resumen_pedidos: resultados?.resumen_pedidos?.map(p => ({ ...p, descripcion: `${p.sku} ${p?.producto?.toLowerCase()}`, cliente: `${p.rut_cliente?.toLowerCase() ?? ''} ${p.nombre_cliente?.toLowerCase() ?? 'N/A'} ${p.forma_pago?.toLowerCase() ?? 'N/A'}`, filtro_pedido: `${p.np} ${p?.estatus_pedido?.toLowerCase()}` })),
      resumen_por_dia: resultados?.resumen_por_dia?.sort((a, b) => sortFecha(a.fecha, b.fecha))?.map(p => p),
      resumen_por_sku: Object?.values(resultados?.resumen_por_sku ?? {})?.sort((a, b) => b.cantidad - a.cantidad)?.map(p => p)
    })

    return () => {
      setResponse(null)
    }
  }, [resultados])


  // FUNCION MANEJADORA DE LOS INPUTS DE LAS ASIGNACIONES MASIVAS
  const handleDatatableHeaderInputs = (e) => {
    const { name, value } = e.target
    const [target, key] = name.split('-')

    setDatatableHeadInput({
      ...datatableHeadInput,
      [target]: {
        ...datatableHeadInput[target],
        [key]: value?.toLowerCase()
      },
    })
  }


  return (
    <>
      <AccordionTable
        classes='mb-2'
        title={`${CONSTANT.title} (${response?.resumen_pedidos?.length ?? 0} resultados)`}
      >
        {!response && <CustomLoader blocking={'partial'} />}

        <Table pb={false} style={{ maxHeight: '1000px' }}>
          <thead className="table-light position-sticky top-0 w-100 z-100 shadow text-center">
            <tr role="row">
              <th>
                <FormInput
                  labelText=''
                  placeholder="Fecha"
                  name='ventas-fecha'
                  value={datatableHeadInput.ventas.fecha}
                  handleValue={handleDatatableHeaderInputs}
                  onKeyDown={handleDatatableHeaderInputs}
                  size="col-12"
                  sizeDesk="col-md-12"
                  margin="mb-0"
                />
              </th>

              <th>
                <FormInput
                  labelText=''
                  placeholder="COT"
                  name='ventas-cot'
                  value={datatableHeadInput.ventas.cot}
                  handleValue={handleDatatableHeaderInputs}
                  onKeyDown={handleDatatableHeaderInputs}
                  size="col-12"
                  margin="mb-0"
                />
              </th>

              <th>
                <FormInput
                  labelText=''
                  placeholder="NP"
                  name='ventas-filtro_pedido'
                  value={datatableHeadInput.ventas.filtro_pedido}
                  handleValue={handleDatatableHeaderInputs}
                  onKeyDown={handleDatatableHeaderInputs}
                  size="col-12"
                  margin="mb-0"
                />
              </th>

              <th>
                <FormInput
                  labelText=''
                  placeholder="NV"
                  name='ventas-nv'
                  value={datatableHeadInput.ventas.nv}
                  handleValue={handleDatatableHeaderInputs}
                  onKeyDown={handleDatatableHeaderInputs}
                  size="col-12"
                  margin="mb-0"
                />
              </th>

              <th>
                <FormInput
                  labelText=''
                  placeholder="SKU | DESCRIPCIÓN"
                  name='ventas-descripcion'
                  value={datatableHeadInput.ventas.descripcion}
                  handleValue={handleDatatableHeaderInputs}
                  onKeyDown={handleDatatableHeaderInputs}
                  size="col-12"
                  margin="mb-0"
                />
              </th>

              <th>QTY  Precio</th>

              <th>
                <FormInput
                  labelText=''
                  placeholder="Frontend"
                  name='ventas-frontend'
                  value={datatableHeadInput.ventas.frontend}
                  handleValue={handleDatatableHeaderInputs}
                  onKeyDown={handleDatatableHeaderInputs}
                  size="col-12"
                  margin="mb-0"
                />
              </th>

              <th>
                <FormInput
                  labelText=''
                  placeholder="Vendedor"
                  name='ventas-vendedor'
                  value={datatableHeadInput.ventas.vendedor}
                  handleValue={handleDatatableHeaderInputs}
                  onKeyDown={handleDatatableHeaderInputs}
                  size="col-12"
                  margin="mb-0"
                />
              </th>

              <th>
                <FormInput
                  labelText=''
                  placeholder="Búsqueda"
                  name='ventas-cliente'
                  value={datatableHeadInput.ventas.cliente}
                  handleValue={handleDatatableHeaderInputs}
                  onKeyDown={handleDatatableHeaderInputs}
                  size="col-12"
                  margin="mb-0"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {response?.resumen_pedidos?.length
              ? response?.resumen_pedidos
                ?.filter(p => (String(p?.cot)?.includes(datatableHeadInput.ventas.cot) && String(p?.frontend_pedido)?.includes(datatableHeadInput.ventas.frontend) && p?.filtro_pedido?.includes(datatableHeadInput.ventas.filtro_pedido) &&
                  String(p?.nv)?.includes(datatableHeadInput.ventas.nv) && String(p?.descripcion)?.includes(datatableHeadInput.ventas.descripcion) && String(p?.vendedor).toLowerCase()?.includes(datatableHeadInput.ventas.vendedor) &&
                  p.fecha.includes(datatableHeadInput.ventas.fecha) && p.cliente.includes(datatableHeadInput.ventas.cliente.toLocaleLowerCase())))
                ?.map((data, i) => {
                  return (
                    <tr className="odd text-center" key={`row-${i}-${data.producto}-${data?.sku}`} style={{ backgroundColor: data?.estado_pago === 'Pagado' ? '#fff' : '#fad7dc', borderBottom: '1px solid #222' }}>
                      <td className='text-nowrap'>{data?.fecha || 'N/A'}</td>

                      <td className={`${data?.cot ? '' : 'bg-danger'}`}>{data?.cot ?? ""}</td>

                      <td>
                        <div className='d-flex flex-column'>
                          <span>{data?.np ?? 'S/P'}</span>
                          <span className='tag px-25 mx-auto'>{data?.estatus_pedido ?? '?'}</span>
                        </div>
                      </td>

                      <td className={`${data?.nv ? '' : 'bg-danger'}`}>{data?.nv ?? ""}</td>


                      <td>
                        <div className='d-flex flex-column'>
                          <span className='mb-25'>{data?.sku ?? 'N/A'}</span>

                          <b>{data?.producto.toUpperCase() ?? 'N/A'}</b>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex flex-column'>
                          <b className='fs-4'>{data?.cantidad.split('.')[0] ?? "N/A"}</b>
                          <span className='text-nowrap'><b>{PrecioRaw(data?.precio_linea)}</b></span>
                          <span className='text-nowrap'>P unit: {PrecioRaw((data?.precio_linea) / (data?.cantidad))}</span>
                          <span className='text-nowrap'>P pag: {PrecioRaw(data?.precio_original_unitario)}</span>
                          {data?.ofertas && <strong className='text-nowrap'>Oferta: {data?.ofertas}</strong>}
                        </div>
                      </td>

                      <td className='text-nowrap'>
                        <div className='d-flex flex-column'>
                          <p className={`tag px-50 ${data?.frontend_pedido === "bridgestone" ? 'bridgestone' : 'chileneumaticos'} mx-auto mt-50`}>{data?.frontend_pedido ?? 'chileneumaticos'}</p>
                          <span>Pedido: <b>{PrecioRaw(data?.monto_pedido)}</b></span>
                          <span>Pagado: <b>{PrecioRaw(data?.monto_pagado)}</b></span>

                          <b className={`tag px-50 mx-auto ${data?.estado_pago === 'Pagado' ? 'success' : 'yellow'}`}>{data?.estado_pago ?? 'N/A'}</b>
                        </div>
                      </td>

                      <td>{data?.vendedor ?? 'N/A'}</td>

                      <td>
                        <div className='d-flex flex-column'>
                          <b>{data?.nombre_cliente ?? 'N/A'}</b>
                          <span>{data?.rut_cliente ?? 'N/A'}</span>
                          <span>{data?.forma_pago ?? 'N/A'}</span>
                        </div>
                      </td>
                    </tr>
                  )
                })
              : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={9}><i>Sin datos para mostrar...</i></td></tr>
            }
          </tbody>
        </Table>
      </AccordionTable >

      {/* RESUMEN POR DIA */}
      < AccordionTable
        classes='mb-2'
        title={`${CONSTANT.scndTitle} (${response?.resumen_por_dia?.length ?? 0} resultados)`
        }
      >
        {!response && <CustomLoader blocking={'partial'} />}

        <Table pb={false} style={{ maxHeight: '1000px' }}>
          <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
            <tr role="row" className='text-center'>
              <th className='col-2'>
                <FormInput
                  labelText=''
                  placeholder="Fecha"
                  name='dia-fecha'
                  value={datatableHeadInput.dia.fecha}
                  handleValue={handleDatatableHeaderInputs}
                  onKeyDown={handleDatatableHeaderInputs}
                  size="col-12"
                  sizeDesk="col-md-12"
                  margin="mb-0"
                />
              </th>
              <th>Cantidad</th>
              <th>Precio total</th>
            </tr>
          </thead>
          <tbody>
            {response?.resumen_por_dia?.length
              ? response.resumen_por_dia
                ?.filter(p => p.fecha.includes(datatableHeadInput.dia.fecha))
                ?.map((data, i) => {
                  return (
                    <tr className="odd text-center" key={`rsm-dia-${data.fecha}-${data.i}`}>
                      <td className='col-2'>{data?.fecha ?? '?'}</td>
                      <td>{data?.cantidad ?? "N/A"}</td>
                      <td><b>{PrecioRaw(data?.precio_total)}</b></td>
                    </tr>
                  )
                })
              : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
            }
          </tbody>
        </Table>
      </AccordionTable >

      {/* RESUMEN POR SKU */}
      <ReportePorSkuTable data={response?.resumen_por_sku} />
    </>

  )
}

export default DivisasTable
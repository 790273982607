import axios from 'axios';

// Redux
import { useDispatch, useSelector } from 'react-redux'
// import { fireToaster } from "../../redux/actions/toaster";


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Table from "../Table";


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { idExtractor } from '../../helpers/idExtractor';
import { clearFilterHistorial, resultadosHistorial } from '../../redux/actions/historialCambios';
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter';


const CONSTANT = {
  reduxStateSelector: 'historialCambios',
  permissionsPath: 'historial-cambios',

  queryUrlGetOne: getOne.historialCambios,
  pathUrl: 'historial-cambios',
  title: 'Búsqueda de historial',

  reduxClearFilters: clearFilterHistorial,
  reduxSetResults: resultadosHistorial,
}


const dataFormatter = (filtrosObj) => {
  let data = {}

  Object.entries(filtrosObj).forEach(([key, value]) => {
    if (['usuario_id', 'evento_id'].includes(key) && value.length) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          [key]: value.map(el => idExtractor(el))
        }
      }
    }
  })

  return data
}


const HistorialCambiosTable = () => {
  const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()


  const handleUrl = (url) => {
    dispatch(CONSTANT.reduxSetResults(null))
    axios(url + queryParamsFormatter({ accion: filtros.accion }) + `&limit=${showing_results}`,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: dataFormatter(filtros)
      })
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
      .catch(err => console.error(err))
  }


  // const handleFetch = async (objData, url, target) => {
  //   let config = {
  //     headers: {
  //       'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
  //     },
  //   }

  //   if (target === 'estatus') {
  //     config = {
  //       ...config,
  //       method: 'PUT',
  //       data: {
  //         estatus: !objData?.estatus
  //       }
  //     }
  //   }

  //   if (target === 'delete') {
  //     if (!window.confirm('¿Está seguro que desea eliminar el registro?')) return

  //     config = { ...config, method: 'DELETE' }
  //   }

  //   await axios(CONSTANT.queryUrlGetOne(objData.id), config)
  //     .then(res => {
  //       const toasterContent = {
  //         title: 'Operación realizada',
  //         text: `${CONSTANT.title}: registro actualizado con éxito`,
  //         icon: 'success'
  //       }

  //       dispatch(fireToaster(toasterContent))
  //     })
  //     .catch(err => {
  //       console.error(err);
  //       const { errores } = err.response.data
  //       let detalles = []
  //       Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

  //       const toasterContent = {
  //         title: `
  //           Operación fallida
  //           (${err.response.status} - ${err.response.statusText})
  //         `,
  //         html: `
  //           <b>Detalle: </b>
  //           ${detalles.map(error => `<br /><i>-${error}</i>`)}
  //         `,
  //         icon: 'error'
  //       }
  //       dispatch(fireToaster(toasterContent))
  //     })

  //   dispatch(CONSTANT.reduxSetResults(null))

  //   axios(url + queryParamsFormatter({ accion: filtros.accion })`&limit=${showing_results}`,
  //     {
  //       headers: {
  //         'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
  //       },
  //       method: 'POST',
  //       data: dataFormatter(filtros)
  //     })
  //     .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
  //     .catch(err => console.error(err))
  // }

  return (
    <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
      {!response && <CustomLoader blocking={'partial'} />}

      <Table response={response} handlePagination={handleUrl}>
        <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
          <tr role="row">
            <th>ID</th>
            <th>Descripción</th>
            <th>Usuario</th>
            <th>Fecha</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {response?.data?.length
            ? response.data?.map((data) => {
              return (
                <tr className="odd" key={`producto-proveedor-${data.id}`}>
                  <td>{data?.id}</td>

                  <td>
                    <div className='d-flex flex-column'>
                      <span>Acción: <b> {data?.accion ?? 'N/A'}</b></span>
                      <span>Evento: <b> {data?.evento?.nombre ?? 'N/A'}</b></span>
                      <span>Modelo: <b>{data?.modelo ?? 'N/A'}</b></span>
                    </div>
                  </td>

                  <td>
                    <b>{data?.usuario?.nombre ?? 'N/A'}</b>
                  </td>

                  <td>{data?.updated_at?.split('T')?.[0] ?? data?.created_at?.split('T')?.[0] ?? 'N/A'}</td>

                  <td>
                    <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                      <DropdownMenuLink text='Detalle' href={`/${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
                      {/* <DropdownMenuLink text='Editar' href={`/${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" /> */}
                      {/* <DropdownMenuLink
                        text='Borrar'
                        icon="X"
                        onClick={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`, 'delete')}
                      /> */}
                    </DropdownWrapper>
                  </td>
                </tr>
              )
            })
            : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
          }
        </tbody>
      </Table>
    </AccordionTable >
  )
}

export default HistorialCambiosTable
import axios from "axios"
import { getOne } from "../../endpoints/getOne"
import { idExtractor } from "../../helpers/idExtractor"

const validFormat = (form) => {
  const { porcentaje_descuento, credito, tipo_id } = form

  const isB2B = tipo_id.split('%-%')[1] === 'b2b'


  return {
    porcentaje_descuento: Number(porcentaje_descuento),
    credito: isB2B ? Number(credito) : undefined,
    tipo_id: Number(idExtractor(tipo_id, true))
  }
}

export const approveEmpresa = async (form) => {
  try {
    const { id, ...restForm } = form

    const response = await axios(`${getOne.aprobar_empresa(id)}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'PUT',
      data: validFormat({ ...restForm })
    })
    if (!response.status) throw response

    return response.data
  } catch (err) {
    console.error(err)
    return err.response
  }
}
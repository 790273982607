import axios from 'axios'
import React, { useEffect, useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { FILTER_NOMBRE, FILTER_FECHA_INICIO, FILTER_FECHA_FINAL, FILTER_ADD_ESTATUS } from '../../redux/redux-types'
import { fireToaster } from '../../redux/actions/toaster'
import { clearFilterOfertas, estatusFilter, fechaHoraFinalFilter, fechaHoraInicioFilter, nombreFilter, resultadosOfertas } from '../../redux/actions/ofertas'
import { resultadosProductos } from '../../redux/actions/productos'


// Components
import FormInput from '../FormInput'
import SelectFilter from '../SelectFilter'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'


const CONSTANT = {
  queryUrlGetAll: getAll.ofertas,
  redirectUrl: '/ofertas',
  title: 'Búsqueda de ofertas',
  reduxSetProductos: resultadosProductos,
  reduxClearFilters: clearFilterOfertas,
  reduxSetResults: (data) => resultadosOfertas(data)
}


const OfertasFiltros = ({ isAsignacionMasiva = false }) => {
  const { filtros } = useSelector(state => state.ofertas)
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const {
    nombre,
    estatus,
    fecha_hora_inicio,
    fecha_hora_final
  } = filtros

  const [isConsulting, setIsConsulting] = useState(false)


  useEffect(() => {
    axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data.ofertas)))
      .catch(err => console.error(err))
  }, [showing_results, dispatch])


  // EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
  useEffect(() => {
    return () => {
      dispatch(CONSTANT.reduxClearFilters())
      dispatch(CONSTANT.reduxSetProductos(null))
    }
  }, [dispatch])


  // FUNCION MANEJADORA QUE CONSULTA EL ENDPOINT CON LOS FILTROS APLICADOS
  const handleFetchFilter = async (reset = false) => {
    let paramEstatus = estatus.split('-')[0]
    const url = CONSTANT.queryUrlGetAll + `?limit=${isAsignacionMasiva ? '99999' : showing_results}`

    setIsConsulting(true)

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return axios(url, {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
        .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data.ofertas)))
        .catch(err => console.error(err))
        .finally(() => {
          setIsConsulting(false)
        })
    }

    // dispatch(CONSTANT.reduxSetResults(null))

    await axios(url + queryParamsFormatter({
      nombre,
      estatus: paramEstatus === '2' ? 0 : paramEstatus,
      fecha_hora_inicio: fecha_hora_inicio?.split('T').join('%20'),
      fecha_hora_final: fecha_hora_final?.split('T').join('%20')
    }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data?.ofertas ?? []))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
      })
      .catch(err => {
        console.error(err)

        const { errores } = err.response.data
        let detalles = []

        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO - ADICIONALES
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_NOMBRE:
        dispatch(nombreFilter(value))
        break;

      case FILTER_ADD_ESTATUS:
        dispatch(estatusFilter(value))
        break;

      case FILTER_FECHA_INICIO:
        dispatch(fechaHoraInicioFilter(value))
        break;

      case FILTER_FECHA_FINAL:
        dispatch(fechaHoraFinalFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className={`card mb-50 shadow-none bg-transparent ${isAsignacionMasiva ? 'col-md-6 col-12' : 'col-12'}`}>
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <FormInput
            labelText='Nombre'
            placeholder='Ingrese su búsqueda'
            size='col-12'
            sizeDesk={isAsignacionMasiva ? 'col-md-6' : 'col-md-3'}
            handleValue={(e) => handleAddFilter(e, FILTER_NOMBRE)}
            value={nombre}
          />

          <SelectFilter
            labelText='Estatus'
            size='col-12'
            sizeDesk={isAsignacionMasiva ? 'col-md-6' : 'col-md-2'}
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_ESTATUS)}
            optionObj={[{ id: 2, name: 'Sin selección' }, { id: 1, name: 'Activo' }, { id: 0, name: 'Inactivo' }]}
            value={estatus?.split('-')[1] ?? 'Seleccione'}
          />

          <FormInput
            type='datetime-local'
            labelText='Fecha hora inicio'
            max={fecha_hora_final}
            size='col-12'
            sizeDesk={isAsignacionMasiva ? 'col-md-6' : 'col-md-2'}
            handleValue={(e) => handleAddFilter(e, FILTER_FECHA_INICIO)}
            value={fecha_hora_inicio}
          />

          <FormInput
            type='datetime-local'
            labelText='Fecha hora final'
            min={fecha_hora_inicio}
            size='col-12'
            sizeDesk={isAsignacionMasiva ? 'col-md-6' : 'col-md-2'}
            handleValue={(e) => handleAddFilter(e, FILTER_FECHA_FINAL)}
            value={fecha_hora_final}
          />
        </>
      </ListadoHeader>
    </div>
  )
}

export default OfertasFiltros
import {
   FILTER_ADD_CATEGORIA,
   FILTER_REMOVE_CATEGORIA,
   FILTER_BUSQUEDA,
   FILTER_REMOVE_METODOS_PAGO,
   FILTER_ADD_METODOS_PAGO,
   RESULTADOS_PRODUCTOS_METODOS_PAGO,
   FILTER_CLEAR_PRODUCTOS_METODOS_PAGO,
   FILTER_SKUS_PRODUCTO
} from "../redux-types";


export const categoriaFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_CATEGORIA, payload: data }
      : { type: FILTER_ADD_CATEGORIA, payload: data };

export const metodosPagoFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_METODOS_PAGO, payload: data }
      : { type: FILTER_ADD_METODOS_PAGO, payload: data };

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const skusFilter = (data) => ({ type: FILTER_SKUS_PRODUCTO, payload: data })

export const resultadosProductosMetodosPago = (data) => ({ type: RESULTADOS_PRODUCTOS_METODOS_PAGO, payload: data })

export const clearFilterProductosMetodosPago = () => ({ type: FILTER_CLEAR_PRODUCTOS_METODOS_PAGO });

import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   FILTROS_PERSISTENTES_GARANTIAS_RESPUESTAS,
   RESULTADOS_GARANTIAS_RESPUESTAS,
} from "../redux-types";

const initialState = {
   filtros: {
      // params
      busqueda: '',
   },
   resultados: null
}


export const garantiasRespuestasReducer = (state = initialState, action) => {

   switch (action.type) {
      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda: action.payload
            }
         };

      case FILTROS_PERSISTENTES_GARANTIAS_RESPUESTAS:
         return {
            ...state, filtros: action.payload
         };

      case RESULTADOS_GARANTIAS_RESPUESTAS:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};

// Components
import CriteriosComparacionFiltros from "../../components/AccordionFilter/CriteriosComparacion";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import CriteriosComparacionTable from "../../components/Tables/CriteriosComparacionTable";

const CONSTANT = {
	title: 'Criterios de comparación',
	path: 'criterios-comparacion',
	create_btn: 'Crear criterio'
}

const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/generales/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<CriteriosComparacionFiltros />

			<CriteriosComparacionTable />
		</div>
	);
}

export default Listado;
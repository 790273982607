import axios from 'axios';

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { clearFilterOfertas, resultadosOfertas } from '../../redux/actions/ofertas';
import { fireToaster } from "../../redux/actions/toaster";


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Switch from "../Switch";
import Table from "../Table";


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter';
import { PrecioFormatter } from '../../helpers/precioFormatter';


const Precio = (v) => new PrecioFormatter(v).producto()


const CONSTANT = {
   pathUrl: '/ofertas',
   permissionsPath: 'ofertas',
   queryUrlGetOne: getOne.ofertas,
   title: 'Ofertas',
   reduxClearFilters: clearFilterOfertas,
   reduxSetResults: resultadosOfertas,
}


const OfertasTable = () => {
   const { resultados: response, filtros } = useSelector(state => state.ofertas)
   const { showing_results } = useSelector(state => state.listedResults)
   const dispatch = useDispatch()
   const {
      nombre,
      estatus,
      fecha_hora_inicio,
      fecha_hora_final
   } = filtros
   const { user } = useSelector(state => state.auth)


   const handleUrl = (url) => {
      dispatch(CONSTANT.reduxSetResults(null))
      axios(url + `&limit=${showing_results}` + queryParamsFormatter(
         {
            nombre,
            estatus: Number(estatus?.split('-')[0]),
            fecha_hora_inicio: fecha_hora_inicio?.split('T').join('%20'),
            fecha_hora_final: fecha_hora_final?.split('T').join('%20')
         }
      ),
         {
            headers: {
               'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
         })
         .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data.ofertas)))
         .catch(err => console.error(err))
   }


   const handleEstatus = async (objData, url) => {
      let newEstatus = objData.estatus ? 0 : 1

      let data = new FormData()
      data.append('estatus', newEstatus)
      data.append('_method', 'PUT')

      await axios(CONSTANT.queryUrlGetOne(objData.id), {
         method: 'POST',
         headers: {
            'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
            'Content-Type': 'multipart/form-data'
         },
         data
      })
         .then(res => {
            const toasterContent = {
               title: 'Operación realizada',
               text: `${CONSTANT.title}: estatus actualizado con éxito`,
               icon: 'success'
            }

            dispatch(fireToaster(toasterContent))
         })
         .catch(err => {
            console.error(err);
            const { errores } = err.response.data
            let detalles = []
            Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

            const toasterContent = {
               title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
               html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
               icon: 'error'
            }
            dispatch(fireToaster(toasterContent))
         })

      dispatch(CONSTANT.reduxSetResults(null))

      axios(url + `&limit=${showing_results}` + queryParamsFormatter(
         {
            nombre,
            estatus: Number(estatus?.split('-')[0]),
            fecha_hora_inicio: fecha_hora_inicio?.split('T').join('%20'),
            fecha_hora_final: fecha_hora_final?.split('T').join('%20')
         }
      ),
         {
            headers: {
               'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
         })
         .then(({ data }) => {
            dispatch(CONSTANT.reduxSetResults(data.ofertas))
         })
         .catch(err => console.error(err))
   }


   return (
      <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
         {!response && <CustomLoader blocking={'partial'} />}

         <Table response={response} handlePagination={handleUrl}>
            <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
               <tr role="row">
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Datos</th>
                  <th>Fechas</th>
                  <th>Creación</th>
                  <th>Estatus</th>
                  <th className="w-max">Acciones</th>
               </tr>
            </thead>
            <tbody>
               {response?.data?.length
                  ? response.data?.map((data) => {
                     return (
                        <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                           <td>{data?.id}</td>
                           <td>{data?.nombre ?? "N/A"}</td>
                           <td>
                              <div className='d-flex flex-column'>
                                 <span>Criterio comparación: <b>{data?.criterio_comparacion?.nombre ?? "N/A"}</b></span>
                                 <span>Límite: <b>{data?.cantidad_limite ?? "N/A"}</b></span>
                                 <span>Venta/caducidad: <b>{data?.cantidad_venta_caducidad ?? "N/A"}</b></span>
                                 <span>Monto descuento: <b>{data?.porcentaje_descuento ? `${data?.porcentaje_descuento}%` : 'N/A'}</b></span>
                                 <span>Porcentaje descuento: <b>{Precio(Number(data?.monto_descuento))}</b></span>
                              </div>
                           </td>

                           <td>
                              <div className='d-flex flex-column'>
                                 <span>Inicio: <b>{`${data?.fecha_hora_inicio?.split('T')[0]} | ${data?.fecha_hora_inicio?.split('T')[1].split('.')[0]}`}</b></span>
                                 <span>Final: <b>{`${data?.fecha_hora_final?.split('T')[0]} | ${data?.fecha_hora_final?.split('T')[1].split('.')[0]}`}</b></span>
                              </div>
                           </td>

                           <td>{data?.created_at.split("T")[0] || 'N/A'}</td>
                           
                           <td>
                              <Switch value={data.estatus} labelText={""} handleValue={() => handleEstatus(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`)} />
                           </td>
                           <td>
                              <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                                 {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                                    <DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
                                 }

                                 {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
                                    <DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
                                 }
                              </DropdownWrapper>
                           </td>
                        </tr>
                     )
                  })
                  : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={8}><i>Sin datos para mostrar...</i></td></tr>
               }
            </tbody>
         </Table>
      </AccordionTable >
   )
}

export default OfertasTable
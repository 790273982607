
// Components
import Index from "./Tabs/Index";


const NotasVentasIndex = ({ view = <Index /> }) => {
   return (
      <div className="row">
         <div className="col">
            {view}
         </div>
      </div>
   )
}

export default NotasVentasIndex;
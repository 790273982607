import axios from 'axios';

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from "../../redux/actions/toaster";


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Switch from "../Switch";
import Table from "../Table";


// Endpoints
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { resultadosCategorias } from '../../redux/actions/categorias';


const CONSTANT = {
   pathUrl: '/productos/categorias',
   permissionsPath: 'categorias',
   queryUrlGetOne: getOne.categoria,
   title: 'Categorias',
   reduxSetResults: resultadosCategorias,
}


const CategoriasTable = () => {
   const { resultados: response } = useSelector(state => state.categorias)
   const { showing_results } = useSelector(state => state.listedResults)
   const dispatch = useDispatch()
   const { user } = useSelector(state => state.auth)


   const handleUrl = (url) => {
      dispatch(CONSTANT.reduxSetResults(null))
      axios(url + `&limit=${showing_results}`,
         {
            headers: {
               'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
            method: 'POST'
         })
         .then(res => dispatch(CONSTANT.reduxSetResults(res.data.categorias)))
         .catch(err => console.error(err))
   }


   const handleEstatus = async (objData, url) => {
      let newEstatus = objData.estatus ? 0 : 1

      let data = new FormData()
      data.append('estatus', newEstatus)
      data.append('_method', 'PUT')

      await axios(CONSTANT.queryUrlGetOne(objData.id), {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
         },
         data
      })
         .then(({ data }) => {
            const toasterContent = {
               title: 'Operación realizada',
               text: `${CONSTANT.title}: ${data?.nombre} - registro actualizado con éxito`,
               icon: 'success'
            }

            dispatch(fireToaster(toasterContent))
         })
         .catch(err => {
            console.error(err);
            const { errores } = err.response.data
            let detalles = []
            Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

            const toasterContent = {
               title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
               html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
               icon: 'error'
            }
            dispatch(fireToaster(toasterContent))
         })

      dispatch(CONSTANT.reduxSetResults(null))

      axios(url, { method: 'POST' })
         .then(({ data }) => {
            dispatch(CONSTANT.reduxSetResults(data?.categorias))
         })
         .catch(err => console.error(err))
   }


   return (
      <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
         {!response && <CustomLoader blocking={'partial'} />}

         <Table response={response} handlePagination={handleUrl}>
            <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
               <tr role="row">
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Tipo elemento</th>
                  <th>Creación</th>
                  <th>Estatus</th>
                  <th className="w-max">Acciones</th>
               </tr>
            </thead>
            <tbody>
               {response?.data.length
                  ? response.data?.map((data) => {
                     return (
                        <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                           <td>{data?.id}</td>
                           <td>{data?.nombre ?? "N/A"}</td>
                           <td>{data?.neu_tipo_elemento?.nombre ?? "N/A"}</td>
                           {/* <td>{data?.icono ?? "N/A"}</td> */}
                           {/* <td>{data?.imagen ?? "N/A"}</td> */}
                           <td>{data?.created_at.split("T")[0] || 'N/A'}</td>
                           <td>
                              <Switch value={data.estatus} labelText={""} handleValue={() => handleEstatus(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`)} />
                           </td>
                           <td>
                              <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                                 {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                                    <DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
                                 }
                                 {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                                    <DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
                                 }
                                 {/* <DropdownMenuLink text='Borrar' icon="Table" /> */}
                              </DropdownWrapper>
                           </td>
                        </tr>
                     )
                  })
                  : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={6}><i>Sin datos para mostrar...</i></td></tr>
               }
            </tbody>
         </Table>
      </AccordionTable >
   )
}

export default CategoriasTable
import axios from 'axios'
import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { FILTER_ADD_BODEGA, FILTER_ADD_PUNTO_ENTREGA, FILTER_ADD_REGION } from '../../redux/redux-types'
import { bodegaFilter, clearFilterBodegasPuntosEntrega, puntoEntregaFilter, regionFilter, resultadosBodegasPuntosEntrega } from '../../redux/actions/bodegasPuntosEntrega'


// Components
import SelectFilter from '../SelectFilter'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { idExtractor } from '../../helpers/idExtractor'
import { lastPosition } from '../../helpers/lastPosition'
import FiltroAplicado from './FiltroAplicado'


const CONSTANT = {
  queryUrlGetAll: getAll.bodegas_puntos_entrega + '/listar',
  redirectUrl: '/puntos-entrega/bodegas',
  reduxSelector: 'bodegasPuntoEntrega',
  title: 'Búsqueda de bodegas',

  fetchPuntosEntrega: getAll.puntos_entrega + '/listar',
  fetchBodegas: getAll.erp_bodegas + '/obtener-vigentes',
  fetchRegiones: getAll.regiones + '/buscar',


  reduxClearFilters: clearFilterBodegasPuntosEntrega,
  reduxSetResults: resultadosBodegasPuntosEntrega,

  selectFilterInitialState: {
    bodegas: [],
    puntosEntrega: [],
    regiones: []
  }
}


const dataFormatter = (filtrosObj) => {
  let data = {}

  Object.entries(filtrosObj).forEach(([key, value]) => {
    if (value.length) {
      if (['bodega_id', 'region_id', 'punto_entrega_id'].includes(key)) {
        data = {
          ...data,
          filtros: {
            ...data.filtros,
            [key]: value.map(el => idExtractor(el))
          }
        }
      }
    }
  })

  return data
}


const handleSelectsData = async () => {
  const puntosEntrega = await axios(CONSTANT.fetchPuntosEntrega, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    }, method: 'POST'
  })
    .then(({ data }) => data)
    .catch(err => {
      console.error(err)
      return []
    })

  const regiones = await axios(CONSTANT.fetchRegiones, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    }, method: 'POST'
  })
    .then(({ data }) => data?.regiones ?? data)
    .catch(err => {
      console.error(err)
      return []
    })

  const bodegas = await axios(CONSTANT.fetchBodegas, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
  })
    .then(({ data }) => data.data)
    .catch(err => {
      console.error(err)
      return []
    })

  return { puntosEntrega, regiones, bodegas }
}


const BodegasPuntosEntregaFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const dispatch = useDispatch()
  const { punto_entrega_id, bodega_id, region_id } = filtros

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
      .catch(err => {
        dispatch(CONSTANT.reduxSetResults([]))
        console.error(err)
      })
  }, [dispatch, showing_results])


  useEffect(() => {
    handleSelectsData().then(data => setSelectFilter(data))
  }, [])


  // EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
  useEffect(() => {
    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = (reset = false) => {
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    }


    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}`, config)
        .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
        .catch(err => {
          dispatch(CONSTANT.reduxSetResults([]))
          console.error(err)
        })
    }

    config = {
      ...config,
      data: { ...dataFormatter(filtros) }
    }
    setIsConsulting(true)

    axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}`, config)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_ADD_PUNTO_ENTREGA:
        dispatch(puntoEntregaFilter(value))
        break;

      case FILTER_ADD_BODEGA:
        dispatch(bodegaFilter(value))
        break;

      case FILTER_ADD_REGION:
        dispatch(regionFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <SelectFilter
            labelText="Punto de entrega"
            size="col-12"
            sizeDesk="col-md-9"
            optionObj={selectFilter?.puntosEntrega?.map(({ id, direccion, tipo_destino, comuna }) => ({ id, name: `${tipo_destino?.nombre ?? 'N/A'} | ${direccion ?? 'N/A'} | ${comuna?.region?.nombre ?? 'N/A'}` }))}
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_PUNTO_ENTREGA)}
            value={lastPosition(punto_entrega_id)}
          />

          <SelectFilter
            labelText="Bodega"
            size="col-12"
            sizeDesk="col-md-6"
            optionObj={selectFilter.bodegas.map(({ WARE_CODE, DESC_TEXT }) => ({ id: WARE_CODE, name: `${WARE_CODE} | ${DESC_TEXT}` }))}
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_BODEGA)}
            value={lastPosition(bodega_id)}
          />

          <SelectFilter
            labelText="Región"
            size="col-12"
            sizeDesk="col-md-6"
            optionObj={selectFilter.regiones.map(({ id, nombre }) => ({ id, name: nombre }))}
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_REGION)}
            value={lastPosition(region_id)}
          />
        </>

        {/* FILTROS APLICADOS - PRODUCTOS */}
        <p className="mb-25 text-black">Filtros aplicados</p>
        <div className="row">
          <FiltroAplicado title="Puntos de entrega" array={punto_entrega_id} func={puntoEntregaFilter} />
          <FiltroAplicado title="Bodegas" array={bodega_id} func={bodegaFilter} />
          <FiltroAplicado title="Regiones" array={region_id} func={regionFilter} />
        </div>
        <hr className="my-1" />
      </ListadoHeader>
    </div>
  )
}

export default BodegasPuntosEntregaFiltros
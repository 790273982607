import axios from "axios";


//	Components
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import FormInput from "../../components/FormInput";


// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


// Hooks
import { useForm } from "../../hooks/useForm";


// Endpoint
import { create } from "../../endpoints/create";


// React-router
import { useNavigate } from "react-router-dom";


const initialformState = {
  nombre: "",
  estatus: true,
}


const CONSTANT = {
  redirectUrl: '/generales/tipos-atencion',
  componentTitle: 'Crear tipo de atención',
  title: 'Tipos de atención',
  queryUrl: create.tipos_atencion,
}


const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [{ nombre, estatus }, handleInputChange, reset] = useForm(initialformState);


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = (e) => {
    e.preventDefault()

    let data = {
      nombre,
      estatus: Number(estatus) ? true : false
    }

    axios(CONSTANT.queryUrl, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'application/json'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>
        <CustomForm size="" title={CONSTANT.componentTitle}>
          <div className="row">
            <Switch name="estatus"
              value={estatus}
              labelText="Estatus"
              handleValue={handleInputChange}
              size="col-12"
            />

            <FormInput
              name="nombre"
              value={nombre}
              labelText="* Nombre"
              handleValue={handleInputChange}
              size="col-12"
            />
          </div>

          <Button type="submit" text="Crear" color="primary" icon="Table" onClick={handleFetch} />
        </CustomForm>
      </div>
    </div>
  );
};

export default Create;

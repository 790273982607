import axios from "axios";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";


// React-router
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";
import { useEffect, useState } from "react";
import CustomLoader from "../../components/CustomLoader";
import { getOne } from "../../endpoints/getOne";
import { getAll } from "../../endpoints/getAll";
import { fecthSelectFiltersData } from "../../helpers/fetchSelectFilterData";
import SelectFilter from "../../components/SelectFilter";
import { idExtractor } from "../../helpers/idExtractor";
import FormInput from "../../components/FormInput";


const CONSTANT = {
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  redirectUrl: '/garantias',
  componentTitle: id => `${id ? 'Editar' : 'Crear'} garantía`,
  title: 'Garantías',

  // NOTA: endpoint no habilitado porque no existe la creacion desde el panel
  queryUrl: create.garantia,

  fecthData: getOne.garantias,

  form: {
    estado_id: '',
    posicion_id: '',
    daño_id: '',
    resultado_id: '',
    respuesta_id: '',
    producto_sku: '',
    producto_nombre: '',
    producto: '',

    fecha_recepcion: '',
    fecha_envio: '',

    // req
    patente: '',
    vehiculo_marca: '',
    vehiculo_modelo: '',
    vehiculo_año: '', //int
    numero_factura: '', //int
    numero_chasis: '',
    observacion: '',

  },

  selectFilter: {
    estados: [],
    posiciones: [],
    daños: [],
    resultados: [],
    lineasProductos: [],
    respuestas: []
  }
}

const selectFilterQueries = [
  {
    url: getAll.garantia_respuestas,
    key: 'respuestas',
  },
  {
    url: getAll.garantia_daños,
    key: 'daños',
  },
  {
    url: getAll.garantia_resultados,
    key: 'resultados',
  },
  {
    url: getAll.garantia_estados,
    key: 'estados',
  },
  {
    url: getAll.garantia_posiciones,
    key: 'posiciones',
  }
]

const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.form)
  const { id } = useParams()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const [isConsulting, setIsConsulting] = useState(false)

  // EFECTO QUE SETEA LA DATA DE LOS SELECTFILTERS
  useEffect(() => {
    fecthSelectFiltersData(selectFilterQueries).then(res => setSelectFilter(res))
  }, [])


  // EFECTO QUE SETEA LA DATA DEL FORMULARIO DE EDICION
  useEffect(() => {
    if (!id) return

    axios(CONSTANT.fecthData(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        const lineasProductos = data?.pedido?.lineas_pedido?.map(({ producto }) => ({ producto: producto?.nombre ?? 'Nombre no encontrado', id: producto?.sku ?? '*NULL*' }))
        const selectedDaño = selectFilter.daños.find(({ id }) => id === data.daño_id)
        const selectedEstado = selectFilter.estados.find(({ id }) => id === data.estado_id)
        const selectedPosicion = selectFilter.posiciones.find(({ id }) => id === data.posicion_id)
        const selectedRespuesta = selectFilter.respuestas.find(({ id }) => id === data.respuesta_id)
        const selectedResultado = selectFilter.resultados.find(({ id }) => id === data.resultado_id)

        setSelectFilter(selectFilter => ({
          ...selectFilter,
          lineasProductos,
        }))

        setForm({
          ...data,
          daño_id: `${selectedDaño?.id ?? '*NULL*'}-${selectedDaño?.daño ?? 'Sin selección'}`,
          estado_id: `${selectedEstado?.id ?? '*NULL*'}-${selectedEstado?.estado ?? 'Sin selección'}`,
          posicion_id: `${selectedPosicion?.id ?? '*NULL*'}-${selectedPosicion?.posicion ?? 'Sin selección'}`,
          respuesta_id: `${selectedRespuesta?.id ?? '*NULL*'}-${selectedRespuesta?.titulo ?? 'Sin selección'}`,
          resultado_id: `${selectedResultado?.id ?? '*NULL*'}-${selectedResultado?.resultado ?? 'Sin selección'}`,
          producto: `${data?.producto_nombre}-${data?.producto_sku}`
        })
      })
      .catch(err => console.error(err))

    return () => {
      setForm(CONSTANT.form)
    }
  }, [id, selectFilter.daños, selectFilter.estados, selectFilter.posiciones, selectFilter.respuestas, selectFilter.resultados])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    if (!form?.estado_id?.length ?? form?.estado_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, seleccione un estado', icon: 'info' }))
    if (!form?.posicion_id?.length ?? form?.posicion_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, seleccione un posición', icon: 'info' }))
    // if (!form?.resultado_id?.length ?? form?.resultado_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, seleccione un resultado', icon: 'info' }))
    // if (!form?.daño_id?.length ?? form?.daño_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, seleccione un daño', icon: 'info' }))
    // if (!form?.respuesta_id?.length ?? form?.respuesta_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'Por favor, seleccione un respuesta', icon: 'info' }))

    if (!form?.patente?.length) return dispatch(fireToaster({ title: 'Por favor, ingrese una patente', icon: 'info' }))
    if (!form?.numero_factura?.length) return dispatch(fireToaster({ title: 'Por favor, ingrese un número de factura', icon: 'info' }))
    // if (!form?.fecha_recepcion?.length) return dispatch(fireToaster({ title: 'Por favor, seleccione una fecha de recepción', icon: 'info' }))
    // if (!form?.fecha_envio?.length) return dispatch(fireToaster({ title: 'Por favor, seleccione una fecha de envío', icon: 'info' }))
    // if (!form?.vehiculo_marca?.length) return dispatch(fireToaster({ title: 'Por favor, ingrese una marca de vehículo', icon: 'info' }))
    // if (!form?.vehiculo_modelo?.length) return dispatch(fireToaster({ title: 'Por favor, ingrese un modelo de vehículo', icon: 'info' }))
    // if (!form?.vehiculo_año?.length) return dispatch(fireToaster({ title: 'Por favor, ingrese el año del vehículo', icon: 'info' }))
    // if (!form?.numero_chasis?.length) return dispatch(fireToaster({ title: 'Por favor, ingrese un número de chasis', icon: 'info' }))
    // if (!form?.observacion?.length) return dispatch(fireToaster({ title: 'Por favor, ingrese una observación', icon: 'info' }))

    let url = id => id ? CONSTANT.queryUrl + `/${id}` : CONSTANT.queryUrl

    let data = {
      estado_id: idExtractor(form?.estado_id),
      posicion_id: idExtractor(form?.posicion_id),
      daño_id: idExtractor(form?.daño_id),
      resultado_id: idExtractor(form?.resultado_id),
      respuesta_id: idExtractor(form?.respuesta_id),
      producto_sku: form?.producto?.split('-')?.[0],
      producto_nombre: form?.producto?.split('-')?.[1],

      patente: form?.patente,
      vehiculo_marca: form?.vehiculo_marca,
      vehiculo_modelo: form?.vehiculo_modelo,
      numero_factura: Number(form?.numero_factura), //int
      vehiculo_año: Number(form?.vehiculo_año), //int
      numero_chasis: form?.numero_chasis,
      observacion: form?.observacion,
      fecha_recepcion: form?.fecha_recepcion,
      fecha_envio: form?.fecha_envio,
    }

    setIsConsulting(true)
    axios(url(id), {
      method: id ? 'PUT' : 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: registro creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() =>
        setIsConsulting(false)
      )
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        <CustomForm size="" titleColor={'w-100 d-flex align-items-baseline'} title={<>{CONSTANT.componentTitle(id)} <span className="ms-auto sub-text-2 fw-normal"># Seguimiento: {form.numero_seguimiento}</span></>}>
          <div className="row">
            <FormInput
              labelText='* Patente'
              name='patente'
              value={form?.patente ?? ''}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              labelText='* Vehículo marca'
              name='vehiculo_marca'
              value={form?.vehiculo_marca ?? ''}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              labelText='* Vehículo modelo'
              name='vehiculo_modelo'
              value={form?.vehiculo_modelo ?? ''}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              type='number'
              labelText='* Vehículo año'
              name='vehiculo_año'
              value={form?.vehiculo_año ?? ''}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              type='number'
              labelText='* Número factura'
              name='numero_factura'
              value={form?.numero_factura ?? ''}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              labelText='* Número chasis'
              name='numero_chasis'
              value={form?.numero_chasis ?? ''}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              type='date'
              labelText='* Fecha de recepción'
              name='fecha_recepcion'
              value={form?.fecha_recepcion ?? ''}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              type='date'
              labelText='* Fecha de envío'
              name='fecha_envio'
              value={form?.fecha_envio ?? ''}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              labelText='* Observación'
              name='observacion'
              value={form?.observacion ?? ''}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <SelectFilter
              labelText='* Estado'
              name='estado_id'
              value={form?.estado_id?.split('-')?.[1] ?? 'Seleccione'}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
              optionObj={selectFilter?.estados?.map(({ id, estado, name }) => ({ id, name: estado ?? name }))}
            />

            <SelectFilter
              labelText='* Posición'
              name='posicion_id'
              value={form?.posicion_id?.split('-')?.[1] ?? 'Seleccione'}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
              optionObj={selectFilter?.posiciones?.map(({ id, posicion, name }) => ({ id, name: posicion ?? name }))}
            />

            <SelectFilter
              labelText='* Daño'
              name='daño_id'
              value={form?.daño_id?.split('-')?.[1] ?? 'Seleccione'}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
              optionObj={selectFilter?.daños?.map(({ id, daño, name }) => ({ id, name: daño ?? name }))}
            />

            <SelectFilter
              labelText='* Resultado'
              name='resultado_id'
              value={form?.resultado_id?.split('-')?.[1] ?? 'Seleccione'}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
              optionObj={selectFilter?.resultados?.map(({ id, resultado, name }) => ({ id, name: resultado ?? name }))}
            />

            <SelectFilter
              labelText='* Respuesta'
              name='respuesta_id'
              value={form?.respuesta_id?.split('-')?.[1] ?? 'Seleccione'}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
              optionObj={selectFilter?.respuestas?.map(({ id, titulo, name }) => ({ id, name: titulo ?? name }))}
            />

            <SelectFilter
              labelText='* Producto'
              name='producto'
              value={form?.producto?.split('-')?.[0] ?? 'Seleccione'}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-12'
              optionObj={selectFilter?.lineasProductos?.map(({ id, producto, name }) => ({ id, name: producto ?? name }))}
            />
          </div>

          <Button
            type="submit"
            text={id ? 'Actualizar' : "Crear"}
            color="primary"
            disabled={isConsulting}
            icon={id ? 'Check' : "Plus"}
            onClick={(e) => handleFetch(e)}
          />
        </CustomForm>
      </div>
    </div>
  );
};

export default Form;

import { useState } from "react"
import { Eye, EyeOff } from "react-feather"


const FormPasswordInput = ({ id = null, placeholder = "", classes, labelText = null, handleValue, value, name, ...props }) => {
  const [displayPassword, setDisplayPassword] = useState(false)


  const handleDisplayPassword = () => {
    (displayPassword) ? setDisplayPassword(false) : setDisplayPassword(true)
  }


  return (
    <div className={"form-outline position-relative mb-2 " + classes}>
      <input
        type={displayPassword ? "text" : "password"}
        id={id}
        className="form-control form-control-lg"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleValue}
      />

      {labelText &&
        <label className="form-label" htmlFor={id}>{labelText}</label>
      }

      <button
        type="button"
        className="btn position-absolute end-0 top-0"
        onClick={() => handleDisplayPassword()}
        {...props}
      >
        {displayPassword
          ? <EyeOff />
          : <Eye />
        }
      </button>
    </div>
  )
}

export default FormPasswordInput
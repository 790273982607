
// Redux
import { useDispatch, useSelector } from 'react-redux'
import {
  clearFilterOrdenesCompra,
  creacionDesdeFilter,
  creacionHastaFilter,
  descProductoFilter,
  estatusFilter,
  limiteFilter,
  nvFilter,
  ocFilter,
  proveedoresFilter,
  resultadosOrdenesCompra,
  skuFilter,
  vendedoresFilter
} from '../../redux/actions/ordenesCompra'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Components
import FormInput from '../FormInput'
import SelectFilter from '../SelectFilter'
import FiltroAplicado from './FiltroAplicado'


// Helpers
import { lastPosition } from '../../helpers/lastPosition'
import { FILTER_ADD_NOTA_VENTA, FILTER_ADD_OC, FILTER_ADD_PROVEEDOR, FILTER_ADD_SKU, FILTER_ADD_VENDEDOR, FILTER_DESC_PRODUCTO, FILTER_ESTATUS, FILTER_FECHA_FINAL, FILTER_FECHA_INICIO, FILTER_LIMITE } from '../../redux/redux-types'
import { useEffect, useState } from 'react'
import { getAll } from '../../endpoints/getAll'
import axios from 'axios'
import { fireToaster } from '../../redux/actions/toaster'
import { todayFormat } from '../../helpers/todayInputFormat'
import DownloadFile from '../Download/DownloadFile'


const CONSTANT = {
  queryUrlGetAll: getAll.ordenes_compras + `/listar`,
  queryUrlExportar: getAll.ordenes_compras + `/exportar`,
  redirectUrl: '/erp/ordenes-compra',
  title: 'Búsqueda de ordenes de compra',

  reduxClearFilters: clearFilterOrdenesCompra,
  reduxSetResults: resultadosOrdenesCompra,

  fetchProveedores: getAll.erp_proveedores + '/obtener',
  fetchVendedores: getAll.erp_vendedores + '/obtener',

  selectFilterInitialState: {
    vendedores: [],
    estatus: [{ id: 'A', name: 'Abierta' }, { id: 'C', name: 'Cerrada' }],
    proveedores: []
  }
}

// TODO: arreglar los filtros, faltan los componentes inputs y confirmar la respuesta y la config

export const dataFormatter = (obj) => {
  let data = {}
  Object.entries(obj).forEach(([k, v]) => {
    if (['arr_nv', 'arr_oc', 'arr_sku'].includes(k) && v.length) {
      return data = {
        ...data,
        [k]: [v]
      }
    }

    if (['estatus_oc'].includes(k) && v.length) {
      return data = {
        ...data,
        [k]: v.split('-')[0]
      }
    }

    if (['arr_vendedores', 'arr_proveedores'].includes(k) && v.length) {
      return data = {
        ...data,
        [k]: v.map(str => str?.split('%-%')[0])
      }
    }

    else {
      return data = {
        ...data,
        [k]: v
      }
    }
  })
  return data
}


const fetchSelectFiltersData = async () => {
  const proveedores = await axios(CONSTANT.fetchProveedores, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
  })
    .then(({ data }) => data?.data)
    .catch(err => {
      console.error(err)
      return [{ rut_proveedor: 'Error', nombre_proveedor: 'Error' }]
    })

  const vendedores = await axios(CONSTANT.fetchVendedores, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
  })
    .then(({ data }) => data?.data ?? [])
    .catch(err => {
      console.error(err)
      return [{ codigo_vendedor: 'Error', vendedor: 'Error' }]
    })

  return {
    vendedores,
    proveedores
  }
}


const OrdenesCompraFiltros = () => {
  const { filtros } = useSelector(state => state.ordenesCompra)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const dispatch = useDispatch()
  const {
    arr_proveedores,
    arr_oc,
    arr_nv,
    arr_sku,
    arr_vendedores,
    limite,
    desc_prod,
    oc_creada_desde,
    oc_creada_hasta,
    estatus_oc,
  } = filtros

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE CONSULTA LA DATA POR PRIMERA VEZ
  useEffect(() => {
    axios(CONSTANT.queryUrlGetAll, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
      .catch(err => console.error(err))
  }, [dispatch])


  // EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
  useEffect(() => {
    return () => {
      dispatch(CONSTANT.reduxClearFilters())
    }
  }, [dispatch])


  // EFECTO QUE SETEA LOS VALORES DE LOS SELECTFILTERS Y FECHA DESDE
  useEffect(() => {
    fetchSelectFiltersData().then(data => setSelectFilter((selectFilter) => ({ ...selectFilter, ...data })))
    dispatch(creacionDesdeFilter(todayFormat()))
  }, [dispatch])


  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_ADD_NOTA_VENTA:
        dispatch(nvFilter(value))
        break;

      case FILTER_ADD_SKU:
        dispatch(skuFilter(value))
        break;

      case FILTER_ADD_PROVEEDOR:
        dispatch(proveedoresFilter(value))
        break;

      case FILTER_ADD_OC:
        dispatch(ocFilter(value))
        break;

      case FILTER_ADD_VENDEDOR:
        dispatch(vendedoresFilter(value))
        break;

      case FILTER_ESTATUS:
        dispatch(estatusFilter(value))
        break;

      case FILTER_DESC_PRODUCTO:
        dispatch(descProductoFilter(value))
        break;

      case FILTER_LIMITE:
        dispatch(limiteFilter(value))
        break;

      case FILTER_FECHA_INICIO:
        dispatch(creacionDesdeFilter(value))
        break;

      case FILTER_FECHA_FINAL:
        dispatch(creacionHastaFilter(value))
        break;

      default:
        break;
    }
  }


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = (reset = false) => {
    const url = CONSTANT.queryUrlGetAll
    let data = {}
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST', data
    }

    if (limite <= 0) return dispatch(fireToaster({ title: 'No se puede realizar la acción', text: 'El límite es obligatorio', icon: 'info' }))

    setIsConsulting(true)

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      dispatch(CONSTANT.reduxSetResults([]))

      return axios(url, config)
        .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data ?? []))
        )
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          setIsConsulting(false)
        })
    }

    config = { ...config, data: dataFormatter(filtros) }

    dispatch(fireToaster({ title: 'Realizando consulta', icon: 'info', text: 'Buscando...' }))

    axios(url, config)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data ?? []))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
      })
      .catch(err => {
        console.error(err)
        dispatch(fireToaster({ title: `Operación fallida: Error ${err?.response?.status}`, icon: 'error', text: 'No se pudo hacer la consulta' }))
        dispatch(CONSTANT.reduxSetResults([]))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={CONSTANT.title}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
        exportBtn={
          <DownloadFile
            url={CONSTANT.queryUrlExportar}
            data={{ data: dataFormatter(filtros) }}
          />
        }
      >
        <FormInput
          labelText='OC'
          placeholder='Orden de compra'
          size='col-12'
          sizeDesk='col-md-2'
          handleValue={(e) => handleAddFilter(e, FILTER_ADD_OC)}
          value={arr_oc}
        />

        <FormInput
          labelText='NV'
          placeholder='Nota de venta'
          size='col-12'
          sizeDesk='col-md-2'
          handleValue={(e) => handleAddFilter(e, FILTER_ADD_NOTA_VENTA)}
          value={arr_nv}
        />

        <FormInput
          labelText='SKU'
          placeholder='SKU'
          size='col-12'
          sizeDesk='col-md-2'
          handleValue={(e) => handleAddFilter(e, FILTER_ADD_SKU)}
          value={arr_sku}
        />

        <FormInput
          labelText='Descripción producto'
          placeholder='Búsqueda'
          size='col-12'
          sizeDesk='col-md-2'
          handleValue={(e) => handleAddFilter(e, FILTER_DESC_PRODUCTO)}
          value={desc_prod}
        />

        <FormInput
          type='date'
          labelText='Creación DESDE'
          size='col-12'
          sizeDesk='col-md-2'
          handleValue={(e) => handleAddFilter(e, FILTER_FECHA_INICIO)}
          value={oc_creada_desde}
        />

        <FormInput
          type='date'
          labelText='Creación HASTA'
          size='col-12'
          sizeDesk='col-md-2'
          handleValue={(e) => handleAddFilter(e, FILTER_FECHA_FINAL)}
          value={oc_creada_hasta}
        />

        <FormInput
          type='number'
          labelText='Limite resultados'
          placeholder='Limite resultados'
          size='col-12'
          sizeDesk='col-md-2'
          handleValue={(e) => handleAddFilter(e, FILTER_LIMITE)}
          value={limite}
        />

        <SelectFilter
          labelText='Estatus'
          placeholder='Seleccione'
          size='col-12'
          sizeDesk='col-md-2'
          handleValue={(e) => handleAddFilter(e, FILTER_ESTATUS)}
          optionObj={selectFilter.estatus.map(({ id, name }) => ({ id, name }))}
          value={estatus_oc?.split('-')?.[1] ?? 'Seleccione'}
        />

        <SelectFilter
          altSeparator
          labelText='Proveedores'
          placeholder='Seleccione'
          size='col-12'
          sizeDesk='col-md-2'
          handleValue={(e) => handleAddFilter(e, FILTER_ADD_PROVEEDOR)}
          optionObj={selectFilter.proveedores.map(({ rut_proveedor, nombre_proveedor }) => ({ id: rut_proveedor, name: nombre_proveedor }))}
          value={lastPosition(arr_proveedores, 'Seleccione', '%-%')}
        />

        <SelectFilter
          altSeparator
          labelText='Vendedores'
          placeholder='Seleccione'
          size='col-12'
          sizeDesk='col-md-2'
          handleValue={(e) => handleAddFilter(e, FILTER_ADD_VENDEDOR)}
          optionObj={selectFilter.vendedores.map(({ codigo_vendedor, vendedor }) => ({ id: codigo_vendedor, name: vendedor }))}
          value={lastPosition(arr_vendedores, 'Seleccione', '%-%')}
        />

        {/* FILTROS APLICADOS - PRODUCTOS */}
        <p className='mb-25 text-black'>Filtros aplicados</p>
        <div className='row'>
          <FiltroAplicado title='Proveedores' array={arr_proveedores} func={proveedoresFilter} separator='%-%' />
          <FiltroAplicado title='Vendedores' array={arr_vendedores} func={vendedoresFilter} separator='%-%' />
        </div>
        <hr className='my-1' />
      </ListadoHeader>
    </div>
  )
}

export default OrdenesCompraFiltros
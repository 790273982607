import { useEffect, useState } from "react";
import axios from "axios";
import * as fas from '@fortawesome/free-solid-svg-icons';
import * as far from '@fortawesome/free-regular-svg-icons';
import * as fab from '@fortawesome/free-brands-svg-icons';
import * as MDI from '@mdi/js';


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import SelectFilter from "../../components/SelectFilter";
import CustomLoader from "../../components/CustomLoader";
import IconPicker from "../../components/IconPicker";
import TextareaInput from "../../components/TextareaInput";


// React-router-dom
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { getOne } from "../../endpoints/getOne";
import { getAll } from "../../endpoints/getAll";


// Helpers 
import { idExtractor } from "../../helpers/idExtractor";
import { iconSanatizerFaMdi } from "../../helpers/iconSanatizerFaMdi";
import { contractFormat } from "./misc/contractFormat";
import { useCustomFetch } from "../../hooks/useCustomFetch";


const iconsFA = { fas, far, fab }


const CONSTANT = {
  redirectUrl: '/adicionales',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitle: 'Editar adicional',
  title: 'Adicionales',
  queryUrl: getOne.adicionales,
  fetchCategorias: getAll.categorias + `/buscar?limit=99999`,
  initialFormState: {
    categoria_id: '',
    nombre: '',
    descripcion: '',
    estatus: 1
  },
  selectFilterInitialState: {
    categorias: [],
  }
}


const handleSelectFiltersData = async () => {
  const categorias = await axios(CONSTANT.fetchCategorias,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => [...data?.categorias?.data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { categorias }
}


const getData = async (id, fn) => {
  const { data, statusText } = await fn(CONSTANT.queryUrl(id), contractFormat('detail'))
  return { data, statusText }
}


const Update = () => {
  const navigate = useNavigate()
  const [fetchData, setFetchData] = useState(CONSTANT.initialFormState)
  const [icono, setIcono] = useState(null)
  const { id } = useParams()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [customFetch] = useCustomFetch()

  const [isConsulting, setIsConsulting] = useState(false)


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setFetchData({
      ...fetchData,
      [name]: value
    })
  }


  // EFECTO QUE RELLENA LOS SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  useEffect(() => {
    let fetchIcon = null

    getData(id, customFetch).then(({ data: res, statusText }) => {
      if (res?.adicional?.id) {
        const data = res?.adicional

        fetchIcon = data?.neu_icono?.libreria === 'fa'
          ? {
            prefix: data?.neu_icono?.fuente,
            path: data?.neu_icono?.icono,
            iconName: iconsFA[data?.neu_icono?.fuente][data?.neu_icono?.icono].iconName,
            icon: iconsFA[data?.neu_icono?.fuente][data?.neu_icono?.icono]
          }
          : data?.neu_icono?.libreria === 'mdi'
            ? {
              icon: {
                prefix: 'mdi',
                iconName: MDI[data?.neu_icono?.icono],
                path: MDI[data?.neu_icono?.icono].path
              },
              nativeIcon: MDI[data?.neu_icono?.icono]
            }
            : null

        setFetchData({
          ...data,
          categoria_id: `${data?.categoria?.id ?? '*NULL*'}-${data?.categoria?.nombre ?? 'Error'}`
        })

        setIcono(fetchIcon)
      }
      else console.error(res)
    })


    return () => {
      setFetchData(null)
    }
  }, [id])


  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)

    const { nombre, categoria_id, estatus, descripcion } = fetchData
    const sanitazedIcon = iconSanatizerFaMdi(icono)

    let data = {
      nombre,
      descripcion,
      categoria_id: idExtractor(categoria_id),
      estatus: Number(estatus),

      // valores de IconPicker
      icono_libreria: sanitazedIcon?.libreria,
      icono_fuente: sanitazedIcon?.fuente ?? null,
      icono_icono: sanitazedIcon?.icono,
    }

    const { data: res } = await customFetch(CONSTANT.queryUrl(id), contractFormat('update', data))
    if (res?.id) navigate(CONSTANT.redirectUrl)
    setIsConsulting(false)
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {fetchData?.id && selectFilter.categorias?.length

          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                labelText='Estatus'
                name='estatus'
                value={fetchData.estatus}
                handleValue={handleInputChange}
                size='col-12'
              />

              <IconPicker
                value={icono}
                handleValue={setIcono}
                size='col-12'
              />

              <FormInput
                labelText='* Nombre'
                name='nombre'
                value={fetchData.nombre ?? ''}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <SelectFilter
                labelText='* Categoría'
                name='categoria_id'
                value={fetchData?.categoria_id?.split('-')[1] ?? ''}
                handleValue={handleInputChange}
                optionObj={selectFilter.categorias.map(({ id, nombre }) => ({ id, name: nombre }))}
                size='col-12'
              />

              <TextareaInput
                labelText='Descripción'
                name='descripcion'
                value={fetchData.descripcion ?? ''}
                handleValue={handleInputChange}
                rows={2}
                size='col-12'
              />
            </div>

            <Button disabled={isConsulting} type="submit" text="Actualizar" color="primary" icon="Circle" onClick={(e) => handleFetch(e)} />
          </CustomForm>

          : <CustomLoader />
        }
      </div>
    </div>

  );
};

export default Update;

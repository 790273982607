import PropTypes from 'prop-types';
import Icon from './Icon';



const FormInputNumber = ({
   placeholder, labelText,
   value, name, handleValue,
   size, sizeDesk, sizeTablet,
   classes, margin, withButton,
   isDisabled }) => {
   return (
      <div className={`input-inset ${classes ?? ''} ${size} ${sizeTablet} ${sizeDesk}`}>
         <div className={`${withButton ? 'd-flex' : ''} form-outline ${margin ?? 'mb-2'}`}>
            {
               labelText &&
               <label className="form-label">{labelText}</label>
            }

            <input
               type="number"
               className="form-control form-control-lg"
               placeholder={placeholder}
               value={value}
               onChange={(e) => handleValue(e)}
               name={name}
               disabled={isDisabled ?? false}
            />
            {
               withButton &&
               <button className='input-inset-button form-control ms-50'><Icon icon={'Search'} /></button>
            }
         </div>
      </div>
   )
}



FormInputNumber.propTypes = {
   placeholder: PropTypes.string,
   handleValue: PropTypes.func.isRequired,
   size: PropTypes.string,
   sizeDesk: PropTypes.string,
   sizeTablet: PropTypes.string,
   name: PropTypes.string,
}

FormInputNumber.defaultProps = {
   size: "",
   sizeDesk: "",
   sizeTablet: "",
   value: "",
   name: "",
   handleValue: () => { }
}



export default FormInputNumber;
import {
   FILTER_AUTOGESTION_INPUT,
   FILTER_CLEAR,
   RESULTADOS_AUTOGESTION,
} from "../redux-types";

export const autogestionInputFilter = ({ key, value }) => ({ type: FILTER_AUTOGESTION_INPUT, payload: { key, value } })

export const resultadosAutogestion = (data) => ({ type: RESULTADOS_AUTOGESTION, payload: data })

export const clearFilterAutogestion = () => ({ type: FILTER_CLEAR });

import {
   FILTER_CLEAR,
   FILTER_FECHA_INICIO,
   FILTER_FECHA_FINAL,
   RESULTADOS_NOTAS_VENTAS,
   FILTER_NV,
   FILTER_NP,
   FILTER_FACTURA,
   FILTER_RUT_CLIENTE,
   FILTER_TIPO_DESTINO,
   FILTER_PUNTO_ENTREGA,
   FILTER_DXC,
   FILTER_CODIGO_VENDEDOR,
   FILTER_ESTATUS,
   FILTER_COMUNA_ENTREGA,
   FILTER_PLANIFICACION_INICIO,
   FILTER_PLANIFICACION_FINAL,
   FILTER_CANTIDAD_RESULTADOS,
   FILTER_ADD_METODO_ENTREGA,
   FILTER_REMOVE_METODO_ENTREGA,
   RESULTADOS_PLANIFICACION,
   FILTROS_PERSISTENTES_NOTAS_VENTAS
} from "../redux-types";


const initialState = {
   filtros: {
      nv: '',
      planificacion_desde: '',
      planificacion_hasta: '',
      limite: '',
      np: '',
      factura: '',
      rut_cliente: '',
      destino: '',
      codigo_vendedor: '',
      estatus: '',
      punto_entrega: '',
      metodos_entregas: [],
      comuna_entrega_cod: '',
      fecha_desde: '',
      fecha_hasta: '',
      dxc: '',
   },
   resultados: null,
   planificacion: null
}


export const notasVentasReducer = (state = initialState, action) => {
   let isListed

   switch (action.type) {
      case FILTER_ADD_METODO_ENTREGA:
         isListed = state.filtros.metodos_entregas.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               metodos_entregas: isListed ? state.filtros.metodos_entregas : [...state.filtros.metodos_entregas, action.payload]
            }
         };

      case FILTER_REMOVE_METODO_ENTREGA:
         return {
            ...state, filtros: {
               ...state.filtros,
               metodos_entregas: state.filtros.metodos_entregas.filter(el => el !== action.payload)
            }
         };

      case FILTER_NV:
         return {
            ...state, filtros: {
               ...state.filtros,
               nv: action.payload
            }
         };

      case FILTER_NP:
         return {
            ...state, filtros: {
               ...state.filtros,
               np: action.payload
            }
         };

      case FILTER_FACTURA:
         return {
            ...state, filtros: {
               ...state.filtros,
               factura: action.payload
            }
         };

      case FILTER_RUT_CLIENTE:
         return {
            ...state, filtros: {
               ...state.filtros,
               rut_cliente: action.payload
            }
         };

      case FILTER_TIPO_DESTINO:
         return {
            ...state, filtros: {
               ...state.filtros,
               destino: action.payload
            }
         };

      case FILTER_CODIGO_VENDEDOR:
         return {
            ...state, filtros: {
               ...state.filtros,
               codigo_vendedor: action.payload
            }
         };

      case FILTER_ESTATUS:
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus: action.payload
            }
         };

      case FILTER_PUNTO_ENTREGA:
         return {
            ...state, filtros: {
               ...state.filtros,
               punto_entrega: action.payload
            }
         };

      case FILTER_COMUNA_ENTREGA:
         return {
            ...state, filtros: {
               ...state.filtros,
               comuna_entrega_cod: action.payload
            }
         };

      case FILTER_DXC:
         return {
            ...state, filtros: {
               ...state.filtros,
               dxc: action.payload
            }
         };

      case FILTER_FECHA_INICIO:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_desde: action.payload
            }
         };

      case FILTER_FECHA_FINAL:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_hasta: action.payload
            }
         };

      case FILTER_PLANIFICACION_INICIO:
         return {
            ...state, filtros: {
               ...state.filtros,
               planificacion_desde: action.payload
            }
         };

      case FILTER_PLANIFICACION_FINAL:
         return {
            ...state, filtros: {
               ...state.filtros,
               planificacion_hasta: action.payload
            }
         };

      case FILTER_CANTIDAD_RESULTADOS:
         return {
            ...state, filtros: {
               ...state.filtros,
               limite: action.payload
            }
         };

      case RESULTADOS_NOTAS_VENTAS:
         return {
            ...state, resultados: action.payload
         }

      case FILTROS_PERSISTENTES_NOTAS_VENTAS:
         return {
            ...state, filtros: action.payload
         }

      case RESULTADOS_PLANIFICACION:
         return {
            ...state, planificacion: action.payload
         }

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
import { Route, Routes } from "react-router-dom";

import RoleHasPermissions from "../views/RoleHasPermissions/RoleHasPermissions";

const RoleHasPermissionsRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<RoleHasPermissions />} />
    </Routes>
  );
};

export default RoleHasPermissionsRouter;

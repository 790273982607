
// Components
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import ClavesFiltros from "../../components/AccordionFilter/Claves";
import ClavesTable from "../../components/Tables/ClavesTable";


const CONSTANT = {
   title: 'Claves',
   path: 'claves',
   create_btn: 'Crear clave'
}

const Listado = () => {
   return (
      <div className="row">
         <h3 className="mb-1 d-flex justify-content-between">
            <p><b>{CONSTANT.title}</b> - Listado</p>
            <DropdownWrapper text="Acciones" classes="ms-auto">
               <DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
            </DropdownWrapper>
         </h3>

         <ClavesFiltros />

         <ClavesTable />
      </div>
   );
}

export default Listado;
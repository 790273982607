import PropTypes from 'prop-types'

/**  
* Para que funcione correctamente el bloqueo a componente; el padre debe tener position: relative.
*/

const CustomLoader = ({ blocking }) => {
   return (
      <div className={'rounded-3 blocked-modal ' + (blocking)} style={{zIndex: '1000'}}>
         <section className='page-spinner-container'>
         <div className="page-spinner"></div>
      </section>
      </div>
   )
}

CustomLoader.propTypes = {
   blocking: PropTypes.oneOf(['partial', 'full', '']),
}

CustomLoader.defaultProps = {
   blocking: '',
}

export default CustomLoader;
import axios from 'axios'
import React, { useEffect, useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { busquedaFilter, clearFilterCategorias, creacionDesdeFilter, creacionHastaFilter, estatusFilter, nombreFilter, resultadosCategorias } from '../../redux/actions/categorias'
import { FILTER_ADD_ESTATUS, FILTER_ADD_NOMBRE, FILTER_BUSQUEDA, FILTER_CREACION_DESDE, FILTER_CREACION_HASTA } from '../../redux/redux-types'
import { fireToaster } from '../../redux/actions/toaster'


// Components
import FormInput from '../FormInput'
// import SelectFilter from '../SelectFilter'
import Select from '../Select'
import FiltroAplicado from './FiltroAplicado'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Hooks


// Helpers
// import { lastPosition } from '../../helpers/lastPosition'
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'


const mockEstatus = [
	{ name: 'Activo' },
	{ name: 'Inactivo' },
]

const CategoriasFiltros = () => {
	const { filtros } = useSelector(state => state.categorias)
	const { showing_results } = useSelector(state => state.listedResults)
	const dispatch = useDispatch()
	const { busqueda, estatus, nombre, creacion_desde, creacion_hasta } = filtros

	const [isConsulting, setIsConsulting] = useState(false)


	useEffect(() => {
		axios(getAll.categorias + `/buscar?limit=${showing_results}`, {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			}, method: 'POST'
		})
			.then(res => dispatch(resultadosCategorias(res.data.categorias)))
			.catch(err => console.error(err))
	}, [showing_results, dispatch])

	useEffect(() => {
		return () => dispatch(clearFilterCategorias())
	}, [dispatch])


	const handleFetchFilter = (reset = false) => {
		let data = {
			busqueda: null,
		}

		if (reset) {
			dispatch(clearFilterCategorias())
		}

		else {
			Object.entries(filtros).forEach(([key, value]) => {
				if (key === 'busqueda') {
					data = {
						...data,
						busqueda: value
					}
					return
				}

				if (key === 'creacion_desde' && value.length > 0) {
					data = {
						...data,
						filtros: {
							...data.filtros,
							created_at_desde: value
						}
					}
					return
				}

				if (key === 'creacion_hasta' && value.length > 0) {
					data = {
						...data,
						filtros: {
							...data.filtros,
							created_at_hasta: value
						}
					}
					return
				}

				if (value.length > 0 && key === 'estatus') {
					data = {
						...data,
						filtros: {
							...data.filtros,
							[key]: value.map(value => value === 'Activo' ? 1 : 0)
						}
					}
					return
				}

				if (value.length > 0) {
					data = {
						...data,
						filtros: {
							...data.filtros,
							[key]: value
						}
					}
				}
			})
		}

		setIsConsulting(true)

		axios(getAll.categorias + `/buscar?limit=${showing_results}`, {
			method: 'POST',
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
				'Content-Type': 'application/json',
			},
			data
		})
			.then(res => {
				dispatch(resultadosCategorias(res.data.categorias))
				dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
			})
			.catch(err => {
				console.error(err);
				const { errores } = err.response.data
				let detalles = []
				Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

				const toasterContent = {
					title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
					html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
					icon: 'error'
				}
				dispatch(fireToaster(toasterContent))
			})
			.finally(() => {
				setIsConsulting(false)
			})
	}

	// MANEJADORES DE FILTRO - ADICIONALES
	const handleAddFilter = (e, filter) => {
		const { value } = e.target

		switch (filter) {
			case FILTER_BUSQUEDA:
				dispatch(busquedaFilter(value))
				break;

			case FILTER_ADD_ESTATUS:
				dispatch(estatusFilter(value))
				break;

			case FILTER_ADD_NOMBRE:
				dispatch(nombreFilter(value.split('-')[1]))
				break;

			case FILTER_CREACION_DESDE:
				dispatch(creacionDesdeFilter(value))
				break;

			case FILTER_CREACION_HASTA:
				dispatch(creacionHastaFilter(value))
				break;

			default:
				break;
		}
	}


	return (
		<div className="card mb-50 shadow-none bg-transparent">
			<ListadoHeader
				classes='border rounded-2'
				title={`Búsqueda de categorías (${filtrosAplicadosTotal(filtros)})`}
				handleDisable={isConsulting}
				handleClickSearch={() => handleFetchFilter()}
				handleClickClearFilter={() => handleFetchFilter(true)}
			>
				<>
					<FormInput
						labelText='Búsqueda'
						placeholder='Ingrese su búsqueda'
						size='col-12'
						sizeDesk='col-md-4'
						handleValue={(e) => handleAddFilter(e, FILTER_BUSQUEDA)}
						value={busqueda}
					/>

					<Select
						labelText='Estatus'
						placeholder='Selecciona'
						size='col-12'
						sizeDesk='col-md-2'
						options={mockEstatus}
						handleValue={(e) => handleAddFilter(e, FILTER_ADD_ESTATUS)}
					/>

					{/* <SelectFilter
                  labelText='Nombres'
                  placeholder='Selecciona'
                  size='col-12'
                  sizeDesk='col-md-2'
                  optionObj={nombres?.map(({ nombre, id }) => ({ id, name: nombre }))}
                  handleValue={(e) => handleAddFilter(e, FILTER_ADD_NOMBRE)}
                  value={lastPosition(nombre)}
               /> */}

					<FormInput
						type='date'
						labelText='Creación (desde)'
						size='col-12'
						sizeDesk='col-md-2'
						handleValue={(e) => handleAddFilter(e, FILTER_CREACION_DESDE)}
						value={creacion_desde}
					/>

					<FormInput
						type='date'
						labelText='Creación (hasta)'
						size='col-12'
						sizeDesk='col-md-2'
						handleValue={(e) => handleAddFilter(e, FILTER_CREACION_HASTA)}
						value={creacion_hasta}
					/>
				</>
				{/* } */}
				{/* FILTROS APLICADOS - ADICIONALES */}
				<p className='mb-25 text-black'>Filtros aplicados</p>
				<div className='row'>
					<FiltroAplicado array={estatus} func={estatusFilter} title={'Estatus'} />
					<FiltroAplicado array={nombre} func={nombreFilter} title={'Nombres'} />
				</div>

				<hr className='my-1' />
			</ListadoHeader>
		</div>
	)
}

export default CategoriasFiltros
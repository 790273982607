import { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from '../../Modal';
import { getAll } from '../../../endpoints/getAll';
import SelectFilter from '../../SelectFilter';
import FormInput from '../../FormInput';
import Button from '../../Button';
import { useDispatch } from 'react-redux';
import { fireToaster } from '../../../redux/actions/toaster';
import { idExtractor } from '../../../helpers/idExtractor';
import { BASE_URL, PATH_URL } from '../../../endpoints';
import { useParams } from 'react-router-dom';
import TextareaInput from '../../TextareaInput';


const CONSTANT = {
  title: 'Garantía',
  componentTitle: 'Procesar garantía',
  redirectUrl: '/garantias',
  queryUrl: (id) => `${BASE_URL}${PATH_URL}/garantias/procesar/${id}`,
  fetchDaños: getAll.garantia_daños,
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  initialFormState: {
    daño_id: '',
    fecha_recepcion: '',
    fecha_envio: '',
    observacion: '',
    vehiculo_marca: '',
    vehiculo_modelo: '',
    vehiculo_año: '',
    numero_chasis: '',
  },
  selectFilterInitialState: {
    daños: [],
  }
}


const handleSelectFiltersData = async () => {
  const daños = await axios(CONSTANT.fetchDaños,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { daños }
}


const ModalProcesar = ({isOpen, handleClose, handleGet}) => {
  const dispatch = useDispatch()

  const { id: garantiaId } = useParams()

  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [form, setForm] = useState(CONSTANT.initialFormState);


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // MANEJADOR DE LOS INPUTS
  const handleInputChange = (e) => {
    const { value, name } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  const handleOnlyNumbersInput = (e) => (/^[0-9\b]+$/.test(e.target.value) || e.target.value === '') && handleInputChange(e);


  const handleFetch = (e) => {
    e.preventDefault()

    
    if (!form.daño_id || form.daño_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'El daño es necesario', icon: 'info' }))
    if (!form.fecha_recepcion) return dispatch(fireToaster({ title: 'La fecha de recepción es obligatoria', icon: 'info' }))
    if (!form.fecha_envio) return dispatch(fireToaster({ title: 'La fecha de envío es obligatoria', icon: 'info' }))
    if (!form.vehiculo_marca) return dispatch(fireToaster({ title: 'La marca del vehículo es obligatoria', icon: 'info' }))
    if (!form.vehiculo_modelo) return dispatch(fireToaster({ title: 'El modelo del vehículo es obligatorio', icon: 'info' }))
    if (!form.vehiculo_año) return dispatch(fireToaster({ title: 'El año del vehículo es obligatorio', icon: 'info' }))
    if (!form.numero_chasis) return dispatch(fireToaster({ title: 'El número de chasis del vehículo es obligatorio', icon: 'info' }))

    let data = {
      daño_id: idExtractor(form.daño_id),
      fecha_recepcion: form.fecha_recepcion,
      fecha_envio: form.fecha_envio,
      observacion: form.observacion,
      vehiculo_marca: form.vehiculo_marca,
      vehiculo_modelo: form.vehiculo_modelo,
      vehiculo_año: form.vehiculo_año,
      numero_chasis: form.numero_chasis,
    }

    axios(CONSTANT.queryUrl(garantiaId), {
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'application/json'
      },
      data
    })
      .then(res => {

        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: #${garantiaId} actualizada con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        handleGet(garantiaId)
        handleClose()
      })
      .catch(err => {
        console.log(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }


  return (
    <Modal display={isOpen} handleValue={handleClose}>
      <h3 className='text-center'>Formulario procesamiento de garantía</h3>

      <form className='row' onSubmit={handleFetch}>
        <SelectFilter
          labelText="* Daño"
          name="daño_id"
          value={form?.daño_id?.split('-')[1] ?? 'Seleccione'}
          handleValue={handleInputChange}
          optionObj={selectFilter.daños?.map(({ daño, nombre, id }) => ({ name: daño ?? nombre , id }))}
          size="col-12"
          sizeDesk='col-12'
        />

        <FormInput
          labelText="* Fecha de recepción de neumático"
          name='fecha_recepcion'
          type='date'
          handleValue={handleInputChange}
          value={form?.fecha_recepcion}
          size="col-12"
          sizeDesk="col-md-6"
        />

        <FormInput
          labelText="* Fecha de envío de neumático"
          name='fecha_envio'
          type='date'
          handleValue={handleInputChange}
          value={form?.fecha_envio}
          size="col-12"
          sizeDesk="col-md-6"
        />
        
        <FormInput
          labelText="* Marca del vehículo"
          name='vehiculo_marca'
          type='text'
          handleValue={handleInputChange}
          value={form?.vehiculo_marca}
          size="col-12"
          sizeDesk="col-md-6"
        />

        <FormInput
          labelText="* Modelo del vehículo"
          name='vehiculo_modelo'
          type='text'
          handleValue={handleInputChange}
          value={form?.vehiculo_modelo}
          size="col-12"
          sizeDesk="col-md-6"
        />

        <FormInput
          labelText="* Año del vehículo"
          name='vehiculo_año'
          type='text'
          handleValue={handleOnlyNumbersInput}
          value={form?.vehiculo_año}
          size="col-12"
          sizeDesk="col-md-6"
        />

        <FormInput
          labelText="* Número de chasis"
          name='numero_chasis'
          type='text'
          handleValue={handleInputChange}
          value={form?.numero_chasis}
          size="col-12"
          sizeDesk="col-md-6"
        />

        <TextareaInput
					labelText='Observaciones'
					name='observacion'
					placeholder='Escriba sus observaciones...'
					value={form.observacion}
					handleValue={handleInputChange}
				/>

        <div className='d-flex justify-content-end'>
          <Button type='submit' text='Actualizar' />
        </div>
      </form>
    </Modal>
  );
}



export default ModalProcesar;
import { Navigate, Route, Routes } from "react-router-dom"
import { Listado } from "../views/Autogestion/Listado"
import { Detalle } from "../views/Autogestion/Detalle"

export const AutogestionRouter = () => {
   // const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         <Route path='/detalle/:id' element={<Detalle />} />

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}

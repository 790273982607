import React, { useEffect, useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { FILTER_ADD_ESTATUS, FILTER_BUSQUEDA, FILTER_CREACION_DESDE, FILTER_CREACION_HASTA, FILTER_ADD_COD_MAXIMISE, FILTER_ADD_PAIS, FILTER_ADD_REGION, FILTER_ADD_NOMBRE } from '../../redux/redux-types'
import { busquedaFilter, clearFilterComunas, codMaximiseFilter, creacionDesdeFilter, creacionHastaFilter, estatusFilter, nombreFilter, paisFilter, regionFilter, resultadosComunas } from '../../redux/actions/comunas'
import { fireToaster } from '../../redux/actions/toaster'


// Components
import FormInput from '../FormInput'
import SelectFilter from '../SelectFilter'
import Select from '../Select'
import FiltroAplicado from './FiltroAplicado'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { lastPosition } from '../../helpers/lastPosition'
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import axios from 'axios'
import { idExtractor } from '../../helpers/idExtractor'


const mockEstatus = [
  { name: 'Activo' },
  { name: 'Inactivo' },
]


const CONSTANT = {
  reduxStateSelector: 'comunas',
  queryUrlGetAll: getAll.comunas + '/buscar',
  redirectUrl: '/comunas',
  title: 'Búsqueda de comunas',
  reduxClearFilters: clearFilterComunas,
  reduxSetResults: resultadosComunas
}


const dataFormatter = (filtrosObj) => {
  let data = {}

  Object.entries(filtrosObj).forEach(([key, value]) => {
    if (key === 'busqueda') {
      data = {
        ...data,
        busqueda: value
      }
      return
    }

    if (key === 'creacion_desde' && value.length > 0) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          created_at_desde: value
        }
      }
      return
    }

    if (key === 'creacion_hasta' && value.length > 0) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          created_at_hasta: value
        }
      }
      return
    }

    if (key === 'pais_id' && value.length > 0) {
      data = {
        ...data,
        filtros_externos: {
          ...data.filtros_externos,
          pais_id: value.map(el => idExtractor(el))
        }
      }
      return
    }

    if (key === 'region' && value.length > 0) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          region_id: value.map(el => idExtractor(el))
        }
      }
      return
    }

    if (value.length > 0 && key === 'estatus') {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          [key]: value.map(value => value === 'Activo' ? 1 : 0)
        }
      }
      return
    }

    if (value.length > 0) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          [key]: value
        }
      }
    }
  })

  return data
}


const ComunasFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const [filtrosPosibles, setFiltrosPosibles] = useState(null)
  const [paises, setPaises] = useState(null)
  const [regiones, setRegiones] = useState(null)
  const dispatch = useDispatch()
  const { busqueda, estatus, nombre, region, creacion_desde, creacion_hasta, pais_id, cod_maxi } = filtros

  const [isConsulting, setIsConsulting] = useState(false)


  useEffect(() => {
    axios(getAll.regiones + '/buscar', {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
      .then(res => setRegiones(res.data.regiones))
      .catch(err => console.error(err))

    axios(getAll.paises + '/buscar', {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
      .then(res => setPaises(res.data.paises))
      .catch(err => console.error(err))

    axios(getAll.comunas + '/filtros', {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
      .then(res => setFiltrosPosibles(res.data.filtros_posibles[0]))
      .catch(err => console.error(err))

  }, [])


  useEffect(() => {
    axios(getAll.comunas + `/buscar?limit=${showing_results}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
      .then(({ data }) => dispatch(resultadosComunas(data.comunas)))
      .catch(err => console.error(err))
  }, [dispatch, showing_results])


  useEffect(() => {
    return () => dispatch(clearFilterComunas())
  }, [dispatch])


  const handleFetchFilter = (reset = false) => {
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    }
    let data = {
      busqueda
    }

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}`, config)
        .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data?.comunas)))
        .catch(err => {
          console.error(err)
          dispatch(CONSTANT.reduxSetResults([]))
        })
    }

    data = { ...data, ...dataFormatter(filtros) }
    setIsConsulting(true)

    axios(getAll.comunas + `/buscar?limit=${showing_results}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      data
    })
      .then(res => {
        dispatch(resultadosComunas(res.data.comunas))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO - ADICIONALES
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_BUSQUEDA:
        dispatch(busquedaFilter(value))
        break;

      case FILTER_ADD_NOMBRE:
        dispatch(nombreFilter(value.split('-')[1]))
        break;

      case FILTER_ADD_ESTATUS:
        dispatch(estatusFilter(value))
        break;

      case FILTER_ADD_REGION:
        dispatch(regionFilter(value))
        break;

      case FILTER_CREACION_DESDE:
        dispatch(creacionDesdeFilter(value))
        break;

      case FILTER_CREACION_HASTA:
        dispatch(creacionHastaFilter(value))
        break;

      case FILTER_ADD_PAIS:
        dispatch(paisFilter(value))
        break;

      case FILTER_ADD_COD_MAXIMISE:
        dispatch(codMaximiseFilter(value.split('-')[1]))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`Búsqueda de comunas (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        {filtrosPosibles &&
          <>
            <FormInput
              labelText='Búsqueda'
              placeholder='Ingrese su búsqueda'
              size='col-12'
              sizeDesk='col-md-4'
              handleValue={(e) => handleAddFilter(e, FILTER_BUSQUEDA)}
              value={busqueda}
            />

            <Select
              labelText='Estatus'
              placeholder='Selecciona'
              size='col-12'
              sizeDesk='col-md-2'
              options={mockEstatus}
              handleValue={(e) => handleAddFilter(e, FILTER_ADD_ESTATUS)}
            />

            <SelectFilter
              labelText='Nombres'
              placeholder='Selecciona'
              size='col-12'
              sizeDesk='col-md-2'
              optionObj={filtrosPosibles?.nombre.map((nombre, i) => ({ id: i, name: nombre }))}
              handleValue={(e) => handleAddFilter(e, FILTER_ADD_NOMBRE)}
              value={lastPosition(nombre)}
            />

            <SelectFilter
              labelText='Regiones'
              placeholder='Selecciona'
              size='col-12'
              sizeDesk='col-md-2'
              optionObj={regiones?.map(({ nombre, id }) => ({ id, name: nombre }))}
              handleValue={(e) => handleAddFilter(e, FILTER_ADD_REGION)}
              value={lastPosition(region)}
            />

            <SelectFilter
              labelText='País'
              placeholder='Selecciona'
              size='col-12'
              sizeDesk='col-md-2'
              optionObj={paises?.map(({ id, nombre }) => ({ id, name: nombre }))}
              handleValue={(e) => handleAddFilter(e, FILTER_ADD_PAIS)}
              value={lastPosition(pais_id)}
            />


            <SelectFilter
              labelText='Cod. maxisime'
              placeholder='Selecciona'
              size='col-12'
              sizeDesk='col-md-2'
              optionObj={filtrosPosibles?.cod_maxi.map((nombre, i) => ({ id: i, name: nombre }))}
              handleValue={(e) => handleAddFilter(e, FILTER_ADD_COD_MAXIMISE)}
              value={lastPosition(cod_maxi)}
            />

            <FormInput
              type='date'
              labelText='Creación (desde)'
              size='col-12'
              sizeDesk='col-md-2'
              handleValue={(e) => handleAddFilter(e, FILTER_CREACION_DESDE)}
              value={creacion_desde}
            />

            <FormInput
              type='date'
              labelText='Creación (hasta)'
              size='col-12'
              sizeDesk='col-md-2'
              handleValue={(e) => handleAddFilter(e, FILTER_CREACION_HASTA)}
              value={creacion_hasta}
            />
          </>
        }
        {/* FILTROS APLICADOS - ADICIONALES */}
        <p className='mb-25 text-black'>Filtros aplicados</p>
        <div className='row'>
          <FiltroAplicado array={estatus} func={estatusFilter} title={'Estatus'} />
          <FiltroAplicado array={nombre} func={nombreFilter} title={'Nombres'} />
          <FiltroAplicado array={region} func={regionFilter} title={'Regiones'} />
          <FiltroAplicado array={cod_maxi} func={codMaximiseFilter} title={'Cod. Maximise'} />
          <FiltroAplicado array={pais_id} func={paisFilter} title={'País(es)'} />
        </div>

        <hr className='my-1' />

      </ListadoHeader>
    </div>
  )
}

export default ComunasFiltros
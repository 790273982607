import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { toasterClear } from '../redux/actions/toaster'

const Toaster = () => {
   const state = useSelector(state => state)
   const dispatch = useDispatch()
   const { data, fire } = state.toaster

   useEffect(() => {
      if (fire) Swal.fire(data)
      return () => {
         dispatch(toasterClear())
      }
   }, [fire, data, dispatch])

   return <div />
}

export default Toaster
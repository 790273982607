import Button from "../../Button"
import CheckBoxInput from "../../CheckboxInput"
import FileUploaderB from "../../FileUploader_B"
import FormInput from "../../FormInput"
import TextareaInput from "../../TextareaInput"
import SelectFilter from "../../SelectFilter"
import { useWindowDimensions } from "../../../hooks/useDimensions"
import Table from "../../Table"
import { useState } from "react"


export const ModalTracking = ({ form, handleInputChange, id, selectFilter, handleFetch, trackingImg, setTrackingImg }) => {
	const { width } = useWindowDimensions()
	const [nuevoProducto, setNuevoProducto] = useState({ sku: '', value: '' })

	const handleAddProducto = (e) => {
		const { value } = e.target
		setNuevoProducto({ sku: value?.split('%-%')[0], value: value?.split('%-%')[1] })
	}

	return (
		<>
			<div className='m-2'>
				<h3 className='p-50 rounded-2 m-0'>Adjuntar tracking a NV #{id}</h3>
				<hr className='mt-0 mb-1' />

				<div className='row'>
					<div className='d-flex mb-1'>
						<CheckBoxInput
							labelText='Enviar Orden de compra'
							name='tracking%-%enviar_oc'
							value={form.enviar_oc ? true : false}
							handleValue={handleInputChange}
						/>
					</div>

					<SelectFilter
						altSeparator
						labelText='Bodega'
						name='tracking%-%bodega'
						value={form?.bodega?.split('%-%')?.[1] ?? 'Seleccione'}
						handleValue={handleInputChange}
						optionObj={selectFilter.bodegas.map(({ WARE_CODE, DESC_TEXT }) => ({ id: WARE_CODE ?? '*NULL*', name: `${WARE_CODE} | ${DESC_TEXT}` ?? 'ERROR' }))}
					/>

					<SelectFilter
						altSeparator
						labelText='RUT proveedor'
						name='tracking%-%rut_proveedor'
						optionObj={selectFilter.proveedores.map(({ rut_proveedor, nombre_proveedor }) => ({ id: rut_proveedor ?? '*NULL*', name: `${rut_proveedor} | ${nombre_proveedor}` ?? 'ERROR' }))}
						value={form.rut_proveedor.split('%-%')?.[1] ?? 'Sin selección'}
						handleValue={handleInputChange}
						size='col-12'
						sizeDesk='col-md-6'
					/>

					<SelectFilter
						altSeparator
						name='tracking%-%nuevo_producto'
						labelText='Agregar producto'
						optionObj={selectFilter.ocProductos.map(({ PART_CODE, DESC_TEXT }) => ({ id: PART_CODE ?? '*NULL*', name: `${PART_CODE} | ${DESC_TEXT}` ?? 'ERROR' }))}
						value={nuevoProducto.value ?? 'Seleccione'}
						handleValue={handleAddProducto}
						size='col-10'
						sizeDesk='col-md-11'
					/>

					<Button
						className={'w-fit h-fit my-auto'}
						icon={'Plus'}
						color={'success'}
						onClick={() => handleInputChange({
							target: {
								name: `tracking%-%extra_productos%-%${nuevoProducto.sku}%-%add`,
								value: null
							}
						})}
					/>
				</div>

				{width > 600
					? <Table pb={false} style={{ maxHeight: '900px' }}>
						<thead>
							<tr className='text-center'>
								<th colSpan={2} className='px-1'>Item linea</th>
								<th className='px-1'>Descripción / SKU</th>
								<th className='px-1'>QTY</th>
								<th className='px-1'>Costo</th>
							</tr>
						</thead>
						<tbody>
							{form?.items?.map(({ linea_item, descripcion, sku, qty, costo_neto, check }) => (
								<tr key={`${linea_item}-${descripcion}`}>
									<td className='pe-0'>
										<CheckBoxInput
											name={`tracking%-%producto%-%${linea_item}%-%check`}
											labelText=''
											value={check}
											handleValue={handleInputChange}
										/>
									</td>

									<td>{linea_item ?? 'N/A'}</td>

									<td>
										<div className='d-flex flex-column'>
											<span className='fw-bold'>{descripcion ?? 'Error'}</span>
											<span>SKU: <b>{sku ?? 'Error'}</b></span>
										</div>
									</td>

									<td className='col-2'>
										<FormInput
											type="number"
											labelText=""
											name={`tracking%-%producto%-%${linea_item}%-%qty`}
											size="col-12"
											margin='m-0'
											handleValue={handleInputChange}
											value={qty ?? 0}
										/>
									</td>

									<td className='col-3'>
										<FormInput
											type="number"
											labelText=""
											name={`tracking%-%producto%-%${linea_item}%-%costo_neto`}
											size="col-12"
											margin='m-0'
											handleValue={handleInputChange}
											value={costo_neto ?? 0}
										/>
									</td>
								</tr>
							))}

							{form.extra_productos.map((prod, i) => (
								<tr key={`extra_producto-${prod.sku}`}>
									<td colSpan={2}>
										<Button
											text={'Borrar'}
											icon={'Trash'}
											color={'danger'}
											onClick={() => handleInputChange({
												target: {
													name: `tracking%-%extra_productos%-%${nuevoProducto.sku}%-%delete`,
													value: null
												}
											})}
										/>
									</td>

									<td className='d-flex flex-column'>
										<span className='fw-bold'>{selectFilter.ocProductos?.find(({ PART_CODE }) => PART_CODE === prod.sku)?.DESC_TEXT ?? 'Error'}</span>
										<span>SKU: <b>{prod?.sku?.split('%-%')?.[0] ?? 'Error'}</b></span>
									</td>

									<td className='col-2'>
										<FormInput
											type="number"
											labelText=""
											name={`tracking%-%extra_productos%-%${prod.sku}%-%qty`}
											size="col-12"
											margin='m-0'
											handleValue={handleInputChange}
											value={prod.qty ?? 0}
										/>
									</td>

									<td className='col-3'>
										<FormInput
											type="number"
											labelText=""
											name={`tracking%-%extra_productos%-%${prod.sku}%-%costo_neto`}
											size="col-12"
											margin='m-0'
											handleValue={handleInputChange}
											value={prod.costo_neto ?? 0}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</Table>

					: <Table pb={false} style={{ maxHeight: '900px' }}>
						<thead>
							<tr className='text-center'>
								<th colSpan={2} className='px-1'>Items</th>
							</tr>
						</thead>
						<tbody className='border'>
							{form.items.map(({ linea_item, descripcion, sku, qty, costo_neto, check }) => (
								<tr key={`${linea_item}-${descripcion}`}>
									<td>
										<div className='d-flex gap-1'>
											<b>{linea_item ?? 'N/A'}</b>
											<CheckBoxInput
												name={`tracking-producto-${linea_item}-check`}
												labelText=''
												value={check}
												handleValue={handleInputChange}
											/>

											<span className='fw-bold'><b>{sku ?? 'Error'}</b> | {descripcion ?? 'Error'}</span>
										</div>

										<div className='row'>
											<FormInput
												type="number"
												labelText="Qty"
												name={`tracking-producto-${linea_item}-qty`}
												size="col-6"
												margin='m-0'
												handleValue={handleInputChange}
												value={qty ?? 0}
											/>

											<FormInput
												type="number"
												labelText="Costo"
												name={`tracking-producto-${linea_item}-costo_neto`}
												size="col-6"
												margin='m-0'
												handleValue={handleInputChange}
												value={costo_neto ?? 0}
											/>
										</div>
									</td>
								</tr>
							))}

							{form.extra_productos.map((prod, i) => (
								<tr key={`extra_producto-${prod.sku}`}>
									<td colSpan={2}>

										<div className='d-flex gap-1'>
											<Button
												text={'Borrar'}
												className='my-auto'
												icon={'Trash'}
												color={'danger'}
												onClick={() => handleInputChange({
													target: {
														name: `tracking%-%extra_productos%-%${nuevoProducto.sku}%-%delete`,
														value: null
													}
												})}
											/>

											<span className='fw-bold'>
												<b>{prod.sku ?? 'Error'}</b> | {selectFilter.ocProductos?.find(({ PART_CODE }) => PART_CODE === prod.sku)?.DESC_TEXT ?? 'Error'}
											</span>
										</div>

										<div className='row'>
											<FormInput
												type="number"
												labelText="Qty"
												name={`tracking%-%extra_productos%-%${prod.sku}%-%qty`}
												size="col-6"
												margin='m-0'
												handleValue={handleInputChange}
												value={prod.qty ?? 0}
											/>

											<FormInput
												type="number"
												labelText="Costo"
												name={`tracking%-%extra_productos%-%${prod.sku}%-%costo_neto`}
												size="col-6"
												margin='m-0'
												handleValue={handleInputChange}
												value={prod.costo_neto ?? 0}
											/>
										</div>
									</td>
								</tr>
							))}

						</tbody>
					</Table>
				}

				<TextareaInput
					labelText='Comentario'
					name='tracking%-%comentario'
					placeholder='Escriba un comentario...'
					value={form.comentario}
					handleValue={handleInputChange}
				/>

				<div className='mb-1'>
					<CheckBoxInput
						labelText='Enviar correo a cliente'
						name='tracking%-%enviar_correo_cliente'
						value={form.enviar_correo_cliente ? true : false}
						handleValue={handleInputChange}
					/>

					{form?.enviar_correo_cliente
						? <FormInput
							placeholder='Email...'
							labelText='* Email cliente'
							name='tracking%-%email_cliente'
							value={form?.email_cliente}
							handleValue={handleInputChange}
						/>
						: <FormInput
							isDisabled
							labelText='* Email cliente'
						/>

					}
				</div>

				<div className='col-12 mb-50'>
					<FileUploaderB value={trackingImg} handleValue={setTrackingImg} />
				</div>

				<div>
					<Button className='mx-auto' text='Adjuntar' onClick={() => handleFetch(form, 'tracking')} />
				</div>
			</div>
		</>
	)
}
import { Route, Routes } from "react-router-dom"
import PermisosCreate from "../views/Permisos/PermisosCreate"
import PermisosUpdate from "../views/Permisos/PermisosUpdate"
import PermisosListado from "../views/Permisos/PermisosListado"



const PermisosRouter = () => {
   return (
      <Routes>
         <Route path='/' element={<PermisosListado />} />
         <Route path='/create' element={<PermisosCreate />} />
         <Route path='/update' element={<PermisosUpdate />} />
      </Routes>
   )
}

export default PermisosRouter
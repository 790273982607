import axios from "axios"
import { getAll } from "../../../endpoints/getAll"


export const getAllImagenesProductos = async (limit = 9999, paginationUrl = undefined) => {
  try {
    const response = await axios(`${paginationUrl ?? `${getAll.productos_imagenes_masiva}`}${paginationUrl ? '&' : '?'}limit=${limit}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })

    if (!response.status) throw response

    return response.data
  } catch (err) {
    console.error(err)
    return err.response
  }
}
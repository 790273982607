import moment from "moment"

/**Retorna el valor del primer día del mes en curso */
export const currentMonthDate = (date) => {
  const today = new Date(date)
  const firstWeekDay = today.getDay() === 1
    ? 0
    : today.getDay() === 0
      ? 6
      : today.getDay() - 1
  const dayCalc = today.getDate() === 1
    ? 0
    : today.getDay() === 0
      ? 6
      : today.getDate() - 1
  const first = moment(today).add(`-${dayCalc + firstWeekDay}`, 'days').format('MM/DD/YYYY')

  const last = moment(first).add(`35`, 'days').format('MM/DD/YYYY')
  return { first, last }
}

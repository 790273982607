import axios from 'axios'
import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { FILTER_ADD_DIVISA, FILTER_ADD_ESTATUS_PEDIDO, FILTER_ADD_METODO_ENTREGA, FILTER_ADD_METODO_PAGO, FILTER_ADD_PUNTO_ENTREGA, FILTER_ADD_TIPO_FACTURACION, FILTER_ADD_USUARIO, FILTER_CANTIDAD_RESULTADOS, FILTER_FECHA_AGENDA, FILTER_FECHA_FINAL, FILTER_FECHA_INICIO, FILTER_NOMBRE_CLIENTE, FILTER_PAIS, FILTER_PEDIDO_ID } from '../../redux/redux-types'
import { agendaDesdeFilter, agendaFechaFilter, agendaHastaFilter, busquedaUsuarioFilter, clearFilterAgendamientos, divisaFilter, estatusPedidoFilter, limitFilter, metodoEntregaFilter, metodoPagoFilter, paisFilter, pedidoFilter, puntoEntregaFilter, resultadosAgendamientos, tipoFacturacionFilter, usuarioFilter } from '../../redux/actions/agendamientos'


// Components
import FormInput from '../FormInput'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'
import SelectFilter from '../SelectFilter'
import { lastPosition } from '../../helpers/lastPosition'
import FiltroAplicado from './FiltroAplicado'
import { idExtractor } from '../../helpers/idExtractor'
import DownloadFile from '../Download/DownloadFile'


const CONSTANT = {
  queryUrlGetAll: getAll.agendamientos + '/listar',
  queryExportar: getAll.agendamientos + '/exportar',
  redirectUrl: '/agendamientos',
  title: 'Búsqueda de agendamientos',
  reduxClearFilters: clearFilterAgendamientos,
  reduxSetResults: resultadosAgendamientos,

  fetchPaises: getAll.paises + '/buscar?limit=99999',
  fetchUsuarios: getAll.usuarios + '/listar?limit=99999',
  fetchPuntosEntrega: getAll.puntos_entrega + '/listar',
  fetchMetodosEntrega: getAll.metodos_entrega,
  fetchMetodosPago: getAll.metodos_pago,
  fetchDivisas: getAll.divisas,
  fetchTiposFacturacion: getAll.tipos_facturacion,
  fetchEstatusPedido: getAll.estatus_pedido,

  selectFilterInitialState: { paises: [], usuarios: [], puntos_entrega: [], metodos_entrega: [], metodos_pago: [], divisas: [], tipos_facturacion: [], estatus_pedido: [] }
}


const dataFormatter = (obj) => {
  let data = {}
  Object.entries(obj).forEach(([k, v]) => {
    if (['usuario_id', 'puntoentrega_id', 'metodo_entrega_id', 'metodo_pago_id', 'divisa_id', 'tipo_facturacion_id', 'estatus_pedido_id'].includes(k) && v.length) {
      return data = {
        ...data,
        filtros: {
          ...data.filtros,
          [k]: v.map(str => idExtractor(str))
        }
      }
    }

    if (['pedido_id'].includes(k) && v.length) {
      return data = {
        ...data,
        filtros: {
          ...data.filtros,
          [k]: [Number(v)]
        }
      }
    }
  })

  return data
}


const todayFormat = () => {
  const date = new Date()
  let dd = date.getDate()
  let mm = date.getMonth() + 1
  let yyyy = date.getFullYear()
  return `${yyyy}-${String(mm).length < 2 ? `0${mm}` : mm}-${String(dd).length < 2 ? `0${dd}` : dd}`
}


const fetchSelectFiltersData = async () => {
  const paises = await axios(CONSTANT.fetchPaises, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    }, method: 'POST'
  })
    .then(({ data }) => data?.paises?.data ?? data)
    .catch(err => {
      console.error(err)
      return []
    })

  const usuarios = await axios(CONSTANT.fetchUsuarios, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    }, method: 'POST'
  })
    .then(({ data }) => data?.data)
    .catch(err => {
      console.error(err)
      return []
    })

  const puntos_entrega = await axios(CONSTANT.fetchPuntosEntrega, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    }, method: 'POST'
  })
    .then(({ data }) => data)
    .catch(err => {
      console.error(err)
      return []
    })

  const metodos_entrega = await axios(CONSTANT.fetchMetodosEntrega, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
  })
    .then(({ data }) => data)
    .catch(err => {
      console.error(err)
      return []
    })

  const metodos_pago = await axios(CONSTANT.fetchMetodosPago, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
  })
    .then(({ data }) => data)
    .catch(err => {
      console.error(err)
      return []
    })

  const divisas = await axios(CONSTANT.fetchDivisas, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
  })
    .then(({ data }) => data)
    .catch(err => {
      console.error(err)
      return []
    })

  const tipos_facturacion = await axios(CONSTANT.fetchTiposFacturacion, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
  })
    .then(({ data }) => data)
    .catch(err => {
      console.error(err)
      return []
    })

  const estatus_pedido = await axios(CONSTANT.fetchEstatusPedido, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    },
  })
    .then(({ data }) => data)
    .catch(err => {
      console.error(err)
      return []
    })

  return {
    paises,
    usuarios,
    puntos_entrega,
    metodos_entrega,
    metodos_pago,
    divisas,
    tipos_facturacion,
    estatus_pedido
  }
}


const AgendamientosFiltros = () => {
  const { filtros } = useSelector(state => state.agendamientos)
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const {
    busqueda_usuario,
    pais_id,
    pedido_id,
    desde,
    hasta,
    fecha,
    usuario_id,
    puntoentrega_id,
    metodo_entrega_id,
    metodo_pago_id,
    divisa_id,
    tipo_facturacion_id,
    limit,
    estatus_pedido_id } = filtros

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}` + queryParamsFormatter({ desde: todayFormat() }), {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
      .catch(err => {
        console.error(err)
        dispatch(CONSTANT.reduxSetResults([]))
      })
  }, [dispatch, showing_results])


  // EFECTO QUE SETEA LOS VALORES DE LOS SELECTFILTERS Y FECHA DESDE
  useEffect(() => {
    fetchSelectFiltersData().then(data => setSelectFilter(data))
    dispatch(agendaDesdeFilter(todayFormat()))
  }, [dispatch])


  // EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
  useEffect(() => {
    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = (reset = false) => {
    let data = {}
    let url = CONSTANT.queryUrlGetAll + `?limit=${limit}` + queryParamsFormatter({ busqueda_usuario, pais_id, desde, hasta, fecha })
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST', data
    }

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return axios(CONSTANT.queryUrlGetAll + `?limit=${limit}`, config)
        .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
        .catch(err => {
          console.error(err)
          dispatch(CONSTANT.reduxSetResults([]))
        })
    }

    config = { ...config, data: dataFormatter(filtros) }
    setIsConsulting(true)

    axios(url, config)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
            `,
          html: `
            <b> Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
            `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
        CONSTANT.reduxSetResults([])
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_CANTIDAD_RESULTADOS:
        dispatch(limitFilter(value))
        break;

      case FILTER_NOMBRE_CLIENTE:
        dispatch(busquedaUsuarioFilter(value))
        break;

      case FILTER_PAIS:
        dispatch(paisFilter(value))
        break;

      case FILTER_FECHA_INICIO:
        dispatch(agendaDesdeFilter(value))
        break;

      case FILTER_FECHA_FINAL:
        dispatch(agendaHastaFilter(value))
        break;

      case FILTER_FECHA_AGENDA:
        dispatch(agendaFechaFilter(value))
        break;

      case FILTER_ADD_USUARIO:
        dispatch(usuarioFilter(value))
        break;

      case FILTER_ADD_PUNTO_ENTREGA:
        dispatch(puntoEntregaFilter(value))
        break;

      case FILTER_ADD_METODO_ENTREGA:
        dispatch(metodoEntregaFilter(value))
        break;

      case FILTER_ADD_METODO_PAGO:
        dispatch(metodoPagoFilter(value))
        break;

      case FILTER_ADD_DIVISA:
        dispatch(divisaFilter(value))
        break;

      case FILTER_ADD_TIPO_FACTURACION:
        dispatch(tipoFacturacionFilter(value))
        break;

      case FILTER_ADD_ESTATUS_PEDIDO:
        dispatch(estatusPedidoFilter(value))
        break;

      case FILTER_PEDIDO_ID:
        dispatch(pedidoFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-2 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
        exportBtn={
          <DownloadFile
            url={CONSTANT.queryExportar + '?q=0' + queryParamsFormatter({ busqueda_usuario, pais_id, desde, hasta, fecha })}
            data={dataFormatter(filtros)}
          />
        }
      >
        <>
          <FormInput
            labelText='Búsqueda de usuario'
            placeholder='nombre, RUT, fono, email...'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_NOMBRE_CLIENTE)}
            value={busqueda_usuario}
          />

          <FormInput
            labelText='Búsqueda de pedido'
            placeholder='Pedido...'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_PEDIDO_ID)}
            value={pedido_id}
          />

          <FormInput
            type='date'
            labelText='Fecha exacta'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_FECHA_AGENDA)}
            value={fecha}
          />

          <FormInput
            type='date'
            labelText='Desde'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_FECHA_INICIO)}
            value={desde}
          />

          <FormInput
            type='date'
            labelText='Hasta'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_FECHA_FINAL)}
            value={hasta}
          />

          <SelectFilter
            labelText='País'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_PAIS)}
            optionObj={selectFilter.paises.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={pais_id?.split('-')[1] ?? 'Seleccione un país'}
          />

          <SelectFilter
            labelText='Punto de entrega'
            size='col-12'
            sizeDesk='col-md-6'
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_PUNTO_ENTREGA)}
            optionObj={selectFilter.puntos_entrega.map(({ id, direccion, tipo_destino, comuna }) => ({ id, name: `${tipo_destino?.nombre ?? 'N/A'} | ${direccion ?? 'N/A'} | ${comuna?.region?.nombre ?? 'N/A'}` }))}
            value={lastPosition(puntoentrega_id)}
          />

          {/* <SelectFilter
            labelText='Usuario'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_USUARIO)}
            optionObj={selectFilter.usuarios.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={lastPosition(usuario_id)}
          /> */}


          <SelectFilter
            labelText='Método de entrega'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_METODO_ENTREGA)}
            optionObj={selectFilter.metodos_entrega.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={lastPosition(metodo_entrega_id)}
          />

          <SelectFilter
            labelText='Método de pago'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_METODO_PAGO)}
            optionObj={selectFilter.metodos_pago.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={lastPosition(metodo_pago_id)}
          />

          <SelectFilter
            labelText='Divisa'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_DIVISA)}
            optionObj={selectFilter.divisas.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={lastPosition(divisa_id)}
          />

          <SelectFilter
            labelText='Tipo de facturación'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_TIPO_FACTURACION)}
            optionObj={selectFilter.tipos_facturacion.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={lastPosition(tipo_facturacion_id)}
          />

          <SelectFilter
            labelText='Estatus de pedido'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_ESTATUS_PEDIDO)}
            optionObj={selectFilter.estatus_pedido.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={lastPosition(estatus_pedido_id)}
          />

          <FormInput
            type='number'
            labelText='Resultados a mostrar'
            placeholder='Cantidad'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_CANTIDAD_RESULTADOS)}
            value={limit}
          />
        </>

        {/* FILTROS APLICADOS - ADICIONALES */}
        <p className='mb-25 text-black'>Filtros aplicados</p>
        <div className='row'>
          <FiltroAplicado array={usuario_id} func={usuarioFilter} title={'Usuarios'} />
          <FiltroAplicado array={puntoentrega_id} func={puntoEntregaFilter} title={'Puntos de entrega'} />
          <FiltroAplicado array={metodo_entrega_id} func={metodoEntregaFilter} title={'Métodos de entrega'} />
          <FiltroAplicado array={metodo_pago_id} func={metodoPagoFilter} title={'Métodos de pago'} />
          <FiltroAplicado array={divisa_id} func={divisaFilter} title={'Divisas'} />
          <FiltroAplicado array={tipo_facturacion_id} func={tipoFacturacionFilter} title={'Tipos de facturación'} />
          <FiltroAplicado array={estatus_pedido_id} func={estatusPedidoFilter} title={'Estatus de pedido'} />
        </div>

        <hr className='my-1' />

      </ListadoHeader>
    </div>
  )
}

export default AgendamientosFiltros
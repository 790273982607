import React from 'react'
import { useLocation } from 'react-router-dom'
import AgendamientosFiltros from '../../../components/AccordionFilter/Agendamientos'
import AgendamientosPuntoEntregaFiltros from '../../../components/AccordionFilter/AgendamientosPuntoEntrega'
import AgendamientosPuntoEntregaTable from '../../../components/Tables/AgendamientosPuntoEntregaTable'
import AgendamientosTable from '../../../components/Tables/AgendamientosTable'


const CONSTANT = {
   agendamientos: {
      title: 'Agendamientos',
      path: 'agendamientos',
      create_btn: 'Crear agendamiento',
      render: <>
         <AgendamientosFiltros />
         <AgendamientosTable />
      </>
   },
   'puntos-entrega': {
      title: 'Puntos de entrega',
      path: 'puntos-entrega',
      create_btn: 'Crear punto de entrega',
      render: <>
         <AgendamientosPuntoEntregaFiltros />
         <AgendamientosPuntoEntregaTable />
      </>
   },

}


const Listado = () => {
   const { pathname } = useLocation()
   const path = pathname.split('/')[2]
   
   return (
      <div className="row">
         <h3 className="mb-1 d-flex justify-content-between">
            <p><b>{CONSTANT[path ?? 'agendamientos'].title}</b> - Listado</p>
            {/* <DropdownWrapper text="Acciones" classes="ms-auto">
               <DropdownMenuLink text={CONSTANT[path ?? 'agendamientos'].create_btn} href={`/${CONSTANT[path ?? 'agendamientos'].path}/create`} icon="Plus" />
            </DropdownWrapper> */}
         </h3>

         {CONSTANT[path ?? 'agendamientos'].render}
      </div>
   )
}

export default Listado
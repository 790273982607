import { useDispatch } from "react-redux"
import { fireToaster } from "../redux/actions/toaster"

export const useCopyClipboard = () => {
  const dispatch = useDispatch()

  const copy = (str) => {
    if (!str) return new Error('No hay nada para copiar')

    navigator.clipboard.writeText(str)
    dispatch(fireToaster({ title: 'Texto copiado', icon: 'success' }))
  }

  return [copy]
} 
import { Route, Routes } from "react-router-dom"
import NotasVentasRouter from "./ERP/NotasVentasRouter"
import PlanificacionRouter from "./ERP/PanificacionRouter"
import OrdenesCompraRouter from "./ERP/OrdenesCompraRouter"
import { useSelector } from "react-redux"



const ErpRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         {user.permisos['notas-venta.listar'] &&
            <Route path='/notas-ventas/*' element={<NotasVentasRouter />} />
         }

         {user.permisos['planificar.listar'] &&
            <Route path='/planificacion/*' element={<PlanificacionRouter />} />
         }

         {user.permisos['ordenes-compra.listar'] &&
            <Route path='/ordenes-compra/*' element={<OrdenesCompraRouter />} />
         }
      </Routes>
   )
}

export default ErpRouter
import { useEffect, useRef, useState } from 'react';


// Components
import Icon from './Icon';


const DropdownMenu = ({ icon, text, classes, children }) => {
   const [isDisplayed, setIsDisplayed] = useState(false);

   const dropDownMenu = useRef();
   const dropDownButton = useRef();

   const handleDropdown = (e) => {
      setIsDisplayed(!isDisplayed);
   };

   useEffect(() => {
      dropDownMenu.current.classList.toggle('show')
   }, [isDisplayed]);

   return (
      <div
         className={`nav-item dropdown dropdown-user ${isDisplayed && "show"} ${classes ?? ''}`}
         ref={dropDownButton}
      >
         <button
            className="btn-icon btn btn-primary btn-round btn-sm waves-effect waves-float waves-light show fs-4"
            onClick={(e) => handleDropdown(e)}
         >
            {text && text}
            {icon && <Icon icon={icon} />}
         </button>

         <div
            ref={dropDownMenu}
            className="dropdown-menu dropdown-menu-end dropdown-menu-rtl"
            aria-labelledby="dropdown-user"
         >
            {children}
         </div>
      </div>
   );
}

export default DropdownMenu;
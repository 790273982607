import axios from "axios"
import { getOne } from "../../endpoints/getOne"


export const updateServicio = async (id, data) => {
  try {

    const response = await axios(getOne.servicios(id), {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
      data
    })

    if (response.status >= 400) throw response

    return response
  } catch (err) {
    console.error(`Error actualizando el registro de servicio`);
    return err
  }
}
import {
   FILTER_ADD_PROVEEDOR,
   FILTER_REMOVE_PROVEEDOR,
   FILTER_DESC_PRODUCTO,
   FILTER_FECHA_INICIO,
   FILTER_FECHA_FINAL,
   FILTER_CLEAR,
   FILTER_REMOVE_NOTA_VENTA,
   FILTER_ADD_NOTA_VENTA,
   FILTER_REMOVE_SKU,
   FILTER_ADD_SKU,
   FILTER_REMOVE_OC,
   FILTER_ADD_OC,
   FILTER_ESTATUS,
   FILTER_REMOVE_VENDEDOR,
   FILTER_ADD_VENDEDOR,
   FILTER_LIMITE,
   RESULTADOS_ORDENES_COMPRA,
} from "../redux-types";


// export const nvFilter = (data, isRemove = false) => {
//    return isRemove
//       ? { type: FILTER_REMOVE_NOTA_VENTA, payload: data }
//       : { type: FILTER_ADD_NOTA_VENTA, payload: data };
// }

// export const skuFilter = (data, isRemove = false) => {
//    return isRemove
//       ? { type: FILTER_REMOVE_SKU, payload: data }
//       : { type: FILTER_ADD_SKU, payload: data };
// }

// export const ocFilter = (data, isRemove = false) => {
//    return isRemove
//       ? { type: FILTER_REMOVE_OC, payload: data }
//       : { type: FILTER_ADD_OC, payload: data };
// }

export const proveedoresFilter = (data, isRemove = false) => {
   return isRemove
      ? { type: FILTER_REMOVE_PROVEEDOR, payload: data }
      : { type: FILTER_ADD_PROVEEDOR, payload: data };
}


export const vendedoresFilter = (data, isRemove = false) => {
   return isRemove
      ? { type: FILTER_REMOVE_VENDEDOR, payload: data }
      : { type: FILTER_ADD_VENDEDOR, payload: data };
}

// ESTOS SON MOMENTANEOS, SE DEBEN CAMBIAR A ARREGLOS EN REDUCER
export const nvFilter = (data) => ({ type: FILTER_ADD_NOTA_VENTA, payload: data })
export const skuFilter = (data) => ({ type: FILTER_ADD_SKU, payload: data })
export const ocFilter = (data) => ({ type: FILTER_ADD_OC, payload: data })
// 

export const estatusFilter = (data) => ({ type: FILTER_ESTATUS, payload: data })
export const descProductoFilter = (data) => ({ type: FILTER_DESC_PRODUCTO, payload: data })
export const limiteFilter = (data) => ({ type: FILTER_LIMITE, payload: data })
export const creacionDesdeFilter = (data) => ({ type: FILTER_FECHA_INICIO, payload: data })
export const creacionHastaFilter = (data) => ({ type: FILTER_FECHA_FINAL, payload: data })

export const resultadosOrdenesCompra = (data) => ({ type: RESULTADOS_ORDENES_COMPRA, payload: data })
export const clearFilterOrdenesCompra = () => ({ type: FILTER_CLEAR })


export const types = {
   // Mobile menu
   SIDE_BAR: '[UI] toggle sidebar',
}



const initialState = {
   // sidebar
   sidebar: true
}



export const uiReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SIDE_BAR:
         return {
            ...state,
            sidebar: !state.sidebar
         }

      default:
         return state;
   }
}
import {
   FILTER_ADD_CATEGORIA,
   FILTER_REMOVE_CATEGORIA,
   FILTER_ADD_ATRIBUTO_RELACION,
   FILTER_REMOVE_ATRIBUTO_RELACION,
   FILTER_ADD_ATRIBUTO_VALOR_PADRE,
   FILTER_REMOVE_ATRIBUTO_VALOR_PADRE,
   FILTER_ADD_ATRIBUTO_VALOR_HIJO,
   FILTER_REMOVE_ATRIBUTO_VALOR_HIJO,
   FILTER_REMOVE_ESTATUS,
   FILTER_ADD_ESTATUS,
   FILTER_CLEAR
} from "../redux-types";

const initialState = {
   filters: {
      categoria: [],
      atributo_relacion: [],
      atributo_valor_padre: [],
      atributo_valor_hijo: [],
      estatus: []
   }
}

export const atributosValoresRelacionesReducer = (state = initialState, action) => {
   const isListedCategoria = state.filters.categoria.find(el => el === action.payload)
   const isListedAtributoRelacion = state.filters.atributo_relacion.find(el => el === action.payload)
   const isListedAtributoValorPadre = state.filters.atributo_valor_padre.find(el => el === action.payload)
   const isListedAtributoValorHijo = state.filters.atributo_valor_hijo.find(el => el === action.payload)
   const isListedEstatus = state.filters.estatus.find(el => el === action.payload)

   switch (action.type) {
      case FILTER_ADD_CATEGORIA:
         return {
            ...state, filters: {
               ...state.filters,
               categoria: isListedCategoria
                  ? state.filters.categoria
                  : [...state.filters.categoria, action.payload]
            }
         };

      case FILTER_REMOVE_CATEGORIA:
         return {
            ...state, filters: {
               ...state.filters,
               categoria: state.filters.categoria.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_ATRIBUTO_RELACION:
         return {
            ...state, filters: {
               ...state.filters,
               atributo_relacion: isListedAtributoRelacion ? state.filters.atributo_relacion : [...state.filters.atributo_relacion, action.payload]
            }
         };

      case FILTER_REMOVE_ATRIBUTO_RELACION:
         return {
            ...state, filters: {
               ...state.filters,
               atributo_relacion: state.filters.atributo_relacion.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_ATRIBUTO_VALOR_PADRE:
         return {
            ...state, filters: {
               ...state.filters,
               atributo_valor_padre: isListedAtributoValorPadre ? state.filters.atributo_valor_padre : [...state.filters.atributo_valor_padre, action.payload]
            }
         };

      case FILTER_REMOVE_ATRIBUTO_VALOR_PADRE:
         return {
            ...state, filters: {
               ...state.filters,
               atributo_valor_padre: state.filters.atributo_valor_padre.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_ATRIBUTO_VALOR_HIJO:
         return {
            ...state, filters: {
               ...state.filters,
               atributo_valor_hijo: isListedAtributoValorHijo ? state.filters.atributo_valor_hijo : [...state.filters.atributo_valor_hijo, action.payload]
            }
         };

      case FILTER_REMOVE_ATRIBUTO_VALOR_HIJO:
         return {
            ...state, filters: {
               ...state.filters,
               atributo_valor_hijo: state.filters.atributo_valor_hijo.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_ESTATUS:
         return {
            ...state, filters: {
               ...state.filters,
               estatus: isListedEstatus ? state.filters.estatus : [...state.filters.estatus, action.payload]
            }
         };

      case FILTER_REMOVE_ESTATUS:
         return {
            ...state, filters: {
               ...state.filters,
               estatus: state.filters.estatus.filter(el => el !== action.payload)
            }
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
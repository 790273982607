
// Utils
import axios from 'axios';
import PropTypes from 'prop-types'
import { useState } from 'react';
import { useDispatch } from 'react-redux';


// Components
import FormInput from '../components/FormInput';
import FormPasswordInput from '../components/FormPasswordInput';
import { BASE_URL, PATH_URL } from '../endpoints';
import { setLoginData } from '../redux/actions/auth';
import CheckBoxInput from '../components/CheckboxInput';


const CONSTANT = {
  redirectUrl: '/home',
  queryUrl: BASE_URL + PATH_URL + '/auth/login',
  reduxSetLoginData: setLoginData,
  initialFormState: { email: '', clave: '' }
}


const LoginForm = ({ size, sizeTablet, sizeDesk }) => {
  const [form, setForm] = useState(CONSTANT.initialFormState)
  const [rememberMe, setRememberMe] = useState(true)
  const [error401, setError401] = useState(false)
  const dispatch = useDispatch()

  // FUNCION MANEJADORA DEL FORMULARIO
  const handleInputForm = (e) => {
    const { name, value } = e.target

    setError401(false)

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCION MANEJADORA DE LA PETICION A LA BBDD
  const handleFetch = (e, target) => {
    e.preventDefault()
    let config, url, data

    if (target === 'login') {
      url = CONSTANT.queryUrl
      config = { method: 'POST' }
      data = form
    }

    config = { ...config, data }


    axios(url, config)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetLoginData(data?.usuario))
        rememberMe
          ? localStorage.setItem('x-token', data?.token)
          : sessionStorage.setItem('x-token', data?.token)
      })
      .catch(err => {
        console.error(err)
        dispatch(CONSTANT.reduxSetLoginData({}))
        setError401(true)
      })
  }
  return (
    <section className={`rounded-3 shadow-sm mx-auto z-10 login-modal ${size} ${sizeTablet} ${sizeDesk}`}>
      <div className="h-100 w-100">
        <div className="d-flex align-items-center justify-content-center h-100 login-component p-4">
          <div className="col-md-8 col-lg-7 col-xl-6">
            <img
              src={process.env.PUBLIC_URL + "/logo_oscuro.svg"}
              className="img-fluid login-logo w-100 p-1" alt="Phone_image"
            />
          </div>

          <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
            <form onSubmit={(e) => handleFetch(e, 'login')}>
              <p className='text-center fw-bold' style={{ color: error401 ? '#ff5452' : '#333' }}>{error401 ? 'Datos ingresados no válidos' : 'Ingrese sus datos'}</p>

              <FormInput
                name='email'
                type="email"
                labelText="Email"
                handleValue={handleInputForm}
                value={form.email ?? ''}
              />

              <FormPasswordInput
                name='clave'
                labelText="Contraseña"
                handleValue={handleInputForm}
                value={form.clave ?? ''}
              />

              <div className="d-flex justify-content-around align-items-center mb-4">
                <div className="form-check">
                  <CheckBoxInput labelText='Recuérdame' value={rememberMe} handleValue={() => setRememberMe(!rememberMe)} />
                </div>

                <a className="underline-text" href="/#">¿Olvidaste la contraseña?</a>
              </div>

              <button type='submit' className="btn text-white btn-lg btn-block w-100 bg-brand-color">Entrar</button>
            </form>
          </div>
        </div>
      </div>

    </section >
  );
}

LoginForm.propTypes = {
  size: PropTypes.string,
  sizeDesk: PropTypes.string,
  sizeTablet: PropTypes.string
}

LoginForm.defaultProps = {
  size: '',
  sizeDesk: '',
  sizeTablet: ''
}

export default LoginForm;
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";


//Redux
import { useDispatch, useSelector } from "react-redux";


// Components
import Accordion from "../../components/Accordion";
import AccordionDetailData from "../../components/AccordionDetailData";
import Button from "../../components/Button";
import Table from "../../components/Table";
import FormInput from "../../components/FormInput";
import CheckBoxInput from "../../components/CheckboxInput";
import { NoResults } from "../../components/Tables/Misc/NoResults";


// Layouts
import AccordionTable from "../../layout/AccordionTable";


// Endpoints
import { getOne } from "../../endpoints/getOne";


// React-router
import { useNavigate, useParams } from "react-router-dom";


//Helpers
import capitalize from "../../helpers/capitalize";
import { getAll } from "../../endpoints/getAll";
import CustomLoader from "../../components/CustomLoader";
import { fireToaster } from "../../redux/actions/toaster";
import ProductosFiltros from "../../components/AccordionFilter/Productos";
import { BASE_URL, PATH_URL } from "../../endpoints";
import { historicoData } from "../../helpers/historicoData";
import { ModalHistorico } from "../../components/Historico/ModalHistorico";
import TablaDetalle from "../../components/Historico/TablaDetalle";
import { renderRecargoInstalacion } from "../../helpers/renderRecargoInstalacion";
import { PrecioFormatter } from "../../helpers/precioFormatter";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import DropdownMenuLink from "../../components/Dropdown/Link";
import Modal from "../../components/Modal";
import { create } from "../../endpoints/create";


const Precio = (v) => new PrecioFormatter(v).producto()


const CONSTANT = {
  queryUrlGetOne: getOne.cotizaciones,
  queryEnviarCot: create.cotizaciones + `/enviar`,
  queryLineasCotizacion: BASE_URL + PATH_URL + `/lineas-cotizacion`,
  title: 'Cotizaciones',
  redirectListado: '/cotizaciones',
  pathUrl: '/cotizaciones',

  queryUrlGetAllPaises: getAll.paises + '/buscar',
  queryUrlGetAllComunas: getAll.comunas + '/buscar',
  queryUrlGetAllPuntosEntrega: getAll.puntos_entrega + '/listar',
  queryUrlGetAllMetodosPago: getAll.metodos_pago,
  queryUrlGetAllTiposFacturacion: getAll.tipos_facturacion,

  queryUrl: getAll.cotizaciones + '/pedido',
  modelo: 'App\\Models\\NeuCotizacion',
  permissionsPath: 'cotizaciones',

  modalTarget: {
    enviar_cot: 'enviar cot'
  },

  editLineaForm: {
    linea_cotizacion_id: '',
    cantidad_cotizada: '',
    precio_unidad: '',
    recargo: '',
    precio_instalacion: ''
  }
}


// INCOMPLETO: FALTA AGREGAR LA FUNCION PARA ACTUALIZAR LOS PRODUCTOS, chill bro, ya estoy trabajando

const validateOferta = (producto) => {
  const criterio = producto?.neu_oferta_producto?.[0]?.neu_oferta?.neu_criterio_comparacion?.simbolo ?? null
  const { cantidad } = producto
  const limite = Number(producto?.neu_oferta_producto?.[0]?.neu_oferta?.cantidad_limite)

  switch (criterio) {
    case '>':
      return cantidad > limite

    case '>=':
      return cantidad >= limite

    case '=':
      return cantidad === limite

    case '<=':
      return cantidad <= limite

    case '<':
      return cantidad < limite

    default:
      return false
  }
}


const Detalles = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { resultados } = useSelector(state => state.productos)
  const { user } = useSelector(state => state.auth)
  const [displayAllAccordion, setDisplayAllAccodion] = useState(true);
  const [addProductos, setAddProductos] = useState(false)
  const redirect = useNavigate()
  const [historico, setHistorico] = useState(null)
  const [modalHistorico, setModalHistorico] = useState({ id: null, display: false, data: null })
  const [modal, setModal] = useState({ display: false, data: null })

  const [data, setData] = useState(null)

  const [productosEncontrados, setProductosEncontrados] = useState(false)
  const [filteredProductosEncontrados, setFilteredProductosEncontrados] = useState(null)
  const [filteredProductosStockDisponible, setFilteredProductosStockDisponible] = useState(false)
  const [orderProductosPrecio, setOrderProductosPrecio] = useState(false)
  const [nombreInputProductoEncontrado, setNombreInputProductoEncontrado] = useState('')
  const [skuInputProductoEncontrado, setSkuInputProductoEncontrado] = useState('')

  const [productosSeleccionados, setProductosSeleccionados] = useState([])
  const [productosSeleccionadosFiltrados, setProductosSeleccionadosFiltrados] = useState(productosSeleccionados)

  const [filterProducto, setFilterProducto] = useState('')
  const [editLineaForm, setEditLineaForm] = useState(CONSTANT.editLineaForm)

  const [isConsulting, setIsConsulting] = useState(false)

  const add = useRef()


  // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
  useEffect(() => {
    // TODO: sacar en una funcion aparte
    axios(CONSTANT.queryUrlGetOne(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        setData({
          ...data,
          lineas_cotizacion: data?.lineas_cotizacion?.map(producto => ({ ...producto, edicion: false }))
        })
        historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
      })
      .catch(err => {
        console.error(err)
        const toasterContent = {
          title: 'Registro no encontrado',
          icon: 'warning'
        }

        dispatch(fireToaster(toasterContent))
        redirect(CONSTANT.redirectListado)
      })

    return () => {
      setData(null)
    }
  }, [id, dispatch, redirect])


  // EFECTO QUE RELLENA LA DATATABLE DE PRODUCTOS ENCONTRADOS
  useEffect(() => {
    if (!resultados?.data || !resultados?.data?.length) return setProductosEncontrados([])

    const orderedResults = resultados.data.map(prod => {
      const stockDisponible = prod?.stock_erp.length ? prod.stock_erp.reduce((prev, current, i) => {
        return prev += Number(current.stock_disponible)
      }, 0)
        : 0
      return prod = { ...prod, filtro_stock: stockDisponible, precio_unitario: Number(prod.precio_final) }
    })

    orderedResults.sort((a, b) => b.filtro_stock - a.filtro_stock)

    setProductosEncontrados(orderedResults)
  }, [resultados])


  // EFECTO QUE FILTRA LOS PRODUCTOS DE LA TABLA DE PRODUCTOS ENCONTRADOS
  useEffect(() => {
    if (!productosEncontrados.length) return setFilteredProductosEncontrados([])

    let filtered = productosEncontrados?.filter(producto => producto.nombre.toLowerCase().includes(nombreInputProductoEncontrado.toLowerCase()) &&
      producto.sku.toLowerCase().includes(skuInputProductoEncontrado.toLowerCase()))

    if (filteredProductosStockDisponible) filtered = filtered.filter(el => el.filtro_stock > 0)
    filtered = filtered.sort((a, b) => orderProductosPrecio
      ? b.precio_final - a.precio_final
      : a.precio_final - b.precio_final
    )

    setFilteredProductosEncontrados(filtered)
  }, [productosEncontrados, nombreInputProductoEncontrado, skuInputProductoEncontrado, filteredProductosStockDisponible, orderProductosPrecio])


  // RENDERIZA LOS PRODUCTOS LUEGO DE FILTRADOS EN LA DATATABLE
  useEffect(() => {
    if (!productosEncontrados) return

    setProductosSeleccionadosFiltrados([...productosSeleccionados
      .map(el => ({ ...el, filter_params: `${el.nombre} ${el.sku}` }))
      .filter(el => el.filter_params.toLocaleLowerCase().includes(filterProducto.toLocaleLowerCase()))
    ])
  }, [filterProducto, productosSeleccionados, productosEncontrados])


  /* Añade el producto seleccionado desde el arreglo de productos encontrado */
  const handleProductoSeleccionado = async (e) => {
    const { name } = e.target?.dataset ?? e.target
    const [id] = name.split('%-%')
    let isListed = productosSeleccionados.find(obj => String(obj.id) === id)
    let isAdded = data.lineas_cotizacion.find(obj => String(obj.producto.id) === id)
    let selected = productosEncontrados.find(obj => String(obj.id) === id)

    if (isListed || isAdded) return dispatch(fireToaster({ title: 'Este producto ya se encuentra', icon: 'info' }))

    selected = { ...selected, cantidad: 1, seleccionado: true, porcentaje_descuento: 0 }

    setProductosSeleccionados([...productosSeleccionados, selected])
  }


  const handleQuitarProductosSeleccionados = (e) => {
    const { name } = e.target?.dataset ?? e.target
    const [id] = name.split('%-%')
    let isListed = productosSeleccionados.find(obj => String(obj.id) === id)
    setProductosSeleccionados(productosSeleccionados.filter((producto) => producto.id !== isListed.id))
    //setProductosSeleccionados([]) 
  }


  const handleInputFilterProducto = (e) => setFilterProducto(e.target.value);


  const handleInputProductoSeleccionado = (e, producto, target) => {
    let { value } = e.target
    const { id, nombre } = producto

    if (target === 'porcentaje_descuento' && value > 100) value = 100
    if (target === 'porcentaje_descuento' && value < 0) value = 0
    if (target === 'cantidad' && value < 1) value = 1

    setProductosSeleccionados(productosSeleccionados.map(el => {
      return (el.id === id && el.nombre === nombre)
        ? { ...el, [target]: Number(value) }
        : el
    }))
  }


  const handleCounterButtonProductoSeleccionado = (producto, target) => {
    const { id, nombre, cantidad } = producto
    let newCantidad = cantidad

    switch (target) {
      case 'plus': newCantidad++
        break;

      case 'minus': newCantidad > 1 && newCantidad--
        break;

      default:
        break;
    }

    setProductosSeleccionados(productosSeleccionados.map(el => {
      return (el.id === id && el.nombre === nombre)
        ? { ...el, cantidad: Number(newCantidad) }
        : el
    }))
  }


  const handleEditForm = (obj, set = true) => {
    const targetObj = data.lineas_cotizacion.find(({ id }) => Number(id) === Number(obj.id))

    if (!targetObj) return dispatch(fireToaster({ title: 'El artículo no se encuentra en la lista', icon: 'info' }))

    setData({
      ...data, lineas_cotizacion: data?.lineas_cotizacion?.map(p => targetObj.id === p.id
        ? ({ ...p, edicion: set })
        : p
      )
    })

    setEditLineaForm({
      linea_cotizacion_id: obj?.id,
      cantidad_cotizada: Number(obj?.cantidad_cotizada),
      precio_unidad: Number(obj?.precio_unidad),
      precio_referencia: Number(obj?.linea_oferta?.precio_final),
      recargo: obj?.recargo,
      precio_instalacion: obj?.precio_instalacion
    })
  }


  const handleInputChange = (e) => {
    const { name, value } = e?.target
    const [target, key] = name.split('%-%')

    if (target === 'editLineaForm') {
      return setEditLineaForm({
        ...editLineaForm,
        [key]: value
      })
    }

    if (target === 'enviar_cot') {
      console.log('a');
      return setModal({
        ...modal,
        data: {
          ...modal.data,
          [key]: value
        }
      })
    }
  }


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleAddProductos = async (e) => {
    e.preventDefault()
    setIsConsulting(true)
    let badPrecioUnitario = []

    let productos = productosSeleccionados.map(p => ({
      cotizacion_id: Number(id),
      producto_id: p.id,
      cantidad_cotizada: p.cantidad,
      precio_ingresado: p.precio_unitario,
      precio_final: p.precio_final,
      sku: p.sku
    }))


    // REVISION DE PRECIOS LUEGO DE FORMATEADOS LOS PRODUCTOS QUE SE VAN A ENVIAR
    productos.forEach(prod => {
      const porcentajeValido = (prod.precio_final * (100 - user?.porcentaje_descuento_max ?? 30)) / 100
      if (prod.precio_ingresado < porcentajeValido) return badPrecioUnitario = [...badPrecioUnitario, prod.sku]
    })

    if (badPrecioUnitario.length) {
      return dispatch(fireToaster({
        title: 'Existen productos seleccionados con precios por debajo del límite permitido',
        html: `
        Lista de productos (SKU):
        ${badPrecioUnitario.map(sku => (
          `<br /><b>✖ ${sku}</b>`
        ))}
      `,
        icon: 'warning',
        timer: 180000
      }))
    }
    if (!productosSeleccionados.length) {
      const toasterContent = {
        title: 'No hay productos seleccionados',
        text: `Por favor, seleccione al menos un producto para proseguir`,
        icon: 'info'
      }
      return dispatch(fireToaster(toasterContent))
    }

    await Promise.all(
      productos.map(async p => axios(CONSTANT.queryLineasCotizacion, {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        data: p
      })
        .then(({ data }) => {
          return {
            [p.sku]: 200
          }
        })
        .catch(err => {
          console.error(err)
          const { errores } = err?.response?.data
          let detalles = []
          Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))
          return {
            [p.sku]: detalles
          }
        })
      )
    )
      .then(res => {
        const toasterContent = {
          title: `
          Detalles de la operación
        `,
          html: `
          <b>Resumen: </b>
          ${res.map(i => Object.entries(i).map(([k, v]) => {
            if (v === 200) v = ['Realizado']
            return `<br /><br /><i>-<b>SKU ${k}:</b> ${v.join(', ')}</i>`
          }))}
            `,
          timer: 400000,
          icon: 'info'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(async () => {
        // TODO: sacar en una funcion aparte
        setIsConsulting(false)

        await axios(CONSTANT.queryUrlGetOne(id),
          {
            headers: {
              'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
          })
          .then(({ data }) => {
            setData(data)
            setAddProductos(false)
            setProductosSeleccionados([])
            setProductosEncontrados([])
            historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
          })
          .catch(err => {
            console.error(err)
            const toasterContent = {
              title: 'Registro no encontrado',
              icon: 'warning'
            }

            dispatch(fireToaster(toasterContent))
            redirect(CONSTANT.redirectListado)
          })
      })
  }


  const handleFetch = async (objData, action) => {
    let url = CONSTANT.queryLineasCotizacion
    setIsConsulting(true)
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    }

    if (action === CONSTANT.modalTarget.enviar_cot) {
      url = CONSTANT.queryEnviarCot

      config = {
        ...config,
        method: 'POST',
        data: {
          ...objData,
          totalizacion: objData.totalizacion ? 1 : 0
        }
      }
    }

    if (action === 'update') {
      url = CONSTANT.queryLineasCotizacion
      const porcentajeValido = (editLineaForm.precio_referencia * (100 - user?.porcentaje_descuento_max ?? 30)) / 100
      if (editLineaForm.precio_unidad < porcentajeValido) return dispatch(fireToaster({ title: `El precio digitado no puede ser menor al ${100 - user?.porcentaje_descuento_max ?? 30}% de su precio final`, icon: 'info' }))

      config = {
        ...config,
        method: 'PUT',
        data: editLineaForm
      }
    }

    if (action === 'delete') {
      if (!window.confirm('¿Está seguro que desea eliminar esta línea de la cotización?')) return
      url = CONSTANT.queryLineasCotizacion + `/${objData.id}`
      config = {
        ...config,
        method: 'DELETE',
      }
    }

    await axios(url, config)
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: registro actualizado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
      })
      .catch(err => {
        console.error(err)
        const { errores } = err?.response?.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
	          Operación fallida
	          (${err.response.status} - ${err.response.statusText})
	        `,
          html: `
	          <b>Detalle: </b>
	          ${detalles.map(error => `<br /><i>-${error}</i>`)}
	        `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(async () => {
        // TODO: sacar en una funcion aparte
        setIsConsulting(false)

        await axios(CONSTANT.queryUrlGetOne(id),
          {
            headers: {
              'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
          })
          .then(({ data }) => {
            setData(data)
            setModal({ display: false, data: null })
            setAddProductos(false)
            setProductosSeleccionados([])
            setProductosEncontrados([])
            historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
          })
          .catch(err => {
            console.error(err)
            const toasterContent = {
              title: 'Registro no encontrado',
              icon: 'warning'
            }

            dispatch(fireToaster(toasterContent))
            redirect(CONSTANT.redirectListado)
          })
      })
  }


  const handleModalHistorico = (data) => {
    const { id, anterior, nuevo } = data

    setModalHistorico({
      display: true,
      id,
      data: {
        anterior,
        nuevo
      }
    })
  }

  const handleModal = (data, target) => {
    if (target === CONSTANT.modalTarget.enviar_cot) {
      return setModal({ display: target, data: { correo: data?.usuario?.email ?? data?.email_cliente, cotizacion_id: data?.id, totalizacion: 0 } })
    }
  }

  const handleFiltrosPersistentes = (location) => {
    return `?filtros_persistentes=${location?.search?.split('filtros_persistentes=')?.[1] ?? '0'}`
  }

  return (
    <>

      <ModalHistorico modal={modalHistorico} fnHandleModal={setModalHistorico} />

      <Modal size='w-100' handleValue={() => setModal({ data: null, display: false })} display={modal.display}>

        {modal.display === CONSTANT.modalTarget.enviar_cot &&
          <>
            <h4>Enviar cotización #{modal?.data?.cotizacion_id ?? 'Error'}</h4>

            <hr className="mt-25" />

            <CheckBoxInput
              name={`enviar_cot%-%totalizacion`}
              handleValue={handleInputChange}
              labelText='Agregar totales'
              value={modal?.data?.totalizacion ?? 0}
            />

            <FormInput
              type='Email'
              name='enviar_cot%-%correo'
              labelText="Correo"
              placeholder="Email"
              size="col-12"
              sizeDesk="col-md-6"
              classes='mt-1'
              handleValue={handleInputChange}
              value={modal?.data?.correo ?? ''}
            />

            <div>
              <Button disabled={isConsulting} text="Enviar" className='m-auto' onClick={() => handleFetch(modal.data, CONSTANT.modalTarget.enviar_cot)} />
            </div>
          </>

        }
      </Modal>

      <div className="card-body p-25 mb-50 bg-white rounded-2 border">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado + handleFiltrosPersistentes(window.location)} />
        </div>

        <div className="d-flex justify-content-between flex-md-row mt-0 p-25 align-items-center ">
          <b className="mb-0 px-1">
            Cotización
            <span className="mx-50 mb-0">#{data?.id}</span>
          </b>

          <div className="d-flex gap-25">
            <Button icon={displayAllAccordion ? 'Minimize2' : 'Maximize2'} onClick={() => setDisplayAllAccodion(!displayAllAccordion)} />

            {user.permisos[`${CONSTANT.permissionsPath}.editar`] && !addProductos &&
              <Button text='Añadir productos' color='warning' icon='Plus' onClick={() => { setAddProductos(true); setTimeout(() => { add.current.scrollIntoView() }, 10) }} />
            }

            {user.permisos[`${CONSTANT.permissionsPath}.crear`] && !addProductos &&
              <Button text='Enviar' color='success' icon='Mail' onClick={() => handleModal(data, CONSTANT.modalTarget.enviar_cot)} />
            }

            {user.permisos[`${CONSTANT.permissionsPath}.exportar`] &&
              <>
                <Button isDownload url={`${BASE_URL}admin/v1${CONSTANT.pathUrl}/${id}/pdf`} target='_blank' rel='noreferrer' text='Ver PDF' icon='FileText' />

                <Button isDownload url={`${BASE_URL}admin/v1${CONSTANT.pathUrl}/${id}/pdf?totalizacion=1`} target='_blank' rel='noreferrer' text='Ver PDF (con totales)' color='warning' icon='FileText' />
              </>
            }


            {data?.pedido
              ? <Button newTab icon='ArrowRight' text='Ir al pedido' color={'success'} isLink url={`/pedidos/detalle/${data?.pedido?.id}`} />
              : <Button newTab icon='Plus' text='Generar pedido' color={'success'} isLink url={`/pedidos/cotizacion/${id}`} />
            }
          </div>
        </div>
      </div>

      {data
        ? <div className="row">
          {/* COLUMNA IZQUIERDA */}
          <div className="col-12 col-md-6">
            {/* DATOS GENERALES */}
            <div className="col mb-1">
              <Accordion title={<h3 className="m-0 w-100 text-center text-white">Datos generales</h3>} isOpen={displayAllAccordion}>
                <div className="col px-2 align-items-center">
                  <div className="row">
                    <AccordionDetailData dataKey={"Punto entrega"} value={`${data?.punto_entrega?.tipo_destino?.nombre ?? 'N/A'} | ${data?.punto_entrega?.direccion ?? 'N/A'}`} />
                    <AccordionDetailData dataKey={"Tipo atención"} value={data?.tipo_atencion?.nombre ?? 'N/A'} />
                    <AccordionDetailData dataKey={"Frontend"} value={data?.frontend?.nombre ?? 'chileneumaticos'} />
                    <AccordionDetailData dataKey={"Tipo facturación"} value={data?.tipo_facturacion?.nombre ?? 'N/A'} />
                    <AccordionDetailData dataKey={"Divisa"} value={data?.divisa?.nombre ?? 'N/A'} />
                    <AccordionDetailData dataKey={"Razón social"} value={data?.razon_social ?? 'N/A'} />
                    <AccordionDetailData dataKey={"Referencia externa"} value={data?.referencia_externa ?? 'N/A'} />
                    <AccordionDetailData dataKey={"Método de pago"} value={data?.metodo_pago?.nombre ?? 'N/A'} />
                    <AccordionDetailData dataKey={"Método de pago"} value={data?.metodo_pago?.nombre ?? 'N/A'} />
                    <AccordionDetailData dataKey={"Productos totales"} value={data?.total_productos ?? 'N/A'} />
                    <AccordionDetailData dataKey={"Orden a proveedor"} value={data?.orden_a_proveedor ? 'Sí' : 'No'} />
                    <AccordionDetailData dataKey={"Costo total"} value={<b>{Precio(data?.monto_total)}</b>} />
                    <AccordionDetailData dataKey={"Costo subtotal"} value={<b>{Precio(data?.monto_subtotal)}</b>} />
                    <AccordionDetailData dataKey={"Costo envío"} value={<b>{Precio(data?.costo_envio)}</b>} />
                    <AccordionDetailData dataKey={"Costo instalación"} value={<b>{Precio(data?.costo_instalacion)}</b>} />
                    <AccordionDetailData dataKey={"Costo impuesto"} value={<b>{Precio(data?.monto_impuesto)}</b>} />
                    <AccordionDetailData dataKey={"Vigente hasta"} value={data?.fecha_vigente_hasta ?? 'N/A'} />
                    <AccordionDetailData dataKey={"Fecha creación"} value={data?.created_at.split('T')[0] ?? 'N/A'} />
                    <AccordionDetailData dataKey={"Fecha edición"} value={data?.updated_at.split('T')[0] ?? 'N/A'} last />
                  </div>
                </div>
              </Accordion>
            </div>

            <div className="col mb-1">
              <div className="d-flex justify-content-center w-100 gap-1">
                <span className="tag px-50 mb-50">Tarifa plana: <b>{data?.es_tarifa_plana ? 'Sí' : 'No'}</b></span>
                {!data?.lineas_cotizacion.find(({ linea_oferta }) => linea_oferta?.instalacion_permanente)
                  ? <span className="tag px-50 mb-50">Instalación hasta: <b>{renderRecargoInstalacion(data?.aplicar_instalacion_hasta)}</b></span>
                  : <span className="tag px-50 mb-50">Inst. permanente: <b>{data?.lineas_cotizacion.find(({ linea_oferta }) => linea_oferta?.instalacion_permanente) ? 'Sí' : 'No'}</b></span>
                }
              </div>
            </div>
          </div>
          {/* FIN COLUMNA IZQUIERDA */}

          {/* COLUMNA DERECHA */}
          <div className="col-12 col-md-6">

            {/* EJECUTIVO */}
            <div className="col mb-1">
              <Accordion isOpen={displayAllAccordion} title={<h3 className="m-0 w-100 text-center text-white">Ejecutivo</h3>}>
                <div className="col px-2 align-items-center">
                  <div className="row">
                    <AccordionDetailData
                      dataKey={"Nombre y apellido"}
                      value={
                        <div className="d-flex gap-1 justify-content-center">
                          <span>{data?.ejecutivo?.nombre ?? 'N/A'}</span>
                        </div>
                      }
                    />
                    <AccordionDetailData dataKey={"Teléfono"} value={data?.ejecutivo?.fono ?? 'N/A'} />
                    <AccordionDetailData dataKey={"Email"} value={<span className="mb-0 text-lgray">{data?.ejecutivo?.email ?? 'N/A'}</span>} />
                    <AccordionDetailData dataKey={"RUT"} value={data?.ejecutivo?.rut ?? 'N/A'} last />
                  </div>
                </div>
              </Accordion>
            </div>

            {/* CLIENTE */}
            <div className="col mb-1">
              <Accordion isOpen={displayAllAccordion} title={<h3 className="m-0 w-100 text-center text-white">Cliente</h3>}>
                <div className="col px-2 align-items-center">
                  <div className="row">
                    <AccordionDetailData
                      dataKey={"Nombre y apellido"}
                      value={
                        <div className="d-flex gap-1 justify-content-center">
                          <span>{data?.nombre_cliente ?? 'N/A'}</span>
                          <span className="sub-text-2 text-lgray">({data?.punto_entrega?.comuna?.region?.pais?.nombre ? capitalize(data?.punto_entrega?.comuna?.region?.pais?.nombre) : 'N/A'})</span>
                        </div>
                      }
                    />
                    <AccordionDetailData dataKey={"Tipo cliente"} value={data?.tipo_cliente?.name ?? 'N/A'} />
                    <AccordionDetailData dataKey={"Teléfono"} value={data?.telefono_cliente ?? 'N/A'} />
                    <AccordionDetailData dataKey={"Email"} value={<span className="mb-0 text-lgray">{data?.email_cliente ?? 'N/A'}</span>} />
                    <AccordionDetailData dataKey={"RUT cliente"} value={data?.rut_cliente ?? 'N/A'} />
                    <AccordionDetailData dataKey={"RUT empresa"} value={data?.rut_empresa ?? 'N/A'} last />
                  </div>
                </div>
              </Accordion>
            </div>

            {/* PUNTO ENTREGA */}
            <div className="col mb-1">
              <Accordion isOpen={displayAllAccordion} title={<h3 className="m-0 w-100 text-center text-white">Punto entrega</h3>}>
                <div className="col px-2 align-items-center">
                  <div className="row">
                    <AccordionDetailData dataKey={"Región"} value={data?.punto_entrega?.comuna?.region?.nombre ?? 'N/A'} />
                    <AccordionDetailData dataKey={"Comuna"} value={data?.punto_entrega?.comuna?.nombre ?? 'N/A'} />
                    <AccordionDetailData dataKey={"Tipo destino"} value={data?.punto_entrega?.tipo_destino?.nombre ?? 'N/A'} />
                    <AccordionDetailData dataKey={"Método entrega"} value={data?.punto_entrega?.metodo_entrega?.nombre ?? 'N/A'} />
                    <AccordionDetailData dataKey={"Nombre"} value={data?.punto_entrega?.nombre ?? 'N/A'} />
                    <AccordionDetailData
                      dataKey={"Dirección"}
                      value={
                        <span>
                          {data?.punto_entrega?.nombre + ', ' ?? ''}{data?.punto_entrega?.direccion + ', ' ?? ''} {data?.punto_entrega?.descripcion ?? ''} (<button className="text-blue p-0 bg-white">abrir mapa</button>)
                        </span>
                      }
                      last
                    />
                  </div>
                </div>
              </Accordion>
            </div>

            {/* OBSERVACIONES */}
            <div className="col mb-1">
              <Accordion title={<h3 className="m-0 w-100 text-center text-white">Observaciones</h3>} isOpen={displayAllAccordion}>
                <p className="m-0 px-1">{data?.observaciones ?? 'Sin observaciones'}</p>
              </Accordion>
            </div>
          </div>
          {/* FIN COLUMNA DERECHA */}

          {/* PRODUCTOS */}
          {addProductos
            ? <div className="col-12" ref={add}>
              <ProductosFiltros
                isAsignacionMasiva={true}
                isCotizaciones={true}
                firstQuery={false}
                puntoEntrega={data?.puntoentrega_id ?? null}
                isOpen
              />
              <div className="mb-2"></div>

              <div className="row">
                {/* PRODUCTOS ENCONTRADOS */}
                <AccordionTable
                  title={`Productos encontrados (${productosEncontrados?.length ?? 0} encontrados / ${filteredProductosEncontrados?.length ?? 0} filtrados)`}
                  classes='col-12 col-md-6 mb-2'
                >
                  <div className={`card-datatable table-responsive pt-0 custom-scrollbar flex-shrink-0 dataTable custom-scrollbar overflow-auto`}>
                    <table className="position-relative user-list-table table no-footer dtr-column z-10">
                      <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                        <tr role="row">
                          <th className="col-9">
                            <div className='d-flex gap-1'>
                              <div className="d-flex flex-column">
                                <p className="mb-25">Stock</p>
                                <CheckBoxInput
                                  labelText=''
                                  value={filteredProductosStockDisponible}
                                  handleValue={() => setFilteredProductosStockDisponible(!filteredProductosStockDisponible)}
                                  classes='mx-auto'
                                />
                              </div>
                              <div className="d-flex flex-column">
                                <p className="mb-25">Precio ({orderProductosPrecio ? 'DESC' : 'ASC'})</p>
                                <CheckBoxInput
                                  labelText=''
                                  value={orderProductosPrecio}
                                  handleValue={() => setOrderProductosPrecio(!orderProductosPrecio)}
                                  classes='mx-auto'
                                />
                              </div>
                              <FormInput
                                value={nombreInputProductoEncontrado}
                                placeholder='Producto'
                                labelText=''
                                handleValue={(e) => setNombreInputProductoEncontrado(e.target.value)}
                                sizeDesk='col'
                                classes='text-capitalize sub-text-3 font-weight-normal'
                                margin='my-auto'
                              />
                            </div>
                          </th>
                          <th className="col-2 px-50">
                            <div className='d-flex'>
                              <FormInput
                                value={skuInputProductoEncontrado}
                                placeholder='SKU'
                                labelText=''
                                handleValue={(e) => setSkuInputProductoEncontrado(e.target.value)}
                                sizeDesk='col'
                                classes='text-capitalize sub-text-3 font-weight-normal'
                                margin='my-auto'
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredProductosEncontrados?.length
                          ? filteredProductosEncontrados?.map((data, i) => {
                            return (
                              <React.Fragment key={`${i}-producto-${data?.id}-${data?.nombre}`}>
                                <tr
                                  className={`odd data-selector ${data.seleccionado ? 'selected' : ''}`}
                                  onClick={handleProductoSeleccionado}
                                  data-name={`${data?.id}%-%${data?.nombre}`}
                                  style={{ backgroundColor: data.filtro_stock ? '#fff' : '#fcdede', borderBottom: 'transparent' }}
                                  key={`tr1-producto-${data?.id}-${data?.nombre}`}
                                >

                                  <td data-name={`${data?.id}%-%${data?.nombre}`}>
                                    <div className="d-flex justify-content-between" data-name={`${data?.id}%-%${data?.nombre}`}>
                                      <div className="d-flex gap-1" data-name={`${data?.id}%-%${data?.nombre}`}>
                                        <div className="d-flex flex-column gap-1">
                                          <span className={`tag fs-7 mx-auto px-25 ${data?.publicado ? 'success' : 'red'}`}>{data?.publicado ? 'Publicado' : 'Despublicado'}</span>

                                          {data?.neu_producto_imagenes?.length
                                            ? <img
                                              className="height-listado border rounded-2 p-50"
                                              data-name={`${data?.id}%-%${data?.nombre}`}
                                              style={{ width: '100px' }}
                                              src={`${BASE_URL}storage/${data.neu_producto_imagenes[0].imagen}`}
                                              alt={data?.nombre} />
                                            : <span className="my-auto border rounded-2 p-50" data-name={`${data?.id}%-%${data?.nombre}`} style={{ width: '100px' }}>Sin imagen</span>
                                          }
                                        </div>

                                        <div className='d-flex flex-column my-auto' data-name={`${data?.id}%-%${data?.nombre}`}>
                                          <span data-name={`${data?.id}%-%${data?.nombre}`}>{data?.neu_categoria?.nombre}</span>
                                          <a href={`/productos/detalle/${data?.id}`} target="_blank" rel="noreferrer" className="rounded-2 border p-25 data-selector">
                                            <b>{data?.nombre.toUpperCase()}</b>
                                          </a>
                                          <span data-name={`${data?.id}%-%${data?.nombre}`}>
                                            {data?.precio_oferta &&
                                              <b data-name={`${data?.id}%-%${data?.nombre}`} className='fs-4'>{Precio(data?.precio_oferta)} - </b>
                                            }
                                            <span data-name={`${data?.id}%-%${data?.nombre}`} className='fw-bold'>{Precio(data?.precio_final)}</span>
                                          </span>
                                          <span data-name={`${data?.id}%-%${data?.nombre}`}>Stock: <b data-name={`${data?.id}%-%${data?.nombre}`}>{data?.stock_propio ?? 'N/A'}</b></span>
                                        </div>
                                      </div>
                                    </div>
                                  </td>

                                  <td className="ps-3" data-name={`${data?.id}%-%${data?.nombre}`}>
                                    <b data-name={`${data?.id}%-%${data?.nombre}`}>{data?.sku}</b>

                                    <div className="d-flex flex-column mt-50" data-name={`${data?.id}%-%${data?.nombre}`}>
                                      <span data-name={`${data?.id}%-%${data?.nombre}`}>Oferta:</span>
                                      <b data-name={`${data?.id}%-%${data?.nombre}`}>{data?.neu_oferta_producto?.[0]?.neu_oferta?.nombre ?? 'N/A'}</b>
                                    </div>
                                  </td>

                                </tr>

                                <tr style={{ borderBottom: '1px solid #041c62' }} key={`tr2-producto-${data?.id}-${data?.nombre}`}>
                                  <td colSpan={2} className="pt-0 px-50" style={{ backgroundColor: data.filtro_stock ? '#fff' : '#fcdede' }} >
                                    <AccordionTable
                                      title={`Stock disponible: ${data.filtro_stock}`}
                                      style={{ backgroundColor: '#eee', color: '#555' }}
                                      isOpen={data.filtro_stock ? true : false}
                                    >
                                      <Table pb={false}>
                                        <thead>
                                          <tr key={`tr3-producto-${data?.id}-${data?.nombre}`}>
                                            <th>Bodega</th>
                                            <th>Qty disponible</th>
                                            <th>Qty Física</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {data?.stock_erp.length
                                            ? data.stock_erp.map(({ bodega, stock_disponible, stock_fisico }) => (
                                              <tr key={`erp-${data?.id}-${bodega}`}>
                                                <td>
                                                  <b>{bodega}</b>
                                                </td>
                                                <td>
                                                  <b>{stock_disponible}</b>
                                                </td>
                                                <td>
                                                  <b>{stock_fisico}</b>
                                                </td>
                                              </tr>
                                            ))
                                            : <tr><td colSpan={3}>No hay disponibilidad</td></tr>
                                          }
                                        </tbody>
                                      </Table>
                                    </AccordionTable>
                                  </td>
                                </tr>
                              </React.Fragment>
                            )
                          })
                          : !productosEncontrados
                            ? <tr>
                              <td colSpan={2}><CustomLoader /></td>
                            </tr>

                            : <tr>
                              <td colSpan={2}><NoResults /></td>
                            </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </AccordionTable>

                {/* PRODUCTOS SELECCIONADOS */}
                <AccordionTable
                  title={`Productos seleccionados(${productosSeleccionados.length})`}
                  classes='col-12 col-md-6 mb-2'
                >
                  <div className={`card-datatable table-responsive pt-0 custom-scrollbar overflow-auto dataTable flex-shrink-0`} style={{ minHeight: '150px' }}>
                    <table className="user-list-table table dataTable no-footer dtr-column z-10 h-100" >
                      <thead className="table-light position-sticky top-0 z-10">
                        <tr role="row">
                          <th className='col'>
                            <div className='d-flex'>
                              <button
                                onClick={() => setProductosSeleccionados([])}
                                className='m-auto bg-warning rounded-2 text-white fw-bolder p-25 px-50 mt-2'
                              >
                                Borrar productos
                              </button>
                              <FormInput
                                name='producto'
                                value={filterProducto}
                                placeholder='Buscar...'
                                labelText='PRODUCTO - SKU'
                                handleValue={handleInputFilterProducto}
                                sizeDesk='col-10'
                                classes='text-capitalize sub-text-3 font-weight-normal'
                                margin='my-auto ms-50'
                              />
                              <div className='mt-2 pt-75'>
                                <Button color={'danger'} icon='Trash' className={'ms-50 my-auto p-25'} onClick={() => setFilterProducto('')} />
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {productosSeleccionadosFiltrados.map((producto, i) => (
                          <React.Fragment key={`${i} -producto - selected - ${producto.id} -${producto.nombre} `}>
                            <tr className="odd multi-producto" style={{ borderBottom: 'transparent' }}>
                              <td className="p-0 pt-1" >
                                <div className="d-flex gap-1">
                                  <div className="d-flex justify-content-center">
                                    <CheckBoxInput
                                      labelText=''
                                      value={producto.seleccionado}
                                      handleValue={handleQuitarProductosSeleccionados}
                                      name={`${producto.id}%-%${producto.nombre} `}
                                      classes='my-auto ms-50'
                                    />
                                  </div>

                                  {producto?.neu_producto_imagenes?.length
                                    ? <img
                                      className="height-listado border rounded-2 p-50"
                                      style={{ width: '100px' }}
                                      src={`${BASE_URL}storage/${producto.neu_producto_imagenes[0].imagen}`}
                                      alt={producto?.nombre}
                                    />
                                    : <span className="my-auto border rounded-2 p-50" style={{ width: '100px' }}>Sin imagen</span>
                                  }

                                  <div className='d-flex flex-column'>
                                    <b>{producto?.nombre?.toUpperCase() ?? 'N/A'}</b>
                                    <span className='sub-text-3 text-lgray'>SKU: <b>{producto?.sku ?? 'N/A'}</b> | Stock: <b>{producto?.stock_propio ?? 'N/A'}</b></span>
                                    <span className='sub-text-3 text-lgray'>Recargo: <b>{renderRecargoInstalacion(producto?.neu_punto_entrega_producto[0]?.recargo)}</b> | Instalación: <b>{renderRecargoInstalacion(producto?.neu_punto_entrega_producto[0]?.precio_instalacion)}</b></span>
                                    <span className='sub-text-3 text-lgray'>Instalación permanente: <b>{producto?.neu_punto_entrega_producto?.[0]?.instalacion_permanente ? 'Sí' : 'No' ?? 'N/A'}</b></span>
                                  </div>

                                  <div className="d-flex flex-column justify-content-center text-center m-auto me-2 p-1 border rounded-3"
                                    style={{ backgroundColor: validateOferta(producto) ? '#7cfc9e' : '#e8edea' }}
                                  >
                                    <p>Oferta:</p>
                                    <b>{producto?.neu_oferta_producto?.[0]?.neu_oferta?.nombre ?? 'N/A'}</b>
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={5} style={{ borderBottom: '1px solid #041c62' }}>
                                <div className="d-flex justify-content-between gap-1">
                                  <div className="d-flex flex-column">

                                    <div className="text-center">
                                      <span>Cantidad</span>
                                    </div>

                                    <div className='d-flex fs-2' style={{ width: '180px' }}>
                                      <Button icon='Minus' className='m-auto' onClick={() => handleCounterButtonProductoSeleccionado(producto, 'minus')} />
                                      <FormInput
                                        type='number'
                                        value={producto.cantidad ?? undefined}
                                        margin='my-auto'
                                        classes='d-flex'
                                        size='col-4'
                                        handleValue={(e) => handleInputProductoSeleccionado(e, producto, 'cantidad')}
                                      />
                                      <Button icon='Plus' className='m-auto' onClick={() => handleCounterButtonProductoSeleccionado(producto, 'plus')} />
                                    </div>
                                  </div>

                                  <div className="col-2">
                                    <p className="mb-25">Precio unit.</p>
                                    <FormInput
                                      type='number'
                                      value={producto.precio_unitario ?? undefined}
                                      margin='my-auto'
                                      classes='d-flex'
                                      handleValue={(e) => handleInputProductoSeleccionado(e, producto, 'precio_unitario')}
                                    />
                                  </div>

                                  <div>
                                    <p className="mb-25">Total</p>
                                    <b>{Precio(producto.precio_unitario * producto.cantidad)}</b>
                                  </div>

                                  <div>
                                    <p className="mb-25">Instalación</p>
                                    {data?.es_tarifa_plana
                                      ? <b>Tarifa plana</b>
                                      : <b>{renderRecargoInstalacion(producto?.neu_punto_entrega_producto[0]?.precio_instalacion, producto.cantidad)}</b>
                                    }
                                  </div>

                                  <div>
                                    <p className="mb-25">Recargo</p>
                                    <b>{renderRecargoInstalacion(producto?.neu_punto_entrega_producto[0]?.recargo, producto.cantidad)}</b>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </AccordionTable>
              </div>

              <div className="d-flex justify-content-center gap-3">
                <div className="d-flex">
                  <Button isDisabled={isConsulting} className='mx-auto w-fit' text='Guardar productos' icon='Save' onClick={(e) => { handleAddProductos(e) }} />
                </div>
                <div className="d-flex">
                  <Button className='mx-auto w-fit' text='Cancelar' color='danger' icon='X' onClick={() => setAddProductos(false)} />
                </div>
              </div>
            </div>

            :
            <>
              <AccordionTable isOpen={displayAllAccordion} title="Productos" classes='mb-2'>
                <Table pb={false} style={{ overflow: 'visible' }}>
                  <thead className="table-light">
                    <tr role="row">
                      <th>ID</th>
                      <th>Descripción</th>
                      <th>Datos</th>
                      <th>P. unit.</th>
                      <th>P. subtotal</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.lineas_cotizacion.length
                      ? data?.lineas_cotizacion.map(({ producto, precios_mostrar, cantidad_cotizada, subtotal, linea_oferta, recargo, subtotal_neto, precio_instalacion, edicion, id }, i, arr) => {
                        return (
                          <tr key={`${producto?.id}-${producto?.nombre}`}>
                            <td className="position-relative">
                              {linea_oferta?.instalacion_permanente === 1
                                ? <span className="position-absolute h-100 top-0 left-0 text-black" style={{ backgroundColor: '#b6fcc9', width: '20px', writingMode: 'vertical-rl', textOrientation: 'mixed' }}>INST. PERMA.</span>
                                : <></>
                              }
                              <b>{id}</b>
                            </td>

                            <td>
                              <a href={`/productos/detalle/${producto?.id}`} rel="noreferrer" target="_blank">
                                <b>{producto?.nombre.toUpperCase() ?? 'N/A'}</b>
                              </a>
                            </td>

                            <td>
                              <div className="d-flex flex-column w-max">
                                {edicion
                                  ?
                                  <FormInput
                                    labelText='QTY'
                                    name='editLineaForm%-%cantidad_cotizada'
                                    value={editLineaForm.cantidad_cotizada}
                                    handleValue={handleInputChange}
                                  />

                                  :
                                  <span>QTY: <b>{cantidad_cotizada ?? "N/A"}</b></span>
                                }

                                <span>SKU: <b>{producto?.sku ?? "N/A"}</b></span>
                                <span>Stock: <b>{producto?.neu_stock_propio?.[0]?.stock ?? "N/A"}</b></span>
                              </div>
                            </td>

                            <td>
                              <div className="d-flex flex-column">

                                {edicion

                                  ?
                                  <FormInput
                                    labelText='Precio unit.'
                                    name='editLineaForm%-%precio_unidad'
                                    value={editLineaForm.precio_unidad}
                                    handleValue={handleInputChange}
                                  />

                                  :
                                  <span className="fw-bolder fs-3 text-black">
                                    {Precio(precios_mostrar?.precio_unitario)}
                                  </span>
                                }


                                <span className="text-black" style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                  Neto: {Precio(precios_mostrar?.precio_unitario_neto)}
                                </span>

                                <span style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                  Inst.: {renderRecargoInstalacion(precio_instalacion)}
                                </span>

                                <span style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                  Recargo: {renderRecargoInstalacion(recargo)}
                                </span>
                              </div>
                            </td>

                            <td>
                              <div className="d-flex flex-column">

                                <span className="fw-bolder fs-3 text-black">
                                  {Precio(subtotal)}
                                </span>

                                <span className="text-black" style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                  Neto: {Precio(subtotal_neto)}
                                </span>

                                <span style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                  Inst.: {precio_instalacion === null
                                    ? renderRecargoInstalacion(precio_instalacion)
                                    : renderRecargoInstalacion(precio_instalacion * cantidad_cotizada)
                                  }
                                </span>

                                <span style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                  Recargo: {recargo === null
                                    ? renderRecargoInstalacion(recargo)
                                    : renderRecargoInstalacion(recargo * cantidad_cotizada)
                                  }
                                </span>
                              </div>
                            </td>

                            <td>
                              {edicion
                                ?
                                <div className='d-flex gap-1'>
                                  <Button isDisabled={isConsulting} icon='Check' color='success' className='p-25' onClick={() => handleFetch(arr[i], 'update')} />
                                  <Button icon='X' color='danger' className='p-25' onClick={() => handleEditForm(arr[i], false)} />
                                </div>

                                :
                                <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >

                                  {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
                                    <DropdownMenuLink text='Editar' onClick={() => handleEditForm(arr[i])} icon="Settings" />
                                  }

                                  {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
                                    <DropdownMenuLink
                                      text='Borrar'
                                      icon="X"
                                      onClick={() => handleFetch(arr[i], 'delete')}
                                    />
                                  }
                                </DropdownWrapper>
                              }
                            </td>
                          </tr>
                        )
                      })
                      : <tr><td><NoResults /></td></tr>
                    }
                  </tbody>
                </Table>
              </AccordionTable>

              <TablaDetalle historicoData={historico} fnHandleModal={handleModalHistorico} />
            </>
          }


        </div>

        : <CustomLoader />
      }
    </>
  );
};

export default Detalles;

import { useEffect, useState } from "react";
import axios from "axios";

// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import SelectFilter from "../../components/SelectFilter";
import FileUploaderB from "../../components/FileUploader_B";
import CustomLoader from "../../components/CustomLoader";
import TextareaInput from "../../components/TextareaInput";


// React-router
import { useNavigate } from "react-router-dom";


// Enpoints
import { getAll } from "../../endpoints/getAll";
import { create } from "../../endpoints/create";


// Helpers
import { hourFormatter } from "../../helpers/hourFormatter";


const CONSTANT = {
  title: 'Etiquetas',
  componentTitle: 'Crear etiqueta',
  redirectUrl: '/etiquetas',
  queryUrl: create.etiquetas,
  queryGetAllOfertas: getAll.ofertas,
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  initialFormState: {
    oferta_id: undefined,
    nombre: undefined,
    descripcion: undefined,
    prioridad: undefined,
    fecha_inicio: undefined,
    hora_inicio: undefined,
    fecha_final: undefined,
    hora_final: undefined,
    estatus: 1,
  },
  selectFilterInitialState: {
    ofertas: [],
  }
}


const handleSelectFiltersData = async () => {
  const ofertas = await axios(CONSTANT.queryGetAllOfertas,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data.ofertas] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { ofertas }
}


const Create = () => {
  const [imagen, setImagen] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [form, setForm] = useState(CONSTANT.initialFormState);


  // MANEJADOR DE LOS INPUTS
  const dataFiller = (e) => {
    const { value, name } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    let requestData = new FormData()
    requestData.append('nombre', form?.nombre)
    form?.descripcion?.length && requestData.append('descripcion', form?.descripcion)
    requestData.append('prioridad', form?.prioridad)
    requestData.append('imagen', imagen[0]?.file)
    form?.oferta_id?.length && !form.oferta_id.includes('*NULL*') && requestData.append('oferta_id', form?.oferta_id?.split('-')[0])
    requestData.append('fecha_hora_inicio', `${form?.fecha_inicio} ${hourFormatter(form?.hora_inicio)}`)
    requestData.append('fecha_hora_final', `${form?.fecha_final} ${hourFormatter(form?.hora_final)}`)
    requestData.append('estatus', Number(form?.estatus))

    axios(CONSTANT.queryUrl,
      {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
          'Content-Type': 'multipart/form-data'
        },
        data: requestData
      })
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${res.data.nombre} creada con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {selectFilter.ofertas
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              {/* <Switch
                name="estatus"
                value={form?.estatus}
                labelText="Estatus"
                handleValue={dataFiller}
                size="col-12"
              /> */}

              <SelectFilter
                labelText="Oferta"
                name="oferta_id"
                value={form?.oferta_id?.split('-')[1] ?? 'Seleccione'}
                handleValue={dataFiller}
                optionObj={selectFilter.ofertas?.map(({ nombre, id }) => ({ name: nombre, id }))}
                size="col-12"
                sizeDesk="col-md-5"
              />

              <FormInput
                labelText="* Nombre"
                name='nombre'
                placeholder='Nombre...'
                handleValue={dataFiller}
                value={form?.nombre}
                size="col-12"
                sizeDesk="col-md-5"
              />

              <FormInput
                labelText="* Prioridad"
                placeholder='Núm...'
                type="number"
                name='prioridad'
                handleValue={dataFiller}
                value={form?.prioridad}
                size="col-12"
                sizeDesk="col-md-2"
              />

              <TextareaInput
                labelText="Descripción"
                placeholder='Descripción...'
                name='descripcion'
                handleValue={dataFiller}
                value={form?.descripcion}
                size="col-12"
                sizeDesk="col-md-12"
                rows={2}
              />

              <FormInput
                labelText="* Fecha inicio"
                type="date"
                handleValue={dataFiller}
                max={form?.fecha_final}
                name='fecha_inicio'
                value={form?.fecha_inicio}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Hora inicio"
                type="time"
                name='hora_inicio'
                handleValue={dataFiller}
                value={form?.hora_inicio}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Fecha final"
                type="date"
                min={form?.fecha_inicio}
                name='fecha_final'
                handleValue={dataFiller}
                value={form?.fecha_final}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                labelText="* Hora final"
                type="time"
                name='hora_final'
                handleValue={dataFiller}
                value={form?.hora_final}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FileUploaderB labelText='* Imagen' handleValue={setImagen} value={imagen} />
            </div>

            <Button type="submit" text="Crear" color="primary" icon="Table" onClick={(e) => handleFetch(e)} />
          </CustomForm>

          : <CustomLoader />
        }
      </div>
    </div>
  );
};

export default Create;

import axios from "axios"
import { create } from "../../../endpoints/create"

const URL = create.imagenes_productos


export const addImgProducto = async ({ id, images = [] }) => {
  try {
    if (!id) return console.error(new Error(`Error: Falta el id`))
    let dataForm = new FormData()
    images?.length && images.forEach(({ file }) => dataForm.append('imagenes[]', file))
    dataForm.append('estatus', 1)
    dataForm.append('producto_id', Number(id))


    const response = await axios(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      data: dataForm
    })

    if (!response.status) throw response

    return response
  } catch (err) {
    console.error(err)
    return err
  }
}
import axios from "axios"
import { getAll } from "../../../endpoints/getAll"

const URL = getAll.puntos_entrega_productos + '/importar-actualizacion'


export const updateExcel = async (files) => {
  let toasterContent = {
    title: 'Error - Vacío',
    icon: 'info'
  }
  
  try {
    if (!files || !files?.length) throw Error({ title: 'Debe seleccionar un archivo tipo excel', icon: 'error' })

    const validFormData = new FormData()
    validFormData.append('excel', files[0].file)

    return await axios(URL,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
          'Content-Type': 'multipart/form-data'
        },
        method: 'POST',
        data: validFormData
      })
      .then(({ data }) => {
        toasterContent = {
          title: 'Operación realizada',
          text: `Actualización exitosa`,
          icon: 'success'
        }
        return toasterContent
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        toasterContent = {
          title: `
            Error en la actualización
          (${err.response.status} - ${err.response.statusText})
        `,
          html: `
          <b>Detalle: </b>
          ${detalles.map(error => `<br /><i>-${error}</i>`)}
        `,
          icon: 'error'
        }

        throw toasterContent
      })

  } catch (error) {
    return error
  }
}
import axios from 'axios'
import { useEffect, useState } from 'react'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import Switch from '../../components/Switch'
import CustomLoader from '../../components/CustomLoader'
import { ModalHistorico } from '../../components/Historico/ModalHistorico'
import TablaDetalle from '../../components/Historico/TablaDetalle'


// Router Dom
import { useNavigate, useParams } from 'react-router-dom'


// Endpoints
import { getOne } from '../../endpoints/getOne'
import { historicoData } from '../../helpers/historicoData'
import { getAll } from '../../endpoints/getAll'


const CONSTANT = {
  queryUrlGetOne: getOne.cinta_informativa,
  title: 'Cinta informativa',
  redirectListado: '/template/cinta-informativa',
  modelo: 'App\\Models\\TemplateCintaInformativa',
  redirectUpdate: (id) => `/template/cinta-informativa/update/${id}`,
  fetchFrontendId: getAll.frontend_id,

  selectFilterInitialState: {
    frontendId: [],
  }
}


const Detalle = () => {
  const [data, setData] = useState(null)
  const { id } = useParams()
  const dispatch = useDispatch()
  const [historico, setHistorico] = useState(null)
  const [modal, setModal] = useState({ id: null, display: false, data: null })
  const redirect = useNavigate()

  useEffect(() => {
    axios(CONSTANT.queryUrlGetOne(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        }
      })
      .then(({ data }) => setData(data))
      .catch(err => {
        console.error(err)
        const toasterContent = {
          title: 'Registro no encontrado',
          icon: 'warning'
        }

        dispatch(fireToaster(toasterContent))
        redirect(CONSTANT.redirectListado)
      })

    historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))

    return () => {
      setData(null)
    }
  }, [id, dispatch, redirect])


  const handleFetch = async (objData) => {
    let newEstatus = objData.estatus ? 0 : 1

    let data = { estatus: newEstatus, _method: 'PUT' }

    await axios(CONSTANT.queryUrlGetOne(objData.id),
      {
        method: 'PUT',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
          'Content-Type': 'application/json',
        },
        data
      })
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `Cinta informativa: ${res.data.texto} - estatus actualizado con éxito`,
          icon: 'success'
        }

        dispatch(fireToaster(toasterContent))
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
      })
      .finally(async () => {
        await axios(CONSTANT.queryUrlGetOne(id),
          {
            headers: {
              'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
            },
          })
          .then(({ data }) => {
            setData(data)
            historicoData(Number(id), CONSTANT.modelo).then(data => setHistorico(data))
          })
          .catch(err => {
            console.error(err)
            const toasterContent = {
              title: 'Registro no encontrado',
              icon: 'warning'
            }

            dispatch(fireToaster(toasterContent))
            redirect(CONSTANT.redirectListado)
          })
      })

  }


  // FUNCION MANEJADORA DE LA DATA DEL MODAL
  const handleModal = (data) => {
    const { id, anterior, nuevo } = data

    setModal({
      display: true,
      id,
      data: {
        anterior,
        nuevo
      }
    })
  }

console.log(data);
  return (
    <div className='row'>

      <ModalHistorico modal={modal} fnHandleModal={setModal} />

      <div className='col-12'>
        <div className='col mb-50'>
          <div className='d-flex gap-1'>
            <div className='col'>
              <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado} />
            </div>
            <Button text='Editar' icon='Settings' color='success' isLink url={CONSTANT.redirectUpdate(id)} />
            <Button text='Borrar' icon='Trash' color='danger' onClick={() => handleFetch(data, 'delete')} />
          </div>
        </div>

        {data
          ? <>
            <Accordion
              title={<h3 className='m-0 w-100 text-center text-white'>Datos generales</h3>}
              classes='col-md-6 mx-auto mb-2'
              isOpen
            >
              <AccordionDetailData dataKey={'ID'} value={data?.id} />
              <AccordionDetailData dataKey={'Texto'} value={data?.texto ?? 'N/A'} />
              <AccordionDetailData dataKey={'Frontend'} value={data?.frontend?.nombre ?? 'chileneumáticos'} />
              <AccordionDetailData dataKey={'Creación'} value={data?.created_at.split('T')[0]} />
              <AccordionDetailData dataKey={'Estatus'} value={
                <Switch
                  value={data?.estatus}
                  labelText={""}
                  margin='my-0'
                  handleValue={() => handleFetch(data)}
                />
              } />
              <AccordionDetailData dataKey={'URL de redirección'} value={data?.url_redireccion ?? 'N/A'} />
              <AccordionDetailData dataKey={'Ícono'} value={data?.icono ?? 'N/A'} />
              <AccordionDetailData dataKey={'Fecha-Hora inicio/final'} value={
                <span>
                  {data?.fecha_hora_inicio ?? "N/A"} / {data?.fecha_hora_final ?? "N/A"}
                </span>
              } />
              <AccordionDetailData dataKey={'Modicación'} value={data?.updated_at.split('T')[0] ?? 'N/A'} last />
            </Accordion>

            <TablaDetalle historicoData={historico} fnHandleModal={handleModal} />
          </>

          : <CustomLoader />
        }
      </div>
    </div>
  )
}

export default Detalle
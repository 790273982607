import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   FILTER_TABLA,
   RESULTADOS_COMPONENTES,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const tablaFilter = (data) => ({ type: FILTER_TABLA, payload: data })

export const resultadosComponentes = (data) => ({ type: RESULTADOS_COMPONENTES, payload: data })

export const clearFilterComponentes = () => ({ type: FILTER_CLEAR });

import { Navigate, Route, Routes } from "react-router-dom"
import Listado from "../../views/PasosCompra/Listado"
import Create from "../../views/PasosCompra/Create"
import Update from "../../views/PasosCompra/Update"
import Detalle from "../../views/PasosCompra/Detalle"
import { useSelector } from "react-redux"



const PasosCompraRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['pasos-compra.crear'] &&
            <Route path='/create' element={<Create />} />
         }

         {user.permisos['pasos-compra.editar'] &&
            <Route path='/update/:id' element={<Update />} />
         }

         {user.permisos['pasos-compra.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}

export default PasosCompraRouter
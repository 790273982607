import { useEffect, useState } from "react";
import axios from "axios";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import SelectFilter from "../../components/SelectFilter";


// Hooks / Helpers


// React-router
import { useNavigate } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";
import { getAll } from "../../endpoints/getAll";
import CustomLoader from "../../components/CustomLoader";


const CONSTANT = {
  redirectUrl: '/proveedores',
  componentTitle: 'Crear categoría - proveedor',
  title: 'Categoría - Proveedor',
  queryUrl: create.proveedores_categorias,
  queryFetchProveedores: getAll.proveedores,
  queryFetchCategorias: getAll.categorias + '/buscar'
}


const initialformState = {
  proveedor_id: '',
  categorias: [],
};

const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetchedProveedores, setFetchedProveedores] = useState([])
  const [fetchedCategorias, setFetchedCategorias] = useState([])
  const [form, setForm] = useState(initialformState);


  // FUNCIÓN MANEJADORA DEL STATE 'form' 
  const handleInputChange = (e) => {
    const { name, value } = e.target
    const isListed = form.categorias.find(el => el.id === value)

    if (isListed) return

    if (name === 'categorias' && !isListed) {
      setForm({
        ...form,
        categorias: [
          ...form.categorias,
          {
            id: value,
            estatus: true
          }
        ]
      })
      return
    }

    else {
      setForm({ ...form, [name]: value })
    }
  }


  // FUNCIÓN MANEJADORA QUE ELIMINA ELEMENTOS EL ARREGLO DE CATEGORIAS 
  const handleRemoveCategoria = (e) => {
    e.preventDefault()
    const { name } = e.target.dataset
    const isListed = form.categorias.find(el => el.id === name)

    if (isListed) {
      setForm({
        ...form,
        categorias: form.categorias.filter(el => el.id !== name),
      })
      return
    }
  }


  // FUNCIÓN MANEJADORA DEL CAMPO ESTATUS DEL ELEMENTO SELECCIONADO POR EL SWITCH DEL FORMULARIO
  const handleEstatusChange = (e) => {
    const { name } = e.target

    if (name.includes('estatus')) {
      const [objName] = name?.split('%-%')
      const selectedItemIndex = form.categorias.findIndex(el => el.id === objName)
      setForm({
        ...form,
        categorias: form.categorias.map(({ id, estatus }, i) => (
          {
            id,
            estatus: i !== selectedItemIndex ? estatus : !estatus
          }
        ))
      })
      return
    }
  }

  // EEFECTO QUE RELLENA EL SELECTFILTER 'fetchedProveedores' y 'fetchedCategorias'
  useEffect(() => {
    axios(CONSTANT.queryFetchProveedores,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => setFetchedProveedores(data))
      .catch(err => console.error(err))

    axios(CONSTANT.queryFetchCategorias,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        }, method: 'POST'
      })
      .then(({ data }) => setFetchedCategorias(data.categorias))
      .catch(err => console.error(err))
  }, [])


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    let data = {
      proveedor_id: Number(form.proveedor_id.split('-')[0]),
      categorias: form.categorias.map(({ id, estatus }) => ({ id: Number(id.split('-')[0]), estatus }))
    }

    axios(CONSTANT.queryUrl, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'application/json'
      },
      data
    })
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: Categoría creada con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      < div className="col col-md-6 mx-auto" >
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>
        <CustomForm size="" title={CONSTANT.componentTitle}>
          {fetchedCategorias && fetchedProveedores
            ? <div className="row">
              <SelectFilter
                labelText='Proveedor'
                name='proveedor_id'
                placeholder="Selecciona un proveedor"
                value={form.proveedor_id.split('-')[1]}
                handleValue={handleInputChange}
                optionObj={fetchedProveedores?.map(({ nombre, id }) => ({ id, name: nombre }))}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <SelectFilter
                labelText='Categorías'
                name='categorias'
                placeholder="Selecciona categorías"
                value={"Selecciona categorías"}
                handleValue={handleInputChange}
                size='col-12'
                optionObj={fetchedCategorias?.map(({ nombre, id }) => ({ id, name: nombre }))}
                sizeDesk='col-md-12'
              />

              <b className="mb-0 mt-1">Categorias: </b>

              {form.categorias.map(({ id, estatus }) => (
                <div
                  className="d-flex"
                  key={id}
                >
                  <button
                    className="btn btn-danger p-25 py-0 m-auto d-flex me-50"
                    data-name={id}
                    onClick={handleRemoveCategoria}>
                    x
                  </button>

                  <Switch
                    labelText={`${id.split('-')[1]}`}
                    name={`${id}%-%estatus`}
                    value={estatus}
                    handleValue={handleEstatusChange}
                    size='col-12'
                    sizeDesk='col-md-12'
                    isList
                  />
                </div>
              ))}
            </div>
            : <CustomLoader />
          }
          <Button className='mt-1' type="submit" text="Crear" color="primary" icon="Plus" onClick={(e) => handleFetch(e)} />
        </CustomForm>
      </div >
    </div >
  );
};

export default Create;

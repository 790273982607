import axios from 'axios'
import React, { useEffect, useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { busquedaFilter, clearFilterCintaInformativa, creacionDesdeFilter, creacionHastaFilter, estatusFilter, frontendFilter, resultadosCintaInformativa, textoFilter } from '../../redux/actions/cintaInformativa'
import { FILTER_ADD_ESTATUS, FILTER_ADD_FRONTEND, FILTER_ADD_TEXTO, FILTER_BUSQUEDA, FILTER_CREACION_DESDE, FILTER_CREACION_HASTA } from '../../redux/redux-types'


// Components
import FormInput from '../FormInput'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import SelectFilter from '../SelectFilter'
import { lastPosition } from '../../helpers/lastPosition'
import FiltroAplicado from './FiltroAplicado'
import { idExtractor } from '../../helpers/idExtractor'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'


const CONSTANT = {
	nullObject: { id: '*NULL*', nombre: '- Error -' },
	unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
	fetchFrontendId: getAll.frontend_id,

	selectFilterInitialState: {
		frontendId: [],
	}
}


export const dataFormatter = (obj) => {
	let data = {}
	Object.entries(obj).forEach(([k, v]) => {
		if (['frontend_id'].includes(k) && v.length) {
			return data = {
				...data,
				[k]: v.map(str => idExtractor(str))
			}
		}
	})
	return data
}


const fetchSelectFiltersData = async () => {
	const frontendId = await axios(CONSTANT.fetchFrontendId,
		{
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
		.then(({ data }) => [...data] ?? [CONSTANT.unselectedObject])
		.catch(err => {
			console.error(err)
			return [CONSTANT.nullObject]
		})

	return { frontendId }
}

const CintaInformativaFiltros = () => {
	const { filtros } = useSelector(state => state.cintaInformativa)
	const { showing_results } = useSelector(state => state.listedResults)
	const dispatch = useDispatch()
	const { busqueda, frontend_id } = filtros
	const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)

	const [isConsulting, setIsConsulting] = useState(false)


	// EFECTO QUE SETEA LOS VALORES DE LOS SELECTFILTERS Y FECHA DESDE
	useEffect(() => {
		fetchSelectFiltersData().then(data => setSelectFilter(selectFilter => ({ ...selectFilter, ...data })))
	}, [dispatch])


	useEffect(() => {
		axios(getAll.cinta_informativa + `?limit=${showing_results}`, {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
			.then(({ data }) => dispatch(resultadosCintaInformativa(data)))
			.catch(err => console.error(err))
	}, [dispatch, showing_results])


	useEffect(() => {
		return () => dispatch(clearFilterCintaInformativa())
	}, [dispatch])


	const handleFetchFilter = (reset = false) => {
		if (reset) {
			axios(getAll.cinta_informativa + `?limit=${showing_results}`, {
				headers: {
					'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
				},
			})
				.then(({ data }) => dispatch(resultadosCintaInformativa(data)))
				.catch(err => console.error(err))
		}

		setIsConsulting(true)

		axios(getAll.cinta_informativa + `?limit=${showing_results}` + queryParamsFormatter({ busqueda }), {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
			.then(res => {
				dispatch(resultadosCintaInformativa(res.data))
				dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
			})
			.catch(err => {
				console.error(err);
				const { errores } = err.response.data
				let detalles = []
				Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

				const toasterContent = {
					title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
					html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
					icon: 'error'
				}
				dispatch(fireToaster(toasterContent))
			})
			.finally(() => {
				setIsConsulting(false)
			})
	}

	// MANEJADORES DE FILTRO - ADICIONALES
	const handleAddFilter = (e, filter) => {
		const { value } = e.target

		switch (filter) {
			case FILTER_BUSQUEDA:
				dispatch(busquedaFilter(value))
				break;

			case FILTER_ADD_FRONTEND:
				dispatch(frontendFilter(value))
				break;

			case FILTER_ADD_ESTATUS:
				dispatch(estatusFilter(value))
				break;

			case FILTER_ADD_TEXTO:
				dispatch(textoFilter(value.split('-')[1]))
				break;

			case FILTER_CREACION_DESDE:
				dispatch(creacionDesdeFilter(value))
				break;

			case FILTER_CREACION_HASTA:
				dispatch(creacionHastaFilter(value))
				break;

			default:
				break;
		}
	}


	return (
		<div className="card mb-50 shadow-none bg-transparent">
			<ListadoHeader
				classes='border rounded-2'
				title={`Búsqueda de cinta informativa (${filtrosAplicadosTotal(filtros)})`}
				handleDisable={isConsulting}
				handleClickSearch={() => handleFetchFilter()}
				handleClickClearFilter={() => handleFetchFilter(true)}
			>
				<>
					<FormInput
						labelText='Búsqueda'
						placeholder='Ingrese su búsqueda'
						size='col-12'
						sizeDesk='col-md-4'
						handleValue={(e) => handleAddFilter(e, FILTER_BUSQUEDA)}
						value={busqueda}
					/>

					{/* <SelectFilter
						labelText='Frontend'
						size='col-12'
						sizeDesk='col-md-2'
						handleValue={(e) => handleAddFilter(e, FILTER_ADD_FRONTEND)}
						optionObj={selectFilter.frontendId.map(({ id, nombre }) => ({ id, name: nombre }))}
						value={lastPosition(frontend_id)}
					/> */}
				</>

				{/* <p className='mb-25 text-black'>Filtros aplicados</p>
				<div className='row'>
					<FiltroAplicado array={frontend_id} func={frontendFilter} title={'Frontends'} />
				</div> */}

				<hr className='my-1' />

			</ListadoHeader>
		</div>
	)
}

export default CintaInformativaFiltros
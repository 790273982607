import {
   FILTER_CREACION,
   FILTER_ADD_OFERTA,
   FILTER_REMOVE_OFERTA,
   FILTER_ADD_ESTATUS,
   FILTER_FECHA_FINAL,
   FILTER_FECHA_INICIO,
   FILTER_REMOVE_ESTATUS,
   FILTER_CLEAR,
   FILTER_NOMBRE,
   RESULTADOS_OFERTAS
} from "../redux-types";

const initialState = {
   filtros: {
      nombre: '',
      estatus: '',
      fecha_hora_inicio: '',
      fecha_hora_final: ''
   },
   resultados: null
}


export const ofertasReducer = (state = initialState, action) => {

   switch (action.type) {
      case FILTER_NOMBRE:
         return {
            ...state, filtros: {
               ...state.filtros,
               nombre: action.payload
            }
         };

      case FILTER_ADD_ESTATUS:
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus: action.payload
            }
         };

      case FILTER_FECHA_INICIO:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_hora_inicio: action.payload
            }
         };

      case FILTER_FECHA_FINAL:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_hora_final: action.payload
            }
         };

      case RESULTADOS_OFERTAS:
         return {
            ...state,
            resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};
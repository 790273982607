import {
   FILTER_ADD_EVENTO,
   FILTER_ADD_USUARIO,
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   FILTER_MODELO,
   FILTER_MODELO_ID,
   FILTER_REMOVE_EVENTO,
   FILTER_REMOVE_USUARIO,
   RESULTADOS_HISTORIAL,
} from "../redux-types";

export const usuarioFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_USUARIO, payload: data }
      : { type: FILTER_ADD_USUARIO, payload: data }

export const eventoFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_EVENTO, payload: data }
      : { type: FILTER_ADD_EVENTO, payload: data }

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const modeloFilter = (data) => ({ type: FILTER_MODELO, payload: data })

export const modeloIdFilter = (data) => ({ type: FILTER_MODELO_ID, payload: data })

export const resultadosHistorial = (data) => ({ type: RESULTADOS_HISTORIAL, payload: data })

export const clearFilterHistorial = () => ({ type: FILTER_CLEAR });

import { Route, Routes } from "react-router-dom"
import Create from "../../views/Categorias/Create";
import Update from "../../views/Categorias/Update";
import Detalle from "../../views/Categorias/Detalle";
import Listado from "../../views/Categorias/Listado";
import { useSelector } from "react-redux";



const CategoriasRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['categorias.crear'] &&
            <Route path='/create' element={<Create />} />
         }

         {user.permisos['categorias.editar'] &&
            <Route path='/update/:id' element={<Update />} />
         }

         {user.permisos['categorias.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }
      </Routes>
   )
}

export default CategoriasRouter;
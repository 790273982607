
// Components
import RolesFiltros from "../../components/AccordionFilter/Roles";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import RolesTable from "../../components/Tables/RolesTable";

const CONSTANT = {
	title: 'Roles',
	path: 'roles',
	create_btn: 'Crear rol'
}

const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<RolesFiltros />

			<RolesTable />
		</div>
	);
}

export default Listado;
import { useEffect, useState } from "react"
import FormInput from "./FormInput"

const classOpened = 'select2 select2-container form-select select2-container--default select2-container--below select-filter-title opened';
const classClosed = 'select2 select2-container form-select select2-container--default select2-container--below select-filter-title'

// Mock options
const selectOptions = [
  { id: 1, name: 'Sin datos' },
]

const SelectFilter = ({
  optionObj = selectOptions, labelText, classes,
  size, sizeTablet, sizeDesk, name, altSeparator,
  placeholder = 'Selecciona...', handleValue, value, fatherStyle = {}
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [search, setSearch] = useState('')
  const [renderOptions, setRenderOptions] = useState(options)
  const [currentValue, setCurrentValue] = useState(value)

  const handleSelectValue = (e) => {
    const { value } = e.target.dataset
    const synthEvent = {
      target: {
        name,
        value
      }
    }

    value
      ? handleValue(synthEvent)
      : handleValue({
        target: {
          name,
          value: ''
        }
      });
  }

  const handleInputSearch = (e) => {
    setSearch(e.target.value)
  }


  // Efecto que filtra a traves del input
  useEffect(() => {
    setRenderOptions([...options.filter(opt => opt.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))])
  }, [search, options])


  useEffect(() => {
    setOptions(optionObj)
    setCurrentValue(value === '' ? placeholder : value);
  }, [optionObj, value, placeholder])


  return (
    <div className={`${size || 'col'} ${sizeDesk || 'col-md-6'} ${classes ?? 'mb-2'}`}
      // onClick={() => setIsOpen(!isOpen)}
      onClick={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      style={{...fatherStyle}}
    >
      {labelText &&
        <label className="form-label">{labelText}</label>
      }

      <div>
        <div className="position-relative form-outline select-filter-display">
          <span className={`${currentValue === placeholder ? 'synth-placeholder' : 'text-black'} d-flex ${isOpen ? classOpened : classClosed} form-control-lg`}>
            <p className="m-0 align-self-center overflow-hidden">{currentValue}</p>
          </span>
          <div>
            <span className={`select2-container select2-container--default select2-container--open select-filter-body overflow-auto custom-scrollbar z-100 mb-1`} style={{ display: isOpen ? 'block' : 'none' }}>
              <span className="select2-dropdown select2-dropdown--below position-relative" >
                <div className='position-sticky top-0 pt-50 shadow'>
                  <FormInput
                    margin='my-0'
                    handleValue={handleInputSearch}
                    value={search}
                  />
                </div>

                <ul className="select-filter z-100">
                  {options.length > 0 &&
                    renderOptions.map(({ id, name }, i) => (
                      <li
                        key={`option-${i}-${name}`}
                        className="select-filter-option cursor-pointer"
                        data-value={`${id ?? name}${altSeparator ? '%-%' : '-'}${name}`}
                        onClick={handleSelectValue}
                        name={name}
                      >{name}
                      </li>
                    ))
                  }
                </ul>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectFilter
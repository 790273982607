import axios from 'axios'
import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { FILTER_ADD_PAIS, FILTER_BUSQUEDA } from '../../redux/redux-types'


// Components
import FormInput from '../FormInput'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { busquedaFilter, clearFilterUsuarios, paisFilter, resultadosUsuarios } from '../../redux/actions/usuarios'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'
import SelectFilter from '../SelectFilter'
import { lastPosition } from '../../helpers/lastPosition'
import FiltroAplicado from './FiltroAplicado'
import { idExtractor } from '../../helpers/idExtractor'


const CONSTANT = {
  queryUrlGetAll: getAll.usuarios + '/listar',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  redirectUrl: '/usuarios',
  title: 'Búsqueda de usuarios',
  fetchPaises: getAll.paises + '/buscar',
  reduxClearFilters: clearFilterUsuarios,
  reduxSetResults: resultadosUsuarios,
  selectFilterInitialState: {
    paises: []
  }
}


const dataFormatter = (filtrosObj) => {
  let data = {}

  Object.entries(filtrosObj).forEach(([key, value]) => {
    if (key === 'busqueda') return

    if (value.length > 0) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          [key]: value.map(el => idExtractor(el))
        }
      }
    }
  })

  return data
}


const handleSelectFilterData = async () => {
  const paises = await axios(CONSTANT.fetchPaises, {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    }, method: 'POST'
  })
    .then(({ data }) => [...data?.paises] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { paises }
}


const UsuariosFiltros = () => {
  const { filtros } = useSelector(state => state.usuarios)
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const { busqueda, pais_id } = filtros
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }, method: 'POST'
    })
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
      .catch(err => {
        console.error(err)
        dispatch(CONSTANT.reduxSetResults([]))
      })
  }, [dispatch, showing_results])


  // EFECTO QUE RELLENA LA DATA DE LOS SELECT FILTERS
  useEffect(() => {
    handleSelectFilterData().then(data => setSelectFilter(data))
  }, [])



  // EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
  useEffect(() => {
    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetch = (reset = false) => {
    setIsConsulting(true)

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}`, {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST'
      })
        .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
        .catch(err => {
          console.error(err)
          dispatch(CONSTANT.reduxSetResults([]))
        })
        .finally(() => {
          setIsConsulting(false)
        })
    }

    axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}` + queryParamsFormatter({ busqueda }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: { ...dataFormatter(filtros) }
      })
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
      })
      .catch(err => {
        console.error(err)

        const { errores } = err.response.data
        let detalles = []

        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_BUSQUEDA:
        dispatch(busquedaFilter(value))
        break;

      case FILTER_ADD_PAIS:
        dispatch(paisFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">
      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleClickSearch={() => handleFetch()}
        handleClickClearFilter={() => handleFetch(true)}
      >
        <>
          <FormInput
            labelText='Búsqueda'
            placeholder='Ingrese su búsqueda'
            size='col-12'
            sizeDesk='col-md-4'
            handleValue={(e) => handleAddFilter(e, FILTER_BUSQUEDA)}
            value={busqueda}
          />

          <SelectFilter
            labelText='Paises'
            size='col-12'
            sizeDesk='col-md-4'
            optionObj={selectFilter.paises.map(({ nombre, id }) => ({ id, name: nombre }))}
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_PAIS)}
            value={lastPosition(pais_id)} />
        </>

        {/* FILTROS APLICADOS - ADICIONALES */}
        <p className='mb-25 text-black'>Filtros aplicados</p>
        <div className='row'>
          <FiltroAplicado array={pais_id} func={paisFilter} title={'Países'} />
        </div>

        <hr className='my-1' />
      </ListadoHeader>
    </div>
  )
}

export default UsuariosFiltros
import { useEffect, useState } from 'react'
import axios from 'axios'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { clearFilterAgendamientosPuntoEntrega, fechaFinalFilter, fechaInicialFilter, puntoEntregaFilter, resultadosAgendamientosPuntoEntrega } from '../../redux/actions/agendamientosPuntoEntrega'
import { FILTER_FECHA_FINAL, FILTER_FECHA_INICIO, FILTER_PUNTO_ENTREGA } from '../../redux/redux-types'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Components
import FormInput from '../FormInput'
import SelectFilter from '../SelectFilter'


// Helpers
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'
import { todayFormat } from '../../helpers/todayInputFormat'


// Endpoints
import { getAll } from '../../endpoints/getAll'


const CONSTANT = {
	queryUrlGetAll: getAll.agendamientos,
	redirectUrl: '/agendamientos/puntos-entrega',
	title: 'Búsqueda de agendamientos - punto de entrega',
	reduxClearFilters: clearFilterAgendamientosPuntoEntrega,
	reduxSetResults: resultadosAgendamientosPuntoEntrega,
	fetchPuntosEntrega: getAll.puntos_entrega + '/listar',
	selectFilterInitialState: { puntos_entrega: [] }
}

const fetchSelectFiltersData = async () => {
	const puntos_entrega = await axios(CONSTANT.fetchPuntosEntrega, {
		headers: {
			'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
		}, method: 'POST'
	})
		.then(({ data }) => data)
		.catch(err => {
			console.error(err)
			return []
		})

	return { puntos_entrega }
}


const AgendamientosPuntoEntregaFiltros = () => {
	const dispatch = useDispatch()
	const { filtros } = useSelector(state => state.agendamientosPuntoEntrega)
	// const { showing_results } = useSelector(state => state.listedResults)
	const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
	const { punto_entrega_id, desde, hasta } = filtros

	const [isConsulting, setIsConsulting] = useState(false)


	// EFECTO QUE SETEA LOS VALORES DE LOS SELECTFILTERS Y FECHA DESDE
	useEffect(() => {
		fetchSelectFiltersData().then(data => setSelectFilter(data))
		dispatch(fechaInicialFilter(todayFormat()))
	}, [dispatch])


	// FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
	const handleFetchFilter = async (reset = false) => {
		let config = {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			}, method: 'GET'
		}
		const id = Number(punto_entrega_id?.split('-')[0] ?? null)

		if (!id) return dispatch(fireToaster({ title: 'Seleccione un punto de entrega a consultar', icon: 'info' }))

		if (reset) {
			dispatch(CONSTANT.reduxClearFilters())
			return dispatch(CONSTANT.reduxSetResults(null))
		}

		setIsConsulting(true)

		await axios(CONSTANT.queryUrlGetAll + `/${id}?` + queryParamsFormatter({ desde, hasta }), config)
			.then(({ data }) => {
				dispatch(CONSTANT.reduxSetResults(data))
				dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
			})
			.catch(err => {
				console.error(err);
				const { errores } = err.response.data
				let detalles = []
				Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

				const toasterContent = {
					title: `
           Operación fallida
           (${err.response.status} - ${err.response.statusText})
           `,
					html: `
           <b> Detalle: </b>
           ${detalles.map(error => `<br /><i>-${error}</i>`)}
           `,
					icon: 'error'
				}

				dispatch(fireToaster(toasterContent))
				CONSTANT.reduxSetResults([])
			})
			.finally(() => {
				setIsConsulting(false)
			})

	}


	// MANEJADORES DE FILTRO - PRODUCTOS
	const handleAddFilter = (e, filter) => {
		const { value } = e.target

		switch (filter) {
			case FILTER_PUNTO_ENTREGA:
				dispatch(puntoEntregaFilter(value))
				break;

			case FILTER_FECHA_INICIO:
				dispatch(fechaInicialFilter(value))
				break;

			case FILTER_FECHA_FINAL:
				dispatch(fechaFinalFilter(value))
				break;

			default:
				break;
		}
	}


	return (
		<div className="card mb-50 shadow-none bg-transparent">
			<ListadoHeader
				classes='border rounded-2'
				title='Consulta de agenda'
				handleDisable={isConsulting}
				handleClickSearch={() => handleFetchFilter()}
				handleClickClearFilter={() => handleFetchFilter(true)}
			>
				<SelectFilter
					labelText='Punto de entrega'
					size='col-12'
					sizeDesk='col-md-12'
					handleValue={(e) => handleAddFilter(e, FILTER_PUNTO_ENTREGA)}
					optionObj={selectFilter?.puntos_entrega?.map(({ id, direccion, neu_tipo_destino, tipo_destino, comuna }) => ({ id, name: `${neu_tipo_destino?.nombre ?? tipo_destino?.nombre ?? 'Sin tipo destino'} | ${direccion ?? 'Sin dirección'} | ${comuna?.nombre ?? 'Sin comuna'}, ${comuna?.neu_region?.nombre ?? comuna?.region?.nombre ?? 'Sin región'}` }))}
					value={punto_entrega_id?.split('-')[1] ?? 'Seleccione un punto de entrega'}
				/>

				<FormInput
					type='date'
					labelText='Fecha inicio'
					value={desde}
					size='col-12'
					sizeDesk='col-md-2'
					handleValue={(e) => handleAddFilter(e, FILTER_FECHA_INICIO)}
				/>

				<FormInput
					type='date'
					labelText='Fecha final'
					value={hasta}
					size='col-12'
					sizeDesk='col-md-2'
					handleValue={(e) => handleAddFilter(e, FILTER_FECHA_FINAL)}
				/>
			</ListadoHeader>
		</div>
	)
}

export default AgendamientosPuntoEntregaFiltros

// Components
import { useState } from "react";
import { ProductosImagenesFiltros } from "../../../components/AccordionFilter/ImagenesProductos";
import DropdownMenuLink from "../../../components/Dropdown/Link";
import DropdownWrapper from "../../../components/Dropdown/Wrapper";
import Modal from "../../../components/Modal";
import { ProductosImagenesTable } from "../../../components/Tables/ProductosImagenesTable";
import axios from "axios";
import FileUploaderB from "../../../components/FileUploader_B";
import Button from "../../../components/Button";
import { fireToaster } from "../../../redux/actions/toaster";
import { useDispatch } from "react-redux";
import { BASE_URL, PATH_URL } from "../../../endpoints";
import CustomLoader from "../../../components/CustomLoader";

const CONSTANT = {
	title: 'Imágenes',
	path: 'productos/imagenes',

	subir_imagenes_masivo_btn: 'Actualizar imágenes de productos masivo',
	subir_imagenes_masivo_excel_btn: 'Actualizar imágenes de productos masivo (Excel)',

	imagenesMasiva: `${BASE_URL}${PATH_URL}/imagenes-masivas`,
	imagenesMasivaExcel: `${BASE_URL}${PATH_URL}/imagenes-masivas/asignar`,

	fetchTarget: {
		actualizacion_img_masiva: 'act img prod masiva',
		actualizacion_img_masiva_excel: 'act img prod masiva excel'
	},

	modalState: { display: false, target: null }
}


export const Imagenes = () => {
	const dispatch = useDispatch()
	const [productosImgArr, setProductosImgArr] = useState([])
	const [productosImgArrExcel, setProductosImgArrExcel] = useState([])
	const [modal, setModal] = useState(CONSTANT.modalState)
	const [isConsulting, setIsConsulting] = useState(false)


	const handleUploadImgs = async () => {
		let modalData = new FormData()
		setIsConsulting(true)
		productosImgArr.length ? productosImgArr.forEach(({ file }) => modalData.append('imagenes[]', file)) : modalData = null

		const url = CONSTANT.imagenesMasiva
		try {
			const response = await axios(url, {
				data: modalData,
				method: 'POST',
				headers: {
					'Content-Type': 'multipart/form-data',
					'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
				},
				responseType: 'blob', // Importante para manejar archivos binarios,
			});
			const blob = new Blob([response.data]);
			const link = document.createElement('a');
			let fileName = response?.headers?.['content-disposition']?.split('=')?.[1] ?? 'descarga'

			link.href = URL.createObjectURL(blob);
			link.download = fileName; // Nombre del archivo y su extensión
			link.click();
		}
		catch (error) {
			console.error('Error al descargar el archivo:', error);
		}
		finally {
			setIsConsulting(false)
		}
	};


	const handleFetch = async (target) => {
		let config
		let url
		setIsConsulting(true)

		if (target === CONSTANT.fetchTarget.actualizacion_img_masiva_excel) {
			let modalData = new FormData()
			productosImgArrExcel.length && modalData.append('excel', productosImgArrExcel[0]?.file)
			url = CONSTANT.imagenesMasivaExcel

			config = {
				method: 'POST',
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				data: modalData
			}
		}

		config = {
			...config,
			headers: {
				...config?.headers,
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			}
		}

		await axios(url, config)
			.then(res => {
				const toasterContent = {
					title: 'Operación realizada',
					text: `${CONSTANT.title}: registro actualizado con éxito`,
					icon: 'success'
				}

				dispatch(fireToaster(toasterContent))
			})
			.catch(err => {
				console.error(err);
				const { errores } = err.response.data
				let detalles = []
				Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

				const toasterContent = {
					title: `
				Operación fallida
				(${err.response.status} - ${err.response.statusText})
			`,
					html: `
				<b>Detalle: </b>
				${detalles.map(error => `<br /><i>-${error}</i>`)}
			`,
					icon: 'error'
				}

				dispatch(fireToaster(toasterContent))
			})

			.finally(() => {
				setModal(CONSTANT.modalState)
				setIsConsulting(false)
			})
	}


	const closeModal = () => {
		setModal({ ...modal, display: false })
	}

	return (
		<div className="row">
			{isConsulting && <CustomLoader blocking={'partial'} />}

			<Modal display={modal.display} handleValue={closeModal}>

				{modal.target === CONSTANT.fetchTarget.actualizacion_img_masiva &&
					<>
						<div className='m-2'>
							<h3 className='p-50 rounded-2 m-0'>Actualización de imágenes de productos masivo</h3>

							<hr className='mt-0 mb-1' />

							<h4 className='p-50 rounded-2 m-0'>IMÁGENES</h4>

							<div className='col-12 mb-50'>
								<FileUploaderB value={productosImgArr} handleValue={setProductosImgArr} />
							</div>

							<div className='d-flex'>
								<Button text='Subir imágenes' className='mx-auto' color='success' onClick={handleUploadImgs} />
							</div>
						</div>

						<div className='m-2'>
							<hr className='mt-0 mb-1' />
							<h4 className='p-50 rounded-2 m-0'>EXCEL</h4>

							<div className='col-12 mb-50'>
								<FileUploaderB value={productosImgArrExcel} handleValue={setProductosImgArrExcel} />
							</div>

							<div className='d-flex'>
								<Button text='Subir excel' className='mx-auto' color='success' onClick={() => handleFetch(CONSTANT.fetchTarget.actualizacion_img_masiva_excel)} />
							</div>
						</div>
					</>
				}
			</Modal>

			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.subir_imagenes_masivo_btn} onClick={() => setModal({ display: true, target: CONSTANT.fetchTarget.actualizacion_img_masiva })} icon="Image" />
				</DropdownWrapper>
			</h3>


			<ProductosImagenesFiltros />

			<ProductosImagenesTable />
		</div>
	);
}

import axios from 'axios'
import React, { useEffect } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { clearFilterClaves, resultadosClaves } from '../../redux/actions/claves'



// Endpoints
import { BASE_URL, CONFIG_URL } from '../../endpoints'


const CONSTANT = {
  reduxStateSelector: 'claves',
  queryUrlGetAll: BASE_URL + CONFIG_URL + `/claves`,
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  redirectUrl: '/claves',
  title: 'Búsqueda de claves',

  reduxClearFilters: clearFilterClaves,
  reduxSetResults: resultadosClaves,

}


const ClavesFiltros = () => {
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()


  // EFFECTO QUE HACE LA CONSULTA A LA BBDD
  useEffect(() => {
    axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)));
    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [dispatch, showing_results])


  return (<></>)
}

export default ClavesFiltros
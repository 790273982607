
// Components
import UnidadesFiltros from "../../components/AccordionFilter/Unidades";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import UnidadesTable from "../../components/Tables/UnidadesTable";

const CONSTANT = {
	title: 'Unidades',
	path: 'unidades',
	create_btn: 'Crear unidad'
}

const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/generales/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>
			
			<UnidadesFiltros />

			<UnidadesTable />
		</div>
	);
}

export default Listado;
import axios from "axios"
import { getOne } from "../../endpoints/getOne"
import { getAll } from "../../endpoints/getAll"
import { queryParamsFormatter } from "../../helpers/queryParamsFormatter"

export const getDetail = async (id) => {
  try {
    if (!id) throw Error(`Missing "id" empresa`)

    const response = await axios(getOne.empresas(id), {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    })

    if (response.status >= 400) throw response

    return response
  } catch (err) {
    console.error(`Error fetching empresa detail data`);
    return err
  }
}

export const getCuentaCorriente = async (empresa_id, limit = 20) => {
  try {
    if (!empresa_id) throw Error(`Missing "id" empresa`)

    const response = await axios(`${getAll.empresas}/cuenta-corriente?s=1${queryParamsFormatter({ empresa_id, limit })}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      }
    })

    if (response.status >= 400) throw response

    return response
  } catch (err) {
    console.error(`Error fetching cuenta corriente empresa detail data`);
    return err
  }
}
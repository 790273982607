
// Components
import DropdownMenu from "../../components/DropdownMenu";
import FormInput from "../../components/FormInput";
import Select from "../../components/Select";
// import Pagination from "../../components/PaginationR";
// import Button from "../../components/Button";


// Mocks
import permissions from '../../mocks/permissions.json'


// Hooks
import { useWindowDimensions } from "../../hooks/useDimensions";


// Layouts
import ListadoHeader from "../../layout/ListadoHeader";



const actionsOptions = [
   { text: 'Crear permiso', href: '/permisos/create', icon: 'Plus' },
   { text: 'Exportar PDF', href: '#', icon: 'ExternalLink' }
]

const userOptions = [
   { text: 'Detalles', href: '#', icon: 'Table' },
   { text: 'Editar', href: '/permisos/update', icon: 'Settings' },
   { text: 'Borrar', href: '#', icon: 'Trash' }
]

const PermissionsListado = () => {
   const { width } = useWindowDimensions()

   return (
      <div className="row">
         <div className="d-flex">
            <h3 className="mb-1"><b>Permisos</b> - Listado</h3>

            <DropdownMenu
               options={actionsOptions}
               text='Acciones'
               classes='ms-auto'
            />
         </div>

         <div className="col-12 mx-auto">
            <div className="card">
               <ListadoHeader>
                  <Select
                     labelText='Roles'
                     placeholder='Selecciona un rol'
                     size='col-12'
                     sizeDesk='col-md-3'
                  />

                  <FormInput
                     type="date"
                     labelText='Creación'
                     size='col-12'
                     sizeDesk='col-md-3'
                  />
               </ListadoHeader>

               <div className={`card-datatable table-responsive pt-0 ${width > 1200 ? 'overflow-visible' : ''}`}>
                  <table className="user-list-table table dataTable no-footer dtr-column z-10">
                     <thead className="table-light">
                        <tr role="row">
                           <th>ID</th>
                           <th>Nombre</th>
                           <th>Tipos</th>
                           <th>Creación</th>
                           <th style={{ width: '120px' }}>Acciones</th>
                        </tr>
                     </thead>
                     <tbody>
                        {
                           permissions.map(permission => {
                              return (
                                 <tr className="odd" key={`user-${permission.id}-${permission.nombre}`}>
                                    <td>{permission.id}</td>

                                    <td>{permission.name ?? 'N/A'}</td>

                                    <td>
                                       <span className="px-50 tag">{permission.guard_name ?? 'N/A'}</span>
                                    </td>

                                    <td>{permission.created_at.split(' ')[0]}</td>

                                    <td>
                                       <DropdownMenu icon={'MoreVertical'} options={userOptions} classes='w-fit mx-auto' />
                                    </td>
                                 </tr>
                              )
                           })
                        }
                     </tbody>
                  </table>
               </div>

               <div className="mx-auto my-1">
                  {/* <Pagination /> */}
               </div>
            </div>
         </div>
      </div>

   );
}

export default PermissionsListado;

// Components
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import OfertasFiltros from "../../components/AccordionFilter/Ofertas";
import OfertasTable from "../../components/Tables/OfertasTable";


const CONSTANT = {
   title: 'Ofertas',
   path: 'ofertas',
   create_btn: 'Crear oferta'
}


const Listado = () => {
   return (
      <div className="row">
         <h3 className="mb-1 d-flex justify-content-between">
            <p><b>{CONSTANT.title}</b> - Listado</p>
            <DropdownWrapper text="Acciones" classes="ms-auto">
               <DropdownMenuLink text={CONSTANT.create_btn} href={`/${CONSTANT.path}/create`} icon="Plus" />
            </DropdownWrapper>
         </h3>

         <OfertasFiltros />

         <OfertasTable />
      </div>
   );
}

export default Listado;
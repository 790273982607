import { Navigate, Route, Routes } from 'react-router-dom'
import Listado from '../views/CodigosDescuentos/Listado'
import Create from '../views/CodigosDescuentos/Create'
import Update from '../views/CodigosDescuentos/Update'
import Detalle from '../views/CodigosDescuentos/Detalle'
import { useSelector } from 'react-redux'

const CodigosDescuentosRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['codigos-descuento.crear'] &&
            <Route path='/create' element={<Create />} />
         }

         {user.permisos['codigos-descuento.editar'] &&
            <Route path='/update/:id' element={<Update />} />
         }

         {user.permisos['codigos-descuento.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}

export default CodigosDescuentosRouter
import { useState } from "react"
import Button from "../../Button"
import CheckBoxInput from "../../CheckboxInput"
import FormInput from "../../FormInput"
import SelectFilter from "../../SelectFilter"
import Table from "../../Table"


export const ModalOrdenCompra = ({ handleInputChange, id, form, selectFilter, handleFetch }) => {
  const [nuevoProducto, setNuevoProducto] = useState({ sku: '', value: '' })

  const handleAddProducto = (e) => {
    const { value } = e.target
    setNuevoProducto({ sku: value?.split('%-%')[0], value: value?.split('%-%')[1] })
  }

  return (
    <>
      <p className='fw-bold fs-3'>OC para nota de venta #{id}</p>
      <hr className='my-2' />
      <div className='row'>
        <SelectFilter
          altSeparator
          labelText='RUT proveedor'
          name='OC%-%rut_proveedor'
          optionObj={selectFilter?.proveedores?.map(({ rut_proveedor, nombre_proveedor }) => ({ id: rut_proveedor ?? '*NULL*', name: `${rut_proveedor} | ${nombre_proveedor}` ?? 'ERROR' }))}
          value={form.rut_proveedor.split('%-%')?.[1] ?? 'Sin selección'}
          handleValue={handleInputChange}
          size='col-12'
          sizeDesk='col-md-6'
        />

        <SelectFilter
          labelText='Bodega'
          name='OC%-%bodega'
          optionObj={selectFilter?.bodegas?.map(({ WARE_CODE, DESC_TEXT }) => ({ id: WARE_CODE ?? '*NULL*', name: `${WARE_CODE} | ${DESC_TEXT}` ?? 'ERROR' }))}
          value={form.bodega.split('-')?.[1] ?? 'Sin selección'}
          handleValue={handleInputChange}
          size='col-12'
          sizeDesk='col-md-6'
        />

        <FormInput
          labelText='Comentario'
          handleValue={handleInputChange}
          name='OC%-%comentario'
          value={form.comentario}
          size='col-12'
          sizeDesk='col-md-12'
        />

        <SelectFilter
          altSeparator
          name='OC%-%nuevo_producto'
          labelText='Agregar producto'
          optionObj={selectFilter?.ocProductos?.map(({ PART_CODE, DESC_TEXT }) => ({ id: PART_CODE ?? '*NULL*', name: `${PART_CODE} | ${DESC_TEXT}` ?? 'ERROR' }))}
          value={nuevoProducto.value ?? 'Seleccione'}
          handleValue={handleAddProducto}
          size='col-12'
          sizeDesk='col-md-11'
        />

        <Button
          className={'w-fit h-fit my-auto'}
          icon={'Plus'}
          color={'success'}
          onClick={() => handleInputChange({
            target: {
              name: `OC%-%extra_productos%-%${nuevoProducto.sku}%-%add`,
              value: null
            }
          })}
        />
      </div>

      <Table pb={false} style={{ overflow: 'none' }}>
        <thead>
          <tr className='text-center'>
            <th colSpan={2} className='px-1'>Item linea</th>
            <th className='px-1'>Descripción / SKU</th>
            <th className='px-1'>QTY</th>
            <th className='px-1'>Costo</th>
          </tr>
        </thead>
        <tbody>
          {form?.productos?.filter(({ qty_reservada }) => !qty_reservada).map(({ linea_item, descripcion, sku, qty, costo_neto, check }) => (
            <tr key={`${linea_item}-${descripcion}`}>
              <td className='pe-0'>
                <CheckBoxInput
                  name={`OC%-%producto%-%${linea_item}%-%check`}
                  labelText=''
                  value={check}
                  handleValue={handleInputChange}
                />
              </td>

              <td>{linea_item ?? 'N/A'}</td>

              <td>
                <div className='d-flex flex-column'>
                  <span className='fw-bold'>{descripcion ?? 'Error'}</span>
                  <span>SKU: <b>{sku ?? 'Error'}</b></span>
                </div>
              </td>

              <td className='col-2'>
                <FormInput
                  type="number"
                  labelText=""
                  name={`OC%-%producto%-%${linea_item}%-%qty`}
                  size="col-12"
                  margin='m-0'
                  handleValue={handleInputChange}
                  value={qty ?? 0}
                />
              </td>

              <td className='col-3'>
                <FormInput
                  type="number"
                  labelText=""
                  name={`OC%-%producto%-%${linea_item}%-%costo_neto`}
                  size="col-12"
                  margin='m-0'
                  handleValue={handleInputChange}
                  value={costo_neto ?? 0}
                />
              </td>
            </tr>
          ))}

          {form.extra_productos.map((prod, i) => (
            <tr key={`extra_producto-${prod.sku}`}>
              <td colSpan={2}>
                <Button
                  text={'Borrar'}
                  icon={'Trash'}
                  color={'danger'}
                  onClick={() => handleInputChange({
                    target: {
                      name: `OC%-%extra_productos%-%${nuevoProducto.sku}%-%delete`,
                      value: null
                    }
                  })}
                />
              </td>

              <td className='d-flex flex-column'>
                <span className='fw-bold'>{selectFilter.ocProductos?.find(({ PART_CODE }) => PART_CODE === prod.sku)?.DESC_TEXT ?? 'Error'}</span>
                <span>SKU: <b>{prod?.sku?.split('%-%')?.[0] ?? 'Error'}</b></span>
              </td>

              <td className='col-2'>
                <FormInput
                  type="number"
                  labelText=""
                  name={`OC%-%extra_productos%-%${prod.sku}%-%qty`}
                  size="col-12"
                  margin='m-0'
                  handleValue={handleInputChange}
                  value={prod.qty ?? 0}
                />
              </td>

              <td className='col-3'>
                <FormInput
                  type="number"
                  labelText=""
                  name={`OC%-%extra_productos%-%${prod.sku}%-%costo_neto`}
                  size="col-12"
                  margin='m-0'
                  handleValue={handleInputChange}
                  value={prod.costo_neto ?? 0}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div>
        <Button className='mx-auto mt-1' text='Realizar OC' icon='Check' onClick={() => handleFetch(null, 'oc-create')} />
      </div>
    </>
  )
}

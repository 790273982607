import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   FILTER_DAÑO_ID,
   FILTER_ESTADO_ID,
   FILTER_FACTURA,
   FILTER_FECHA_CREACION_DESDE,
   FILTER_FECHA_CREACION_HASTA,
   FILTER_FECHA_ENVIO_DESDE,
   FILTER_FECHA_ENVIO_HASTA,
   FILTER_FECHA_RECEPCION_DESDE,
   FILTER_FECHA_RECEPCION_HASTA,
   FILTER_PEDIDO_ID,
   FILTER_POSICION_ID,
   FILTER_RESPUESTA_ID,
   FILTER_RESULTADO_ID,
   FILTER_SKU,
   FILTROS_PERSISTENTES_GARANTIAS,
   RESULTADOS_GARANTIAS,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })
export const pedidoFilter = (data) => ({ type: FILTER_PEDIDO_ID, payload: data })
export const estadoFilter = (data) => ({ type: FILTER_ESTADO_ID, payload: data })
export const posicionFilter = (data) => ({ type: FILTER_POSICION_ID, payload: data })
export const dañoFilter = (data) => ({ type: FILTER_DAÑO_ID, payload: data })
export const resultadoFilter = (data) => ({ type: FILTER_RESULTADO_ID, payload: data })
export const respuestaFilter = (data) => ({ type: FILTER_RESPUESTA_ID, payload: data })
export const skuFilter = (data) => ({ type: FILTER_SKU, payload: data })
export const numeroFacturaFilter = (data) => ({ type: FILTER_FACTURA, payload: data })
export const fechaCreacionDesdeFilter = (data) => ({ type: FILTER_FECHA_CREACION_DESDE, payload: data })
export const fechaCreacionHastaFilter = (data) => ({ type: FILTER_FECHA_CREACION_HASTA, payload: data })
export const fechaRecepcionDesdeFilter = (data) => ({ type: FILTER_FECHA_RECEPCION_DESDE, payload: data })
export const fechaRecepcionHastaFilter = (data) => ({ type: FILTER_FECHA_RECEPCION_HASTA, payload: data })
export const fechaEnvioDesdeFilter = (data) => ({ type: FILTER_FECHA_ENVIO_DESDE, payload: data })
export const fechaEnvioHastaFilter = (data) => ({ type: FILTER_FECHA_ENVIO_HASTA, payload: data })

export const resultadosGarantias = (data) => ({ type: RESULTADOS_GARANTIAS, payload: data })

export const filtrosPersistentesGarantias = (data) => ({ type: FILTROS_PERSISTENTES_GARANTIAS, payload: data })

export const clearFilterGarantias = () => ({ type: FILTER_CLEAR });

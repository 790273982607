
// Components
import FormasPagoFiltros from "../../components/AccordionFilter/FormasPago";
import DropdownMenuLink from "../../components/Dropdown/Link";
import DropdownWrapper from "../../components/Dropdown/Wrapper";
import FormasPagoTable from "../../components/Tables/FormasPagoTable";

const CONSTANT = {
	title: 'Formas de pago',
	path: 'formas-pago',
	create_btn: 'Crear forma de pago'
}

const Listado = () => {
	return (
		<div className="row">
			<h3 className="mb-1 d-flex justify-content-between">
				<p><b>{CONSTANT.title}</b> - Listado</p>
				<DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT.create_btn} href={`/generales/${CONSTANT.path}/create`} icon="Plus" />
				</DropdownWrapper>
			</h3>

			<FormasPagoFiltros />

			<FormasPagoTable />
		</div>
	);
}

export default Listado;
import { useState } from "react";
import axios from "axios";


//	Components
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
import FormInput from "../../components/FormInput";
import TextareaInput from "../../components/TextareaInput";


// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


// Endpoint
import { create } from "../../endpoints/create";


// React-router
import { useNavigate } from "react-router-dom";


const CONSTANT = {
  redirectUrl: '/generales/tipos-destinos',
  componentTitle: 'Crear tipo de destino',
  title: 'Tipos de destino',
  queryUrl: create.tipos_destino,
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  initialFormState: {
    nombre: "",
    descripcion: "",
    estatus: 1,
  },
}


const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form, setForm] = useState(CONSTANT.initialFormState);


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = (e) => {
    e.preventDefault()

    if (!form.nombre.length) return dispatch(fireToaster({ title: 'El nombre es obligatorio', icon: 'info' }))

    let data = {
      nombre: form.nombre,
      descripcion: form.descripcion,
      estatus: form.estatus ? 1 : 0
    }

    axios(CONSTANT.queryUrl,
      {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
          'Content-Type': 'application/json'
        },
        data
      })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        <CustomForm size="" title={CONSTANT.componentTitle}>
          <div className="row">
            <Switch name="estatus"
              value={form.estatus}
              labelText="Estatus"
              handleValue={handleInputChange}
              size="col-12"
            />

            <FormInput
              name="nombre"
              value={form.nombre}
              labelText="* Nombre"
              handleValue={handleInputChange}
              size="col-12"
            />

            <TextareaInput
              name="descripcion"
              value={form.descripcion}
              labelText="Descripción"
              handleValue={handleInputChange}
              rows={2}
              size="col-12"
            />
          </div>

          <Button type="submit" text="Crear" color="primary" icon="Table" onClick={handleFetch} />
        </CustomForm>
      </div>
    </div>
  );
};

export default Create;

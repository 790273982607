import {
   FILTER_FECHA_FINAL,
   FILTER_FECHA_INICIO,
   FILTER_ADD_OFERTA,
   FILTER_REMOVE_OFERTA,
   FILTER_DESCRIPCION,
   FILTER_NOMBRE,
   FILTER_CREACION_DESDE,
   FILTER_CREACION_HASTA,
   RESULTADOS_ETIQUETAS,
   FILTER_CLEAR_ETIQUETAS,
} from "../redux-types";

const initialState = {
   filtros: {
      oferta_id: [],
      nombre: '',
      descripcion: '',
      created_at_desde: '',
      created_at_hasta: '',
      fecha_hora_inicio: '',
      fecha_hora_final: '',
   },
   resultados: null
}

export const etiquetasReducer = (state = initialState, action) => {
   const isListedOferta = state.filtros.oferta_id.find(el => el === action.payload)

   switch (action.type) {
      case FILTER_ADD_OFERTA:
         return {
            ...state, filtros: {
               ...state.filtros,
               oferta_id: isListedOferta ? state.filtros.oferta_id : [...state.filtros.oferta_id, action.payload]
            }
         };

      case FILTER_REMOVE_OFERTA:
         return {
            ...state, filtros: {
               ...state.filtros,
               oferta_id: state.filtros.oferta_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_NOMBRE:
         return {
            ...state, filtros: {
               ...state.filtros,
               nombre: action.payload
            }
         };

      case FILTER_DESCRIPCION:
         return {
            ...state, filtros: {
               ...state.filtros,
               descripcion: action.payload
            }
         };

      case FILTER_CREACION_DESDE:
         return {
            ...state, filtros: {
               ...state.filtros,
               created_at_desde: action.payload
            }
         };

      case FILTER_CREACION_HASTA:
         return {
            ...state, filtros: {
               ...state.filtros,
               created_at_hasta: action.payload
            }
         };

      case FILTER_FECHA_INICIO:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_hora_inicio: action.payload
            }
         };

      case FILTER_FECHA_FINAL:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_hora_final: action.payload
            }
         };

      case RESULTADOS_ETIQUETAS:
         return {
            ...state,
            resultados: action.payload
         };

      case FILTER_CLEAR_ETIQUETAS:
         return initialState;

      default:
         return state;
   }
};
import { useSelector } from 'react-redux'


// Redux
import { useEffect, useState } from 'react'


// Components
import ProductosPuntosEntregaFiltros from '../../../components/AccordionFilter/ProductosPuntosEntregas'
import Button from '../../../components/Button'


// Layouts
import AccordionTable from '../../../layout/AccordionTable'


const CONSTANT = {
	path: 'puntos-entrega',
	title: 'Puntos de entrega'
}


const Etiquetas = () => {
	const { resultados: response } = useSelector(state => state.productosPuntosEntrega)
	const [r, setR] = useState(null)
	const [puntosEntrega, setPuntosEntrega] = useState([])
	const [resultadosFormatted, setResutadosFormatted] = useState(null)


	useEffect(() => {
		setResutadosFormatted(null)
		setPuntosEntrega([])
		if (!response) return
		setR(response?.puntos_entregas_productos ?? [])
	}, [response])


	useEffect(() => {
		if (!r?.length) return setPuntosEntrega([])
		const arrPtEn = []

		r.forEach(PPE => {
			Object.entries(PPE).forEach(([k, v]) => {
				if (k === 'neu_punto_entrega_producto') {
					v.forEach(epp => {
						if (!arrPtEn.length) arrPtEn.push(epp)
						arrPtEn.find(({ punto_entrega_id }) => epp.punto_entrega_id === punto_entrega_id) ?? arrPtEn.push(epp)
					})
				}
			})
		})
		setPuntosEntrega(arrPtEn);
	}, [r])


	useEffect(() => {
		if (!r?.length) return
		if (!puntosEntrega?.length) return setResutadosFormatted([])
		const newResultados = r.map(productoPuntoEntrega => {
			let newObj = {
				...productoPuntoEntrega,
				neu_punto_entrega_producto: puntosEntrega.map(() => ({}))
			}
			puntosEntrega.forEach((currentPuntoEntrega, i) => {
				const isIncludedPuntoEntrega = productoPuntoEntrega.neu_punto_entrega_producto.find(el => el.punto_entrega_id === currentPuntoEntrega.punto_entrega_id)

				if (isIncludedPuntoEntrega) {
					newObj.neu_punto_entrega_producto.splice(i, 1, isIncludedPuntoEntrega)
					newObj = {
						...newObj,
						neu_punto_entrega_producto: newObj.neu_punto_entrega_producto
					}
				}
			})

			return newObj
		})
		setResutadosFormatted(newResultados)
	}, [puntosEntrega])


	return (
		<div className="row">
			<div className='d-flex justify-content-between mb-1'>
				<h3>{CONSTANT.title} - Listado</h3>

				<div className='d-flex gap-1'>
					<div className='w-fit'>
						<Button text='Actualización masiva' className='m-auto p-50' color='primary' url={`/productos/${CONSTANT.path}/actualizacion-masiva`} isLink />
					</div>
					<div className='w-fit'>
						<Button text='Asignación masiva' className='m-auto p-50' color='success' url={`/productos/${CONSTANT.path}/asignacion-masiva`} isLink />
					</div>
					<div className='w-fit'>
						<Button text='Edición masiva' className='m-auto p-50' color='warning' url={`/productos/instalacion-despacho/edicion-masiva`} isLink />
					</div>
				</div>
			</div>

			<div className="col-12 mx-auto">
				{/* COMPONENTE FILTROS - PRODUCTOS */}
				<ProductosPuntosEntregaFiltros type='visualizar' />

				{/* TABLA DE RESULTADOS PRODUCTOS - ETIQUETAS */}
				<AccordionTable
					title={`Tabla de resultados (${r?.length ?? 0})`}
				>
					<div className='d-flex'>

						{/* PRODUCTOS */}
						<div
							className={`card-datatable table-responsive pt-0 custom-scrollbar overflow-auto shadow flex-shrink-0 dataTable w-100`}
						>
							<table className="user-list-table table position-relative no-footer dtr-column z-10">
								<thead className="table-light position-sticky top-0 z-100" >
									<tr role="row">
										{resultadosFormatted &&
											<th className='border-end position-sticky left-0 z-100 w-fit' style={{ height: '85px', width: '180px' }}>
												<p className='text-center mt-2'>Producto</p>
											</th>
										}

										{puntosEntrega?.map(({ neu_punto_entrega }, i) => (
											<th style={{ height: '85px', minWidth: '210px' }} className='border-end' key={`${i}-${neu_punto_entrega?.nombre}`}>
												<div className='mx-auto w-fit my-auto'>
													<a href={`/puntos-entrega/detalle/${neu_punto_entrega?.id}`} target='_blank' rel='noreferrer'>
														<p className='my-0'>{neu_punto_entrega?.neu_tipo_destino?.nombre ?? 'N/A'} | {neu_punto_entrega?.direccion ?? 'N/A'} | {neu_punto_entrega?.nombre ?? 'N/A'} <span className='tag px-50 sub-text-4'>{neu_punto_entrega?.estatus ? 'Activo' : 'Inactivo'}</span></p>
													</a>
												</div>
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{resultadosFormatted?.length
										? resultadosFormatted?.map(data => {
											return (
												<tr className="odd" key={`product-${data?.id}-${data?.producto}`}>
													{/* RESUMEN PRODUCTO - DETALLE */}
													<td className='p-0 position-sticky left-0 border-end z-10'>
														<div className='px-2 py-1 bg-product'>
															<div className='my-0 d-flex flex-column'>
																<span>
																	<a href={`/productos/detalle/${data?.id}`} target='_blank' rel='noreferrer'>
																		<b>{data?.producto.toUpperCase()}</b>
																	</a>
																</span>
															</div>
															<div className='my-0 sub-text-3'>{data?.categoria}</div>
															<div className='my-0 sub-text-3'>{data?.sku}</div>
															<span className={`my-auto tag px-50 sub-text-3 w-fit ${data?.publicado ? 'success' : 'red'}`}>{data?.publicado ? 'Publicado' : 'Sin publicar'}</span>
														</div>
													</td>
													{data?.neu_punto_entrega_producto.map((puntoEntrega, i) => (
														<td className='bg-white border-end' key={`${i}-puntoEntrega-${data?.producto}`}>
															{Object.entries(puntoEntrega).length
																? <div className='m-auto w-fit z-10'>
																	<div>
																		<span className={`tag ${puntoEntrega?.estatus ? 'success' : 'red'} px-50`}>{puntoEntrega?.estatus ? 'Activo' : 'Inactivo'}</span>
																	</div>
																	<div>Instalación: <b>{puntoEntrega?.precio_instalacion ?? 'N/A'}</b></div>
																	<div>Recargo: <b>{puntoEntrega?.recargo ?? 'N/A'}</b></div>
																	<div>Inst. perma.: <b>{puntoEntrega?.instalacion_permanente ? 'Sí' : 'No'}</b></div>
																	{/* <div>Editar</div> */}
																</div>
																: <p className='text-center'>N/A</p>
															}
														</td>
													))}
												</tr>
											)
										})
										: <tr><td><p className='text-center fw-bolder fs-2 mt-1'>Sin resultados a mostrar...</p></td></tr>
									}
								</tbody>
							</table>
						</div>
					</div>

				</AccordionTable>
			</div >
		</div >
	)
}

export default Etiquetas
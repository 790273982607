import validator from "validator";

export const dateRangeCounter = (dateInit, dateEnd) => {
  const msToDay = 1000 * 3600 * 24
  const dates = []
  const currentDate = new Date(dateInit.getTime())

  if (!validator.isDate(dateInit)) {
    console.error(`el valor dateInit (${dateInit}) no tiene un formato fecha válido`)
    return false
  }
  if (!validator.isDate(dateEnd)) {
    console.error(`el valor dateEnd (${dateEnd}) no tiene un formato fecha válido`)
    return false
  }

  let msDiff = dateEnd.getTime() - dateInit.getTime()
  let daysDiff = Math.ceil(msDiff / (msToDay))

  while (currentDate <= dateEnd) {
    const date = new Date(currentDate)
    let dd = date.getDate()
    let mm = date.getMonth() + 1
    let yyyy = date.getFullYear()
    dates.push(`${yyyy}-${String(mm).length < 2 ? `0${mm}` : mm}-${String(dd).length < 2 ? `0${dd}` : dd}`)
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return { daysDiff, dates }
}
import axios from "axios"
import { getAll } from "../../../endpoints/getAll"

const URL = (id) => getAll.puntos_entrega_productos + `/${id}`

export const deleteRelation = async (punto_entrega_producto_id) => {
  try {
    return await axios(URL(punto_entrega_producto_id), {
      method: 'DELETE',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
      .then(({ data }) => data)
      .catch(err => {
        console.error(err)
        throw err
      })
  } catch (err) {
    return { ok: false, msg: err }
  }
}
// Components
import Button from "../../components/Button";
import TablaPermisos from "./TablaPermisos";
import TablaRoles from "./TablaRoles";

const RoleHasPermissions = () => {
  return (
    <div className="row">
      <TablaRoles />
      <TablaPermisos />
      <div className="col">
        <div className="d-flex justify-content-center mt-2">
          <Button text="Asignación masiva de métodos de pago a productos" />
        </div>
      </div>
    </div>
  );
};

export default RoleHasPermissions;

import IconPicker from "../components/IconPicker";


const Templates = () => {

   return (
      <>
         <IconPicker />
      </>
   );
}

export default Templates;
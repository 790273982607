import { useRef } from 'react'
import Icon from './Icon'



const FullscreenImg = ({imgSrc, alt}) => {

   const containerRef = useRef(null)

   const handleOpen = () => {
      if (!containerRef.current.classList.contains('open')) {
         containerRef.current.classList.add('open')
         document.body.style.overflow = 'hidden';
      }
   }

   const handleClose = () => {
      containerRef.current.classList.remove('open')
      document.body.style.overflow = 'visible';
   }

   const handleOutClose = (e) => {
      if (e.target === containerRef.current) {
         handleClose()
      }
   }

   return (
      <div className='fullscreen-container'>
         <div className='fullscreen' ref={containerRef} onClick={handleOutClose}>
            <div className='fullscreen-img'>
               <img src={imgSrc} alt={alt} onClick={handleOpen} />

               <button type='button' onClick={handleClose}>
                  <Icon icon='X' size={32} />
               </button>
            </div>
         </div>

         <div className='holder'></div>
      </div>
   );
}



export default FullscreenImg;
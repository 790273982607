import axios from 'axios';

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { clearFilterValoresAtributos, resultadosValoresAtributos } from '../../redux/actions/valoresAtributos';
import { fireToaster } from "../../redux/actions/toaster";


// Components
import CustomLoader from "../CustomLoader";
import DropdownMenuLink from "../Dropdown/Link";
import DropdownWrapper from "../Dropdown/Wrapper";
import Switch from "../Switch";
import Table from "../Table";


// Endpoints
import { BASE_URL } from "../../endpoints";
import { getOne } from "../../endpoints/getOne";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter';


const CONSTANT = {
  pathUrl: '/productos/valores-atributos',
  permissionsPath: 'atributos',
  queryUrlGetOne: getOne.valores_atributos,
  title: 'Valores/Atributos',
  reduxClearFilters: (data) => clearFilterValoresAtributos(data),
  reduxSetResults: (data) => resultadosValoresAtributos(data),
  logo: (data) => `${BASE_URL}storage/${data?.logo}` ?? '/placeholder.png'
}


const dataFormatter = (filtros) => {
  let data = {}

  Object.entries(filtros).forEach(([k, v]) => {
    if (k === 'busqueda') return

    if (v.length) {
      data = {
        ...data,
        filtros: {
          ...data.filtros,
          [k]: v.map(el => el.split('-')[0])
        }
      }
    }
  })

  return data
}


const ValoresAtributosTable = () => {
  const { resultados: response, filtros } = useSelector(state => state.valoresAtributos)
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const { busqueda } = filtros
  const { user } = useSelector(state => state.auth)


  const handleUrl = (url) => {
    let data = { ...dataFormatter(filtros) }

    dispatch(CONSTANT.reduxSetResults(null))
    axios(url + `&limit=${showing_results}` + queryParamsFormatter({ busqueda }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data
      })
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
      .catch(err => console.error(err))
  }


  const handleFetch = async (objData, url, target) => {
    let config

    if (target === 'estatus') {
      let newEstatus = objData.estatus ? 0 : 1

      config = {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
          'Content-Type': 'multipart/form-data'
        },
        data: {
          [target]: newEstatus,
          _method: 'PUT'
        }
      }
    }

    if (target === 'delete') {
      config = {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'DELETE'
      }
    }

    await axios(CONSTANT.queryUrlGetOne(objData.id), config)
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: registro actualizado con éxito`,
          icon: 'success'
        }

        dispatch(fireToaster(toasterContent))
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
      })

    dispatch(CONSTANT.reduxSetResults(null))
    axios(url + queryParamsFormatter({ busqueda }),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST',
        data: { ...dataFormatter(filtros) }
      })
      .then(res => {
        dispatch(CONSTANT.reduxSetResults(res.data))
      })
      .catch(err => console.error(err))
  }


  return (
    <AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
      {!response && <CustomLoader blocking={'partial'} />}

      <Table response={response} handlePagination={handleUrl}>
        <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
          <tr role="row">
            <th>ID</th>
            <th>Nombre</th>
            <th>Categoría</th>
            <th>Slug</th>
            <th>Clave</th>
            <th>Logo</th>
            <th># img.</th>
            <th>Creación</th>
            <th>Estatus</th>
            <th className="w-max">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {response?.data?.length
            ? response?.data.map((data) => {

              return (
                <tr className="odd" key={`user-${data.id}-${data.nombre}`}>
                  <td>{data?.id}</td>
                  <td>{data?.nombre ?? "N/A"}</td>
                  <td>{data?.neu_clave_atributo?.categoria?.nombre ?? "N/A"}</td>
                  <td>{data?.slug ?? "N/A"}</td>
                  <td>{data?.neu_clave_atributo?.nombre ?? "N/A"}</td>
                  <td>{data?.logo ? <img className="height-listado" src={CONSTANT.logo(data)} alt={data?.nombre} /> : "N/A"}</td>
                  <td className='text-center'>{data?.imagenes_productos?.split(',').length ?? 0}</td>
                  <td>{data?.created_at.split("T")[0]}</td>
                  <td>
                    <Switch
                      value={data?.estatus}
                      labelText={""}
                      handleValue={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`, 'estatus')}
                    />
                  </td>
                  <td>
                    <DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
                      {user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
                        <DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
                      }

                      {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
                        <DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
                      }

                      {user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
                        <DropdownMenuLink text='Borrar' icon="X" onClick={() => handleFetch(data, response.path + '?page=' + response.current_page + `&limit=${showing_results}`, 'delete')} />
                      }
                    </DropdownWrapper>
                  </td>
                </tr>
              )
            })
            : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
          }
        </tbody>
      </Table>
    </AccordionTable >
  )
}

export default ValoresAtributosTable
// Components
import { useLocation } from 'react-router-dom'
import PedidosFiltros from '../../../components/AccordionFilter/Pedidos'
import DropdownMenuLink from '../../../components/Dropdown/Link'
import DropdownWrapper from '../../../components/Dropdown/Wrapper'
import PedidosTable from '../../../components/Tables/PedidosTable'


const CONSTANT = {
	listado: {
		title: 'Pedidos',
		path: 'pedidos',
		create_btn: 'Crear pedido',
		render: <>
			<PedidosFiltros />
			<PedidosTable />
		</>
	}
}


const Index = () => {
	const { pathname } = useLocation()
	const path = pathname.split('/')[2]

	return (
		<div>
			<h3 className="mb-1 d-flex justify-content-between">
				{/* <DropdownWrapper text="Acciones" classes="ms-auto">
					<DropdownMenuLink text={CONSTANT[path?.length ? path : 'listado'].create_btn} href={`/${CONSTANT[path?.length ? path : 'listado'].path}/create`} icon="Plus" />
				</DropdownWrapper> */}
			</h3>

			{CONSTANT[path?.length ? path : 'listado'].render}
		</div>
	)
}

export default Index
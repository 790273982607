import {
  FILTER_RUT_CLIENTE,
  FILTER_NOMBRE_CLIENTE,
  FILTER_EMAIL_CLIENTE,
  FILTER_TELEFONO_CLIENTE,
  FILTER_ADD_PAIS_CLIENTE,
  FILTER_REMOVE_PAIS_CLIENTE,
  FILTER_ADD_FACTURACION,
  FILTER_REMOVE_FACTURACION,
  FILTER_ADD_COMUNA,
  FILTER_REMOVE_COMUNA,
  FILTER_RAZON_SOCIAL,
  FILTER_GIRO,
  FILTER_DIRECCION,
  FILTER_ADD_PUNTO_ENTREGA,
  FILTER_REMOVE_PUNTO_ENTREGA,
  FILTER_DIRECCION_ENTREGA,
  FILTER_ADD_METODO_PAGO,
  FILTER_REMOVE_METODO_PAGO,
  FILTER_CLEAR,
  FILTER_OBSERVACIONES,
  FILTER_CODIGO_DESCUENTO,
} from "../redux-types";

const initialState = {
  filters: {
    pais_cliente: [],
    rut_cliente: "",
    nombre_cliente: "",
    email_cliente: "",
    telefono_cliente: "",
    facturacion: [],
    comuna: [],
    razon_social: "",
    giro: "",
    direccion: "",
    punto_entrega: [],
    direccion_entrega: "",
    metodo_pago: [],
    observaciones: "",
    codigo_descuento: "",
  },
};

export const generarPedidoReducer = (state = initialState, action) => {
  const isListedMetodoPago = state.filters.metodo_pago.find((el) => el === action.payload);
  const isListedFacturacion = state.filters.facturacion.find((el) => el === action.payload);
  const isListedPuntoEntrega = state.filters.punto_entrega.find((el) => el === action.payload);
  const isListedComuna = state.filters.comuna.find((el) => el === action.payload);
  const isListedPaisCliente = state.filters.pais_cliente.find((el) => el === action.payload);

  switch (action.type) {
    case FILTER_ADD_FACTURACION:
      return {
        ...state,
        filters: {
          ...state.filters,
          facturacion: isListedFacturacion ? state.filters.facturacion : [...state.filters.facturacion, action.payload],
        },
      };

    case FILTER_REMOVE_FACTURACION:
      return {
        ...state,
        filters: {
          ...state.filters,
          facturacion: state.filters.facturacion.filter((el) => el !== action.payload),
        },
      };

    case FILTER_ADD_METODO_PAGO:
      return {
        ...state,
        filters: {
          ...state.filters,
          metodo_pago: isListedMetodoPago ? state.filters.metodo_pago : [...state.filters.metodo_pago, action.payload],
        },
      };

    case FILTER_REMOVE_METODO_PAGO:
      return {
        ...state,
        filters: {
          ...state.filters,
          metodo_pago: state.filters.metodo_pago.filter((el) => el !== action.payload),
        },
      };

    case FILTER_DIRECCION_ENTREGA:
      return {
        ...state,
        filters: {
          ...state.filters,
          direccion_entrega: action.payload,
        },
      };

    case FILTER_ADD_PUNTO_ENTREGA:
      return {
        ...state,
        filters: {
          ...state.filters,
          punto_entrega: isListedPuntoEntrega ? state.filters.punto_entrega : [...state.filters.punto_entrega, action.payload],
        },
      };

    case FILTER_REMOVE_PUNTO_ENTREGA:
      return {
        ...state,
        filters: {
          ...state.filters,
          punto_entrega: state.filters.punto_entrega.filter((el) => el !== action.payload),
        },
      };

    case FILTER_ADD_COMUNA:
      return {
        ...state,
        filters: {
          ...state.filters,
          comuna: isListedComuna ? state.filters.comuna : [...state.filters.comuna, action.payload],
        },
      };

    case FILTER_REMOVE_COMUNA:
      return {
        ...state,
        filters: {
          ...state.filters,
          comuna: state.filters.comuna.filter((el) => el !== action.payload),
        },
      };

    case FILTER_RUT_CLIENTE:
      return {
        ...state,
        filters: {
          ...state.filters,
          rut_cliente: action.payload,
        },
      };

    case FILTER_NOMBRE_CLIENTE:
      return {
        ...state,
        filters: {
          ...state.filters,
          nombre_cliente: action.payload,
        },
      };

    case FILTER_EMAIL_CLIENTE:
      return {
        ...state,
        filters: {
          ...state.filters,
          email_cliente: action.payload,
        },
      };

    case FILTER_TELEFONO_CLIENTE:
      return {
        ...state,
        filters: {
          ...state.filters,
          telefono_cliente: action.payload,
        },
      };

    case FILTER_ADD_PAIS_CLIENTE:
      return {
        ...state,
        filters: {
          ...state.filters,
          pais_cliente: isListedPaisCliente ? state.filters.pais_cliente : [...state.filters.pais_cliente, action.payload],
        },
      };

    case FILTER_REMOVE_PAIS_CLIENTE:
      return {
        ...state,
        filters: {
          ...state.filters,
          pais_cliente: state.filters.pais_cliente.filter((el) => el !== action.payload),
        },
      };

    case FILTER_RAZON_SOCIAL:
      return {
        ...state,
        filters: {
          ...state.filters,
          razon_social: action.payload,
        },
      };

    case FILTER_GIRO:
      return {
        ...state,
        filters: {
          ...state.filters,
          giro: action.payload,
        },
      };

    case FILTER_DIRECCION:
      return {
        ...state,
        filters: {
          ...state.filters,
          direccion: action.payload,
        },
      };

    case FILTER_OBSERVACIONES:
      return {
        ...state,
        filters: {
          ...state.filters,
          observaciones: action.payload,
        },
      };

    case FILTER_CODIGO_DESCUENTO:
      return {
        ...state,
        filters: {
          ...state.filters,
          codigo_descuento: action.payload,
        },
      };

    case FILTER_CLEAR:
      return initialState;

    default:
      return state;
  }
};

import { useEffect, useState } from "react";
import axios from "axios";


// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";
// import SelectFilter from "../../components/SelectFilter";


// React-router-dom
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { getOne } from "../../endpoints/getOne";
import CustomLoader from "../../components/CustomLoader";


const CONSTANT = {
  redirectUrl: '/productos/tipos-elementos',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitle: 'Crear tipo elemento',
  title: 'Tipos elementos',
  queryUrl: getOne.tipos_elementos,
  initialFormState: {
    tabla: '',
    nombre: undefined,
    estatus: 1
  },
}

const Update = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetchData, setFetchData] = useState(null)
  const { id } = useParams()


  const handleInputChange = (e) => {
    const { target } = e

    setFetchData({
      ...fetchData,
      [target.name]: target.value,
    })
  }


  useEffect(() => {
    axios(CONSTANT.queryUrl(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => setFetchData(data?.tipo_elemento))
      .catch(err => console.error(err))

    return () => {
      setFetchData(null)
    }
  }, [id])


  const handleFetch = async (e) => {
    e.preventDefault()

    let data = {
      nombre: fetchData?.nombre,
      tabla: fetchData?.tabla,
      estatus: Number(fetchData?.estatus)
    }

    axios(CONSTANT.queryUrl(id), {
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'application/json'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} actualizado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []

        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))
        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {fetchData
          ? <CustomForm size="" title="Editar tipo-elemento">
            <div className="row">
              <Switch
                name="estatus"
                value={fetchData?.estatus}
                labelText="Estatus"
                handleValue={handleInputChange}
                size="col-12"
              />

              <FormInput
                name="nombre"
                value={fetchData?.nombre}
                labelText="* Nombre"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk="col-md-6"
              />

              <FormInput
                name="tabla"
                value={fetchData?.tabla}
                labelText="Tabla"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk="col-md-6"
              />
            </div>

            <Button
              type="submit"
              text="Actualizar"
              color="primary"
              icon="Circle"
              onClick={handleFetch}
            />
          </CustomForm>
          : <CustomLoader />
        }
      </div>
    </div>

  );
};

export default Update;

import axios from "axios";

// Redux
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";



// Hooks
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { create } from "../../endpoints/create";
import { useState } from "react";
import { getAll } from "../../endpoints/getAll";
import { useEffect } from "react";
import SelectFilter from "../../components/SelectFilter";
import CustomLoader from "../../components/CustomLoader";
import { idExtractor } from "../../helpers/idExtractor";


const CONSTANT = {
  redirectUrl: '/template/cinta-informativa',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  componentTitle: 'Crear cinta Informativa',
  title: 'Cinta Informativa',
  queryUrl: create.cinta_informativa,
  fetchFrontendId: getAll.frontend_id,

  initialFormState: {
    texto: '',
    frontend_id: '',
    fecha_inicio: '',
    hora_inicio: '',
    fecha_final: '',
    hora_final: '',
    estatus: 1,
  },

  selectFilterInitialState: {
    frontendId: [],
  }
}


const handleSelectFiltersData = async () => {
  const frontendId = await axios(CONSTANT.fetchFrontendId,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { frontendId }
}


const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [form, setForm] = useState(CONSTANT.initialFormState);

  const [isConsulting, setIsConsulting] = useState(false)


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => {
      const defaultFrontend = data.frontendId.find(x => x.id === 1)
      setSelectFilter(data)
      setForm(form => ({
        ...form,
        frontend_id: `${defaultFrontend?.id}-${defaultFrontend?.nombre}`
      }))
    })
  }, [])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)

    let data = {
      texto: form?.texto,
      fecha_hora_inicio: `${form?.fecha_inicio} ${form?.hora_inicio}:00`,
      fecha_hora_final: `${form?.fecha_final} ${form?.hora_final}:00`,
      estatus: Number(form?.estatus)
    }

    if (!form?.frontend_id.includes('*NULL*')) {
      data = {
        ...data,
        frontend_id: idExtractor(form?.frontend_id),
      }
    }

    // return console.log(data);

    axios(CONSTANT.queryUrl,
      {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        },
        data
      })
      .then(res => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `Cinta informativa: ${res.data.texto} creada con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() =>
        setIsConsulting(false)
      )
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT?.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {selectFilter.frontendId.length
          ? <CustomForm size="" title={CONSTANT?.componentTitle}>
            <div className="row">
              <Switch
                name="estatus"
                value={form?.estatus}
                labelText="Estatus"
                handleValue={handleInputChange}
                size="col-12"
              />

              <FormInput
                name="texto"
                value={form?.texto}
                labelText="* Texto"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <SelectFilter
                name="frontend_id"
                value={form?.frontend_id?.split('-')[1] ?? ''}
                labelText="* Frontend"
                optionObj={selectFilter.frontendId.map(({ id, nombre }) => ({ id, name: nombre }))}
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <FormInput
                name="fecha_inicio"
                type="date"
                value={form?.fecha_inicio}
                labelText="* Fecha de inicio"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <FormInput
                name="hora_inicio"
                type="time"
                value={form?.hora_inicio}
                labelText="* Hora de inicio"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <FormInput
                name="fecha_final"
                type="date"
                value={form?.fecha_final}
                labelText="* Fecha final"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />

              <FormInput
                name="hora_final"
                type="time"
                value={form?.hora_final}
                labelText="* Hora final"
                handleValue={handleInputChange}
                size="col-12"
                sizeDesk='col-md-6'
              />
            </div>

            <Button disabled={isConsulting} type="submit" text="Crear" color="primary" icon="Plus" onClick={(e) => handleFetch(e)} />
          </CustomForm>

          : <CustomLoader />
        }
      </div>
    </div>
  );
};

export default Create;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setListedResults } from '../redux/actions/listedResults';



// TODO ARREGLA LA FUNCIONALIDAD Y ACOPLALA

const Pagination = ({ currentPage = 1, classes, links, lastPageUrl, firstPageUrl, handleUrl }) => {
   const { showing_results } = useSelector(state => state.listedResults)
   const dispatch = useDispatch()
   const [pagesArray, setPagesArray] = useState([]);

   useEffect(() => {
      setPagesArray(links);
   }, [links]);


   return (
      <div className='d-flex'>
         <ul className={`pagination url1-links ${classes ?? ''}`}>
            <li className={`${currentPage === 1 ? "page-item first disabled" : "page-item first cursor-pointer"}`}>
               <div onClick={() => handleUrl(firstPageUrl)} className="page-link">Primera</div>
            </li>

            {pagesArray.map(({ label, active, url }, i) => (
               <li key={i + 1 + "-page"} className={`${active ? 'page-item active' : 'page-item'} ${url ? 'cursor-pointer' : 'disabled'}`}>
                  <div onClick={() => handleUrl(url)} className="page-link">
                     {i === 0
                        ? 'Anterior'
                        : i === pagesArray.length - 1
                           ? 'Siguiente'
                           : label
                     }
                  </div>
               </li>
            ))}

            <li className={`${currentPage === pagesArray.length - 2 ? "page-item last disabled" : "page-item last cursor-pointer"}`}>
               <div onClick={() => handleUrl(lastPageUrl)} className="page-link">Última</div>
            </li>
         </ul>
         <span className='ms-5 p-25'>Mostrar:
            <select value={showing_results} className='ms-50 border rounded-3 p-25 cursor-pointer' onChange={(e) => dispatch(setListedResults(Number(e.target.value)))}>
               <option>5</option>
               <option>10</option>
               <option>15</option>
               <option>20</option>
               <option>25</option>
               <option>50</option>
               <option>100</option>
               <option>200</option>
            </select>
         </span>
      </div>
   )
}

export default Pagination
import axios from 'axios'
import React, { useEffect, useState } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { clavesFilter, displayModal } from '../../redux/actions/modalProductosFiltros'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Components
import SelectFilter from '../SelectFilter'


// Helpers
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'


// Endpoints
import { getAll } from '../../endpoints/getAll'
import { FILTER_ADD_ESTATUS, FILTER_ADD_PUNTO_ENTREGA } from '../../redux/redux-types'



const CONSTANT = {
  redirectUrl: '/productos/puntos-entrega',
  title: 'Puntos de entrega',

  fetchPuntosEntrega: getAll.puntos_entrega + '/listar',
  fetchProductos: getAll.productos + '/listar?limit=99999',

  selectFilterInitialState: {
    puntos_entrega: [],
  },


}

const fetchSelectFiltersData = async (isLoading) => {
  let config = {
    headers: {
      'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    }, method: 'POST'
  }

  isLoading(true)

  const puntos_entrega = await axios(CONSTANT.fetchPuntosEntrega, config)
    .then(({ data }) => [{ id: '*NULL*', tipo_destino: { nombre: 'Sin selección' } }, ...data] ?? [{ id: '*NULL*', tipo_destino: { nombre: 'Sin selección' } }])
    .catch(err => {
      console.error(err)
      return []
    })

  const estatus = [
    { id: '0', nombre: 'Inactivo' },
    { id: '1', nombre: 'Activo' },
    { id: '*NULL*', nombre: 'Todos' }
  ]

  isLoading(false)

  return { puntos_entrega, estatus }
}


export const ProductosPuntosEntregaUpdateFiltros = ({ form, handleForm }) => {
  const dispatch = useDispatch()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RELLENA LOS SELECTFILTERS
  useEffect(() => {
    fetchSelectFiltersData(setIsConsulting).then(data => setSelectFilter(data))
  }, [dispatch])


  // MANEJADORES DE FILTRO - PRODUCTOS
  const handleFormInput = (e, filter) => {
    const { value, name } = e.target

    handleForm(prev => ({
      ...prev,
      [name]: value
    }))
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">

      <ListadoHeader
        classes='border rounded-2'
        title={CONSTANT.title}
        handleDisable={isConsulting}
      >
        <SelectFilter
          altSeparator
          labelText='Puntos de entrega'
          size='col-12'
          name='punto_entrega_id'
          sizeDesk='col-md-8'
          handleValue={handleFormInput}
          optionObj={selectFilter.puntos_entrega?.map(({ id, direccion, tipo_destino, comuna, nombre }) => ({ id, name: `${tipo_destino?.nombre ?? 'N/A'} | ${direccion ?? 'N/A'}, ${nombre ?? 'N/A'} | ${comuna?.region?.nombre ?? 'N/A'}` }))}
          value={form.punto_entrega_id?.split('%-%')?.[1]}
        />

        <SelectFilter
          altSeparator
          labelText='Estatus'
          size='col-12'
          name='estatus'
          sizeDesk='col-md-2'
          handleValue={handleFormInput}
          optionObj={selectFilter.estatus?.map(({ id, nombre }) => ({ id, name: nombre }))}
          value={form.estatus?.split('%-%')?.[1]}
        />

      </ListadoHeader>
    </div>
  )
}